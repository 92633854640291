import React from "react"
import { FormattedMessage } from "react-intl"
import { Button, DialogContent, DialogContentText, DialogActions, DialogTitle } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"

export function ModalErrorStep() {
  return (
    <>
      <DialogTitle>
        <FormattedMessage defaultMessage="An unknown error occurred." />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage defaultMessage="Please refresh the page and try again." />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button to="/" id="home" component={RouterLink} variant="outlined" color="secondary">
          <FormattedMessage defaultMessage="Carrot Home" />
        </Button>
        <Button onClick={() => window.location.reload()}>
          <FormattedMessage defaultMessage="Refresh the page" />
        </Button>
      </DialogActions>
    </>
  )
}
