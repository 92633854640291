import React, { AnchorHTMLAttributes, FC, MouseEventHandler, MutableRefObject, ReactNode, useRef } from "react"
import { Link } from "react-router-dom"

export type MaybeLinkProps = {
  to?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  onClickOnly?: boolean
  children: ReactNode
  underline?: boolean
  enabled?: boolean
  className?: string
} & AnchorHTMLAttributes<HTMLAnchorElement>

/**
 * A visually un-opinionated base link component that generates either a react
 * router link or an anchor tag depending on the destination of the `to` prop
 */
const MaybeLink: FC<MaybeLinkProps> = ({
  to = "",
  onClick,
  onClickOnly,
  children,
  underline = false,
  enabled = true,
  className = "",
  ...props
}: MaybeLinkProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null)
  const externalLink = to && to.length > 0 && (to.startsWith("http") || to.startsWith("mailto") || to.startsWith("tel"))

  const toIfEnabled = enabled ? to : ""
  const onClickIfEnabled = enabled ? onClick : null

  const withBlur = (ref: MutableRefObject<any>, fn?: ((event: any) => any) | null) => (event?: any) => {
    ref.current.blur()
    fn && fn(event)
  }

  const linkClasses = `
    ${enabled ? "pointer" : ""}
    ${underline ? "underline" : "no-underline"}
    ${className}
  `
  const linkProps = {
    to: toIfEnabled,
    onClick: withBlur(linkRef, onClickIfEnabled),
    href: externalLink ? to : undefined,
    className: linkClasses,
    ref: linkRef,
    ...props
  }

  const onClickOnlyProps = onClickOnly
    ? {
        role: "button",
        // eslint-disable-next-line no-script-url -- this should either not use an `<a>` at all, or use `href: "#"` and ensure that `event.preventDefault()`is called after the `onClick` handler
        href: "javascript:void(null);"
      }
    : {}

  return toIfEnabled && !externalLink && !onClickOnly ? (
    <Link {...linkProps}>{children}</Link>
  ) : (
    <a {...{ ...linkProps, ...onClickOnlyProps }}>{children}</a>
  )
}
export { MaybeLink }
