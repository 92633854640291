export enum PartnerInviteStatus {
  NONE = "NONE",
  INVITED = "INVITED",
  ACTIVE = "ACTIVE"
}

export type InviteStatusResult = {
  status: PartnerInviteStatus
}

export enum UserRole {
  PARTNER = "partner",
  EMPLOYEE = "employee"
}

export type UserRoleResult = {
  role: UserRole
  partnerAccessId: number
}

export type PartnerEmailOptInResult = {
  partnerEmailOptIn: boolean
}
