import { Box, Button, Theme, Typography } from "@carrotfertility/carotene-core"
import React from "react"
import { FormattedMessage } from "react-intl"
import { handleFillYourPrescriptionButtonClick } from "../components/FillYourPrescriptionButton"
import { handleGetACarrotPlanButtonClick } from "../components/GetACarrotPlanButton"
import CarrotRx from "../resources/images/carrot-rx.svg"
import { useDigitalFrontDoorBreakpointViews } from "../hooks/useDigitalFrontDoorBreakpointViews"
const HeaderButton = ({
  isMobileView = false,
  isInAppLockdown
}: {
  isMobileView: boolean
  isInAppLockdown: boolean
}) => {
  return isInAppLockdown ? (
    <Button
      variant="contained"
      onClick={handleGetACarrotPlanButtonClick}
      sx={{
        fontSize: isMobileView ? "0.875rem" : "1.25rem",
        padding: isMobileView ? "0.5rem 2rem" : "1rem 2.5rem"
      }}
      id="digital-front-door-header-get-started-button"
    >
      <FormattedMessage defaultMessage="Get started" />
    </Button>
  ) : (
    <Button
      variant="contained"
      onClick={handleFillYourPrescriptionButtonClick}
      sx={{
        fontSize: isMobileView ? "0.875rem" : "1.25rem",
        padding: isMobileView ? "0.5rem 2rem" : "1rem 2.5rem"
      }}
      id="digital-front-door-header-fill-your-prescription-button"
    >
      <FormattedMessage defaultMessage="Fill your prescription" />
    </Button>
  )
}

function HeaderContent({ isInAppLockdown }: { isInAppLockdown: boolean }): JSX.Element {
  const { isMobileView, isUnderXLScreenView } = useDigitalFrontDoorBreakpointViews()

  const isMediumAndLargeBreakpoints = isUnderXLScreenView && !isMobileView

  const whiteBackgroundStyles = {
    position: "absolute",
    bottom: "1.25rem",
    left: isMediumAndLargeBreakpoints ? "7.5rem" : "1.5rem",
    right: "1.5rem",
    bgcolor: (theme: Theme) => theme.palette.background.paper,
    padding: (theme: Theme) => theme.tokens.spacing.lg,
    borderRadius: "2rem",
    width: isMediumAndLargeBreakpoints ? "max-content" : "auto"
  }

  const extraLargeDesktopView = {
    position: "absolute",
    top: "calc(100vw * .125)",
    left: "calc(100vw * .084)",
    right: "calc(100vw * .084)",
    bgcolor: "transparent",
    minWidth: "28.5rem",
    minHeight: "13.25rem"
  }

  const headerStyle = isUnderXLScreenView ? { ...whiteBackgroundStyles } : { ...extraLargeDesktopView }

  return (
    <Box sx={headerStyle}>
      <Box component="img" src={CarrotRx} alt="Carrot Rx" />
      <Box
        maxWidth={isMobileView ? "18.625rem" : "28.25rem"}
        marginTop={isUnderXLScreenView ? (theme) => theme.tokens.spacing.md : "3.25rem"}
      >
        <Typography
          variant="h2"
          component="h1"
          marginBottom={isMobileView ? (theme) => theme.tokens.spacing.md : (theme) => theme.tokens.spacing.xl}
          fontSize={isMobileView ? "1.875rem" : "3rem"}
        >
          <FormattedMessage defaultMessage="Fertility medication made easy" />
        </Typography>
        <HeaderButton isMobileView={isMobileView} isInAppLockdown={isInAppLockdown} />
      </Box>
    </Box>
  )
}

export function Header({ isInAppLockdown }: { isInAppLockdown: boolean }) {
  return (
    <Box position="relative" width="100%" justifyContent="center">
      <Box
        component="img"
        src={require("../resources/images/header-image.jpg")}
        minHeight={"416px"}
        width="100%"
        sx={{ objectFit: "cover" }}
        alt=""
        position="relative"
        display={"block"}
        maxHeight={`calc(100vw / 2.2)`}
      />
      <HeaderContent isInAppLockdown={isInAppLockdown} />
    </Box>
  )
}
