import { ListItemText, Typography, UnorderedList, UnorderedListItem } from "@carrotfertility/carotene-core"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"

export function SaveOnFertilityMedicationsList(): JSX.Element {
  const intl = useIntl()
  return (
    <UnorderedList
      sx={(theme) => ({
        "li::marker": {
          color: theme.palette.text.primary
        }
      })}
    >
      <UnorderedListItem>
        <ListItemText
          primary={
            <FormattedMessage
              defaultMessage="<bold>Save up to 40%</bold> with exclusive discounts on fertility medication"
              values={{
                bold: (boldContent) => (
                  <Typography component="span" fontWeight="bold">
                    {boldContent}
                  </Typography>
                )
              }}
            />
          }
        />
      </UnorderedListItem>
      <UnorderedListItem>
        <ListItemText
          primary={intl.formatMessage({
            defaultMessage: "Guaranteed price matching on most fertility medication orders"
          })}
        />
      </UnorderedListItem>
      <UnorderedListItem>
        <ListItemText
          primary={intl.formatMessage({
            defaultMessage: "Competitive pricing on all prescriptions"
          })}
        />
      </UnorderedListItem>
      <UnorderedListItem>
        <ListItemText
          primary={intl.formatMessage({
            defaultMessage: "Easily switch pharmacies to Alto"
          })}
        />
      </UnorderedListItem>
    </UnorderedList>
  )
}
