import React from "react"
import { Typography, Stack, Link, Box } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { Link as ReactRouterLink } from "react-router-dom"

export function ErrorPage(): JSX.Element {
  const intl = useIntl()

  const headerText = intl.formatMessage({ defaultMessage: "Something’s not working" })
  const altText = intl.formatMessage({ defaultMessage: "error sign" })

  return (
    <Stack alignItems={"center"}>
      <Box
        component="img"
        src={`/images/error-sign.png`}
        srcSet={`/images/error-sign.png`}
        alt={altText}
        marginTop={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
        marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
        width={300}
      />
      <Stack alignItems={"center"}>
        <Typography variant="h2" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          {headerText}
        </Typography>
        <Typography marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          <FormattedMessage defaultMessage="Try reloading this page. " />
        </Typography>
        <Typography>
          <FormattedMessage
            defaultMessage="If the problem persists, feel free to <link>contact us</link>."
            values={{
              link: (linkContent) => (
                <Link component={ReactRouterLink} to="/send-a-message">
                  {linkContent}
                </Link>
              )
            }}
          />
        </Typography>
      </Stack>
    </Stack>
  )
}
