import React from "react"
import { useFormContext } from "react-hook-form"
import { useSignUpFlow } from "components/context/signup/SignUpFlowContext"
import { AppAccessPageLayout } from "components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "components/access-page/AppAccessPageCard"
import { FormattedMessage, useIntl } from "react-intl"
import { Alert, Box, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { UnauthPreferredLocale } from "../views/UnauthPreferredLocale"
import { useIntlValidator } from "../../utils/hooks/useIntlValidator"
import { emailValidation } from "../../utils/Regexes"
import { Form, FormButton, FormTextField } from "@carrotfertility/carotene-core-x"

interface EmailInputProps {
  enabled: boolean
  responseErrorExists: boolean
}

const EmailInput = ({ enabled, responseErrorExists }: EmailInputProps): JSX.Element => {
  const {
    formState: { errors }
  } = useFormContext()
  const { email } = useSignUpFlow()
  const intlEmailValidation = useIntlValidator(emailValidation)
  const intl = useIntl()

  return (
    <FormTextField
      name="email"
      disabled={!enabled}
      inputProps={{ readOnly: !enabled, disabled: false }}
      registerOptions={{
        required: intl.formatMessage({ defaultMessage: "Required" }),
        validate: intlEmailValidation
      }}
      helperText={errors?.email?.message?.toString()}
      error={Boolean(errors.email) || responseErrorExists}
      label={intl.formatMessage({ defaultMessage: "Email" })}
      defaultValue={email}
      type="email"
      id="email"
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
    />
  )
}

// @ts-expect-error TS7031
function AddEmailToAccountCard({ onSubmit, error, getTranslatedErrorMessage, heading, subheading }): JSX.Element {
  const { email } = useSignUpFlow()
  const intl = useIntl()
  const headingCopy =
    heading ||
    (email
      ? intl.formatMessage({ defaultMessage: "Finally, tell us how to reach you" })
      : intl.formatMessage({ defaultMessage: "Tell us how to reach you" }))
  const theme = useTheme()

  return (
    <AppAccessPageCard>
      <Form onSubmit={onSubmit} reValidateMode="onSubmit">
        <Stack
          spacing={theme.spacing(theme.tokens.spacing.lg)}
          paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}
        >
          <Typography variant="h1">{headingCopy}</Typography>
          {subheading && <Typography variant="body1">{subheading}</Typography>}
          {error && (
            <Alert severity="error" data-testid="error-alert">
              {getTranslatedErrorMessage(error)}
            </Alert>
          )}
          <EmailInput enabled responseErrorExists={Boolean(error)} />
          <Box display="block" sx={{ alignSelf: "center" }}>
            <FormButton type="submit">
              <FormattedMessage defaultMessage="Continue" />
            </FormButton>
          </Box>
          <Box display="block" sx={{ alignSelf: "center" }}>
            <UnauthPreferredLocale />
          </Box>
        </Stack>
      </Form>
    </AppAccessPageCard>
  )
}

// @ts-expect-error TS7006
export default function SignUpFindAccountSuccessPage(props): JSX.Element {
  const intl = useIntl()
  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Tell us how to reach you" })}>
      <AddEmailToAccountCard {...props} />
    </AppAccessPageLayout>
  )
}
