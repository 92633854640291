import React, { useEffect } from "react"
import { Spacer } from "../atoms/Atoms"
import { IlloUnderwaterPlants } from "../atoms/Illustrations"
import { Body } from "../atoms/Bodies"
import { ButtonPrimary } from "../atoms/buttons/ButtonPrimary"

import { H2 } from "@carrotfertility/carotene"
import { getHeap } from "utils/heap"
import { FormattedMessage } from "react-intl"
import { PageLayout } from "features/global-ui/page-layout"
import { Box } from "@carrotfertility/carotene-core"

const PageNotFound = () => {
  useEffect(() => {
    getHeap().track("PageNotFound", { EventName: "User saw page not found screen" })
  }, [])

  return (
    <PageLayout header={null}>
      <Box display="flex" justifyContent="center" marginTop={"8rem"}>
        <div className="flex-none br3 bg-pampas animate-all mw6-ns  pv4">
          <div className="tc pa4">
            <IlloUnderwaterPlants />
            <Spacer height={1} />
            <H2>
              <FormattedMessage defaultMessage="Page not found!" />
            </H2>
            <Spacer height={0.25} />
            <Body color="black-80">
              <FormattedMessage defaultMessage="Sorry we couldn't find what you were looking for" />
            </Body>
            <Spacer height={1} />
            <ButtonPrimary to="/">
              <FormattedMessage defaultMessage="Return home" />
            </ButtonPrimary>
          </div>
        </div>
      </Box>
    </PageLayout>
  )
}

export { PageNotFound }
