import React from "react"
import { Redirect, Route } from "react-router-dom"
import { CarrotRxExtendLandingPage } from "../../features/carrot-rx/extend/register/CarrotRxExtendLandingPage"
import { CarrotRxExtendInfoPage } from "./info"
import { Title } from "services/page-title/Title"
import { useIntl } from "react-intl"

const CarrotRxExtendContext = React.createContext(null)

type useCarrotRxExtendContext = {
  isRegistered: boolean
  setIsRegistered: (isRegistered: boolean) => void
}
export const useCarrotRxExtend = (): useCarrotRxExtendContext => React.useContext(CarrotRxExtendContext)

function CarrotRxExtendProvider({ children }: { children: React.ReactElement }): JSX.Element {
  const [isRegistered, setIsRegistered] = React.useState(false)

  return (
    <CarrotRxExtendContext.Provider value={{ isRegistered, setIsRegistered }}>
      {children}
    </CarrotRxExtendContext.Provider>
  )
}

export function CarrotRxExtend(): JSX.Element {
  return (
    <CarrotRxExtendProvider>
      <CarrotRxExtendRoutes />
    </CarrotRxExtendProvider>
  )
}
function CarrotRxExtendRoutes(): JSX.Element {
  const intl = useIntl()
  return (
    <>
      <Title title={intl.formatMessage({ defaultMessage: "Carrot Rx Extend" })} />
      <Route exact path="/carrot-rx-extend/access">
        <CarrotRxExtendLandingPage />
      </Route>
      <Route exact path="/carrot-rx-extend/access/info">
        <CarrotRxExtendInfoPage />
      </Route>
      <Route path="*">
        <Redirect to="/carrot-rx-extend/access" />
      </Route>
    </>
  )
}
