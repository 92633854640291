import { UseQueryResult } from "@tanstack/react-query"
import { dayjs } from "@carrotfertility/carotene-core"
import useMemberQuery from "../member-info"

export function useMemberName(): UseQueryResult<string> {
  return useMemberQuery(({ firstName, preferredName }) => preferredName || firstName)
}

function isFutureDate(date: string): boolean {
  if (date) {
    const now = dayjs()
    const launch = dayjs(date)
    return now.isBefore(launch, "date")
  }
  return false
}

export function useMemberBenefitStarted(): UseQueryResult<boolean> {
  return useMemberQuery(({ company, eligibilityDate }) => {
    const launchDateInFuture = isFutureDate(company.launchDate)
    const eligibilityDateInFuture = isFutureDate(eligibilityDate)
    if (!launchDateInFuture && !eligibilityDateInFuture) {
      return true
    }
    return false
  })
}
