import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "components/carrot-plans/shared/useQueryRoutingInfo"
import { useParams } from "react-router-dom"
import { AfabKeys } from "components/carrot-plans/hooks/useMedicalQuestionMenuItem"

enum AfabSteps {
  ASSISTED_REPRODUCTION = "PAST_FERTILITY_TREATMENT",
  PRESERVATION = "PAST_FERTILITY_TREATMENT",
  PREGNANT = "PREGNANT",
  TRY_PREGNANT = "TRY_PREGNANT",
  MENOPAUSE = "MENOPAUSE"
}

export default function WhatKindOfJourneyAfabDecisionStep(): JSX.Element {
  const routingData = useCheckRoutingInfoQueryCache()
  const { journey } = useParams<{ journey: string }>()
  // @ts-expect-error TS7053
  const journeyValue = journey ? AfabKeys[journey] : AfabSteps[routingData?.journey]
  const { send } = useStateMachine()

  useEffect(() => {
    send(journeyValue)
  })
  return <></>
}
