import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Container } from "@carrotfertility/carotene"
import { Steps } from "../workflow/steps"
import { useProgressTracker } from "../../views/modal/ProgressTracker"
import { useCurrentUser } from "../../context/user/UserContext"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import useSubmitWithErrorCatch from "../hooks/useSubmitWithErrorCatch"
import {
  BenefitEnrollmentFlowModalBackButton,
  BenefitEnrollmentModalContinueButton,
  BenefitEnrollmentModalFormSelect
} from "../shared"
import { useUserDeductible } from "components/context/user/DeductibleContext"
import { defineMessage, useIntl, MessageDescriptor, FormattedMessage } from "react-intl"
import { Box, Typography } from "@carrotfertility/carotene-core"

const FieldName = "memberPlanSourceSelectValue"
const ViewName = Steps.MEMBER_PLAN_SOURCE

export const enum MemberPlanSourceOptions {
  EMPLOYER = "EMPLOYER",
  PARTNER = "PARTNER",
  PARENT = "PARENT",
  OTHER = "OTHER",
  NOT_INSURED = "NOT_INSURED",
  IM_NOT_SURE = "IM_NOT_SURE"
}

const memberPlanSourceIdToLabel = {
  [MemberPlanSourceOptions.EMPLOYER]: defineMessage({ defaultMessage: "From my employer" }),
  [MemberPlanSourceOptions.PARTNER]: defineMessage({ defaultMessage: "From my partner's employer" }),
  [MemberPlanSourceOptions.PARENT]: defineMessage({ defaultMessage: "From my parent's employer" }),
  [MemberPlanSourceOptions.OTHER]: defineMessage({ defaultMessage: "I buy it, or get it through a federal program" }),
  [MemberPlanSourceOptions.NOT_INSURED]: defineMessage({ defaultMessage: "I'm not insured" }),
  [MemberPlanSourceOptions.IM_NOT_SURE]: defineMessage({ defaultMessage: "I'm not sure" })
}

export const getMemberPlanSourceLabelFromId = (id: MemberPlanSourceOptions): MessageDescriptor =>
  memberPlanSourceIdToLabel[id]

function MemberPlanSourceStep(): JSX.Element {
  const intl = useIntl()
  useProgressTracker(1, intl.formatMessage({ defaultMessage: "YOUR INSURANCE COVERAGE" }))
  const { memberPlanSourceSelectValue } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const { setNextStep } = useStateMachine(ViewName, memberPlanSourceSelectValue)
  const formMethods = useForm()
  // @ts-expect-error TS7006
  const { onSubmit } = useSubmitWithErrorCatch(async (args) => {
    const val = args[FieldName].value
    val !== "IM_NOT_SURE" &&
      (await updateBenefitEnrollmentAnswers({
        memberPlanSourceSelectValue: val
      }))
    setNextStep(ViewName, val)
  })
  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <Container padding="none" stack="huge">
        <BenefitEnrollmentFlowModalBackButton />
      </Container>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xl}
      >
        <FormattedMessage defaultMessage="Where do you get your primary health insurance?" />
      </Typography>
      <Container padding="none">
        <BenefitEnrollmentModalFormSelect
          name={FieldName}
          aria-labelledby="step-heading"
          defaultValue={memberPlanSourceSelectValue}
          options={[
            {
              label: intl.formatMessage(getMemberPlanSourceLabelFromId(MemberPlanSourceOptions.EMPLOYER)),
              value: MemberPlanSourceOptions.EMPLOYER
            },
            {
              label: intl.formatMessage(getMemberPlanSourceLabelFromId(MemberPlanSourceOptions.PARTNER)),
              value: MemberPlanSourceOptions.PARTNER
            },
            {
              label: intl.formatMessage(getMemberPlanSourceLabelFromId(MemberPlanSourceOptions.PARENT)),
              value: MemberPlanSourceOptions.PARENT
            },
            {
              label: intl.formatMessage(getMemberPlanSourceLabelFromId(MemberPlanSourceOptions.OTHER)),
              value: MemberPlanSourceOptions.OTHER
            },
            {
              label: intl.formatMessage(getMemberPlanSourceLabelFromId(MemberPlanSourceOptions.NOT_INSURED)),
              value: MemberPlanSourceOptions.NOT_INSURED
            },
            {
              label: intl.formatMessage(getMemberPlanSourceLabelFromId(MemberPlanSourceOptions.IM_NOT_SURE)),
              value: MemberPlanSourceOptions.IM_NOT_SURE
            }
          ]}
        />
      </Container>
      <Box height="25rem" />
      <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
    </FormProvider>
  )
}

export { MemberPlanSourceStep }
