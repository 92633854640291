import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { Box, Button, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { CaseRateRenewalModalTopSection } from "../case-rate-renewals/CaseRateRenewalModalTopSection"
import { CaseRateRenewalModalButtonGroup } from "../case-rate-renewals/CaseRateRenewalModalButtonGroup"

type UpdateRoutingFlowModalProps = {
  header: string
  clickGoBack: () => void
  clickUpdateMyAnswers: () => void
}
export function UpdateRoutingFlowModal({
  header,
  clickGoBack,
  clickUpdateMyAnswers
}: UpdateRoutingFlowModalProps): JSX.Element {
  const theme = useTheme()
  const [updateMyAnswersIsLoading, setUpdateMyAnswersIsLoading] = useState(false)
  async function updateMyAnswers(): Promise<void> {
    setUpdateMyAnswersIsLoading(true)
    clickUpdateMyAnswers()
  }

  return (
    <>
      <CaseRateRenewalModalTopSection
        header={header}
        showImage
        imageAltText=""
        imageSrc="/images/illo-underwater-desktop"
      />
      <Box>
        <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
            <FormattedMessage
              defaultMessage="When you first started using Carrot, you may have answered some questions about your journey. To help us best
          support you now, please answer or update your answers to the following."
            />
          </Typography>
          <CaseRateRenewalModalButtonGroup
            buttonOne={
              <Button disabled={updateMyAnswersIsLoading} variant="outlined" color="secondary" onClick={clickGoBack}>
                <FormattedMessage defaultMessage="Go back" />
              </Button>
            }
            buttonTwo={
              <Button disabled={updateMyAnswersIsLoading} onClick={updateMyAnswers} variant="contained" color="primary">
                <FormattedMessage defaultMessage="Update my answers" />
              </Button>
            }
          />
        </Stack>
      </Box>
    </>
  )
}
