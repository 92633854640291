import React from "react"
import { StateMachineProvider, useStateMachine } from "components/context/stateMachine/StateMachineContext"
import { benefitEnrollmentSteps } from "components/cmd-enrollment/workflow/step-library"
import { Steps } from "components/cmd-enrollment/workflow/steps"
import { useBenefitEnrollment } from "components/context/enrollment/BenefitEnrollmentContext"
import { connectInsuranceWorkflows, Workflows } from "components/cmd-enrollment/workflow/workflows"
import { useEnrollmentFlowModal } from "components/cmd-enrollment/EnrollmentModal"
import { useProgressTracker } from "components/views/modal/ProgressTracker"
import { EnrollmentErrorBoundary } from "../UnhandledErrorStep"
import { useIntl } from "react-intl"

type WorkflowType = Workflows
// @ts-expect-error TS7031
function getWorkflow({ companyHasCMDEnabled }): WorkflowType {
  return companyHasCMDEnabled ? Workflows.CMD_HDHP : Workflows.EMD
}
type GetFirstStepType = Steps
// @ts-expect-error TS7031
function getFirstStep({ companyHasCMDEnabled }): GetFirstStepType {
  return companyHasCMDEnabled ? Steps.MEMBER_SHOULD_SHOW_INSURANCE : Steps.MEMBER_INFO_CONNECT_INSURANCE
}

function ConnectInsuranceView(): JSX.Element {
  const { step } = useStateMachine()
  return React.createElement(step.view)
}

export default function ConnectInsuranceFlow(): JSX.Element {
  const { onExit } = useEnrollmentFlowModal()
  const { companyHasCMDEnabled } = useBenefitEnrollment()
  const intl = useIntl()
  useProgressTracker(2, intl.formatMessage({ defaultMessage: "CONNECT YOUR INSURANCE" }))
  const stateMachineProps = {
    initialStep: getFirstStep({ companyHasCMDEnabled: companyHasCMDEnabled }),
    workflows: connectInsuranceWorkflows,
    steps: benefitEnrollmentSteps,
    stateMachineName: "Connect Insurance",
    workflowName: getWorkflow({ companyHasCMDEnabled })
  }
  return (
    <StateMachineProvider {...stateMachineProps} handleWorkflowComplete={onExit}>
      <EnrollmentErrorBoundary>
        <ConnectInsuranceView />
      </EnrollmentErrorBoundary>
    </StateMachineProvider>
  )
}
