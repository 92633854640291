import React from "react"
import { useHistory, useLocation } from "react-router-dom"
import MemberMessagesContainer from "../components/messaging"
import { EMAIL_VERIFICATION_CACHE_KEY } from "../components/EmailVerification/useEmailVerification"
import { useCurrentUser } from "../components/context/user/UserContext"
import { MessagesHeader } from "../components/messaging/MessagesHeader"
import { InAppMessagingContextProvider } from "../components/context/messaging/InAppMessagingContext"
import { useQueryClient } from "@tanstack/react-query"
import EmployeeOnlySpaceContainer from "../components/employee-only-containers/EmployeeOnlySpaceContainer"
import { PageLayout } from "../features/global-ui/page-layout"

const InAppMessagingContainer = (): JSX.Element => {
  return (
    <PageLayout header={<MessagesHeader />}>
      <EmployeeOnlySpaceContainer>
        <MemberMessagesContainer />
      </EmployeeOnlySpaceContainer>
    </PageLayout>
  )
}

const MessagesContainer = (): JSX.Element => {
  const queryClient = useQueryClient()
  const { personalEmail } = useCurrentUser()
  const { state } = useLocation<{ previousPath: string }>()
  const history = useHistory()
  const isEmailVerified = queryClient.getQueryData([EMAIL_VERIFICATION_CACHE_KEY])

  const MessagesRestrictAccessUnverifiedPersonalEmail = {
    pathname: state?.previousPath || "/",
    state: { showVerifyEmailModal: true }
  }

  const restrictAccessForUnverifiedPersonalEmail = (): boolean => {
    if (personalEmail === null || isEmailVerified) {
      return false
    }
    return !isEmailVerified
  }

  if (restrictAccessForUnverifiedPersonalEmail()) {
    history.replace(MessagesRestrictAccessUnverifiedPersonalEmail)
  }
  return (
    <>
      <InAppMessagingContextProvider>
        <InAppMessagingContainer />
      </InAppMessagingContextProvider>
    </>
  )
}

export default MessagesContainer
