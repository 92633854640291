import React from "react"
import { Container, FlexContainer, useBreakpointValue } from "@carrotfertility/carotene"

export const BackgroundWrapper = ({
  guideSlugId,
  children
}: {
  guideSlugId: string
  children: JSX.Element | JSX.Element[]
}): JSX.Element => {
  const navFooterGap = useBreakpointValue(["medium", "medium", null, null])
  return (
    <FlexContainer
      justifyContent="center"
      width="100%"
      height={["auto", "auto", "auto", "700px"]}
      padding={["small", "small", "medium", "0 0 128px 0"]}
    >
      <Container
        width="100%"
        maxWidth={[null, null, "900px"]}
        minHeight={["60vh", "60vh", "auto", "auto"]}
        height={["auto", "auto", "700px", "700px"]}
      >
        <FlexContainer
          height="100%"
          direction="column"
          justifyContent="space-between"
          data-guide-id={guideSlugId}
          gap={navFooterGap}
        >
          {children}
        </FlexContainer>
      </Container>
    </FlexContainer>
  )
}
