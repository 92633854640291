import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { Link, Typography } from "@carrotfertility/carotene-core"
import { EmployeeSupportUrl } from "../../../utils/EmployeeSupportLink"

export function RegisterError(): JSX.Element {
  const intl = useIntl()
  const header = intl.formatMessage({
    defaultMessage: "Something’s not working"
  })

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        description={
          <Typography>
            <FormattedMessage
              defaultMessage="We weren't able to activate your account. Try clicking the unique activation link in your email. Still not working? Feel free to <link>contact us</link>."
              values={{
                link: (text) => (
                  <Link color="inherit" href={EmployeeSupportUrl} target={"_blank"}>
                    {text}
                  </Link>
                )
              }}
            />
          </Typography>
        }
      />
    </>
  )
}
