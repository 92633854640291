import React from "react"
import { Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { Body } from "../atoms/Bodies"
import { Spacer } from "../atoms/Atoms"
import { ButtonPrimary } from "../atoms/buttons/ButtonPrimary"

export const MerchantDenied = () => (
  <>
    <Spacer height={2} />
    <Typography variant="h3">
      <FormattedMessage defaultMessage="Please contact Carrot Care" />
    </Typography>
    <Spacer height={1} />
    <Body>
      <FormattedMessage defaultMessage="At this time, you won’t be able to use your Carrot Card with this provider. You’ll need to pay for this transaction out-of-pocket and request a reimbursement." />
    </Body>
    <Spacer height={1} />
    <Body>
      <FormattedMessage defaultMessage="In the meantime, please contact Carrot Care for further details." />
    </Body>
    <Spacer height={1.5} />
    <div className="flex justify-end">
      <ButtonPrimary href="/talk-to-carrot">
        <FormattedMessage defaultMessage="Sign in to send a message" />
      </ButtonPrimary>
    </div>
  </>
)
