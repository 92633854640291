import { WelcomeBackIntroStep } from "./steps/WelcomeBackIntroStep"
import { CarrotPlanOptionsStep } from "./steps/CarrotPlanOptionsStep"
import { UpdateCarrotPlanDetailsStep } from "./steps/UpdateCarrotPlanDetailsStep"
import { KeepMyPlanStep } from "./steps/KeepMyPlanStep"
import { DoneUsingCarrotStep } from "./steps/DoneUsingCarrotStep"
import { ThanksForLettingUsKnowStep } from "./steps/ThanksForLettingUsKnowStep"
import { ErrorStep } from "./steps/ErrorStep"

export enum Steps {
  WELCOME_BACK_INTRO = "Welcome back intro",
  CARROT_PLAN_OPTIONS = "Carrot plan options",
  UPDATE_CARROT_PLAN_DETAILS = "Update carrot plan details",
  KEEP_MY_PLAN = "Keep my plan",
  DONE_FOR_NOW = "Done for now",
  THANKS_FOR_LETTING_US_KNOW = "Thanks for letting us know",
  ERROR = "Error"
}

export enum Workflows {
  CASE_RATE_RENEWAL = "CaseRateRenewal"
}

// prettier-ignore
export const caseRateRenewalSteps = [
  { name: Steps.WELCOME_BACK_INTRO, component: WelcomeBackIntroStep },
  { name: Steps.CARROT_PLAN_OPTIONS, component: CarrotPlanOptionsStep },
  { name: Steps.UPDATE_CARROT_PLAN_DETAILS, component: UpdateCarrotPlanDetailsStep },
  { name: Steps.KEEP_MY_PLAN, component: KeepMyPlanStep },
  { name: Steps.DONE_FOR_NOW, component: DoneUsingCarrotStep },
  { name: Steps.THANKS_FOR_LETTING_US_KNOW, component: ThanksForLettingUsKnowStep },
  { name: Steps.ERROR, component: ErrorStep }
]

// prettier-ignore
export const caseRateRenewalFlow = [
  { at: Steps.WELCOME_BACK_INTRO,                           given: "",                            goto: Steps.CARROT_PLAN_OPTIONS },
  { at: Steps.CARROT_PLAN_OPTIONS,                          given: "REFRESH_MY_PLAN",             goto: Steps.UPDATE_CARROT_PLAN_DETAILS },
  { at: Steps.CARROT_PLAN_OPTIONS,                          given: "KEEP_MY_PLAN",                goto: Steps.KEEP_MY_PLAN },
  { at: Steps.CARROT_PLAN_OPTIONS,                          given: "DONE_FOR_NOW",                goto: Steps.DONE_FOR_NOW },
  { at: Steps.CARROT_PLAN_OPTIONS,                          given: "ERROR",                       goto: Steps.ERROR },
  { at: Steps.DONE_FOR_NOW,                                 given: "THANKS_FOR_LETTING_US_KNOW",  goto: Steps.THANKS_FOR_LETTING_US_KNOW },
  { at: Steps.DONE_FOR_NOW,                                 given: "ERROR",                       goto: Steps.ERROR },
]

export const caseRateRenewalWorkflows = [{ name: Workflows.CASE_RATE_RENEWAL, workflow: caseRateRenewalFlow }]

export const caseRateRenewalRoutingFlowUrl = "/carrot-plans/case-rate-renewal/routing/?refreshRequest=true"
