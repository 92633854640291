import { EnrollmentType } from "components/cmd-enrollment/EnrollmentModal"

const HomePageEnrollmentFlowActive = {
  pathname: "/",
  state: { showBenefitEnrollmentModal: true }
}

const HomePageQleEnrollment = {
  pathname: "/",
  state: { showBenefitEnrollmentModal: true, enrollmentType: EnrollmentType.QLE_RE_ENROLLMENT }
}

const HomePageInsuranceEditEnrollment = {
  pathname: "/",
  state: { showBenefitEnrollmentModal: true, enrollmentType: EnrollmentType.INSURANCE_EDIT }
}

const HomePagePersonalEmailVerified = {
  pathname: "/",
  state: { showPersonalEmailVerifiedModal: true }
}

const HomePageIndex = {
  pathname: "/"
}

const Locations = {
  Home: {
    Index: HomePageIndex,
    EnrollmentFlowActive: HomePageEnrollmentFlowActive,
    QleEnrollmentActive: HomePageQleEnrollment,
    InsuranceEditEnrollmentActive: HomePageInsuranceEditEnrollment,
    PersonalEmailVerified: HomePagePersonalEmailVerified
  }
}

export default Locations
