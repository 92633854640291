import { PageHeader, PageHeaderGlyph } from "features/global-ui/page-header"
import React from "react"
import { FormattedMessage } from "react-intl"

const TalkToCarrotChatHeader = (): JSX.Element => {
  return (
    <PageHeader
      startGlyph={<PageHeaderGlyph src="/images/icn-talk-to-carrot.png" srcSet="/images/icn-talk-to-carrot-2x.png" />}
      pageTitle={<FormattedMessage defaultMessage="Talk to Carrot" />}
    />
  )
}

export { TalkToCarrotChatHeader }
