/* eslint-disable unused-imports/no-unused-vars -- we're not confident that the mobile bridge doesn't depend on these function signatures */

import { MobileBridgeClient } from "./MobileBridgeClient"

export class NullMobileBridgeClient implements MobileBridgeClient {
  // @ts-expect-error TS7006
  helpCenter = (token, zdType, id) => {
    throw new Error("Not a mobile device")
  }

  isBiometricsCapable = async () => false

  isBiometricLoginAlreadyConfigured = async () => false

  disableBiometrics = async () => {
    throw new Error("Not a mobile device")
  }

  // @ts-expect-error TS7006
  attemptBiometricsCredentialsRetrieval = async (login) => {
    throw new Error("Not a mobile device")
  }

  // @ts-expect-error TS7006
  showBiometricsEnrollment = (username, password, biometricEnrollmentSuccess, biometricEnrollmentFailure) => {
    throw new Error("Not a mobile device")
  }

  getAppVersion = async () => {
    throw new Error("Not a mobile device")
  }

  isPushPermissionsEnabled = async (): Promise<null> => null

  promptPushPermissions = async () => {
    throw new Error("Not a mobile device")
  }

  // @ts-expect-error TS7006
  setUserInfo = (email, employeeId) => {
    throw new Error("Not a mobile device")
  }
}

/* eslint-enable unused-imports/no-unused-vars */
