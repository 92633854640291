import { DbgPaths } from "../../../../../utils/Paths"
import React from "react"
import { FormattedMessage } from "react-intl"
import { TextLink } from "@carrotfertility/carotene"
import { UnorderedListItem } from "@carrotfertility/carotene-core"
import useBenefit from "../../../../dynamic-content/hooks/useGetBenefit"
import { CategorySections } from "../PayingForCareModal"

const MedicationsHeader = (): JSX.Element => {
  return (
    <p>
      <FormattedMessage defaultMessage="This category includes eligible medications for the following journeys:" />
    </p>
  )
}

const MedicationListItems = (): JSX.Element => {
  const { data: benefitInfo } = useBenefit()
  const journeys = benefitInfo.journeys
  return (
    <>
      {journeys.fertilityCare.eligibleExpensesDetail.isRxCovered && (
        <UnorderedListItem>
          <FormattedMessage
            defaultMessage="<link>Fertility care</link>"
            values={{
              link: (linkContent) => (
                <TextLink size="medium" target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                  {linkContent}
                </TextLink>
              )
            }}
          />
        </UnorderedListItem>
      )}
      {journeys.fertilityPreservation.eligibleExpensesDetail.isRxCovered && (
        <UnorderedListItem>
          <FormattedMessage
            defaultMessage="<link>Fertility preservation</link>"
            values={{
              link: (linkContent) => (
                <TextLink size="medium" target="_blank" href={DbgPaths.PRESERVATION_COVERAGE}>
                  {linkContent}
                </TextLink>
              )
            }}
          />
        </UnorderedListItem>
      )}
      {journeys.fertilityCare.eligibleExpensesDetail.hasDonorAssistance && (
        <UnorderedListItem>
          <FormattedMessage
            defaultMessage="<link>Donor assistance</link> related to fertility care"
            values={{
              link: (linkContent) => (
                <TextLink size="medium" target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                  {linkContent}
                </TextLink>
              )
            }}
          />
        </UnorderedListItem>
      )}
      {journeys.gestationalCarrier.hasEligibleExpenses && (
        <UnorderedListItem>
          <FormattedMessage
            defaultMessage="<link>Gestational surrogacy</link> and related donor assistance"
            values={{
              link: (linkContent) => (
                <TextLink size="medium" target="_blank" href={DbgPaths.GESTATIONAL_SURROGACY_COVERAGE}>
                  {linkContent}
                </TextLink>
              )
            }}
          />
        </UnorderedListItem>
      )}
      {journeys.menopause.hasEligibleExpenses && (
        <UnorderedListItem>
          <FormattedMessage
            defaultMessage="<link>Menopause</link>"
            values={{
              link: (linkContent) => (
                <TextLink size="medium" target="_blank" href={DbgPaths.MENOPAUSE}>
                  {linkContent}
                </TextLink>
              )
            }}
          />
        </UnorderedListItem>
      )}
      {journeys.lowTestosterone.hasEligibleExpenses && (
        <UnorderedListItem>
          <FormattedMessage
            defaultMessage="<link>Low testosterone</link>"
            values={{
              link: (linkContent) => (
                <TextLink size="medium" target="_blank" href={DbgPaths.LOW_TESTOSTERONE}>
                  {linkContent}
                </TextLink>
              )
            }}
          />
        </UnorderedListItem>
      )}
      {journeys.genderAffirmingCare.hasEligibleExpenses && (
        <UnorderedListItem>
          <FormattedMessage
            defaultMessage="<link>Gender-affirming care</link>"
            values={{
              link: (linkContent) => (
                <TextLink size="medium" target="_blank" href={DbgPaths.GENDER_AFFIRMING_CARE}>
                  {linkContent}
                </TextLink>
              )
            }}
          />
        </UnorderedListItem>
      )}
    </>
  )
}

const MedicationsFooter = (): JSX.Element => {
  const { data: benefitInfo } = useBenefit()
  const carrotRx = benefitInfo.carrotRx

  return (
    <p>
      {carrotRx.isCarrotRxRequired ? (
        <FormattedMessage
          defaultMessage="To be covered, all medications must be prescribed by an <linkOne>eligible provider</linkOne> and filled through <linkTwo>Carrot Rx</linkTwo>."
          values={{
            linkOne: (linkContent) => (
              <TextLink size="medium" target="_blank" href={DbgPaths.PROVIDER_ELIGIBILITY}>
                {linkContent}
              </TextLink>
            ),
            linkTwo: (linkContent) => (
              <TextLink size="medium" target="_blank" href={DbgPaths.CARROT_RX}>
                {linkContent}
              </TextLink>
            )
          }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="To be covered, all medications must be prescribed by an <link>eligible provider</link>."
          values={{
            link: (linkContent) => (
              <TextLink size="medium" target="_blank" href={DbgPaths.PROVIDER_ELIGIBILITY}>
                {linkContent}
              </TextLink>
            )
          }}
        />
      )}
    </p>
  )
}

export function getMedicationCategorySections(): CategorySections {
  return {
    header: <MedicationsHeader />,
    listItems: <MedicationListItems />,
    footer: <MedicationsFooter />
  }
}
