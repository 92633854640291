import { LegacyBenefit } from "services/member-benefit/hooks/useGetLegacyBenefit"

export function getDoulaAttestationConditions(benefit: LegacyBenefit): DoulaConditions {
  const { coverages } = benefit

  const partnerEligibleForCoverage = coverages?.isMemberAndPartnerEligible
  const partnerEligibleIfOnCompanyMedicalPlan = coverages?.isPartnerEligibleIfOnCustomerMedicalPlan
  const onlyLegalPartnersEligible = coverages?.areLegalPartnersEligible

  const doulaConditions = {
    allPartnersEligible:
      partnerEligibleForCoverage && !partnerEligibleIfOnCompanyMedicalPlan && !onlyLegalPartnersEligible,
    noPartnersEligible:
      !partnerEligibleForCoverage && !partnerEligibleIfOnCompanyMedicalPlan && !onlyLegalPartnersEligible,
    anyLegalPartnerEligible:
      partnerEligibleForCoverage && !partnerEligibleIfOnCompanyMedicalPlan && onlyLegalPartnersEligible,
    anyPartnerOnMedicalPlanEligible:
      partnerEligibleForCoverage && partnerEligibleIfOnCompanyMedicalPlan && !onlyLegalPartnersEligible,
    onlyLegalPartnerOnMedicalPlanEligible:
      partnerEligibleForCoverage && partnerEligibleIfOnCompanyMedicalPlan && onlyLegalPartnersEligible
  }

  return doulaConditions
}

export type DoulaConditions = {
  noPartnersEligible: boolean
  allPartnersEligible: boolean
  anyLegalPartnerEligible: boolean
  anyPartnerOnMedicalPlanEligible: boolean
  onlyLegalPartnerOnMedicalPlanEligible: boolean
}
