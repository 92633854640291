import React from "react"
import { Box, Button } from "@carrotfertility/carotene-core"
import { FormTextField } from "@carrotfertility/carotene-core-x"
import { FormattedMessage, useIntl } from "react-intl"
import { useHistory } from "react-router"

export function Email(): JSX.Element {
  const intl = useIntl()
  const history = useHistory()
  const handleOnClick = () => {
    history.push(`/account/#email-addresses`)
  }
  return (
    <Box>
      <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.sm)}>
        <FormTextField
          name="email"
          label={intl.formatMessage({ defaultMessage: "Email" })}
          registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
          disabled
        />
      </Box>

      <Button fullWidth={false} variant="outlined" color="secondary" onClick={handleOnClick}>
        <FormattedMessage defaultMessage="Update email" />
      </Button>
    </Box>
  )
}
