import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { MenopauseDiagnosisDetails } from "./MedicalInfoOptions"
import { FormattedMessage } from "react-intl"
import {
  RoutingFlowCheckboxGroup,
  useConvertTranslatedLabel,
  ContinueButton,
  StepLayout,
  ButtonFooterLayout,
  SkipButton
} from "components/carrot-plans/shared"
import { Form } from "@carrotfertility/carotene-core-x"

export default function MenopauseDiagnosisDetailsStep(): JSX.Element {
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultValues = medicalInfo?.menopauseDiagnosisDetails
  const options = useConvertTranslatedLabel(MenopauseDiagnosisDetails)

  const { send } = useStateMachine(defaultValues || hasQuestionBeenSkipped("menopauseDiagnosisDetails") ? "" : null)

  async function onClickContinue(formValues: { menopauseDiagnosisDetails: string | string[] }): Promise<void> {
    await updateMedicalInfo(formValues)
    send("")
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["menopauseDiagnosisDetails"] })
    send("")
  }

  return (
    <StepLayout
      header={<FormattedMessage defaultMessage="Select all that apply." />}
      formElements={
        <Form onSubmit={onClickContinue} defaultValues={{ menopauseDiagnosisDetails: defaultValues }}>
          <RoutingFlowCheckboxGroup name="menopauseDiagnosisDetails" options={options} />
          <ButtonFooterLayout
            continueButton={
              <ContinueButton>
                <FormattedMessage defaultMessage="Continue" />
              </ContinueButton>
            }
            skipButton={<SkipButton onClick={onSkip} />}
          />
        </Form>
      }
    />
  )
}
