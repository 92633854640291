import { Box, InternalIcon, Link, Typography, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { DigitalFrontDoorSectionLayout } from "../components/DigitalFrontDoorSectionLayout"
import { FormattedMessage } from "react-intl"
import { FillYourPrescriptionButton } from "../components/FillYourPrescriptionButton"
import { GetACarrotPlanButton } from "../components/GetACarrotPlanButton"
import { useDigitalFrontDoorBreakpointViews } from "../hooks/useDigitalFrontDoorBreakpointViews"
import { CarrotMemberImageGroup } from "../components/CarrotMemberImageGroup"
import { SaveOnFertilityMedicationsList } from "../components/SaveOnFertilityMedicationsList"

function PricingLinks(): JSX.Element {
  const PRICE_QUOTE_LINK = "https://www.surveymonkey.com/r/3VHLDLH"
  return (
    <Box
      marginTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}
      bgcolor={(theme) => theme.palette.background.default}
      gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}
      display="flex"
      flexDirection="column"
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      padding={(theme) => theme.spacing(theme.tokens.spacing.md, theme.tokens.spacing.lg)}
    >
      <Link
        id="price-quote-for-fertility-medication-link"
        target="_blank"
        href={PRICE_QUOTE_LINK}
        endIcon={<InternalIcon fontSize="small" color="primary" />}
      >
        <FormattedMessage defaultMessage="Get a price quote for fertility medications" />
      </Link>
      <Link
        id="price-matching-details-link"
        target="_blank"
        href="/your-benefit-guide/pharmacy"
        endIcon={<InternalIcon fontSize="small" color="primary" />}
      >
        <FormattedMessage defaultMessage="View price matching details" />
      </Link>
    </Box>
  )
}

export function FertilityMedicationSavingSection({ isInAppLockdown }: { isInAppLockdown: boolean }): JSX.Element {
  const theme = useTheme()

  const { isUnderXLScreenView } = useDigitalFrontDoorBreakpointViews()

  return (
    <Box id="save-on-fertility-meds">
      <DigitalFrontDoorSectionLayout backgroundColor={theme.palette.background.paper}>
        <Box
          flexDirection={isUnderXLScreenView ? "column-reverse" : "row"}
          display="flex"
          gap={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}
          paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}
        >
          <CarrotMemberImageGroup />
          <Box flex={1} flexDirection="column">
            <Typography marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxl)} variant="h2">
              <FormattedMessage defaultMessage="Save up to 40% on fertility medications" />
            </Typography>
            <SaveOnFertilityMedicationsList />
            {!isInAppLockdown && <PricingLinks />}
            <Box
              marginTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}
              marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
            >
              {isInAppLockdown ? <GetACarrotPlanButton /> : <FillYourPrescriptionButton />}
            </Box>
          </Box>
        </Box>
      </DigitalFrontDoorSectionLayout>
    </Box>
  )
}
