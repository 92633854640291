export const AcceptableFileExtensions = [
  ".jpeg",
  ".jpg",
  ".png",
  ".pdf",
  ".HEIC",
  ".doc",
  ".docx",
  ".txt",
  ".xlsx",
  ".zip"
]
