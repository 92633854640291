import React, { FC } from "react"
import { Spacer } from "../Atoms"
import { Button, ButtonProps } from "../buttons/Button"

/**
 * A button footer intended to be composed within a RoundedRectangle
 */
const RRButtonFooter: FC<ButtonProps> = (props) => (
  <>
    <Spacer expand height={1.5} />
    <Button {...props} />
  </>
)

export { RRButtonFooter }
