import React from "react"
import { Box, InternalIcon, Link, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { DbgPaths } from "../../utils/Paths"

export default function CarrotRxHomeSidebarModule({
  fertilityCareOrPreservationEnabled
}: {
  fertilityCareOrPreservationEnabled: boolean
}): JSX.Element {
  const intl = useIntl()

  return (
    <Box
      id="home-sidebar-carrot-rx-module"
      padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      bgcolor={(theme) => theme.palette.primary.light}
      borderRadius={(theme) => theme.tokens.borderRadius.md}
    >
      <Box
        component="img"
        src={"/images/carrot-rx-logo.svg"}
        aria-label={intl.formatMessage({
          defaultMessage: "Carrot Rx logo",
          description: "Accessibility label for a logo that reads 'Carrot Rx'"
        })}
      />
      <Typography
        variant="body2"
        paddingTop={(theme) => theme.spacing(theme.tokens.spacing.md)}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      >
        <FormattedMessage defaultMessage="Carrot Rx offers significant savings on medication and convenient delivery to your home." />
      </Typography>
      <Link
        id="home-sidebar-carrot-rx-module-link"
        href={fertilityCareOrPreservationEnabled ? "/carrot-rx" : DbgPaths.PHARMACY}
        target={fertilityCareOrPreservationEnabled ? "_blank" : "_self"}
        variant="body2"
        endIcon={<InternalIcon color="primary" fontSize="small" />}
      >
        <FormattedMessage defaultMessage="Explore your pharmacy benefit" />
      </Link>
    </Box>
  )
}
