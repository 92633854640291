import React, { useEffect } from "react"
import { Button, DialogContentText } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { ConfirmationDialog } from "@carrotfertility/carotene-core-x"
import { promptPushPermissions } from "lib/carrot-api/MobileNativeClient"
import { getHeap } from "utils/heap"

export function AccountSettingsEnablePushNotificationsSettingDialog({
  initialOpen
}: {
  initialOpen: boolean
}): JSX.Element {
  const intl = useIntl()

  const [open, setOpen] = React.useState(initialOpen)

  useEffect(() => {
    if (initialOpen) {
      getHeap().track("Push notification setting dialog on the account settings page rendered")
    }
  }, [initialOpen])

  function handleClose() {
    setOpen(false)
  }

  const handlePrimaryAction = async () => {
    setOpen(false)
    await promptPushPermissions()
  }

  return (
    <ConfirmationDialog
      aria-labelledby="account-settings-push-notifications-dialog"
      onClose={handleClose}
      open={open}
      title={intl.formatMessage({ defaultMessage: "Enable notifications" })}
      primaryAction={
        <Button onClick={handlePrimaryAction} data-button-id="account-settings-push-notifications-dialog-enable-button">
          <FormattedMessage defaultMessage="Go to settings" />
        </Button>
      }
      secondaryAction={
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleClose}
          data-button-id="account-settings-push-notifications-dialog-not-now-button"
        >
          <FormattedMessage defaultMessage="Not now" />
        </Button>
      }
    >
      <DialogContentText>
        <FormattedMessage defaultMessage="To receive push notifications, turn them on in your device settings." />
      </DialogContentText>
    </ConfirmationDialog>
  )
}
