import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useMedicalInfoWorkflow } from "../../hooks/useMedicalInfoFlow"

export default function AmabOrAfabDecisionStep(): JSX.Element {
  const { workflow } = useMedicalInfoWorkflow()

  const { send } = useStateMachine()
  useEffect(() => {
    send(workflow)
  })
  return <></>
}
