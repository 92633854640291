import React from "react"
import {
  Divider,
  Typography,
  Link,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Box,
  TypographyProps,
  DividerProps,
  TableBodyProps,
  TableHeadProps,
  TableRowProps,
  TableCellProps,
  LinkProps,
  BoxProps,
  ListProps,
  ListItemProps,
  UnorderedListItem,
  ListItemText,
  UnorderedList,
  OrderedList,
  TableContainer,
  TableContainerProps
} from "@carrotfertility/carotene-core"
import {
  getSupportCenterLink,
  goToHelpCenter,
  isZendeskLink,
  parseZendeskFragmentFromUrl
} from "lib/carrot-api/HelpCenterClient"
import { Link as ReactRouterLink } from "react-router-dom"
import { reportErrorMessage } from "utils/ErrorReporting"

type MarkdownElementDefinition<Props> = {
  component: React.ElementType<Props>
  props: Props
}

export function isRelativeLink(href: string): boolean {
  // test if the start of the href is "/", "./" or "../" followed by a non "/" character
  if (!href) return false

  if (/^\.{0,2}\/[^/]/.test(href) || href === "/") {
    return true
  }
  return false
}

const h1: MarkdownElementDefinition<TypographyProps> = {
  component: Typography,
  props: {
    variant: "h1",
    paddingBottom: (theme) => theme.spacing(theme.tokens.spacing.lg)
  }
}

const h2: MarkdownElementDefinition<TypographyProps> = {
  component: Typography,
  props: {
    variant: "h2",
    paddingTop: (theme) => theme.spacing(theme.tokens.spacing.lg),
    paddingBottom: (theme) => theme.spacing(theme.tokens.spacing.xxs)
  }
}

const h3: MarkdownElementDefinition<TypographyProps> = {
  component: Typography,
  props: {
    variant: "h3",
    paddingTop: (theme) => theme.spacing(theme.tokens.spacing.md),
    paddingBottom: (theme) => theme.spacing(theme.tokens.spacing.xxs)
  }
}

const h4: MarkdownElementDefinition<TypographyProps> = {
  component: Typography,
  props: {
    variant: "h4",
    paddingTop: (theme) => theme.spacing(theme.tokens.spacing.xs),
    paddingBottom: (theme) => theme.spacing(theme.tokens.spacing.xxs)
  }
}

const h5: MarkdownElementDefinition<TypographyProps> = {
  component: Typography,
  props: {
    variant: "h5",
    paddingTop: (theme) => theme.spacing(theme.tokens.spacing.xs)
  }
}

const h6: MarkdownElementDefinition<TypographyProps> = {
  component: Typography,
  props: {
    variant: "h6",
    paddingTop: (theme) => theme.spacing(theme.tokens.spacing.xs)
  }
}

const hr: MarkdownElementDefinition<DividerProps> = {
  component: Divider,
  props: {
    sx: (theme) => ({
      marginTop: theme.spacing(theme.tokens.spacing.lg),
      marginBottom: theme.spacing(theme.tokens.spacing.lg)
    })
  }
}

const p: MarkdownElementDefinition<TypographyProps> = {
  component: Typography,
  props: {
    component: "p",
    paddingBottom: (theme) => theme.spacing(theme.tokens.spacing.md),
    sx: {
      ":last-child": {
        paddingBottom: 0
      }
    }
  }
}

const strong: MarkdownElementDefinition<TypographyProps> = {
  component: Typography,
  props: {
    component: "strong",
    fontWeight: "bold"
  }
}

const em: MarkdownElementDefinition<TypographyProps> = {
  component: Typography,
  props: {
    component: "em",
    fontStyle: "italic"
  }
}

const table: MarkdownElementDefinition<TableContainerProps> = {
  component: ({ children, ...otherProps }) => (
    <TableContainer {...otherProps}>
      <Table>{children}</Table>
    </TableContainer>
  ),
  props: {
    sx: (theme) => ({
      paddingBottom: theme.spacing(theme.tokens.spacing.md),
      ":last-child": {
        paddingBottom: 0
      }
    })
  }
}

const tbody: MarkdownElementDefinition<TableBodyProps> = { component: TableBody, props: {} }

const thead: MarkdownElementDefinition<TableHeadProps> = { component: TableHead, props: {} }

const tr: MarkdownElementDefinition<TableRowProps> = {
  component: TableRow,
  props: {
    sx: { verticalAlign: "top" }
  }
}

const th: MarkdownElementDefinition<TableCellProps> = { component: TableCell, props: { component: "th" } }

const td: MarkdownElementDefinition<TableCellProps> = {
  component: (props) => (
    <TableCell {...props}>
      <Typography variant="body1" component="span">
        {props.children}
      </Typography>
    </TableCell>
  ),
  props: {}
}

const a: MarkdownElementDefinition<LinkProps & { "data-noalerts"?: boolean }> = {
  component: ({ href, children, title, ...otherProps }) => {
    if (!href && !otherProps["data-noalerts"]) {
      reportErrorMessage(`Empty href found in Markdown with title ${title} and children ${JSON.stringify(children)}`)
    }
    if (isZendeskLink(href)) {
      const zdLinkFragment = parseZendeskFragmentFromUrl(href)

      return (
        <Link
          href={getSupportCenterLink(zdLinkFragment)}
          // @ts-expect-error TS7006
          onClick={(event) => goToHelpCenter(event, zdLinkFragment)}
          target={"_blank"}
          {...{ ...otherProps, title }}
        >
          {children}
        </Link>
      )
    }
    const linkProps = isRelativeLink(href)
      ? ({ target: "_self", component: ReactRouterLink, to: href, title } as LinkProps<ReactRouterLink>)
      : ({ target: "_blank", href, title } as LinkProps<"a">)

    return <Link {...{ ...otherProps, ...linkProps }}>{children}</Link>
  },
  props: {
    color: "inherit"
  }
}

const blockquote: MarkdownElementDefinition<BoxProps> = {
  component: Box,
  props: {
    bgcolor: (theme) => theme.palette.background.default,
    padding: (theme) => theme.spacing(theme.tokens.spacing.lg),
    marginTop: (theme) => theme.spacing(theme.tokens.spacing.xs),
    marginBottom: (theme) => theme.spacing(theme.tokens.spacing.lg),
    width: "100%",
    borderRadius: (theme) => theme.tokens.borderRadius.md,
    component: "blockquote",
    sx: {
      ":last-child": {
        marginBottom: 0
      }
    }
  }
}

const ol: MarkdownElementDefinition<ListProps> = {
  component: OrderedList,
  props: {
    sx: {
      paddingTop: 0,
      paddingBottom: (theme) => theme.spacing(theme.tokens.spacing.xl),
      ":last-child": {
        paddingBottom: 0
      }
    }
  }
}

const ul: MarkdownElementDefinition<ListProps> = {
  component: UnorderedList,
  props: {
    sx: {
      paddingTop: 0,
      paddingBottom: (theme) => theme.spacing(theme.tokens.spacing.lg),
      ":last-child": {
        paddingBottom: 0
      }
    }
  }
}

const li: MarkdownElementDefinition<ListItemProps> = {
  component: ({ children, ...otherProps }) => {
    // Majority of the styles for Unordered / Ordered lists are controlled at the list level
    // so this should be safe to do.
    return (
      <UnorderedListItem {...otherProps}>
        <ListItemText>{children}</ListItemText>
      </UnorderedListItem>
    )
  },
  props: {}
}

export const defaultMarkdownElements = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  p,
  strong,
  em,
  table,
  tbody,
  thead,
  tr,
  th,
  td,
  a,
  blockquote,
  ol,
  ul,
  li
} as const
