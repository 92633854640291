import React, { ReactElement, useMemo, useState } from "react"
import { Typography, Link, useTheme, Stack, Divider } from "@carrotfertility/carotene-core"
import { useStartJourneyChangeRoutingInfo } from "../shared/useQueryRoutingInfo"
import { FormattedMessage, useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import { getShortJourneyLabel } from "../hooks/useJourneyOptions"
import useCarrotPlanEmployeeSettings from "../hooks/useCarrotPlanEmployeeSettings"
import { useStartRouting } from "../shared/useQueryStartRouting"
import { CarrotPlansPaths, Paths } from "../../../utils/Paths"
import { JourneyChangeDialogContainer } from "./JourneyChangeDialog"
import { useJourneyChange } from "../hooks/useJourneyChange"

const journeysWithExpectedUpdates = ["ASSISTED_REPRODUCTION", "GESTATIONAL", "PREGNANT"]

export function JourneyChange(): ReactElement {
  const {
    shouldShowEnrolledJourneyDisplay,
    shouldShowEngagedJourneyDisplay,
    journeyChangeDataIsLoaded,
    isJourneyComplete,
    resolvedJourney,
    carrotPlanIsOnTheWay,
    appIsUnlockedAndPendingCarrotPlan
  } = useJourneyChange()

  const journeyChangeComponent = useMemo(() => {
    if (shouldShowEngagedJourneyDisplay && journeyChangeDataIsLoaded) {
      return (
        <PostCarrotPlanJourneyChange
          journeySelection={resolvedJourney}
          isPendingCarrotPlan={appIsUnlockedAndPendingCarrotPlan}
        />
      )
    }

    if (shouldShowEnrolledJourneyDisplay && journeyChangeDataIsLoaded && !carrotPlanIsOnTheWay) {
      return <PreCarrotPlanJourneyChange journeySelection={resolvedJourney} isJourneyComplete={isJourneyComplete} />
    }

    return null
  }, [
    shouldShowEngagedJourneyDisplay,
    journeyChangeDataIsLoaded,
    shouldShowEnrolledJourneyDisplay,
    carrotPlanIsOnTheWay,
    resolvedJourney,
    appIsUnlockedAndPendingCarrotPlan,
    isJourneyComplete
  ])

  return journeyChangeComponent
}

type PreCarrotPlanJourneyChangeProps = {
  journeySelection: string
  isJourneyComplete: boolean
}

function PreCarrotPlanJourneyChange({
  journeySelection,
  isJourneyComplete
}: PreCarrotPlanJourneyChangeProps): ReactElement {
  const theme = useTheme()
  const history = useHistory()
  const { isDoneForNow } = useCarrotPlanEmployeeSettings()
  const { startRouting } = useStartRouting(useStartJourneyChangeRoutingInfo(), CarrotPlansPaths.JOURNEY_CHANGE)

  async function sendToRouting(): Promise<Promise<void> | void> {
    if (isDoneForNow) {
      await startRouting()
    } else {
      history.push(CarrotPlansPaths.ENROLLMENT)
    }
  }

  return (
    <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.xs)}>
      {journeySelection ? <JourneySelection journeySelection={journeySelection} /> : <ReadyToUnlockYourBenefit />}
      <Link component="button" onClick={sendToRouting}>
        {journeySelection && !isJourneyComplete && (
          <FormattedMessage defaultMessage="Tell us about your journey to unlock your benefit" />
        )}
        {journeySelection && isJourneyComplete && <FormattedMessage defaultMessage="Update us on your journey" />}
        {!journeySelection && <FormattedMessage defaultMessage="Tell us about your journey" />}
      </Link>
    </Stack>
  )
}

type JourneySelectionProps = {
  journeySelection: string
  showDivider?: boolean
}

function JourneySelection({ journeySelection, showDivider = true }: JourneySelectionProps): ReactElement {
  const theme = useTheme()
  const intl = useIntl()
  const journeyLabel = getShortJourneyLabel(journeySelection)
  return journeyLabel ? (
    <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.xs)}>
      <Typography>
        <FormattedMessage defaultMessage="Your journey:" /> {intl.formatMessage(journeyLabel)}
      </Typography>
      {showDivider && <Divider orientation="vertical" />}
    </Stack>
  ) : null
}

function ReadyToUnlockYourBenefit(): ReactElement {
  return (
    <Typography>
      <FormattedMessage defaultMessage="Ready to unlock your benefit?" />
    </Typography>
  )
}

type PostCarrotPlanJourneyChangeProps = {
  journeySelection: string
  isPendingCarrotPlan: boolean
}

function UpdateUsButton() {
  const theme = useTheme()
  const history = useHistory()

  function handleUpdateUsClick() {
    history.push(Paths.REFRESH_CARROT_PLAN)
  }

  return (
    <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.xs)}>
      <Divider orientation="vertical" />
      <Link component="button" onClick={handleUpdateUsClick}>
        <FormattedMessage defaultMessage="Update us" />
      </Link>
    </Stack>
  )
}

function PostCarrotPlanJourneyChange({
  journeySelection,
  isPendingCarrotPlan
}: PostCarrotPlanJourneyChangeProps): ReactElement {
  const theme = useTheme()
  const [toggleJourneyChangeModalOpen, setToggleJourneyChangeModalOpen] = useState(false)
  const isJourneyUpdateExpected = journeysWithExpectedUpdates.includes(journeySelection)

  async function openModal(event: { preventDefault: () => void }) {
    event.preventDefault()
    setToggleJourneyChangeModalOpen(true)
  }

  return (
    <>
      <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.xs)}>
        <JourneySelection journeySelection={journeySelection} showDivider={!isPendingCarrotPlan} />
        {!isPendingCarrotPlan && (
          <Link component="button" onClick={openModal}>
            <FormattedMessage defaultMessage="Change journey" />
          </Link>
        )}
        {isJourneyUpdateExpected ? <UpdateUsButton /> : null}
      </Stack>
      {toggleJourneyChangeModalOpen && (
        <JourneyChangeDialogContainer origin="cta" setToggleModalFromCta={setToggleJourneyChangeModalOpen} />
      )}
    </>
  )
}
