import React, { useCallback, useMemo } from "react"
import { Container, FlexContainer, FlexContainerProps, ContainerProps } from "@carrotfertility/carotene"
import styled from "styled-components"

interface RowItemProps extends ContainerProps {
  minWidth: string
}

const RowWrapper = styled.div({
  flex: 1
})

export const RowItem = styled(Container)((props: RowItemProps) => ({
  minWidth: props.minWidth
}))

interface RowProps extends FlexContainerProps {
  /* Gutter space width */
  space: string
  children: React.ReactNodeArray
  direction?: "row" | "row-reverse"
}
export function Row({ space, children, ...otherProps }: RowProps): JSX.Element {
  const addSpaceBetween = useCallback(
    // @ts-expect-error TS7006
    (elements) => {
      // @ts-expect-error TS7006
      return elements.filter(Boolean).reduce((accum: Array<React.ReactNode>, child, index) => {
        accum.push(<RowWrapper key={`${index}-item`}>{child}</RowWrapper>)
        accum.push(<div key={`${index}-spacer`} style={{ width: space }} />)
        return accum
      }, [])
    },
    [space]
  )

  const row = useMemo(() => addSpaceBetween(children), [addSpaceBetween, children])

  return (
    <FlexContainer wrap="wrap" {...otherProps}>
      {row.slice(0, row.length - 1)}
    </FlexContainer>
  )
}
