import React, { useEffect, useState } from "react"
import StripeCheckoutForm from "./components/StripeCheckoutForm"
import { Redirect, useHistory, useLocation } from "react-router"

const InboundMemberPaymentsCheckoutPage = () => {
  const location = useLocation()
  const history = useHistory()
  // @ts-expect-error TS7053
  const clientSecret = location?.state !== undefined ? location?.state["clientSecret"] : undefined
  // @ts-expect-error TS7053
  const paymentIntentAmount = location?.state !== undefined ? location?.state["paymentIntentAmount"] : undefined
  const paymentIntentCurrencyCode =
    // @ts-expect-error TS7053
    location?.state !== undefined ? location?.state["paymentIntentCurrencyCode"] : undefined
  const [, updateState] = useState({})

  useEffect(() => {
    if (history.action === "POP") {
      // @ts-expect-error TS7053
      location.state["clientSecret"] = undefined
      // @ts-expect-error TS7053
      location.state["paymentIntentAmount"] = undefined
      // @ts-expect-error TS7053
      location.state["paymentIntentCurrencyCode"] = undefined
      updateState({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  const appearance = {
    theme: "stripe",
    variables: {
      colorBackground: "#ffffff",
      colorText: "#30313d",
      fontFamily: "Gotham, Noto Sans, Noto Sans Thai Looped, sans-serif",
      spacingUnit: "6px",
      borderRadius: "6px",
      colorDanger: "#D26556",
      colorIconSelectArrow: "#E48400",
      spacingGridRow: "25px"
    },
    rules: {
      ".Input": {
        fontSize: "16px"
      },
      ".Input::placeholder": {
        fontSize: "16px"
      },
      ".Input:focus": {
        outline: "none",
        borderColor: "#E48400",
        boxShadow: "none"
      },
      ".Label": {
        fontSize: "16px",
        fontWeight: "bold",
        fontVariant: "all-small-caps"
      }
    }
  }

  const options = {
    clientSecret,
    appearance
  }

  return clientSecret ? (
    <StripeCheckoutForm
      options={options}
      paymentIntentCurrencyCode={paymentIntentCurrencyCode}
      paymentIntentAmount={paymentIntentAmount}
    />
  ) : (
    <Redirect to="/make-a-payment" />
  )
}

export default InboundMemberPaymentsCheckoutPage
