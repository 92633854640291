import React, { ReactElement } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import { FormattedMessage, useIntl } from "react-intl"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  SkipButton,
  StepLayout,
  useConvertTranslatedLabel
} from "components/carrot-plans/shared"
import { FormRadioButtonGroup } from "services/common-forms"
import { Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { YES_NO_BUTTON_OPTIONS } from "../../shared/yesNoButtonOptions"
import {
  useSocialDeterminantsOfHealthQuery,
  useSocialDeterminantsOfHealthMutation
} from "../../../social-determinants-of-health/useSocialDeterminantsOfHealth"
import { SDOHAnswerOptions } from "../../../../types/socialDeterminantsOfHealth"
import { useCheckRoutingInfoQueryCache } from "../../shared/useQueryRoutingInfo"
import { CenteredLoadingIndicator } from "../../../views/molecules/Molecules"

type FormValues = {
  hasIssuesAffectingWellBeing?: string
  routingInfoId?: number
}

export default function SocialDeterminantsOfHealthStep(): ReactElement {
  const routingData = useCheckRoutingInfoQueryCache()
  const socialDeterminantsOfHealthMutation = useSocialDeterminantsOfHealthMutation()
  const socialDeterminantsOfHealthQuery = useSocialDeterminantsOfHealthQuery(routingData?.routingInfoId)
  const defaultValue = socialDeterminantsOfHealthQuery.data?.hasIssuesAffectingWellBeing
  const radioOptions = useConvertTranslatedLabel(YES_NO_BUTTON_OPTIONS)
  const { send } = useStateMachine(defaultValue ? "" : null)
  const intl = useIntl()
  const theme = useTheme()

  if (socialDeterminantsOfHealthQuery.isLoading) {
    return <CenteredLoadingIndicator />
  }

  async function handleMutateAsync(formValues: FormValues, skip = false): Promise<void> {
    formValues = skip ? { hasIssuesAffectingWellBeing: SDOHAnswerOptions.SKIP } : formValues
    await socialDeterminantsOfHealthMutation.mutateAsync(
      { ...formValues, routingInfoId: routingData?.routingInfoId },
      { onSuccess: () => send("") }
    )
  }

  async function onClickContinue(formValues: FormValues): Promise<void> {
    if (formValues?.hasIssuesAffectingWellBeing) {
      await handleMutateAsync(formValues)
    } else {
      await onSkip()
    }
  }

  async function onSkip(): Promise<void> {
    await handleMutateAsync({}, true)
  }

  const header = intl.formatMessage({
    defaultMessage: "Are there any issues affecting your well-being or access to healthcare?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="hasIssuesAffectingWellBeing-label">{header}</span>}
        description={
          <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
            <Typography>
              <FormattedMessage defaultMessage="These issues may include difficulty with basic needs (like food or housing), challenges with education or employment, unsafe living conditions, or trouble getting medical care. Your answer helps us provide local resources for any social or economic challenges you may be experiencing." />
            </Typography>
          </Stack>
        }
        formElements={
          <Form defaultValues={{ hasIssuesAffectingWellBeing: defaultValue }} onSubmit={onClickContinue}>
            <FormRadioButtonGroup
              name="hasIssuesAffectingWellBeing"
              aria-labelledby="hasIssuesAffectingWellBeing-label"
              options={radioOptions}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
