import React from "react"
import { Typography, Box } from "@carrotfertility/carotene-core"

type PlanPeriodWarningProps = {
  warningTitle: string
  warningDescription: string
}

const PlanPeriodWarning = ({ warningTitle, warningDescription }: PlanPeriodWarningProps): JSX.Element => {
  return (
    <Box
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      bgcolor={(theme) => theme.palette.error.light}
      paddingX={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      paddingY={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      display={"flex"}
    >
      <Box
        component="img"
        src="/images/icn-mini-app.png"
        alt=""
        width={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        height={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        marginTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        marginRight={(theme) => theme.spacing(theme.tokens.spacing.sm)}
      />
      <div>
        <Typography variant="h5" color={(theme) => theme.palette.text.primary}>
          {warningTitle}
        </Typography>
        <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
          {warningDescription}
        </Typography>
      </div>
    </Box>
  )
}
export default PlanPeriodWarning
