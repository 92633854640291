import { useState } from "react"

// @ts-expect-error TS7006
export default function useSubmitWithErrorCatch(submitFunc) {
  const [error, setError] = useState(false)

  // @ts-expect-error TS7019
  async function onSubmit(...args): Promise<void> {
    try {
      await submitFunc(...args)
    } catch (e) {
      setError(e)
    }
  }
  if (error) {
    throw error
  }
  return {
    onSubmit
  }
}
