import React from "react"
import AvailableFundsModule from "components/views/molecules/AvailableFundsModule"
import BenefitsEnrollmentRequired from "./BenefitsEnrollmentRequired"
import { useHistory } from "react-router-dom"
import Locations from "components/views/home/navigation"
import BenefitsEnrollmentExpired from "./BenefitsEnrollmetExpired"
import { BenefitAvailabilityStatus } from "../../../types/benefitEnrollmentStatus"

type BenefitsModuleProps = {
  availabilityStatus: BenefitAvailabilityStatus
  appLockedDown: boolean
  hideAvailableFundsModule: boolean
  onClickEnroll?: () => void
}

const BenefitsModule = (props: BenefitsModuleProps): JSX.Element => {
  const history = useHistory()
  const { availabilityStatus, appLockedDown, hideAvailableFundsModule } = props

  const onClickEnroll = (): void => history.push(Locations.Home.EnrollmentFlowActive)

  if (appLockedDown) {
    return !hideAvailableFundsModule ? <AvailableFundsModule appLockedDown={appLockedDown} /> : null
  }

  switch (availabilityStatus) {
    case BenefitAvailabilityStatus.DOES_NOT_NEED_ENROLLMENT:
      return !hideAvailableFundsModule ? <AvailableFundsModule /> : null
    case BenefitAvailabilityStatus.NEEDS_BENEFIT_ENROLLMENT:
    case BenefitAvailabilityStatus.COMPANY_ADDED_DEDUCTIBLE:
      return <BenefitsEnrollmentRequired onClickEnroll={onClickEnroll} />
    case BenefitAvailabilityStatus.ENROLLMENT_PLAN_EXPIRED:
      return <BenefitsEnrollmentExpired onClickEnroll={onClickEnroll} />
  }
}

export default BenefitsModule
