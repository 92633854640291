import TestUserActionTypes from "./testUserActionTypes"

// @ts-expect-error TS7006
export const toggleDymamicContentTogglesTool = () => async (dispatch) => {
  dispatch({
    type: TestUserActionTypes.TOGGLE_DYNAMIC_CONTENT_TOGGLES_TOOL,
    response: {}
  })
}

// @ts-expect-error TS7006
export const getToggleActionForType = (type: TestUserActionTypes) => () => async (dispatch) => {
  dispatch({ type })
}

// @ts-expect-error TS7006
export const updateCustomCoverageText = () => async (dispatch) => {
  dispatch({
    type: TestUserActionTypes.UPDATE_CUSTOM_COVERAGE_TEXT
  })
}

// @ts-expect-error TS7006
export const updateAdditionalEmployerBenefitsText = () => async (dispatch) => {
  dispatch({
    type: TestUserActionTypes.UPDATE_ADDITIONAL_EMPLOYER_BENEFITS_TEXT
  })
}
