import React, { useContext } from "react"
import { useCurrentUser } from "components/context/user/UserContext"
import { getInsuranceSyncInfo } from "reducers/reimbursementDetails"
import { useSelector } from "react-redux"
import {
  useGetPlanProviderDetailsData,
  useGetInsurancePlanDetailsData
} from "../plan-information-table/plan-information-table-data"
import { InsurancePlanInformationRowData } from "../plan-information-table/InsurancePlanInformationRow"
import { useBenefitEnrollment } from "components/context/enrollment/BenefitEnrollmentContext"
import { getHasDeductible, getHasDeductibleRemaining } from "derivedSelectors/deductibleSelectors"
import { BenefitAvailabilityStatus } from "types/benefitEnrollmentStatus"
import { useUserDeductible } from "components/context/user/DeductibleContext"
import { MemberPlanSourceOptions } from "components/cmd-enrollment/steps/MemberPlanSourceStep"
import {
  useMemberEnrollmentHistory,
  MemberEnrollmentHistory
} from "components/context/enrollment/hooks/useMemberEnrollmentHistory"

type InsuranceDetailsContextProps = {
  hasDeductible: boolean
  insuranceExpired: boolean
  hasDeductibleRemaining: boolean
  needsBenefitEnrollment: boolean
  insurancePlanDetailsData?: InsurancePlanInformationRowData[]
  insuranceProviderDetailsData?: InsurancePlanInformationRowData[]
  partnerPlanRequiresInsuranceProviderInfo: boolean
  memberPlanRequiresInsuranceProviderInfo: boolean
  hasInsurance: boolean
  companyDeductibleStatus: string
  memberHistory: MemberEnrollmentHistory
}

type InsuranceDetailsProviderProps = {
  children: React.ReactNode
}

const InsuranceDetailsContext = React.createContext<InsuranceDetailsContextProps>(null)

export const InsuranceDetailsProvider = (props: InsuranceDetailsProviderProps): JSX.Element => {
  const userInfo = useCurrentUser()
  const { memberPlanRequiresInsuranceProviderInfo, partnerPlanRequiresInsuranceProviderInfo, companyDeductibleStatus } =
    useUserDeductible()

  const { availabilityStatus, needsBenefitEnrollment } = useBenefitEnrollment()
  const insuranceExpired = availabilityStatus === BenefitAvailabilityStatus.ENROLLMENT_PLAN_EXPIRED
  const { memberHistory } = useMemberEnrollmentHistory()
  const { availableTradingPartners } = useSelector(getInsuranceSyncInfo)
  const insurancePlanDetailsData = useGetPlanProviderDetailsData(userInfo, insuranceExpired, memberHistory)
  const insuranceProviderDetailsData = useGetInsurancePlanDetailsData(
    userInfo,
    availableTradingPartners,
    insuranceExpired,
    memberHistory
  )
  const hasDeductible = useSelector(getHasDeductible)
  const hasDeductibleRemaining = useSelector(getHasDeductibleRemaining)
  const hasInsurance = userInfo.memberPlanSourceSelectValue !== MemberPlanSourceOptions.NOT_INSURED

  return (
    <InsuranceDetailsContext.Provider
      value={{
        needsBenefitEnrollment,
        hasDeductible,
        hasDeductibleRemaining,
        insuranceExpired,
        insurancePlanDetailsData,
        insuranceProviderDetailsData,
        memberPlanRequiresInsuranceProviderInfo,
        partnerPlanRequiresInsuranceProviderInfo,
        hasInsurance,
        companyDeductibleStatus,
        memberHistory
      }}
    >
      {props.children}
    </InsuranceDetailsContext.Provider>
  )
}

const useInsuranceDetails = (): InsuranceDetailsContextProps => useContext(InsuranceDetailsContext)

export default useInsuranceDetails
