import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { useConvertTranslatedLabel } from "../../../../services/common-forms/useConvertTranslatedLabel"
import { useCheckRoutingInfoQueryCache, useUpdateRoutingInfo } from "../../shared/useQueryRoutingInfo"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { ButtonFooterLayout, ContinueButton, RoutingFlowTitle, StepLayout } from "../../shared"
import { Form } from "@carrotfertility/carotene-core-x"
import { FormRadioButtonGroup } from "../../../../services/common-forms"
import React from "react"
import { Typography } from "@carrotfertility/carotene-core"

const OPTIONS = [
  { label: defineMessage({ defaultMessage: "I already had it" }), value: "ALREADY_HAD" },
  { label: defineMessage({ defaultMessage: "In the next 2 months" }), value: "NEXT_TWO_MONTHS" },
  { label: defineMessage({ defaultMessage: "In the next 4 months" }), value: "NEXT_FOUR_MONTHS" },
  { label: defineMessage({ defaultMessage: "In the next 6 months" }), value: "NEXT_SIX_MONTHS" },
  { label: defineMessage({ defaultMessage: "I am not sure" }), value: "NOT_SURE" }
]

export default function IvfTreatmentStartStep(): JSX.Element {
  const intl = useIntl()
  const radioOptions = useConvertTranslatedLabel(OPTIONS)
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.ivfTreatmentStart
  const { send } = useStateMachine(defaultValue ? "" : null)

  async function onClickContinue(formValues: { ivfTreatmentStart: string }): Promise<void> {
    await mutateAsync(formValues, { onSuccess: () => send("") })
  }

  const header = intl.formatMessage({
    defaultMessage: "When are you starting fertility testing?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="ivfTreatmentStart-label">{header}</span>}
        description={
          <Typography>
            <FormattedMessage defaultMessage="This includes your initial consultation, bloodwork, ultrasound, or hysteroscopy. Your best estimate is fine." />
          </Typography>
        }
        formElements={
          <>
            <Form defaultValues={{ ivfTreatmentStart: defaultValue }} onSubmit={onClickContinue}>
              <FormRadioButtonGroup
                name="ivfTreatmentStart"
                aria-labelledby="ivfTreatmentStart-label"
                options={radioOptions}
                controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
              />
              <ButtonFooterLayout
                continueButton={
                  <ContinueButton>
                    <FormattedMessage defaultMessage="Continue" />
                  </ContinueButton>
                }
              />
            </Form>
          </>
        }
      />
    </>
  )
}
