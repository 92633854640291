import React, { useContext } from "react"

interface FeatureToggleContextProviderProps {
  children: React.ReactNode | React.ReactNodeArray
}

// We currently lack any toggles of this kind. Before adding a new toggle here,
// consider using LaunchDarkly instead. Eventually, we will likely remove this
// infrastructure entirely in favor of LaunchDarkly flags.
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UseFeatureToggleReturn {}

const FeatureToggleContext = React.createContext(null)

export const useFeatureToggle = (): UseFeatureToggleReturn => useContext(FeatureToggleContext)

export function FeatureToggleContextProvider({ children }: FeatureToggleContextProviderProps): JSX.Element {
  return <FeatureToggleContext.Provider value={{}}>{children}</FeatureToggleContext.Provider>
}
