export enum reimbursementCategoryNameMessageKeys {
  ADOPTION_AND_GC = "ADOPTION_AND_GC",
  ADOPTION = "ADOPTION",
  DEFAULT = "DEFAULT",
  FERTILITY = "FERTILITY",
  FERTILITY_AND_PRESERVATION = "FERTILITY_AND_PRESERVATION",
  GAC = "GAC",
  GC = "GC",
  M_LT = "M_LT",
  M_LT_GAC = "M_LT_GAC",
  MEDICATION = "MEDICATION",
  OTHER = "OTHER",
  PREGNANCY = "PREGNANCY",
  PREGNANCY_M_LT = "PREGNANCY_M_LT",
  PRESERVATION = "PRESERVATION",
  QME = "QME"
}
