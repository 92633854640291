import {
  ListItemText,
  Stack,
  Tile,
  Typography,
  UnorderedList,
  UnorderedListItem,
  useTheme
} from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import React, { ReactElement } from "react"
import useGetMemberBenefit from "../carrot-plans/hooks/useGetMemberBenefit"

export default function PartnerAccessConsent({ isInvitationSent }: { isInvitationSent: boolean }): ReactElement {
  const theme = useTheme()
  const { data: memberBenefit } = useGetMemberBenefit()
  const isCarrotLite = memberBenefit?.program.isCarrotLite
  const proHelpText = (
    <FormattedMessage defaultMessage="To protect personal health information, your partner will not have access to financial support areas of the app or see your in-app messages." />
  )

  const liteHelpText = (
    <FormattedMessage defaultMessage="To protect personal health information, your partner will not see your in-app messages." />
  )

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      {isInvitationSent ? (
        <Typography>
          <FormattedMessage defaultMessage="Once they accept, they'll be able to sign in with their own email address and password." />
        </Typography>
      ) : (
        <Typography>
          <FormattedMessage defaultMessage="This allows them to sign in with their own email address and password." />
        </Typography>
      )}
      <Tile
        sx={{
          padding: (theme) => theme.spacing(theme.tokens.spacing.md)
        }}
      >
        <Typography>
          <FormattedMessage defaultMessage="Partners have access to:" />
        </Typography>
        <UnorderedList sx={{ paddingBlockEnd: 0 }}>
          <UnorderedListItem sx={{ paddingY: 0 }}>
            <ListItemText>
              <FormattedMessage defaultMessage="Educational resources" />
            </ListItemText>
          </UnorderedListItem>
          <UnorderedListItem sx={{ paddingY: 0 }}>
            <ListItemText>
              <FormattedMessage defaultMessage="Benefit details" />
            </ListItemText>
          </UnorderedListItem>
          <UnorderedListItem sx={{ paddingY: 0 }}>
            <ListItemText>
              <FormattedMessage defaultMessage="Personal information" />
            </ListItemText>
          </UnorderedListItem>
          <UnorderedListItem sx={{ paddingY: 0 }}>
            <ListItemText>
              <FormattedMessage defaultMessage="The Carrot Plan associated with your account" />
            </ListItemText>
          </UnorderedListItem>
          <UnorderedListItem sx={{ paddingY: 0 }}>
            <ListItemText>
              <FormattedMessage defaultMessage="Care Navigator and Medical Expert support" />
            </ListItemText>
          </UnorderedListItem>
        </UnorderedList>
      </Tile>
      <Typography fontWeight="bold">{isCarrotLite ? liteHelpText : proHelpText}</Typography>
    </Stack>
  )
}
