import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import {
  BenefitEnrollmentFlowModalBackButton,
  BenefitEnrollmentModalContinueButton,
  BenefitEnrollmentModalFormSelect
} from "../../shared"
import moment from "moment"
import { Container, H4 } from "@carrotfertility/carotene"
import { Typography, DatePicker } from "@carrotfertility/carotene-core"
import { useStateMachine } from "components/context/stateMachine/StateMachineContext"
import { Steps } from "components/cmd-enrollment/workflow/steps"
import useSubmitWithErrorCatch from "components/cmd-enrollment/hooks/useSubmitWithErrorCatch"
import { Form } from "components/views/atoms/forms/Form"
import { useCurrentUser } from "components/context/user/UserContext"
import { useSelector } from "react-redux"
import { getBenefitConfiguration } from "../../../../reducers/benefitConfiguration"
import { defineMessage, useIntl, MessageDescriptor, FormattedMessage } from "react-intl"

interface DobSexStepProps {
  defaultSex: string | null
  defaultDateOfBirth: string | null
  viewName: typeof Steps[keyof typeof Steps]
  onUserUpdate: (dateOfBirth: string, sex: string) => any
  selectionLabels: {
    heading: string
    dob: string
    sex: string
  }
}

const FieldNameSex = "sexValue"

export const enum SexOptions {
  MALE = "MALE",
  FEMALE = "FEMALE",
  INTERSEX = "INTERSEX"
}

const sexIdToLabel = {
  [SexOptions.MALE]: defineMessage({ defaultMessage: "Male" }),
  [SexOptions.FEMALE]: defineMessage({ defaultMessage: "Female" }),
  [SexOptions.INTERSEX]: defineMessage({ defaultMessage: "Intersex" })
}

export const getSexLabelFromId = (id: SexOptions): MessageDescriptor => sexIdToLabel[id]

function DobSexStep({
  defaultSex,
  defaultDateOfBirth,
  viewName,
  selectionLabels,
  onUserUpdate
}: DobSexStepProps): JSX.Element {
  const shouldSkip = defaultSex && defaultDateOfBirth
  const { setNextStep } = useStateMachine(viewName, shouldSkip ? "" : null)
  const formMethods = useForm()
  const { handleSubmit, setValue } = formMethods
  const intl = useIntl()

  const [dateOfBirth, setDateOfBirth] = useState(null)
  const [isDobError, setDobIsError] = useState(Boolean)
  const [dobHelperText, setDobHelperText] = useState("")

  const options = [
    { label: intl.formatMessage(getSexLabelFromId(SexOptions.MALE)), value: SexOptions.MALE },
    { label: intl.formatMessage(getSexLabelFromId(SexOptions.FEMALE)), value: SexOptions.FEMALE },
    {
      label: intl.formatMessage(getSexLabelFromId(SexOptions.INTERSEX)),
      value: SexOptions.INTERSEX,
      // @ts-expect-error TS7006
      filter: (c) => c.featureConfig.isLgtbqEnabled
    }
  ]

  useEffect(() => {
    setValue(
      FieldNameSex,
      options.find(({ value }) => value === defaultSex)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  // @ts-expect-error TS7006
  const { onSubmit } = useSubmitWithErrorCatch(async (args) => {
    if (isDobError || !dateOfBirth) {
      setDobIsError(true)
      setDobHelperText(intl.formatMessage({ defaultMessage: "Please enter a valid date" }))
      return
    }
    const sexVal = args[FieldNameSex].value
    const dobVal = dateOfBirth.format("YYYY-MM-DD")

    // We should try to use a hidden input / more native react-hook-forms methods after we refactor the dobSelector
    await onUserUpdate(dobVal, sexVal)
    setNextStep(viewName, "")
  })

  const handleDobChange = (newDate: any) => {
    if (!newDate) {
      setDobIsError(true)
      setDobHelperText(intl.formatMessage({ defaultMessage: "Please enter a valid date" }))
      return
    }
    setDobIsError(false)
    setDobHelperText(null)
    if (newDate.isAfter(moment().utc().subtract(18, "year"))) {
      setDobIsError(true)
      setDobHelperText(intl.formatMessage({ defaultMessage: "You must be at least 18 years old" }))
    }
    setDateOfBirth(newDate)
  }

  const benefitConfiguration = useSelector(getBenefitConfiguration)
  const filteredSexOptions = options.filter((c) => !c.filter || c.filter(benefitConfiguration))

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Container padding="none" stack="huge">
          <BenefitEnrollmentFlowModalBackButton />
        </Container>
        <Typography id="step-heading" hidden={true}>
          {selectionLabels.heading}
        </Typography>
        <Typography
          id={`step-heading-${FieldNameSex}`}
          variant="h2"
          color={(theme) => theme.palette.text.primary}
          paddingBottom={(theme) => theme.tokens.spacing.lg}
        >
          {selectionLabels.sex}
        </Typography>
        <H4 stack="tiny" variant="primary">
          <FormattedMessage defaultMessage="SEX" />
        </H4>
        <Container padding="none" stack="small">
          <BenefitEnrollmentModalFormSelect
            name={FieldNameSex}
            aria-labelledby={`step-heading-${FieldNameSex}`}
            options={filteredSexOptions}
          />
        </Container>
        <Typography
          variant="h2"
          color={(theme) => theme.palette.text.primary}
          paddingBottom={(theme) => theme.tokens.spacing.lg}
        >
          {selectionLabels.dob}
        </Typography>
        <Container padding="none" stack="giant">
          <DatePicker
            defaultValue={null}
            onChange={(date) => handleDobChange(date)}
            slotProps={{
              textField: {
                label: "Date of birth",
                helperText: dobHelperText,
                error: isDobError,
                required: true
              }
            }}
          />
        </Container>
        <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
      </Form>
    </FormProvider>
  )
}

export function MemberDobSexStep(): JSX.Element {
  const { sex: defaultSex, dateOfBirth: defaultDateOfBirth } = useCurrentUser()
  const { updateUserInfo } = useCurrentUser()
  const intl = useIntl()

  // @ts-expect-error TS7006
  function onUserUpdate(dateOfBirth, sex): any {
    return updateUserInfo({
      sex,
      dateOfBirth
    })
  }
  return (
    <DobSexStep
      selectionLabels={{
        heading: intl.formatMessage({ defaultMessage: "What is your sex and date of birth?" }),
        sex: intl.formatMessage({ defaultMessage: "Your sex, as stated on your health insurance plan" }),
        dob: intl.formatMessage({ defaultMessage: "Your date of birth, as stated on your health insurance plan" })
      }}
      viewName={Steps.MEMBER_DOB_SEX_CONNECT}
      {...{ defaultSex, defaultDateOfBirth, onUserUpdate }}
    />
  )
}

export function PartnerDobSexStep(): JSX.Element {
  const { partnerSex: defaultSex, partnerDateOfBirth: defaultDateOfBirth } = useCurrentUser()
  const { updateUserInfo } = useCurrentUser()
  const intl = useIntl()

  // @ts-expect-error TS7006
  function onUserUpdate(dateOfBirth, sex): any {
    return updateUserInfo({
      partnerSex: sex,
      partnerDateOfBirth: dateOfBirth
    })
  }
  return (
    <DobSexStep
      selectionLabels={{
        heading: intl.formatMessage({ defaultMessage: "What is your partner’s sex and date of birth?" }),
        sex: intl.formatMessage({ defaultMessage: "Your partner’s sex, as stated on their health insurance plan" }),
        dob: intl.formatMessage({
          defaultMessage: "Your partner’s date of birth, as stated on their health insurance plan"
        })
      }}
      viewName={Steps.PARTNER_DOB_SEX_CONNECT}
      {...{ defaultSex, defaultDateOfBirth, onUserUpdate }}
    />
  )
}
