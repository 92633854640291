import React, { useEffect, useState } from "react"
import { InAppMessagingThread } from "../../messaging/inAppMessagingTypes"

type useInAppMessagingReturn = {
  showOnlyThreadsList: boolean
  setShowOnlyThreadsList: React.Dispatch<boolean>
  showNewThreadForm: boolean
  setShowNewThreadForm: React.Dispatch<boolean>
  selectedThread: InAppMessagingThread | null
  setSelectedThread: React.Dispatch<InAppMessagingThread>
  threadUpdateInFlight: boolean
  setThreadUpdateInFlight: React.Dispatch<boolean>
  threadIdForFailedUpdate: number | null
  setThreadIdForFailedUpdate: React.Dispatch<number>
}

const InAppMessagingContext = React.createContext(null)

export const useInAppMessaging = (): useInAppMessagingReturn => React.useContext(InAppMessagingContext)

export function InAppMessagingContextProvider({ children }: { children: React.ReactElement }): JSX.Element {
  const [showOnlyThreadsList, setShowOnlyThreadsList] = useState(true)
  const [showNewThreadForm, setShowNewThreadForm] = useState(false)
  const [selectedThread, setSelectedThread] = useState(null)
  const [threadUpdateInFlight, setThreadUpdateInFlight] = useState(false)
  const [threadIdForFailedUpdate, setThreadIdForFailedUpdate] = useState(null)

  useEffect(() => {
    if (threadIdForFailedUpdate !== selectedThread?.threadId && threadIdForFailedUpdate !== null) {
      setThreadIdForFailedUpdate(null)
    }
  }, [selectedThread, showNewThreadForm, threadIdForFailedUpdate])

  return (
    <InAppMessagingContext.Provider
      value={{
        showOnlyThreadsList,
        setShowOnlyThreadsList,
        showNewThreadForm,
        setShowNewThreadForm,
        selectedThread,
        setSelectedThread,
        threadUpdateInFlight,
        setThreadUpdateInFlight,
        threadIdForFailedUpdate,
        setThreadIdForFailedUpdate
      }}
    >
      {children}
    </InAppMessagingContext.Provider>
  )
}
