import React from "react"
import { useCurrentUser } from "components/context/user/UserContext"
import { AboutThisExpenseWhatKindOfJourney } from "./WhatKindOfJourney"
import { defineMessage, MessageDescriptor, useIntl } from "react-intl"

type WhatKindOfFertilityCareOption = {
  label: MessageDescriptor
  id: string
}

export const fertilityCareOptions: WhatKindOfFertilityCareOption[] = [
  {
    id: "IUI",
    label: defineMessage({ defaultMessage: "Intrauterine insemination (IUI)" })
  },
  {
    id: "IVF",
    label: defineMessage({ defaultMessage: "In vitro fertilization (IVF)" })
  },
  {
    id: "PATHFINDING",
    label: defineMessage({ defaultMessage: "Other" })
  }
]

export const AboutThisExpenseWhatKindOfFertilityCare = (): JSX.Element => {
  const { company, hasMetSignatureRequirement } = useCurrentUser()
  const hasIvfRequirement = company?.hasIvfRequirements
  const intl = useIntl()
  const fertilityCareHeaderText = intl.formatMessage({
    defaultMessage: "What kind of fertility care is this related to?"
  })
  const fertilityCareBodyText = intl.formatMessage({
    defaultMessage: "If you're not sure, choose the option that seems like the best fit."
  })
  // if the user's company has an ivf requirement and the user has not met the signature requirement, we need to show them the IVF info
  const needsIvfInfo = hasIvfRequirement && !hasMetSignatureRequirement
  const formattedTypeOfFertilityCareOptions = fertilityCareOptions.map((typeOfFertilityCareOption) => ({
    ...typeOfFertilityCareOption,
    label: intl.formatMessage(typeOfFertilityCareOption.label)
  }))

  return (
    <>
      <AboutThisExpenseWhatKindOfJourney
        headerText={fertilityCareHeaderText}
        bodyText={fertilityCareBodyText}
        selectOptions={formattedTypeOfFertilityCareOptions}
        hasRequirements={needsIvfInfo}
      />
    </>
  )
}
