import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query"
import { carrotClient } from "../../../../utils/CarrotClient"
import buildCarrotApiRetryPolicy from "../../../../utils/CarrotApiRetryPolicy"

const SIGNATURE_OR_LINK_CLICK_EXISTS_QUERY_KEY = "signatureAndLinkClickExistsQueryKey"
export function useSignatureOrSurveyLinkClicksExists(): UseQueryResult<boolean> {
  return useQuery(
    [SIGNATURE_OR_LINK_CLICK_EXISTS_QUERY_KEY],
    () => carrotClient.getSignatureOrSurveyLinkClickExists(),
    {
      retry: buildCarrotApiRetryPolicy(3),
      refetchOnWindowFocus: false
    }
  )
}

export function useCreateSurveyLinkView(): UseMutationResult {
  return useMutation(async () => carrotClient.createSurveyLinkView(), {
    retry: false
  })
}

export function useCreateSurveyLinkClick(): UseMutationResult {
  return useMutation(async () => carrotClient.createSurveyLinkClick(), {
    retry: false
  })
}

export function useCreateSurveyBannerCloseClick(): UseMutationResult {
  return useMutation(async () => carrotClient.createSurveyBannerCloseClick(), {
    retry: false
  })
}
