import React, { useState } from "react"

type useCaseRateRenewalsReturn = {
  openGatedAccountModal: boolean
  // @ts-expect-error TS7051
  setOpenGatedAccountModal: (boolean) => void
  // @ts-expect-error TS7051
  setRequestError: (boolean) => void
  requestError: boolean
  // @ts-expect-error TS7051
  setDisableModalCloseOnLoading: (boolean) => void
  disableModalCloseOnLoading: boolean
}

export enum AdvancedCheckInEmailLinkQueryString {
  REFRESH_PLAN = "refresh-plan-email-link",
  KEEP_PLAN = "keep-plan-email-link"
}

export const AdvancedCheckInWarningQueryString = "advanced-check-in-warning"

const CaseRateRenewalsContext = React.createContext(null)
export const useCaseRateRenewals = (): useCaseRateRenewalsReturn => React.useContext(CaseRateRenewalsContext)

export function CaseRateRenewalsContextProvider({
  children,
  shouldCrrModalRender
}: {
  children: React.ReactElement
  shouldCrrModalRender: boolean
}): JSX.Element {
  const [requestError, setRequestError] = useState(false)
  const [openGatedAccountModal, setOpenGatedAccountModal] = useState(shouldCrrModalRender)
  const [disableModalCloseOnLoading, setDisableModalCloseOnLoading] = useState(false)

  return (
    <CaseRateRenewalsContext.Provider
      value={{
        openGatedAccountModal,
        setOpenGatedAccountModal,
        setRequestError,
        requestError,
        disableModalCloseOnLoading: disableModalCloseOnLoading,
        setDisableModalCloseOnLoading: setDisableModalCloseOnLoading
      }}
    >
      {children}
    </CaseRateRenewalsContext.Provider>
  )
}
