import React from "react"
import { Box, DialogTitle } from "@carrotfertility/carotene-core"

type CaseRateRenewalModalTopSectionProps = {
  showImage?: boolean
  imageSrc?: string
  imageAltText?: string
  header?: string
}

export function CaseRateRenewalModalTopSection({
  showImage = false,
  imageSrc = null,
  imageAltText = null,
  header = null
}: CaseRateRenewalModalTopSectionProps): JSX.Element {
  return (
    <Box>
      {showImage ? (
        <Box display="flex" justifyContent="center" padding={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
          <Box
            component="img"
            src={imageSrc}
            srcSet={`${imageSrc}-2x.png`}
            alt={`${imageAltText}.png`}
            maxHeight={"237px"}
            maxWidth={"100%"}
          />
        </Box>
      ) : null}
      <DialogTitle>{header}</DialogTitle>
    </Box>
  )
}
