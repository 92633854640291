import React from "react"
import { Box, Container, Typography, useTheme } from "@carrotfertility/carotene-core"

const scrollBarWidth = "15px"

const scrollFade = (
  <Box
    display={{ xs: "none", md: "block" }}
    position="absolute"
    bottom={0}
    height="50px"
    sx={{
      background: "linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%)"
    }}
    width={`calc(100% - ${scrollBarWidth})`}
  />
)

export const Frame = ({
  topGreyHeaderText,
  menu,
  children
}: {
  topGreyHeaderText: string
  menu: JSX.Element
  children: JSX.Element
}): JSX.Element => {
  const theme = useTheme()

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="space-between"
        paddingX={(theme) => ({
          xs: theme.spacing(theme.tokens.spacing.md),
          md: theme.spacing(theme.tokens.spacing.lg)
        })}
        paddingY={(theme) => ({
          xs: theme.spacing(theme.tokens.spacing.xs),
          md: theme.spacing(theme.tokens.spacing.lg)
        })}
        alignItems="center"
      >
        <Box paddingX={theme.spacing(theme.tokens.spacing.lg)}>
          <Typography variant="overline" color={theme.palette.text.tertiary}>
            {topGreyHeaderText}
          </Typography>
        </Box>
        {menu}
      </Box>
      <Box position="relative" overflow="hidden">
        <Box
          paddingX={(theme) => ({
            xs: theme.spacing(theme.tokens.spacing.md),
            md: theme.spacing(theme.tokens.spacing.xxxl)
          })}
          height={{ xs: "auto", md: "500px" }}
          sx={{ overflowY: "auto" }}
        >
          <Container maxWidth="md" disableGutters sx={{ marginX: 0 }}>
            {children}
          </Container>
        </Box>
        {scrollFade}
      </Box>
    </Box>
  )
}
