import React from "react"
import { useHistory } from "react-router-dom"
import Home from "../views/home/Home"
import { DoulaAttestationFormModal } from "./DoulaAttestationFormModal"

export function DoulaAttestationLandingPage(): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(true)
  const history = useHistory()
  const onClose = () => history.push("/")
  return (
    <>
      <Home />
      <DoulaAttestationFormModal open={isOpen} setOpen={setIsOpen} onClose={onClose} />
    </>
  )
}
