/* eslint formatjs/no-literal-string-in-jsx: "error" */
import React from "react"
import { useSelector } from "react-redux"
import { getReimbursementsList } from "reducers/reimbursements"
import { SingleCategoryReimbursementsList } from "./company-plan-config-type/SingleCategoryList"
import { MultipleCategoriesReimbursementsLists } from "./company-plan-config-type/MultipleCategoriesReimbursementsLists"
import { getSubsidies } from "../../../../reducers/subsidies"

const ReimbursementsList = () => {
  const reimbursements = useSelector((state) => ({
    reimbursementsList: getReimbursementsList(state)
  }))

  const { reimbursementsList } = reimbursements

  const subsidies = useSelector(getSubsidies)
  const hasMultipleCategories = subsidies?.length > 1

  return (
    <div>
      {subsidies &&
        reimbursements &&
        (hasMultipleCategories ? (
          <MultipleCategoriesReimbursementsLists reimbursementsList={reimbursementsList} subsidies={subsidies} />
        ) : (
          <SingleCategoryReimbursementsList reimbursementsList={reimbursementsList} subsidy={subsidies[0]} />
        ))}
    </div>
  )
}

export default ReimbursementsList
