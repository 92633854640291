import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { carrotClient } from "../../../utils/CarrotClient"
import buildCarrotApiRetryPolicy from "../../../utils/CarrotApiRetryPolicy"

/**
 * Returns list of locales enabled for the current employee's country
 */
export const useEnabledLocalesForCurrentUser = (): UseQueryResult<string[]> => {
  return useQuery<string[]>(
    ["useEnabledLocalesForCurrentUser"],
    () => {
      return carrotClient.getEnabledLocalesForCurrentUser()
    },
    {
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
