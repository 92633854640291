import { Provider } from "../types/Providers"

// @ts-expect-error TS7006
export function compareApproximateCenters(initialCenter, newCenter, precision = 2): boolean {
  return (
    initialCenter.lat.toFixed(precision) === newCenter.lat.toFixed(precision) &&
    initialCenter.lng.toFixed(precision) === newCenter.lng.toFixed(precision)
  )
}

// Group Carrot Partners towards the end of the array
export function sortByCarrotPartnerAscending(providerA: Provider, providerB: Provider): number {
  if (!providerA.carrotPartner && providerB.carrotPartner) {
    return -1
  }
  if (providerA.carrotPartner && !providerB.carrotPartner) {
    return 1
  }
  return 0
}

export function hideVirtualOnlyProvider(provider: Provider): boolean {
  return !!provider.location
}
