import { HttpStatusCodes } from "utils/HttpStatusCodes"

const HttpErrors = {
  BAD_REQUEST: "BAD_REQUEST",
  UNAUTHORIZED: "UNAUTHORIZED",
  FORBIDDEN: "FORBIDDEN",
  NOT_FOUND: "NOT_FOUND",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  CONFLICT: "CONFLICT",
  UNHANDLED_STATUS_CODE: "UNHANDLED_STATUS_CODE"
}

const StatusCodeToHttpErrorMapping = {
  [HttpStatusCodes.BAD_REQUEST]: HttpErrors.BAD_REQUEST,
  [HttpStatusCodes.UNAUTHORIZED]: HttpErrors.UNAUTHORIZED,
  [HttpStatusCodes.FORBIDDEN]: HttpErrors.FORBIDDEN,
  [HttpStatusCodes.NOT_FOUND]: HttpErrors.NOT_FOUND,
  [HttpStatusCodes.CONFLICT]: HttpErrors.CONFLICT,
  [HttpStatusCodes.INTERNAL_SERVER_ERROR]: HttpErrors.INTERNAL_SERVER_ERROR
}

const isHttpError = (error) => {
  return Object.values(HttpErrors).includes(error?.name)
}

async function parseErrorBody(error) {
  let bodyAsJson = null
  if (isHttpError(error) && error?.response) {
    try {
      const body = await error.response.clone().text()
      bodyAsJson = JSON.parse(body)
    } catch (error) {
      /* empty */
    }
  }
  return bodyAsJson
}

export { HttpErrors, StatusCodeToHttpErrorMapping, isHttpError, parseErrorBody }
