import React from "react"
import { attemptBiometricsCredentialsRetrieval, isMobileApp } from "lib/carrot-api/MobileNativeClient"
import { Controller, useFormContext } from "react-hook-form"
import { useToggle } from "utils/Hooks"
import { useBiometricAuthEnrollment } from "components/context/BiometricAuthEnrollment/BiometricAuthEnrollmentContext"
import {
  Typography,
  Alert,
  Stack,
  useTheme,
  Checkbox,
  FormControlLabel,
  Link,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  DialogTitle,
  Button
} from "@carrotfertility/carotene-core"
import { AppAccessPageCard, AppAccessPageCardFooter } from "components/access-page/AppAccessPageCard"
import { AppAccessPageLayout } from "components/access-page/AppAccessPageLayout"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { UnauthPreferredLocale } from "components/views/UnauthPreferredLocale"
import { FormButton, FormTextField } from "@carrotfertility/carotene-core-x"
import { useIntlValidator } from "utils/hooks/useIntlValidator"
import { emailValidation } from "utils/Regexes"
import ConditionalSiteMaintenanceAlert from "../components/site-maintenance/ConditionalSiteMaintenanceAlert"

interface LoginProps {
  errorMessage?: string
  showForgotPassword: boolean
  showEmailInput: boolean
  showPasswordInput: boolean
  submitting: boolean
  shake: boolean
  onSubmit: () => unknown
  passwordInputRef: React.MutableRefObject<undefined | HTMLInputElement>
  successfulBiometricAuthAction: () => void
  bioAuthLogin: typeof attemptBiometricsCredentialsRetrieval
  enrollInBioAuth: boolean
  samlConfig: SamlIdpConfig
  returnUrl: string
  redirectToSamlExternalLogin: (
    externalLoginUrl: string,
    entityId: string,
    employeeIsAdmin: boolean,
    returnUrl: string
  ) => void
}

type SamlIdpConfig = {
  samlExternalLoginUrl: string
  samlIdpEntityId: string
}

/**
 * For a11y of the sign in form fieldset
 */
function VisuallyHiddenText(props: React.PropsWithChildren<Record<string, unknown>>): JSX.Element {
  return (
    <Typography
      sx={{
        position: "absolute",
        insetInlineStart: "-9999px",
        inlineSize: "100px",
        blockSize: "auto",
        overflow: "hidden"
      }}
    >
      {props.children}
    </Typography>
  )
}

interface EmailInputProps {
  enabled: boolean
}

function EmailInput({ enabled }: EmailInputProps): JSX.Element {
  const intl = useIntl()
  const intlEmailValidation = useIntlValidator(emailValidation)

  return (
    <FormTextField
      disabled={!enabled}
      inputProps={{ readOnly: !enabled, disabled: false }}
      registerOptions={{
        required: intl.formatMessage({ defaultMessage: "Required" }),
        validate: intlEmailValidation
      }}
      label={intl.formatMessage({ defaultMessage: "Email" })}
      name="username"
      id="username"
    />
  )
}

function PasswordInput(): JSX.Element {
  const intl = useIntl()

  return (
    <FormTextField
      name="password"
      id="password"
      label={intl.formatMessage({ defaultMessage: "Password" })}
      type="password"
      registerOptions={{
        required: intl.formatMessage({ defaultMessage: "Required" })
      }}
    />
  )
}

function BioAuthCheckboxInput({ defaultChecked }: { defaultChecked: boolean }): JSX.Element {
  const [checked, toggleChecked] = useToggle(defaultChecked || false)
  const { control } = useFormContext()
  const intl = useIntl()

  return (
    <Controller
      control={control}
      name="setupBiometricAuth"
      render={({ field: { onChange, ref } }) => (
        <FormControlLabel
          control={
            <Checkbox
              inputRef={ref}
              checked={checked}
              onChange={(e) => {
                toggleChecked()
                onChange(e.target.checked)
              }}
              id="biometric-setup-checkbox"
            />
          }
          label={intl.formatMessage({ defaultMessage: "Set up biometric sign-in" })}
        />
      )}
    />
  )
}

function BiometricAuthEnrollmentInformationalModal({
  successfulBiometricAuthAction
}: Pick<LoginProps, "successfulBiometricAuthAction">): JSX.Element {
  const intl = useIntl()

  const { isBiometricAuthEnrollmentSuccessful } = useBiometricAuthEnrollment()
  const title = isBiometricAuthEnrollmentSuccessful
    ? defineMessage({ defaultMessage: "You're all set" })
    : defineMessage({ defaultMessage: "Something's not working" })
  const body = isBiometricAuthEnrollmentSuccessful
    ? defineMessage({ defaultMessage: "Biometric sign-in is now enabled." })
    : defineMessage({
        defaultMessage: "Please try again. If the problem persists, please check your device settings."
      })
  const actionText = isBiometricAuthEnrollmentSuccessful
    ? defineMessage({ defaultMessage: "Continue" })
    : defineMessage({ defaultMessage: "Skip for now" })

  return (
    <Dialog
      aria-labelledby="bioauth-enrollment-status-title"
      aria-describedby="bioauth-enrollment-status-description"
      open={true}
    >
      <DialogTitle id="bioauth-enrollment-status-title">{intl.formatMessage(title)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="bioauth-enrollment-status-description">{intl.formatMessage(body)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={successfulBiometricAuthAction}>{intl.formatMessage(actionText)}</Button>
      </DialogActions>
    </Dialog>
  )
}

export function SignIn({
  errorMessage,
  shake,
  showEmailInput,
  showForgotPassword,
  showPasswordInput,
  samlConfig,
  successfulBiometricAuthAction,
  bioAuthLogin,
  enrollInBioAuth,
  redirectToSamlExternalLogin,
  returnUrl
}: LoginProps): JSX.Element {
  const intl = useIntl()
  const {
    isBiometricAuthEnrollmentComplete,
    isBioauthCapable,
    hasBioAuthAlreadyBeenConfigured,
    isBioauthCapableLoading
  } = useBiometricAuthEnrollment()

  const actionText = showPasswordInput
    ? defineMessage({ defaultMessage: "Sign in" })
    : defineMessage({ defaultMessage: "Continue" })

  const showMobileBiometricEnrollmentCheckbox =
    isMobileApp() &&
    showPasswordInput &&
    !isBioauthCapableLoading &&
    isBioauthCapable &&
    !hasBioAuthAlreadyBeenConfigured
  const showMobileBiometricEnrollmentModal = isMobileApp() && isBiometricAuthEnrollmentComplete
  const showMobileBiometricSignInAction = isMobileApp() && hasBioAuthAlreadyBeenConfigured

  const authFooterLinks = React.useMemo(() => {
    const links = [
      {
        id: "access-page-sign-up-link",
        to: "/signup",
        linkText: intl.formatMessage({ defaultMessage: "New to Carrot? Sign up" })
      }
    ]
    if (showForgotPassword) {
      links.push({
        id: "access-page-forgot-password-link",
        to: "/request-reset-password",
        linkText: intl.formatMessage({ defaultMessage: "Forgot your password?" })
      })
    }
    return links
  }, [showForgotPassword, intl])

  const theme = useTheme()

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Sign in" })}>
      <AppAccessPageCard id="login-card" shake={shake}>
        <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          {errorMessage ? (
            <Alert severity="error">{errorMessage}</Alert>
          ) : (
            <Box textAlign="center">
              <Typography variant="h1">
                <FormattedMessage defaultMessage="Welcome back" />
              </Typography>
            </Box>
          )}
        </Box>

        <ConditionalSiteMaintenanceAlert />

        <Stack divider={<Divider />} spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <Stack
            spacing={theme.spacing(theme.tokens.spacing.lg)}
            sx={{ textAlign: "center" }}
            marginBottom={theme.spacing(theme.tokens.spacing.xl)}
          >
            <fieldset>
              <legend>
                <VisuallyHiddenText>
                  <FormattedMessage defaultMessage="Sign in" />
                </VisuallyHiddenText>
              </legend>
              <Stack textAlign={"left"} spacing={theme.spacing(theme.tokens.spacing.lg)}>
                {showEmailInput && <EmailInput enabled={!showPasswordInput} />}
                {showPasswordInput ? <PasswordInput /> : null}
              </Stack>
            </fieldset>
            {showMobileBiometricEnrollmentCheckbox || showMobileBiometricSignInAction ? (
              <Box display="grid">
                {showMobileBiometricEnrollmentCheckbox ? (
                  <BioAuthCheckboxInput defaultChecked={enrollInBioAuth} />
                ) : null}
                {showMobileBiometricSignInAction ? (
                  <Link
                    display="block"
                    component="button"
                    onClick={() => {
                      setTimeout(async () => {
                        await attemptBiometricsCredentialsRetrieval(bioAuthLogin)
                      }, 100)
                    }}
                  >
                    <FormattedMessage defaultMessage="Sign in with biometric ID" />
                  </Link>
                ) : null}
              </Box>
            ) : null}
            <Box display="block">
              <FormButton type="submit">{intl.formatMessage(actionText)}</FormButton>
            </Box>
            {samlConfig && (
              <div>
                <Button
                  variant="contained"
                  onClick={() =>
                    redirectToSamlExternalLogin(
                      samlConfig.samlExternalLoginUrl,
                      samlConfig.samlIdpEntityId,
                      false,
                      returnUrl
                    )
                  }
                >
                  <FormattedMessage defaultMessage="Sign in with SSO" />
                </Button>
              </div>
            )}
            <div>
              <UnauthPreferredLocale />
            </div>
          </Stack>
          <AppAccessPageCardFooter links={authFooterLinks} />
        </Stack>
        {showMobileBiometricEnrollmentModal ? (
          <BiometricAuthEnrollmentInformationalModal successfulBiometricAuthAction={successfulBiometricAuthAction} />
        ) : null}
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}
