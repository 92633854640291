import { MobileBridgeClient } from "./MobileBridgeClient"
import { initPushNotificationsGlobalCallback } from "./pushNotificationsControl"
import { getCarrotBridgePayload, initLoginGlobalCallbacks, updateMobileLoginControls } from "./loginControl"
import { initGlobalCallback } from "./mobileAppControl"

const appWindow: Record<string, any> = window

export class AndroidMobileBridgeClient implements MobileBridgeClient {
  static bridge = appWindow.CarrotJsBridge
  static isAndroidMobileClient = () => Boolean(AndroidMobileBridgeClient.bridge)

  constructor() {
    initLoginGlobalCallbacks()
    initPushNotificationsGlobalCallback()
    initGlobalCallback()
  }

  // @ts-expect-error TS7006
  helpCenter = (token, zdType, id) => {
    const payload = getCarrotBridgePayload(token, zdType, id)
    AndroidMobileBridgeClient.bridge.action(JSON.stringify(payload))
  }

  isBiometricsCapable = async () => AndroidMobileBridgeClient.bridge.isBiometricsCapable()

  isBiometricLoginAlreadyConfigured = () => AndroidMobileBridgeClient.bridge.isBiometricLoginAlreadyConfigured()

  disableBiometrics = () => AndroidMobileBridgeClient.bridge.disableBiometrics()

  attemptBiometricsCredentialsRetrieval = async (login: () => Promise<unknown>) => {
    updateMobileLoginControls("_login", login)
    updateMobileLoginControls("_bioAuthenticatedUsername", null)
    updateMobileLoginControls("_bioAuthenticatedPassword", null)

    AndroidMobileBridgeClient.bridge.attemptBiometricsCredentialsRetrieval()
  }

  showBiometricsEnrollment = (
    username: string,
    password: string,
    biometricEnrollmentSuccess: () => void,
    biometricEnrollmentFailure: () => void
  ) => {
    updateMobileLoginControls("_biometricEnrollmentSuccess", biometricEnrollmentSuccess)
    updateMobileLoginControls("_biometricEnrollmentFailure", biometricEnrollmentFailure)
    AndroidMobileBridgeClient.bridge.showBiometricsEnrollment(username, password)
  }

  getAppVersion = async () => AndroidMobileBridgeClient.bridge.initializeAppVersion()

  isPushPermissionsEnabled = async () => AndroidMobileBridgeClient.bridge.updatePushPermissionsEnabled()

  promptPushPermissions = async () => AndroidMobileBridgeClient.bridge.promptPushPermissions()

  setUserInfo = async (email: string, employeeId: number) => {
    return AndroidMobileBridgeClient.bridge.setUserInfo(email, employeeId)
  }
}
