import React, { FC, ReactNode } from "react"
import { TalkToCarrotChatHeader } from "./TalkToCarrotChatHeader"
import { SendMessageDescription } from "./send-message/useSendMessageTranslator"
import { Box, Typography } from "@carrotfertility/carotene-core"
import { PageLayout } from "features/global-ui/page-layout"
import { RRIconBar } from "components/views/atoms/roundedrectangle/RRIconBar"
import { FormattedMessage } from "react-intl"

type TalkToCarrotLayoutProps = {
  subtitle: string
  description: SendMessageDescription
  children: ReactNode
  sidebar?: ReactNode
}

const TalkToCarrotLayout: FC<TalkToCarrotLayoutProps> = ({
  subtitle,
  description,
  children,
  sidebar = <ChatSideBar />
}: TalkToCarrotLayoutProps) => (
  <PageLayout variant="sidebar" sidebar={sidebar} header={<TalkToCarrotChatHeader />}>
    <Box display="flex" flexDirection="column">
      <Typography
        variant="h2"
        color={(theme) => theme.palette.text.secondary}
        marginBottom={(theme) => theme.tokens.spacing.md}
      >
        {subtitle}
      </Typography>
      <Typography
        variant="body1"
        color={(theme) => theme.palette.text.secondary}
        marginBottom={(theme) => theme.tokens.spacing.xxxl}
      >
        {description}
      </Typography>
      {children}
    </Box>
  </PageLayout>
)

const ChatSideBar: FC = () => (
  <Box
    padding={(theme) => theme.tokens.spacing.lg}
    bgcolor={(theme) => theme.palette.background.default}
    borderRadius={(theme) => theme.tokens.borderRadius.md}
  >
    <RRIconBar iconName="icn-help" iconBackgroundColor="white" />
    <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
      <FormattedMessage defaultMessage="Carrot resources are for informational purposes only and do not constitute medical advice or professional services. They are not a substitute for legal, tax, or professional medical advice, diagnosis, or treatment. If you are experiencing a medical emergency, call emergency services immediately." />
    </Typography>
  </Box>
)

export { TalkToCarrotLayout }
