import { SupportedLocale } from "../types/Locale"

export const setOneTrustLocale = (locale: SupportedLocale) => {
  try {
    window.OneTrust?.changeLanguage(locale.oneTrustLanguageTag)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}
