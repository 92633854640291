import React from "react"
import { Link, Alert } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { FormCheckbox } from "@carrotfertility/carotene-core-x"

export function DoulaAttestationFormPartnerConfirmationAlert({
  anyPartnerOnMedicalPlanEligible
}: {
  anyPartnerOnMedicalPlanEligible: boolean
}) {
  const intl = useIntl()

  return (
    <>
      {anyPartnerOnMedicalPlanEligible && (
        <Alert>
          <FormattedMessage
            defaultMessage="Your partner must be enrolled in your employer’s medical plan to use funds for eligible services. <link>Visit your benefit guide</link> for more information."
            values={{
              link: (linkText) => (
                <Link color="inherit" target="_blank" href={"/your-benefit-guide/coverage/pregnancy-and-postpartum"}>
                  {linkText}
                </Link>
              )
            }}
          />
        </Alert>
      )}
      <FormCheckbox
        name="partnerConfirmation"
        controllerProps={{ rules: { required: "Required" } }}
        label={intl.formatMessage({ defaultMessage: "My partner is enrolled in my employer’s medical plan." })}
        required={true}
      />
    </>
  )
}
