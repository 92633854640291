import React from "react"
import { Box, BoxProps, useMediaQuery, useTheme } from "@carrotfertility/carotene-core"
import { UserDropdown } from "../user-dropdown"
import { PartnerAccessAccountBanner } from "components/partner-access/PartnerAccessBanner"
import { useUserRole } from "components/partner-access/hooks/usePartnerAccess"

type PageLayoutProps = {
  children: React.ReactNode | React.ReactNode[]
  footer?: React.ReactNode
  header?: React.ReactNode
} & ({ variant?: "sidebar"; sidebar?: React.ReactNode; sidebarDirection?: "start" | "end" } | { variant?: "default" })

const footerAdjustmentStyles: Pick<BoxProps, "display" | "flexDirection" | "height"> = {
  display: "flex",
  flexDirection: "column",
  height: "100%"
}

function getMainComponentFromVariant(variant: "sidebar" | "default"): React.FC | null {
  switch (variant) {
    case "sidebar":
      return BodyWithSidebarLayout
    case "default":
    default:
      return null
  }
}

export function BodyWithSidebarLayout({
  children,
  sidebar,
  direction = "end"
}: {
  direction?: "start" | "end"
  children: React.ReactNode | React.ReactNode[]
  sidebar: React.ReactNode
}): JSX.Element {
  const theme = useTheme()
  const switchToColumn = useMediaQuery(theme.breakpoints.down("lg"))
  function getDirection() {
    if (switchToColumn) {
      return direction === "end" ? "column" : "column-reverse"
    }
    return direction === "end" ? "row" : "row-reverse"
  }
  return (
    <Box
      gap={(theme) => theme.spacing(theme.tokens.spacing.xl)}
      display="flex"
      flexDirection={getDirection()}
      width="100%"
    >
      <Box width={switchToColumn ? "100%" : "66%"}>{children}</Box>
      <Box width={switchToColumn ? "100%" : "33%"}>{sidebar}</Box>
    </Box>
  )
}

export function PageLayout({ footer, header, children, variant = "default", ...variantProps }: PageLayoutProps) {
  const { isPartnerAccessingAccount } = useUserRole()
  const theme = useTheme()
  const showUserDropdown = useMediaQuery(theme.breakpoints.up("lg"))
  const mainComponent = getMainComponentFromVariant(variant)
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      marginX={(theme) => ({ xs: "16px", md: "32px", lg: theme.spacing(theme.tokens.spacing.xxxl) })}
    >
      <Box maxWidth={{ xl: "60.5rem", xs: "100vw" }} width="100%" {...(footer ? footerAdjustmentStyles : {})}>
        {showUserDropdown ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginBlockStart: (theme) => theme.tokens.spacing.xl
              }}
            >
              <UserDropdown />
            </Box>
            {isPartnerAccessingAccount && <PartnerAccessAccountBanner />}
            {header}
          </>
        ) : (
          <Box marginTop={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
            {isPartnerAccessingAccount && <PartnerAccessAccountBanner />}
            {header}
          </Box>
        )}
        <Box
          marginTop={(theme) => ({
            xs: theme.spacing(theme.tokens.spacing.lg),
            md: theme.spacing(theme.tokens.spacing.xl)
          })}
          marginBottom={(theme) => ({ xs: "6rem", md: theme.spacing(theme.tokens.spacing.xxxl) })}
        >
          <Box component={mainComponent} {...variantProps}>
            {children}
          </Box>
        </Box>
        {footer}
      </Box>
    </Box>
  )
}
