export default class ResponseError extends Error {
  name
  response
  statusCode
  correlationId
  constructor(response: Response, correlationId: string) {
    super()
    this.statusCode = response.status
    this.name = response.statusText
    this.response = response
    this.correlationId = correlationId
    Object.setPrototypeOf(this, ResponseError.prototype)
  }
}
