import { Theme, useMediaQuery } from "@carrotfertility/carotene-core"

export function useDigitalFrontDoorBreakpointViews() {
  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
  const isUnderXLScreenView = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"))
  const isAboveXLScreenView = useMediaQuery((theme: Theme) => theme.breakpoints.up("xl"))
  const smallerThanDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"))

  return {
    isMobileView: isMobileView,
    isUnderXLScreenView: isUnderXLScreenView,
    isAboveXLScreenView: isAboveXLScreenView,
    smallerThanDesktop: smallerThanDesktop
  }
}
