import React, { Dispatch } from "react"
import { getHeap } from "utils/heap"
import { StateMachineReducerAction, StateMachineReducerState, TransitionBase } from "./StateMachineTypes"

interface UseTrackingArgs {
  state: StateMachineReducerState
  dispatch: Dispatch<StateMachineReducerAction>
}

interface UseTrackingReturn {
  trackCurrentPageOnFirstTransition: () => void
  trackStepTransition: (selection: TransitionBase["given"]) => void
}

export function useTracking({ state, dispatch }: UseTrackingArgs): UseTrackingReturn {
  const trackPageView = React.useCallback(() => {
    getHeap().track(state?.present?.name, {
      EventName: state?.present?.sectionName,
      CurrentStep: state?.present?.name
    })
  }, [state?.present?.name, state?.present?.sectionName])

  const trackStepTransition = React.useCallback(
    (selection: TransitionBase["given"]) => {
      if (state?.startTrackingPageViews) {
        getHeap().track("Action: " + state?.present?.name, {
          EventName: state?.present?.sectionName,
          CurrentStep: state?.present?.name,
          ActionTakenByMember: (!state?.present?.decisionStep).toString(),
          Selection: selection
        })
      }
    },
    [state?.present?.decisionStep, state?.present?.name, state?.present?.sectionName, state?.startTrackingPageViews]
  )

  const trackCurrentPageOnFirstTransition = React.useCallback(() => {
    if (!state?.startTrackingPageViews) {
      trackPageView()
    }
  }, [state?.startTrackingPageViews, trackPageView])

  React.useEffect(() => {
    const timeout =
      !state?.startTrackingPageViews &&
      setTimeout(() => {
        dispatch({ type: "START_TRACKING" })
      }, 5000)
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [dispatch, state?.startTrackingPageViews, trackPageView])

  React.useEffect(() => {
    if (state?.startTrackingPageViews && !state?.present?.decisionStep) {
      trackPageView()
    }
  }, [state?.present, state?.startTrackingPageViews, trackPageView])

  return {
    trackCurrentPageOnFirstTransition,
    trackStepTransition
  }
}
