import {
  Dialog,
  Box,
  IconButton,
  CloseIcon,
  DialogContent,
  DialogContentText,
  Stack,
  useTheme,
  Alert
} from "@carrotfertility/carotene-core"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SupportedLocale } from "../../../types/Locale"

const NonEnglishPreferredLocaleDialogText = ({
  // eslint-disable-next-line no-restricted-syntax
  preferredLocale
}: {
  // eslint-disable-next-line no-restricted-syntax
  preferredLocale: SupportedLocale
}): React.ReactElement => {
  const theme = useTheme()

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
      <Stack>
        <FormattedMessage
          defaultMessage="You've changed your language to {selectedLanguage}. Your Carrot app and communications will be in {selectedLanguage}. You can update preferred language in your account settings."
          // eslint-disable-next-line no-restricted-syntax -- Message shown to a user when they update their preferredLocale on unauthenticated pages. Carrot-app's LocaleService will then determine if they can use the selected language once authenticated.
          values={{ selectedLanguage: preferredLocale.displayName }}
          description="{selectedLanugage} contains the translated name of the language the user has just selected"
        />
      </Stack>
      <Stack>
        <Alert>
          <FormattedMessage
            defaultMessage="Please note: Language options vary by location. Your Carrot experience may return to {englishUs} once your account is created."
            values={{ englishUs: SupportedLocale.English_UnitedStates.displayName }}
            description="{englishUs} is the translated value of 'English (U.S.)'"
          />
        </Alert>
      </Stack>
    </Stack>
  )
}

export const PreferredLocaleChangedDialog = ({
  // eslint-disable-next-line no-restricted-syntax -- This is the user's preferredLocale based on their selection in the language menu on registration pages
  preferredLocale,
  modalOpen,
  setModalOpen
}: {
  // eslint-disable-next-line no-restricted-syntax
  preferredLocale: SupportedLocale
  modalOpen: boolean
  setModalOpen: React.Dispatch<boolean>
}): JSX.Element => {
  const intl = useIntl()

  function handleModalClose() {
    setModalOpen(false)
  }

  return (
    <Dialog aria-describedby="language-selection-info-modal-description" open={modalOpen} maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={handleModalClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <DialogContentText id="language-selection-info-modal-description">
          {
            // eslint-disable-next-line no-restricted-syntax -- Message shown to a user when they update their preferredLocale to English on registration pages
            preferredLocale === SupportedLocale.English_UnitedStates ? (
              <FormattedMessage
                defaultMessage="You've changed your language to {englishUs}. Your Carrot app and communications will be in {englishUs}. You can update preferred language in your account settings."
                values={{ englishUs: SupportedLocale.English_UnitedStates.displayName }}
                description="{englishUs} is the translated value of 'English (U.S.)'"
              />
            ) : (
              // eslint-disable-next-line no-restricted-syntax
              <NonEnglishPreferredLocaleDialogText preferredLocale={preferredLocale} />
            )
          }
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
