import { FlexContainer, Stack } from "@carrotfertility/carotene"
import { BenefitEnrollmentModalContinueButton } from "../shared"
import { FlexRowCenter } from "../../views/atoms/Atoms"
import { IlloUnderwaterPlants } from "../../views/atoms/Illustrations"
import React, { useContext, useEffect } from "react"
import { Steps } from "../workflow/steps"
import { getHeap } from "../../../utils/heap"
import { SendMessageFormContext } from "../../talk-to-carrot/send-message/SendMessage"
import { useFormContext } from "react-hook-form"
import { useStateMachine } from "components/context/stateMachine/StateMachineContext"
import { Button, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { MessageSent } from "./MessageSent"

const MemberEmdIneligible = (): JSX.Element => {
  const { onSubmit, sent, employeeEmail, personalEmail } = useContext(SendMessageFormContext)
  const { gotoPreviousStep } = useStateMachine(viewName, null)
  const { register, setValue } = useFormContext()
  const sendSupportRequest = (): void => {
    onSubmit()
    getHeap().track("EnrollmentFlowSupportRequest", { EventName: "Member EMD Ineligible" })
  }
  const email = personalEmail ?? employeeEmail
  const intl = useIntl()
  const body = personalEmail
    ? intl.formatMessage(
        { defaultMessage: "We'll look into this and get back to you at {email}, your preferred email" },
        { email: email }
      )
    : intl.formatMessage(
        { defaultMessage: "We'll look into this and get back to you at {email}, your account email" },
        { email: email }
      )

  useEffect(() => {
    setValue("defaultMessage", "This member is not on their employer's health plan.")
    setValue("email", email)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return sent ? (
    <MessageSent close={true} body={body} header={intl.formatMessage({ defaultMessage: "We'll take it from here." })} />
  ) : (
    <Stack>
      <FlexRowCenter>
        <IlloUnderwaterPlants />
      </FlexRowCenter>
      <Typography id="step-heading" variant="h2" color={(theme) => theme.palette.text.primary}>
        <FormattedMessage defaultMessage="It looks like you may not be eligible for Carrot." />
      </Typography>
      <Typography color={(theme) => theme.palette.text.secondary}>
        <FormattedMessage defaultMessage="You need to be enrolled in your employer's health insurance plan to use Carrot. If you are, please edit your answer to the previous question." />
      </Typography>
      <input hidden {...register("email")} />
      <input hidden {...register("defaultMessage")} />
      <FlexContainer justifyContent="center" gap="small">
        <Button variant="outlined" color="secondary" onClick={gotoPreviousStep}>
          <FormattedMessage defaultMessage="Edit your answer" />
        </Button>
        <Button variant="contained" onClick={sendSupportRequest}>
          <FormattedMessage defaultMessage="Get help" />
        </Button>
      </FlexContainer>
    </Stack>
  )
}

const viewName = Steps.PARTNER_EMD_INELIGIBLE

const PartnerEmdIneligible = (): JSX.Element => {
  const { setNextStep } = useStateMachine(viewName, null)
  return (
    <Stack>
      <FlexRowCenter>
        <IlloUnderwaterPlants />
      </FlexRowCenter>
      <Typography id="step-heading" variant="h2" color={(theme) => theme.palette.text.primary}>
        <FormattedMessage defaultMessage="It looks like your partner's expenses aren't eligible." />
      </Typography>
      <Typography color={(theme) => theme.palette.text.secondary}>
        <FormattedMessage defaultMessage="They need to be on your employer's health plan in order to use Carrot." />
      </Typography>
      <BenefitEnrollmentModalContinueButton onClick={() => setNextStep(viewName, "")} />
    </Stack>
  )
}

export { MemberEmdIneligible, PartnerEmdIneligible }
