import Settings from "../lib/Settings"
import { useUserContext } from "../context/UserContext"
import { useMemo } from "react"
import { CarrotBenefitClient } from "../lib/CarrotBenefitClient"

export const useCarrotBenefitClient = (): CarrotBenefitClient => {
  const { handleSessionExpiration } = useUserContext()

  if (!handleSessionExpiration) {
    throw new Error("Missing handleSessionExpiration")
  }

  return useMemo(() => {
    return new CarrotBenefitClient(Settings.CARROT_BACKEND_URL, handleSessionExpiration)
  }, [handleSessionExpiration])
}
