import { fetchWrapper } from "../FetchWrapper/FetchWrapper"
import Settings from "../../utils/CarrotConfig"

const getCheckout = async () => {
  const headers = new Headers()
  headers.append("Content-Type", "application/json")
  const response = await fetchWrapper(`${Settings.CARROT_BACKEND_URL}/api/v2/ava/get-checkout-url`, {
    method: "GET",
    headers,
    credentials: "include"
  })
  const url = await response.text()
  return url
}

export { getCheckout }
