import { WelcomeToCarrotStep } from "./steps/WelcomeToCarrotStep"
import { CreatePasswordStep } from "./steps/CreatePasswordStep"
import { TermsConsentStep } from "./steps/TermsConsentStep"
import { DataConsentStep } from "./steps/DataConsentStep"
import { EmailOptInStep } from "./steps/EmailOptInStep"
import { RegistrationCompleteStep } from "./steps/RegistrationCompleteStep"

export enum Steps {
  WELCOME_TO_CARROT = "Partner welcome back intro step",
  CREATE_PASSWORD = "Partner create a password step",
  TERMS_CONSENT = "Partner terms consent step",
  DATA_CONSENT = "Partner data consent step",
  EMAIL_OPT_IN = "Partner mail opt in step",
  REGISTRATION_COMPLETED = "Partner registration completed step"
}

// prettier-ignore
const partnerRegistrationSteps = [
    { name: Steps.WELCOME_TO_CARROT,        component: WelcomeToCarrotStep },
    { name: Steps.CREATE_PASSWORD,          component: CreatePasswordStep },
    { name: Steps.TERMS_CONSENT,            component: TermsConsentStep },
    { name: Steps.DATA_CONSENT,             component: DataConsentStep },
    { name: Steps.EMAIL_OPT_IN,             component: EmailOptInStep },
    {name: Steps.REGISTRATION_COMPLETED,    component: RegistrationCompleteStep}
]

// prettier-ignore
const partnerRegistrationFlow = [
    { at: Steps.WELCOME_TO_CARROT,          given: "",                  goto: Steps.CREATE_PASSWORD },
    { at: Steps.CREATE_PASSWORD,            given: "BACK",              goto: Steps.WELCOME_TO_CARROT },
    { at: Steps.CREATE_PASSWORD,            given: "",                  goto: Steps.TERMS_CONSENT },
    { at: Steps.TERMS_CONSENT,              given: "BACK",              goto: Steps.CREATE_PASSWORD },
    { at: Steps.TERMS_CONSENT,              given: "",                  goto: Steps.DATA_CONSENT },
    { at: Steps.DATA_CONSENT,               given: "BACK",              goto: Steps.TERMS_CONSENT },
    { at: Steps.DATA_CONSENT,               given: "EMAIL_OPT_IN",      goto: Steps.EMAIL_OPT_IN },
    { at: Steps.EMAIL_OPT_IN,               given: "BACK",              goto: Steps.DATA_CONSENT },
    { at: Steps.EMAIL_OPT_IN,               given: "",                  goto: Steps.REGISTRATION_COMPLETED },
    { at: Steps.DATA_CONSENT,               given: "",                  goto: Steps.REGISTRATION_COMPLETED }
]

export enum Workflows {
  PARTNER_REGISTRATION = "PartnerRegistration"
}

const partnerRegistrationWorkflows = [{ name: Workflows.PARTNER_REGISTRATION, workflow: partnerRegistrationFlow }]

export const partnerRegistrationSection = {
  name: "PartnerRegistration",
  initialStep: Steps.WELCOME_TO_CARROT,
  desiredWorkflow: Workflows.PARTNER_REGISTRATION,
  steps: partnerRegistrationSteps,
  workflows: partnerRegistrationWorkflows
}
