import React, { FC, ImgHTMLAttributes } from "react"

export type RetinaImageProps = {
  name: string
  className?: string
} & ImgHTMLAttributes<HTMLImageElement>

function isFullUrl(name: string) {
  return name.includes("//") && name.includes(".")
}

const RetinaImage: FC<RetinaImageProps> = ({ name, height, width, className, alt = "" }: RetinaImageProps) => (
  <img
    className={className}
    src={isFullUrl(name) ? name : require(`../resources/images/${name}.png`)}
    srcSet={isFullUrl(name) ? name : require(`../resources/images/${name}-2x.png`)}
    alt={alt}
    height={height}
    width={width}
    style={{
      boxSizing: "content-box"
    }}
  />
)
export { RetinaImage }
