// eslint-disable-next-line no-restricted-imports
import { getCarrotUserId, getEmployeeId, getIsPosingAsMember, getPreferredLocale } from "reducers/userInfo"
import { getCompanyId, getCountryCode, getIsExcludedFromMetrics, getParentCompanyId } from "reducers/companyInfo"
import { Middleware } from "redux"
import { DefaultRootState } from "react-redux"
import {
  getHeap,
  HeapBoolean,
  HeapPropertyValue,
  HeapUserPropertyName,
  setHeapPosingAsMember,
  unsetHeapPosingAsMember
} from "../utils/heap"

type HeapProperties = Partial<Record<HeapUserPropertyName, HeapPropertyValue>>

const heapUserPropertySelectorsToWatch = {
  [HeapUserPropertyName.EmployeeId]: getEmployeeId,
  [HeapUserPropertyName.CarrotUserId]: getCarrotUserId,
  [HeapUserPropertyName.CountryCode]: getCountryCode,
  [HeapUserPropertyName.CompanyId]: getCompanyId,
  [HeapUserPropertyName.ParentCompanyId]: getParentCompanyId,
  // @ts-expect-error TS7006
  [HeapUserPropertyName.CompanyIsExcludedFromMetrics]: (state) => HeapBoolean.from(getIsExcludedFromMetrics(state)),
  [HeapUserPropertyName.PreferredLocale]: getPreferredLocale
} as const

const detectStateChanges = (previousState: DefaultRootState, nextState: DefaultRootState): HeapProperties => {
  const modifiedValues: HeapProperties = {}
  const heapPropertiesNamesToWatch = Object.keys(heapUserPropertySelectorsToWatch)

  for (const heapPropertyName of heapPropertiesNamesToWatch) {
    // @ts-expect-error TS7053
    const selector = heapUserPropertySelectorsToWatch[heapPropertyName]
    const previousValue = selector(previousState)
    const nextValue = selector(nextState)

    if (previousValue !== nextValue && nextValue !== null) {
      // @ts-expect-error TS7053
      modifiedValues[heapPropertyName] = nextValue
    }
  }

  return modifiedValues
}

const addHeapUserProperties = (changedHeapProperties: HeapProperties): void => {
  const carrotUserId = changedHeapProperties[HeapUserPropertyName.CarrotUserId]
  if (carrotUserId) {
    getHeap().identify(carrotUserId.toString())
  }

  getHeap().addUserProperties(changedHeapProperties)

  if (process.env.NODE_ENV !== "production") {
    /* eslint-disable no-console */
    console.group("Heap properties updated:")
    console.log("Identity:", getHeap().identity)
    console.log("Added user properties:", changedHeapProperties)
    console.groupEnd()
    /* eslint-enable no-console */
  }
}

const addChangedHeapUserProperties = (changedHeapProperties: HeapProperties): void => {
  const haveAnyValuesChanged = Object.keys(changedHeapProperties).length > 0

  if (haveAnyValuesChanged) {
    addHeapUserProperties(changedHeapProperties)
  }
}

const heapMiddleware: Middleware = (store) => (next) => (action) => {
  const previousState: DefaultRootState = store.getState()
  const result = next(action)
  const nextState: DefaultRootState = store.getState()

  const isPosingAsMember = getIsPosingAsMember(nextState)

  if (isPosingAsMember) {
    const employeeId = getEmployeeId(nextState)
    setHeapPosingAsMember(employeeId)
  } else {
    unsetHeapPosingAsMember()

    const changedHeapProperties = detectStateChanges(previousState, nextState)
    addChangedHeapUserProperties(changedHeapProperties)
  }

  return result
}

export { heapMiddleware }
