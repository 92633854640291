import { defineMessage } from "react-intl"

export const NETWORK_ID_TIER_MAPPING = {
  5: {
    tierDisplayName: defineMessage({ defaultMessage: "Tier 1 Network" }),
    modalTitle: defineMessage({ defaultMessage: "Tier 1 network" }),
    modalDescription: defineMessage({
      defaultMessage:
        "This provider is a part of your health insurance plan’s Tier 1 network. Your chosen provider’s tier impacts your deductibles and how you pay for care."
    })
  },
  6: {
    tierDisplayName: defineMessage({ defaultMessage: "Tier 2 Network" }),
    modalTitle: defineMessage({ defaultMessage: "Tier 2 network" }),
    modalDescription: defineMessage({
      defaultMessage:
        "This provider is a part of your health insurance plan’s Tier 2 network. Your chosen provider’s tier impacts your deductibles and how you pay for care."
    })
  }
} as const
