import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, CloseIcon, Dialog, DialogContent, DialogContentText, IconButton } from "@carrotfertility/carotene-core"

// @ts-expect-error TS7031
export function LgbtqDisclaimerModal({ onExit }): JSX.Element {
  const intl = useIntl()

  return (
    <Dialog
      open={true}
      maxWidth="md"
      aria-labelledby="lgbtq-info-modal-title"
      aria-describedby="lgbtq-info-modal-description"
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onExit}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <DialogContentText id="lgbtq-info-modal-description">
          <FormattedMessage
            defaultMessage="Please be aware that laws and attitudes in some countries may affect your safety. 
            Legal protections vary from country to country. Many countries do not legally recognize same-sex marriage. 
            More than 60 countries consider consensual same-sex sexual relations a crime, sometimes carrying severe punishment. 
            This may be the case in your country of residence. 
            If you have any doubts about your fertility options, please check with a local attorney."
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
