import { useStateMachine } from "components/context/stateMachine/StateMachineContext"
import { Steps } from "components/cmd-enrollment/workflow/steps"
import React from "react"
import { Container, FlexContainer, TextLink } from "@carrotfertility/carotene"
import { IlloBlocks } from "../../../views/atoms/Illustrations"
import { BenefitEnrollmentModalContinueButton } from "../../shared"
import { useBenefitEnrollment } from "components/context/enrollment/BenefitEnrollmentContext"
import { FormattedMessage, useIntl } from "react-intl"
import { Typography, Box } from "@carrotfertility/carotene-core"

interface InformationalConnectionInsuranceStepProps {
  stepHeader: string
  stepBody: string[]
  viewName: typeof Steps[keyof typeof Steps]
}

function InformationalConnectionInsuranceStep({
  stepHeader,
  viewName,
  stepBody
}: InformationalConnectionInsuranceStepProps): JSX.Element {
  const { setNextStep } = useStateMachine(viewName, null)
  const { companyHasCMDEnabled } = useBenefitEnrollment()

  function onClickContinue(): void {
    setNextStep(viewName, "")
  }

  return (
    <div>
      <Box height={(theme) => theme.tokens.spacing.xxxl} />
      <FlexContainer justifyContent="center" padding="none" stack="medium">
        <IlloBlocks />
      </FlexContainer>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        {stepHeader}
      </Typography>
      <Container padding="none" stack="huge">
        {stepBody.map((part, index) => (
          <>
            {index ? <Box height={(theme) => theme.tokens.spacing.md} /> : <></>}
            <Typography color={(theme) => theme.palette.text.secondary} key={part}>
              {part}
            </Typography>
          </>
        ))}
      </Container>
      <FlexContainer padding="none" justifyContent={companyHasCMDEnabled ? "space-between" : "flex-end"}>
        {companyHasCMDEnabled && (
          <div>
            <TextLink onClick={(): void => setNextStep(viewName, "skip")}>
              <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
                <FormattedMessage defaultMessage="Skip section" />
              </Typography>
            </TextLink>
          </div>
        )}
        <BenefitEnrollmentModalContinueButton onClick={onClickContinue} />
      </FlexContainer>
    </div>
  )
}

export function InformationalConnectPartnerInsuranceStep(): JSX.Element {
  const intl = useIntl()
  return (
    <InformationalConnectionInsuranceStep
      viewName={Steps.PARTNER_INFO_CONNECT_INSURANCE}
      stepHeader={intl.formatMessage({
        defaultMessage: "Next, connect your Carrot account to your partner's health insurance provider."
      })}
      stepBody={[
        intl.formatMessage({
          defaultMessage:
            "Share a few details about your partner’s plan so we can track their progress toward their health insurance deductible."
        }),
        intl.formatMessage({
          defaultMessage:
            "Expenses applied to their health insurance deductible will also be credited to their deductible managed by Carrot."
        })
      ]}
    />
  )
}

export function InformationalConnectMemberInsuranceStep(): JSX.Element {
  const intl = useIntl()
  const { companyHasCMDEnabled } = useBenefitEnrollment()
  return (
    <InformationalConnectionInsuranceStep
      viewName={Steps.MEMBER_INFO_CONNECT_INSURANCE}
      stepHeader={intl.formatMessage({
        defaultMessage: "Next, connect your Carrot account with your health insurance provider."
      })}
      stepBody={
        companyHasCMDEnabled
          ? [
              intl.formatMessage({
                defaultMessage:
                  "Share a few details about your plan so we can track your progress toward your health insurance deductible."
              }),
              intl.formatMessage({
                defaultMessage:
                  "Expenses applied to your health insurance deductible will also be credited to your deductible managed by Carrot."
              })
            ]
          : [
              intl.formatMessage({
                defaultMessage: "Share a few details about your plan so we can talk to your insurance provider."
              }),
              intl.formatMessage({
                defaultMessage:
                  "Eligible qualified medical expenses you submit to Carrot will be applied to your health insurance deductible. This helps you meet your deductible faster."
              })
            ]
      }
    />
  )
}
