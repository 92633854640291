import { useLocation } from "react-router-dom"

type SpecialistChatMetadata = {
  country: string
  specialistType: string
  language: string
}

export const useGetSpecialistChatMetadata = (): SpecialistChatMetadata => {
  const { pathname } = useLocation()

  const pathArray = pathname.split("/")

  const country = pathArray[2].replaceAll("-", " ")
  // The specialistType can be 1 or 2 levels deep
  // 1 level: /talk-to-carrot/united-states-of-america/specialist/Emotional-support/english
  // 2 levels: /talk-to-carrot/united-states-of-america/specialist/fertility/Medical-questions/english
  const specialistType = pathArray.slice(4, -1).join("-").toLowerCase().replaceAll("-", " ")
  const language = pathArray.slice(-1).join("").toLowerCase().replaceAll("-", " ")

  return { country, specialistType, language }
}
