import React, { FC } from "react"
import { RoundedRectangle } from "../atoms/roundedrectangle/RoundedRectangle"
import { Spacer } from "../atoms/Atoms"
import { BodyLarge, BodySmall } from "../atoms/Bodies"
import { RRButtonFooter } from "../atoms/roundedrectangle/RRButtonFooter"
import { FormattedMessage } from "react-intl"

type BenefitEnrollmentModuleProps = {
  onClickEnroll: () => void
}

const BenefitEnrollmentModule: FC<BenefitEnrollmentModuleProps> = ({ onClickEnroll }) => {
  return (
    <RoundedRectangle id="home-sidebar-benefit-enrollment-module" backgroundColor="mint-alternate">
      <BodyLarge color="black-80">
        <FormattedMessage defaultMessage="To access your funds, tell us about your insurance" />
      </BodyLarge>
      <Spacer height={1} />
      <BodySmall color="black-80">
        <FormattedMessage defaultMessage="Your insurance coverage affects how Carrot funds are taxed and whether a deductible applies." />
      </BodySmall>
      <RRButtonFooter onClick={onClickEnroll} outline>
        <FormattedMessage defaultMessage="Share plan info" />
      </RRButtonFooter>
    </RoundedRectangle>
  )
}

export { BenefitEnrollmentModule }
