import actionTypes from "../actions/actionTypes"
import { combineReducers } from "redux"

const reducers = {}

reducers.persistLoginEmail = (state = null, action) => {
  switch (action.type) {
    case actionTypes.PERSIST_EMAIL:
      return { ...state, payload: action.payload }
    default:
      return state
  }
}

const loginInfo = combineReducers(reducers)

export const loginEmail = (state) => state.loginInfo.persistLoginEmail?.payload

export default loginInfo
