import React, { useContext } from "react"
import { SendMessageFormContext } from "components/talk-to-carrot/send-message/SendMessage"
import { FlexContainer, Stack } from "@carrotfertility/carotene"
import { BenefitEnrollmentModalContinueButton } from "../shared"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, Typography, useTheme } from "@carrotfertility/carotene-core"
import { PiiText } from "services/tracking"

interface MessageSentProps {
  close?: boolean
  onClick?: () => void
  header?: string
  body?: string
}

const MessageSent = (props: MessageSentProps) => {
  const { personalEmail, employeeEmail, partnerEmail, sentFromPartnerEmail } = useContext(SendMessageFormContext)
  const intl = useIntl()
  const { close = false, onClick = null, header = intl.formatMessage({ defaultMessage: "Received!" }), body } = props

  function getIntlBodyText(): any {
    if (sentFromPartnerEmail)
      return (
        <FormattedMessage
          defaultMessage="We'll get back to you at {email}, your partner's email."
          values={{
            email: <PiiText>{partnerEmail}</PiiText>
          }}
        />
      )
    else if (personalEmail)
      return (
        <FormattedMessage
          defaultMessage="We'll get back to you at {email}, your preferred email."
          values={{
            email: <PiiText>{personalEmail}</PiiText>
          }}
        />
      )
    else
      return (
        <FormattedMessage
          defaultMessage="We'll get back to you at {email}, your account email."
          values={{
            email: <PiiText>{employeeEmail}</PiiText>
          }}
        />
      )
  }
  const theme = useTheme()
  return (
    <Stack paddingTopBottom={theme.spacing(theme.tokens.spacing.md)} spacing={theme.spacing(theme.tokens.spacing.md)}>
      <Typography variant="h3">{header}</Typography>
      <Typography>{body ?? getIntlBodyText()}</Typography>
      {close ? (
        <FlexContainer justifyContent="center">
          <Button onClick={onClick}>
            <FormattedMessage defaultMessage="Close" />
          </Button>
        </FlexContainer>
      ) : (
        <BenefitEnrollmentModalContinueButton onClick={onClick} />
      )}
    </Stack>
  )
}

export { MessageSent }
