import { Divider, FlexContainer, H2 } from "@carrotfertility/carotene"
import useGetBankDetailsPageData from "components/bank-details/hooks/useGetBankDetailsPage"
import useShowBankDetails from "components/bank-details/hooks/useShowBankDetails"
import { PageNotFound } from "components/views/error/PageNotFound"
import React from "react"
import { Route } from "react-router"
import CountrySpecificBankDetails from "../../components/bank-details/CountrySpecificBankDetails"
import { IlloUnderwaterPlants } from "components/views/atoms/Illustrations"
import { ButtonPrimary } from "components/views/atoms/buttons/ButtonPrimary"
import EmployeeOnlySpaceContainer from "../../components/employee-only-containers/EmployeeOnlySpaceContainer"
import { FormattedMessage } from "react-intl"
import { PageLayout } from "features/global-ui/page-layout"
import { PageHeader, PageHeaderGlyph } from "features/global-ui/page-header"

const BankDetailsHeader = (): JSX.Element => (
  <PageHeader
    startGlyph={
      <PageHeaderGlyph src="/images/icn-header-bankdetails.png" srcSet={"/images/icn-header-bankdetails-2x.png"} />
    }
    pageTitle={<FormattedMessage defaultMessage="Bank details" />}
  />
)

const BankDetailsPage = () => {
  const { showBankDetails } = useShowBankDetails()
  const { data, schema, isError, isLoading } = useGetBankDetailsPageData()

  if (showBankDetails) {
    return (
      <PageLayout header={<BankDetailsHeader />}>
        <EmployeeOnlySpaceContainer>
          {isError ? (
            <SomethingWentWrong />
          ) : (
            <FlexContainer>
              <CountrySpecificBankDetails schema={schema} data={data} isLoading={isLoading} />
            </FlexContainer>
          )}
        </EmployeeOnlySpaceContainer>
      </PageLayout>
    )
  } else {
    return <Route component={PageNotFound} />
  }
}

export default BankDetailsPage

const SomethingWentWrong = () => {
  return (
    <div className={`flex-none br3 bg-pampas animate-all mw6-ns pv4`}>
      <div className="tc pa4">
        <IlloUnderwaterPlants />
        <Divider padding="8px" color="pampas" />
        <H2>
          <FormattedMessage defaultMessage="Something went wrong" />
        </H2>
        <Divider padding="8px" color="pampas" />
        <ButtonPrimary to="/">
          <FormattedMessage defaultMessage="Return home" />
        </ButtonPrimary>
      </div>
    </div>
  )
}
