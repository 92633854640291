import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import useAboutYou from "../../hooks/useAboutYou"
import { StepLayout } from "components/carrot-plans/shared/StepLayout"
import { ButtonFooterLayout, ContinueButton } from "components/carrot-plans/shared/ButtonFooter"
import { FormattedMessage, useIntl } from "react-intl"
import { dayjs } from "@carrotfertility/carotene-core"
import { Form, FormDatePicker } from "@carrotfertility/carotene-core-x"
import { validatePartnerDOB } from "services/common-forms"
import { RoutingFlowTitle } from "components/carrot-plans/shared"

export default function AboutYourPartnerDobStep(): JSX.Element {
  const intl = useIntl()
  const { data } = useAboutYou()
  const { send } = useStateMachine(data?.partnerDateOfBirth ? "" : null)
  const { updateAboutYou } = useAboutYou()
  async function onClickContinue({ partnerDateOfBirth }: { partnerDateOfBirth?: dayjs.Dayjs }): Promise<void> {
    await updateAboutYou({ partnerDateOfBirth: partnerDateOfBirth.format("YYYY-MM-DD") })
    send("")
  }
  const header = intl.formatMessage({
    defaultMessage: "When was your partner born?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={header}
        formElements={
          <Form
            defaultValues={{
              partnerDateOfBirth: dayjs(data?.partnerDateOfBirth)
            }}
            onSubmit={onClickContinue}
          >
            <FormDatePicker
              name="partnerDateOfBirth"
              controllerProps={{
                rules: {
                  required: intl.formatMessage({ defaultMessage: "Required" }),
                  validate: (value: dayjs.Dayjs) => {
                    const result = validatePartnerDOB(value)
                    if (typeof result !== "boolean") {
                      return intl.formatMessage(result)
                    }
                    return result
                  }
                }
              }}
              label={intl.formatMessage({ defaultMessage: "Partner's date of birth" })}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
