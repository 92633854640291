export enum MemberActions {
  PHONE_NUMBER_COLLECTION_MODAL_VIEWED = "PHONE_NUMBER_COLLECTION_MODAL_VIEWED",
  PHONE_NUMBER_COLLECTION_MODAL_DISMISSED = "PHONE_NUMBER_COLLECTION_MODAL_DISMISSED",
  PREGNANCY_MEDICAL_RECORD_CONSENT_SKIPPED = "PREGNANCY_MEDICAL_RECORD_CONSENT_SKIPPED",
  PREGNANCY_MEDICAL_RECORD_CONSENT_REVIEWED_SIGNED = "PREGNANCY_MEDICAL_RECORD_CONSENT_REVIEWED_SIGNED",
  PREGNANCY_MEDICAL_RECORD_CONSENT_REVIEWED_FINISH_LATER = "PREGNANCY_MEDICAL_RECORD_CONSENT_REVIEWED_FINISH_LATER",
  PREGNANCY_MEDICAL_RECORD_CONSENT_REVIEWED_DECLINED = "PREGNANCY_MEDICAL_RECORD_CONSENT_REVIEWED_DECLINED",
  PREGNANCY_MEDICAL_RECORD_CONSENT_REVIEWED_NOT_SIGNED_OTHER = "PREGNANCY_MEDICAL_RECORD_CONSENT_REVIEWED_NOT_SIGNED_OTHER",
  READ_AND_LEARN_VIEWED = "READ_AND_LEARN_VIEWED",
  DIGITAL_BENEFITS_GUIDE_VIEWED = "DIGITAL_BENEFITS_GUIDE_VIEWED",
  REGISTRATION_WELCOME_CONTINUE = "REGISTRATION_WELCOME_CONTINUE",
  SDOH_FINDHELP_LINK_CLICKED = "SDOH_FINDHELP_LINK_CLICKED"
}
