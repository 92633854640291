import React, { useContext, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { getShouldShowOptionalInsuranceInfoPromo } from "../../../derivedSelectors"
import { CMD_CONFIGURATION, useUserDeductible } from "../user/DeductibleContext"
import { getPartnerHasSuccessfullySyncedInsurance } from "../../../reducers/userInfo"

const ConnectInsuranceContext = React.createContext({
  showOptionalInsuranceInfoPromo: false,
  hideOptionalInsuranceInfoPromo: () => {}
})

export const useConnectInsurance = () => useContext(ConnectInsuranceContext)

// @ts-expect-error TS7031
export function ConnectInsuranceContextProvider({ children }) {
  const showOptionalInsuranceInfoPromo = useSelector(getShouldShowOptionalInsuranceInfoPromo)
  const partnerHasSuccessfullySyncedInsurance = useSelector(getPartnerHasSuccessfullySyncedInsurance)
  const deductibleContext = useUserDeductible()
  const [forceHideOptionalInsuranceInfoPromo, setForceHideOptionalInsuranceInfoPromo] = useState(false)
  function hideOptionalInsuranceInfoPromo() {
    setForceHideOptionalInsuranceInfoPromo(true)
  }
  const showAddInsuranceInfo = useMemo(() => {
    // on page load deductible context is null the first time this is executed
    // which shouldn't be the case but we have this null check here for now.
    if (deductibleContext === null) return false
    if (
      deductibleContext.cmdConfiguration === CMD_CONFIGURATION.PARTNER_ONLY &&
      partnerHasSuccessfullySyncedInsurance
    ) {
      return false
    }
    if (
      (deductibleContext.cmdConfiguration === CMD_CONFIGURATION.MEMBER_AND_PARTNER_SEPARATE ||
        deductibleContext.cmdConfiguration === CMD_CONFIGURATION.PARTNER_ONLY) &&
      !partnerHasSuccessfullySyncedInsurance
    ) {
      return true
    }
    return showOptionalInsuranceInfoPromo
  }, [showOptionalInsuranceInfoPromo, deductibleContext, partnerHasSuccessfullySyncedInsurance])

  return (
    <ConnectInsuranceContext.Provider
      value={{
        showOptionalInsuranceInfoPromo: !forceHideOptionalInsuranceInfoPromo && showAddInsuranceInfo,
        hideOptionalInsuranceInfoPromo
      }}
    >
      {children}
    </ConnectInsuranceContext.Provider>
  )
}
