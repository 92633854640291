import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "../types/ResponseError"
import { useProviderFinderClient } from "./useProviderFinderClient"
import { HasVirtualProvidersResponse } from "../types/HasVirtualProvidersResponse"

const HAS_VIRTUAL_PROVIDERS_QUERY_KEY = "GetHasVirtualProviders"

export function useGetHasVirtualProviders(
  companyCountryCode: string
): UseQueryResult<HasVirtualProvidersResponse, ResponseError> {
  const client = useProviderFinderClient()

  return useQuery<HasVirtualProvidersResponse, ResponseError>(
    [HAS_VIRTUAL_PROVIDERS_QUERY_KEY, companyCountryCode],
    async () => {
      const response = await client.getHasVirtualProviders(companyCountryCode)
      return response ? response : null
    },
    {
      refetchOnWindowFocus: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
