import React from "react"
import useCarrotPlanEmployeeSettings from "../carrot-plans/hooks/useCarrotPlanEmployeeSettings"
import { useHistory } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { Box, Typography, Button } from "@carrotfertility/carotene-core"
import { useStartRouting } from "../carrot-plans/shared/useQueryStartRouting"
import { useStartRoutingInfo } from "../carrot-plans/shared/useQueryRoutingInfo"
import { caseRateRenewalRoutingFlowUrl } from "../carrot-plans/case-rate-renewals/workflow"
import { CarrotPlansPaths } from "../../utils/Paths"

export function ContinueCarrotPlanHero(): JSX.Element {
  const { startRouting } = useStartRouting(useStartRoutingInfo(), caseRateRenewalRoutingFlowUrl)
  const { isDoneForNow } = useCarrotPlanEmployeeSettings()
  const history = useHistory()

  async function continueRouting(): Promise<Promise<void> | void> {
    if (isDoneForNow) {
      await startRouting()
    } else {
      history.push(CarrotPlansPaths.ENROLLMENT)
    }
  }
  return (
    <Box
      component="section"
      bgcolor="#15415b"
      color={(theme) => theme.palette.secondary.contrastText}
      gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      display="flex"
      flexWrap="wrap-reverse"
      padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      borderRadius={(theme) => theme.tokens.borderRadius.md}
    >
      <Box flex="1">
        <Typography
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxs)}
          variant="h3"
          component="h2"
          color="inherit"
        >
          <FormattedMessage defaultMessage="Your Carrot Plan" />
        </Typography>
        <Typography marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)} color="inherit">
          <FormattedMessage defaultMessage="Continue telling us about your journey so we can create your Carrot Plan." />
        </Typography>
        <Button onClick={continueRouting}>
          <FormattedMessage defaultMessage="Continue" />
        </Button>
      </Box>
      <Box alt="" component="img" src="/images/illo-underwater-checklist-2x.png" height="14.25rem" width="14.3125rem" />
    </Box>
  )
}
