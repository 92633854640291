enum CarrotRoute {
  Home = "/",
  Transactions = "/transactions",
  IvfRequirements = "/reimbursements/ivf-requirements",
  IvfRequirementsSigned = "/reimbursements/ivf-requirements-signed/"
}

enum CarrotQueryParam {
  Signed = "signed"
}

export { CarrotRoute, CarrotQueryParam }
