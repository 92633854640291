import React from "react"
import ReadLearnLandingPageLayout from "./ReadLearnLandingPageLayout"
import { FullScreenLoading } from "./shared/FullScreenLoading"
import useGetCategories from "./shared/useGetCategories"
import { ErrorPage } from "../views/app/ErrorPage"

export default function ReadLearnLandingPage(): JSX.Element {
  const { data: categories, isLoading, isError } = useGetCategories()

  if (isError) {
    return <ErrorPage />
  }
  return isLoading ? <FullScreenLoading /> : <ReadLearnLandingPageLayout categories={categories} />
}
