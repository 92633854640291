import Locations from "components/views/home/navigation"
import React from "react"
import { useHistory } from "react-router-dom"
import { useIntl } from "react-intl"
import { Alert, Box, Button, Stack, useTheme } from "@carrotfertility/carotene-core"

const InsuranceExpired = (): JSX.Element => {
  const intl = useIntl()
  const history = useHistory()
  const buttonLabel = intl.formatMessage({ defaultMessage: "Update health plan" })
  const warningHeader = intl.formatMessage({
    defaultMessage: "Your health plan information has expired. Please update it to access your funds."
  })

  const theme = useTheme()

  const handleButtonClick = (): void => history.push(Locations.Home.EnrollmentFlowActive)

  return (
    <Alert severity="error">
      <Stack direction="column" spacing={theme.spacing(theme.tokens.spacing.md)}>
        <Box>{warningHeader}</Box>
        <Box>
          <Button fullWidth={false} color="error" onClick={handleButtonClick}>
            {buttonLabel}
          </Button>
        </Box>
      </Stack>
    </Alert>
  )
}

export default InsuranceExpired
