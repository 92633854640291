import React from "react"
import { DialogTitleImage, InfoDialog } from "@carrotfertility/carotene-core-x"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, DialogContentText, Stack, Typography } from "@carrotfertility/carotene-core"

export function DoulaAttestationSuccessDialog({
  doulaEmail,
  onClose,
  open
}: {
  doulaEmail: string
  onClose: () => void
  open: boolean
}): JSX.Element {
  const intl = useIntl()

  return (
    <InfoDialog
      slotProps={{
        closeButton: {
          "aria-label": "Close"
        }
      }}
      image={<DialogTitleImage width="100%" maxWidth="300px" src="/images/illo-paper-airplane-mail-slot-2x.png" />}
      title={intl.formatMessage({ defaultMessage: "Sent to doula" })}
      open={open}
      onClose={onClose}
      primaryAction={
        <Button onClick={onClose}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      }
    >
      <DialogContentText>
        <Stack gap={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          <Typography>
            <FormattedMessage
              defaultMessage="We’ve emailed a link to the attestation form to {doulaEmail}. When your doula completes the form, you’ll receive a confirmation email."
              values={{ doulaEmail }}
            />
          </Typography>
          <Typography>
            <FormattedMessage defaultMessage="We’ll review the form as soon as possible and send you a message when it’s approved. If we need any additional information, we’ll follow up with your doula directly." />
          </Typography>
        </Stack>
      </DialogContentText>
    </InfoDialog>
  )
}
