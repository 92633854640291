import React from "react"
import { Box, Button, DialogActions, DialogContentText, DialogTitle } from "@carrotfertility/carotene-core"
import { IlloCoinsAndBills } from "components/views/atoms/Illustrations"
import { useReimbursementContext } from "components/context/reimbursement/GetReimbursedContext"
import { FormattedMessage } from "react-intl"

export function GetReimbursedFinalStep(): JSX.Element {
  const { onExit } = useReimbursementContext()

  return (
    <>
      <Box display="flex" justifyContent="center" paddingY={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
        <IlloCoinsAndBills />
      </Box>
      <DialogTitle>
        <FormattedMessage defaultMessage="You're all set" />
      </DialogTitle>
      <DialogContentText>
        <FormattedMessage defaultMessage="We've received your reimbursement request and will review it as soon as possible. We'll notify you when it's approved or if we need any additional information." />
      </DialogContentText>
      <DialogActions>
        <Button color="primary" onClick={onExit}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </DialogActions>
    </>
  )
}
