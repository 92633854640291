import React from "react"
import { Typography, Link, Stack, useTheme, Button, Box } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"
import { AppAccessPageLayout } from "components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "components/access-page/AppAccessPageCard"
import { FormattedMessage, useIntl } from "react-intl"

export default function NoPersonalEmail(): JSX.Element {
  const intl = useIntl()
  const headerText = intl.formatMessage({ defaultMessage: "Your email address could not be verified" })

  const theme = useTheme()
  return (
    <AppAccessPageLayout title={headerText}>
      <AppAccessPageCard>
        <Box textAlign="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          <Typography variant="h1">{headerText}</Typography>
        </Box>
        <Stack textAlign="center" spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <Stack spacing={theme.spacing(theme.tokens.spacing.sm)} textAlign="center">
            <Typography>
              <FormattedMessage
                defaultMessage="You can add another email address in <link>Account settings</link>."
                values={{
                  link: (linkContent) => (
                    <Link to={"/account#email-addresses"} component={RouterLink}>
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </Typography>
            <Typography>
              <FormattedMessage
                defaultMessage=" Still having trouble? <link>Contact us</link>."
                values={{
                  link: (linkContent) => (
                    <Link to={"/send-a-message/?verification=personal-email"} component={RouterLink}>
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </Typography>
          </Stack>
          <Box>
            <Button component={RouterLink} to="/account#email-addresses" id="goToAccountSettings">
              <FormattedMessage defaultMessage="Add another email address" />
            </Button>
          </Box>
        </Stack>
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}
