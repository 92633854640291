import { combineReducers } from "redux"
import actionTypes from "actions/actionTypes"

import companyInfo from "reducers/companyInfo"
import subsidies from "reducers/subsidies"
import userInfo from "reducers/userInfo"
import reimbursements from "reducers/reimbursements"
import application from "reducers/application"
import deductibleStatus from "reducers/deductibleStatus"
import avaCheckout from "reducers/ava"
import cardDetails from "reducers/cardDetails"
import loginInfo from "reducers/loginInfo"
import insuranceSyncInfo, { reimbursementDetails } from "./reimbursementDetails"
import benefitConfiguration from "./benefitConfiguration"

const rootReducer = combineReducers({
  application,
  companyInfo,
  subsidies,
  userInfo,
  reimbursements,
  deductibleStatus,
  avaCheckout,
  cardDetails,
  loginInfo,
  insuranceSyncInfo,
  reimbursementDetails,
  benefitConfiguration
})

export type RootState = ReturnType<typeof rootReducer>

// this is used to reset the redux state on signOut
// more info here: https://netbasal.com/how-to-secure-your-users-data-after-logout-in-redux-30468c6848e8
// @ts-expect-error TS7006
const rootReducerWithSignOut = (state, action): RootState => {
  if (action.type === actionTypes.FETCH_SIGN_OUT_SUCCESS) {
    state = undefined
  }

  return rootReducer(state, action)
}

export default rootReducerWithSignOut
