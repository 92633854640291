import { BankDetailsData } from "lib/carrot-api/types/Bank"
import { useQuery } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import { carrotClient } from "utils/CarrotClient"

export default function useGetBankDetailsPageData(): {
  isSuccess: boolean
  isLoading: boolean
  isError: boolean
  schema: any
  data: BankDetailsData
  refetchData: any
} {
  const bankSchemaQuery = useQuery({
    queryKey: ["bank-details", "schema"],
    queryFn: () => carrotClient.getBankDetailsSchema(),
    retry: buildCarrotApiRetryPolicy(1),
    refetchOnWindowFocus: false
  })
  const bankDetailsQuery = useQuery({
    queryKey: ["bank-details"],
    queryFn: () => carrotClient.getBankDetails(),
    retry: buildCarrotApiRetryPolicy(1),
    refetchOnWindowFocus: false
  })

  return {
    isSuccess: bankSchemaQuery.isSuccess && bankDetailsQuery.isSuccess,
    isLoading: bankSchemaQuery.isLoading || bankDetailsQuery.isLoading,
    isError: bankSchemaQuery.isError || bankDetailsQuery.isError,
    schema: bankSchemaQuery.data,
    data: bankDetailsQuery.data,
    refetchData: bankDetailsQuery.refetch
  }
}
