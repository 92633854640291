import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { AppointmentRequest } from "../types/RequestAppointmentTypes"
import { useCarrotPartnerBookingApiClient } from "./useCarrotPartnerBookingApiClient"

export default function useSubmitAppointmentRequest(
  onSuccess?: () => void
): UseMutationResult<unknown, Error, AppointmentRequest> {
  const client = useCarrotPartnerBookingApiClient()
  return useMutation<unknown, Error, AppointmentRequest>(
    (appointmentRequest) => client.postAppointmentRequest(appointmentRequest),
    {
      retry: false,
      onSuccess: onSuccess
    }
  )
}
