import React, { FormEvent, useEffect, useState } from "react"
import { useGetLocationDetails } from "../../hooks/useGetLocationDetails"
import { useHistory } from "react-router-dom"
import {
  getProviderFinderSearchURL,
  ProviderFinderParam,
  RedirectToProviderFinderURL
} from "../../utils/providerFinderSearchUrl"
import { useUserContext } from "../../context/UserContext"
import { useBroadAreaContext } from "../../context/BroadAreaContext"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@carrotfertility/carotene-core"
import { LocationPicker } from "../location-picker/LocationPicker"

interface ChangeLocationModalProps {
  open: boolean
  setOpen: (inputValue: boolean) => void
}

export const ChangeLocationModal = ({ open, setOpen }: ChangeLocationModalProps): JSX.Element => {
  const intl = useIntl()
  const [locationPickerValue, setLocationPickerValue] = useState("")
  const [searchLocation, setSearchLocation] = useState(null)
  const { data: locationDetails, isFetching, isError } = useGetLocationDetails(searchLocation)
  const history = useHistory()
  const { companyCountryCode } = useUserContext()
  const { setBroadAreaSearched } = useBroadAreaContext()

  const params = new URLSearchParams(history.location.search)
  const radiusInKm = params.get(ProviderFinderParam.RadiusInKm)
  const isNewSearchDetailsLoaded = searchLocation && !isFetching && !isError
  const carrotPartner = params.get(ProviderFinderParam.CarrotPartner) === "true"
  const isVirtual = params.get(ProviderFinderParam.IsVirtual) === "true"
  const providerType = params.get(ProviderFinderParam.ProviderType)
  const keyword = ProviderFinderParam.Keyword && params.get(ProviderFinderParam.Keyword)
  const newSearchURL = isNewSearchDetailsLoaded
    ? getProviderFinderSearchURL({
        searchLocation,
        locationDetails,
        radiusInKm,
        companyCountryCode,
        carrotPartner,
        isVirtual,
        providerType,
        keyword
      })
    : null

  useEffect(() => {
    if (isNewSearchDetailsLoaded) {
      setBroadAreaSearched(locationDetails?.isBroadArea)
    }
  }, [isNewSearchDetailsLoaded, setBroadAreaSearched, locationDetails?.isBroadArea])

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault()
    setSearchLocation(locationPickerValue)
    setOpen(false)
  }

  return (
    <>
      <RedirectToProviderFinderURL url={newSearchURL} />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle>
          <FormattedMessage defaultMessage="Change location" />
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <LocationPicker
              label={intl.formatMessage({ defaultMessage: "Where would you like to find a provider?" })}
              setLocationPickerValue={setLocationPickerValue}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit">
              <FormattedMessage defaultMessage="Update location" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
