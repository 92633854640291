import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import { TypeaheadProvider } from "../types/Providers"
import ResponseError from "../types/ResponseError"
import { useProviderFinderClient } from "./useProviderFinderClient"

export const TYPEAHEAD_PROVIDERS_QUERY_KEY = "GetTypeaheadProviders"

export function useGetTypeaheadProviders(
  providerNameSubstring: string,
  companyCountryCode: string
): UseQueryResult<TypeaheadProvider[], ResponseError> {
  const client = useProviderFinderClient()

  return useQuery<TypeaheadProvider[], ResponseError>(
    [TYPEAHEAD_PROVIDERS_QUERY_KEY, providerNameSubstring],
    () => {
      if (!providerNameSubstring || !companyCountryCode) {
        return null
      }

      return client.getTypeaheadProviders(providerNameSubstring, companyCountryCode)
    },
    {
      enabled: !!providerNameSubstring,
      refetchOnWindowFocus: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
