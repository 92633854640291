import { useLDClient } from "launchdarkly-react-client-sdk"

export function useTrackExperimentEvent() {
  const ldClient = useLDClient()

  const trackExperimentEvent = async (eventName: string) => {
    if (ldClient) {
      try {
        await ldClient.waitUntilReady()
        ldClient.track(eventName)
      } catch (error) {
        reportError(error)
      }

      try {
        await ldClient.flush()
      } catch (error) {
        reportError(error)
      }
    }
  }

  return { trackExperimentEvent }
}
