import { Container, H4 } from "@carrotfertility/carotene"
import { useCurrentUser } from "components/context/user/UserContext"
import {
  BenefitEnrollmentFlowModalBackButton,
  BenefitEnrollmentModalContinueButton,
  BenefitEnrollmentModalFormSelect
} from "components/cmd-enrollment/shared"
import { Steps } from "components/cmd-enrollment/workflow/steps"
import { CompleteFormInput } from "components/views/atoms/forms/FormInput"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import {
  FieldNameHealthPlanId,
  FieldNameTradingPartner,
  UseConnectHealthPlan,
  useConnectHealthPlan,
  UseTradingPartners,
  useTradingPartners,
  UseTradingPartnersArgs
} from "./hooks"
import { UpdateHealthPlan } from "./types"
import { Form } from "../../../../views/atoms/forms/Form"
import { useUserDeductible } from "../../../../context/user/DeductibleContext"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Typography } from "@carrotfertility/carotene-core"

export interface ProviderConnectYourInsuranceProps
  extends UseConnectHealthPlan,
    Pick<UseTradingPartners, "options" | "providerListed" | "checkIfProviderIsListed">,
    Pick<UseTradingPartnersArgs, "defaultTradingPartnerPk"> {
  stepHeader: string
  defaultHealthPlanId: string
}

export interface ProviderConnectYourInsuranceViewProps
  extends UseTradingPartnersArgs,
    Pick<ProviderConnectYourInsuranceProps, "stepHeader"> {
  viewName: typeof Steps[keyof typeof Steps]
  onUserUpdate: (healthPlanIdVal: string, tradingPartnerPkVal: string, tradingPartnerId: string) => any
  // @ts-expect-error TS7008
  defaultHealthPlanId
}
function ProviderConnectYourInsurance({
  defaultTradingPartnerPk,
  options,
  providerListed,
  checkIfProviderIsListed,
  stepHeader,
  onSubmit,
  defaultHealthPlanId
}: ProviderConnectYourInsuranceProps): JSX.Element {
  const formMethods = useForm()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = formMethods

  useEffect(() => {
    setValue(FieldNameHealthPlanId, defaultHealthPlanId)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])
  const intl = useIntl()

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Container padding="none" stack="huge">
          <BenefitEnrollmentFlowModalBackButton />
        </Container>
        <Typography
          id="step-heading"
          variant="h2"
          color={(theme) => theme.palette.text.primary}
          paddingBottom={(theme) => theme.tokens.spacing.xl}
        >
          {stepHeader}
        </Typography>
        <H4 id="provider-name-label" stack="tiny" variant="primary">
          <FormattedMessage defaultMessage="PROVIDER NAME" />
        </H4>
        <BenefitEnrollmentModalFormSelect
          name={FieldNameTradingPartner}
          aria-labelledby="provider-name-label"
          defaultValue={defaultTradingPartnerPk}
          options={options.map((option) => ({ value: option.id, label: option.label }))}
          onChange={checkIfProviderIsListed}
        />
        <Container padding="none" stack={providerListed ? "large" : "giant"}>
          {providerListed && (
            <>
              <H4 id="member-id-label" stack="tiny" variant="primary">
                <FormattedMessage defaultMessage="MEMBER ID" />
              </H4>
              <CompleteFormInput
                aria-labelledby="member-id-label"
                errors={errors}
                {...register(FieldNameHealthPlanId, {
                  required: intl.formatMessage({ defaultMessage: "Required" }),
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message: intl.formatMessage({ defaultMessage: "Only numbers and letters may be entered." })
                  }
                })}
              />
            </>
          )}
        </Container>
        <Box height="4rem" />
        <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
      </Form>
    </FormProvider>
  )
}

function ProviderConnectYourInsuranceView({
  viewName,
  defaultTradingPartnerPk,
  defaultHealthPlanId,
  onUserUpdate,
  stepHeader
}: ProviderConnectYourInsuranceViewProps): JSX.Element {
  const { options, providerListed, availableTradingPartners, checkIfProviderIsListed } = useTradingPartners({
    defaultTradingPartnerPk
  })
  const { onSubmit } = useConnectHealthPlan({ viewName, onUserUpdate, availableTradingPartners })

  return (
    <ProviderConnectYourInsurance
      {...{
        defaultTradingPartnerPk,
        options,
        providerListed,
        checkIfProviderIsListed,
        stepHeader,
        onSubmit,
        defaultHealthPlanId
      }}
    />
  )
}

export function MemberConnectHealthPlanStep(): JSX.Element {
  const { selectedTradingPartnerPk: defaultTradingPartnerPk, memberId: defaultHealthPlanId } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  function onUserUpdate(
    healthPlanIdVal: string,
    tradingPartnerPkVal: string,
    tradingPartnerId: string
  ): UpdateHealthPlan {
    return updateBenefitEnrollmentAnswers({
      selectedTradingPartnerId: tradingPartnerId,
      memberId: healthPlanIdVal,
      selectedTradingPartnerPk: tradingPartnerPkVal
    }) as any
  }
  const intl = useIntl()

  return (
    <ProviderConnectYourInsuranceView
      {...{ defaultHealthPlanId, onUserUpdate }}
      defaultTradingPartnerPk={defaultTradingPartnerPk?.toString() || null}
      stepHeader={intl.formatMessage({ defaultMessage: "Connect to your health insurance provider" })}
      viewName={Steps.MEMBER_HEALTH_PLAN_CONNECT}
    />
  )
}

export function PartnerConnectHealthPlanStep(): JSX.Element {
  const {
    partnerHealthPlanSelectedTradingPartnerPk: defaultTradingPartnerPk,
    partnerHealthPlanMemberId: defaultHealthPlanId
  } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()

  function onUserUpdate(
    healthPlanIdVal: string,
    tradingPartnerPkVal: string,
    tradingPartnerId: string
  ): UpdateHealthPlan {
    return updateBenefitEnrollmentAnswers({
      partnerHealthPlanSelectedTradingPartnerId: tradingPartnerId,
      partnerHealthPlanMemberId: healthPlanIdVal,
      partnerHealthPlanSelectedTradingPartnerPk: tradingPartnerPkVal
    }) as any
  }
  const intl = useIntl()

  return (
    <ProviderConnectYourInsuranceView
      {...{ defaultHealthPlanId, onUserUpdate }}
      defaultTradingPartnerPk={defaultTradingPartnerPk?.toString() || null}
      stepHeader={intl.formatMessage({ defaultMessage: "Connect to their health insurance provider" })}
      viewName={Steps.PARTNER_HEALTH_PLAN_CONNECT}
    />
  )
}
