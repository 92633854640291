import React, { ReactElement, useEffect, useState } from "react"
import TwoColumnRightPrimary from "components/views/atoms/layouts/TwoColumnRightPrimary"
import { getTalkToCarrotRefreshCarrotPlanGuidance } from "../../lib/contentful/contentfulClient"
import { GuidanceElement } from "../../lib/contentful/components/GuidanceElement"
import { talkToCarrotSource } from "../../components/talk-to-carrot/send-message/SendMessageUtil"
import { SendMessage } from "../../components/talk-to-carrot/send-message/SendMessage"
import { TalkToCarrotLegal } from "../../components/talk-to-carrot/TalkToCarrotLegal"
import { NonStaleDoneUsingCarrotModal } from "../../components/carrot-plans/case-rate-renewals/NonStaleDoneUsingCarrotModal"
import { useIntl, FormattedMessage } from "react-intl"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header"
import { Stack, Box, Breadcrumbs, Breadcrumb, Typography, Link, useTheme } from "@carrotfertility/carotene-core"

// @ts-expect-error TS7031
const RefreshCarrotPlan = ({ breadcrumbs }) => {
  const [guidance, setGuidance] = useState<ReactElement<typeof GuidanceElement>>(null)
  const showDoneForNowCopy = window.location.href.includes("talk-to-carrot/refresh-carrot-plan?fresh")
  const [openDoneUsingCarrotModal, setOpenDoneUsingCarrotModal] = React.useState(false)
  const intl = useIntl()
  const { locale } = intl
  const breadcrumbTitle = intl.formatMessage({
    defaultMessage: "Refresh Carrot Plan"
  })
  const theme = useTheme()

  useEffect(() => {
    const loadGuidance = async () => {
      const guidance = await getTalkToCarrotRefreshCarrotPlanGuidance(locale)
      setGuidance(<GuidanceElement guidance={guidance} />)
    }
    if (!guidance) {
      loadGuidance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return (
    <PageLayout
      footer={<TalkToCarrotLegal />}
      header={
        <PageBreadcrumbHeader
          breadcrumbs={
            <Breadcrumbs>
              {[
                ...breadcrumbs,
                <Breadcrumb key="help-breadcrumb" currentPage>
                  {breadcrumbTitle}
                </Breadcrumb>
              ]}
            </Breadcrumbs>
          }
        />
      }
    >
      <TwoColumnRightPrimary
        secondaryContent={
          <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
            <Box>{guidance}</Box>
            {showDoneForNowCopy ? (
              <Typography variant="body1" color={theme.palette.text.secondary}>
                <FormattedMessage
                  defaultMessage="Or if you're done using Carrot, <link>let us know</link>."
                  values={{
                    link: (linkContent) => (
                      <Link
                        sx={{
                          "text-decoration-color": theme.palette.text.secondary,
                          "&:hover": {
                            textDecorationLine: "underline",
                            textDecorationColor: "inherit"
                          }
                        }}
                        component={"button"}
                        onClick={() => setOpenDoneUsingCarrotModal(true)}
                      >
                        {linkContent}
                      </Link>
                    )
                  }}
                />
              </Typography>
            ) : null}
          </Stack>
        }
        primaryContent={<SendMessage source={talkToCarrotSource.REFRESH_CARROT_PLAN} />}
      />
      <NonStaleDoneUsingCarrotModal {...{ openDoneUsingCarrotModal, setOpenDoneUsingCarrotModal }} />
    </PageLayout>
  )
}

export { RefreshCarrotPlan }
