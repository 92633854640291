import React from "react"
import { CountryCodes } from "content/CountryCode"
import { FormInput, FormSelect, FormTextarea, Stack } from "@carrotfertility/carotene"
import { AboutYou } from "types/carrotPlanTypes"
import { useIntl } from "react-intl"

const validateFieldIsRequired = (value: string): boolean => !value

interface AboutYouFormInternationalAddressProps {
  defaultValues?: Omit<AboutYou["address"], "address1" | "address2">
}

export function AboutYouFormInternationalAddress(props: AboutYouFormInternationalAddressProps): JSX.Element {
  const intl = useIntl()
  const requiredText = intl.formatMessage({ defaultMessage: "Required" })

  const CountryOptions: { label: string; value: string }[] = CountryCodes.map((code) => ({
    label: intl.formatDisplayName(code, { type: "region" }),
    value: code
  }))

  return (
    <Stack spacing="8px">
      <FormTextarea
        minRows={4}
        defaultValue={props?.defaultValues?.internationalAddressLine}
        // @ts-expect-error TS7006
        validate={(value) => {
          return validateFieldIsRequired(value) ? requiredText : false
        }}
        data-testid="about-you-internationalAddressLine"
        isRequired
        name="internationalAddressLine"
        placeholder={intl.formatMessage({ defaultMessage: "Enter house number and street name" })}
        label={intl.formatMessage({ defaultMessage: "Street Address" })}
      />
      <FormInput
        defaultValue={props?.defaultValues?.city}
        // @ts-expect-error TS7006
        validate={(value) => {
          return validateFieldIsRequired(value) ? requiredText : false
        }}
        data-testid="about-you-city"
        placeholder={intl.formatMessage({ defaultMessage: "Enter city" })}
        label={intl.formatMessage({ defaultMessage: "City" })}
        name="city"
        isRequired
      />
      <FormInput
        defaultValue={props?.defaultValues?.state}
        // @ts-expect-error TS7006
        validate={(value) => {
          return validateFieldIsRequired(value) ? requiredText : false
        }}
        data-testid="about-you-state"
        placeholder={intl.formatMessage({ defaultMessage: "Enter state, province, country, or region" })}
        label={intl.formatMessage({ defaultMessage: "State / Province / County / Region" })}
        name="state"
        isRequired
      />
      <FormSelect
        defaultValue={props?.defaultValues?.internationalCountry}
        // @ts-expect-error TS7006
        validate={(value) => {
          return validateFieldIsRequired(value) ? requiredText : false
        }}
        data-testid="about-you-internationalCountry"
        placeholder={intl.formatMessage({ defaultMessage: "Select country" })}
        label={intl.formatMessage({ defaultMessage: "Country" })}
        options={CountryOptions}
        name="internationalCountry"
        isRequired
      />
      <FormInput
        defaultValue={props?.defaultValues?.zip}
        placeholder={intl.formatMessage({ defaultMessage: "Enter postal code" })}
        data-testid="about-you-zip"
        label={intl.formatMessage({ defaultMessage: "Postal code" })}
        name="zip"
        helperMessage={intl.formatMessage({ defaultMessage: "Postal code is only required if your address has one." })}
      />
    </Stack>
  )
}

/**
 * Co-locating this form to prevent potential bugs when making updates to such similar forms in the future
 */
export function ReimbursementFormInternationalAddress(props: AboutYouFormInternationalAddressProps): JSX.Element {
  return <AboutYouFormInternationalAddress {...props} />
}
