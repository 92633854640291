import React from "react"
import { ChangePasswordDialog } from "./ChangePasswordDialog"
import { Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"

export default function PasswordSettings({ isPartnerSection }: { isPartnerSection: boolean }): JSX.Element {
  return (
    <>
      <Typography variant="h2" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
        {isPartnerSection ? (
          <FormattedMessage defaultMessage="Partner password" />
        ) : (
          <FormattedMessage defaultMessage="Password" />
        )}
      </Typography>
      <ChangePasswordDialog />
    </>
  )
}
