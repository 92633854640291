import React from "react"
import { FormattedMessage } from "react-intl"
import { DbgPaths, TtcPaths } from "../../../utils/Paths"
import { Box, InternalIcon, Link, Stack, Typography } from "@carrotfertility/carotene-core"
import { RetinaImage } from "../atoms/images/RetinaImage"

const SupportModule = () => {
  return (
    <>
      <Box
        component="section"
        borderRadius={(theme) => theme.tokens.borderRadius.md}
        padding={(theme) => theme.tokens.spacing.lg}
        sx={(theme) => ({ backgroundColor: theme.palette.background.default })}
      >
        <Stack>
          <Box
            padding={(theme) => theme.tokens.spacing.xs}
            borderRadius={(theme) => theme.tokens.borderRadius.round}
            height="40px"
            width="40px"
            sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
          >
            <RetinaImage name="icn-account-settings" height="24px" width="24px" />
          </Box>

          <Typography variant="h4" component="h3" color="text.secondary" marginTop={(theme) => theme.tokens.spacing.xs}>
            <FormattedMessage defaultMessage="Before using your funds" />
          </Typography>

          <Typography variant="body1" color="text.secondary" paddingTop={(theme) => theme.tokens.spacing.xxs}>
            <Link endIcon={<InternalIcon fontSize="small" color="primary" />} href={DbgPaths.CARROT_ELIGIBLE}>
              <FormattedMessage defaultMessage="Understand your coverage" />
            </Link>
          </Typography>

          <Typography variant="body1" color="text.secondary" paddingTop={(theme) => theme.tokens.spacing.xxs}>
            <Link endIcon={<InternalIcon fontSize="small" color="primary" />} href={DbgPaths.PROVIDER_ELIGIBILITY}>
              <FormattedMessage defaultMessage="Make sure your provider is eligible" />
            </Link>
          </Typography>

          <Typography variant="body1" color="text.secondary" paddingTop={(theme) => theme.tokens.spacing.xxs}>
            <Link endIcon={<InternalIcon fontSize="small" color="primary" />} href={DbgPaths.REQUIRED_DOCS}>
              <FormattedMessage defaultMessage="Review documentation requirements" />
            </Link>
          </Typography>

          <Typography variant="body1" color="text.secondary" paddingTop={(theme) => theme.tokens.spacing.xxs}>
            <Link endIcon={<InternalIcon fontSize="small" color="primary" />} href={DbgPaths.TAXES}>
              <FormattedMessage defaultMessage="Learn about Carrot and taxes" />
            </Link>
          </Typography>

          <Typography variant="h4" component="h3" color="text.secondary" marginTop={(theme) => theme.tokens.spacing.lg}>
            <FormattedMessage defaultMessage="Need help?" />
          </Typography>

          <Typography variant="body1" color="text.secondary" paddingTop={(theme) => theme.tokens.spacing.xxs}>
            <Link endIcon={<InternalIcon fontSize="small" color="primary" />} href={TtcPaths.TTC_CARE_NAV}>
              <FormattedMessage defaultMessage="Talk to Carrot" />
            </Link>
          </Typography>
        </Stack>
      </Box>
    </>
  )
}

export { SupportModule }
