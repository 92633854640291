import { DeductibleAndCostShareBadge } from "components/views/planinfo/PlanInfoBadge"
import React, { FC, useMemo } from "react"
import { COMPANY_DEDUCTIBLE_STATUS, useUserDeductible } from "../../context/user/DeductibleContext"
import subsidyStatus from "utils/CarrotPropTypes"
import { FormattedMessage } from "react-intl"
import { reimbursementCategoryNameMessageKeys } from "../../views/reimbursements/reimbursementCategoryNameMessageKeys"
import { BoxProps } from "@carrotfertility/carotene-core"
import { getCategoryCoverage } from "../../../utils/CategoryCoverage"

interface PlanInfoBadgeContainerProps {
  subsidy: typeof subsidyStatus
  allSubsidies: typeof subsidyStatus[]
  sx?: BoxProps["sx"]
}

const PlanInfoBadgeContainer: FC<PlanInfoBadgeContainerProps> = ({ subsidy, allSubsidies, sx }) => {
  const { companyDeductibleStatus, hasCMD } = useUserDeductible()
  const companyHasExternalDeductible = useMemo(
    () => companyDeductibleStatus === COMPANY_DEDUCTIBLE_STATUS.EXTERNAL,
    [companyDeductibleStatus]
  )
  const companyHasCMD = useMemo(
    () => companyDeductibleStatus === COMPANY_DEDUCTIBLE_STATUS.CARROT_MANAGED_DEDUCTIBLE,
    [companyDeductibleStatus]
  )
  /* eslint-disable react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps */
  // @ts-expect-error TS2339
  const hasCostShare = useMemo(() => subsidy?.hasCostShareDisplay, [subsidy?.reimbursementPercent])
  const hasQmeCategory = getCategoryCoverage(allSubsidies).qme
  const categoryNameMessageKey = subsidy.reimbursementCategoryNameMessageKey.toString()
  const categorySupportsDeductibles =
    categoryNameMessageKey !== reimbursementCategoryNameMessageKeys.ADOPTION &&
    categoryNameMessageKey !== reimbursementCategoryNameMessageKeys.GC &&
    categoryNameMessageKey !== reimbursementCategoryNameMessageKeys.ADOPTION_AND_GC &&
    (categoryNameMessageKey !== reimbursementCategoryNameMessageKeys.QME || !hasQmeCategory)
  /* eslint-enable react-hooks/exhaustive-deps */
  const shouldShowDeductibleOrCostShareLink = useMemo(
    () => hasCostShare || (categorySupportsDeductibles && (companyHasExternalDeductible || (companyHasCMD && hasCMD))),
    [hasCostShare, categorySupportsDeductibles, companyHasExternalDeductible, companyHasCMD, hasCMD]
  )

  const getTextFromUserDeductibleAndCostShare = (
    hasCostShare: boolean,
    companyHasExternalDeductible: boolean,
    hasCMD: boolean
  ) => {
    if (categorySupportsDeductibles && hasCostShare && (hasCMD || companyHasExternalDeductible)) {
      return <FormattedMessage defaultMessage="Deductible & cost sharing apply" />
    }
    if (hasCostShare) {
      return <FormattedMessage defaultMessage="Cost sharing applies" />
    }
    if (categorySupportsDeductibles && (hasCMD || companyHasExternalDeductible)) {
      return <FormattedMessage defaultMessage="Deductible applies" />
    }
    return null
  }

  return (
    shouldShowDeductibleOrCostShareLink && (
      <DeductibleAndCostShareBadge subsidy={subsidy} sx={sx}>
        {getTextFromUserDeductibleAndCostShare(hasCostShare, companyHasExternalDeductible, hasCMD)}
      </DeductibleAndCostShareBadge>
    )
  )
}

export { PlanInfoBadgeContainer }
