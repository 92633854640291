import { combineReducers } from "redux"
import actionTypes from "actions/actionTypes"
import { createIsLoadedReducer, createReducer } from "./shared"

const {
  FETCH_INSURANCE_SYNC_SUCCESS,
  FETCH_REIMBURSEMENT_DETAILS_SUCCESS,
  FETCH_REIMBURSEMENT_DETAILS_STATUS,
  FETCH_REIMBURSEMENT_DETAILS_ENROLLMENT_STARTED
} = actionTypes

const reducers = {}

reducers.loaded = createIsLoadedReducer("FETCH_INSURANCE_SYNC")

// insurance sync
reducers.availableTradingPartners = createReducer(null, {
  [FETCH_INSURANCE_SYNC_SUCCESS]: (state, action) => action.response.tradingPartners.availableTradingPartners
})

const insuranceSyncInfo = combineReducers(reducers)

// insurance sync
export const getInsuranceSyncInfo = (state) => state.insuranceSyncInfo
export const getAvailableTradingPartners = (state) => state.insuranceSyncInfo.availableTradingPartners

export default insuranceSyncInfo

// reimbursement details
const reimbursementDetailsReducers = {
  loaded: createIsLoadedReducer("FETCH_REIMBURSEMENT_DETAILS_SYNC"),
  reimbursementDetails: createReducer(null, {
    [FETCH_REIMBURSEMENT_DETAILS_SUCCESS]: (state, action) => action.response
  }),
  reimbursementDetailsStatus: createReducer(null, {
    [FETCH_REIMBURSEMENT_DETAILS_STATUS]: (state, action) => action.status
  }),
  // Used only by the enrollment flow
  enrollmentFlowRequestStarted: createReducer(false, {
    [FETCH_REIMBURSEMENT_DETAILS_ENROLLMENT_STARTED]: (state, action) => action.startedEnrollment
  })
}

// This is some weird wiring thing. Not worth trying to unwind right now.
export const getReimbursementDetails = (state) => state.reimbursementDetails.reimbursementDetails
export const getReimbursementDetailsStatus = (state) => state.reimbursementDetails.reimbursementDetailsStatus
export const getEnrollmentFlowRequestStarted = (state) => state.reimbursementDetails.enrollmentFlowRequestStarted

export const reimbursementDetails = combineReducers(reimbursementDetailsReducers)
