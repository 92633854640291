import React from "react"
import { Alert, Link } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { TtcPaths } from "utils/Paths"

export const unexpectedServerErrorAlert = (
  <Alert severity="error">
    <FormattedMessage
      defaultMessage="Something went wrong on our end. Please try again. If the problem persists, <link>contact us</link>."
      values={{
        link: (linkContent) => (
          <Link color="inherit" href={TtcPaths.TTC_CARE_NAV} target={"_blank"}>
            {linkContent}
          </Link>
        )
      }}
    />
  </Alert>
)
