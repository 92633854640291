import React from "react"
import SignUpFindAccountSuccessPage from "components/signup-find-account-success"
import {} from "components/signup-find-account-success/context/SignUpFindAccountSuccessContext"
import {
  FindAccountByExternalEmployeeIdSuccessProvider,
  useFindAccountByExternalEmployeeIdSuccess
} from "components/signup-find-account-by-external-employee-id-success/context/SignUpFindAccountSuccessContext"

export default function FindAccountByExternalEmployeeIdSuccess(): JSX.Element {
  return (
    <FindAccountByExternalEmployeeIdSuccessProvider>
      <ContextProvidingWrapper />
    </FindAccountByExternalEmployeeIdSuccessProvider>
  )
}

function ContextProvidingWrapper(): JSX.Element {
  const contextValue = useFindAccountByExternalEmployeeIdSuccess()

  return <SignUpFindAccountSuccessPage {...contextValue} />
}
