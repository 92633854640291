import { StatusCodes } from "http-status-codes"
import ResponseError from "types/responseError"
import { GuideSurveyAnswers } from "../types/GuideSurveyAnswers"
// @ts-expect-error TS7016
import { v4 as uuid } from "uuid"

export default class GuidesClient {
  baseUrl: string

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  // @ts-expect-error TS7006
  async ProcessJsonResponse<T>(response, correlationId): Promise<T> {
    if (response.status === StatusCodes.OK) {
      const responseText = await response.text()
      return responseText ? JSON.parse(responseText) : null
    }
    throw new ResponseError(response, correlationId)
  }

  // @ts-expect-error TS7006
  createJsonHeaders(correlationId): Headers {
    const headers = new Headers()
    headers.append("X-Request-ID", correlationId)
    headers.append("X-React-App-Version", process.env.REACT_APP_VERSION || "local")
    headers.append("Content-Type", "application/json")
    return headers
  }

  // @ts-expect-error TS7006
  async getCountByGuideEmployee(guideId): Promise<number> {
    const correlationId = uuid()

    const response = await fetch(`${this.baseUrl}/api/v2/guides/${guideId}/users/me/survey-answers/count`, {
      method: "GET",
      headers: this.createJsonHeaders(correlationId),
      credentials: "include"
    })

    return response.json()
  }

  async submitGuideSurveyAnswers({ guideId, ...answers }: GuideSurveyAnswers): Promise<void> {
    const correlationId = uuid()
    const headers = new Headers()
    headers.append("X-Request-ID", correlationId)
    headers.append("X-React-App-Version", process.env.REACT_APP_VERSION || "local")
    headers.append("Content-Type", "application/json")

    const body = JSON.stringify({
      ...answers
    })
    await fetch(`${this.baseUrl}/api/v2/guides/${guideId}/users/me/survey-answers`, {
      credentials: "include",
      body,
      headers,
      method: "POST"
    })
  }
}
