import React, { FC, ReactNode } from "react"

type RoundedRectangleProps = {
  /**  Background color as tachyons class suffix eg: "mint" */
  backgroundColor?: string
  /** Border color of the rectangle as a tachyons class suffix eg: "mint-alternate" */
  borderColor?: string
  /** Amount of padding in the rectangle as a tachyons class eg: "pa4" */
  padding?: string
  id?: string
  children: ReactNode
  className?: string
}

/**
 * A rounded box with adjustable padding, background color and border color
 *
 * Composes well with components prefixed with `RR`
 */
const RoundedRectangle: FC<RoundedRectangleProps> = ({
  backgroundColor = "white",
  borderColor,
  children,
  id,
  padding = "pa35",
  className = ""
}: RoundedRectangleProps) => {
  const border = borderColor ? `b--${borderColor} ba bw1` : ""

  const divClassName = `
  h-100 flex flex-column br3 animate-all
  ${padding} 
  ${border} 
  bg-${backgroundColor}
  ${className}
  `

  return (
    <div id={id} className={divClassName}>
      {children}
    </div>
  )
}

export { RoundedRectangle }
