import React, { FC } from "react"
import { RoundedRectangle } from "components/views/atoms/roundedrectangle/RoundedRectangle"
import { H3 } from "@carrotfertility/carotene"
import { RRButtonFooter } from "../atoms/roundedrectangle/RRButtonFooter"
import { FormattedMessage } from "react-intl"

type ConnectInsuranceInfoModuleProps = {
  onClickEnroll: () => void
}

const ConnectInsuranceInfoModule: FC<ConnectInsuranceInfoModuleProps> = ({ onClickEnroll }) => {
  return (
    <>
      <RoundedRectangle backgroundColor="light-gray">
        <H3 variant="primary" stack="tiny">
          <FormattedMessage defaultMessage="Connect to your health insurance plan and you may get more money back" />
        </H3>
        <RRButtonFooter onClick={onClickEnroll} outline>
          <FormattedMessage defaultMessage="Add insurance info" />
        </RRButtonFooter>
      </RoundedRectangle>
    </>
  )
}

export { ConnectInsuranceInfoModule }
