import { applyMiddleware, compose, createStore } from "redux"
import { createLogger } from "redux-logger"
import { default as thunk } from "redux-thunk"
import rootReducer from "reducers"
import { heapMiddleware } from "middleware/heapMiddleware"

import createSentryMiddleware from "redux-sentry-middleware"
import * as Sentry from "@sentry/browser"
import Settings from "./utils/CarrotConfig"

const configureStore = () => {
  const notProduction = process.env.NODE_ENV !== "production"
  const reduxDevtoolsCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

  const middlewares = [thunk, heapMiddleware, getSentryMiddleware()]
  if (notProduction && Settings.LOG_REDUX_EVENTS) {
    middlewares.push(createLogger())
  }

  const composeEnhancers = notProduction && !!reduxDevtoolsCompose ? reduxDevtoolsCompose : compose

  return createStore(rootReducer, undefined, composeEnhancers(applyMiddleware(...middlewares)))
}

const getSentryMiddleware = () => {
  return createSentryMiddleware(Sentry, {
    actionTransformer: () => null,
    stateTransformer: (state) => ({
      employeeId: state.userInfo.employeeId,
      email: obfuscateEmail(state.userInfo.email),
      partnerEmail: obfuscateEmail(state.userInfo.partnerEmail),
      personalEmail: obfuscateEmail(state.userInfo.personalEmail),
      companyInfo: state.companyInfo,
      isPosingAsMember: state.userInfo.isPosingAsMember
    })
  })
}

const obfuscateEmail = (email) => {
  return email?.replace(/.*@/i, "*@")
}

export default configureStore
