import {
  InputLabel,
  ClickAwayListener,
  InfoIcon,
  Stack,
  Tooltip,
  IconButton,
  InputLabelProps
} from "@carrotfertility/carotene-core"
import React, { useState } from "react"

export type ExtendLabelWithIconTooltipProps = Pick<LabelWithIconTooltipProps, "tooltipTitle">

type LabelWithIconTooltipProps = InputLabelProps & {
  label: React.ReactNode | React.ReactNode[]
  tooltipTitle?: React.ReactNode | React.ReactNode[]
}

export function LabelWithIconTooltip({ label, tooltipTitle, ...otherProps }: LabelWithIconTooltipProps): JSX.Element {
  const [open, setOpen] = useState(false)

  if (tooltipTitle) {
    return (
      <Stack direction="row" alignItems="center" gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)}>
        <InputLabel sx={{ padding: "unset", whiteSpace: "normal" }} {...otherProps}>
          {label}
        </InputLabel>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Tooltip title={tooltipTitle} placement="top" open={open}>
            <IconButton
              size="small"
              onClick={() => setOpen(!open)}
              sx={{ padding: (theme) => theme.spacing(theme.tokens.spacing.none) }}
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
      </Stack>
    )
  }

  return (
    <InputLabel sx={{ padding: "unset", display: "inline" }} {...otherProps}>
      {label}
    </InputLabel>
  )
}
