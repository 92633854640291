import { reimbursementCategoryNameMessageKeys } from "../components/views/reimbursements/reimbursementCategoryNameMessageKeys"

export class CategoryCoverage {
  adoption: boolean
  gc: boolean
  mlt: boolean
  pregnancy: boolean
  gac: boolean
  fertility: boolean
  preservation: boolean
  medication: boolean
  qme: boolean
}

export function getCategoryCoverage(subsidies: any): CategoryCoverage {
  const coverage: CategoryCoverage = new CategoryCoverage()

  subsidies?.forEach((subsidy: any) => {
    switch (subsidy.reimbursementCategoryNameMessageKey) {
      case reimbursementCategoryNameMessageKeys.ADOPTION_AND_GC: {
        coverage.adoption = true
        coverage.gc = true
        break
      }
      case reimbursementCategoryNameMessageKeys.ADOPTION: {
        coverage.adoption = true
        break
      }
      case reimbursementCategoryNameMessageKeys.GC: {
        coverage.gc = true
        break
      }
      case reimbursementCategoryNameMessageKeys.MEDICATION: {
        coverage.medication = true
        break
      }
      case reimbursementCategoryNameMessageKeys.QME: {
        coverage.qme = true
        break
      }
      case reimbursementCategoryNameMessageKeys.M_LT: {
        coverage.mlt = true
        break
      }
      case reimbursementCategoryNameMessageKeys.FERTILITY: {
        coverage.fertility = true
        break
      }
      case reimbursementCategoryNameMessageKeys.FERTILITY_AND_PRESERVATION: {
        coverage.fertility = true
        coverage.preservation = true
        break
      }
      case reimbursementCategoryNameMessageKeys.GAC: {
        coverage.gac = true
        break
      }
      case reimbursementCategoryNameMessageKeys.M_LT_GAC: {
        coverage.mlt = true
        coverage.gac = true
        break
      }
      case reimbursementCategoryNameMessageKeys.PREGNANCY: {
        coverage.pregnancy = true
        break
      }
      case reimbursementCategoryNameMessageKeys.PREGNANCY_M_LT: {
        coverage.pregnancy = true
        coverage.mlt = true
        break
      }
      case reimbursementCategoryNameMessageKeys.PRESERVATION: {
        coverage.preservation = true
        break
      }
    }
  })

  return coverage
}
