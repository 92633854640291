import React from "react"
import { useUserDeductible } from "../../context/user/DeductibleContext"
import { FormattedMessage, useIntl } from "react-intl"
import { Typography, Button, Card } from "@carrotfertility/carotene-core"

type BenefitsEnrollmentRequiredProps = {
  onClickEnroll: () => void
}

const BenefitsEnrollmentRequired = ({ onClickEnroll }: BenefitsEnrollmentRequiredProps): JSX.Element => {
  const { companyDeductibleStatus } = useUserDeductible()
  const intl = useIntl()
  return (
    <Card
      color="success"
      id="home-sidebar-benefit-enrollment-module"
      title={intl.formatMessage({ defaultMessage: "To access your funds, tell us about your health plan" })}
      primaryAction={
        <Button variant="outlined" color="secondary" onClick={onClickEnroll}>
          <FormattedMessage defaultMessage="Share plan info" />
        </Button>
      }
    >
      <Typography>
        {companyDeductibleStatus === "NO_DEDUCTIBLE"
          ? intl.formatMessage({ defaultMessage: "Your insurance coverage can affect how Carrot funds are taxed." })
          : intl.formatMessage({
              defaultMessage: "Your insurance coverage affects how Carrot funds are taxed and if any deductibles apply"
            })}
      </Typography>
    </Card>
  )
}
export default BenefitsEnrollmentRequired
