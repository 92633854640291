import React, { useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Button, ForwardIcon, Typography } from "@carrotfertility/carotene-core"
import { RoutingFlowTitle } from "components/carrot-plans/shared"
import { useRoutingFlow } from "../../shared/RoutingFlowContainer"
import { useHistory } from "react-router"
import { PiiText } from "../../../../services/tracking"
import { useMemberName } from "services/user/derived-selectors/member-info"

export default function ThanksStep(): JSX.Element {
  const intl = useIntl()
  const { setShowProgressTracker } = useRoutingFlow()
  const { setHideCloseButton } = useRoutingFlow()
  const history = useHistory()
  const memberName = useMemberName()

  const header = intl.formatMessage({
    defaultMessage: "Thanks!"
  })

  useEffect(() => {
    setShowProgressTracker(false)
    setHideCloseButton(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  if (memberName.isLoading) {
    return null
  }

  return (
    <>
      <RoutingFlowTitle title={header} />
      <Box display="flex" justifyContent="center">
        <Box
          component="img"
          src={`/images/illo-underwater-homepage.png`}
          srcSet={`/images/illo-underwater-homepage-2x.png`}
          alt=""
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
          width={330}
          height={228}
        />
      </Box>
      <Typography
        color={(theme) => theme.palette.text.primary}
        variant="h3"
        component="h1"
        marginTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
      >
        <FormattedMessage
          defaultMessage="Thanks, {memberPreferredName}"
          values={{
            memberPreferredName: <PiiText>{memberName.data}</PiiText>
          }}
        />
      </Typography>
      <Typography>
        <FormattedMessage defaultMessage="A medical expert will add new suggestions to your Carrot Plan based on the information you shared." />
      </Typography>
      <Box textAlign="right" marginTop={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
        <Button onClick={() => history.push("/")} color="secondary" variant="outlined" endIcon={<ForwardIcon />}>
          <FormattedMessage defaultMessage="Go to Carrot home" />
        </Button>
      </Box>
    </>
  )
}
