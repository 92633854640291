import { useStateMachine } from "components/context/stateMachine/StateMachineContext"
import useSubmitWithErrorCatch from "components/cmd-enrollment/hooks/useSubmitWithErrorCatch"
import { Steps } from "components/cmd-enrollment/workflow/steps"
import { FieldNameTradingPartner, FieldNameHealthPlanId, providerNotListedValue } from "."
import { UpdateHealthPlan } from "../types"
import { UseTradingPartners } from "./useTradingPartners"

export interface UseConnectHealthPlanArgs extends Pick<UseTradingPartners, "availableTradingPartners"> {
  viewName: typeof Steps[keyof typeof Steps]
  onUserUpdate: (healthPlanIdVal: string, tradingPartnerPkVal: string, tradingPartnerId: string) => UpdateHealthPlan
}

export interface UseConnectHealthPlan {
  onSubmit: (...args: any[]) => void
}

export function useConnectHealthPlan({
  viewName,
  onUserUpdate,
  availableTradingPartners
}: UseConnectHealthPlanArgs): UseConnectHealthPlan {
  const { setNextStep } = useStateMachine(viewName, null)
  // @ts-expect-error TS7006
  const { onSubmit } = useSubmitWithErrorCatch(async (args) => {
    const tradingPartnerPkVal = args[FieldNameTradingPartner].value
    const healthPlanIdVal = args[FieldNameHealthPlanId]
    const tradingPartnerId = availableTradingPartners.find((tp) => tp.tradingPartnerPk === tradingPartnerPkVal)?.id
    await onUserUpdate(healthPlanIdVal, tradingPartnerPkVal, tradingPartnerId)
    setNextStep(viewName, tradingPartnerId === providerNotListedValue ? providerNotListedValue : "PROVIDER")
  })
  return {
    onSubmit
  }
}
