import React, { useState } from "react"
import { useCurrentUser } from "../context/user/UserContext"
import EmailVerificationSent from "./EmailVerificationSent"
import SendVerificationButton from "./SendVerficationButton"
import { AppAccessPageLayout } from "components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "components/access-page/AppAccessPageCard"
import { Box, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"

export default function TokenExpired(): JSX.Element {
  const theme = useTheme()
  const { personalEmail } = useCurrentUser()
  const [showEmailSent, setShowEmailSent] = useState(false)

  const intl = useIntl()
  const headerText = intl.formatMessage({ defaultMessage: "Your verification link has expired" })

  return (
    <AppAccessPageLayout title={headerText}>
      <AppAccessPageCard>
        {showEmailSent ? (
          <EmailVerificationSent personalEmail={personalEmail} />
        ) : (
          <>
            <Box textAlign="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
              <Typography variant="h1">{headerText}</Typography>
            </Box>
            <Stack textAlign="center" spacing={theme.spacing(theme.tokens.spacing.lg)}>
              <Typography>
                <FormattedMessage defaultMessage="Simply request a new one below and we'll send it to the email address you provided." />
              </Typography>
              <Box>
                <SendVerificationButton handleEmailSent={() => setShowEmailSent(true)}></SendVerificationButton>
              </Box>
            </Stack>
          </>
        )}
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}
