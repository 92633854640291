import React, { FC } from "react"
import ActivateTemplate from "./ActivateTemplate"
import { useIntl } from "react-intl"
import { IlloCarrotCards } from "../atoms/Illustrations"
import { PageLayout } from "../../../features/global-ui/page-layout"
import { Title } from "../../../services/page-title/Title"
import EmployeeOnlySpaceContainer from "../../employee-only-containers/EmployeeOnlySpaceContainer"

const ActivateDone: FC = () => {
  const intl = useIntl()
  return (
    <PageLayout>
      <EmployeeOnlySpaceContainer>
        <Title title={intl.formatMessage({ defaultMessage: "Carrot Card already activated" })} />
        <ActivateTemplate
          imageComponent={<IlloCarrotCards />}
          header={intl.formatMessage({ defaultMessage: "Carrot Card already activated" })}
          body={intl.formatMessage({ defaultMessage: "Your Carrot Card is already active. You may use it now." })}
          buttonLink="/"
          buttonText={intl.formatMessage({ defaultMessage: "Return home" })}
        />
      </EmployeeOnlySpaceContainer>
    </PageLayout>
  )
}

export default ActivateDone
