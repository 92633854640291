import { RoundedRectangle } from "../../../views/atoms/roundedrectangle/RoundedRectangle"
import { FlexContainer, Stack } from "@carrotfertility/carotene"
import { Box, Link, Typography } from "@carrotfertility/carotene-core"
import { CircleImage } from "../../../views/atoms/images/CircleImage"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"

export const DoulaRequirementsCard = (): JSX.Element => {
  const intl = useIntl()
  return (
    <>
      <RoundedRectangle backgroundColor="light-gray" className="flex-row">
        <FlexContainer shrink={0}>
          <CircleImage iconName="icn-documents" backgroundColor={"black-10"} />
        </FlexContainer>
        <Stack direction="column" spacing="small" justifyContent="flex-end" paddingLeftRight="small">
          <Typography variant="h4">
            <FormattedMessage defaultMessage="Doula requirements" />
          </Typography>
          <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
            <Typography variant="body2">
              <FormattedMessage
                defaultMessage="If you haven't already, send your doula the <link>required attestation form</link> so we can make sure they're eligible."
                values={{
                  link: (linkContent) => (
                    <Link
                      fontSize="inherit"
                      target="_blank"
                      href={"/doula-attestation"}
                      color="inherit"
                      aria-label={intl.formatMessage({ defaultMessage: "Send attestation form to doula" })}
                    >
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage defaultMessage="You can still submit this expense, but we won't be able to reimburse you until we've received the attestation form." />
            </Typography>
          </Box>
        </Stack>
      </RoundedRectangle>
    </>
  )
}
