import { FormattedMessage, useIntl } from "react-intl"
import { useWatch } from "@carrotfertility/carotene-core-x"
import { FormTextFieldWithCharacterCount } from "../../../services/common-forms/FormTextFieldWithCharacterCount"
import React from "react"

export default function ConditionalSomethingElseTextField() {
  const intl = useIntl()
  const requiredText = intl.formatMessage({ defaultMessage: "Required" })
  const selectedValue = useWatch({ name: "journey" })
  if (selectedValue !== "SOMETHING_ELSE") {
    return null
  }
  return (
    <FormTextFieldWithCharacterCount
      name="somethingElseJourney"
      helperText={<FormattedMessage defaultMessage="More information about your journey" />}
      registerOptions={{ required: requiredText }}
    />
  )
}
