import { getCountryUsesImperialUnits } from "../../utils/getCountryUsesImperialUnits"
import { useUserContext } from "../../context/UserContext"
import React from "react"
import { ProviderFinderParam, useStringSearchParam } from "../../utils/providerFinderSearchUrl"
import { useBroadAreaContext } from "../../context/BroadAreaContext"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, MenuItem, Select, Typography } from "@carrotfertility/carotene-core"

const MILE_OPTIONS = [1, 5, 10, 20, 50, 100] as const
const KILOMETER_OPTIONS = [1, 2, 5, 10, 20, 50, 100, 200] as const
const KILOMETERS_PER_MILE = 1.609

type RadiusUnit = {
  readonly options: readonly number[]
  readonly format: (radius: number) => string
  readonly getSelectOptionValue: (radius: number) => string
}

export function RadiusSelect(): JSX.Element {
  const { companyCountryCode } = useUserContext()
  const countryUsesImperialUnits = getCountryUsesImperialUnits(companyCountryCode)
  const { broadAreaSearched } = useBroadAreaContext()
  const intl = useIntl()

  const kilometer: RadiusUnit = {
    options: KILOMETER_OPTIONS,
    format: (kilometerRadius: number): string =>
      intl.formatNumber(kilometerRadius, { style: "unit", unit: "kilometer" }),
    getSelectOptionValue: (kilometerRadius: number): string => kilometerRadius.toFixed(1)
  }

  const mile: RadiusUnit = {
    options: MILE_OPTIONS,
    format: (mileRadius: number): string => intl.formatNumber(mileRadius, { style: "unit", unit: "mile" }),
    getSelectOptionValue: (mileRadius: number): string =>
      kilometer.getSelectOptionValue(mileRadius * KILOMETERS_PER_MILE)
  }

  const radiusUnit = countryUsesImperialUnits ? mile : kilometer

  const ANYWHERE_IN_COUNTRY_OPT = {
    value: "",
    label: intl.formatMessage({ defaultMessage: "Anywhere in the country" })
  }

  const selectOptions = [
    ...radiusUnit.options.map((radius: number) => {
      return {
        value: radiusUnit.getSelectOptionValue(radius),
        label: intl.formatMessage({ defaultMessage: "Within {radius}" }, { radius: radiusUnit.format(radius) })
      }
    }),
    ANYWHERE_IN_COUNTRY_OPT
  ]

  const [radiusSearchParam, setradiusSearchParam] = useStringSearchParam(ProviderFinderParam.RadiusInKm)

  const selectedOption = selectOptions.find((option) => option.value === radiusSearchParam) || ANYWHERE_IN_COUNTRY_OPT

  return (
    <>
      <Select
        required
        defaultValue={selectedOption.value}
        onChange={(event) => setradiusSearchParam(event.target.value)}
        aria-label={intl.formatMessage({ defaultMessage: "Select search radius" })}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
          }
        }}
      >
        {selectOptions.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      {broadAreaSearched ? (
        <Box>
          <Typography variant="body2" color="secondary" paddingLeft={(theme) => theme.spacing(theme.tokens.spacing.md)}>
            <FormattedMessage defaultMessage="This is a large area. Try narrowing your search parameters for the best results." />
          </Typography>
        </Box>
      ) : null}
    </>
  )
}
