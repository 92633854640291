import React, { ReactElement, useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useFlags } from "launchdarkly-react-client-sdk"

export default function SocialDeterminantsOfHealthDecisionStep(): ReactElement {
  const { sDoHIntegration } = useFlags()
  const { send } = useStateMachine()
  useEffect(() => {
    send(sDoHIntegration ? "YES" : "NO")
  })

  return <></>
}
