import Settings from "../../utils/CarrotConfig"
import { SupportCenterLinks } from "../../utils/SupportCenterLinks"
import { goToMobileHelpCenter, isMobileApp } from "./MobileNativeClient"
import { MouseEvent } from "react"

const SupportCenterBaseUri = Settings.ZENDESK_GUIDE

const parseZendeskFragmentFromUrl = (url: string) => {
  const urlPathname = new URL(url).pathname
  if (urlPathname === "/hc" || urlPathname === "/hc/") return null
  const filteredUrl = urlPathname?.split("/").filter((path) => path !== "hc" && path !== "")
  return filteredUrl.join("/")
}

const goToHelpCenter = async (event: MouseEvent<HTMLElement>, link?: string) => {
  if (isMobileApp()) {
    event.preventDefault()
    await goToMobileHelpCenter(link)
  }
}

const goToHelpCenterLegacy = async (link?: SupportCenterLinks) => {
  if (isMobileApp()) {
    await goToMobileHelpCenter(link)
  } else {
    window.open(getSupportCenterLink(link), "_blank")
  }
}

const getSupportCenterLink = (link?: string) => {
  return isNullOrUndefined(link) ? SupportCenterBaseUri : `${SupportCenterBaseUri}/${link}`
}

// @ts-expect-error TS7006
const isNullOrUndefined = (link) => {
  return typeof link === "undefined" || link == null
}

// @ts-expect-error TS7006
const isZendeskLink = (href) => {
  try {
    const url = new URL(href)
    return url?.host?.match(/\.*\.zendesk\.com/)
  } catch (e) {
    return false
  }
}

const getLocalizedZendeskLink = (link: string, zendeskLocale: string): string => {
  const linkWithoutLocale = link.replace(/\/en-us/i, "")
  return linkWithoutLocale.replace("hc", `hc/${zendeskLocale}`)
}

export {
  goToHelpCenterLegacy,
  goToHelpCenter,
  getSupportCenterLink,
  isZendeskLink,
  parseZendeskFragmentFromUrl,
  getLocalizedZendeskLink
}
