// Used to help prevent the need for ts-ignores everywhere
import { MobileBridgeClient } from "./MobileBridgeClient"
import {
  initPushNotificationsGlobalCallback,
  pushNotificationsControl,
  updatePushNotificationsControl
} from "./pushNotificationsControl"
import {
  initLoginGlobalCallbacks,
  getCarrotBridgePayload,
  updateMobileLoginControls,
  mobileLoginControls
} from "./loginControl"
import { initGlobalCallback, mobileAppControl, updateMobileAppControl } from "./mobileAppControl"

const appWindow: Record<string, any> = window

export class IOSMobileBridgeClient implements MobileBridgeClient {
  static bridge = appWindow?.webkit?.messageHandlers?.carrotBridge
  static isIOSMobileClient = () => Boolean(this.bridge)

  constructor() {
    initLoginGlobalCallbacks()
    initPushNotificationsGlobalCallback()
    initGlobalCallback()
  }

  // @ts-expect-error TS7006
  helpCenter = (token, zdType, id) => {
    const payload = getCarrotBridgePayload(token, zdType, id)
    appWindow.webkit.messageHandlers.carrotBridge.postMessage(payload)
  }

  isBiometricsCapable = async () => {
    const payload = {
      action: "isBiometricsCapable"
    }
    updateMobileLoginControls("_isBiometricsCapable", null)

    await IOSMobileBridgeClient.bridge.postMessage(payload)
    return mobileLoginControls._isBiometricsCapable
  }

  isBiometricLoginAlreadyConfigured = async () => {
    const payload = {
      action: "isBiometricLoginAlreadyConfigured"
    }
    updateMobileLoginControls("_isBiometricLoginAlreadyConfigured", null)

    await IOSMobileBridgeClient.bridge.postMessage(payload)
    return mobileLoginControls._isBiometricLoginAlreadyConfigured
  }

  disableBiometrics = async () => {
    const payload = {
      action: "disableBiometrics"
    }
    await IOSMobileBridgeClient.bridge.postMessage(payload)
  }

  attemptBiometricsCredentialsRetrieval = async (login: () => Promise<unknown>) => {
    updateMobileLoginControls("_login", login)
    updateMobileLoginControls("_bioAuthenticatedUsername", null)
    updateMobileLoginControls("_bioAuthenticatedPassword", null)

    const payload = {
      action: "attemptBiometricsCredentialsRetrieval"
    }
    await IOSMobileBridgeClient.bridge.postMessage(payload)
  }

  showBiometricsEnrollment = async (
    username: string,
    password: string,
    biometricEnrollmentSuccess: () => void,
    biometricEnrollmentFailure: () => void
  ) => {
    updateMobileLoginControls("_biometricEnrollmentSuccess", biometricEnrollmentSuccess)
    updateMobileLoginControls("_biometricEnrollmentFailure", biometricEnrollmentFailure)

    const payload = {
      action: "showBiometricsEnrollment",
      data: {
        username: `${username}`,
        password: `${password}`
      }
    }
    await IOSMobileBridgeClient.bridge.postMessage(payload)
  }

  getAppVersion = async () => {
    const payload = {
      action: "initializeAppVersion"
    }
    updateMobileAppControl("_appVersion", null)

    await IOSMobileBridgeClient.bridge.postMessage(payload)
    return mobileAppControl._appVersion
  }

  isPushPermissionsEnabled = async () => {
    const payload = {
      action: "updatePushPermissionsEnabled"
    }
    updatePushNotificationsControl("_isPushPermissionsEnabled", null)

    await IOSMobileBridgeClient.bridge.postMessage(payload)
    return pushNotificationsControl._isPushPermissionsEnabled
  }

  promptPushPermissions = async () => {
    updatePushNotificationsControl("_promptPushPermissions", null)
    const payload = {
      action: "promptPushPermissions"
    }
    await IOSMobileBridgeClient.bridge.postMessage(payload)
  }

  setUserInfo = async (email: string, employeeId: number) => {
    const payload = {
      action: "setUserInfo",
      data: {
        email: email,
        employeeId: employeeId
      }
    }
    await IOSMobileBridgeClient.bridge.postMessage(payload)
  }
}
