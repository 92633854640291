export default class StripeResponseError extends Error {
  code?: string
  correlationId: string
  response: Response
  errorMessage: string

  constructor(response: Response, correlationId: string, errorMessage: string, code?: string) {
    super()
    this.response = response
    this.correlationId = correlationId
    this.code = code
    this.errorMessage = errorMessage
  }
}
