import { Box, Chip, ClickAwayListener, InfoIcon, Tooltip, useTheme } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import React from "react"

export const VirtualServicesTag = (): React.ReactElement => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const handleTooltipClose = () => {
    setOpen(false)
  }
  const handleTooltipOpen = () => {
    setOpen(!open)
  }

  return (
    <Box marginTop={theme.spacing(theme.tokens.spacing.md)} data-tooltip-id="virtual-services-chip">
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          onClose={handleTooltipClose}
          open={open}
          title={
            <FormattedMessage defaultMessage="Please note that the providers listed here have indicated that they provide virtual services. In sharing this list, Carrot makes no guarantee that an individual provider will be able to provide members with virtual services. We recommend contacting a provider directly to confirm if the services offered by the provider meet your needs." />
          }
        >
          <Chip
            size="small"
            label={
              <Box display="flex" flexDirection="row" alignItems="center">
                <FormattedMessage defaultMessage="Virtual services" />
                &nbsp;
                <InfoIcon color="inherit" fontSize="inherit" />
              </Box>
            }
            sx={{ backgroundColor: theme.palette.success.light }}
            onClick={handleTooltipOpen}
          />
        </Tooltip>
      </ClickAwayListener>
    </Box>
  )
}
