import React from "react"
import PropTypes from "prop-types"
import { Redirect, Route } from "react-router-dom"
import { AppContainer } from "components/containers/app/AppContainer"
import LoginContainer from "components/containers/login/LoginContainer"
import "css/transitions.css"
import RegisterWizard from "components/views/register/RegisterWizard"
import { FadeTransition } from "./molecules/Molecules"
import ResetPasswordContainer from "../containers/login/ResetPasswordContainer"
import RequestResetPasswordContainer from "../containers/login/RequestResetPasswordContainer"
import ResetPasswordSuccess from "../../pages/reset-password-success"
import MerchantDeclineContainer from "../containers/merchantdecline/MerchantDeclineContainer"
import SignUp from "pages/signup"
import SignUpSuccess from "pages/signup-success"
import SignUpFindAccount from "pages/signup-find-account"
import SignUpFindAccountSuccess from "pages/signup-find-account-success"
import FindAccountByExternalEmployeeIdSuccess from "pages/signup-find-account-by-external-employee-id-success"
import SignUpGetHelp from "../../pages/signup-get-help"
import SignUpGetHelpSuccess from "../../pages/signup-get-help-success"
import SignUpEmployeeAlreadyRegistered from "../../pages/signup-employee-already-registered"
import SignUpEmployeeHasSaml from "../../pages/signup-employee-has-saml"
import SignUpInfo from "pages/signup/info"
import { withLDProvider } from "launchdarkly-react-client-sdk"
import Settings from "../../utils/CarrotConfig"
import { BiometricAuthEnrollmentContextProvider } from "../context/BiometricAuthEnrollment/BiometricAuthEnrollmentContext"
import { Helmet } from "react-helmet"
import PartnerRegistrationFlow from "../partner-access/partner-registration/PartnerRegistrationFlow"
import { LoadingInner } from "./Loading"
import SamlNoEmailOnboardingCaptureEmail from "../../pages/saml-no-email-onboarding-capture-email"
import { Switch } from "../../services/routing/Switch"
import { DEFAULT_LOCALE } from "../../types/Locale"
import { useLocaleContext } from "components/context/user/LocaleContext"
import { CarrotRxExtend } from "pages/carrot-rx-extend"

const Root = ({ loggedIn, loadingIsLoggedIn }) => {
  const { didCompiledMessagesLoad } = useLocaleContext()
  const showPage = didCompiledMessagesLoad && !loadingIsLoggedIn

  return (
    <div>
      <Helmet>
        <html lang={DEFAULT_LOCALE.toString()} />
      </Helmet>
      {showPage ? (
        <Switch allowedRouters={[NonAuthenticatedRoutesContainer]}>
          <Route path="/register/partner/:guid" component={PartnerRegistrationFlow} />
          <Route path="/register/:guid" component={RegisterWizard} />
          <Route path="/saml-no-email-onboarding" component={SamlNoEmailOnboardingCaptureEmail} />
          <Route path={"/carrot-rx-extend"} component={CarrotRxExtend} />
          {loggedIn ? <Route render={() => <AppContainer key="app" />} /> : <NonAuthenticatedRoutesContainer />}
        </Switch>
      ) : (
        <LoadingInner />
      )}
    </div>
  )
}

const NonAuthenticatedRoutesContainer = () => {
  return (
    <Switch>
      <Route path="/signup/info/:parentCompanyId" component={SignUpInfo} />
      <Route path="/signup/info" component={SignUpInfo} />
      <Route
        path="/signup"
        render={() => (
          <FadeTransition>
            <SignUp />
          </FadeTransition>
        )}
      />

      <Route path="/signup-success" component={SignUpSuccess} />
      <Route path="/signup-find-account" component={SignUpFindAccount} />
      <Route path="/signup-find-account-success" component={SignUpFindAccountSuccess} />
      <Route
        path="/signup-find-account-by-external-employee-id-success"
        component={FindAccountByExternalEmployeeIdSuccess}
      />
      <Route path="/signup-get-help" component={SignUpGetHelp} />
      <Route path="/signup-get-help-success" component={SignUpGetHelpSuccess} />
      <Route path="/signup-employee-already-registered" component={SignUpEmployeeAlreadyRegistered} />
      <Route path="/signup-employee-has-saml" component={SignUpEmployeeHasSaml} />
      <Route path="/reset-password-success" component={ResetPasswordSuccess} />
      <Route path="/reset-password/:guid" component={ResetPasswordContainer} />
      <Route path="/request-reset-password" component={RequestResetPasswordContainer} />
      <Route path="/confirm/:token" component={MerchantDeclineContainer} />

      <Redirect exact path="/sign-up" to="/signup" />
      {/* This needs to stay on the bottom since it doesn't have a path and will override any routes declared below it. */}
      <Route
        render={() => (
          <FadeTransition>
            <BiometricAuthEnrollmentContextProvider>
              <LoginContainer key="login" />
            </BiometricAuthEnrollmentContextProvider>
          </FadeTransition>
        )}
      />
    </Switch>
  )
}

Root.propTypes = {
  loggedIn: PropTypes.bool.isRequired
}

export default withLDProvider({
  clientSideID: Settings.LAUNCH_DARKLY_CLIENT_ID,
  user: {
    key: "anon",
    email: "anon@get-carrot.com"
  }
})(Root)
