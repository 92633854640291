import React from "react"
import { FlexContainer, H2, Stack, Text } from "@carrotfertility/carotene"
import ArrowSubmitButton from "features/legacy-components/ArrowSubmitButton"
import { BackArrow } from "components/views/atoms/Molecules"
import { Progress } from "components/views/modal/Progress"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { useReimbursementContext } from "components/context/reimbursement/GetReimbursedContext"
import { FormattedMessage, useIntl } from "react-intl"
import { FormRadioButtonGroup } from "../../../services/common-forms"
import { Form } from "@carrotfertility/carotene-core-x"

export const AboutThisExpenseRelatedToStepchildAdoption = (): JSX.Element => {
  const { send, back } = useStateMachine()
  const { state, updateStepchildAdoption } = useReimbursementContext()
  // @ts-expect-error TS7006
  const onSubmit = (value): void => {
    updateStepchildAdoption(value.isStepchildAdoption)
    send("")
  }
  const intl = useIntl()

  return (
    <>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <Form defaultValues={{ isStepchildAdoption: state.isStepchildAdoption }} onSubmit={onSubmit}>
        <Stack minHeight="500px" paddingTopBottom="huge">
          <H2 id="step-heading">
            <FormattedMessage defaultMessage="Is this related to adopting a spouse's child (stepparent adoption)?" />
          </H2>
          <Text>
            <FormattedMessage
              defaultMessage="This helps us determine whether an expense is taxable so we can process reimbursements in a legally
            compliant way."
            />
          </Text>
          <FormRadioButtonGroup
            aria-labelledby="step-heading"
            name="isStepchildAdoption"
            controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
            options={[
              { label: intl.formatMessage({ defaultMessage: "Yes" }), value: "true" },
              { label: intl.formatMessage({ defaultMessage: "No" }), value: "false" }
            ]}
          />
        </Stack>
        <FlexContainer paddingTopBottom="huge" justifyContent="flex-end">
          <ArrowSubmitButton>
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        </FlexContainer>
      </Form>
    </>
  )
}
