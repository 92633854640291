import React from "react"
import { AnythingElse } from "../steps/IsThereAnythingElse"
import { AboutThisExpenseCareRelatedToInfertilityDiagnosis } from "../steps/RelatedToInfertilityDiagnosis"
import { AboutThisExpenseExpenseRelatedTo } from "../steps/ExpenseRelatedTo"
import { AboutThisExpenseMedicallyNecessary } from "../steps/MedicallyNecessary"
import { UploadStatement } from "../steps/UploadStatement"
import { AboutThisExpenseWhatKindOfFertilityCare } from "../steps/WhatKindOfFertilityCare"
import { AboutThisExpenseWhoReceivedCare } from "../steps/WhoReceivedCare"
import { Steps } from "./steps"
import { GetReimbursedFinalStep } from "../steps/FinalStep"
import { GetReimbursedDoIvfRequirement } from "../steps/DoIvfRequirement"
import { reimbursementWorkflow } from "./workflow"
import { AboutThisExpenseRelatedToStepchildAdoption } from "../steps/RelatedToStepchildAdoption"
import { AboutThisExpenseEligibleReimbursementAmount } from "../steps/EligibleReimbursementAmount"
import { InternationalAddressUpdateDecision } from "../steps/InternationalAddressUpdateDecision"
import { InternationalAddressUpdate } from "../steps/InternationalAddressUpdate"
import { AboutThisExpenseWhatKindOfService } from "../steps/WhatKindOfService"
import { AboutThisExpenseRelatedToStorageFees } from "../steps/RelatedToStorageFees"
import { AttestationMedicallyNecessaryAndEligible } from "../steps/AttestationMedicallyNecessaryAndEligible"
import { AttestationMedicallyRelated } from "../steps/AttestationMedicallyRelated"
import { BeforeYouContinueWarning } from "../steps/BeforeYouContinueWarning"
import { AboutThisExpenseRelatedToMedication } from "../steps/RelatedToMedication"

//prettier-ignore
export const reimbursementSection = {
  name: "ReimbursementSection",
  initialStep: Steps.BEFORE_YOU_CONTINUE_WARNING,
  desiredWorkflow: "Primary Workflow",
  steps: [
    { name: Steps.BEFORE_YOU_CONTINUE_WARNING, component: () => <BeforeYouContinueWarning/> },
    { name: Steps.INTERNATIONAL_ADDRESS_UPDATE_DECISION, component: () => <InternationalAddressUpdateDecision/> },
    { name: Steps.INTERNATIONAL_ADDRESS_UPDATE, component: () => <InternationalAddressUpdate/> },
    { name: Steps.UPLOAD_STATEMENT, component: () => <UploadStatement /> },
    { name: Steps.ELIGIBLE_REIMBURSEMENT_AMOUNT, component: () => <AboutThisExpenseEligibleReimbursementAmount />},
    { name: Steps.WHO_RECEIVED_CARE, component: () => <AboutThisExpenseWhoReceivedCare /> },
    { name: Steps.EXPENSE_RELATED_TO, component: () => <AboutThisExpenseExpenseRelatedTo /> },
    { name: Steps.STEPCHILD_ADOPTION, component: () => <AboutThisExpenseRelatedToStepchildAdoption /> },
    { name: Steps.WHAT_KIND_OF_FERTILITY_CARE, component: () => <AboutThisExpenseWhatKindOfFertilityCare /> },
    { name: Steps.WHAT_KIND_OF_SERVICE, component: () => <AboutThisExpenseWhatKindOfService /> },
    { name: Steps.ATTESTATION_MEDICALLY_NECESSARY_AND_ELIGIBLE, component: () => <AttestationMedicallyNecessaryAndEligible />},
    { name: Steps.ATTESTATION_MEDICALLY_RELATED, component: () => <AttestationMedicallyRelated />},
    { name: Steps.RELATED_TO_STORAGE_FEES, component: () => <AboutThisExpenseRelatedToStorageFees />},
    { name: Steps.RELATED_TO_MEDICATION, component: () => <AboutThisExpenseRelatedToMedication />},
    { name: Steps.RELATED_TO_INFERTILITY_DIAGNOSIS, component: () => <AboutThisExpenseCareRelatedToInfertilityDiagnosis /> },
    { name: Steps.MEDICALLY_NECESSARY, component: () => <AboutThisExpenseMedicallyNecessary /> },
    { name: Steps.ANYTHING_ELSE, component: () => <AnythingElse /> },
    { name: Steps.FINAL_STEP, component: () => <GetReimbursedFinalStep /> },
    { name: Steps.DO_IVF_REQUIREMENT, component: () => <GetReimbursedDoIvfRequirement /> }
  ],
  workflows: reimbursementWorkflow
}
