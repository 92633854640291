import useUpdateThread from "./hooks/useUpdateThread"
import { useBreakpointComponent } from "@carrotfertility/carotene"
import { Form } from "@carrotfertility/carotene-core-x"
import React from "react"
import { useInAppMessaging } from "../context/messaging/InAppMessagingContext"
import { InAppMessagingThread } from "./inAppMessagingTypes"
import useCreateThread from "./hooks/useCreateThread"
import useGetThreads from "./hooks/useGetThreads"
import { reportErrorMessage } from "../../utils/ErrorReporting"
import { useIntl } from "react-intl"
import { Box } from "@carrotfertility/carotene-core"
import { FormTextFieldWithCharacterCount } from "services/common-forms/FormTextFieldWithCharacterCount"
import { FormSendIconButton, FormSendButton } from "./InAppMessagingFormButtons"

type MessageReplyContainerProps = {
  thread: InAppMessagingThread
  scrollThreadsListToTop: () => void
}

const SendButtonContainer = (): JSX.Element => {
  const sendButton = useBreakpointComponent([
    <FormSendIconButton key="smallest" />,
    <FormSendIconButton key="small" />,
    <FormSendButton key="med" />,
    <FormSendButton key="large" />
  ])

  return (
    <Box justifyContent="flex-end" width="auto" paddingY={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
      {sendButton}
    </Box>
  )
}

export function MessageReplyContainer({ thread, scrollThreadsListToTop }: MessageReplyContainerProps): JSX.Element {
  const { mutateAsync: updateThreadAsync } = useUpdateThread()
  const { mutateAsync: createThreadAsync } = useCreateThread()
  const { refetch: refetchThreads } = useGetThreads()
  const { setThreadIdForFailedUpdate, setThreadUpdateInFlight, setSelectedThread } = useInAppMessaging()
  const intl = useIntl()

  // this is a workaround to capture the div that surrounds the helper text message on the Carotene Textarea component
  const textAreaRef = React.useRef(null)

  // @ts-expect-error TS7006
  async function onSubmit(values, instance): Promise<void> {
    setThreadUpdateInFlight(true)

    if (thread.isClosed) {
      values.viaFollowUpSourceId = thread.threadId
      values.subject = intl
        .formatMessage({ defaultMessage: "Re: {subject}" }, { subject: thread.subject })
        .substring(0, 150)

      try {
        const followupThread = await createThreadAsync(values)
        await refetchThreads()
        setSelectedThread(followupThread)
        setThreadIdForFailedUpdate(null)
        setThreadUpdateInFlight(false)
        scrollThreadsListToTop()
        instance.reset()
      } catch (error) {
        reportErrorMessage(`Unable to create follow up thread for thread id: ${thread.threadId}. Error: ${error}`)
        setThreadIdForFailedUpdate(thread.threadId)
        setThreadUpdateInFlight(false)
      }
    } else {
      values.threadId = thread.threadId

      try {
        await updateThreadAsync(values)
        instance.reset()
        setThreadIdForFailedUpdate(null)
        setThreadUpdateInFlight(false)
      } catch (error) {
        reportErrorMessage(`Unable to update or reply to thread id: ${thread.threadId}. Error: ${error}`)
        setThreadIdForFailedUpdate(thread.threadId)
        setThreadUpdateInFlight(false)
      }
    }
  }

  return (
    <Box>
      <Form onSubmit={onSubmit}>
        <Box
          className="input-group flex"
          justifyContent="space-between"
          marginLeft={(theme) => theme.spacing(theme.tokens.spacing.xl)}
          marginRight={(theme) => theme.spacing(theme.tokens.spacing.md)}
        >
          <Box width="100%" marginRight={(theme) => theme.spacing(theme.tokens.spacing.sm)}>
            <Box ref={textAreaRef} id="text-container">
              <FormTextFieldWithCharacterCount
                name="message"
                aria-label={intl.formatMessage({ defaultMessage: "Message" })}
                placeholder={intl.formatMessage({ defaultMessage: "Write a message..." })}
                required
                maxLength={1600}
                minRows={1}
                maxRows={9}
                rows={null} // TODO: OP-1041 will clean this up
                helperText={intl.formatMessage({ defaultMessage: "We usually respond within one business day." })}
              />
            </Box>
          </Box>
          <SendButtonContainer />
        </Box>
      </Form>
    </Box>
  )
}
