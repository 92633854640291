import React from "react"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { Form } from "@carrotfertility/carotene-core-x"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  SkipButton,
  StepLayout,
  useConvertTranslatedLabel
} from "components/carrot-plans/shared"
import { FormRadioButtonGroup } from "services/common-forms"

export default function CyclesAreRegularStep(): JSX.Element {
  const intl = useIntl()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultValue = medicalInfo?.cyclesAreRegular

  const { send } = useStateMachine(hasQuestionBeenSkipped("cyclesAreRegular") ? "" : defaultValue)
  const buttonOptions = [
    { label: defineMessage({ defaultMessage: "Yes" }), value: "YES" },
    { label: defineMessage({ defaultMessage: "No" }), value: "NO" },
    { label: defineMessage({ defaultMessage: "I'm not sure" }), value: "NOT_SURE" }
  ]
  const radioOptions = useConvertTranslatedLabel(buttonOptions)

  async function onClickContinue(formValues: { cyclesAreRegular: string }): Promise<void> {
    if (formValues.cyclesAreRegular !== null) {
      await updateMedicalInfo({ cyclesAreRegular: formValues.cyclesAreRegular })
      send(formValues.cyclesAreRegular)
    } else {
      await onSkip()
    }
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["cyclesAreRegular"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Do you have regular menstrual cycles?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="cyclesAreRegular-label">{header}</span>}
        description={intl.formatMessage({
          defaultMessage:
            'A "regular" cycle is approximately 25-35 days, when you\'re not pregnant or on medication like birth control.'
        })}
        formElements={
          <Form onSubmit={onClickContinue} defaultValues={{ cyclesAreRegular: defaultValue }}>
            <FormRadioButtonGroup
              name="cyclesAreRegular"
              options={radioOptions}
              aria-labelledby="cyclesAreRegular-label"
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
