import React from "react"
import { useWatch, FormTextField, FormTextFieldProps } from "@carrotfertility/carotene-core-x"
import { Box } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"

interface FormTextFieldWithCharacterCountProps extends Omit<FormTextFieldProps, "label" | "variant"> {
  maxLength?: number
  label?: string
}

export function FormTextFieldWithCharacterCount({
  name,
  maxLength = 200,
  helperText,
  label,
  rows = 2,
  ...otherProps
}: FormTextFieldWithCharacterCountProps) {
  const value = useWatch({ name })
  return (
    <FormTextField
      {...otherProps}
      registerOptions={{ shouldUnregister: true, ...otherProps.registerOptions }}
      multiline
      rows={rows}
      inputProps={{ ...otherProps.inputProps, maxLength, "aria-labelledby": label ? undefined : `${name}-label` }}
      name={name}
      label={label}
      helperText={
        <Box display="flex" justifyContent={helperText ? "space-between" : "flex-end"}>
          {helperText && <p id={`${name}-label`}>{helperText}</p>}
          <p>
            <FormattedMessage
              defaultMessage="{charactersRemaining, number} / {maximumCharactersAllowed, number}"
              values={{
                charactersRemaining: value ? value.length : 0,
                maximumCharactersAllowed: maxLength
              }}
            />
          </p>
        </Box>
      }
    />
  )
}
