import { Button } from "@carrotfertility/carotene-core"
import React, { useState } from "react"
import { isHttpError } from "../../utils/HttpErrors"
import { CarrotErrorCodes } from "../../utils/CarrotErrors"
import { getHeap } from "../../utils/heap"
import useEmailVerification from "./useEmailVerification"
import { useHistory } from "react-router"
import { useIntl } from "react-intl"

export default function SendVerificationButton({ handleEmailSent }: { handleEmailSent: () => void }): JSX.Element {
  const { sendVerificationEmail } = useEmailVerification()
  const [verificationSending, setVerificationSending] = useState(false)
  const history = useHistory()
  const intl = useIntl()

  async function sendVerification(): Promise<void> {
    setVerificationSending(true)
    try {
      await sendVerificationEmail()
    } catch (err) {
      if (isHttpError(err)) {
        const { code } = await err.response.json()
        if (code === CarrotErrorCodes.EMAIL_VERIFICATION_MISSING_PERSONAL_EMAIL) {
          getHeap().track("MissingPersonalEmail", {
            EventName: "Attempted to send email verification without a personal email"
          })
          history.push("/no-personal-email")
        }
      }
    }
    handleEmailSent()
  }

  return (
    <Button onClick={sendVerification} disabled={verificationSending} id="sendVerification">
      {verificationSending
        ? intl.formatMessage({ defaultMessage: "Sending verification" })
        : intl.formatMessage({ defaultMessage: "Send a new verification link" })}
    </Button>
  )
}
