import React from "react"
import { CaseRateRenewalModalButtonGroup } from "./CaseRateRenewalModalButtonGroup"
import { useSetCarrotPlanRenewalDismissed } from "./useQueryCaseRateRenewals"
import { useHistory } from "react-router-dom"
import { useCaseRateRenewals } from "./useCaseRateRenewals"
import { useStateMachine } from "../../context/stateMachine/StateMachineV2"
import { FormattedMessage } from "react-intl"
import { Box, Button, Typography } from "@carrotfertility/carotene-core"

export function AdvancedCheckInWarning(): JSX.Element {
  const { mutate } = useSetCarrotPlanRenewalDismissed()
  const { setOpenGatedAccountModal } = useCaseRateRenewals()
  const history = useHistory()
  const { send } = useStateMachine(null)
  const { setRequestError } = useCaseRateRenewals()

  async function onCloseStaleAdvancedCheckIn(): Promise<void> {
    mutate(null, {
      onSuccess: () => {
        setOpenGatedAccountModal(false)
        history.push("/")
      },
      onError: () => {
        history.push("/")
        send("ERROR")
        setRequestError(true)
      }
    })
  }

  return (
    <Box>
      <Typography variant="h2" color={(theme) => theme.palette.text.primary}>
        <FormattedMessage defaultMessage="Close without updating your answers?" />
      </Typography>
      <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
        <FormattedMessage defaultMessage="We'll check in again soon." />
      </Typography>
      <CaseRateRenewalModalButtonGroup
        buttonOne={
          <Button onClick={() => history.goBack()} variant="outlined" color="inherit">
            <FormattedMessage defaultMessage="No, continue updating" />
          </Button>
        }
        buttonTwo={
          <Button onClick={onCloseStaleAdvancedCheckIn} variant="contained">
            <FormattedMessage defaultMessage="Close" />
          </Button>
        }
      ></CaseRateRenewalModalButtonGroup>
    </Box>
  )
}
