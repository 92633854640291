import { useStateMachine } from "components/context/stateMachine/StateMachineContext"
import { FlexRowCenter } from "components/views/atoms/Atoms"
import { IlloCoinsAndBills } from "components/views/atoms/Illustrations"
import { BankInfoOptionButtons } from "../shared/BankInfoOptionButtons"
import { Steps } from "../workflow/steps"
import { getShouldFillOutBankInfo } from "derivedSelectors"
import React from "react"
import { useSelector } from "react-redux"
import { useEnrollmentFlowModal } from "../EnrollmentModal"
import { Workflows } from "../workflow/workflows"
import { GetWorkflow } from "./BenefitEnrollmentFlow"
import { FormattedMessage } from "react-intl"
import { Typography } from "@carrotfertility/carotene-core"

const ViewName = Steps.CONNECT_INSURANCE_NOT_REQUIRED

function getText(workflowType: Workflows, needsBankInfo: boolean): JSX.Element {
  let allSetText
  switch (workflowType) {
    case Workflows.ACA_ONLY:
      allSetText = needsBankInfo ? (
        <></>
      ) : (
        <Typography
          color={(theme) => theme.palette.text.secondary}
          paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        >
          <FormattedMessage defaultMessage="We have all the information we need for now." />
        </Typography>
      )
      break
    default:
      allSetText = (
        <Typography
          color={(theme) => theme.palette.text.secondary}
          paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        >
          <FormattedMessage defaultMessage="It looks like you do not have a deductible associated with your Carrot benefit. That means we can start reimbursing eligible expenses right away." />
        </Typography>
      )
  }

  return <>{allSetText}</>
}

function ConnectInsuranceNotRequiredView(): JSX.Element {
  const { onExit } = useEnrollmentFlowModal()
  const needsBankInfo = useSelector(getShouldFillOutBankInfo)
  useStateMachine(ViewName, null)
  const workflowType = GetWorkflow()

  return (
    <div>
      <FlexRowCenter>
        <IlloCoinsAndBills />
      </FlexRowCenter>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
      >
        <FormattedMessage defaultMessage="You're all set." />
      </Typography>
      {getText(workflowType, needsBankInfo)}
      <BankInfoOptionButtons {...{ needsBankInfo, onExit }} />
    </div>
  )
}

export { ConnectInsuranceNotRequiredView }
