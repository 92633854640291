import { MessageDescriptor, defineMessage } from "react-intl"

enum CarrotDisclaimers {
  CARROT_RESOURCES = "CARROT_RESOURCES",
  INCLUSIVE_LANGUAGE = "INCLUSIVE_LANGUAGE",
  SERVICES_LEGALITY_AVAILABILITY = "SERVICES_LEGALITY_AVAILABILITY"
}

export const carrotDisclaimerMessages: Record<CarrotDisclaimers, MessageDescriptor> = {
  CARROT_RESOURCES: defineMessage({
    defaultMessage:
      "Carrot resources are for informational purposes only and do not constitute medical advice or professional services. They are not a substitute for legal, tax, or professional medical advice, diagnosis, or treatment. If you are experiencing a medical emergency, call emergency services immediately."
  }),
  INCLUSIVE_LANGUAGE: defineMessage({
    defaultMessage:
      "We strive to be as clear and inclusive as possible. When appropriate, we use terminology that reflects current medical practice. We recognize that these terms do not capture every individual's experience."
  }),
  SERVICES_LEGALITY_AVAILABILITY: defineMessage({
    defaultMessage:
      "Legality and availability of services vary from country to country and often even within a country. Contact your Care Team for help understanding what services are available to you domestically or through cross-border care.",
    description:
      "Legal disclaimer for articles and videos. Care Team refers to the care navigators that help Carrot members navigate their fertility journey"
  })
}
