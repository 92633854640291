import React from "react"
import { Box, IconButton, SortAscendingIcon } from "@carrotfertility/carotene-core"
import { FormButton, useWatch, useFormContext } from "@carrotfertility/carotene-core-x"
import { useInAppMessaging } from "../context/messaging/InAppMessagingContext"
import { FormattedMessage, useIntl } from "react-intl"

type FormCancelButtonProps = { onClick: () => unknown }

export function FormCancelButton({ onClick }: FormCancelButtonProps): JSX.Element {
  function onClickFormCancel(): void {
    onClick()
  }
  return (
    <FormButton variant="outlined" onClick={onClickFormCancel}>
      <FormattedMessage defaultMessage="Cancel" />
    </FormButton>
  )
}

export function FormSendButton(): JSX.Element {
  const { selectedThread } = useInAppMessaging()
  const intl = useIntl()
  const {
    formState: { isValid, isDirty }
  } = useFormContext()
  const messageValue = useWatch({ name: "message" })
  const subjectValue = useWatch({ name: "subject" })

  return (
    <Box display="flex" width="auto" minWidth="140px" justifyContent="center" alignItems="center">
      <FormButton
        color="primary"
        type="submit"
        disabled={!isValid || !isDirty || messageValue === "" || subjectValue === ""}
      >
        {selectedThread?.isClosed
          ? intl.formatMessage({ defaultMessage: "Start follow-up" })
          : intl.formatMessage({ defaultMessage: "Send" })}
      </FormButton>
    </Box>
  )
}

export function FormSendNewMessageButton(): JSX.Element {
  const {
    formState: { isValid, isDirty }
  } = useFormContext()
  const messageValue = useWatch({ name: "message" })
  const subjectValue = useWatch({ name: "subject" })

  return (
    <FormButton
      color="primary"
      type="submit"
      disabled={!isValid || !isDirty || messageValue === "" || subjectValue === ""}
    >
      <FormattedMessage defaultMessage="Send" />
    </FormButton>
  )
}

export function FormSendIconButton(): JSX.Element {
  const {
    formState: { isValid, isDirty }
  } = useFormContext()
  const intl = useIntl()
  const messageValue = useWatch({ name: "message" })
  const subjectValue = useWatch({ name: "subject" })

  return (
    <IconButton
      type="submit"
      disabled={!isValid || !isDirty || messageValue === "" || subjectValue === ""}
      aria-label={intl.formatMessage({ defaultMessage: "Send message" })}
    >
      <SortAscendingIcon />
    </IconButton>
  )
}
