import React from "react"
import { useTrackView } from "../../../utils/heap"
import { PiiText } from "services/tracking"
import { useTheme, Typography, Stack, Box } from "@carrotfertility/carotene-core"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { ContinueButton, RegistrationButtonFooter } from "./RegistrationButtons"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"

interface NiceToSeeYouProps {
  companyDisplayName?: string
  onComplete: () => void
}

const WelcomeScreenStepsCopy = {
  STEP_ONE: defineMessage({ defaultMessage: "Create your account" }),
  STEP_TWO: defineMessage({ defaultMessage: "Learn about your benefit and the resources available to you" }),
  STEP_THREE: defineMessage({
    defaultMessage: "Tell us about your journey to get a personalized Carrot Plan and unlock full access"
  })
}

export function NiceToSeeYou({ companyDisplayName, onComplete }: NiceToSeeYouProps): JSX.Element {
  useTrackView("WelcomeScreenRegFlow", "Welcome to Carrot in screen")
  const intl = useIntl()
  const header = intl.formatMessage({ defaultMessage: "Welcome to Carrot" })

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        imageName="illo-welcome"
        description={
          <>
            <Greeting companyDisplayName={companyDisplayName} />
            <WelcomeStepIntro />
            <Box marginLeft={(theme) => theme.spacing(theme.tokens.spacing.none)}>
              <IconListItem iconName={"icn-user"}>{intl.formatMessage(WelcomeScreenStepsCopy.STEP_ONE)}</IconListItem>
              <IconListItem iconName={"icn-lightbulb-color"}>
                {intl.formatMessage(WelcomeScreenStepsCopy.STEP_TWO)}
              </IconListItem>
              <IconListItem iconName={"icn-account-settings"}>
                {intl.formatMessage(WelcomeScreenStepsCopy.STEP_THREE)}
              </IconListItem>
            </Box>
          </>
        }
        interactiveElements={
          <>
            <RegistrationButtonFooter
              continueButton={
                <ContinueButton onClick={onComplete}>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </>
        }
      />
    </>
  )
}

function Greeting({ companyDisplayName }: { companyDisplayName: string }): JSX.Element {
  return (
    <Typography>
      <FormattedMessage
        defaultMessage="<PiiText>{companyDisplayName}</PiiText> has partnered with Carrot, a benefit designed to support fertility, family
      forming, and hormonal health."
        values={{
          companyDisplayName: companyDisplayName,
          PiiText: (textContent) => <PiiText>{textContent}</PiiText>
        }}
      />
    </Typography>
  )
}

function WelcomeStepIntro() {
  return (
    <Typography
      marginTop={(theme) => theme.spacing(theme.tokens.spacing.md)}
      marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
    >
      <FormattedMessage defaultMessage="Get started in a few simple steps:" />
    </Typography>
  )
}

function IconListItem({ iconName, children }: { iconName: string; children: any }): JSX.Element {
  const theme = useTheme()
  return (
    <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
      <Stack direction="row" alignItems={"center"} spacing={theme.spacing(theme.tokens.spacing.md)}>
        <Box
          component="img"
          src={`/images/${iconName}.png`}
          srcSet={`/images/${iconName}-2x.png`}
          alt=""
          height={32}
          width={32}
        />
        <Typography>{children}</Typography>
      </Stack>
    </Box>
  )
}
