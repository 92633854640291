import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import queryString from "query-string"
import { Steps } from "../workflow"
import { CaseRateRenewalModalTopSection } from "../CaseRateRenewalModalTopSection"
import { useIntl } from "react-intl"
import { Box, Button, ForwardIcon, Typography } from "@carrotfertility/carotene-core"

export function WelcomeBackIntroStep(): JSX.Element {
  const intl = useIntl()
  const { step } = queryString.parse(window.location.search)
  const { send } = useStateMachine(step === Steps.WELCOME_BACK_INTRO || !step ? null : "")
  const header = intl.formatMessage({ defaultMessage: "Welcome back" })
  const imageAltText = intl.formatMessage({ defaultMessage: "carrot logo watering can" })
  const bodyText1 = intl.formatMessage({
    defaultMessage:
      "It’s been a while since we last refreshed your Carrot Plan with new suggestions. We want to make sure it is best serving you and your journey."
  })
  const bodyText2 = intl.formatMessage({ defaultMessage: "Please take a couple minutes to update us." })
  const buttonText = intl.formatMessage({ defaultMessage: "Continue" })

  return (
    <Box>
      <CaseRateRenewalModalTopSection
        header={header}
        showImage
        imageAltText={imageAltText}
        imageSrc="/images/carrot-logo-watering-can"
      />
      <Box>
        <Typography
          variant="body1"
          color={(theme) => theme.palette.text.secondary}
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
        >
          {bodyText1}
        </Typography>
        <Typography
          variant="body1"
          color={(theme) => theme.palette.text.secondary}
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xl)}
        >
          {bodyText2}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={() => send("")} color="secondary" variant="outlined" endIcon={<ForwardIcon />}>
          {buttonText}
        </Button>
      </Box>
    </Box>
  )
}
