import React, { useContext, useState } from "react"

const BroadAreaContext = React.createContext(null)
export const useBroadAreaContext = () => useContext(BroadAreaContext)

export function BroadAreaContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [broadAreaSearched, setBroadAreaSearched] = useState(false)
  return (
    <BroadAreaContext.Provider
      value={{
        broadAreaSearched,
        setBroadAreaSearched
      }}
    >
      {children}
    </BroadAreaContext.Provider>
  )
}
