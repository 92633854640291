import React, { useEffect, useState } from "react"
import AddEditEmailAddress from "./AddEditEmailAddress"
import { FormattedMessage, useIntl } from "react-intl"
import EmployeeOnlyModalContainer from "../employee-only-containers/EmployeeOnlyModalContainer"
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@carrotfertility/carotene-core"

type AddEditEmailAddressModalProps = {
  open: boolean
  closeModal: () => void
  removeDisabled: boolean
  onRemovePersonalEmail: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>
}

export default function EditEmailAddressModal({
  open,
  closeModal,
  removeDisabled,
  onRemovePersonalEmail
}: AddEditEmailAddressModalProps): JSX.Element {
  const intl = useIntl()
  const [showEmailRemoved, setShowEmailRemoved] = useState(false)
  const [showEmailUpdated, setShowEmailUpdated] = useState(false)

  useEffect(() => {
    if (open) {
      setShowEmailRemoved(false)
      setShowEmailUpdated(false)
    }
  }, [open])

  function onClose() {
    closeModal()
  }

  const title = showEmailRemoved
    ? intl.formatMessage({ defaultMessage: "Email address removed" })
    : intl.formatMessage({ defaultMessage: "Email address changed" })
  const description = showEmailRemoved
    ? intl.formatMessage({ defaultMessage: "This email address was successfully removed." })
    : intl.formatMessage({
        defaultMessage:
          "Please check your inbox and click the verification link to enable us to communicate with you at this address."
      })

  return (
    <EmployeeOnlyModalContainer onClose={onClose} open={open}>
      <Dialog
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        maxWidth="md"
        open={open}
        onClose={onClose}
      >
        {showEmailUpdated ? (
          <>
            <DialogTitle id="modal-title">{title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="modal-description">{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                <FormattedMessage defaultMessage="Close" />
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <Box display="flex" justifyContent="flex-end">
              <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <AddEditEmailAddress
              setShowActionTaken={setShowEmailUpdated}
              title={intl.formatMessage({ defaultMessage: "Edit email address" })}
              showRemoveEmail
              onRemove={async (event) => {
                await onRemovePersonalEmail(event)
                setShowEmailRemoved(true)
                setShowEmailUpdated(true)
              }}
              removeDisabled={removeDisabled}
            />
          </>
        )}
      </Dialog>
    </EmployeeOnlyModalContainer>
  )
}
