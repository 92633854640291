import React from "react"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { Box, Divider, Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Link as ReactRouterLink } from "react-router-dom"

const title = defineMessage({
  defaultMessage: "Looking for something else?"
})
const shortMessage = defineMessage({
  defaultMessage: "Consult your employer to learn how they support some types of providers outside of Carrot."
})
const longMessage = defineMessage({
  defaultMessage:
    "Consult your employer's HR team or benefits portal to learn how they support some types of providers outside of Carrot."
})
const longMessageWhenAdditionalEmployerBenefits = defineMessage({
  defaultMessage: "Your employer supports some types of providers outside of Carrot."
})

export function FertilityWraparoundMessageForDropdown(): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()

  return (
    <>
      <Divider />
      <Stack
        padding={theme.spacing(theme.tokens.spacing.md)}
        maxWidth="540px"
        spacing={theme.spacing(theme.tokens.spacing.xxs)}
      >
        <Typography fontWeight="bold" variant="body1" color={theme.palette.text.secondary}>
          {intl.formatMessage(title)}
        </Typography>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          {intl.formatMessage(shortMessage)}
        </Typography>
      </Stack>
    </>
  )
}

export function FertilityWraparoundMessageForSidebar(): JSX.Element {
  const intl = useIntl()

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}
    >
      <Typography variant="body1" fontWeight="bold" color="secondary">
        {intl.formatMessage(title)}
      </Typography>
      <Typography variant="body2" color="secondary">
        {intl.formatMessage(longMessage)}
      </Typography>
    </Box>
  )
}

export function FertilityWraparoundMessageWhenAdditionalEmployerBenefitsForSidebar(): JSX.Element {
  const intl = useIntl()

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}
    >
      <Typography variant="body1" fontWeight="bold" color="secondary">
        {intl.formatMessage(title)}
      </Typography>
      <Typography variant="body2" color="secondary">
        {intl.formatMessage(longMessageWhenAdditionalEmployerBenefits)}
      </Typography>
      <Link
        to="/your-benefit-guide/coverage/additional-employer-benefits"
        component={ReactRouterLink}
        variant="body2"
        color="inherit"
        title={intl.formatMessage({
          defaultMessage: "Learn about providers outside of Carrot that your employer supports in your benefit guide"
        })}
      >
        <FormattedMessage defaultMessage={`Learn more about those offerings`} />
      </Link>
    </Box>
  )
}
