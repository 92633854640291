import { CountryCodes } from "../../content/CountryCode"
import { useIntlSort } from "../../utils/hooks/useIntlSort"
import { useIntl } from "react-intl"
import { getCountryCallingCode } from "libphonenumber-js"

export const usePhoneCodeSelectOptions = () => {
  const { localeComparator } = useIntlSort()
  const intl = useIntl()

  return CountryCodes.map((countryCode) => {
    const formattedCountryCode = intl.formatDisplayName(countryCode, { type: "region" })
    const countryCallingCode = getCountryCallingCode(countryCode)
    return {
      value: countryCode,
      label: intl.formatMessage(
        { defaultMessage: "{countryCode} (+{phoneCode})" },
        { countryCode: formattedCountryCode, phoneCode: countryCallingCode }
      )
    }
  }).sort((a, b) => localeComparator(a.label, b.label))
}
