import { reportError } from "utils/ErrorReporting"
import { HttpErrors } from "utils/HttpErrors"
import actionTypes from "actions/actionTypes"

export const createRequestAction = (actionTypeRoot, requestFn) => {
  return () => async (dispatch) => {
    let response

    try {
      response = await requestFn()
    } catch (error) {
      if (error.name === HttpErrors.UNAUTHORIZED) {
        dispatch({
          type: actionTypes.SESSION_EXPIRED
        })
        return
      }

      dispatch({
        type: `${actionTypeRoot}_FAILURE`
      })

      throw error
    }

    dispatch({
      type: `${actionTypeRoot}_SUCCESS`,
      response
    })
  }
}

export const createRequestActionWithParam = (actionTypeRoot, param, errorFieldName, requestFn) => {
  return async (dispatch) => {
    let response

    try {
      response = await requestFn(param)
    } catch (error) {
      if (error.name === HttpErrors.UNAUTHORIZED) {
        dispatch({
          type: actionTypes.SESSION_EXPIRED
        })
        return
      }

      error.message = `Error updating : ${errorFieldName}` + error.message
      reportError(error)
      dispatch({
        type: `${actionTypeRoot}_FAILURE`
      })

      throw error
    }

    dispatch({
      type: `${actionTypeRoot}_SUCCESS`,
      response
    })
  }
}
