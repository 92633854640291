import { MessageDescriptor, defineMessage } from "react-intl"

const UsaStates: { label: MessageDescriptor; id: string }[] = [
  { label: defineMessage({ defaultMessage: "Alabama" }), id: "AL" },
  { label: defineMessage({ defaultMessage: "Alaska" }), id: "AK" },
  { label: defineMessage({ defaultMessage: "American Samoa" }), id: "AS" },
  { label: defineMessage({ defaultMessage: "Arizona" }), id: "AZ" },
  { label: defineMessage({ defaultMessage: "Arkansas" }), id: "AR" },
  { label: defineMessage({ defaultMessage: "California" }), id: "CA" },
  { label: defineMessage({ defaultMessage: "Colorado" }), id: "CO" },
  { label: defineMessage({ defaultMessage: "Commonwealth of the Northern Mariana Islands" }), id: "MP" },
  { label: defineMessage({ defaultMessage: "Connecticut" }), id: "CT" },
  { label: defineMessage({ defaultMessage: "Delaware" }), id: "DE" },
  { label: defineMessage({ defaultMessage: "District of Columbia" }), id: "DC" },
  { label: defineMessage({ defaultMessage: "Florida" }), id: "FL" },
  { label: defineMessage({ defaultMessage: "Georgia" }), id: "GA" },
  { label: defineMessage({ defaultMessage: "Guam" }), id: "GU" },
  { label: defineMessage({ defaultMessage: "Hawaii" }), id: "HI" },
  { label: defineMessage({ defaultMessage: "Idaho" }), id: "ID" },
  { label: defineMessage({ defaultMessage: "Illinois" }), id: "IL" },
  { label: defineMessage({ defaultMessage: "Indiana" }), id: "IN" },
  { label: defineMessage({ defaultMessage: "Iowa" }), id: "IA" },
  { label: defineMessage({ defaultMessage: "Kansas" }), id: "KS" },
  { label: defineMessage({ defaultMessage: "Kentucky" }), id: "KY" },
  { label: defineMessage({ defaultMessage: "Louisiana" }), id: "LA" },
  { label: defineMessage({ defaultMessage: "Maine" }), id: "ME" },
  { label: defineMessage({ defaultMessage: "Maryland" }), id: "MD" },
  { label: defineMessage({ defaultMessage: "Massachusetts" }), id: "MA" },
  { label: defineMessage({ defaultMessage: "Michigan" }), id: "MI" },
  { label: defineMessage({ defaultMessage: "Minnesota" }), id: "MN" },
  { label: defineMessage({ defaultMessage: "Mississippi" }), id: "MS" },
  { label: defineMessage({ defaultMessage: "Missouri" }), id: "MO" },
  { label: defineMessage({ defaultMessage: "Montana" }), id: "MT" },
  { label: defineMessage({ defaultMessage: "Nebraska" }), id: "NE" },
  { label: defineMessage({ defaultMessage: "Nevada" }), id: "NV" },
  { label: defineMessage({ defaultMessage: "New Hampshire" }), id: "NH" },
  { label: defineMessage({ defaultMessage: "New Jersey" }), id: "NJ" },
  { label: defineMessage({ defaultMessage: "New Mexico" }), id: "NM" },
  { label: defineMessage({ defaultMessage: "New York" }), id: "NY" },
  { label: defineMessage({ defaultMessage: "North Carolina" }), id: "NC" },
  { label: defineMessage({ defaultMessage: "North Dakota" }), id: "ND" },
  { label: defineMessage({ defaultMessage: "Ohio" }), id: "OH" },
  { label: defineMessage({ defaultMessage: "Oklahoma" }), id: "OK" },
  { label: defineMessage({ defaultMessage: "Oregon" }), id: "OR" },
  { label: defineMessage({ defaultMessage: "Pennsylvania" }), id: "PA" },
  { label: defineMessage({ defaultMessage: "Puerto Rico" }), id: "PR" },
  { label: defineMessage({ defaultMessage: "Rhode Island" }), id: "RI" },
  { label: defineMessage({ defaultMessage: "South Carolina" }), id: "SC" },
  { label: defineMessage({ defaultMessage: "South Dakota" }), id: "SD" },
  { label: defineMessage({ defaultMessage: "Tennessee" }), id: "TN" },
  { label: defineMessage({ defaultMessage: "Texas" }), id: "TX" },
  { label: defineMessage({ defaultMessage: "US Virgin Islands" }), id: "VI" },
  { label: defineMessage({ defaultMessage: "Utah" }), id: "UT" },
  { label: defineMessage({ defaultMessage: "Vermont" }), id: "VT" },
  { label: defineMessage({ defaultMessage: "Virginia" }), id: "VA" },
  { label: defineMessage({ defaultMessage: "Washington" }), id: "WA" },
  { label: defineMessage({ defaultMessage: "West Virginia" }), id: "WV" },
  { label: defineMessage({ defaultMessage: "Wisconsin" }), id: "WI" },
  { label: defineMessage({ defaultMessage: "Wyoming" }), id: "WY" }
]

export { UsaStates }
