import { Steps } from "./steps"

export default [
  { at: Steps.BEFORE_YOU_CONTINUE_WARNING, given: "", goto: Steps.INTERNATIONAL_ADDRESS_UPDATE_DECISION },
  { at: Steps.INTERNATIONAL_ADDRESS_UPDATE_DECISION, given: "YES", goto: Steps.INTERNATIONAL_ADDRESS_UPDATE },
  { at: Steps.INTERNATIONAL_ADDRESS_UPDATE_DECISION, given: "NO", goto: Steps.UPLOAD_STATEMENT },
  { at: Steps.INTERNATIONAL_ADDRESS_UPDATE, given: "", goto: Steps.UPLOAD_STATEMENT },
  { at: Steps.UPLOAD_STATEMENT, given: "", goto: Steps.WHO_RECEIVED_CARE },
  {
    at: Steps.UPLOAD_STATEMENT,
    given: "ABOUT_EXPENSE_ELIGIBLE_REIMBURSEMENT_AMOUNT",
    goto: Steps.ELIGIBLE_REIMBURSEMENT_AMOUNT
  },
  { at: Steps.ELIGIBLE_REIMBURSEMENT_AMOUNT, given: "", goto: Steps.WHO_RECEIVED_CARE },
  { at: Steps.WHO_RECEIVED_CARE, given: "", goto: Steps.EXPENSE_RELATED_TO },
  { at: Steps.EXPENSE_RELATED_TO, given: "ADOPTION", goto: Steps.STEPCHILD_ADOPTION },
  { at: Steps.EXPENSE_RELATED_TO, given: "FERTILITY_CARE", goto: Steps.WHAT_KIND_OF_FERTILITY_CARE },
  {
    at: Steps.EXPENSE_RELATED_TO,
    given: "WHAT_SERVICES",
    goto: Steps.WHAT_KIND_OF_SERVICE
  },
  { at: Steps.EXPENSE_RELATED_TO, given: "ADDITIONAL_INFO_ANYTHING_ELSE", goto: Steps.ANYTHING_ELSE },
  { at: Steps.EXPENSE_RELATED_TO, given: "STORAGE_FEES", goto: Steps.RELATED_TO_STORAGE_FEES },
  { at: Steps.EXPENSE_RELATED_TO, given: "", goto: Steps.RELATED_TO_INFERTILITY_DIAGNOSIS },
  { at: Steps.STEPCHILD_ADOPTION, given: "", goto: Steps.ANYTHING_ELSE },
  { at: Steps.WHAT_KIND_OF_FERTILITY_CARE, given: "", goto: Steps.RELATED_TO_STORAGE_FEES },
  { at: Steps.WHAT_KIND_OF_FERTILITY_CARE, given: "ADDITIONAL_INFO_ANYTHING_ELSE", goto: Steps.ANYTHING_ELSE },
  { at: Steps.WHAT_KIND_OF_SERVICE, given: "", goto: Steps.ANYTHING_ELSE },
  {
    at: Steps.WHAT_KIND_OF_SERVICE,
    given: "ATTESTATION_MEDICALLY_NECESSARY_AND_ELIGIBLE",
    goto: Steps.ATTESTATION_MEDICALLY_NECESSARY_AND_ELIGIBLE
  },
  { at: Steps.ATTESTATION_MEDICALLY_NECESSARY_AND_ELIGIBLE, given: "", goto: Steps.ANYTHING_ELSE },
  {
    at: Steps.WHAT_KIND_OF_SERVICE,
    given: "ATTESTATION_MEDICALLY_RELATED",
    goto: Steps.ATTESTATION_MEDICALLY_RELATED
  },
  { at: Steps.ATTESTATION_MEDICALLY_RELATED, given: "", goto: Steps.ANYTHING_ELSE },
  { at: Steps.RELATED_TO_STORAGE_FEES, given: "", goto: Steps.RELATED_TO_INFERTILITY_DIAGNOSIS },
  { at: Steps.RELATED_TO_STORAGE_FEES, given: "RELATED_TO_MEDICATION", goto: Steps.RELATED_TO_MEDICATION },
  { at: Steps.RELATED_TO_STORAGE_FEES, given: "ADDITIONAL_INFO_ANYTHING_ELSE", goto: Steps.ANYTHING_ELSE },
  { at: Steps.RELATED_TO_MEDICATION, given: "", goto: Steps.RELATED_TO_INFERTILITY_DIAGNOSIS },
  { at: Steps.RELATED_TO_MEDICATION, given: "ADDITIONAL_INFO_ANYTHING_ELSE", goto: Steps.ANYTHING_ELSE },
  { at: Steps.RELATED_TO_INFERTILITY_DIAGNOSIS, given: "", goto: Steps.MEDICALLY_NECESSARY },
  { at: Steps.RELATED_TO_INFERTILITY_DIAGNOSIS, given: "", goto: Steps.MEDICALLY_NECESSARY },
  { at: Steps.MEDICALLY_NECESSARY, given: "", goto: Steps.ANYTHING_ELSE },
  { at: Steps.ANYTHING_ELSE, given: "NEEDS_IVF_REQUIREMENT", goto: Steps.DO_IVF_REQUIREMENT },
  { at: Steps.ANYTHING_ELSE, given: "", goto: Steps.FINAL_STEP }
]
