import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "components/carrot-plans/shared/useQueryRoutingInfo"

export default function WhatKindOfJourneyIVFDecisionStep(): JSX.Element {
  const routingData = useCheckRoutingInfoQueryCache()
  const journeyValue = routingData?.journey === "ASSISTED_REPRODUCTION" ? "ASSISTED_REPRODUCTION" : "OTHER_ANSWERS"

  const { send } = useStateMachine()
  useEffect(() => {
    send(journeyValue)
  })
  return <></>
}
