import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { Container } from "@carrotfertility/carotene"
import CheckoutForm from "./CheckoutForm"
import { loadStripe } from "@stripe/stripe-js"
import Settings from "../../../utils/CarrotConfig"

type stripeCheckoutOptionsProps = {
  clientSecret: string
  appearance: Record<string, any>
}

type StripeCheckoutFormProps = {
  options: stripeCheckoutOptionsProps
  paymentIntentAmount: string
  paymentIntentCurrencyCode: string
}

export function StripeCheckoutForm({
  options,
  paymentIntentCurrencyCode,
  paymentIntentAmount
}: StripeCheckoutFormProps): JSX.Element {
  const stripePromise = loadStripe(Settings.STRIPE_PUBLISHABLE_KEY)

  return (
    <Container maxWidth="684px">
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm paymentIntentAmount={paymentIntentAmount} paymentIntentCurrencyCode={paymentIntentCurrencyCode} />
      </Elements>
    </Container>
  )
}

export default StripeCheckoutForm
