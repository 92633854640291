import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCurrentUser } from "../../../context/user/UserContext"

function IsUsStep(): JSX.Element {
  const { isUsa } = useCurrentUser()
  const { send } = useStateMachine()
  useEffect(() => {
    send(isUsa ? "YES" : "NO")
  })
  return <></>
}

export const UsMostHelpfulIsUsStep = IsUsStep
export const GestationalCarrierIsUsDecisionStep = IsUsStep
export const AdoptionIsUsDecisionStep = IsUsStep
export const LGBTQPlusIsUsDecisionStep = IsUsStep
export const FertilityPreservationIsUsDecisionStep = IsUsStep
export const AssistedReproductionIsUsDecisionStep = IsUsStep
export const AttemptingToGetPregnantIsUsDecisionStep = IsUsStep
export const CurrentlyPregnantIsUsDecisionStep = IsUsStep
export const MenopauseIsUsDecisionStep = IsUsStep
export const LowTestosteroneIsUsDecisionStep = IsUsStep
export const GenderAffirmingCareIsUsDecisionStep = IsUsStep
