import { useState, useEffect } from "react"

export default function useInitialSetup(setupFunc: () => any, commitFunc: (result: any) => any = () => {}) {
  const [error, setError] = useState(false)
  useEffect(() => {
    let commit = true
    async function execute() {
      try {
        const result = await setupFunc()
        return commit && commitFunc(result)
      } catch (e) {
        setError(e)
      }
    }
    execute()
    return () => {
      commit = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])
  if (error) {
    throw error
  }
}
