import React from "react"
import { useForm, FormProvider } from "react-hook-form"
import { SignUpInfoPage } from "components/signup-find-account"
import { SignUpFindAccountProvider } from "components/signup-find-account/context/SignUpFindAccountContext"
import { useHistory, useParams } from "react-router"
import { useSignUpFlow } from "components/context/signup/SignUpFlowContext"
import { trackMatomoPageView } from "utils/matomo"
import { FindAccountByEmployeeIdForm } from "components/sign-up-find-account-company-specific"
import { FindAccountByExternalEmployeeIdProvider } from "components/sign-up-find-account-company-specific/context/FindAccountByExternalEmployeeIdContext"

export default function SignUpInfo(): JSX.Element {
  const history = useHistory()
  const formMethods = useForm({ reValidateMode: "onSubmit" })
  const { handleSaveMemberInfoInMemory } = useSignUpFlow()
  const { parentCompanyId } = useParams<{ parentCompanyId: string }>()

  const { handleSubmit } = formMethods

  // @ts-expect-error TS7006
  function onEmailFound(email): void {
    const signupSuccessHref = `/signup-success?email=${encodeURIComponent(email)}`
    history.push(signupSuccessHref)
    trackMatomoPageView(signupSuccessHref)
  }

  // @ts-expect-error TS7006
  function onEmailNotFound({ email, dateOfBirth, firstName, lastName, externalEmployeeId, parentCompanyId }): void {
    handleSaveMemberInfoInMemory({ email, dateOfBirth, firstName, lastName, externalEmployeeId, parentCompanyId })
    history.push(
      parentCompanyId ? "/signup-find-account-by-external-employee-id-success" : "/signup-find-account-success"
    )
  }

  function onMultipleEmployeesFound(): void {
    history.push("/signup-get-help")
  }

  function onEmployeeAlreadyRegistered(): void {
    history.push("/signup-employee-already-registered")
  }

  // @ts-expect-error TS7006
  function onEmployeeHasSamlAccount(existingEmail): void {
    handleSaveMemberInfoInMemory({ email: existingEmail })
    history.push("/signup-employee-has-saml")
  }

  return (
    <>
      {parentCompanyId ? (
        <FindAccountByExternalEmployeeIdProvider
          {...{
            onEmailFound,
            onEmailNotFound,
            onMultipleEmployeesFound,
            onEmployeeAlreadyRegistered,
            onEmployeeHasSamlAccount
          }}
        >
          <FormProvider {...formMethods}>
            <FindAccountByEmployeeIdForm parentCompanyId={Number(parentCompanyId)} />
          </FormProvider>
        </FindAccountByExternalEmployeeIdProvider>
      ) : (
        <SignUpFindAccountProvider
          {...{
            handleSubmit,
            onEmailFound,
            onEmailNotFound,
            onMultipleEmployeesFound,
            onEmployeeAlreadyRegistered,
            onEmployeeHasSamlAccount
          }}
        >
          <FormProvider {...formMethods}>
            <SignUpInfoPage />
          </FormProvider>
        </SignUpFindAccountProvider>
      )}
    </>
  )
}
