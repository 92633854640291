import React from "react"
import { InputAdornment } from "@carrotfertility/carotene-core"
import { FormTextField, FormTextFieldProps, useWatch } from "@carrotfertility/carotene-core-x"
import { useIntl } from "react-intl"
import { CountryCode } from "../../content/CountryCode"
import { getCountryCallingCode } from "libphonenumber-js"

type FormPhoneNumberTextInputProps = FormTextFieldProps & {
  countryCodeFieldName: string
}

export default function FormPhoneNumberTextInput({ countryCodeFieldName, ...props }: FormPhoneNumberTextInputProps) {
  const intl = useIntl()
  const countryCodeSelection = useWatch({ name: countryCodeFieldName })
  const phoneCode = getCountryCallingCode(countryCodeSelection as CountryCode)

  return (
    <FormTextField
      {...props}
      InputProps={{
        startAdornment: phoneCode ? (
          <InputAdornment disableTypography={props.disabled} position="start">
            {intl.formatMessage({ defaultMessage: "+{phoneCode}" }, { phoneCode: phoneCode })}
          </InputAdornment>
        ) : null
      }}
    />
  )
}
