import { Locale, SupportedLocale } from "../types/Locale"

const LOCAL_STORAGE_INTL = "intl"

export const setLastUsedLocale = (lastUsedLocale: SupportedLocale) => {
  localStorage.setItem(LOCAL_STORAGE_INTL, JSON.stringify({ lastUsedLocale: lastUsedLocale?.toString() }))
}

export const getLastUsedLocale = (): SupportedLocale => {
  let intlStorage

  try {
    intlStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_INTL))
  } catch (error) {
    intlStorage = {}
  }

  if (SupportedLocale.isSupportedLocale(intlStorage?.lastUsedLocale)) {
    return Locale.getLocaleFromString(intlStorage.lastUsedLocale)
  }

  return null
}
