import React from "react"
import { CaseRateRenewalModalTopSection } from "./CaseRateRenewalModalTopSection"
import { CaseRateRenewalModalButtonGroup } from "./CaseRateRenewalModalButtonGroup"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, Typography } from "@carrotfertility/carotene-core"

type DoneForNowContentProps = {
  takeMeBackOnClick: () => void
  yesImDoneOnClick: () => void
  isLoading: boolean
}

export function DoneForNowContent({
  takeMeBackOnClick,
  yesImDoneOnClick,
  isLoading
}: DoneForNowContentProps): JSX.Element {
  const intl = useIntl()

  return (
    <>
      <CaseRateRenewalModalTopSection
        header={intl.formatMessage({
          defaultMessage: "Are you sure you're done using Carrot?"
        })}
        showImage
        imageAltText=""
        imageSrc="/images/illo-underwater-glass"
      />
      <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
        <FormattedMessage
          defaultMessage="If you’re done, your Carrot Plan will disappear and you will not have access to funds or expert chats. You can
          get access again by requesting a new plan."
        />
      </Typography>
      <CaseRateRenewalModalButtonGroup
        buttonOne={
          <Button variant="outlined" color="inherit" onClick={takeMeBackOnClick} disabled={isLoading}>
            <FormattedMessage defaultMessage="No, take me back" />
          </Button>
        }
        buttonTwo={
          <Button variant="contained" onClick={yesImDoneOnClick} disabled={isLoading}>
            <FormattedMessage defaultMessage="Yes, I'm done" />
          </Button>
        }
      />
    </>
  )
}
