import React, { useCallback, useState } from "react"
import { FlexContainer, Select, Stack, H2, Text } from "@carrotfertility/carotene"
import ArrowSubmitButton from "features/legacy-components/ArrowSubmitButton"
import { BackArrow } from "components/views/atoms/Molecules"
import { Progress } from "components/views/modal/Progress"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { whoReceivedCareChoices } from "components/views/reimbursements/whoReceivedCareChoices"
import { useReimbursementContext } from "components/context/reimbursement/GetReimbursedContext"
import { FormattedMessage, useIntl } from "react-intl"
import { FormControlLabel, Link, Checkbox, Typography } from "@carrotfertility/carotene-core"
import { useSelector } from "react-redux"
import { getBenefitConfiguration } from "../../../reducers/benefitConfiguration"
import { CorrectOption } from "@carrotfertility/carotene/dist/lib/components/Select/SelectTypes"

export const AboutThisExpenseWhoReceivedCare = (): JSX.Element => {
  const { send, back } = useStateMachine()
  const { updateWhoReceivedCare, state } = useReimbursementContext()
  const [answer, setAnswer] = useState(state.whoReceivedCare || null)
  const intl = useIntl()
  const [isChecked, setIsChecked] = useState(false)
  const { coverageConfig } = useSelector(getBenefitConfiguration)

  // @ts-expect-error TS7006
  const handleChange = (value): void => {
    setAnswer(value)
  }

  const handleContinueClick = (): void => {
    updateWhoReceivedCare(answer)
    send("")
  }

  const formattedAndFilteredOptions = whoReceivedCareChoices
    .filter((choice) => {
      if (!choice.filter) return choice
      return choice.filter(coverageConfig)
    })
    .map((choice) => ({ ...choice, label: intl.formatMessage(choice.label) }))

  const toggleCheckbox = useCallback((): void => {
    if (!isChecked) {
      setIsChecked(true)
      const castedAnswer: CorrectOption = {
        label: "Me",
        value: "MEMBER"
      }
      setAnswer(castedAnswer)
    } else {
      handleChange(null)
      setIsChecked(false)
      setAnswer(null)
    }
  }, [isChecked])

  // @ts-ignore
  return (
    <>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      {formattedAndFilteredOptions.length === 1 && (
        <Stack minHeight="500px" paddingTopBottom="large">
          <Typography variant="h3">
            <FormattedMessage defaultMessage="Did you receive the care or service?" />
          </Typography>
          <FormattedMessage defaultMessage="You can only use funds for your own eligible expenses. " />
          <FormattedMessage
            defaultMessage="<link>Visit your benefit guide</link> for more information."
            values={{
              link: (linkContent) => (
                <Link target="_blank" href={"/your-benefit-guide/coverage"} color="inherit">
                  {linkContent}
                </Link>
              )
            }}
          />
          <FormControlLabel
            sx={{ alignItems: "center" }}
            label={<FormattedMessage defaultMessage="Yes, I received the care or service." />}
            control={<Checkbox value={"acknowledged"} checked={isChecked} onChange={toggleCheckbox} required={true} />}
          />
        </Stack>
      )}
      {formattedAndFilteredOptions.length !== 1 && (
        <Stack minHeight="500px" paddingTopBottom="huge">
          <H2 id="step-heading">
            <FormattedMessage defaultMessage="Who received the care or service?" />
          </H2>
          <Text>
            <FormattedMessage defaultMessage="If you have eligible expenses for more than one person, please upload them as separate submissions." />
          </Text>
          <Select
            aria-labelledby="step-heading"
            isSearchable={false}
            defaultValue={state.whoReceivedCare?.label ? (state.whoReceivedCare as any) : ""}
            onChange={handleChange}
            options={formattedAndFilteredOptions}
            placeholder={intl.formatMessage({ defaultMessage: "Select option" })}
          />
        </Stack>
      )}
      <FlexContainer paddingTopBottom="huge" justifyContent="flex-end">
        <ArrowSubmitButton enabled={Boolean(answer?.value || isChecked)} onClick={handleContinueClick}>
          <FormattedMessage defaultMessage="Continue" />
        </ArrowSubmitButton>
      </FlexContainer>
    </>
  )
}
