export type BenefitEnrollmentStatus = {
  areCommonInsuranceFieldsCompleted: boolean
  areCmdSpecificFieldsCompleted: boolean
  isConnectInsuranceStepCompleted: boolean
  isInsuranceStepCompleted: boolean
  needsBenefitEnrollment: boolean
  isConnectInsuranceStepInProgress: boolean
  hasEnrollmentAndNeedsBenefitEnrollment: boolean
  hasExpensesAndNeedsBenefitEnrollment: boolean
  cardLockedDueToNeedsEnrollmentDateTime: Date | null
}

export enum BenefitAvailabilityStatus {
  DOES_NOT_NEED_ENROLLMENT,
  ENROLLMENT_PLAN_EXPIRED,
  COMPANY_ADDED_DEDUCTIBLE,
  NEEDS_BENEFIT_ENROLLMENT
}

export type HraEligibility = {
  memberHraEligibility: boolean
  partnerHraEligibility: boolean | null
}
