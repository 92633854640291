import React from "react"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { Form } from "@carrotfertility/carotene-core-x"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  SkipButton,
  StepLayout,
  useConvertTranslatedLabel
} from "components/carrot-plans/shared"
import { FormRadioButtonGroup } from "services/common-forms"

export default function PregnancyMetWithObgynStep(): JSX.Element {
  const intl = useIntl()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultValue = medicalInfo?.pregnancyMetWithObgyn

  const { send } = useStateMachine(defaultValue || hasQuestionBeenSkipped("pregnancyMetWithObgyn") ? "" : null)
  const buttonOptions = [
    { label: defineMessage({ defaultMessage: "Yes" }), value: "YES" },
    { label: defineMessage({ defaultMessage: "No" }), value: "NO" }
  ]
  const radioOptions = useConvertTranslatedLabel(buttonOptions)

  async function onClickContinue(formValues: { pregnancyMetWithObgyn: string }): Promise<void> {
    if (formValues.pregnancyMetWithObgyn !== null) {
      await updateMedicalInfo({ pregnancyMetWithObgyn: formValues.pregnancyMetWithObgyn })
      send("")
    } else {
      await onSkip()
    }
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["pregnancyMetWithObgyn"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Have you met with an obstetrician (OB/GYN) since becoming pregnant?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="pregnancyMetWithObgyn-label">{header}</span>}
        formElements={
          <Form onSubmit={onClickContinue} defaultValues={{ pregnancyMetWithObgyn: defaultValue }}>
            <FormRadioButtonGroup
              name="pregnancyMetWithObgyn"
              options={radioOptions}
              aria-labelledby="pregnancyMetWithObgyn-label"
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
