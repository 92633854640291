import ResponseError from "../types/responseError"
import { HttpStatusCodes } from "./HttpStatusCodes"

export default function buildCarrotApiRetryPolicy(maxFailures: number) {
  return function (failureCount: number, error: ResponseError): boolean {
    const statusCodesToNotRetry = [
      HttpStatusCodes.UNAUTHORIZED,
      HttpStatusCodes.FORBIDDEN,
      HttpStatusCodes.BAD_REQUEST,
      HttpStatusCodes.NOT_FOUND
    ]

    return !statusCodesToNotRetry.includes(error.statusCode) && failureCount < maxFailures
  }
}
