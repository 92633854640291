import React, { HTMLAttributes, TextareaHTMLAttributes } from "react"
import { SharedInputCssProps, StyledTextArea } from "components/views/atoms/forms/StyledInputs"
import { completeFormElementFactory, CompleteFormElementProps } from "./completeFormElementFactory"

type FormTextAreaProps = HTMLAttributes<HTMLTextAreaElement> &
  Partial<SharedInputCssProps> &
  TextareaHTMLAttributes<HTMLTextAreaElement>

const FormTextArea = React.forwardRef<HTMLTextAreaElement, FormTextAreaProps>(
  ({ placeholder, name, rows = 6, valid = true, ...props }, ref) => (
    <StyledTextArea valid={valid} placeholder={placeholder} name={name} ref={ref} rows={rows} {...props} />
  )
)

const CompleteFormTextArea = completeFormElementFactory<
  HTMLTextAreaElement,
  CompleteFormElementProps<HTMLTextAreaElement> & FormTextAreaProps
>(FormTextArea)

FormTextArea.displayName = "FormTextArea"

export { CompleteFormTextArea, FormTextArea }
