import { useSelector } from "react-redux"
import { getBankInfoEnabled } from "reducers/benefitConfiguration"
import { getCarrotLite } from "reducers/companyInfo"

export default function useShowBankDetails(): {
  showBankDetails: boolean
} {
  const isBankInfoEnabled = useSelector(getBankInfoEnabled)
  const isCarrotLite = useSelector(getCarrotLite)

  return {
    showBankDetails: isBankInfoEnabled && !isCarrotLite
  }
}
