import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Container } from "@carrotfertility/carotene"
import { Steps } from "../workflow/steps"
import { useProgressTracker } from "../../views/modal/ProgressTracker"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import useSubmitWithErrorCatch from "../hooks/useSubmitWithErrorCatch"
import {
  BenefitEnrollmentFlowModalBackButton,
  BenefitEnrollmentModalContinueButton,
  BenefitEnrollmentModalFormSelect
} from "../shared"
import { useCurrentUser } from "../../context/user/UserContext"
import { useUserDeductible } from "../../context/user/DeductibleContext"
import { defineMessage, useIntl, MessageDescriptor } from "react-intl"
import { Box, Typography } from "@carrotfertility/carotene-core"

interface HsaContributionStatusStepProps {
  ViewName: string
  FieldName: string
  hsaContributionStatusValue: string
  contributionStatus?: string
  updateUserInfo: (val: string) => void
  title: string
  bodyTop: string
  bodyBottom: string
}

export const enum HsaContributionOptions {
  ACTIVELY_CONTRIBUTING = "ACTIVELY_CONTRIBUTING",
  EXPECT_TO_CONTRIBUTE = "EXPECT_TO_CONTRIBUTE",
  NOT_CONTRIBUTING = "NOT_CONTRIBUTING",
  IM_NOT_SURE = "IM_NOT_SURE"
}

const hsaIdToLabelMapping = {
  [HsaContributionOptions.ACTIVELY_CONTRIBUTING]: defineMessage({ defaultMessage: "Actively contributing" }),
  [HsaContributionOptions.EXPECT_TO_CONTRIBUTE]: defineMessage({
    defaultMessage: "Expecting to contribute this benefit year"
  }),
  [HsaContributionOptions.NOT_CONTRIBUTING]: defineMessage({
    defaultMessage: "Not contributing or planning to contribute this benefit year"
  }),
  [HsaContributionOptions.IM_NOT_SURE]: defineMessage({ defaultMessage: "I'm not sure" })
}

export const getHsaContributionLabelFromId = (id: HsaContributionOptions): MessageDescriptor => hsaIdToLabelMapping[id]

function HsaContributionStatusStep({
  ViewName,
  FieldName,
  hsaContributionStatusValue,
  contributionStatus,
  updateUserInfo,
  title,
  bodyTop,
  bodyBottom
}: HsaContributionStatusStepProps): JSX.Element {
  const intl = useIntl()
  useProgressTracker(1, intl.formatMessage({ defaultMessage: "YOUR INSURANCE COVERAGE" }))
  const formMethods = useForm()
  function getHsaContributionStatusValue(val: string, answer: string) {
    return val !== "IM_NOT_SURE" && val !== null ? answer : val
  }
  const { setNextStep } = useStateMachine(
    ViewName,
    getHsaContributionStatusValue(contributionStatus, hsaContributionStatusValue)
  )
  // @ts-expect-error TS7006
  const { onSubmit } = useSubmitWithErrorCatch(async (args) => {
    const val = args[FieldName].value
    val !== "IM_NOT_SURE" && (await updateUserInfo(val))
    setNextStep(ViewName, getHsaContributionStatusValue(val, hsaContributionStatusValue))
  })

  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <Container padding="none" stack="huge">
        <BenefitEnrollmentFlowModalBackButton />
      </Container>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        {title}
      </Typography>
      <Container padding="none" stack="medium">
        <Typography color={(theme) => theme.palette.text.secondary}>{bodyTop}</Typography>
      </Container>
      <Container padding="none" stack="medium">
        <Typography color={(theme) => theme.palette.text.secondary}>{bodyBottom}</Typography>
      </Container>
      <Container padding="none" stack="giant">
        <BenefitEnrollmentModalFormSelect
          name={FieldName}
          aria-labelledby="step-heading"
          defaultValue={contributionStatus}
          options={[
            {
              label: intl.formatMessage(getHsaContributionLabelFromId(HsaContributionOptions.ACTIVELY_CONTRIBUTING)),
              value: HsaContributionOptions.ACTIVELY_CONTRIBUTING
            },
            {
              label: intl.formatMessage(getHsaContributionLabelFromId(HsaContributionOptions.EXPECT_TO_CONTRIBUTE)),
              value: HsaContributionOptions.EXPECT_TO_CONTRIBUTE
            },
            {
              label: intl.formatMessage(getHsaContributionLabelFromId(HsaContributionOptions.NOT_CONTRIBUTING)),
              value: HsaContributionOptions.NOT_CONTRIBUTING
            },
            {
              label: intl.formatMessage(getHsaContributionLabelFromId(HsaContributionOptions.IM_NOT_SURE)),
              value: HsaContributionOptions.IM_NOT_SURE
            }
          ]}
        />
      </Container>
      <Box height="10rem" />
      <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
    </FormProvider>
  )
}

function MemberHsaContributionStatusStepBase(step: Steps): JSX.Element {
  const { memberHsaContributionStatus } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const intl = useIntl()
  return (
    <HsaContributionStatusStep
      FieldName={"memberHsaContributionStatusValue"}
      ViewName={step}
      hsaContributionStatusValue={"MEMBER_ANSWER"}
      updateUserInfo={(val) =>
        updateBenefitEnrollmentAnswers({
          memberHsaContributionStatus: val
        })
      }
      title={intl.formatMessage({
        defaultMessage:
          "Are you or your employer contributing to a health savings account (HSA) associated with your HDHP?"
      })}
      bodyTop={intl.formatMessage({
        defaultMessage:
          "An HSA lets you use pre-tax income to pay for eligible medical expenses, and is only available with an HDHP."
      })}
      bodyBottom={intl.formatMessage({
        defaultMessage:
          "For help understanding your plan details, we recommend contacting your insurance provider or benefits manager."
      })}
      contributionStatus={memberHsaContributionStatus}
    />
  )
}

function MemberIndividualHsaContributionStatusStep(): JSX.Element {
  return MemberHsaContributionStatusStepBase(Steps.MEMBER_INDIVIDUAL_HSA_CONTRIBUTION)
}

function MemberFamilyHsaContributionStatusStep(): JSX.Element {
  return MemberHsaContributionStatusStepBase(Steps.MEMBER_FAMILY_HSA_CONTRIBUTION)
}

function PartnerHsaContributionStatusStep(): JSX.Element {
  const { partnerHsaContributionStatus } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const intl = useIntl()
  return (
    <HsaContributionStatusStep
      FieldName={"partnerHsaContributionStatusValue"}
      ViewName={Steps.PARTNER_HSA_CONTRIBUTION}
      hsaContributionStatusValue={"PARTNER_ANSWER"}
      updateUserInfo={(val) =>
        updateBenefitEnrollmentAnswers({
          partnerHsaContributionStatus: val
        })
      }
      title={intl.formatMessage({
        defaultMessage:
          "Are they or their employer contributing to a health savings account (HSA) associated with their HDHP?"
      })}
      bodyTop={intl.formatMessage({
        defaultMessage:
          "An HSA lets them use pre-tax income to pay for eligible medical expenses, and is only available with an HDHP."
      })}
      bodyBottom={intl.formatMessage({
        defaultMessage:
          "For help understanding their plan details, we recommend contacting their insurance provider or benefits manager."
      })}
      contributionStatus={partnerHsaContributionStatus}
    />
  )
}

export {
  MemberIndividualHsaContributionStatusStep,
  MemberFamilyHsaContributionStatusStep,
  PartnerHsaContributionStatusStep
}
