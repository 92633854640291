import { defineMessages } from "react-intl"

export const bankDetailTitleMessages = defineMessages({
  NameAsItAppearsOnYourBankAccount_Generic: {
    defaultMessage: "Account holder's full name"
  },
  NameAsItAppearsOnYourBankAccount_Generic_Short: {
    defaultMessage: "Name"
  },
  BankName_Generic: {
    defaultMessage: "Bank name"
  },
  WhereYouOpenedYourAccount_Generic: {
    defaultMessage: "Where you opened your account"
  },
  WhereYouOpenedYourAccount_Generic_Short: {
    defaultMessage: "Country"
  },
  BankCity_Generic: {
    defaultMessage: "Bank city"
  },
  AccountType_Generic: {
    defaultMessage: "Account Type"
  },
  AccountNumber_Generic: {
    defaultMessage: "Account number"
  },
  IBANOrBSB_Generic: {
    defaultMessage: "IBAN (international bank account number) or BSB"
  },
  IBANOrBSB_Generic_Short: {
    defaultMessage: "IBAN or BSB"
  },
  SortCode_Generic: {
    defaultMessage: "Sort code"
  },
  SWIFTOrBic_Generic: {
    defaultMessage: "SWIFT or bic (bank identifier code)"
  },
  SWIFTOrBic_Generic_Short: {
    defaultMessage: "SWIFT or BIC"
  },
  AccountPhoneNumber_Generic: {
    defaultMessage: "Account Holder's Phone Number"
  },
  Other_Generic: {
    defaultMessage: "Other"
  },
  RoutingNumber_US: {
    defaultMessage: "Routing Number"
  },
  AccountType_US: {
    defaultMessage: "Account Type"
  },
  AccountNumber_US: {
    defaultMessage: "Account Number"
  },
  AccountNumber_GB: {
    defaultMessage: "Account Number"
  },
  InternationalBankAccountNumber_GB: {
    defaultMessage: "International bank account number (IBAN)"
  },
  InternationalBankAccountNumber_GB_Short: {
    defaultMessage: "IBAN"
  },
  SwiftOrBIC_GB: {
    defaultMessage: "SWIFT or BIC (Bank Identifier Code)"
  },
  SwiftOrBIC_GB_Short: {
    defaultMessage: "SWIFT or BIC"
  },
  SortCode_GB: {
    defaultMessage: "Sort Code"
  },
  SWIFTOrBIC_AU: {
    defaultMessage: "SWIFT or BIC (Bank Identifier Code)"
  },
  SWIFTOrBIC_AU_Short: {
    defaultMessage: "SWIFT or BIC"
  },
  BankStateBranchNumber_AU: {
    defaultMessage: "Bank State Branch Number (BSB)"
  },
  BankStateBranchNumber_AU_Short: {
    defaultMessage: "BSB"
  },
  AccountNumber_AU: {
    defaultMessage: "Account Number"
  },
  SWIFTorBIC_CA: {
    defaultMessage: "SWIFT or BIC (Bank Identifier Code)"
  },
  SWIFTorBIC_CA_Short: {
    defaultMessage: "SWIFT or BIC"
  },
  BankCode_CA: {
    defaultMessage: "Bank Code"
  },
  BankCode_CA_Short: {
    defaultMessage: "Bank Code"
  },
  BranchNumber_CA: {
    defaultMessage: "Branch Number"
  },
  BranchNumber_CA_Short: {
    defaultMessage: "Branch Number"
  },
  AccountNumber_CA: {
    defaultMessage: "Account Number"
  },
  AccountHolderIndianFinancialSystemCode_IN: {
    defaultMessage: "Account Holder's Indian Financial System Code (IFSC)"
  },
  AccountHolderIndianFinancialSystemCode_IN_Short: {
    defaultMessage: "IFSC"
  },
  AccountNumber_IN: {
    defaultMessage: "Account Number"
  },
  SWIFTOrBIC_IN: {
    defaultMessage: "SWIFT or BIC (Bank Identifier Code)"
  },
  SWIFTOrBIC_IN_Short: {
    defaultMessage: "SWIFT or BIC"
  },
  AccountPhoneNumber_BR: {
    defaultMessage: "Account Holder's Phone Number"
  },
  AccountPhoneNumber_BR_Short: {
    defaultMessage: "Account Holder's Phone Number"
  },
  AccountOwnerTaxIDCPF_BR: {
    defaultMessage: "Account holder's Tax ID/CPF"
  },
  AccountOwnerTaxIDCPF_BR_Short: {
    defaultMessage: "Tax ID"
  },
  InternationalBankAccountNumber_BR: {
    defaultMessage: "International Bank Account Number"
  },
  InternationalBankAccountNumber_BR_Short: {
    defaultMessage: "IBAN"
  },
  PrimaryBankSWIFTOrBIC_BR: {
    defaultMessage: "Primary Bank SWIFT or BIC (Bank Identifier Code)"
  },
  PrimaryBankSWIFTOrBIC_BR_Short: {
    defaultMessage: "Primary Bank SWIFT or BIC"
  },
  IntermediaryBankSWIFTOrBIC_BR: {
    defaultMessage: "Intermediary Bank SWIFT or BIC (Bank Identifier Code)"
  },
  IntermediaryBankSWIFTOrBIC_BR_Short: {
    defaultMessage: "Intermediary Bank SWIFT or BIC"
  },
  AgencyCode_BR: {
    defaultMessage: "Agency Code"
  },
  AccountType_AR: {
    defaultMessage: "Account Type"
  },
  AccountHolderPhoneNumber_AR: {
    defaultMessage: "Account Holder's Phone Number"
  },
  AccountHolderPhoneNumber_AR_Short: {
    defaultMessage: "Phone number"
  },
  TaxID_CUIT_AR: {
    defaultMessage: "Tax ID/CUIT"
  },
  TaxID_CUIT_AR_Short: {
    defaultMessage: "Tax ID/CUIT"
  },
  SWIFTOrBIC_AR: {
    defaultMessage: "SWIFT or BIC (Bank Identifier Code)"
  },
  SWIFTOrBIC_AR_Short: {
    defaultMessage: "SWIFT or BIC"
  },
  SingleBankingCode_AR: {
    defaultMessage: "Single Banking Code"
  },
  SingleBankingCode_AR_Short: {
    defaultMessage: "CBU"
  }
})
