import React from "react"
import { FlexContainer, H2, Stack, Text } from "@carrotfertility/carotene"
import ArrowSubmitButton from "features/legacy-components/ArrowSubmitButton"
import { BackArrow } from "components/views/atoms/Molecules"
import { Progress } from "components/views/modal/Progress"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { useReimbursementContext } from "components/context/reimbursement/GetReimbursedContext"
import { FormattedMessage, useIntl } from "react-intl"
import { FormControlLabel, Checkbox } from "@carrotfertility/carotene-core"

export const AttestationMedicallyRelated = (): JSX.Element => {
  const { send, back } = useStateMachine()
  const intl = useIntl()

  const { state, updateAttestationIsMedicallyRelated, expenseId } = useReimbursementContext()

  const canSubmit = state.attestationIsMedicallyRelated

  // expenseRelatedTo has been translated before setting state
  const journeyType = state.expenseRelatedTo?.label?.toString().toLocaleLowerCase(intl.locale)

  const handleContinueClick = (): void => {
    send("")
  }

  const goBack = (): void => {
    updateAttestationIsMedicallyRelated(false)
    back()
  }

  const confirmationMessage = expenseId
    ? intl.formatMessage({
        defaultMessage: "We need your confirmation to process your expense in a legally compliant way."
      })
    : intl.formatMessage({
        defaultMessage: "We need your confirmation to process your reimbursement in a legally compliant way."
      })

  return (
    <>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={goBack} />
      <Stack minHeight="500px" paddingTopBottom="huge" spacing="medium">
        <H2>
          <FormattedMessage defaultMessage="Please confirm one thing." />
        </H2>
        <Text>{confirmationMessage}</Text>
        <Stack alignItems={["start", "center"]} spacing="tiny" direction={["column", "row"]}>
          <FormControlLabel
            label={
              <FormattedMessage
                defaultMessage="This is a medical expense related to {journeyType}"
                values={{
                  journeyType
                }}
              />
            }
            control={
              <Checkbox
                value="acknowledged"
                required={true}
                checked={state.attestationIsMedicallyRelated}
                onChange={(e) => {
                  updateAttestationIsMedicallyRelated(e.target.checked)
                }}
                data-testid="checkbox-medically-related"
              />
            }
          />
        </Stack>
      </Stack>
      <FlexContainer paddingTopBottom="huge" justifyContent="flex-end">
        <ArrowSubmitButton enabled={canSubmit} onClick={handleContinueClick}>
          <FormattedMessage defaultMessage="Continue" />
        </ArrowSubmitButton>
      </FlexContainer>
    </>
  )
}
