import React from "react"
import { LocalizedPageOption } from "./TalkToCarrotRouter"

interface TalkToCarrotContextProps {
  talkToCarrotLocationOptions: LocalizedPageOption[]
  companyGeoLocationOption: LocalizedPageOption
  selectedLocationOption: LocalizedPageOption
  setSelectedLocationOption: (selectedOption: LocalizedPageOption) => void
}
const TalkToCarrotContext = React.createContext<TalkToCarrotContextProps>({
  talkToCarrotLocationOptions: null,
  companyGeoLocationOption: null,
  selectedLocationOption: null,
  setSelectedLocationOption: null
})

export { TalkToCarrotContext }
