import React, { useEffect } from "react"
import { useRequestCarrotCard } from "./AirwallexUserAgreement/request-carrot-card"
import { attemptRequest } from "./AirwallexUserAgreement/agreement-helpers"
import { DialogActionsBar, Form, FormButton } from "@carrotfertility/carotene-core-x"
import RequestCardWrapper from "../requestCardWrapper"
import { unexpectedServerErrorAlert } from "../requestCardWrapper/unexpected-error-alert"
import { Box, Progress } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"

export function NoUserAgreementRequest() {
  const { send } = useStateMachine()
  const { mutate, isError, mutateAsync } = useRequestCarrotCard({
    onSuccess: () => send("")
  })
  useEffect(() => mutate(), [mutate])
  const showRetry = isError

  return (
    <Form onSubmit={() => attemptRequest(mutateAsync)}>
      <RequestCardWrapper hideProgressBar={isError}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: showRetry ? "flex-end" : "center" }}>
          {showRetry ? (
            <>
              {unexpectedServerErrorAlert}
              <DialogActionsBar
                primaryAction={
                  <FormButton type="submit">
                    <FormattedMessage defaultMessage="Try again" />
                  </FormButton>
                }
              />
            </>
          ) : (
            <Progress size="1rem" />
          )}
        </Box>
      </RequestCardWrapper>
    </Form>
  )
}
