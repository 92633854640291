import React, { FC } from "react"
import { Button, ButtonProps } from "./Button"

type ButtonPrimaryProps = ButtonProps

const ButtonPrimary: FC<ButtonPrimaryProps> = ({ children, ...props }) => (
  <Button {...props} color="white" backgroundColor="carrot-orange" hoverBackgroundColor="dark-carrot-orange">
    {children}
  </Button>
)
export { ButtonPrimary }
