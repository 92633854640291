import React from "react"

const Form = ({ children, onSubmit, ...props }) => {
  if (!onSubmit) {
    onSubmit = (event) => {
      event.preventDefault()
      return false
    }
  }
  return (
    <form onSubmit={onSubmit} {...props}>
      {children}
      <input type="submit" className="invisible" />
    </form>
  )
}

export { Form }
