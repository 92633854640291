import React, { ReactElement } from "react"
import { ExternalIcon, InternalIcon, Link, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import useFindHelpLink from "./useFindHelpLink"
import useUpdateMemberActionTracking from "../../services/memberActionTracking/useUpdateMemberActionTracking"
import { MemberActions } from "../../services/memberActionTracking/memberActions"
import { SDoHQuestionnaireDialog } from "./SDoHQuestionnaireDialog"

export const SDoHFindHelpLink = (): ReactElement => {
  const { findHelpLink } = useFindHelpLink()
  const memberActionMutation = useUpdateMemberActionTracking()

  return (
    <Link
      href={findHelpLink}
      target={"_blank"}
      onClick={() => memberActionMutation.mutateAsync(MemberActions.SDOH_FINDHELP_LINK_CLICKED)}
      sx={linkStyles}
      color={"primary"}
      endIcon={<ExternalIcon fontSize="small" color="primary" />}
      component={"a"}
    >
      <Typography>
        <FormattedMessage defaultMessage="Search for resources" />
      </Typography>
    </Link>
  )
}

export const SDoHQuestionLink = (): ReactElement => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <AnswerQuestionLink setOpen={setOpen} />
      <SDoHQuestionnaireDialog open={open} setOpen={setOpen} />
    </>
  )
}

const AnswerQuestionLink = ({ setOpen }: { setOpen: (open: boolean) => void }) => {
  return (
    <Link
      sx={linkStyles}
      color={"primary"}
      endIcon={<InternalIcon fontSize="small" color="primary" />}
      component={"button"}
      onClick={() => setOpen(true)}
    >
      <Typography>
        <FormattedMessage defaultMessage="Answer Question" />
      </Typography>
    </Link>
  )
}

const linkStyles = {
  display: "flex",
  alignItems: "center",
  textDecorationLine: "none",
  cursor: "pointer",
  "&:hover": {
    textDecorationLine: "underline",
    textDecorationColor: "primary"
  }
}
