import { FlexContainer, useFormContext } from "@carrotfertility/carotene"
import { Spacer } from "components/views/atoms/Atoms"
import { Body } from "components/views/atoms/Bodies"
import { ArrowLinkArrow } from "components/views/atoms/links/ArrowLink"
import React from "react"
import styled from "styled-components"

interface ArrowSubmitButtonProps {
  children: React.ReactNodeArray | React.ReactNode
  onClick?: () => unknown
  enabled?: boolean
}

const UnstyledButton = styled.button`
  border: none;
  background: unset;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0.8, 0, 0.2, 1);
  :disabled {
    opacity: 0.4;
    cursor: unset;
  }
`

export default function ArrowSubmitButton({ children, onClick, enabled = true }: ArrowSubmitButtonProps): JSX.Element {
  return (
    <UnstyledButton name="continue" disabled={!enabled} type="submit" onClick={onClick}>
      <FlexContainer direction="row">
        <ArrowLinkArrow />
        <Spacer width={0.5} />
        <Body display="di" weight="fw5" color="black-80">
          {children}
        </Body>
      </FlexContainer>
    </UnstyledButton>
  )
}

export function FormArrowSubmitButton({ children }: React.PropsWithChildren<unknown>): JSX.Element {
  const instance = useFormContext()
  return (
    <ArrowSubmitButton enabled={instance?.meta?.canSubmit && !instance?.meta?.isSubmitting}>
      {children}
    </ArrowSubmitButton>
  )
}
