import React, { FC } from "react"
import { useDispatch } from "react-redux"
import { fetchReimbursementDetails } from "../../../actions/reimbursementDetailsActions"
import { PageLayout } from "../../../features/global-ui/page-layout"
import { Title } from "../../../services/page-title/Title"
import { useIntl } from "react-intl"
import ActivateTemplate from "./ActivateTemplate"
import { DbgPaths } from "utils/Paths"
import EmployeeOnlySpaceContainer from "components/employee-only-containers/EmployeeOnlySpaceContainer"

// view component immediately after successfully completing carrot card activation
const ActivateSuccess: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const fetchUpdatedReimbursementDetails = async () => {
    await dispatch(fetchReimbursementDetails())
  }
  return (
    <PageLayout>
      <EmployeeOnlySpaceContainer>
        <Title title={intl.formatMessage({ defaultMessage: "Carrot Card activated" })} />
        <ActivateTemplate
          header={intl.formatMessage({ defaultMessage: "Success" })}
          body={intl.formatMessage({
            defaultMessage:
              "Your Carrot Card is now active. You may now start using your card to pay for eligible care."
          })}
          linkText={intl.formatMessage({
            defaultMessage: "How to use your Carrot Card"
          })}
          linkUrl={DbgPaths.CARROT_CARD}
          buttonLink="/"
          buttonText={intl.formatMessage({ defaultMessage: "Return home" })}
          buttonOnClick={fetchUpdatedReimbursementDetails}
        />
      </EmployeeOnlySpaceContainer>
    </PageLayout>
  )
}

export default ActivateSuccess
