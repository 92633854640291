import { dayjs } from "@carrotfertility/carotene-core"
import { MessageDescriptor, defineMessage } from "react-intl"

const DATE_VALIDATION_MESSAGE = {
  INVALID_DATE: defineMessage({ defaultMessage: "Please enter a valid date" }),
  NOT_A_DATE: defineMessage({ defaultMessage: "Not a date" }),
  MUST_BE_18: defineMessage({ defaultMessage: "You must be at least 18 years old." }),
  MEMBER_DOB_MUST_BE_IN_PAST: defineMessage({ defaultMessage: "Date of birth must be in the past" }),
  PARTNER_DOB_MUST_BE_IN_PAST: defineMessage({ defaultMessage: "Your partner's date of birth must be in the past." }),
  DATE_MUST_BE_IN_PAST: defineMessage({ defaultMessage: "Date must be in the past" })
}

// Attempting to save a date before 1753-01-01 will throw an error on the backend.
const oldestValidServerDate = new Date(1753, 1, 1)

export function validateMemberDOB(dateOfBirth: dayjs.Dayjs): MessageDescriptor | true {
  if (dateOfBirth.isBefore(oldestValidServerDate)) {
    return DATE_VALIDATION_MESSAGE.INVALID_DATE
  }

  if (!dateOfBirth.isValid()) {
    return DATE_VALIDATION_MESSAGE.NOT_A_DATE
  }

  const yesterday = dayjs().subtract(1, "day")
  if (dateOfBirth.isAfter(yesterday)) {
    return DATE_VALIDATION_MESSAGE.MEMBER_DOB_MUST_BE_IN_PAST
  }

  const eighteenYearsAgo = dayjs().subtract(18, "years")
  if (dateOfBirth.isAfter(eighteenYearsAgo)) {
    return DATE_VALIDATION_MESSAGE.MUST_BE_18
  }

  return true
}

export function validatePartnerDOB(dateOfBirth: dayjs.Dayjs): MessageDescriptor | true {
  if (dateOfBirth.isBefore(oldestValidServerDate)) {
    return DATE_VALIDATION_MESSAGE.INVALID_DATE
  }

  if (!dateOfBirth.isValid()) {
    return DATE_VALIDATION_MESSAGE.NOT_A_DATE
  }

  const yesterday = dayjs().subtract(1, "day")
  if (dateOfBirth.isAfter(yesterday)) {
    return DATE_VALIDATION_MESSAGE.PARTNER_DOB_MUST_BE_IN_PAST
  }

  const eighteenYearsAgo = dayjs().subtract(18, "years")
  if (dateOfBirth.isAfter(eighteenYearsAgo)) {
    return DATE_VALIDATION_MESSAGE.MUST_BE_18
  }

  return true
}

export function validatePastDate(date: dayjs.Dayjs): MessageDescriptor | true {
  if (date?.isBefore(oldestValidServerDate) || !date.isValid()) {
    return DATE_VALIDATION_MESSAGE.INVALID_DATE
  }

  const today = dayjs()
  if (date.isAfter(today)) {
    return DATE_VALIDATION_MESSAGE.DATE_MUST_BE_IN_PAST
  }

  return true
}

export function validateOptionalDate(date: dayjs.Dayjs): MessageDescriptor | true {
  if (date?.isBefore(oldestValidServerDate)) {
    return DATE_VALIDATION_MESSAGE.INVALID_DATE
  }

  return true
}
