import moment from "moment"

export const DAYS_IN_YEAR = 365
export const DAYS_IN_LEAP_YEAR = 366

export const dateRangeContainsLeapDay = (start: string, end: string): boolean => {
  const startMoment = moment(start, "YYYY-MM-DD")
  const endMoment = moment(end, "YYYY-MM-DD")

  return (
    (startMoment.isLeapYear() || endMoment.isLeapYear()) &&
    (moment(startMoment.get("year") + "-02-29").isBetween(start, end, null, "[]") ||
      moment(endMoment.get("year") + "-02-29").isBetween(start, end, null, "[]"))
  )
}
