import { MessageDescriptor, useIntl } from "react-intl"

// @ts-expect-error TS7006
function isMessageDescriptor(maybeMessageDescriptor): maybeMessageDescriptor is MessageDescriptor {
  return (maybeMessageDescriptor as MessageDescriptor).id !== undefined
}

type LegacyCaroteneFormValidator<T> = (value: T) => string | MessageDescriptor | false
type CaroteneFormValidator<T> = (value: T | MessageDescriptor) => string | MessageDescriptor | true
type Validator<T> = CaroteneFormValidator<T> | LegacyCaroteneFormValidator<T>

export function useIntlValidator<T>(validator: Validator<T>) {
  const intl = useIntl()
  return (value: T) => {
    const result = validator(value)
    if (isMessageDescriptor(result)) return intl.formatMessage(result)
    else return result
  }
}
