import { Divider, Stack, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { useWatch } from "react-hook-form"
import { useIntl } from "react-intl"
import { FormSelect, FormTextField } from "@carrotfertility/carotene-core-x"
import { FeatureGate } from "utils/FeatureGate"
import { useGetSexOptions } from "services/common-forms/SexOptions"

export function GenderIdentitySettings({
  names,
  shouldBeDisabled = false
}: {
  names: { genderIdentity: string; genderIdentityOther: string }
  shouldBeDisabled?: boolean
}): JSX.Element {
  const genderIdentityValue = useWatch({ name: names.genderIdentity })
  const theme = useTheme()
  const intl = useIntl()

  return (
    <>
      <FeatureGate filter={(c) => c.isLgtbqEnabled}>
        <FormSelect
          disabled={shouldBeDisabled}
          label={intl.formatMessage({ defaultMessage: "Gender Identity" })}
          name={names.genderIdentity}
          options={[
            { label: intl.formatMessage({ defaultMessage: "Man" }), value: "MAN" },
            { label: intl.formatMessage({ defaultMessage: "Woman" }), value: "WOMAN" },
            { label: intl.formatMessage({ defaultMessage: "Non-binary" }), value: "NON_BINARY" },
            {
              label: intl.formatMessage({ defaultMessage: "Another gender identity" }),
              value: "ANOTHER_GENDER_IDENTITY"
            }
          ]}
        />
      </FeatureGate>
      {genderIdentityValue === "ANOTHER_GENDER_IDENTITY" ? (
        <Stack display="flex" direction="row" spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <Divider orientation="vertical" flexItem />
          <FormTextField
            disabled={shouldBeDisabled}
            name={names.genderIdentityOther}
            placeholder={intl.formatMessage({ defaultMessage: "Optional details" })}
            label={intl.formatMessage({ defaultMessage: "Other" })}
            inputProps={{ maxLength: 200 }}
          />
        </Stack>
      ) : null}
    </>
  )
}

export function SexSettings({
  name,
  shouldBeDisabled = false
}: {
  name: string
  shouldBeDisabled?: boolean
}): JSX.Element {
  const intl = useIntl()
  const sexOptions = useGetSexOptions()

  return (
    <FormSelect
      disabled={shouldBeDisabled}
      {...{ name }}
      label={intl.formatMessage({ defaultMessage: "Sex" })}
      options={sexOptions}
    />
  )
}
