import { CarrotCardProvider } from "services/reimbursements/types/ReimbursementDetails"
import { Steps } from "../steps"
import { Workflows } from "../workflows"
import {
  ActiveStepFunction,
  CarrotCardWorkflowContextualInformation,
  GetWorkflowFunction,
  InitialStepFunction,
  ShouldSeeUserAgreementFunction,
  TotalStepsFunction
} from "./routing-helper-types"
import {
  getActiveStepStripeRequestCard,
  getInitialRequestCardStep,
  getStripeShouldSeeUserAgreement,
  getStripeWorkflow,
  getTotalStepsStripeRequestCard
} from "./stripe"
import {
  getActiveStepAirwallexInternationalRequestCard,
  getAirwallexShouldSeeUserAgreement,
  getAirwallexWorkflow,
  getInitialAirwallexInternationalRequestCardStep,
  getTotalStepsAirwallexInternationalRequestCard
} from "./airwallex"

const ShouldSeeUserAgreementFunctionByProvider: Record<CarrotCardProvider, ShouldSeeUserAgreementFunction> = {
  [CarrotCardProvider.STRIPE]: getStripeShouldSeeUserAgreement,
  [CarrotCardProvider.AIRWALLEX]: getAirwallexShouldSeeUserAgreement
}

const InitialStepFunctionByWorkflow: Record<Workflows, InitialStepFunction> = {
  [Workflows.REQUEST_CARD]: getInitialRequestCardStep,
  [Workflows.REQUEST_INTERNATIONAL_AIRWALLEX_CARD]: getInitialAirwallexInternationalRequestCardStep
}

// Even though we're not using the WorkflowContextualInformation, this is set up so that in the future
// we can have multiple workflows for a given provider
// EX: Airwallex might have a separate US or International flow
const DesiredWorkflowFunctionByProvider: Record<CarrotCardProvider, GetWorkflowFunction> = {
  [CarrotCardProvider.STRIPE]: getStripeWorkflow,
  [CarrotCardProvider.AIRWALLEX]: getAirwallexWorkflow
}

const TotalStepsFunctionByWorkflow: Record<Workflows, TotalStepsFunction> = {
  [Workflows.REQUEST_CARD]: getTotalStepsStripeRequestCard,
  [Workflows.REQUEST_INTERNATIONAL_AIRWALLEX_CARD]: getTotalStepsAirwallexInternationalRequestCard
}

// Steps unfortunately don't map 1-1 to a section
// This might get more complicated as time goes on, but this abstraction was
// created to abstract the step Component away from the workflow its in
const ActiveStepFunctionByWorkflow: Record<Workflows, ActiveStepFunction> = {
  [Workflows.REQUEST_CARD]: getActiveStepStripeRequestCard,
  [Workflows.REQUEST_INTERNATIONAL_AIRWALLEX_CARD]: getActiveStepAirwallexInternationalRequestCard
}

export function getInitialStep(workflow: Workflows, contextualInformation: CarrotCardWorkflowContextualInformation) {
  const initialStepFunc = InitialStepFunctionByWorkflow[workflow]
  return initialStepFunc(contextualInformation)
}

export function getActiveStep(
  workflow: Workflows,
  step: Steps,
  contextualInformation: CarrotCardWorkflowContextualInformation
) {
  const activeStepFunc = ActiveStepFunctionByWorkflow[workflow]
  if (!activeStepFunc) {
    return 0
  }
  return activeStepFunc(step, contextualInformation)
}

export function getTotalSteps(workflow: Workflows, contextualInformation: CarrotCardWorkflowContextualInformation) {
  const totalStepsFunc = TotalStepsFunctionByWorkflow[workflow]
  if (!totalStepsFunc) {
    return 0
  }
  return totalStepsFunc(contextualInformation)
}

export function getDesiredWorkflow(
  provider: CarrotCardProvider,
  contextualInformation: CarrotCardWorkflowContextualInformation
) {
  const desiredWorkflowFunc = DesiredWorkflowFunctionByProvider[provider]
  return desiredWorkflowFunc(contextualInformation)
}

export function getShouldSeeUserAgreement(
  provider: CarrotCardProvider,
  contextualInformation: CarrotCardWorkflowContextualInformation
) {
  const shouldSeeUserAgreementFunction = ShouldSeeUserAgreementFunctionByProvider[provider]
  return shouldSeeUserAgreementFunction(contextualInformation)
}
