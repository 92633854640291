import React, { useEffect } from "react"
import { useToggle } from "utils/Hooks"
import { UpdateBankInfoModal } from "../../../containers/reimbursements/UpdateBankInfoModal"
import { BenefitEnrollmentFlowContainer } from "../../../cmd-enrollment"
import { useBenefitEnrollment } from "../../../context/enrollment/BenefitEnrollmentContext"
import { BenefitAvailabilityStatus } from "types/benefitEnrollmentStatus"
import { useHistory, useLocation } from "react-router"
import { useIntl } from "react-intl"
import { NavItemWrapper } from "./MainNavigation"
import { FinancialSupportIcon, GatedIcon } from "@carrotfertility/carotene-core"
import { useCurrentUser } from "components/context/user/UserContext"
import { AppLockedDownModal, LockedDownModalType } from "./nav-modals/AppLockedDownModal"
import { useUserRole } from "../../../partner-access/hooks/usePartnerAccess"

export function FinancialSupportNavItem({
  onClick,
  showLockedIcon
}: {
  onClick?: () => void
  showLockedIcon: boolean
}): JSX.Element {
  const { availabilityStatus } = useBenefitEnrollment()
  const needsFirstTimeEnrollment = availabilityStatus === BenefitAvailabilityStatus.NEEDS_BENEFIT_ENROLLMENT
  const [isBankInfoModalOpen, toggleIsBankInfoModalOpen] = useToggle(false)
  const [isBenefitEnrollmentModalOpen, toggleBenefitEnrollmentModal] = useToggle(false)
  const [isAppLockedDownModalOpen, toggleAppLockedDownModal] = useToggle(false)
  const history = useHistory()
  const location = useLocation()
  const intl = useIntl()
  const { isInAppLockdown } = useCurrentUser()
  const { isPartnerAccessingAccount } = useUserRole()

  useEffect(() => {
    if (location.hash === "#needs-benefit-enrollment" && !isBenefitEnrollmentModalOpen) {
      location.hash = ""
      toggleBenefitEnrollmentModal()
    }

    if (location.hash === "#no-bank-info" && !isBankInfoModalOpen) {
      location.hash = ""
      toggleIsBankInfoModalOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [location.hash, toggleIsBankInfoModalOpen, isBankInfoModalOpen])

  const onNavigateToTransactions = () => {
    if (isInAppLockdown && !isPartnerAccessingAccount) {
      toggleAppLockedDownModal()
    } else if (needsFirstTimeEnrollment) {
      toggleBenefitEnrollmentModal()
    } else {
      history.push("/transactions")
      onClick()
    }
  }

  const onCloseBenefitEnrollmentModal = () => {
    toggleBenefitEnrollmentModal()
    onClick()
  }

  const onCloseBankInfoModal = () => {
    toggleIsBankInfoModalOpen()
    onClick()
  }

  return (
    <>
      <NavItemWrapper
        onClick={onNavigateToTransactions}
        link="/transactions"
        button
        icon={<FinancialSupportIcon />}
        text={intl.formatMessage({ defaultMessage: "Financial support" })}
        extraNavIndicator={showLockedIcon ? <GatedIcon color="primary" fontSize="small" /> : null}
      />

      {isAppLockedDownModalOpen && (
        <AppLockedDownModal
          onClose={toggleAppLockedDownModal}
          open={isAppLockedDownModalOpen}
          modalType={LockedDownModalType.FinancialSupport}
        />
      )}

      {isBenefitEnrollmentModalOpen && <BenefitEnrollmentFlowContainer onExit={onCloseBenefitEnrollmentModal} />}

      {isBankInfoModalOpen && (
        <UpdateBankInfoModal onNavigateToAccountPage={onCloseBankInfoModal} onClose={toggleIsBankInfoModalOpen} />
      )}
    </>
  )
}
