import React, { useContext, useState } from "react"

const ShowMapViewContext = React.createContext(null)
export const useMapViewContext = () => useContext(ShowMapViewContext)

export function MapViewContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [showMapView, setShowMapView] = useState(false)
  return (
    <ShowMapViewContext.Provider
      value={{
        showMapView,
        setShowMapView
      }}
    >
      {children}
    </ShowMapViewContext.Provider>
  )
}
