import { defineMessages } from "react-intl"

export const bankDetailValidationMessages = defineMessages({
  MustBeNDigits: {
    defaultMessage: "{fieldName} must be {length} digits"
  },
  MustBeBetweenNAndMDigits: {
    defaultMessage: "{fieldName} must be {minLength}-{maxLength} digits"
  },
  MustBeNCharacters: {
    defaultMessage: "{fieldName} must be {length} characters"
  },
  FieldIsRequired: {
    defaultMessage: "{fieldName} is required"
  }
})
