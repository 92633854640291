import { Box, Link, Stack, Theme, Typography, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { DigitalFrontDoorSectionLayout } from "../../components/DigitalFrontDoorSectionLayout"
import { FormattedMessage, useIntl } from "react-intl"
import { useDigitalFrontDoorBreakpointViews } from "../../hooks/useDigitalFrontDoorBreakpointViews"

const US_SPANISH_PDF_URL =
  "https://assets.ctfassets.net/cwy2wizp0qlf/3IdD9oAyH2VzNlGbyEq0Jd/3db0905b2537439a65462d32b8b98fc5/Instrucciones_para_el_proveedor_-_Carrot_Rx.pdf"
const ENGLISH_PDF_URL =
  "https://assets.ctfassets.net/cwy2wizp0qlf/3IdD9oAyH2VzNlGbyEq0Jd/03338f21f57b2f7261f057c5e08527c7/Carrot_Rx_-_Provider_instructions.pdf"

export function StartSavingWithCarrotRxSection(): JSX.Element {
  const { locale } = useIntl()

  const providerInstructionsPdf = locale === "es-US" ? US_SPANISH_PDF_URL : ENGLISH_PDF_URL

  const { isMobileView, isAboveXLScreenView } = useDigitalFrontDoorBreakpointViews()
  const theme = useTheme()

  const responsiveGapForSubsections = isMobileView
    ? (theme: Theme) => theme.tokens.spacing.sm
    : (theme: Theme) => theme.tokens.spacing.xs

  const responsiveH3FontSize = isMobileView ? "1.125rem" : "1.5rem"
  const responsiveBodyFontSize = isMobileView ? "0.813rem" : "1rem"

  return (
    <Box id="extend-start-saving-with-carrot-rx" className="start-saving-with-carrot-rx-section">
      <DigitalFrontDoorSectionLayout backgroundColor={theme.palette.background.paper}>
        <Stack
          direction={isAboveXLScreenView ? "row" : "column"}
          gap={isMobileView ? (theme) => theme.tokens.spacing.lg : "6.375rem"}
          justifyContent="space-between"
          paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}
          marginBottom={isAboveXLScreenView ? "4rem" : theme.tokens.spacing.xl}
        >
          <Stack
            direction="column"
            gap={isMobileView ? (theme) => theme.tokens.spacing.lg : (theme) => theme.tokens.spacing.xxl}
          >
            <Typography
              id={"extend-startSavingWithCarrotRx"}
              variant="h2"
              fontSize={isMobileView ? "1.75rem" : "2.5rem"}
            >
              <FormattedMessage defaultMessage="Start saving with Carrot Rx" />
            </Typography>
            <Stack gap={responsiveGapForSubsections}>
              <Typography variant="h3" fontSize={responsiveH3FontSize}>
                <FormattedMessage defaultMessage="1. Talk to your provider" />
              </Typography>
              <Typography variant="body1" fontSize={responsiveBodyFontSize}>
                <FormattedMessage defaultMessage="Let them know you'd like Alto, Carrot Rx's pharmacy partner, to fill your prescriptions. For more detailed instructions, download the PDF below and share it with your provider." />
              </Typography>
              <Link
                id="extend-provider-instructions-pdf-link"
                href={providerInstructionsPdf}
                target="_blank"
                fontSize={responsiveBodyFontSize}
              >
                <FormattedMessage defaultMessage="Download PDF with provider instructions" />
              </Link>
            </Stack>
            <Stack gap={responsiveGapForSubsections}>
              <Typography variant="h3" fontSize={responsiveH3FontSize}>
                <FormattedMessage defaultMessage="2. Place your order with Alto" />
              </Typography>
              <Typography variant="body1" fontSize={responsiveBodyFontSize}>
                <FormattedMessage defaultMessage="When Carrot Rx receives a prescription from your provider, Alto will send you a text message that mentions Carrot Rx. Follow the link provided to order your prescriptions, and you’re all set. Any Carrot discounts will be applied automatically." />
              </Typography>
            </Stack>
          </Stack>
          <Box
            component="img"
            src={require("../../resources/images/carrot-rx-delivery.jpg")}
            alt=""
            width={isAboveXLScreenView ? "30.375rem" : "auto"}
            height={isMobileView ? "14.5rem" : "31rem"}
            borderRadius="0 2rem 0 0"
            sx={{ objectFit: "cover" }}
          />
        </Stack>
      </DigitalFrontDoorSectionLayout>
    </Box>
  )
}
