import { useState, useEffect } from "react"

const useToggle = (initialState) => {
  const [boolValue, setBoolValue] = useState(initialState)

  const toggle = (value = null) => {
    setBoolValue(typeof value === "boolean" ? value : !boolValue)
  }

  return [boolValue, toggle]
}

// eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
const useDidMount = (func) => useEffect(func, [])

const useWindowScroll = (func) =>
  useDidMount(() => {
    window.addEventListener("scroll", func)

    return () => {
      window.removeEventListener("scroll", func)
    }
  })

const useBodyClass = (className) => {
  useEffect(() => {
    const body = document.body
    body.classList.add(className)

    return () => {
      body.classList.remove(className)
    }
  }, [className])
}

const useWindowInnerHeight = () => {
  const [windowInnerHeight, setWindowInnerHeight] = useState()

  useDidMount(() => {
    const handleResize = () => {
      setWindowInnerHeight(window.innerHeight)
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  return [windowInnerHeight]
}

export { useToggle, useDidMount, useWindowScroll, useBodyClass, useWindowInnerHeight }
