import React from "react"
import { Spacer } from "./Atoms"

const render = (element) => element && (React.isValidElement(element) ? element : React.createElement(element))

export const BodyWithLeftSidebarLayout = ({ contentId = "", sideBarElements, children }) => (
  <div id={contentId} className="flex flex-row-l flex-column flex-auto">
    <div className="w-220px-l">
      {sideBarElements.map((sideBar) => (
        <div key={sideBar.key || ""}>{render(sideBar)}</div>
      ))}
    </div>
    <div className="db-l dn">
      <Spacer width={3} />
    </div>
    <div className="flex flex-auto">{children}</div>
  </div>
)

export const CenteredLayout = ({ children }) => (
  <div className="w-100 flex flex-column items-center ph4-l ph2-m ph35-s h-100">
    <div className="ph3-ns w-100 flex flex-column items-center h-100">
      <div className="mw-968-l mw-600-m w-100 h-100">{children}</div>
    </div>
  </div>
)
