import React from "react"

type HeapRedactionAttributes = {
  "data-heap-redact-text": true
}

export function getPiiProps(): HeapRedactionAttributes {
  return {
    "data-heap-redact-text": true
  }
}

export function PiiText(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>) {
  return <span {...{ ...props, ...getPiiProps() }} />
}
