import React, { FC } from "react"
import { SendMessage } from "../../talk-to-carrot/send-message/SendMessage"
import { MemberEmdIneligible } from "./EmdIneligibleStep"

type MemberEmdIneligibleProps = {
  onExit: () => void
}

export const MemberEmdIneligibleContainer: FC<MemberEmdIneligibleProps> = () => (
  <SendMessage modalTopic={"MemberEmdIneligible"} BenefitEnrollmentView={MemberEmdIneligible} />
)
