import { findCategoryBySlug, findGuidesBySlug, findSubcategoryForArticle } from "components/read/shared/Finder"
import { useContentfulConditionalGate } from "lib/contentful/components/ContentfulConditionGate"
import React from "react"
import { useHistory, useParams } from "react-router"
import { Guide, mapContentToGuide } from "./utils/utils"
import useGetCategories from "../../components/read/shared/useGetCategories"
import { EntryWithoutUnresolvableLinks } from "utils/ContentTypes"
import { TypeCategorySkeleton, TypeSubCategorySkeleton } from "types/contentful"

export type GuideComponentProps = {
  guide: Guide
  category: EntryWithoutUnresolvableLinks<TypeCategorySkeleton>
  subcategory: EntryWithoutUnresolvableLinks<TypeSubCategorySkeleton>
}

export const GuideParser = ({
  GuideComponent
}: {
  GuideComponent: React.ComponentType<GuideComponentProps>
}): JSX.Element => {
  const history = useHistory()
  const params = useParams<{ category: string; guide: string }>()

  const { data: categories } = useGetCategories()
  const category = findCategoryBySlug(categories, params.category)

  const guideContent = findGuidesBySlug(categories, params.guide)
  const guideConditions = guideContent?.map((guide) => {
    const mappedGuide = mapContentToGuide(guide)
    const subcategory = findSubcategoryForArticle(categories, guide)

    return {
      element: <GuideComponent guide={mappedGuide} category={category} subcategory={subcategory} />,
      conditions: mappedGuide.conditions
    }
  })

  const [guide] = useContentfulConditionalGate(guideConditions ? guideConditions : [], true)

  if (!guide || !category) {
    history.push("/read")
    return null
  }

  return <>{guide}</>
}
