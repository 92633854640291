const Settings = {
  CARROT_FRONTEND_URL: window.env?.CARROT_FRONTEND_URL,
  CARROT_BACKEND_URL: window.env?.CARROT_BACKEND_URL,
  CARROT_ADMIN_V2_URL: window.env?.CARROT_ADMIN_V2_URL,
  PROVIDER_FINDER_BASE_URL: window.env?.PROVIDER_FINDER_BASE_URL,
  INBOUND_MEMBER_PAYMENTS_BASE_URL: window.env?.INBOUND_MEMBER_PAYMENTS_BASE_URL,
  ABALONE_URL: window.env?.ABALONE_URL,
  PRODUCT_CONTENT_URL: window.env?.PRODUCT_CONTENT_URL,
  SENTRY_URL: window.env?.SENTRY_URL,
  HEAP_ENV_ID: window.env?.HEAP_ENV_ID,
  IVF_TREATMENT_OPTIONS_URL: window.env?.IVF_TREATMENT_OPTIONS_URL,
  IVF_TREATMENT_OPTIONS_URL_ES_US: window.env?.IVF_TREATMENT_OPTIONS_URL_ES_US,
  IVF_REQUIREMENTS_CHAT_TIMETAP_ENVIRONMENT: window.env?.IVF_REQUIREMENTS_CHAT_TIMETAP_ENVIRONMENT,
  IVF_REQUIREMENTS_CHAT_REASON_ID: window.env?.IVF_REQUIREMENTS_CHAT_REASON_ID,
  CONTENTFUL_SPACE_ID: window.env?.CONTENTFUL_SPACE_ID,
  CONTENTFUL_ACCESS_TOKEN: window.env?.CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_PREVIEW_MODE: window.env?.CONTENTFUL_PREVIEW_MODE,
  ZENDESK_GUIDE: window.env?.ZENDESK_GUIDE,
  GOOGLE_PLACES_API: window.env?.GOOGLE_PLACES_API,
  LAUNCH_DARKLY_CLIENT_ID: window.env?.LAUNCH_DARKLY_CLIENT_ID,
  AUTHORIZER_DOMAIN: window.env?.AUTHORIZER_DOMAIN,
  LOG_REDUX_EVENTS: window.env?.LOG_REDUX_EVENTS,
  SHOW_REACT_QUERY_DEVTOOLS: window.env?.SHOW_REACT_QUERY_DEVTOOLS,
  AUTO_REJECT_ONE_TRUST_COOKIE_CONSENT: window.env?.AUTO_REJECT_ONE_TRUST_COOKIE_CONSENT,
  STRIPE_PUBLISHABLE_KEY: window.env?.STRIPE_PUBLISHABLE_KEY,
  SMARTLING_ORG_ID: window.env?.SMARTLING_ORG_ID,
  SMARTLING_VISUAL_CONTEXT_CAPTURE_ENABLED: window.env?.SMARTLING_VISUAL_CONTEXT_CAPTURE_ENABLED,
  MAX_FILE_SIZE: window.env?.MAX_FILE_SIZE,
  IOS_TARGET_APP_VERSION: window.env?.IOS_TARGET_APP_VERSION,
  ANDROID_TARGET_APP_VERSION: window.env?.ANDROID_TARGET_APP_VERSION,
  ZENDESK_MESSAGING_WIDGET: window.env?.ZENDESK_MESSAGING_WIDGET,
  AIRWALLEX_ENVIORNMENT: window.env?.AIRWALLEX_ENVIORNMENT,
  AIRWALLEX_DOMAIN: window.env?.AIRWALLEX_DOMAIN
}

export default Settings
