import React, { useEffect, useState } from "react"
import { Box, Stack, Typography, useTheme, Link } from "@carrotfertility/carotene-core"
import { useCurrentUser } from "../context/user/UserContext"
import { Link as RouterLink } from "react-router-dom"
import EmailVerificationSent from "./EmailVerificationSent"
import { useHistory } from "react-router"
import SendVerificationButton from "./SendVerficationButton"
import { AppAccessPageCard } from "components/access-page/AppAccessPageCard"
import { AppAccessPageLayout } from "components/access-page/AppAccessPageLayout"
import { FormattedMessage, useIntl } from "react-intl"
import { PiiText } from "../../services/tracking"

export default function WrongPersonalEmail(): JSX.Element {
  const { personalEmail } = useCurrentUser()
  const theme = useTheme()
  const history = useHistory()
  const [showEmailSent, setShowEmailSent] = useState(false)

  const intl = useIntl()
  const headerText = intl.formatMessage({ defaultMessage: "Your email address could not be verified" })

  useEffect(() => {
    if (!personalEmail) {
      history.push("/no-personal-email")
    }
  }, [personalEmail, history])

  return (
    <AppAccessPageLayout title={headerText}>
      <AppAccessPageCard>
        {showEmailSent ? (
          <EmailVerificationSent personalEmail={personalEmail} />
        ) : (
          <>
            <Box textAlign="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
              <Typography variant="h1">{headerText}</Typography>
            </Box>
            <Stack textAlign="center" spacing={theme.spacing(theme.tokens.spacing.lg)}>
              <Stack textAlign="center" spacing={theme.spacing(theme.tokens.spacing.sm)}>
                <Typography>
                  <FormattedMessage
                    defaultMessage="We'll send a new verification link to <bold>{personalEmail}</bold>."
                    values={{
                      personalEmail: personalEmail,
                      bold: (boldContent) => (
                        <Typography component={PiiText} fontWeight="bold">
                          {boldContent}
                        </Typography>
                      )
                    }}
                  />
                </Typography>
                <Typography>
                  <FormattedMessage
                    defaultMessage="To change this, go to <link>Account settings</link>."
                    values={{
                      link: (linkContent) => (
                        <Link to={"/account#email-addresses"} component={RouterLink}>
                          {linkContent}
                        </Link>
                      )
                    }}
                  />
                </Typography>
              </Stack>
              <Box>
                <SendVerificationButton handleEmailSent={() => setShowEmailSent(true)} />
              </Box>
            </Stack>
          </>
        )}
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}
