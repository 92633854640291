import queryString from "query-string"
import { reportErrorMessage } from "utils/ErrorReporting"
import { validateUrl } from "./Regexes"
import Settings from "./CarrotConfig"

export const redirectToSamlExternalLogin = (externalLoginUrl, entityId, employeeIsAdmin, returnUrl) => {
  const adminUrl = Settings.ABALONE_URL
  const redirect = (returnUrl) =>
    window.location.replace(
      externalLoginUrl +
        "?" +
        queryString.stringify({
          returnUrl,
          entityId
        })
    )

  if (!employeeIsAdmin) {
    // handle the case where a member has attempted to access some path like
    // app.get-carrot.com/reimbursements. Since our app will show the login
    // screen, but keep the path the same, we can simply use window.location
    // and they will be properly redirected after authentication
    redirect(window.location)
    return
  }

  const returnUrlFromQueryParam = returnUrl
  if (!returnUrlFromQueryParam) {
    redirect(adminUrl)
    return
  }

  const returnUrlIsAllowed = validateUrl(returnUrlFromQueryParam)
  if (!returnUrlIsAllowed) {
    reportErrorMessage(`returnUrl provided at login is not allowed: ${returnUrlFromQueryParam}`)
    redirect(adminUrl)
    return
  }

  redirect(returnUrlFromQueryParam)
}
