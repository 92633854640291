import { useMediaQuery, useTheme } from "@carrotfertility/carotene-core"

export function useResponsiveTalkToCarrotComponent(
  smallContent: JSX.Element,
  mediumContent: JSX.Element,
  largeContent: JSX.Element
): JSX.Element {
  const theme = useTheme()
  const isLargeOrGreaterBreakpoint = useMediaQuery(theme.breakpoints.up("lg"))
  const isMediumBreakpoint = useMediaQuery(theme.breakpoints.only("md"))
  return isLargeOrGreaterBreakpoint ? largeContent : isMediumBreakpoint ? mediumContent : smallContent
}
