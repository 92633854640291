import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import {
  hasQuestionBeenSkipped,
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "components/carrot-plans/shared/useQueryRoutingInfo"
import {
  StepLayout,
  ButtonFooterLayout,
  ContinueButton,
  SkipButton,
  RoutingFlowCheckboxGroup,
  useConvertTranslatedLabel,
  RoutingFlowTitle
} from "components/carrot-plans/shared"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"

type FormValues = {
  doAnyApply?: string[]
}

const OPTIONS = [
  {
    label: defineMessage({ defaultMessage: "Over the age of 40" }),
    value: "OVER_40"
  },
  {
    label: defineMessage({ defaultMessage: "Two or more unintended pregnancy losses" }),
    value: "TWO_LOSSES"
  },
  {
    label: defineMessage({ defaultMessage: "Urgent medical need for fertility preservation" }),
    value: "URGENT_MEDICAL_NEED"
  },
  {
    label: defineMessage({ defaultMessage: "Never menstruated" }),
    value: "NEVER_MENSTRUATED"
  },
  {
    label: defineMessage({ defaultMessage: "Immediate family member with history of blood clotting disorders" }),
    value: "FAMILY_BLOOD_CLOTTING"
  }
]
// @ts-expect-error TS7006
function determineNextStep(selectedValues): "SELECTED" | "NONE_SELECTED" {
  return Array.isArray(selectedValues) && selectedValues.length ? "SELECTED" : "NONE_SELECTED"
}

export default function DoAnyOfTheFollowingApplyStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const intl = useIntl()

  const options = useConvertTranslatedLabel(OPTIONS)

  const { send } = useStateMachine(
    (() => {
      if (routingData?.doAnyApply) {
        return determineNextStep(routingData?.doAnyApply)
      }
      if (hasQuestionBeenSkipped("doAnyApply", routingData)) {
        return "NONE_SELECTED"
      }
      return null
    })()
  )

  async function onClickContinue(values: FormValues) {
    await mutateAsync(values, { onSuccess: () => send(determineNextStep(values?.doAnyApply)) })
  }

  async function onSkip() {
    await mutateAsync({ skipFields: ["doAnyApply"] }, { onSuccess: () => send("SKIP") })
  }
  const header = intl.formatMessage({
    defaultMessage: "Do any of the following apply?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="doAnyApply-label">{header}</span>}
        description={intl.formatMessage({ defaultMessage: "Select as many as apply." })}
        formElements={
          <Form
            defaultValues={{
              doAnyApply: routingData?.doAnyApply || []
            }}
            onSubmit={onClickContinue}
          >
            <RoutingFlowCheckboxGroup aria-labelledby="doAnyApply-label" name="doAnyApply" options={options} />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
