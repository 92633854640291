export enum JourneySubTypes {
  DOULA_SERVICES_PRENATAL_AND_BIRTH = "DOULA_SERVICES_PRENATAL_AND_BIRTH",
  DOULA_SERVICES_POSTPARTUM = "DOULA_SERVICES_POSTPARTUM",
  DOULA_SERVICES_PREGNANCY_LOSS = "DOULA_SERVICES_PREGNANCY_LOSS",
  HUMAN_MILK_SHIPPING = "HUMAN_MILK_SHIPPING",
  CHILDBIRTH_CLASSES = "CHILDBIRTH_CLASSES",
  OTHER_PREGNANCY_SERVICES = "OTHER_PREGNANCY_SERVICES",
  MEDICATION = "MEDICATION",
  MEDICAL_APPOINTMENTS = "MEDICAL_APPOINTMENTS",
  IN_PERSON_CARE = "IN_PERSON_CARE",
  ONLINE_CARE = "ONLINE_CARE",
  LABS = "LABS",
  BINDERS_PACKERS_BODY_SHAPING = "BINDERS_PACKERS_BODY_SHAPING",
  TRAVEL_ELIGIBLE_PROVIDER = "TRAVEL_ELIGIBLE_PROVIDER",
  NUTRITION_COUNSELING = "NUTRITION_COUNSELING",
  TEMPERATURE_REGULATION_PRODUCTS = "TEMPERATURE_REGULATION_PRODUCTS"
}

export enum JourneyTypes {
  ADOPTION = "ADOPTION",
  GC = "GC",
  PRESERVATION = "PRESERVATION",
  FERTILITY_CARE = "FERTILITY_CARE",
  PREGNANCY_AND_POSTPARTUM_SERVICES = "PREGNANCY_AND_POSTPARTUM_SERVICES",
  MENOPAUSE = "MENOPAUSE",
  LOW_TESTOSTERONE = "LOW_TESTOSTERONE",
  GENDER_AFFIRMING_CARE = "GENDER_AFFIRMING_CARE",
  PATHFINDING = "PATHFINDING"
}
