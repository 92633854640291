import React from "react"
import { FormattedMessage } from "react-intl"
import { Stack, Typography, useTheme, InternalIcon, Link } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"

export default function ReadAndLearnEligibilityModule(): JSX.Element {
  const theme = useTheme()
  return (
    <>
      <Typography variant="h3" component="h2" marginBottom={theme.spacing(theme.tokens.spacing.xxs)}>
        <FormattedMessage defaultMessage="Read & learn" />
      </Typography>
      <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
        <Typography>
          <FormattedMessage defaultMessage="Explore articles and videos on a wide range of topics." />
        </Typography>

        <Link to="/read" component={RouterLink} endIcon={<InternalIcon fontSize="small" color="primary" />}>
          <FormattedMessage defaultMessage="Start learning" />
        </Link>
      </Stack>
    </>
  )
}
