import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { SetPassword } from "../../../views/register/SetPassword"
import { usePartnerRegistrationFlow } from "../PartnerRegistrationFlow"
import { useGetRegisteringPartner } from "../../hooks/usePartnerAccess"

export function CreatePasswordStep(): JSX.Element {
  const { send } = useStateMachine(null)
  const { guid, setPassword } = usePartnerRegistrationFlow()
  const { registeringUser } = useGetRegisteringPartner(guid)

  function onCompleteSetPassword(password?: string): void {
    setPassword(password)
    send("")
  }

  return (
    <SetPassword
      email={registeringUser.email}
      onBack={() => send("BACK")}
      onComplete={onCompleteSetPassword}
      allowsSaml={false}
    />
  )
}
