import React from "react"
import { SignUpFindAccountPage } from "components/signup-find-account"
import { SignUpFindAccountProvider } from "components/signup-find-account/context/SignUpFindAccountContext"
import { useHistory } from "react-router"
import { useSignUpFlow } from "components/context/signup/SignUpFlowContext"
import { trackMatomoPageView } from "utils/matomo"

export default function SignUpFindAccount(): JSX.Element {
  const history = useHistory()
  const { handleSaveMemberInfoInMemory } = useSignUpFlow()

  // @ts-expect-error TS7006
  function onEmailFound(email): void {
    const signupSuccessHref = `/signup-success?email=${encodeURIComponent(email)}`
    history.push(signupSuccessHref)
    trackMatomoPageView(signupSuccessHref)
  }

  // @ts-expect-error TS7006
  function onEmailNotFound({ email, dateOfBirth, firstName, lastName, externalEmployeeId, parentCompanyId }): void {
    handleSaveMemberInfoInMemory({ email, dateOfBirth, firstName, lastName, externalEmployeeId, parentCompanyId })
    history.push("/signup-find-account-success")
  }

  function onMultipleEmployeesFound(): void {
    history.push("/signup-get-help")
  }

  function onEmployeeAlreadyRegistered(): void {
    history.push("/signup-employee-already-registered")
  }

  // @ts-expect-error TS7006
  function onEmployeeHasSamlAccount(existingEmail): void {
    handleSaveMemberInfoInMemory({ email: existingEmail })
    history.push("/signup-employee-has-saml")
  }

  return (
    <SignUpFindAccountProvider
      {...{
        onEmailFound,
        onEmailNotFound,
        onMultipleEmployeesFound,
        onEmployeeAlreadyRegistered,
        onEmployeeHasSamlAccount
      }}
    >
      <SignUpFindAccountPage />
    </SignUpFindAccountProvider>
  )
}
