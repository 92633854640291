import useCarrotPlan from "components/carrot-plans/hooks/useCarrotPlan"
import useCompanyComplianceConfigLineItemFilter from "components/views/reimbursements/hooks/useGetCompanyComplianceConfigLineItemFilter"
import useBenefit, { useCreateOverride } from "components/dynamic-content/hooks/useGetBenefit"
import { useLegacyBenefitMapper } from "./mappers/useLegacyBenefitMapper"
import useGetLegacyBenefit from "services/member-benefit/hooks/useGetLegacyBenefit"
import ResponseError from "types/responseError"
import useGetUserInfoConditionsSubset from "services/user/hooks/useGetCurrentUserConditionsSubset"
import { useUserInfoMapper } from "./mappers/useUserInfoMapper"
import { useGetAllDocumentStatuses } from "../../../components/medical-records-consent/utils/DocumentStatusApi"
import { useDocumentStatusMapper } from "./mappers/useDocumentStatusMapper"
import { Benefit, ProgramType } from "lib/carrot-api/types/Benefit"
import { useFlags } from "launchdarkly-react-client-sdk"

const ELECTIVE_FERTILITY_LINE_ITEM = "ELECTIVE_FERTILITY"

type DependenciesStatus = {
  isLoading: boolean
  isError: boolean
  error: ResponseError
}

export function useConditionDepsStatus(): DependenciesStatus {
  const benefit = useBenefit()
  const carrotPlan = useCarrotPlan()
  const legacyBenefit = useGetLegacyBenefit()
  const userInfoSubset = useGetUserInfoConditionsSubset()
  const documentStatuses = useGetAllDocumentStatuses()
  return {
    isLoading:
      benefit.isLoading ||
      carrotPlan.isLoading ||
      legacyBenefit.isLoading ||
      userInfoSubset.isLoading ||
      documentStatuses.isLoading,
    isError:
      benefit.isError ||
      carrotPlan.isError ||
      legacyBenefit.isError ||
      userInfoSubset.isError ||
      documentStatuses.isError,
    error: benefit.error || carrotPlan.error || legacyBenefit.error || userInfoSubset.error || documentStatuses.error
  }
}

export function useContentfulConditions(
  allowConditionsOverride = false
): Record<string, { value: boolean; toggle: (condition?: boolean) => void }> {
  const { hasCarrotPlan } = useCarrotPlan()
  const { data } = useBenefit()
  const { data: companyComplianceData } = useCompanyComplianceConfigLineItemFilter()
  const legacyConditions = useLegacyBenefitMapper()

  const userInfoConditions = useUserInfoMapper()
  const documentStatusConditions = useDocumentStatusMapper()
  const hasCarrotPlanCondition = {
    value: hasCarrotPlan,
    toggle: () => {
      return
    }
  }
  const toggleBenefitData = useCreateOverride()

  const deepClone = (oldBenefit: Benefit): Benefit => JSON.parse(JSON.stringify(oldBenefit))

  // TODO: OP-2060 Replace the flag with hardcoded CIBC companyId comparison
  const { showReadAndLearnOverride } = useFlags()

  // Adding a new mapping? Check the runbook for all the usages: https://carrotfertility.atlassian.net/wiki/spaces/HIPPO/pages/2161377601/Contentful+Attribute+Mapping+Runbook
  const mapper = {
    fertility_care_content: {
      value: data?.journeys.fertilityCare.isAppSupportEnabled || (allowConditionsOverride && showReadAndLearnOverride),
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityCare.isAppSupportEnabled = !data.journeys.fertilityCare.isAppSupportEnabled
        toggleBenefitData(overrideData)
      }
    },
    fertility_preservation_content: {
      value:
        data?.journeys.fertilityPreservation.isAppSupportEnabled ||
        (allowConditionsOverride && showReadAndLearnOverride),
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityPreservation.isAppSupportEnabled =
          !data.journeys.fertilityPreservation.isAppSupportEnabled
        toggleBenefitData(overrideData)
      }
    },
    pregnancy: {
      value: data?.journeys.pregnancy.isAppSupportEnabled || (allowConditionsOverride && showReadAndLearnOverride),
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.pregnancy.isAppSupportEnabled = !data.journeys.pregnancy.isAppSupportEnabled
        toggleBenefitData(overrideData)
      }
    },
    parenting: {
      value: data?.journeys.parenting.isAppSupportEnabled || (allowConditionsOverride && showReadAndLearnOverride),
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.parenting.isAppSupportEnabled = !data.journeys.parenting.isAppSupportEnabled
        toggleBenefitData(overrideData)
      }
    },
    menopause: {
      value: data?.journeys.menopause.isAppSupportEnabled || (allowConditionsOverride && showReadAndLearnOverride),
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.menopause.isAppSupportEnabled = !data.journeys.menopause.isAppSupportEnabled
        toggleBenefitData(overrideData)
      }
    },
    menopause_coverage: {
      value: data?.journeys.menopause.hasEligibleExpenses,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.menopause.hasEligibleExpenses = !data.journeys.menopause.hasEligibleExpenses
        toggleBenefitData(overrideData)
      }
    },
    low_t: {
      value:
        data?.journeys.lowTestosterone.isAppSupportEnabled || (allowConditionsOverride && showReadAndLearnOverride),
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.lowTestosterone.isAppSupportEnabled = !data.journeys.lowTestosterone.isAppSupportEnabled
        toggleBenefitData(overrideData)
      }
    },
    low_t_coverage: {
      value: data?.journeys.lowTestosterone.hasEligibleExpenses,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.lowTestosterone.hasEligibleExpenses = !data.journeys.lowTestosterone.hasEligibleExpenses
        toggleBenefitData(overrideData)
      }
    },
    show_adoption_content: {
      value: data?.journeys.adoption.isAppSupportEnabled || (allowConditionsOverride && showReadAndLearnOverride),
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.adoption.isAppSupportEnabled = !data.journeys.adoption.isAppSupportEnabled
        toggleBenefitData(overrideData)
      }
    },
    medically_necessary_preservation_rider: {
      value:
        data?.journeys.fertilityPreservation.eligibleExpensesDetail.medicallyNecessaryPreservationDetails
          .hasInsuranceCoverageForQmes,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityPreservation.eligibleExpensesDetail.medicallyNecessaryPreservationDetails.hasInsuranceCoverageForQmes =
          !data.journeys.fertilityPreservation.eligibleExpensesDetail.medicallyNecessaryPreservationDetails
            .hasInsuranceCoverageForQmes
        toggleBenefitData(overrideData)
      }
    },
    show_gestational_carrier_content: {
      value:
        data?.journeys.gestationalCarrier.isAppSupportEnabled || (allowConditionsOverride && showReadAndLearnOverride),
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.gestationalCarrier.isAppSupportEnabled =
          !data.journeys.gestationalCarrier.isAppSupportEnabled
        toggleBenefitData(overrideData)
      }
    },
    ava: {
      value: data?.journeys.fertilityCare.isAvaEnabled,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityCare.isAvaEnabled = !data.journeys.fertilityCare.isAvaEnabled
        toggleBenefitData(overrideData)
      }
    },
    can_continue_after_rider_exhausted: {
      value:
        data?.journeys.fertilityPreservation.eligibleExpensesDetail.medicallyNecessaryPreservationDetails
          .canUseCarrotFundsAfterInsuranceExhausted,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityPreservation.eligibleExpensesDetail.medicallyNecessaryPreservationDetails.canUseCarrotFundsAfterInsuranceExhausted =
          !data.journeys.fertilityPreservation.eligibleExpensesDetail.medicallyNecessaryPreservationDetails
            .canUseCarrotFundsAfterInsuranceExhausted
        toggleBenefitData(overrideData)
      }
    },
    ivf_requirements: {
      value: data?.journeys.fertilityCare.eligibleExpensesDetail.ivfRequirementsApply,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityCare.eligibleExpensesDetail.ivfRequirementsApply =
          !data.journeys.fertilityCare.eligibleExpensesDetail.ivfRequirementsApply
        toggleBenefitData(overrideData)
      }
    },
    infertility_dx_rider: {
      value:
        data?.journeys.fertilityCare.eligibleExpensesDetail.infertilityEligibleExpensesDetail
          .hasInsuranceCoverageForQmes,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityCare.eligibleExpensesDetail.infertilityEligibleExpensesDetail.hasInsuranceCoverageForQmes =
          !data.journeys.fertilityCare.eligibleExpensesDetail.infertilityEligibleExpensesDetail
            .hasInsuranceCoverageForQmes
        toggleBenefitData(overrideData)
      }
    },
    gestational_carrier_coverage: {
      value: data?.journeys.gestationalCarrier.hasEligibleExpenses,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.gestationalCarrier.hasEligibleExpenses =
          !data.journeys.gestationalCarrier.hasEligibleExpenses
        toggleBenefitData(overrideData)
      }
    },
    elective_art: {
      value: data?.journeys.fertilityCare.eligibleExpensesDetail.isElectiveCareCovered,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityCare.eligibleExpensesDetail.isElectiveCareCovered =
          !data.journeys.fertilityCare.eligibleExpensesDetail.isElectiveCareCovered
        toggleBenefitData(overrideData)
      }
    },
    infertility_diagnosis: {
      value: data?.journeys.fertilityCare.eligibleExpensesDetail.isCoveredWithInfertilityDiagnosis,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityCare.eligibleExpensesDetail.isCoveredWithInfertilityDiagnosis =
          !data.journeys.fertilityCare.eligibleExpensesDetail.isCoveredWithInfertilityDiagnosis
        toggleBenefitData(overrideData)
      }
    },
    medically_necessary_preservation: {
      value: data?.journeys.fertilityPreservation.eligibleExpensesDetail.isMedicallyNecessaryPreservationCovered,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityPreservation.eligibleExpensesDetail.isMedicallyNecessaryPreservationCovered =
          !data.journeys.fertilityPreservation.eligibleExpensesDetail.isMedicallyNecessaryPreservationCovered
        toggleBenefitData(overrideData)
      }
    },
    elective_preservation: {
      value: data?.journeys.fertilityPreservation.eligibleExpensesDetail.isElectivePreservationCovered,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityPreservation.eligibleExpensesDetail.isElectivePreservationCovered =
          !data.journeys.fertilityPreservation.eligibleExpensesDetail.isElectivePreservationCovered
        toggleBenefitData(overrideData)
      }
    },
    adoption_coverage: {
      value: data?.journeys.adoption.hasEligibleExpenses,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.adoption.hasEligibleExpenses = !data.journeys.adoption.hasEligibleExpenses
        toggleBenefitData(overrideData)
      }
    },
    preservation: {
      value: data?.journeys.fertilityPreservation.hasEligibleExpenses,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityPreservation.hasEligibleExpenses =
          !data.journeys.fertilityPreservation.hasEligibleExpenses
        toggleBenefitData(overrideData)
      }
    },
    donor_assistance: {
      value: data?.journeys.fertilityCare.eligibleExpensesDetail.hasDonorAssistance,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityCare.eligibleExpensesDetail.hasDonorAssistance =
          !data.journeys.fertilityCare.eligibleExpensesDetail.hasDonorAssistance
        toggleBenefitData(overrideData)
      }
    },
    pregnancy_coverage: {
      value: data?.journeys.pregnancy.hasEligibleExpenses,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.pregnancy.hasEligibleExpenses = !data.journeys.pregnancy.hasEligibleExpenses
        toggleBenefitData(overrideData)
      }
    },
    doula_expenses_covered: {
      value: data?.journeys.pregnancy.eligibleExpensesDetail.doulaExpensesCovered,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.pregnancy.eligibleExpensesDetail.doulaExpensesCovered =
          !data.journeys.pregnancy.eligibleExpensesDetail.doulaExpensesCovered
        toggleBenefitData(overrideData)
      }
    },
    additional_pregnancy_expenses_covered: {
      value: data?.journeys.pregnancy.eligibleExpensesDetail.additionalPregnancyExpensesCovered,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.pregnancy.eligibleExpensesDetail.additionalPregnancyExpensesCovered =
          !data.journeys.pregnancy.eligibleExpensesDetail.additionalPregnancyExpensesCovered
        toggleBenefitData(overrideData)
      }
    },
    mira: {
      value: data?.journeys.fertilityCare.isMiraEnabled,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityCare.isMiraEnabled = !data.journeys.fertilityCare.isMiraEnabled
        toggleBenefitData(overrideData)
      }
    },
    carrot_rx: {
      value: data?.carrotRx.isCarrotRxActive,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.carrotRx.isCarrotRxActive = !data.carrotRx.isCarrotRxActive
        toggleBenefitData(overrideData)
      }
    },
    carrot_rx_required: {
      value: data?.carrotRx.isCarrotRxRequired,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.carrotRx.isCarrotRxRequired = !data.carrotRx.isCarrotRxRequired
        toggleBenefitData(overrideData)
      }
    },
    fertility_care_rx_covered: {
      value: data?.journeys.fertilityCare.eligibleExpensesDetail.isRxCovered,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityCare.eligibleExpensesDetail.isRxCovered =
          !data.journeys.fertilityCare.eligibleExpensesDetail.isRxCovered
        toggleBenefitData(overrideData)
      }
    },
    fertility_preservation_rx_covered: {
      value: data?.journeys.fertilityPreservation.eligibleExpensesDetail.isRxCovered,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityPreservation.eligibleExpensesDetail.isRxCovered =
          !data.journeys.fertilityPreservation.eligibleExpensesDetail.isRxCovered
        toggleBenefitData(overrideData)
      }
    },
    custom_coverage: {
      value: !!data?.customizations.memberCustomCoverageExplanationMarkdown,
      toggle: () => {
        const overrideData = deepClone(data)
        if (overrideData.customizations.memberCustomCoverageExplanationMarkdown) {
          overrideData.customizations.memberCustomCoverageExplanationMarkdown = null
        } else {
          overrideData.customizations.memberCustomCoverageExplanationMarkdown =
            "This is a test string. If you were expecting to see the original Custom Coverage text, please refresh the page."
        }
        toggleBenefitData(overrideData)
      }
    },
    additional_employer_benefits: {
      value: !!data?.customizations.additionalEmployerBenefitMarkdown,
      toggle: () => {
        const overrideData = deepClone(data)
        if (overrideData.customizations.additionalEmployerBenefitMarkdown) {
          overrideData.customizations.additionalEmployerBenefitMarkdown = null
        } else {
          overrideData.customizations.additionalEmployerBenefitMarkdown =
            "This is a test string. If you were expecting to see the original Additional Employer Benefit text, please refresh the page."
        }
        toggleBenefitData(overrideData)
      }
    },
    health_plan: {
      value: !!data?.customizations.healthPlanMarkdown,
      toggle: () => {
        const overrideData = deepClone(data)
        if (overrideData.customizations.healthPlanMarkdown) {
          overrideData.customizations.healthPlanMarkdown = null
        } else {
          overrideData.customizations.healthPlanMarkdown =
            "This is a test string. If you were expecting to see the original Health Plan text, please refresh the page."
        }
        toggleBenefitData(overrideData)
      }
    },
    provider_finder_hide_cryobanks_storage: {
      value: !data?.providerFinder.showCryobanks,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.providerFinder.showCryobanks = !data.providerFinder.showCryobanks
        toggleBenefitData(overrideData)
      }
    },
    provider_finder_hide_fertility_clinics: {
      value: !data?.providerFinder.showFertilityClinics,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.providerFinder.showFertilityClinics = !data.providerFinder.showFertilityClinics
        toggleBenefitData(overrideData)
      }
    },
    provider_finder_hide_reproductive_urology: {
      value: !data?.providerFinder.showReproductiveUrology,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.providerFinder.showReproductiveUrology = !data.providerFinder.showReproductiveUrology
        toggleBenefitData(overrideData)
      }
    },
    provider_finder_show_gender_affirming_hormone_therapy: {
      value: data?.providerFinder.showGenderAffirmingHormoneTherapy,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.providerFinder.showGenderAffirmingHormoneTherapy =
          !data.providerFinder.showGenderAffirmingHormoneTherapy
        toggleBenefitData(overrideData)
      }
    },
    show_provider_finder: {
      value: data?.providerFinder.showProviderFinder,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.providerFinder.showProviderFinder = !data.providerFinder.showProviderFinder
        toggleBenefitData(overrideData)
      }
    },
    carrot_pro: {
      value: data?.program.type === ProgramType.PRO,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.program.type = data.program.type === ProgramType.PRO ? ProgramType.CORE : ProgramType.PRO
        overrideData.program.isCarrotLite = overrideData.program.type !== ProgramType.PRO
        toggleBenefitData(overrideData)
      }
    },
    carrot_core: {
      value: data?.program.type === ProgramType.CORE,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.program.type = data.program.type === ProgramType.CORE ? ProgramType.PRO : ProgramType.CORE
        overrideData.program.isCarrotLite = overrideData.program.type !== ProgramType.PRO
        toggleBenefitData(overrideData)
      }
    },
    wtw_engage: {
      value: data?.program.type === ProgramType.WTW_ENGAGE,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.program.type =
          data.program.type === ProgramType.WTW_ENGAGE ? ProgramType.PRO : ProgramType.WTW_ENGAGE
        overrideData.program.isCarrotLite = overrideData.program.type !== ProgramType.PRO
        toggleBenefitData(overrideData)
      }
    },
    gender_affirming_care: {
      value:
        data?.journeys.genderAffirmingCare.isAppSupportEnabled || (allowConditionsOverride && showReadAndLearnOverride),
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.genderAffirmingCare.isAppSupportEnabled =
          !data.journeys.genderAffirmingCare.isAppSupportEnabled
        toggleBenefitData(overrideData)
      }
    },
    gender_affirming_care_coverage: {
      value: data?.journeys.genderAffirmingCare.hasEligibleExpenses,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.genderAffirmingCare.hasEligibleExpenses =
          !data.journeys.genderAffirmingCare.hasEligibleExpenses
        toggleBenefitData(overrideData)
      }
    },
    travel_for_reproductive_care: {
      value: data?.journeys.fertilityCare.eligibleExpensesDetail.areTravelCostsForTerminationCovered,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityCare.eligibleExpensesDetail.areTravelCostsForTerminationCovered =
          !data.journeys.fertilityCare.eligibleExpensesDetail.areTravelCostsForTerminationCovered
        toggleBenefitData(overrideData)
      }
    },
    elective_fertility_qme_line_item_filter: {
      value: companyComplianceData === ELECTIVE_FERTILITY_LINE_ITEM,
      // @ts-expect-error TS7011
      toggle: () => null
    },
    fertility_care_coverage: {
      value: data?.journeys.fertilityCare.hasEligibleExpenses,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.journeys.fertilityCare.hasEligibleExpenses = !data.journeys.fertilityCare.hasEligibleExpenses
        toggleBenefitData(overrideData)
      }
    },
    show_donor_content: {
      value: data?.generalSupport.showDonorContent,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.generalSupport.showDonorContent = !data.generalSupport.showDonorContent
        toggleBenefitData(overrideData)
      }
    },
    show_lgbtq_content: {
      value: data?.generalSupport.showLgbtqContent,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.generalSupport.showLgbtqContent = !data.generalSupport.showLgbtqContent
        toggleBenefitData(overrideData)
      }
    },
    show_support_center: {
      value: data?.supportCenter.enabled,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.supportCenter.enabled = !data.supportCenter.enabled
        toggleBenefitData(overrideData)
      }
    },
    provider_finder_show_donor_assistance: {
      value: data?.providerFinder.showDonorAssistance,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.providerFinder.showDonorAssistance = !data.providerFinder.showDonorAssistance
        toggleBenefitData(overrideData)
      }
    },
    provider_finder_show_adoption: {
      value: data?.providerFinder.showAdoption,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.providerFinder.showAdoption = !data.providerFinder.showAdoption
        toggleBenefitData(overrideData)
      }
    },
    provider_finder_show_gestational_surrogacy: {
      value: data?.providerFinder.showGestationalSurrogacy,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.providerFinder.showGestationalSurrogacy = !data.providerFinder.showGestationalSurrogacy
        toggleBenefitData(overrideData)
      }
    },
    provider_finder_show_low_t: {
      value: data?.providerFinder.showLowTestosterone,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.providerFinder.showLowTestosterone = !data.providerFinder.showLowTestosterone
        toggleBenefitData(overrideData)
      }
    },
    provider_finder_show_menopause: {
      value: data?.providerFinder.showMenopause,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.providerFinder.showMenopause = !data.providerFinder.showMenopause
        toggleBenefitData(overrideData)
      }
    },
    provider_finder_show_obgyn_recommendations: {
      value: data?.providerFinder.showObGynRecommendations,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.providerFinder.showObGynRecommendations = !data.providerFinder.showObGynRecommendations
        toggleBenefitData(overrideData)
      }
    },
    provider_finder_show_pediatrician_recommendations: {
      value: data?.providerFinder.showPediatricianRecommendations,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.providerFinder.showPediatricianRecommendations =
          !data.providerFinder.showPediatricianRecommendations
        toggleBenefitData(overrideData)
      }
    }
  }
  return {
    has_carrot_plan: hasCarrotPlanCondition,
    ...legacyConditions,
    ...userInfoConditions,
    ...documentStatusConditions,
    ...mapper
  }
}
