import React, { useEffect, useState } from "react"
import { Redirect, withRouter } from "react-router-dom"
import Helpers from "../../../utils/Helpers"
import { HttpErrors } from "utils/HttpErrors"
import { useSelector } from "react-redux"
import { loginEmail } from "../../../reducers/loginInfo"
import { CarrotErrorCodes } from "utils/CarrotErrors"
import { Link } from "@carrotfertility/carotene-core"
import { carrotClient } from "utils/CarrotClient"
import RequestResetPassword from "pages/request-reset-password"
import { FormattedMessage } from "react-intl"
import { EmployeeSupportUrl } from "../../../utils/EmployeeSupportLink"

const UnknownError = (): JSX.Element => {
  return (
    <>
      <FormattedMessage
        defaultMessage="Oops, something went wrong. Please try again later or <link>contact us</link> for assistance."
        values={{
          link: (linkContent) => (
            <Link color="error" href={EmployeeSupportUrl} target={"_blank"}>
              {linkContent}
            </Link>
          )
        }}
      />
    </>
  )
}

const InactiveUser = (): JSX.Element => {
  return (
    <>
      <FormattedMessage
        defaultMessage="Your account is no longer active. Please <link>contact us</link> for assistance."
        values={{
          link: (linkContent) => (
            <Link color="error" href={EmployeeSupportUrl} target={"_blank"}>
              {linkContent}
            </Link>
          )
        }}
      />
    </>
  )
}

const RequestResetPasswordContainer = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const email = useSelector(loginEmail)

  useEffect(() => {
    const resetPassword = async () => {
      if (!email) {
        return
      }

      try {
        setLoading(true)
        await carrotClient.forgotPassword(email)
        setLoading(false)
      } catch (error) {
        const message = Helpers.getFromMapOrReportAndDefault(
          {
            [HttpErrors.FORBIDDEN]: <InactiveUser />,
            [HttpErrors.NOT_FOUND]: <UnknownError />,
            [HttpErrors.INTERNAL_SERVER_ERROR]: <UnknownError />,
            [HttpErrors.UNAUTHORIZED]: <UnknownError />
          },
          error.name,
          CarrotErrorCodes.INTERNAL_SERVER_ERROR
        )
        setErrorMessage(message)
        setLoading(false)
        return
      }
    }

    resetPassword()
  }, [email])

  return email ? <RequestResetPassword {...{ loading, errorMessage }} /> : <Redirect to="/" />
}

export default withRouter(RequestResetPasswordContainer)
