import colors from "css/variables/_colors.scss"

export const lightFormTheme = {
  name: "lightFormTheme",
  borderColor: colors.mineralGrey,
  disabledBackgroundColor: colors.ghostWhite,
  checkedCheckboxBackgroundColor: colors.carrotOrange,
  checkboxCheckColor: colors.white
}

export const fleFormTheme = {
  name: "fleFormTheme",
  borderColor: "rgba(0, 0, 0, 0.25)",
  disabledBackgroundColor: colors.chardon,
  checkedCheckboxBackgroundColor: colors.white,
  checkboxCheckColor: "#e48400"
}

export const carrotPlanHeroTheme = {
  name: "carrotPlanHeroTheme",
  checkboxBackgroundColor: "transparent",
  checkboxBorderColor: colors.white,
  checkboxCheckColor: colors.darkBlue,
  checkedCheckboxBackgroundColor: colors.white,
  hoverCheckboxBackgroundColor: colors.carrotOrange,
  hoverCheckColor: colors.white,
  hoverBorderColor: colors.carrotOrange
}
