import { defineMessages, useIntl } from "react-intl"

const reimbursementCategoryNameMessages = defineMessages({
  ADOPTION_AND_GC: {
    defaultMessage: "Adoption and gestational surrogacy"
  },
  ADOPTION_AND_GC_EXPENSES: {
    defaultMessage: "Adoption and gestational surrogacy expenses"
  },
  ADOPTION: {
    defaultMessage: "Adoption"
  },
  ADOPTION_EXPENSES: {
    defaultMessage: "Adoption expenses"
  },
  DEFAULT: {
    defaultMessage: "Default"
  },
  FERTILITY: {
    defaultMessage: "Fertility care"
  },
  FERTILITY_AND_PRESERVATION: {
    defaultMessage: "Fertility care and preservation"
  },
  GAC: {
    defaultMessage: "Gender-affirming care"
  },
  GC: {
    defaultMessage: "Gestational surrogacy"
  },
  GC_EXPENSES: {
    defaultMessage: "Gestational surrogacy expenses"
  },
  M_LT: {
    defaultMessage: "Menopause and low testosterone"
  },
  M_LT_GAC: {
    defaultMessage: "Menopause, low testosterone, and gender-affirming care"
  },
  MEDICATION: {
    defaultMessage: "Medications"
  },
  OTHER: {
    defaultMessage: "Other"
  },
  OTHER_EXPENSES: {
    defaultMessage: "Other expenses"
  },
  PREGNANCY: {
    defaultMessage: "Pregnancy and postpartum"
  },
  PREGNANCY_EXPENSES: {
    defaultMessage: "Pregnancy and postpartum expenses"
  },
  PREGNANCY_M_LT: {
    defaultMessage: "Pregnancy and postpartum, menopause, and low testosterone"
  },
  PRESERVATION: {
    defaultMessage: "Fertility preservation"
  },
  QME: {
    defaultMessage: "Qualified medical expenses"
  }
})

export function GetCategoryName(key: string, hasQmeCategory: boolean) {
  const intl = useIntl()
  const keyWithExpenses = key + "_EXPENSES"
  return intl.formatMessage(
    // @ts-expect-error TS7053
    reimbursementCategoryNameMessages[hasQmeCategory ? keyWithExpenses : key] ?? reimbursementCategoryNameMessages[key]
  )
}
