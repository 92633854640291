import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Controller, useFormContext } from "react-hook-form"
import { SendMessageFormContext } from "./SendMessage"
import { ThankYou } from "./ThankYou"
import { TalkToCarrotLayout } from "../TalkToCarrotLayout"
import { CircleImage } from "../../views/atoms/images/CircleImage"
import { Form } from "components/views/atoms/forms/Form"
import { useToggle } from "../../../utils/Hooks"
import { TalkToCarrotChatHeader } from "../TalkToCarrotChatHeader"
import MessageReceivedModal from "../../views/modal/MessageReceivedModal"
import { useCurrentUser } from "../../context/user/UserContext"
import { useIntl, FormattedMessage } from "react-intl"
import {
  Button,
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme
} from "@carrotfertility/carotene-core"
import { SelectOptionType } from "@carrotfertility/carotene"
import { PageLayout } from "features/global-ui/page-layout"

function SendMessageSuccessModal({ successModal = MessageReceivedModal }) {
  const { sent, personalEmail, employeeEmail, partnerEmail, sentFromPartnerEmail, onExit } =
    useContext(SendMessageFormContext)
  const [showMessageReceivedModal, toggleShowMessageReceivedModal] = useToggle(true)
  const showModal = sent && showMessageReceivedModal
  const SuccessModal = successModal

  if (!showModal) {
    return null
  }

  return (
    <SuccessModal
      onExit={() => {
        onExit && onExit()
        toggleShowMessageReceivedModal()
      }}
      personalEmail={personalEmail}
      employeeEmail={employeeEmail}
      partnerEmail={partnerEmail}
      sentFromPartnerEmail={sentFromPartnerEmail}
    />
  )
}

// @ts-expect-error TS7031
const SendMessageForm = ({ source, successModal }) => {
  const { sent, subtitle, description } = useContext(SendMessageFormContext)
  const isFullForm = !source

  if (isFullForm) {
    return (
      <>
        {sent ? (
          <PageLayout variant="sidebar" sidebar={<></>} header={<TalkToCarrotChatHeader />}>
            <ThankYou />
          </PageLayout>
        ) : (
          <TalkToCarrotLayout subtitle={subtitle} description={description}>
            <SendMessageFormBody isFullForm={isFullForm} />
          </TalkToCarrotLayout>
        )}
      </>
    )
  } else {
    return (
      <>
        <SendMessageFormBody isFullForm={isFullForm} />
        <SendMessageSuccessModal successModal={successModal} />
      </>
    )
  }
}

// @ts-expect-error TS7031
const SendMessageFormBody = ({ isFullForm }) => {
  const { onSubmit } = useContext(SendMessageFormContext)
  const { tokens, palette } = useTheme()

  return (
    <>
      {isFullForm && (
        <Box>
          <CircleImage iconName={"icn-send-message"} backgroundColor="icon-grey" />
          <Typography
            variant="h4"
            color={palette.text.secondary}
            marginTop={tokens.spacing.lg}
            marginBottom={tokens.spacing.lg}
          >
            <FormattedMessage defaultMessage="Send us a message" />
          </Typography>
        </Box>
      )}
      <Form onSubmit={onSubmit}>
        <Stack spacing={tokens.spacing.lg}>
          <SendMessageEmailInput />
          <MessageTextArea />
          <Stack direction="row-reverse">
            <ButtonFooter />
          </Stack>
          {isFullForm && <Divider />}
        </Stack>
      </Form>
    </>
  )
}

const UpdateContactInfoLink = () => {
  return (
    <Link color="primary" href="/account#email-addresses" marginTop={(theme) => theme.tokens.spacing.none}>
      <FormattedMessage defaultMessage="Update your contact info" />
    </Link>
  )
}

const VerifyOtherEmailMessage = ({
  hasUnverifiedPersonalEmail,
  helperTextId
}: {
  hasUnverifiedPersonalEmail: boolean
  helperTextId: string
}): JSX.Element => {
  const { personalEmail } = useCurrentUser()
  if (!hasUnverifiedPersonalEmail) {
    return null
  }
  return (
    <FormHelperText id={helperTextId}>
      <FormattedMessage
        defaultMessage='To use {personalEmail}, click "update your contact info" to go to your account settings and verify it.'
        values={{ personalEmail: personalEmail }}
      />
    </FormHelperText>
  )
}

// @ts-expect-error TS7031
const EmailInput = ({ showUpdateContactInfo, hasUnverifiedPersonalEmail }) => {
  const {
    formState: { errors },
    control
  } = useFormContext()
  const { partnerEmail, options } = useContext(SendMessageFormContext)
  const defaultEmail = partnerEmail ? null : options[0].id
  const name = "email"
  const inputId = name + "-input"
  const labelId = inputId + "-label"
  const helperTextId = name + "-helper-text"

  return (
    <Box>
      <FormControl error={Boolean(errors[name])} fullWidth>
        <InputLabel id={labelId} required>
          <FormattedMessage defaultMessage="Your Email" />
        </InputLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultEmail}
          render={({ field }) => (
            <TextField id={inputId} disabled value={defaultEmail} {...field} onChange={(evt) => field.onChange(evt)} />
          )}
        />
        <VerifyOtherEmailMessage hasUnverifiedPersonalEmail={hasUnverifiedPersonalEmail} helperTextId={helperTextId} />
      </FormControl>
      {showUpdateContactInfo && (
        <Stack direction="row-reverse" marginTop={(theme) => theme.tokens.spacing.xxs}>
          <UpdateContactInfoLink />
        </Stack>
      )}
    </Box>
  )
}

// @ts-expect-error TS7031
const EmailDropdown = ({ showUpdateContactInfo, hasUnverifiedPersonalEmail }) => {
  const { options } = useContext(SendMessageFormContext)
  const intl = useIntl()
  const {
    formState: { errors },
    control
  } = useFormContext()
  const { tokens, palette } = useTheme()
  const name = "email"
  const selectId = name + "-select"
  const labelId = selectId + "-label"
  const helperTextId = name + "-helper-text"

  const placeholder = (
    <Typography variant="body1" color={palette.text.disabled}>
      <FormattedMessage defaultMessage="Select email" />
    </Typography>
  )

  const optionsMap = options.reduce((acc, cur) => {
    // @ts-expect-error TS7053
    acc[cur.value] = (
      <Stack direction="row" spacing={tokens.spacing.xxs}>
        <Typography variant="body1" color={palette.text.primary}>
          {cur.label}
        </Typography>
        <Typography variant="body1" color={palette.text.primary}>
          {cur.subLabel}
        </Typography>
      </Stack>
    )
    return acc
  }, {})

  return (
    <Box>
      <FormControl error={Boolean(errors[name])} fullWidth>
        <InputLabel id={labelId} required>
          <FormattedMessage defaultMessage="Your Email" />
        </InputLabel>
        <Controller
          rules={{ required: intl.formatMessage({ defaultMessage: "Please select an email" }) }}
          control={control}
          name={name}
          render={({ field }) => (
            <Select
              id={selectId}
              labelId={labelId}
              aria-describedby={helperTextId}
              required
              displayEmpty
              {...field}
              onChange={(evt) => field.onChange(evt)}
              // @ts-expect-error TS7053
              renderValue={(value) => (value ? optionsMap[value] : placeholder)}
            >
              {options.map((option: SelectOptionType) => (
                <MenuItem key={option.value} value={option.value}>
                  {/*
                   // @ts-expect-error TS7053 */}
                  {optionsMap[option.value]}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <VerifyOtherEmailMessage hasUnverifiedPersonalEmail={hasUnverifiedPersonalEmail} helperTextId={helperTextId} />
      </FormControl>
      {showUpdateContactInfo && (
        <Stack direction="row-reverse" marginTop={(theme) => theme.tokens.spacing.xxs}>
          <UpdateContactInfoLink />
        </Stack>
      )}
    </Box>
  )
}

const SendMessageEmailInput = ({ showUpdateContactInfo = true }) => {
  const { partnerEmail } = useContext(SendMessageFormContext)
  const { personalEmail, preferredEmail } = useCurrentUser()
  const hasUnverifiedPersonalEmail = personalEmail != null && personalEmail !== preferredEmail

  if (partnerEmail) {
    return (
      <EmailDropdown
        showUpdateContactInfo={showUpdateContactInfo}
        hasUnverifiedPersonalEmail={hasUnverifiedPersonalEmail}
      />
    )
  }

  return (
    <EmailInput showUpdateContactInfo={showUpdateContactInfo} hasUnverifiedPersonalEmail={hasUnverifiedPersonalEmail} />
  )
}

const MessageTextArea = ({ showPlaceholderText = true, rows = "8" }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()
  const intl = useIntl()
  const name = "message"
  const inputId = name + "-select"
  const labelId = inputId + "-label"
  const placeholderText = showPlaceholderText
    ? intl.formatMessage({ defaultMessage: "Type your message here..." })
    : null

  return (
    <FormControl error={Boolean(errors[name])} fullWidth>
      <InputLabel id={labelId} required>
        <FormattedMessage defaultMessage="Message" />
      </InputLabel>
      <Controller
        name={name}
        control={control}
        rules={{
          required: intl.formatMessage({ defaultMessage: "Please enter a message" }),
          validate: (value) => {
            if (value.trim()) {
              return true
            }
            return !!value && intl.formatMessage({ defaultMessage: "Please enter a message" })
          }
        }}
        render={({ field }) => (
          <TextField
            id={inputId}
            required
            multiline
            rows={rows}
            helperText={errors[name] && errors[name].message.toString()}
            placeholder={placeholderText}
            {...field}
            onChange={(evt) => field.onChange(evt)}
          />
        )}
      />
    </FormControl>
  )
}

MessageTextArea.propTypes = {
  showPlaceholderText: PropTypes.bool,
  rows: PropTypes.string
}

const ButtonFooter = () => {
  const { onSubmit, onClickButton } = useContext(SendMessageFormContext)
  const {
    formState: { isValid, isSubmitting }
  } = useFormContext()
  const intl = useIntl()

  return (
    <Button
      onClick={() => (onClickButton ? onClickButton(onSubmit) : onSubmit())}
      disabled={!isValid || isSubmitting}
      size="medium"
      color="primary"
      data-testid="talk-to-carrot-submit"
    >
      {isSubmitting
        ? intl.formatMessage({ defaultMessage: "Sending..." })
        : intl.formatMessage({ defaultMessage: "Send" })}
    </Button>
  )
}

export { SendMessageForm, SendMessageEmailInput, MessageTextArea }
