import React, { useEffect } from "react"
import TwoColumnRightPrimary from "components/views/atoms/layouts/TwoColumnRightPrimary"
import { useHistory } from "react-router"
import { TalkToCarrotLegal } from "./TalkToCarrotLegal"
import { SendMessage } from "./send-message/SendMessage"
import { Container, H2, H5 } from "@carrotfertility/carotene"
import { BodySmall } from "../views/atoms/Bodies"
import { ArrowLink } from "../views/atoms/links/ArrowLink"
import { talkToCarrotSource } from "./send-message/SendMessageUtil"
import { useCurrentUser } from "../context/user/UserContext"
import { Spacer } from "../views/atoms/Atoms"
import { LegacyZendeskArrowLink } from "../views/atoms/links/LegacyZendeskArrowLink"
import { FormattedMessage } from "react-intl"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header"
import { Breadcrumbs, Breadcrumb } from "@carrotfertility/carotene-core"

function lockedDownGetHelpContent() {
  return (
    <>
      <Container stack="medium">
        <H2 stack="tiny">
          <FormattedMessage defaultMessage="Send a message" />
        </H2>
        <BodySmall color="black-60">
          <FormattedMessage defaultMessage="If you're having trouble with your account or otherwise need to reach a human, send us a message." />
        </BodySmall>
      </Container>
      <Container>
        <H5 stack="tiny">
          <FormattedMessage defaultMessage="Or you might like to..." />
        </H5>
        <ArrowLink to="/your-benefit-guide/about-your-benefit" color="black-60">
          <FormattedMessage defaultMessage="Learn about your benefit" />
        </ArrowLink>
        <Spacer height={0.25} />
        <LegacyZendeskArrowLink color="black-60">
          <FormattedMessage defaultMessage="Get answers to frequently asked questions" />
        </LegacyZendeskArrowLink>

        <Spacer height={0.25} />
        <ArrowLink to="/carrot-plans" color="black-60">
          <FormattedMessage defaultMessage="Start using Carrot" />
        </ArrowLink>
      </Container>
    </>
  )
}

function LockedDownGetHelpPage() {
  const history = useHistory()
  const { isInAppLockdown } = useCurrentUser()

  useEffect(() => {
    if (!isInAppLockdown) {
      history.push("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [isInAppLockdown])

  return (
    <PageLayout
      footer={<TalkToCarrotLegal />}
      header={
        <PageBreadcrumbHeader
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumb currentPage>
                <FormattedMessage defaultMessage="Send a message" />
              </Breadcrumb>
            </Breadcrumbs>
          }
        />
      }
    >
      <TwoColumnRightPrimary
        secondaryContent={lockedDownGetHelpContent()}
        primaryContent={<SendMessage source={talkToCarrotSource.LOCKED_DOWN_GET_HELP} />}
      />
    </PageLayout>
  )
}

export { LockedDownGetHelpPage }
