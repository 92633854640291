import { Steps } from "../../steps"

// prettier-ignore
export default [
  { forStep: Steps.MEMBER_INFO_CONNECT_INSURANCE,                      memberSelects: "",                             goto: Steps.MEMBER_HEALTH_PLAN_CONNECT },
  { forStep: Steps.MEMBER_INFO_CONNECT_INSURANCE,                      memberSelects: 'skip',                         goto: Steps.SKIP_MEMBER_CONNECT_INSURANCE},
  { forStep: Steps.MEMBER_HEALTH_PLAN_CONNECT,                         memberSelects: "PROVIDER",                     goto: Steps.MEMBER_DOB_SEX_CONNECT },
  { forStep: Steps.MEMBER_HEALTH_PLAN_CONNECT,                         memberSelects: "OTHER",                        goto: Steps.MEMBER_HEALTH_PLAN_NOT_LISTED },
  { forStep: Steps.MEMBER_HEALTH_PLAN_NOT_LISTED,                      memberSelects: "",                             goto: Steps.FINAL},
  { forStep: Steps.MEMBER_DOB_SEX_CONNECT,                             memberSelects: "",                             goto: Steps.MEMBER_PROCESS_CONNECT_INSURANCE },
  { forStep: Steps.MEMBER_PROCESS_CONNECT_INSURANCE,                   memberSelects: "true",                         goto: Steps.MEMBER_PROCESS_CONNECT_INSURANCE_SUCCESS },
  { forStep: Steps.MEMBER_PROCESS_CONNECT_INSURANCE,                   memberSelects: "false",                        goto: Steps.MEMBER_PROCESS_CONNECT_INSURANCE_FAIL },
  { forStep: Steps.MEMBER_PROCESS_CONNECT_INSURANCE,                   memberSelects: "KNOWN_OUTAGE",                 goto: Steps.MEMBER_PROCESS_CONNECT_INSURANCE_KNOWN_OUTAGE },
  { forStep: Steps.MEMBER_PROCESS_CONNECT_INSURANCE_KNOWN_OUTAGE,      memberSelects: "",                             goto: Steps.FINAL },
  { forStep: Steps.MEMBER_PROCESS_CONNECT_INSURANCE_SUCCESS,           memberSelects: "",                             goto: Steps.FINAL },
  { forStep: Steps.MEMBER_PROCESS_CONNECT_INSURANCE_FAIL,              memberSelects: '',                             goto: Steps.FINAL},
  { forStep: Steps.SKIP_MEMBER_CONNECT_INSURANCE,                      memberSelects: '',                             goto: Steps.FINAL},
]
