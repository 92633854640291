import React from "react"
import { dayjs } from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"

import { FormDatePicker } from "@carrotfertility/carotene-core-x"
import { validateOptionalDate } from "services/common-forms"

export function DoulaAttestationFormDatePicker() {
  const intl = useIntl()

  return (
    <FormDatePicker
      name="dueDate"
      label={intl.formatMessage({ defaultMessage: "Due date or Delivery Date" })}
      controllerProps={{
        rules: {
          validate: (value: dayjs.Dayjs) => {
            const result = validateOptionalDate(value)
            if (typeof result !== "boolean") {
              return intl.formatMessage(result)
            }
            return result
          },
          required: "Required"
        }
      }}
    />
  )
}
