import React from "react"
import { useBooleanSearchParam, ProviderFinderParam } from "../../utils/providerFinderSearchUrl"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText } from "@carrotfertility/carotene-core"

export function VirtualServicesFilter(): JSX.Element {
  const [isVirtualSearchParam, setIsVirtualSearchParam] = useBooleanSearchParam(ProviderFinderParam.IsVirtual)
  const intl = useIntl()
  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={(theme) => theme.spacing(theme.tokens.spacing.sm)}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.tokens.borderRadius.sm
      })}
    >
      <Box
        data-id="virtual-providers-filter"
        display="flex"
        flexDirection="column"
        gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
      >
        <FormControl>
          <FormControlLabel
            label={
              <Box
                component="span"
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
              >
                <FormattedMessage defaultMessage="Virtual services available" />
              </Box>
            }
            aria-label={intl.formatMessage({ defaultMessage: "Select providers who offer virtual services" })}
            control={
              <Checkbox
                name="isVirtual"
                checked={isVirtualSearchParam}
                onChange={(event) => setIsVirtualSearchParam(event.target.checked)}
              />
            }
          />
          <FormHelperText>
            <FormattedMessage defaultMessage="Availability of virtual services may vary based on provider license and your location" />
          </FormHelperText>
        </FormControl>
      </Box>
    </Box>
  )
}
