import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import {
  hasQuestionBeenSkipped,
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "components/carrot-plans/shared/useQueryRoutingInfo"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  SkipButton,
  StepLayout,
  useConvertTranslatedLabel
} from "components/carrot-plans/shared"
import { FormRadioButtonGroup } from "services/common-forms"
import { Stack, Typography, useTheme } from "@carrotfertility/carotene-core"

type FormValues = {
  pocPrefSameBackground?: string
}

const OPTIONS = [
  { label: defineMessage({ defaultMessage: "Yes" }), value: "YES" },
  { label: defineMessage({ defaultMessage: "No" }), value: "NO" }
]

export default function PersonOfColorShareBackgroundStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.pocPrefSameBackground
  const radioOptions = useConvertTranslatedLabel(OPTIONS)
  const { send } = useStateMachine(
    defaultValue || hasQuestionBeenSkipped("pocPrefSameBackground", routingData) ? "" : null
  )
  const intl = useIntl()
  const theme = useTheme()

  async function onClickContinue(formValues: FormValues): Promise<void> {
    if (formValues?.pocPrefSameBackground) {
      await mutateAsync(formValues, { onSuccess: () => send("") })
    } else {
      await onSkip()
    }
  }

  async function onSkip(): Promise<void> {
    await mutateAsync({ skipFields: ["pocPrefSameBackground"] }, { onSuccess: () => send("") })
  }

  const header = intl.formatMessage({
    defaultMessage:
      "If you may be choosing a local provider, would you like help finding someone who shares your background?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="pocPrefSameBackground-label">{header}</span>}
        description={
          <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
            <Typography>
              <FormattedMessage defaultMessage="We know how important BIPOC (Black, Indigenous, and people of color) providers are for overcoming health disparities, and we're eager to help you find someone who shares your background." />
            </Typography>
            <Typography>
              <FormattedMessage
                defaultMessage="This may not be available in all locations or situations, but we're committed to helping you find a provider who will make you feel welcome and empowered."
                description="'This' here refers to Carrot providers who are Black, Indigenous, and people of color"
              />
            </Typography>
          </Stack>
        }
        formElements={
          <Form defaultValues={{ pocPrefSameBackground: defaultValue }} onSubmit={onClickContinue}>
            <FormRadioButtonGroup
              name="pocPrefSameBackground"
              aria-labelledby="pocPrefSameBackground-label"
              options={radioOptions}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
