import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useIntl } from "react-intl"
import { UpdateRoutingFlowModal } from "../../shared/UpdateRoutingFlowModal"
import { useStartRouting } from "../../shared/useQueryStartRouting"
import { useStartRoutingInfo } from "../../shared/useQueryRoutingInfo"
import { caseRateRenewalRoutingFlowUrl } from "../workflow"

export function UpdateCarrotPlanDetailsStep(): JSX.Element {
  const intl = useIntl()
  const { back } = useStateMachine(null)
  const { startRouting } = useStartRouting(useStartRoutingInfo(), caseRateRenewalRoutingFlowUrl)
  const header = intl.formatMessage({ defaultMessage: "We have a few questions..." })

  return (
    <>
      <UpdateRoutingFlowModal header={header} clickGoBack={back} clickUpdateMyAnswers={startRouting} />
    </>
  )
}
