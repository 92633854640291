import { Typography } from "@carrotfertility/carotene-core"
import React from "react"

type Option = { label: string; value: string }

export function renderLabelWithPlaceholder(options: Option[], placeholder: string) {
  // eslint-disable-next-line react/display-name
  return (value: any): React.ReactNode => {
    const option = options.find((option) => option.value === value)
    return option ? (
      option.label
    ) : (
      <Typography component="span" color={(theme) => theme.palette.placeholder}>
        {placeholder}
      </Typography>
    )
  }
}
