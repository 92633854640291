import React from "react"
import { Section } from "../utils/utils"
import { GuideContentMarkdown } from "./GuideContentMarkdown"
import { Frame } from "./Frame"

const GuideFrame = ({
  section,
  pageIndex,
  menu
}: {
  section: Section
  pageIndex: number
  menu: JSX.Element
}): JSX.Element => {
  return (
    <Frame topGreyHeaderText={section?.title} menu={menu}>
      <GuideContentMarkdown section={section} pageIndex={pageIndex} />
    </Frame>
  )
}

export { GuideFrame }
