import { FormCheckbox, FormDatePicker, FormTextField, useWatch } from "@carrotfertility/carotene-core-x"
import React from "react"
import { useIntl } from "react-intl"
import { PhoneNumberAndCountryCode } from "./PhoneNumberAndCountryCode"
import { PreferredContactMethod } from "./PreferredContactMethod"

export function PartnerInformation(): JSX.Element {
  const intl = useIntl()
  const isBringingPartner = useWatch({ name: "isBringingPartner" })

  return (
    <>
      <FormCheckbox
        label={intl.formatMessage({ defaultMessage: "I will be bringing a partner" })}
        name="isBringingPartner"
      />
      {isBringingPartner && (
        <>
          <FormTextField
            registerOptions={{
              required: intl.formatMessage({ defaultMessage: "Required" })
            }}
            label={intl.formatMessage({ defaultMessage: "Partner's name" })}
            name="partnerName"
          />
          <FormDatePicker
            label={intl.formatMessage({ defaultMessage: "Partner's date of birth" })}
            name={"partnerDateOfBirth"}
          />
          <FormTextField
            name="partnerEmail"
            label={intl.formatMessage({ defaultMessage: "Email address" })}
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
          />
          <PhoneNumberAndCountryCode phoneCountryCode={"partnerPhoneCountryCode"} phoneNumber={"partnerPhoneNumber"} />
          <PreferredContactMethod name="partnerPreferredContactMethod" />
        </>
      )}
    </>
  )
}
