import React from "react"
import { FormattedMessage } from "react-intl"
import { Box, Typography, Button } from "@carrotfertility/carotene-core"

type BenefitsEnrollmentExpiredProps = {
  onClickEnroll: () => void
}
const BenefitsEnrollmentExpired = ({ onClickEnroll }: BenefitsEnrollmentExpiredProps): JSX.Element => {
  return (
    <Box
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      bgcolor={(theme) => theme.palette.success.light}
      paddingX={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      paddingY={(theme) => theme.spacing(theme.tokens.spacing.lg)}
    >
      <Typography variant="h5" component="h3" paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
        <FormattedMessage defaultMessage="To access your funds, update your insurance info" />
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      >
        <FormattedMessage defaultMessage="Your insurance plan information has expired. Update now to get access to your Carrot funds." />
      </Typography>
      <Button variant="outlined" color="secondary" onClick={onClickEnroll}>
        <FormattedMessage defaultMessage="Update plan info" />
      </Button>
    </Box>
  )
}
export default BenefitsEnrollmentExpired
