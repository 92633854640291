import { useStateMachine } from "components/context/stateMachine/StateMachineContext"
import { FlexRowCenter } from "components/views/atoms/Atoms"
import { ButtonPrimary } from "components/views/atoms/buttons/ButtonPrimary"
import { IlloUnderwaterPlants } from "components/views/atoms/Illustrations"
import { Link, Typography, Box } from "@carrotfertility/carotene-core"
import React, { ComponentType } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { createErrorBoundary } from "utils/createErrorBoundary"
import { useEnrollmentFlowModal } from "../EnrollmentModal"
import useSubmitWithErrorCatch from "../hooks/useSubmitWithErrorCatch"
import { BenefitEnrollmentFlowModalBackButton } from "../shared"
import { Steps } from "../workflow/steps"
import { getHelpPath } from "../../talk-to-carrot/util/talkToCarrotMolecules"
import { Paths } from "../../../utils/Paths"
import { FormattedMessage } from "react-intl"

const ViewName = Steps.UNKNOWN_ERROR

export const EnrollmentErrorBoundary = createErrorBoundary(UnhandledEnrollmentErrorView as ComponentType)

// @ts-expect-error TS7031
export default function UnhandledEnrollmentErrorView({ unsetError }): JSX.Element {
  const { onExit } = useEnrollmentFlowModal()
  const { onSubmit } = useSubmitWithErrorCatch(() => {
    unsetError()
  })
  useStateMachine(ViewName, null)
  const helpPath = `${getHelpPath({ baseUrl: Paths.TALK_TO_CARROT })}/?enrollmentFlowError=enrollmentFlowUnknownError`

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div onClick={onSubmit}>
        <BenefitEnrollmentFlowModalBackButton />
      </div>
      <Box height={(theme) => theme.tokens.spacing.lg} />
      <FlexRowCenter>
        <IlloUnderwaterPlants />
      </FlexRowCenter>
      <Box height={(theme) => theme.tokens.spacing.xl} />
      <div className="flex flex-row justify-center">
        <Typography id="step-heading" variant="h2" color={(theme) => theme.palette.text.primary}>
          <FormattedMessage defaultMessage="An unknown error occurred." />
        </Typography>
      </div>
      <Typography
        color={(theme) => theme.palette.text.secondary}
        paddingTop={(theme) => theme.tokens.spacing.md}
        paddingBottom={(theme) => theme.tokens.spacing.xxxl}
      >
        <FormattedMessage
          defaultMessage="We're not sure what happened. Please go back and try again. If that doesn't 
        work, contact the <link>Care Team for more support.</link>"
          values={{
            link: (linkContent) => (
              <Link color={"inherit"} component={ReactRouterLink} to={helpPath}>
                {linkContent}
              </Link>
            )
          }}
        />
      </Typography>
      <div className="flex flex-row justify-center">
        <ButtonPrimary onClick={onExit} enabled disabledBackgroundColor="black-10">
          <FormattedMessage defaultMessage="Close" />
        </ButtonPrimary>
      </div>
    </>
  )
}
