import React from "react"
import { StateMachineStepView, StateMachineProvider } from "components/context/stateMachine/StateMachineV2"
import { reimbursementSection } from "./workflow/step-library"
import { ReimbursementProvider } from "components/context/reimbursement/GetReimbursedContext"
import EmployeeOnlyModalContainer from "../employee-only-containers/EmployeeOnlyModalContainer"
import {
  Box,
  CloseIcon,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme
} from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"

// @ts-expect-error TS7031
const GetReimbursed = ({ expenseId = null, transDate = null, transAmount = null, isOpen, onExit }): JSX.Element => {
  const intl = useIntl()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <StateMachineProvider
      initialSection="ReimbursementSection"
      stateMachineDescription={[reimbursementSection]}
      onComplete={() => {}}
    >
      <ReimbursementProvider expenseId={expenseId} transDate={transDate} transAmount={transAmount} onExit={onExit}>
        <EmployeeOnlyModalContainer onClose={onExit}>
          <Dialog aria-labelledby="get-reimbursed" open={isOpen} onClose={onExit} maxWidth="md" fullScreen={fullScreen}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={onExit}>
                <CloseIcon />
              </IconButton>
            </Box>
            <DialogContent sx={{ gap: 0 }}>
              <StateMachineStepView />
            </DialogContent>
          </Dialog>
        </EmployeeOnlyModalContainer>
      </ReimbursementProvider>
    </StateMachineProvider>
  )
}

export { GetReimbursed }
