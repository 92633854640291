import InboundMemberPaymentsClient from "../lib/InboundMemberPaymentsClient"
import { useMutation, UseMutationResult } from "@tanstack/react-query"
import StripeResponseError from "../types/StripeResponseError"
import { GeneratePaymentIntentResponse } from "../types/GeneratePaymentIntentResponse"
import { PaymentIntentArgs } from "../types/PaymentIntentArgs"

const client = new InboundMemberPaymentsClient()

export default function useGeneratePaymentIntent(): UseMutationResult<
  GeneratePaymentIntentResponse,
  StripeResponseError,
  PaymentIntentArgs
> {
  const mutationKey = "generatePaymentIntentMutation"

  return useMutation<GeneratePaymentIntentResponse, StripeResponseError, PaymentIntentArgs>([mutationKey], (args) => {
    return client.generatePaymentIntent(args)
  })
}
