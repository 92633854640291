import { carrotClient } from "../../utils/CarrotClient"
import { useCurrentUser } from "../context/user/UserContext"
import { useQuery, useQueryClient } from "@tanstack/react-query"

export enum VerifyPersonalEmailResponse {
  SUCCESS = "SUCCESS",
  ALREADY_VERIFIED = "ALREADY_VERIFIED",
  EXPIRED = "EXPIRED",
  ERROR = "ERROR"
}

export const EMAIL_VERIFICATION_CACHE_KEY = "getEmailVerification"

export default function useEmailVerification() {
  const { refreshUserInfo, email, personalEmail } = useCurrentUser()
  const queryClient = useQueryClient()
  const { isLoading, data } = useQuery([EMAIL_VERIFICATION_CACHE_KEY], () => carrotClient.getPersonalEmailVerified(), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false
  })

  // @ts-expect-error TS7006
  async function verifyPersonalEmail(token) {
    try {
      const response = await carrotClient.verifyPersonalEmail(token)
      await refreshUserInfo()
      await queryClient.invalidateQueries([EMAIL_VERIFICATION_CACHE_KEY])
      return response
    } catch (error) {
      return await error.response.json()
    }
  }

  async function removePersonalEmail() {
    await carrotClient.removePersonalEmail()
    await refreshUserInfo()
  }

  // @ts-expect-error TS7006
  async function updatePersonalEmail(email) {
    await carrotClient.updatePersonalEmailAndVerification(email)
    await refreshUserInfo()
  }

  async function sendVerificationEmail() {
    await carrotClient.sendPersonalEmailVerificationEmail()
  }

  return {
    verifyPersonalEmail,
    removePersonalEmail,
    updatePersonalEmail,
    sendVerificationEmail,
    data,
    isLoading,
    email,
    personalEmail
  }
}
