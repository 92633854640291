import React from "react"
import { useIntl } from "react-intl"
import { Link, Typography } from "@carrotfertility/carotene-core"

export function CarrotRxExtendSupportMailToHref(): string {
  const intl = useIntl()
  const recipient = "ContactSupport@get-carrot.com"
  const subject = intl.formatMessage({ defaultMessage: "Carrot Rx support request" })
  const body = intl.formatMessage({
    defaultMessage:
      "Please provide your first name, last name, date of birth, and ZIP code in this email. We look forward to supporting you!"
  })

  return `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
}

interface CarrotRxExtendSupportEmailLinkProps {
  children: React.ReactNode
}

export function CarrotRxExtendSupportEmailLink({ children }: CarrotRxExtendSupportEmailLinkProps): JSX.Element {
  const mailtoLink = CarrotRxExtendSupportMailToHref()

  return (
    <Link color={"inherit"} underline={"hover"} href={mailtoLink}>
      <Typography component="span" fontWeight="bold" fontSize={"inherit"}>
        {children}
      </Typography>
    </Link>
  )
}
