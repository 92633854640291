import { Link } from "@carrotfertility/carotene-core"
import React, { useState } from "react"
import { ContentfulDialog } from "../shared/contentful-modal"
import { ModalContentContext } from "../shared/modal-content-context"

type ModalControlType = {
  isOpen: boolean
}

type LinkModelContextType = {
  addModal: ({ modalId, isOpen }: { modalId: string; isOpen?: boolean }) => void
  openModal: (modalId: string) => void
  closeModal: (modalId: string) => void
  isModalOpen: (modalId: string) => boolean
  isModalRegistered: (modalId: string) => boolean
}

const LinkModalContext = React.createContext(null)
export const useLinkModalContext = (): LinkModelContextType => React.useContext(LinkModalContext)

export function LinkModalContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [modals, setModals] = useState<{ [key: string]: ModalControlType }>({})
  const addModal = ({ modalId, isOpen = false }: { modalId: string; isOpen?: boolean }): void => {
    const newModals = {
      [modalId]: { isOpen }
    }
    const updatedModals = { ...modals, ...newModals }
    setModals(updatedModals)
  }
  const openModal = (modalId: string): void => {
    setModals({ ...modals, ...{ [modalId]: { isOpen: true } } })
  }
  const closeModal = (modalId: string): void => {
    setModals({ ...modals, ...{ [modalId]: { isOpen: false } } })
  }
  const isModalOpen = (modalId: string): boolean => modals[modalId].isOpen

  const isModalRegistered = (modalId: string): boolean => !!modals[modalId]
  return (
    <LinkModalContext.Provider
      value={{
        addModal,
        openModal,
        closeModal,
        isModalOpen,
        isModalRegistered
      }}
    >
      {children}
    </LinkModalContext.Provider>
  )
}

export const LinkToModal = ({ toModalId, children }: { toModalId: string; children: React.ReactNode }): JSX.Element => {
  const { isModalRegistered, openModal } = useLinkModalContext()
  if (isModalRegistered(toModalId)) {
    return (
      <Link
        textAlign="start"
        sx={{
          verticalAlign: "baseline"
        }}
        color="inherit"
        component="button"
        onClick={() => openModal(toModalId)}
      >
        {children}
      </Link>
    )
  }
  return <>{children}</>
}

export const ModalFromLink = ({
  modalId,
  title,
  children
}: {
  modalId: string
  title?: string
  children: React.ReactNode
}): JSX.Element => {
  const { addModal, isModalRegistered, closeModal, isModalOpen } = useLinkModalContext()
  if (!isModalRegistered(modalId)) {
    addModal({ modalId })
    return null
  }

  const open = isModalOpen(modalId)
  const onClose = (): void => closeModal(modalId)

  return (
    <ContentfulDialog title={title} open={open} onClose={onClose}>
      <ModalContentContext.Provider value={{ isModalContent: true }}>{children}</ModalContentContext.Provider>
    </ContentfulDialog>
  )
}
