import React from "react"
import { useIntl } from "react-intl"
import { useParams, Route } from "react-router-dom"
import { NeedsApproval } from "../../views/merchantdecline/NeedsApproval"
import { Spacer } from "../../views/atoms/Atoms"
import { MerchantDenied } from "../../views/merchantdecline/MerchantDenied"
import { MerchantApproved } from "../../views/merchantdecline/MerchantApproved"
import { MerchantDeclineError } from "../../views/merchantdecline/MerchantDeclineError"
import { AppAccessPageLayout } from "components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "components/access-page/AppAccessPageCard"
import { Switch } from "../../../services/routing/Switch"

const MerchantDeclineContainer = () => {
  const { token } = useParams()
  const intl = useIntl()

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Carrot Card declined" })}>
      <AppAccessPageCard>
        <Spacer height={1.5} />
        <Switch>
          <Route exact path="/confirm/error" component={MerchantDeclineError} />
          <Route exact path="/confirm/:token/deny" component={MerchantDenied} />
          <Route exact path="/confirm/:token/approve" component={MerchantApproved} />
          <Route exact path="/confirm/:token" render={() => <NeedsApproval token={token} />} />
        </Switch>
        <Spacer height={1.5} />
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}

export default MerchantDeclineContainer
