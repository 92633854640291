import { Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { FormSelect, FormTextField } from "@carrotfertility/carotene-core-x"
import { Days, Times } from "../../../types/RequestAppointmentTypes"
import { FormSelectValue } from "./CarrotPartnerBooking"

export function renderLabelWithPlaceholder<Value = unknown | unknown[]>(
  options: { label: string; value: string }[],
  placeholder: string
) {
  // eslint-disable-next-line react/display-name
  return (value: Value): React.ReactNode => {
    if (Array.isArray(value) && value.length > 0) {
      return value.map((value) => options.find((option) => option.value === value).label).join(", ")
    }

    if (Array.isArray(value) || !value) {
      return (
        <Typography component="span" color={(theme) => theme.palette.placeholder}>
          {placeholder}
        </Typography>
      )
    }
  }
}

export function AppointmentPreference(): React.ReactElement {
  const intl = useIntl()
  const theme = useTheme()

  const preferredAppointmentDaysOptions: FormSelectValue<Days>[] = [
    { label: intl.formatMessage({ defaultMessage: "Monday" }), value: "Monday" },
    { label: intl.formatMessage({ defaultMessage: "Tuesday" }), value: "Tuesday" },
    { label: intl.formatMessage({ defaultMessage: "Wednesday" }), value: "Wednesday" },
    { label: intl.formatMessage({ defaultMessage: "Thursday" }), value: "Thursday" },
    { label: intl.formatMessage({ defaultMessage: "Friday" }), value: "Friday" },
    { label: intl.formatMessage({ defaultMessage: "Saturday" }), value: "Saturday" },
    { label: intl.formatMessage({ defaultMessage: "Sunday" }), value: "Sunday" }
  ]

  const preferredAppointmentTimesOptions: FormSelectValue<Times>[] = [
    {
      label: intl.formatMessage({ defaultMessage: "Morning (earliest appointment - 11 AM)" }),
      value: "Morning"
    },
    {
      label: intl.formatMessage({ defaultMessage: "Mid-day (11 AM - 4 PM)" }),
      value: "Midday"
    },
    {
      label: intl.formatMessage({ defaultMessage: "Evening (4 PM - latest appointment)" }),
      value: "Evening"
    }
  ]

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <Typography variant="h3">
        <FormattedMessage defaultMessage="Appointment preferences" />
      </Typography>
      <FormSelect
        multiple
        renderValue={renderLabelWithPlaceholder(
          preferredAppointmentDaysOptions,
          intl.formatMessage({ defaultMessage: "Select all that apply" })
        )}
        options={preferredAppointmentDaysOptions}
        label={intl.formatMessage({ defaultMessage: "Preferred days" })}
        name="preferredAppointmentDays"
      />
      <FormSelect
        multiple
        renderValue={renderLabelWithPlaceholder(
          preferredAppointmentTimesOptions,
          intl.formatMessage({ defaultMessage: "Select all that apply" })
        )}
        options={preferredAppointmentTimesOptions}
        label={intl.formatMessage({ defaultMessage: "Preferred times" })}
        name="preferredAppointmentTimes"
      />
      <FormTextField
        multiline
        minRows={4}
        label={intl.formatMessage({ defaultMessage: "Desired service or treatment" })}
        name="desiredService"
      />
    </Stack>
  )
}
