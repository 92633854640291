enum WhatKindOfJourneyEnums {
  IVF = "IVF",
  IUI = "IUI",
  PATHFINDING = "PATHFINDING",
  DOULA = "DOULA",
  HUMAN_MILK_SHIPPING = "HUMAN_MILK_SHIPPING",
  OTHER_PREGNANCY_SERVICES = "OTHER_PREGNANCY_SERVICES"
}

export type JourneySubType = keyof typeof WhatKindOfJourneyEnums

export default WhatKindOfJourneyEnums
