import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import usePartnerAccess from "components/partner-access/hooks/usePartnerAccess"

export function PartnerAccessDecisionStep(): JSX.Element {
  const { showPartnerAccess } = usePartnerAccess()
  const { send } = useStateMachine(null)
  useEffect(() => {
    send(showPartnerAccess ? "YES" : "NO")
  })
  return <></>
}
