import React from "react"
import { StateMachineProvider, useStateMachine } from "components/context/stateMachine/StateMachineContext"
import { benefitEnrollmentSteps } from "components/cmd-enrollment/workflow/step-library"
import { Steps } from "components/cmd-enrollment/workflow/steps"
import { useBenefitEnrollment } from "components/context/enrollment/BenefitEnrollmentContext"
import { benefitEnrollmentWorkflows, Workflows } from "components/cmd-enrollment/workflow/workflows"
import { useEnrollmentFlowModal } from "components/cmd-enrollment/EnrollmentModal"
import { useProgressTracker } from "components/views/modal/ProgressTracker"
import { EnrollmentErrorBoundary } from "./UnhandledErrorStep"
import { useIntl } from "react-intl"

type WorkflowType = Workflows
export function GetWorkflow(): WorkflowType {
  const { companyHasCMDEnabled, companyHasExternalDeductible, companyHasHDHP } = useBenefitEnrollment()
  return companyHasCMDEnabled && companyHasHDHP
    ? Workflows.CMD_HDHP
    : companyHasCMDEnabled && !companyHasHDHP
    ? Workflows.CMD_NON_HDHP
    : companyHasExternalDeductible
    ? Workflows.EMD
    : Workflows.ACA_ONLY
}

function BenefitEnrollmentView(): JSX.Element {
  const { step } = useStateMachine()
  return React.createElement(step.view)
}

export default function BenefitEnrollmentFlow(): JSX.Element {
  const { onExit } = useEnrollmentFlowModal()
  const intl = useIntl()
  useProgressTracker(1, intl.formatMessage({ defaultMessage: "YOUR INSURANCE COVERAGE" }))
  const stateMachineProps = {
    initialStep: Steps.PROCESS_ENROLLMENT_TYPE,
    workflows: benefitEnrollmentWorkflows,
    steps: benefitEnrollmentSteps,
    stateMachineName: "Benefit Enrollment",
    workflowName: GetWorkflow()
  }
  return (
    <StateMachineProvider {...stateMachineProps} handleWorkflowComplete={onExit}>
      <EnrollmentErrorBoundary>
        <BenefitEnrollmentView />
      </EnrollmentErrorBoundary>
    </StateMachineProvider>
  )
}
