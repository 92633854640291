import {
  DocumentStatusResponse,
  useCreateGetAllDocumentStatusesOverride,
  useGetAllDocumentStatuses
} from "../../../../components/medical-records-consent/utils/DocumentStatusApi"
import { CarrotDocumentType } from "carrot-api/signatureClient"
import { useIsExcludedFromMedicalRecordsConsent } from "../../../user/hooks/useGetIsExcludedFromMedicalRecordsConsent"

function hasDeclinedOrSignedMedicalRecordConsent(
  documentStatuses: DocumentStatusResponse[],
  documentType: CarrotDocumentType
): boolean {
  return Boolean(
    documentStatuses?.find(
      (docStatus) =>
        docStatus.documentType.toString() === documentType &&
        (docStatus.documentAction === "DECLINED" ||
          (docStatus.documentAction === "SIGNED" && !docStatus.isSignatureExpired))
    )
  )
}

function toDocumentStatusResponse(
  documentType: CarrotDocumentType,
  hasDeclinedOrSigned: boolean
): DocumentStatusResponse {
  return {
    documentType,
    documentTypeDescription: "",
    documentAction: hasDeclinedOrSigned ? "DECLINED" : "SKIPPED",
    timestamp: null,
    isSignatureExpired: false
  }
}

export function useDocumentStatusMapper() {
  const { data: documentStatuses } = useGetAllDocumentStatuses()
  const isOptedInToMedicalRecordCollection = !useIsExcludedFromMedicalRecordsConsent()
  const overrideDocumentStatusesResponse = useCreateGetAllDocumentStatusesOverride()

  const hasDeclinedOrSignedArt = hasDeclinedOrSignedMedicalRecordConsent(
    documentStatuses,
    CarrotDocumentType.ArtMedicalRecordsConsentForm
  )
  const hasDeclinedOrSignedPregnancy = hasDeclinedOrSignedMedicalRecordConsent(
    documentStatuses,
    CarrotDocumentType.PregnancyMedicalRecordsConsentForm
  )

  return {
    is_company_opted_in_to_medical_record_collection: {
      value: isOptedInToMedicalRecordCollection,
      // @ts-expect-error TS7011
      toggle: () => null // this is backed by launch darkly, which can easily be overridden, so not going to try to figure out how to toggle that
    },
    has_declined_or_signed_art_consent: {
      value: hasDeclinedOrSignedArt,
      toggle: () => {
        const overrideData = [
          toDocumentStatusResponse(CarrotDocumentType.ArtMedicalRecordsConsentForm, !hasDeclinedOrSignedArt),
          toDocumentStatusResponse(CarrotDocumentType.PregnancyMedicalRecordsConsentForm, hasDeclinedOrSignedPregnancy)
        ]
        overrideDocumentStatusesResponse(overrideData)
      }
    },
    has_declined_or_signed_pregnancy_consent: {
      value: hasDeclinedOrSignedPregnancy,
      toggle: () => {
        const overrideData = [
          toDocumentStatusResponse(CarrotDocumentType.ArtMedicalRecordsConsentForm, hasDeclinedOrSignedArt),
          toDocumentStatusResponse(CarrotDocumentType.PregnancyMedicalRecordsConsentForm, !hasDeclinedOrSignedPregnancy)
        ]
        overrideDocumentStatusesResponse(overrideData)
      }
    }
  }
}
