enum talkToCarrotSource {
  I_NEED_HELP = "i-need-help",
  SEND_A_MESSAGE = "send-a-message",
  SEND_A_MESSAGE_PAGE = "send-a-message",
  PROVIDER_ELIGIBILITY = "provider-eligibility",
  REFRESH_CARROT_PLAN = "refresh-carrot-plan",
  LOCKED_DOWN_GET_HELP = "locked-down-get-help",
  GET_YOUR_CARROT_PLAN = "get-your-carrot-plan",
  DELETE_ACCOUNT = "delete-account"
}

const messageTopicsToSubjectMap: Record<string, string> = {
  helpfindingprovider: "I'd like help finding a provider",
  stolen: "My Carrot Card was stolen",
  lockedcardsupport: "I'm not able to use my Carrot Card",
  "ava-bracelet": "I have a question about the Ava Bracelet",
  legacy: "I have a question about Legacy",
  "insurance-coverage": "I have a question about insurance coverage",
  MemberHSA_INDIVIDUAL: "I'm not sure if I have an HSA",
  MemberHSA_FAMILY: "I'm not sure if I have an HSA",
  MemberIndividualHsaContributionStatus: "I'm not sure of my contribution status towards an HSA",
  MemberFamilyHsaContributionStatus: "I'm not sure of my contribution status towards an HSA",
  MemberHdhp_INDIVIDUAL: "I'm not sure if I am currently enrolled in a HDHP",
  MemberHdhp_FAMILY: "I'm not sure if I am currently enrolled in a HDHP",
  PartnerHdhp: "I'm not sure if my partner is currently enrolled in a HDHP",
  PartnerHsaContributionStatus: "I'm not sure of my partner's contribution status towards an HSA",
  MemberPlanSource: "I'm not sure where I get my health insurance",
  PartnerPlanSource: "I'm not sure where my partner gets their health insurance",
  PartnerPlanSourceACA: "I'm not sure where my partner gets their health insurance",
  PartnerCoverageType: "I'm not sure who my partner's health insurance covers",
  MemberCoverageType: "I'm not sure who my health insurance covers",
  PartnerHasHSA: "I'm not sure if my partner has an HSA",
  ConnectInsuranceError: "I'm having trouble connecting to insurance",
  MemberDeductiblePeriodCoverageDate: "I'm not sure when my medical plan coverage begins or ends",
  PartnerDeductiblePeriodCoverageDate: "I'm not sure when my partner's medical plan coverage begins or ends",
  PartnerTaxDependent: "I'm not sure if I claim my partner as a dependent on my tax return",
  "i-need-help": "I need help (Talk to Carrot)",
  "send-a-message": "I have a question",
  "send-a-message-page": "I need help with Carrot",
  "provider-eligibility": "Is my current provider eligible for reimbursement",
  "refresh-carrot-plan": "My Carrot Plan needs refreshing",
  "locked-down-get-help": "I need help with Carrot",
  "get-your-carrot-plan": "My Carrot Plan",
  MemberHealthPlanConnect: "My insurance provider isn't listed",
  PartnerHealthPlanConnect: "My partner's insurance provider isn't listed",
  MemberEmdIneligible: "I'm not on my employer's health plan",
  "delete-account": "I'd like to delete my account"
}

export { talkToCarrotSource, messageTopicsToSubjectMap }
