import { Workflow } from "components/context/stateMachine/StateMachineTypes"
import { Steps } from "./steps"

export enum Workflows {
  REQUEST_CARD = "RequestCard",
  REQUEST_INTERNATIONAL_AIRWALLEX_CARD = "RequestInternationalAirwallexCard"
}

const requestCardWorkflow = [
  { at: Steps.REQUEST_CARD_INFORMATION, given: "", goto: Steps.MAILING_ADDRESS },
  { at: Steps.MAILING_ADDRESS, given: "", goto: Steps.CONFIRM_PHONE_NUMBER },
  { at: Steps.CONFIRM_PHONE_NUMBER, given: "", goto: Steps.VERIFY_PHONE_NUMBER },
  { at: Steps.VERIFY_PHONE_NUMBER, given: "YES_AGREEMENT", goto: Steps.STRIPE_USER_AGREEMENT },
  { at: Steps.STRIPE_USER_AGREEMENT, given: "", goto: Steps.REQUEST_CARD_SUCCESS },
  { at: Steps.REQUEST_CARD_SUCCESS, given: "", end: true }
]

const requestAirwallexCardWorkflow = [
  { at: Steps.REQUEST_CARD_INFORMATION, given: "", goto: Steps.INTERNATIONAL_ADDRESS },
  { at: Steps.INTERNATIONAL_ADDRESS, given: "", goto: Steps.CONFIRM_PHONE_NUMBER },
  { at: Steps.CONFIRM_PHONE_NUMBER, given: "", goto: Steps.VERIFY_PHONE_NUMBER },
  { at: Steps.VERIFY_PHONE_NUMBER, given: "YES_AGREEMENT", goto: Steps.AIRWALLEX_USER_AGREEMENT },
  { at: Steps.VERIFY_PHONE_NUMBER, given: "NO_AGREEMENT", goto: Steps.NO_USER_AGREEMENT_REQUEST_CARD },
  { at: Steps.AIRWALLEX_USER_AGREEMENT, given: "", goto: Steps.CARD_REQUEST_PENDING },
  { at: Steps.NO_USER_AGREEMENT_REQUEST_CARD, given: "", goto: Steps.CARD_REQUEST_PENDING },
  { at: Steps.CARD_REQUEST_PENDING, given: "", end: true }
]

export const requestCardWorkflows: Workflow[] = [
  { name: Workflows.REQUEST_CARD, workflow: requestCardWorkflow },
  { name: Workflows.REQUEST_INTERNATIONAL_AIRWALLEX_CARD, workflow: requestAirwallexCardWorkflow }
]
