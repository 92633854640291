import React, { FC, useState } from "react"
import { CarrotCardModuleSharedProps } from "components/views/molecules/CarrotCardModuleContainer"
import { FormattedMessage } from "react-intl"
import { RequestCardContainer } from "../../../../pages/request-carrot-card-flow"
import { useDispatch } from "react-redux"
import { fetchReimbursementDetails } from "../../../../actions/reimbursementDetailsActions"
import { Button } from "@carrotfertility/carotene-core"
import { CarrotCardModuleTemplate } from "./carrot-card-module-template"
import CardStates from "utils/CardStates"
import useGetReimbursementDetails from "services/reimbursements/hooks/useGetReimbursementDetails"

const CarrotCardEligibleModule: FC<CarrotCardModuleSharedProps> = ({ isVirtualCard }) => {
  const reimbursementDetails = useGetReimbursementDetails()
  const [openRequestCardModal, setOpenRequestCardModal] = useState(false)
  const dispatch = useDispatch()

  const toggleRequestCardModal: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    setOpenRequestCardModal(true)
  }

  const onClose = () => {
    dispatch(fetchReimbursementDetails())
    setOpenRequestCardModal(false)
  }

  const carrotCardProvider = reimbursementDetails.data?.carrotCardProvider

  if (!carrotCardProvider || reimbursementDetails.isLoading || reimbursementDetails.isError) {
    return null
  }

  return (
    <CarrotCardModuleTemplate
      cardState={CardStates.ELIGIBLE}
      title={<FormattedMessage defaultMessage="Easily pay for care with Carrot Card®" />}
      body={
        <FormattedMessage defaultMessage="The easiest way to pay for eligible services, debited directly from your account." />
      }
      links={[
        <Button
          key="request-card"
          onClick={toggleRequestCardModal}
          variant="outlined"
          color="secondary"
          id="carrot-card-module-request-card"
          sx={(theme) => ({ marginBlockStart: theme.tokens.spacing.md })}
        >
          {isVirtualCard ? (
            <FormattedMessage defaultMessage="Get virtual card" />
          ) : (
            <FormattedMessage defaultMessage="Request a card" />
          )}
        </Button>,
        <RequestCardContainer
          key="request-card-container"
          open={openRequestCardModal}
          onExit={onClose}
          provider={carrotCardProvider}
        />
      ]}
    />
  )
}

export { CarrotCardEligibleModule }
