import React, { useContext } from "react"
import { useIntl } from "react-intl"
import { Typography } from "@carrotfertility/carotene-core"
import { TalkToCarrotContext } from "../../../components/talk-to-carrot/TalkToCarrotContext"

export const SelectedTalkToCarrotLocation = (): JSX.Element => {
  const { locale } = useIntl()
  const { selectedLocationOption } = useContext(TalkToCarrotContext)
  const regionNamesInMemberLocale = new Intl.DisplayNames([locale], { type: "region" })
  return (
    <Typography variant="inherit" color="inherit" component="span">
      {regionNamesInMemberLocale.of(selectedLocationOption?.value)}
    </Typography>
  )
}
