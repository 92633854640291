import React from "react"
import { RetinaImage } from "./images/RetinaImage"
import { CircleImage } from "./images/CircleImage"
import { Box } from "@carrotfertility/carotene-core"

const IlloUnderwaterChecklist = () => <RetinaImage name="illo-underwater-checklist" height={228} width={228} />

const IlloPaperAirplane = () => <RetinaImage name="illo-paper-airplane" height={228} width={228} />

const IlloPaperAirplaneUnderwater = () => <RetinaImage name="illo-paper-airplane-underwater" height={172} width={228} />

const IlloUnderwaterDesktop = ({ className }) => (
  <RetinaImage className={className} name="illo-underwater-desktop" width={265} height={230} />
)

const IlloCircleMoneyBag = () => (
  <CircleImage size={160} padding={10} iconName="illo-money-bag" backgroundColor="geyser" />
)

const IlloCircleCarrotCards = () => (
  <Box
    alt=""
    component="img"
    height={228}
    width={228}
    src={`/images/illo-carrot-cards.png`}
    srcSet={`../images/illo-carrot-cards-2x.png`}
  />
)

const IlloClipboardNoBackground = () => <RetinaImage name="illo-clipboard-no-background" width={24} height={24} />

const IlloUnderwaterPlants = ({ height = 130, width = 212 }) => (
  <RetinaImage name="illo-underwater-plants" width={width} height={height} />
)

const IlloCarrotCards = () => <RetinaImage alt="" name="illo-carrot-cards" width={192} height={192} />

const IlloDocAndCoins = ({ className = null }) => (
  <RetinaImage name="illo-doc-and-coins" height={228} width={228} className={className} />
)

const IlloPercentCircle = ({ height = 228 }) => (
  <RetinaImage name="illo-percent-circle" height={height} width={height} />
)

const IlloBlocks = () => <RetinaImage name="illo-blocks" height={228} width={228} />

const IlloCoinsAndBills = () => <RetinaImage alt="" name="illo-coins-and-bills" height={228} width={228} />

const IlloPaperAirplaneCircle = () => (
  <CircleImage size={160} padding={44} iconName="illo-paper-airplane" backgroundColor="geyser" />
)
const IlloCalendar = ({ height = 88, width = 88 }) => <RetinaImage name="illo-calendar" height={height} width={width} />

const IlloDocSigning = () => <RetinaImage name="illo-claim" width={228} height={228} />

const IlloSearching = ({ width = 360, height = 327 }) => (
  <RetinaImage name="illo-searching" width={width} height={height} />
)

const IlloEnvelopeCheck = () => <CircleImage size={160} iconName="illo-envelope-check" />

export {
  IlloCircleMoneyBag,
  IlloCircleCarrotCards,
  IlloUnderwaterChecklist,
  IlloUnderwaterPlants,
  IlloCarrotCards,
  IlloDocAndCoins,
  IlloPercentCircle,
  IlloBlocks,
  IlloCoinsAndBills,
  IlloPaperAirplane,
  IlloPaperAirplaneCircle,
  IlloPaperAirplaneUnderwater,
  IlloCalendar,
  IlloUnderwaterDesktop,
  IlloDocSigning,
  IlloSearching,
  IlloEnvelopeCheck,
  IlloClipboardNoBackground
}
