import { useSelector } from "react-redux"
import { getCompanyDisplayName } from "reducers/companyInfo"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { PiiText } from "services/tracking"
import { FormattedMessage, useIntl } from "react-intl"
import { InternalIcon, Link, Typography, Stack, useTheme } from "@carrotfertility/carotene-core"

export default function HowToUseYourBenefitCard(): JSX.Element {
  const intl = useIntl()
  const companyDisplayName = useSelector(getCompanyDisplayName)
  const theme = useTheme()

  const formattedMessage = intl.formatMessage(
    {
      defaultMessage: "{companyDisplayName} has partnered with Carrot to offer you a comprehensive set of benefits."
    },
    { companyDisplayName: <PiiText>{companyDisplayName}</PiiText> }
  )

  return (
    <>
      <Typography variant="h3" component="h2" marginBottom={theme.spacing(theme.tokens.spacing.xxs)}>
        <FormattedMessage defaultMessage="Your benefit guide" />
      </Typography>

      <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
        <Typography>{formattedMessage}</Typography>

        <Link
          to="/your-benefit-guide/about-your-benefit"
          component={RouterLink}
          endIcon={<InternalIcon fontSize="small" color="primary" />}
        >
          <FormattedMessage defaultMessage="Get the details" />
        </Link>
      </Stack>
    </>
  )
}
