import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { MenopauseSymptoms } from "./MedicalInfoOptions"
import { FormattedMessage, useIntl } from "react-intl"
import {
  RoutingFlowCheckboxGroup,
  useConvertTranslatedLabel,
  ButtonFooterLayout,
  StepLayout,
  SkipButton,
  ContinueButton,
  RoutingFlowTitle
} from "components/carrot-plans/shared"

type FormValues = {
  menopauseSymptoms: string[]
}

export default function MenopauseSymptomsStep(): JSX.Element {
  const intl = useIntl()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultValues = medicalInfo?.menopauseSymptoms || hasQuestionBeenSkipped("menopauseSymptoms") ? "" : null

  const { send } = useStateMachine(defaultValues)

  const options = useConvertTranslatedLabel(MenopauseSymptoms)

  async function onClickContinue(formValues: FormValues): Promise<void> {
    await updateMedicalInfo(formValues)
    send("")
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["menopauseSymptoms"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Have you noticed any of the following symptoms?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="menopauseSymptoms-label">{header}</span>}
        formElements={
          <Form
            defaultValues={{
              menopauseSymptoms: defaultValues || []
            }}
            onSubmit={onClickContinue}
          >
            <RoutingFlowCheckboxGroup
              aria-labelledby="menopauseSymptoms-label"
              name="menopauseSymptoms"
              options={options}
            />

            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
