import React from "react"
import { ProviderFinderParam, useBooleanSearchParam } from "../../utils/providerFinderSearchUrl"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText } from "@carrotfertility/carotene-core"
import carrotPartnerIndicator from "../../resources/images/carrot-partner-indicator.svg"

export function CarrotPartnerFilter(): JSX.Element {
  const [carrotPartnerSearchParam, setCarrotPartnerSearchParam] = useBooleanSearchParam(
    ProviderFinderParam.CarrotPartner
  )
  const intl = useIntl()

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={(theme) => theme.spacing(theme.tokens.spacing.sm)}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.tokens.borderRadius.sm
      })}
    >
      <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.md)}>
        <FormControl>
          <FormControlLabel
            label={
              <Box
                component="span"
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
              >
                <FormattedMessage defaultMessage="Carrot partners only" />
                <img
                  src={carrotPartnerIndicator}
                  alt={intl.formatMessage({ defaultMessage: "Carrot Partner indicator" })}
                />
              </Box>
            }
            aria-label={intl.formatMessage({ defaultMessage: "Select carrot partner type" })}
            control={
              <Checkbox
                name="carrotPartner"
                checked={carrotPartnerSearchParam}
                onChange={(event) => setCarrotPartnerSearchParam(event.target.checked)}
              />
            }
          />
          <FormHelperText>
            <FormattedMessage defaultMessage="Carrot partner providers offer exclusive benefits like lower negotiated rates" />
          </FormHelperText>
        </FormControl>
      </Box>
    </Box>
  )
}
