import React from "react"
import { Box, Typography, Button, useTheme, Stack } from "@carrotfertility/carotene-core"
import useCarrotPlan from "components/carrot-plans/hooks/useCarrotPlan"
import { CarrotPlan } from "types/carrotPlanTypes"
import { useHistory } from "react-router-dom"
import { Paths } from "utils/Paths"
import useCarrotPlanEmployeeSettings from "../carrot-plans/hooks/useCarrotPlanEmployeeSettings"
import { FormattedMessage, MessageDescriptor, defineMessage, useIntl } from "react-intl"

function carrotPlanIsStale(data: Pick<CarrotPlan, "createdAtUtc">): boolean {
  const createdDate = new Date(data?.createdAtUtc)
  createdDate.setDate(createdDate.getDate() + 90)
  const today = new Date()
  return createdDate <= today
}

function carrotPlanCompleted(data: Pick<CarrotPlan, "steps">): boolean {
  return Boolean(data?.steps?.every((step) => Boolean(step?.completedAtUtc)))
}

function getModuleCopy({ steps }: Pick<CarrotPlan, "steps">): MessageDescriptor {
  if (carrotPlanCompleted({ steps })) {
    return defineMessage({
      defaultMessage:
        "It looks like you completed everything in your plan. Update us about your journey so we can refresh your plan with the most up-to-date guidance."
    })
  }
  return defineMessage({
    defaultMessage:
      "Has anything changed? Update us about your journey so we can refresh your plan with the most up-to-date guidance."
  })
}

export function CarrotPlansRefreshModule(): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()

  const { data } = useCarrotPlan()
  const history = useHistory()
  const { isDoneForNow, appIsUnlockedAndPendingCarrotPlan } = useCarrotPlanEmployeeSettings()
  const showRefreshModule =
    !data?.journeyCompleteTimestampUtc &&
    (carrotPlanCompleted(data) || carrotPlanIsStale(data)) &&
    !isDoneForNow &&
    !appIsUnlockedAndPendingCarrotPlan

  if (showRefreshModule) {
    const bodyCopy = intl.formatMessage(getModuleCopy(data))
    return (
      <Box
        padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        bgcolor={(theme) => theme.palette.info.light}
        borderRadius={(theme) => theme.tokens.borderRadius.md}
      >
        <Stack spacing={theme.spacing(theme.tokens.spacing.md)} alignItems={"flex-start"}>
          <Typography>{bodyCopy}</Typography>
          <Button onClick={() => history.push(Paths.REFRESH_CARROT_PLAN)}>
            <FormattedMessage defaultMessage="Get new suggestions" />
          </Button>
        </Stack>
      </Box>
    )
  }

  return null
}
