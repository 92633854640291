import { Divider } from "@carrotfertility/carotene"
import { Typography } from "@carrotfertility/carotene-core"
import React from "react"

interface TalkToCarrotSelectOptionWithSpacerProps extends React.HTMLProps<HTMLLIElement> {
  isSelected?: boolean
}

export function TalkToCarrotSelectOption(props: TalkToCarrotSelectOptionWithSpacerProps): JSX.Element {
  const { isSelected, ...otherProps } = props

  if (isSelected) {
    return (
      <>
        <li {...otherProps}>
          <Typography fontWeight="bold">{props?.children}</Typography>
        </li>
        <Divider paddingTopBottom="16px" />
      </>
    )
  }
  return <li {...otherProps}>{props?.children}</li>
}
