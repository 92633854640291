import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { usePartnerRegistrationFlow } from "../PartnerRegistrationFlow"
import { HealthConsent } from "../../../views/register/HealthConsent"
import { useGetRegisteringPartner, usePartnerRegFlowComplete } from "../../hooks/usePartnerAccess"
import { getIsDefaultEmailOptIn } from "../../../views/register/EmailOptIn"

export function DataConsentStep(): JSX.Element {
  const { send } = useStateMachine(null)
  const { guid, dataConsent, setDataConsent } = usePartnerRegistrationFlow()
  const { registeringUser } = useGetRegisteringPartner(guid)
  const { isLoading: isSendCompletePartnerRegistrationLoading, mutate: sendCompletePartnerRegistration } =
    usePartnerRegFlowComplete(true)

  return (
    <HealthConsent
      isSubmitting={isSendCompletePartnerRegistrationLoading}
      healthConsent={dataConsent}
      setHealthConsent={setDataConsent}
      benefitConfiguration={registeringUser.benefitConfiguration}
      onBack={() => send("BACK")}
      onComplete={() =>
        getIsDefaultEmailOptIn(registeringUser.countryCode)
          ? sendCompletePartnerRegistration(null)
          : send("EMAIL_OPT_IN")
      }
    />
  )
}
