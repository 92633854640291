export enum Steps {
  REQUEST_CARD_INFORMATION = "RequestCardInformation",
  MAILING_ADDRESS = "RequestCardMailingAddress",
  INTERNATIONAL_ADDRESS = "RequestCardInternationalAddress",
  CONFIRM_PHONE_NUMBER = "RequestCardConfirmPhoneNumber",
  VERIFY_PHONE_NUMBER = "RequestCardVerifyPhoneNumber",
  STRIPE_USER_AGREEMENT = "RequestCardStripeUserAgreement",
  AIRWALLEX_USER_AGREEMENT = "RequestCardAirwallexUserAgreement",
  REQUEST_CARD_SUCCESS = "RequestCardSuccess",
  CARD_REQUEST_PENDING = "CardRequestPending",
  NO_USER_AGREEMENT_REQUEST_CARD = "NoUserAgreementRequestCard"
}
