import { DbgPaths } from "../../../../../utils/Paths"
import React from "react"
import { FormattedMessage } from "react-intl"
import { TextLink } from "@carrotfertility/carotene"
import { UnorderedListItem } from "@carrotfertility/carotene-core"
import { CategorySections } from "../PayingForCareModal"

const AdoptionAndGCHeader = (): JSX.Element => {
  return (
    <p>
      <FormattedMessage defaultMessage="This category includes eligible expenses for the following journeys:" />
    </p>
  )
}

const AdoptionAndGCListItems = (): JSX.Element => {
  return (
    <>
      <UnorderedListItem>
        <FormattedMessage
          defaultMessage="<link>Adoption</link>"
          values={{
            link: (linkContent) => (
              <TextLink size="medium" target="_blank" href={DbgPaths.ADOPTION_COVERAGE}>
                {linkContent}
              </TextLink>
            )
          }}
        />
      </UnorderedListItem>
      <UnorderedListItem>
        <FormattedMessage
          defaultMessage="<link>Gestational surrogacy</link>"
          values={{
            link: (linkContent) => (
              <TextLink size="medium" target="_blank" href={DbgPaths.GESTATIONAL_SURROGACY_COVERAGE}>
                {linkContent}
              </TextLink>
            )
          }}
        />
      </UnorderedListItem>
    </>
  )
}

export function getAdoptionAndGCSections(): CategorySections {
  return {
    header: <AdoptionAndGCHeader />,
    listItems: <AdoptionAndGCListItems />
  }
}
