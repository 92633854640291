// @ts-expect-error TS7006
export async function waitForMinimumDelay(promise, duration) {
  function delay() {
    return new Promise((resolve) => setTimeout(resolve, duration))
  }
  let error
  const delayPromise = delay()
  // @ts-expect-error TS7006
  const promiseResult = await promise.catch((e) => {
    error = e
  })
  await delayPromise
  if (error) {
    throw error
  }
  return promiseResult
}
