declare global {
  interface Window {
    env: {
      PROVIDER_FINDER_BASE_URL: string
      AUTHORIZER_DOMAIN: string
      COMPANY_IDS_WITH_TIERED_PROVIDER_NETWORK: number[]
      CARROT_BACKEND_URL: string
    }
  }
}

const Settings = {
  BASE_URL: window.env?.PROVIDER_FINDER_BASE_URL,
  AUTHORIZER_DOMAIN: window.env?.AUTHORIZER_DOMAIN,
  COMPANY_IDS_WITH_TIERED_PROVIDER_NETWORK: window.env?.COMPANY_IDS_WITH_TIERED_PROVIDER_NETWORK,
  CARROT_BACKEND_URL: window.env?.CARROT_BACKEND_URL
} as const

export default Settings
