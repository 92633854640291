import { useFlags } from "launchdarkly-react-client-sdk"
import { EntryWithoutUnresolvableLinks } from "../../utils/ContentTypes"
import { TypeCalendlyLinkSkeleton } from "../../types/contentful"

export type AppointmentEntry = EntryWithoutUnresolvableLinks<TypeCalendlyLinkSkeleton>

type UseAppointmentSchedulingSystemResponse = {
  isCalendly: boolean
  isTimeTapForCareNavigation: boolean
  isTimeTapForOtherChats: boolean
}

export function useAppointmentSchedulingCheck(reasonId: number, url: string): UseAppointmentSchedulingSystemResponse {
  const { showTimeTapTtcCareNavigation, showTimeTap } = useFlags()
  const hasReasonId = Boolean(reasonId)

  const isTimeTapForCareNavigation = showTimeTapTtcCareNavigation && hasReasonId
  const isTimeTapForOtherChats = showTimeTap && hasReasonId
  const isCalendly = Boolean(url)
  return {
    isCalendly,
    isTimeTapForCareNavigation,
    isTimeTapForOtherChats
  }
}

export function useAppointmentSchedulingSystem(
  appointmentEntry: AppointmentEntry
): UseAppointmentSchedulingSystemResponse {
  const reasonId = appointmentEntry?.fields?.reasonId
  const url = appointmentEntry?.fields?.url

  return useAppointmentSchedulingCheck(reasonId, url)
}
