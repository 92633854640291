import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "../types/ResponseError"
import { useProviderFinderClient } from "./useProviderFinderClient"
import { HasCustomNetworksResponse } from "../types/HasCustomNetworksResponse"

const CUSTOM_NETWORKS_QUERY_KEY = "GetCustomNetworks"

export default function useGetCustomNetworks(): UseQueryResult<HasCustomNetworksResponse, ResponseError> {
  const client = useProviderFinderClient()

  return useQuery<HasCustomNetworksResponse, ResponseError>(
    [CUSTOM_NETWORKS_QUERY_KEY],
    async () => {
      const response = await client.getHasCustomNetworks()
      return response ? response : null
    },
    {
      refetchOnWindowFocus: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
