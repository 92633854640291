import React, { useEffect } from "react"
import { Title } from "../../services/page-title/Title"
import { useHistory } from "react-router"
import { useCurrentUser } from "../context/user/UserContext"
import { FormattedMessage, useIntl } from "react-intl"
import { PageLayout } from "features/global-ui/page-layout"
import { Box, Button, Link, Typography } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"

export default function CarrotPlanRequiredPage(): JSX.Element {
  const intl = useIntl()
  const { isInAppLockdown } = useCurrentUser()
  const history = useHistory()
  useEffect(() => {
    if (!isInAppLockdown) {
      history.push("/")
    }
  }, [isInAppLockdown, history])

  function handleReturnHome(): void {
    history.push("/")
  }
  function handleGetCarrotPlan(): void {
    history.push("/carrot-plans/about-you")
  }

  return (
    <>
      <Title title={intl.formatMessage({ defaultMessage: "Carrot Plan required" })} />
      <PageLayout>
        <Box justifyContent="center" alignItems="center" display="flex" marginTop="8rem">
          <Box
            borderRadius={(theme) => theme.tokens.borderRadius.md}
            bgcolor={(theme) => theme.palette.background.default}
            padding={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexDirection="column"
          >
            <Box
              component="img"
              src={`/images/illo-underwater-desktop.png`}
              srcSet={`/images/illo-underwater-desktop-2x.png`}
              alt=""
              height={162}
              width={214}
              marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
            />
            <Typography marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxs)}>
              <FormattedMessage defaultMessage="To access this page, get your Carrot Plan." />
            </Typography>
            <Link
              marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
              component={RouterLink}
              to="/your-benefit-guide/about-your-benefit"
            >
              <FormattedMessage defaultMessage="Learn about Carrot Plans" />
            </Link>
            <Box display="flex" flexDirection="row" gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
              <Button variant="outlined" color="secondary" onClick={handleReturnHome}>
                <FormattedMessage defaultMessage="Return home" />
              </Button>
              <Button onClick={handleGetCarrotPlan}>
                <FormattedMessage defaultMessage="Get Carrot Plan" />
              </Button>
            </Box>
          </Box>
        </Box>
      </PageLayout>
    </>
  )
}
