import React, { useEffect } from "react"
import { useHistory } from "react-router"
import { useSignUpFlow } from "../context/signup/SignUpFlowContext"
import { AppAccessPageLayout } from "components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "components/access-page/AppAccessPageCard"
import { FormattedMessage, useIntl } from "react-intl"
import { UnauthPreferredLocale } from "../views/UnauthPreferredLocale"
import { Box, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { PiiText } from "../../services/tracking"

export default function SignUpGetHelpSuccessPage() {
  const { email } = useSignUpFlow()
  const history = useHistory()
  const intl = useIntl()
  const theme = useTheme()

  useEffect(() => {
    if (!email) {
      history.push("/signup")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "We're on it" })}>
      <AppAccessPageCard>
        <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <Typography variant="h1">
            <FormattedMessage defaultMessage="We're on it" />
          </Typography>
          <Typography>
            <FormattedMessage
              defaultMessage="Our Care Team is on the case and will reach out to you at {email}."
              values={{ email: <PiiText>{email}</PiiText> }}
            />
          </Typography>
          <Box display="block" sx={{ alignSelf: "center" }}>
            <UnauthPreferredLocale />
          </Box>
        </Stack>
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}
