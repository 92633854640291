import { useMutation } from "@tanstack/react-query"
import { useCurrentUser } from "components/context/user/UserContext"
import { carrotClient } from "utils/CarrotClient"

export default function useUpdatePushNotificationsOptIn() {
  const { refreshUserInfo } = useCurrentUser()
  const { mutateAsync } = useMutation<void, Error, boolean>(
    (pushNotificationOptIn: boolean) => carrotClient.updatePushNotificationOptInSetting(pushNotificationOptIn),
    {
      onSuccess: () => refreshUserInfo(),
      retry: false
    }
  )

  const updatePushNotificationsOptInSetting = async (optIn: boolean) => {
    await mutateAsync(optIn)
  }

  return updatePushNotificationsOptInSetting
}
