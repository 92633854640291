import React from "react"
import { InternalIcon, Link } from "@carrotfertility/carotene-core"
import { DoulaAttestationFormModal } from "../../../components/doula-attestation/DoulaAttestationFormModal"
import { useModalContentContext } from "../shared/modal-content-context"

export const DoulaAttestationLink = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const modalContext = useModalContentContext()
  const [isOpen, setIsOpen] = React.useState(false)

  if (modalContext?.isModalContent) {
    // The link is in a modal.
    // Return a link that opens a new tab that displays the doula attestation form dialog
    return (
      <Link textAlign="start" color="inherit" target="_blank" href="/doula-attestation">
        {children}
      </Link>
    )
  }
  // The link is not in a modal.
  // Return a link that opens the doula attestation form dialog over the current page
  return (
    <>
      <Link
        textAlign="start"
        component="button"
        endIcon={<InternalIcon color="primary" fontSize="small" />}
        onClick={() => setIsOpen(true)}
      >
        {children}
      </Link>
      <DoulaAttestationFormModal open={isOpen} setOpen={setIsOpen} />
    </>
  )
}
