import * as Sentry from "@sentry/browser"
import { ScopeContext } from "@sentry/types"

// It is currently impossible to nest another Sentry reporting client under the monolith one
// We should initialize a client exclusively for Provider Finder when we further decouple it

function reportError(error: any, context?: Partial<ScopeContext>): void {
  if (error?.correlationId) {
    Sentry.captureException(error, {
      ...context,
      tags: { correlationId: error.correlationId }
    })
  } else {
    Sentry.captureException(error, context)
  }
}

function reportErrorMessage(message: string, context?: Partial<ScopeContext>): void {
  Sentry.captureMessage(message, context)
}

export { reportError, reportErrorMessage }
