import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { ProviderRequest } from "../types/ProviderRequest"
import { useCarrotPartnerBookingApiClient } from "./useCarrotPartnerBookingApiClient"

export default function useSubmitProviderRequest(
  onSuccess?: () => void
): UseMutationResult<unknown, Error, ProviderRequest> {
  const client = useCarrotPartnerBookingApiClient()
  return useMutation<unknown, Error, ProviderRequest>(
    (providerRequest) => client.postProviderRequest(providerRequest),
    {
      retry: false,
      onSuccess: onSuccess
    }
  )
}
