import React, { ReactNode } from "react"
import { InfoIcon, IconButton, IconButtonProps, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { useRoutingFlow } from "./RoutingFlowContainer"

interface StepLayoutProps {
  header: ReactNode | ReactNode[]
  description?: ReactNode | ReactNode[]
  formElements?: ReactNode | ReactNode[]
  iconButton?: ReactNode | ReactNode[]
}

export function RoutingFlowModalIcon(props: Pick<IconButtonProps, "aria-label" | "onClick">) {
  const { disableButtons } = useRoutingFlow()
  return (
    <IconButton {...props} disabled={disableButtons} size="small">
      <InfoIcon />
    </IconButton>
  )
}

export function StepLayout({ header, iconButton, description, formElements }: StepLayoutProps): JSX.Element {
  const theme = useTheme()
  return (
    <>
      <Stack
        spacing={theme.spacing(theme.tokens.spacing.xs)}
        marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
      >
        <Stack spacing={theme.spacing(theme.tokens.spacing.xxxs)} direction="row">
          <Typography color={(theme) => theme.palette.text.primary} variant="h3" component="h1">
            {header}
          </Typography>
          {iconButton}
        </Stack>
        <Typography>{description}</Typography>
      </Stack>
      {formElements}
    </>
  )
}
