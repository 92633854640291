import { useIntl } from "react-intl"

export function useTermsAndConditionsUrl(): string {
  const intl = useIntl()
  const { locale } = intl

  return `https://www.get-carrot.com/${locale.toLocaleLowerCase()}/terms-conditions`
}

export function usePrivacyNoticeUrl(): string {
  const intl = useIntl()
  const { locale } = intl

  return `https://www.get-carrot.com/${locale.toLocaleLowerCase()}/privacy-policy`
}
