import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import { useCheckRoutingInfoQueryCache, useUpdateRoutingInfo } from "components/carrot-plans/shared/useQueryRoutingInfo"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  StepLayout,
  useConvertTranslatedLabel
} from "components/carrot-plans/shared"
import { FormRadioButtonGroup } from "services/common-forms"

const OPTIONS = [
  { label: defineMessage({ defaultMessage: "Domestic" }), value: "DOMESTIC" },
  { label: defineMessage({ defaultMessage: "International/intercountry" }), value: "INTERNATIONAL" },
  { label: defineMessage({ defaultMessage: "Adopting from foster care" }), value: "FOSTER" },
  { label: defineMessage({ defaultMessage: "I'm not sure" }), value: "NOT_SURE" }
]

export default function AdoptionPathwayStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.adoptionInterest
  const { send } = useStateMachine(defaultValue ? "" : null)
  const intl = useIntl()
  async function onClickContinue(formValues: { adoptionInterest: string }): Promise<void> {
    await mutateAsync(formValues, { onSuccess: () => send("") })
  }
  const radioOptions = useConvertTranslatedLabel(OPTIONS)
  const header = intl.formatMessage({
    defaultMessage: "What adoption pathway are you pursuing or most interested in exploring?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={header}
        formElements={
          <Form defaultValues={{ adoptionInterest: defaultValue }} onSubmit={onClickContinue}>
            <FormRadioButtonGroup
              name="adoptionInterest"
              controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
              options={radioOptions}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
