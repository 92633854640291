import { RetinaImage } from "./RetinaImage"
import PropTypes from "prop-types"
import React, { FC, ImgHTMLAttributes } from "react"
import { Box } from "@carrotfertility/carotene-core"

type CircleImageProps = {
  iconName: string
  backgroundColor?: string
  size?: number
  padding?: number
} & ImgHTMLAttributes<HTMLImageElement>

const CircleImage: FC<CircleImageProps> = ({ iconName, size = 40, padding = 8 }) => {
  const imageSize = size - padding * 2
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius={(theme) => theme.tokens.borderRadius.round}
      height={size}
      width={size}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper
      })}
    >
      <RetinaImage name={iconName} height={imageSize} width={imageSize} />
    </Box>
  )
}

CircleImage.propTypes = {
  iconName: PropTypes.string.isRequired
}
export { CircleImage }
