import React, { FC, ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { getPiiProps } from "services/tracking"
import {
  Box,
  Stack,
  Typography,
  useTheme,
  UnorderedList,
  UnorderedListItem,
  ListItemText
} from "@carrotfertility/carotene-core"
import { useMemberName } from "services/user/derived-selectors/member-info"

interface Thread {
  date: string
  subject: string
}

type ThreadSubjectAndDateProps = {
  thread: Thread
}

type ThreadContainerProps = {
  children: ReactNode | ReactNode[]
  onClick: () => void
}

type ThreadSideTickProps = {
  active: boolean
}

type StyledThreadsListProps = {
  children: React.ReactNode | React.ReactNode[]
}

export function ThreadSideTick({ active }: ThreadSideTickProps): JSX.Element {
  const borderColor = (theme: any) => (active ? theme.palette.primary.main : theme.palette.background.default)

  return (
    <Box
      display="flex"
      height="80px"
      border={(theme) => `solid ${theme.tokens.borderWidth.md}`}
      borderColor={borderColor}
    />
  )
}
export function DefaultThreadMessage(): JSX.Element {
  const theme = useTheme()
  const memberName = useMemberName()

  if (memberName.isLoading) {
    return null
  }

  return (
    <Stack
      spacing={theme.tokens.spacing.md}
      marginLeft={theme.tokens.spacing.xxl}
      marginRight={theme.tokens.spacing.md}
    >
      <Typography variant="h3" component="h2">
        <FormattedMessage defaultMessage="Get Started with Messages" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage defaultMessage="Hi {memberFirstName}," values={{ memberFirstName: memberName.data }} />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage
          defaultMessage="Welcome! In this space, you’ll find your conversations with us. 
          Send us a message anytime and we’ll do our best to respond within one business day. 
          And don't worry — we'll continue to send our replies to your contact email address as well."
        />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage
          defaultMessage="Don't see something you're looking for? 
          There are a few reasons that might happen:"
        />
        <Box>
          <UnorderedList>
            <UnorderedListItem>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="Some notifications, such as Carrot Expert chat reminders, 
                    are sent only via email."
                  />
                }
              />
            </UnorderedListItem>
            <UnorderedListItem>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="At this time, only members can receive messages here. 
                    If an eligible partner sends us a message, our reply will appear in their email."
                  />
                }
              />
            </UnorderedListItem>
          </UnorderedList>
        </Box>
        <FormattedMessage defaultMessage="Best," description="message salutation" />
        <Typography variant="body1">
          <ListItemText primary={<FormattedMessage defaultMessage="Carrot Care Team" />} />
        </Typography>
      </Typography>
    </Stack>
  )
}

export const ThreadSubjectAndDate: FC<ThreadSubjectAndDateProps> = ({ thread }): JSX.Element => {
  return (
    <Stack
      spacing="none"
      sx={{ marginInlineEnd: "18px", overflow: "hidden" }}
      padding="20px 0px 20px 18px"
      width="100%"
    >
      <Typography variant="overline">{thread.date}</Typography>
      <Typography
        {...getPiiProps()}
        variant="body1"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "block"
        }}
      >
        {thread.subject}
      </Typography>
    </Stack>
  )
}

export function ThreadCardContainer({ children, onClick }: ThreadContainerProps): JSX.Element {
  return (
    <Box
      tabIndex={0}
      padding={0}
      component="button"
      display="flex"
      textAlign="left"
      borderTop={(theme) => `solid ${theme.tokens.borderWidth.sm} ${theme.palette.background.hover}`}
      borderRight={0}
      borderBottom={(theme) => `solid ${theme.tokens.borderWidth.sm} ${theme.palette.background.hover}`}
      borderLeft={0}
      marginRight={(theme) => theme.tokens.spacing.md}
      bgcolor={(theme) => theme.palette.background.default}
      sx={{ overflow: "none" }}
      onClick={onClick}
      width="100%"
    >
      {children}
    </Box>
  )
}

export function StyledThreadsList({ children }: StyledThreadsListProps): JSX.Element {
  return (
    <Box
      minHeight="400px"
      height="100%"
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      bgcolor={(theme) => theme.palette.background.default}
      sx={{ overflowX: "hidden", overflowY: "auto" }}
    >
      {children}
    </Box>
  )
}
