enum ActionTypes {
  FETCH_USER_INFO_FAILURE = "FETCH_USER_INFO_FAILURE",
  FETCH_USER_INFO_REQUEST = "FETCH_USER_INFO_REQUEST",
  FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS",

  UPDATE_PERSONAL_EMAILS_SUCCESS = "UPDATE_PERSONAL_EMAILS_SUCCESS",
  UPDATE_PERSONAL_EMAILS_FAILURE = "UPDATE_PERSONAL_EMAILS_FAILURE",

  UPDATE_EMAIL_OPT_IN_SUCCESS = "UPDATE_EMAIL_OPT_IN_SUCCESS",
  UPDATE_EMAIL_OPT_IN_FAILURE = "UPDATE_EMAIL_OPT_IN_FAILURE",

  UPDATE_PARTNER_NAME_SUCCESS = "UPDATE_PARTNER_NAME_SUCCESS",
  UPDATE_PARTNER_NAME_FAILURE = "UPDATE_PARTNER_NAME_FAILURE",

  UPDATE_PARTNER_EMAIL_SUCCESS = "UPDATE_PARTNER_EMAIL_SUCCESS",
  UPDATE_PARTNER_EMAIL_FAILURE = "UPDATE_PARTNER_EMAIL_FAILURE",

  UPDATE_PARTNER_DOB_SUCCESS = "UPDATE_PARTNER_DOB_SUCCESS",
  UPDATE_PARTNER_DOB_FAILURE = "UPDATE_PARTNER_DOB_FAILURE",

  UPDATE_PARTNER_SEX_SUCCESS = "UPDATE_PARTNER_SEX_SUCCESS",
  UPDATE_PARTNER_SEX_FAILURE = "UPDATE_PARTNER_DOB_FAILURE",

  UPDATE_DATE_OF_BIRTH_SUCCESS = "UPDATE_DATE_OF_BIRTH_SUCCESS",
  UPDATE_DATE_OF_BIRTH_FAILURE = "UPDATE_DATE_OF_BIRTH_FAILURE",

  UPDATE_SEX_SUCCESS = "UPDATE_SEX_SUCCESS",
  UPDATE_SEX_FAILURE = "UPDATE_SEX_FAILURE",

  UPDATE_GENDER_IDENTITY_SUCCESS = "UPDATE_GENDER_IDENTITY_SUCCESS",
  UPDATE_GENDER_IDENTITY_FAILURE = "UPDATE_GENDER_IDENTITY_FAILURE",

  UPDATE_PARTNER_GENDER_IDENTITY_SUCCESS = "UPDATE_PARTNER_GENDER_IDENTITY_SUCCESS",
  UPDATE_PARTNER_GENDER_IDENTITY_FAILURE = "UPDATE_PARTNER_GENDER_IDENTITY_FAILURE",

  UPDATE_MEMBER_HAS_LEGAL_PARTNER_SUCCESS = "UPDATE_MEMBER_HAS_LEGAL_PARTNER_SUCCESS",
  UPDATE_MEMBER_HAS_LEGAL_PARTNER_FAILURE = "UPDATE_MEMBER_HAS_LEGAL_PARTNER_FAILURE",

  UPDATE_MEMBER_COVERAGE_TYPE_SUCCESS = "UPDATE_MEMBER_COVERAGE_TYPE_SUCCESS",
  UPDATE_MEMBER_COVERAGE_TYPE_FAILURE = "UPDATE_MEMBER_COVERAGE_TYPE_FAILURE",

  UPDATE_MEMBER_PLAN_SOURCE_SUCCESS = "UPDATE_MEMBER_PLAN_SOURCE_SUCCESS",
  UPDATE_MEMBER_PLAN_SOURCE_FAILURE = "UPDATE_MEMBER_PLAN_SOURCE_FAILURE",

  UPDATE_MEMBER_HAS_HSA_SUCCESS = "UPDATE_MEMBER_HAS_HSA_SUCCESS",
  UPDATE_MEMBER_HAS_HSA_FAILURE = "UPDATE_MEMBER_HAS_HSA_FAILURE",

  UPDATE_MEMBER_HSA_CONTRIBUTION_STATUS_SUCCESS = "UPDATE_MEMBER_HSA_CONTRIBUTION_STATUS_SUCCESS",

  UPDATE_MEMBER_HAS_HDHP_SUCCESS = "memberHasHDHP",

  UPDATE_PARTNER_COVERAGE_TYPE_SUCCESS = "UPDATE_PARTNER_COVERAGE_TYPE_SUCCESS",
  UPDATE_PARTNER_COVERAGE_TYPE_FAILURE = "UPDATE_PARTNER_COVERAGE_TYPE_FAILURE",

  UPDATE_PARTNER_PLAN_SOURCE_SUCCESS = "UPDATE_PARTNER_PLAN_SOURCE_SUCCESS",
  UPDATE_PARTNER_PLAN_SOURCE_FAILURE = "UPDATE_PARTNER_PLAN_SOURCE_FAILURE",

  UPDATE_PARTNER_HAS_HSA_SUCCESS = "UPDATE_PARTNER_HAS_HSA_SUCCESS",
  UPDATE_PARTNER_HAS_HSA_FAILURE = "UPDATE_PARTNER_HAS_HSA_FAILURE",

  UPDATE_PARTNER_HSA_CONTRIBUTION_STATUS_SUCCESS = "UPDATE_PARTNER_HSA_CONTRIBUTION_STATUS_SUCCESS",

  UPDATE_PARTNER_HAS_HDHP_SUCCESS = "memberHasHDHP",

  UPDATE_MEMBER_COVERAGE_START_DATE = "UPDATE_MEMBER_COVERAGE_START_DATE",
  UPDATE_MEMBER_COVERAGE_END_DATE = "UPDATE_MEMBER_COVERAGE_END_DATE",
  UPDATE_PARTNER_COVERAGE_START_DATE = "UPDATE_PARTNER_COVERAGE_START_DATE",
  UPDATE_PARTNER_COVERAGE_END_DATE = "UPDATE_PARTNER_COVERAGE_START_DATE",

  UPDATE_LEGALLY_MARRIED_SUCCESS = "UPDATE_LEGALLY_MARRIED",
  UPDATE_PARTNER_AS_DEPENDENT_SUCCESS = "UPDATE_PARTNER_AS_DEPENDENT",

  UPDATE_MEMBER_ID_SUCCESS = "UPDATE_MEMBER_ID_SUCCESS",
  UPDATE_MEMBER_ID_FAILURE = "UPDATE_MEMBER_ID_FAILURE",

  UPDATE_SELECTED_TRADING_PARTNER_SUCCESS = "UPDATE_SELECTED_TRADING_PARTNER_SUCCESS",
  UPDATE_SELECTED_TRADING_PARTNER_FAILURE = "UPDATE_SELECTED_TRADING_PARTNER_FAILURE",

  UPDATE_MEMBER_ENROLLMENTS_SUCCESS = "UPDATE_MEMBER_ENROLLMENTS_SUCCESS",
  UPDATE_MEMBER_ENROLLMENTS_FAILURE = "UPDATE_MEMBER_ENROLLMENTS_FAILURE",

  UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS",

  SESSION_EXPIRED = "SESSION_EXPIRED",
  SIGN_IN = "SIGN_IN",

  FETCH_IS_LOGGED_IN_REQUEST = "FETCH_IS_LOGGED_IN_REQUEST",
  FETCH_IS_LOGGED_IN_SUCCESS = "FETCH_IS_LOGGED_IN_SUCCESS",
  FETCH_IS_LOGGED_IN_FAILURE = "FETCH_IS_LOGGED_IN_FAILURE",

  FETCH_SIGN_OUT_REQUEST = "FETCH_SIGN_OUT_REQUEST",
  FETCH_SIGN_OUT_SUCCESS = "FETCH_SIGN_OUT_SUCCESS",
  FETCH_SIGN_OUT_FAILURE = "FETCH_SIGN_OUT_FAILURE",

  VERIFY_INSURANCE_REQUEST = "VERIFY_INSURANCE_REQUEST",
  VERIFY_INSURANCE_SUCCESS = "VERIFY_INSURANCE_SUCCESS",
  VERIFY_INSURANCE_FAILURE = "VERIFY_INSURANCE_FAILURE",

  // Persisting email pre-login
  PERSIST_EMAIL = "PERSIST_EMAIL",

  // activate card
  ACTIVATE_CARD_REQUEST = "ACTIVATE_CARD_REQUEST",
  ACTIVATE_CARD_SUCCESS = "ACTIVATE_CARD_SUCCESS",
  ACTIVATE_CARD_FAILURE = "ACTIVATE_CARD_FAILURE",

  // Deductible status
  FETCH_DEDUCTIBLE_STATUS_REQUEST = "FETCH_DEDUCTIBLE_STATUS_REQUEST",
  FETCH_DEDUCTIBLE_STATUS_SUCCESS = "FETCH_DEDUCTIBLE_STATUS_SUCCESS",
  FETCH_DEDUCTIBLE_STATUS_FAILURE = "FETCH_DEDUCTIBLE_STATUS_FAILURE",

  // Reimbursement status
  FETCH_REIMBURSEMENT_DETAILS_REQUEST = "FETCH_REIMBURSEMENT_DETAILS_REQUEST",
  FETCH_REIMBURSEMENT_DETAILS_SUCCESS = "FETCH_REIMBURSEMENT_DETAILS_SUCCESS",
  FETCH_REIMBURSEMENT_DETAILS_FAILURE = "FETCH_REIMBURSEMENT_DETAILS_FAILURE",
  FETCH_REIMBURSEMENT_DETAILS_STATUS = "FETCH_REIMBURSEMENT_DETAILS_STATUS",
  FETCH_REIMBURSEMENT_DETAILS_ENROLLMENT_STARTED = "FETCH_REIMBURSEMENT_DETAILS_ENROLLMENT_STARTED",

  FETCH_BENEFIT_ENROLLMENT_SUCCESS = "FETCH_BENEFIT_ENROLLMENT_SUCCESS",
  FETCH_BENEFIT_ENROLLMENT_FAILURE = "FETCH_BENEFIT_ENROLLMENT_FAILURE",

  // Subsidy status
  FETCH_SUBSIDY_STATUS_SUCCESS = "FETCH_SUBSIDY_STATUS_SUCCESS",
  FETCH_SUBSIDY_STATUS_FAILURE = "FETCH_SUBSIDY_STATUS_FAILURE",
  FETCH_SUBSIDIES_SUCCESS = "FETCH_SUBSIDIES_SUCCESS",
  FETCH_SUBSIDIES_FAILURE = "FETCH_SUBSIDIES_FAILURE",

  // Insurance sync status
  FETCH_INSURANCE_SYNC_SUCCESS = "FETCH_INSURANCE_SYNC_SUCCESS",
  FETCH_INSURANCE_SYNC_FAILURE = "FETCH_INSURANCE_SYNC_FAILURE",

  FETCH_ACA_STATUS_SUCCESS = "FETCH_ACA_STATUS_SUCCESS",
  FETCH_ACA_STATUS_FAILURE = "FETCH_ACA_STATUS_FAILURE",

  // library categories
  FETCH_LIBRARY_SUCCESS = "FETCH_LIBRARY_SUCCESS",
  FETCH_LIBRARY_FAILURE = "FETCH_LIBRARY_FAILURE",

  SET_CURRENT_LIBRARY_CATEGORY = "SET_CURRENT_LIBRARY_CATEGORY",

  FETCH_AVA_CHECKOUT_SUCCESS = "FETCH_AVA_CHECKOUT_SUCCESS",
  FETCH_AVA_CHECKOUT_FAILURE = "FETCH_AVA_CHECKOUT_FAILURE",
  FETCH_AVA_CHECKOUT_REQUEST = "FETCH_AVA_CHECKOUT_REQUEST",

  FETCH_PAGE_SETS = "FETCH_PAGE_SETS",
  FETCH_PAGE_SETS_SUCCESS = "FETCH_PAGE_SETS_SUCCESS",
  FETCH_PAGE_SETS_FAILURE = "FETCH_PAGE_SETS_FAILURE",

  FETCH_VIRTUAL_CARD_DETAILS_REQUEST = "FETCH_VIRTUAL_CARD_DETAILS_REQUEST",
  FETCH_VIRTUAL_CARD_DETAILS_SUCCESS = "FETCH_VIRTUAL_CARD_DETAILS_SUCCESS",
  FETCH_VIRTUAL_CARD_DETAILS_FAILURE = "FETCH_VIRTUAL_CARD_DETAILS_FAILURE",

  FETCH_BENEFIT_CONFIGURATION_REQUEST = "FETCH_BENEFIT_CONFIGURATION_REQUEST",
  FETCH_BENEFIT_CONFIGURATION_SUCCESS = "FETCH_BENEFIT_CONFIGURATION_SUCCESS",
  FETCH_BENEFIT_CONFIGURATION_FAILURE = "FETCH_BENEFIT_CONFIGURATION_FAILURE",

  DRAG_ENTER = "DRAG_ENTER",
  DRAG_LEAVE = "DRAG_LEAVE",
  DRAG_RESET = "DRAG_RESET",

  UPDATE_HAS_MET_SIGNATURE_REQUIREMENT_IN_MEMORY = "UPDATE_HAS_MET_SIGNATURE_REQUIREMENT_IN_MEMORY"
}

export type ActionType = keyof typeof ActionTypes

export default ActionTypes
