import React from "react"
import { Typography, Link, Stack } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"

export function DoulaAttestationFormGuidance() {
  return (
    <Stack gap={(theme) => theme.spacing(theme.tokens.spacing.md)}>
      <Typography>
        <FormattedMessage defaultMessage="Share some information below to send the required attestation form to your doula. We’ll email them a link to complete the electronic form." />
      </Typography>
      <Typography>
        <FormattedMessage
          defaultMessage="<link>Visit your benefit guide</link> for details on doula eligibility and covered services."
          values={{
            link: (linkText) => (
              <Link color="inherit" target="_blank" href={"/your-benefit-guide"}>
                {linkText}
              </Link>
            )
          }}
        />
      </Typography>
    </Stack>
  )
}
