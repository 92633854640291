import React from "react"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useMedicalInfoWorkflow } from "../../hooks/useMedicalInfoFlow"
import { FemaleConditionsOptions, MaleConditionsOptions } from "./MedicalInfoOptions"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { Form, useWatch } from "@carrotfertility/carotene-core-x"
import {
  RoutingFlowCheckboxGroup,
  ButtonFooterLayout,
  ContinueButton,
  SkipButton,
  StepLayout,
  FormTextFieldWithCharacterCount,
  useConvertTranslatedLabel,
  RoutingFlowTitle
} from "components/carrot-plans/shared"
import { Stack, useTheme } from "@carrotfertility/carotene-core"

type FormValues = {
  conditions?: string[]
  conditionsOther?: string
}

const OPTIONAL_ANSWER_KEY = "otherCondition"

const OPTIONAL_SELECTION = {
  label: defineMessage({ defaultMessage: "Something else you'd like us to know about" }),
  value: OPTIONAL_ANSWER_KEY
}

function OptionalInfoInput() {
  const selectedValues = useWatch({ name: "conditions" })
  if (!selectedValues.includes(OPTIONAL_ANSWER_KEY)) {
    return null
  }
  return (
    <FormTextFieldWithCharacterCount
      name="conditionsOther"
      helperText={<FormattedMessage defaultMessage="Optional details about other conditions" />}
    />
  )
}

export default function ConditionsStep(): JSX.Element {
  const theme = useTheme()
  const { IsAMAB, IsAFAB } = useMedicalInfoWorkflow()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultConditionsValues = IsAMAB ? medicalInfo?.maleConditions : IsAFAB ? medicalInfo?.femaleConditions : ""
  const defaultConditionsOther = medicalInfo?.conditionsOther
  const defaultConditionsValuesWithOther = [
    ...(defaultConditionsValues || []),
    defaultConditionsOther ? OPTIONAL_ANSWER_KEY : ""
  ].filter(Boolean)

  const buttonOptions = IsAMAB ? MaleConditionsOptions : IsAFAB ? FemaleConditionsOptions : null
  const options = useConvertTranslatedLabel([...buttonOptions, OPTIONAL_SELECTION])
  const intl = useIntl()

  const { send } = useStateMachine(
    defaultConditionsValues || defaultConditionsOther || hasQuestionBeenSkipped("conditions") ? "" : null
  )

  async function onClickContinue(values: FormValues): Promise<void> {
    if (values?.conditions?.length) {
      const selected = values.conditions.filter((value) => value !== OPTIONAL_ANSWER_KEY)

      const medicalInfoPayload = {
        conditionsOther: values.conditionsOther || ""
      }

      if (IsAMAB) {
        // @ts-expect-error TS7053
        medicalInfoPayload["maleConditions"] = selected
      } else if (IsAFAB) {
        // @ts-expect-error TS7053
        medicalInfoPayload["femaleConditions"] = selected
      }

      await updateMedicalInfo(medicalInfoPayload)
      send("")
    } else {
      await onSkip()
    }
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["conditions"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Do you have any of these conditions?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="conditions-label">{header}</span>}
        description={<FormattedMessage defaultMessage="Select all that apply." />}
        formElements={
          <Form
            defaultValues={{
              conditions: defaultConditionsValuesWithOther,
              conditionsOther: defaultConditionsOther || ""
            }}
            onSubmit={onClickContinue}
          >
            <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
              <RoutingFlowCheckboxGroup name="conditions" aria-labelledby="conditions-label" options={options} />
              <OptionalInfoInput />
            </Stack>
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
