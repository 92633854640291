import { useMemo } from "react"
import { useGetRoutingInfo } from "../shared/useQueryRoutingInfo"
import useCarrotPlan from "./useCarrotPlan"
import useGetMemberBenefit from "./useGetMemberBenefit"
import { useGetMostRecentlyCompletedRouting } from "./useGetMostRecentlyCompletedRouting"
import { useGetRegistrationJourney } from "./useGetRegistrationJourney"
import { useEnabledJourneyOptions, isJourneySupported } from "./useJourneyOptions"

type UseJourneyReturn = {
  journeyDataIsLoaded: boolean
  isJourneyComplete: boolean
  resolvedJourney: string | null
  memberHasJourneyType: boolean
}

export function useJourney(): UseJourneyReturn {
  const mostRecentlyCompletedRoutingQuery = useGetMostRecentlyCompletedRouting()
  const routingInfoQuery = useGetRoutingInfo()
  const carrotPlanQuery = useCarrotPlan()
  const registrationJourneyQuery = useGetRegistrationJourney()
  const memberBenefitQuery = useGetMemberBenefit()
  const { journeyOptions } = useEnabledJourneyOptions(memberBenefitQuery.data)
  const memberHasJourneyType = Boolean(
    mostRecentlyCompletedRoutingQuery.data?.journey || routingInfoQuery.data?.journey || registrationJourneyQuery.data
  )
  const isJourneyComplete = carrotPlanQuery.data?.journeyCompleteTimestampUtc != null

  const journeyDataIsLoaded = [
    memberBenefitQuery,
    routingInfoQuery,
    registrationJourneyQuery,
    carrotPlanQuery,
    mostRecentlyCompletedRoutingQuery
  ].every((query) => !query.isLoading)

  const resolvedJourney = useMemo(() => {
    if (!journeyDataIsLoaded) return null

    let journeySelection = mostRecentlyCompletedRoutingQuery.data?.journey
    journeySelection ??= routingInfoQuery.data?.journey
    journeySelection ??= registrationJourneyQuery.data?.journey

    if (isJourneySupported({ journey: journeySelection, options: journeyOptions })) {
      return journeySelection
    }

    return null
  }, [
    journeyDataIsLoaded,
    journeyOptions,
    mostRecentlyCompletedRoutingQuery.data?.journey,
    registrationJourneyQuery.data?.journey,
    routingInfoQuery.data?.journey
  ])

  return {
    journeyDataIsLoaded,
    isJourneyComplete,
    memberHasJourneyType,
    resolvedJourney
  }
}
