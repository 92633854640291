import { CountryCode } from "../../content/CountryCode"

// Collections created using https://www.gov.uk/eu-eea
const EU_COUNTRIES = [
  CountryCode.Austria,
  CountryCode.Belgium,
  CountryCode.Bulgaria,
  CountryCode.Croatia,
  CountryCode.Cyprus,
  CountryCode.Czechia,
  CountryCode.Denmark,
  CountryCode.Estonia,
  CountryCode.Finland,
  CountryCode.France,
  CountryCode.Germany,
  CountryCode.Greece,
  CountryCode.Hungary,
  CountryCode.Ireland,
  CountryCode.Italy,
  CountryCode.Latvia,
  CountryCode.Lithuania,
  CountryCode.Luxembourg,
  CountryCode.Malta,
  CountryCode.Netherlands,
  CountryCode.Poland,
  CountryCode.Portugal,
  CountryCode.Romania,
  CountryCode.Slovakia,
  CountryCode.Slovenia,
  CountryCode.Spain,
  CountryCode.Sweden
]
const EEA_COUNTRIES = EU_COUNTRIES.concat([CountryCode.Iceland, CountryCode.Liechtenstein, CountryCode.Norway])

export function isEUCountry(countryCode: CountryCode) {
  return EU_COUNTRIES.includes(countryCode)
}

export function isEEACountry(countryCode: CountryCode) {
  return EEA_COUNTRIES.includes(countryCode)
}
