import React, { useEffect } from "react"
import { RoutingFlowLayout } from "../shared/RoutingFlowContainer"
import { Box, Typography, Button } from "@carrotfertility/carotene-core"
import { useHistory } from "react-router-dom"
import { Steps } from "./workflow"
import { CaseRateRenewalsStatus, useGetCaseRateRenewalStatus } from "./useQueryCaseRateRenewals"
import { FormattedMessage, useIntl } from "react-intl"
import { CaseRateRenewalModalTopSection } from "./CaseRateRenewalModalTopSection"
import { CaseRateRenewalModalButtonGroup } from "./CaseRateRenewalModalButtonGroup"

export function GatedExitRoutingFlowWarning() {
  const { data } = useGetCaseRateRenewalStatus()
  const history = useHistory()
  const intl = useIntl()

  useEffect(() => {
    if (data !== CaseRateRenewalsStatus.GATED) {
      history.push("/")
    }
  })

  return (
    <RoutingFlowLayout>
      <Box padding={(theme) => theme.spacing(theme.tokens.spacing.sm)}>
        <CaseRateRenewalModalTopSection
          header={intl.formatMessage({
            defaultMessage: "Go back to your most recent plan?"
          })}
          showImage={false}
        />
        <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="If you go back, we'll ask you to choose either to keep your plan or to get new suggestions." />
        </Typography>
        <CaseRateRenewalModalButtonGroup
          buttonOne={
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => history.push("/carrot-plans/case-rate-renewal/routing")}
            >
              <FormattedMessage defaultMessage="Continue with refresh" />
            </Button>
          }
          buttonTwo={
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(`/?step=${Steps.CARROT_PLAN_OPTIONS}`)}
            >
              <FormattedMessage defaultMessage="Go back to my plan" />
            </Button>
          }
        />
      </Box>
    </RoutingFlowLayout>
  )
}
