import React, { useEffect } from "react"
import { CircleImage } from "../CircleImage"
import { Link } from "react-router-dom"
import { NoResultMessage } from "./NoResultMessage"
import { providerFinderRootPath } from "../../utils/providerFinderSearchUrl"
import { getHeap } from "../../utils/heap"
import { FormattedMessage } from "react-intl"
import { Box, Button, Typography } from "@carrotfertility/carotene-core"

export function NoResultDisplay({ isCountryCodeNotMatching }: { isCountryCodeNotMatching: boolean }): JSX.Element {
  useEffect(
    function trackProviderSearchNoResultsMessageRendered() {
      getHeap().track("Provider Search No Results Message Rendered", {
        "Location Is Outside Country": isCountryCodeNotMatching ? "true" : "false"
      })
    },
    [isCountryCodeNotMatching]
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      padding="80px 0 0 0"
      gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
    >
      <CircleImage iconName="icn-find" backgroundColor="lightBlue" size={84} alt="" />
      <Box textAlign="center">
        <Typography variant="h4" color="secondary">
          <FormattedMessage defaultMessage="No results found" />
        </Typography>
        <NoResultMessage isCountryCodeNotMatching={isCountryCodeNotMatching} />
      </Box>
      <Link to={providerFinderRootPath}>
        <Button variant="outlined" color="secondary" size="medium">
          <FormattedMessage defaultMessage="Reset location" />
        </Button>
      </Link>
    </Box>
  )
}
