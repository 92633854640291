import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form, FormDatePicker } from "@carrotfertility/carotene-core-x"
import {
  hasQuestionBeenSkipped,
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "components/carrot-plans/shared/useQueryRoutingInfo"
import { FormattedMessage, useIntl } from "react-intl"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  SkipButton,
  StepLayout
} from "components/carrot-plans/shared"
import { dayjs, Typography } from "@carrotfertility/carotene-core"
import { validateOptionalDate } from "services/common-forms"

export default function DueDateStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.dueDate == null ? null : routingData?.dueDate

  const { send } = useStateMachine(defaultValue || hasQuestionBeenSkipped("dueDate", routingData) ? "" : null)
  const intl = useIntl()

  async function onSkip() {
    await mutateAsync(
      {
        skipFields: ["dueDate"]
      },
      { onSuccess: () => send("") }
    )
  }
  async function onClickContinue({ dueDate }: { dueDate?: dayjs.Dayjs }): Promise<void> {
    if (dueDate?.isValid()) {
      await mutateAsync({ dueDate: dueDate.format("YYYY-MM-DD") })
      send("")
    } else {
      await onSkip()
    }
  }

  const header = intl.formatMessage({
    defaultMessage: "What is your due date?",
    description: "This relates to a pregnancy due date"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={header}
        description={
          <Typography>
            <FormattedMessage defaultMessage="This information will help us guide you to the best care." />
          </Typography>
        }
        formElements={
          <>
            <Form defaultValues={{ dueDate: dayjs(defaultValue) }} onSubmit={onClickContinue}>
              <FormDatePicker
                required={false}
                name="dueDate"
                controllerProps={{
                  rules: {
                    validate: (value: dayjs.Dayjs) => {
                      const result = validateOptionalDate(value)
                      if (typeof result !== "boolean") {
                        return intl.formatMessage(result)
                      }
                      return result
                    }
                  }
                }}
                label={intl.formatMessage({
                  defaultMessage: "Due date",
                  description: "This relates to a pregnancy due date"
                })}
              />
              <ButtonFooterLayout
                continueButton={
                  <ContinueButton>
                    <FormattedMessage defaultMessage="Continue" />
                  </ContinueButton>
                }
                skipButton={<SkipButton onClick={onSkip} />}
              />
            </Form>
          </>
        }
      />
    </>
  )
}
