import { useHistory } from "react-router-dom"
import React, { ReactNode, useEffect, FC } from "react"

type ScrollToTopProps = {
  children: ReactNode
}

const ScrollToTop: FC<ScrollToTopProps> = ({ children }) => {
  const {
    location: { pathname }
  } = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return <>{children}</>
}

export { ScrollToTop }
