import React, { useContext } from "react"
import { reportError, reportErrorMessage } from "../utils/ProviderFinderErrorReporting"

const ErrorReportingContext = React.createContext(null)
export const useErrorReportingContext = () => useContext(ErrorReportingContext)

// @ts-expect-error TS7031
export function ProviderFinderErrorReportingProvider({ children }): JSX.Element {
  return (
    <ErrorReportingContext.Provider
      value={{
        reportErrorMessage,
        reportError
      }}
    >
      {children}
    </ErrorReportingContext.Provider>
  )
}
