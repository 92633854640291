import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { EmployeeSupportUrl } from "../../../../utils/EmployeeSupportLink"
import { Box, DialogTitle, Link, Typography } from "@carrotfertility/carotene-core"

export function ErrorStep(): JSX.Element {
  const intl = useIntl()

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box
          component="img"
          src={`/images/exclamation-mark-sign.png`}
          srcSet={`/images/exclamation-mark-sign-2x.png`}
          maxHeight={160}
          maxWidth={"100%"}
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
          alt={intl.formatMessage({
            defaultMessage: "something's not working"
          })}
        />
      </Box>

      <DialogTitle align={"center"}>
        <FormattedMessage defaultMessage="Something's not working" />
      </DialogTitle>

      <Typography
        variant="body1"
        align={"center"}
        paddingLeft={(theme) => theme.spacing(theme.tokens.spacing.md)}
        paddingRight={(theme) => theme.spacing(theme.tokens.spacing.md)}
      >
        <FormattedMessage
          defaultMessage="Try reloading this page. If the problem persists, feel free to <link>contact us</link>."
          values={{
            link: (linkContent) => (
              <Link href={EmployeeSupportUrl} target={"_blank"}>
                {linkContent}
              </Link>
            )
          }}
        />
      </Typography>
    </>
  )
}
