import React, { FC, useEffect } from "react"
import { FlexContainer, Stack } from "@carrotfertility/carotene"
import { Typography } from "@carrotfertility/carotene-core"
import { Steps } from "../workflow/steps"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import { useProgressTracker } from "../../views/modal/ProgressTracker"
import { IlloCalendar } from "../../views/atoms/Illustrations"
import { BenefitEnrollmentModalContinueButton } from "../shared"
import { useBenefitEnrollment } from "../../context/enrollment/BenefitEnrollmentContext"
import { FormattedMessage } from "react-intl"

const ViewName = Steps.RE_ENROLL_INTRO_INFORMATIONAL

const ReEnrollIntroInsuranceFlow: FC = () => {
  const { needsBenefitEnrollment } = useBenefitEnrollment()
  const { setNextStep } = useStateMachine(ViewName, null)
  const { hideProgress } = useProgressTracker()

  useEffect(() => {
    hideProgress()
  }, [hideProgress])

  return (
    <>
      <Stack spacing="medium">
        <FlexContainer justifyContent="center" padding="none">
          <IlloCalendar height={228} width={228} />
        </FlexContainer>
        <Typography
          id="step-heading"
          variant="h2"
          color={(theme) => theme.palette.text.primary}
          paddingBottom={(theme) => theme.tokens.spacing.xs}
        >
          <FormattedMessage defaultMessage="To access your Carrot funds, update your health insurance information" />
        </Typography>
      </Stack>
      <Stack spacing="medium">
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="Your insurance plan information needs to be updated every year to maintain access to your Carrot funds." />
        </Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage
            defaultMessage="That's because your insurance coverage affects how your Carrot funds are taxed and if a deductible applies. 
        If you're on this journey with a partner, we'll need information about their coverage too. 
        You may want to have your insurance card(s) nearby."
          />
        </Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage
            defaultMessage="If we determine you and/or your partner have a deductible, it needs to be met before we can reimburse qualified medical expenses 
        — the kind the IRS doesn't tax. Other types of expenses can be reimbursed right away."
          />
        </Typography>
        <BenefitEnrollmentModalContinueButton
          onClick={() => setNextStep(ViewName, needsBenefitEnrollment ? "" : "SKIP_THIS_FLOW")}
        />
      </Stack>
    </>
  )
}

export { ReEnrollIntroInsuranceFlow }
