import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import { LocationDetails } from "../types/Location"
import ResponseError from "../types/ResponseError"
import { useProviderFinderClient } from "./useProviderFinderClient"
import { CountryCode } from "../../../content/CountryCode"

const LOCATION_DETAILS_QUERY_KEY = "GetLocationDetails"

const CITY_STATE_COUNTRY_CODES = [
  CountryCode.Hong_Kong,
  CountryCode.Monaco,
  CountryCode.Singapore,
  CountryCode.Macao,
  "VA",
  "GI"
]

export function useGetLocationDetails(locationString: string): UseQueryResult<LocationDetails, ResponseError> {
  const client = useProviderFinderClient()

  return useQuery<LocationDetails, ResponseError>(
    [LOCATION_DETAILS_QUERY_KEY, locationString],
    async () => {
      const response = await client.getLocationDetailsForAddress(locationString)
      if (response) {
        const { latitude, longitude, countryCode, city, administrativeLevels } = response
        const hasAdministrativeLevel2 = Boolean(administrativeLevels?.level2short)

        return {
          latitude,
          longitude,
          countryCode,
          isBroadArea: !(hasAdministrativeLevel2 || city || CITY_STATE_COUNTRY_CODES.includes(countryCode))
        }
      }
      return null
    },
    {
      refetchOnWindowFocus: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
