import { useCurrentUser } from "../context/user/UserContext"
import { useHistory } from "react-router"
import React, { useEffect } from "react"

function HelpCenterRedirect({ notLockedDownUrl }: { notLockedDownUrl: string }): JSX.Element {
  const { isInAppLockdown } = useCurrentUser()
  const history = useHistory()

  useEffect(() => {
    if (isInAppLockdown) {
      history.push("/get-help")
    } else {
      history.push(notLockedDownUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-depsx
  }, [isInAppLockdown])

  return <></>
}

function TalkToCarrotHelpCenter(): JSX.Element {
  return <HelpCenterRedirect notLockedDownUrl={"/talk-to-carrot"} />
}

export { TalkToCarrotHelpCenter }
