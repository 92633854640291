import React from "react"
import { CaseRateRenewalModalTopSection } from "./CaseRateRenewalModalTopSection"
import { Button, DialogActions, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"

export function ThanksForLettingUsKnowContent({ close }: { close: () => void }): JSX.Element {
  const intl = useIntl()
  return (
    <>
      <CaseRateRenewalModalTopSection
        header={intl.formatMessage({
          defaultMessage: "Thanks for letting us know"
        })}
        showImage
        imageAltText={intl.formatMessage({
          defaultMessage: "underwater dashboard"
        })}
        imageSrc="/images/illo-underwater-dashboard"
      />

      <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
        <FormattedMessage
          defaultMessage="You can still explore the Read & learn library, Benefit guide, and Help center. To access other features,
            like funds and expert chats, simply request a new plan."
        />
      </Typography>
      <DialogActions>
        <Button color="primary" onClick={close}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </DialogActions>
    </>
  )
}
