import React from "react"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useMedicalInfoWorkflow } from "../../hooks/useMedicalInfoFlow"
import { FemaleExperiencingSymptomsOptions, MaleExperiencingSymptomsOptions } from "./MedicalInfoOptions"
import {
  StepLayout,
  ButtonFooterLayout,
  ContinueButton,
  SkipButton,
  RoutingFlowCheckboxGroup,
  useConvertTranslatedLabel,
  RoutingFlowTitle
} from "components/carrot-plans/shared"
import { FormattedMessage, useIntl } from "react-intl"
import { Form } from "@carrotfertility/carotene-core-x"

type FormValues = {
  experiencingSymptoms?: string[]
}

export default function MedicalHistoryStep(): JSX.Element {
  const intl = useIntl()
  const { IsAMAB, IsAFAB } = useMedicalInfoWorkflow()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultValues = IsAMAB
    ? medicalInfo?.maleExperiencingSymptoms
    : IsAFAB
    ? medicalInfo?.femaleExperiencingSymptoms
    : null
  const buttonOptions = IsAMAB ? MaleExperiencingSymptomsOptions : IsAFAB ? FemaleExperiencingSymptomsOptions : null

  const options = useConvertTranslatedLabel(buttonOptions)
  const { send } = useStateMachine(defaultValues || hasQuestionBeenSkipped("experiencingSymptoms") ? "" : null)

  async function onClickContinue(values: FormValues): Promise<void> {
    const selected = values?.experiencingSymptoms
    const medicalInfoPayload = IsAMAB
      ? { maleExperiencingSymptoms: selected }
      : IsAFAB
      ? { femaleExperiencingSymptoms: selected }
      : {}
    await updateMedicalInfo(medicalInfoPayload)
    send("")
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["experiencingSymptoms"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Are you experiencing any of the following?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="experiencingSymptoms-label">{header}</span>}
        description={<FormattedMessage defaultMessage="Select any that apply." />}
        formElements={
          <Form
            defaultValues={{
              experiencingSymptoms: defaultValues || []
            }}
            onSubmit={onClickContinue}
          >
            <RoutingFlowCheckboxGroup
              name="experiencingSymptoms"
              aria-labelledby="experiencingSymptoms-label"
              options={options}
            />

            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
