import { RetinaImage } from "./RetinaImage"
import PropTypes from "prop-types"
import React, { FC, ImgHTMLAttributes } from "react"

type CircleImageProps = {
  iconName: string
  backgroundColor?: string
  size?: number
  padding?: number
} & ImgHTMLAttributes<HTMLImageElement>

const CircleImage: FC<CircleImageProps> = ({
  iconName,
  className,
  backgroundColor = "white",
  size = 40,
  padding = 8
}) => {
  const imageSize = size - padding * 2
  return (
    <div
      className={`${className || ""} flex items-center justify-center bg-${backgroundColor} br-100`}
      style={{ height: size, width: size }}
    >
      <RetinaImage name={iconName} height={imageSize} width={imageSize} />
    </div>
  )
}

CircleImage.propTypes = {
  iconName: PropTypes.string.isRequired
}
export { CircleImage }
