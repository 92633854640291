type HeapPropertyValue = number | string

interface Heap {
  track: (event: string, properties?: Record<string, HeapPropertyValue>) => void
  identify: (identity: string) => void
  resetIdentity: () => void
  addUserProperties: (properties: Record<string, HeapPropertyValue>) => void
  addEventProperties: (properties: Record<string, HeapPropertyValue>) => void
  removeEventProperty: (property: string) => void
  clearEventProperties: () => void
  appid: string
  userId: string
  identity: string | null
  config: Record<string, HeapPropertyValue>
}

export const getHeap = (): Heap => window.heap
