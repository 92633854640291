import { FormattedMessage, useIntl } from "react-intl"
import { getPiiProps } from "../../../../../services/tracking"
import { DbgPaths } from "../../../../../utils/Paths"
import { GetCategoryName } from "../../ReimbursementCategoryMessages"
import React from "react"
import { Legend, ReimbursementsListSection, ReimbursementSummary } from "../SharedReimbursementElements"
import {
  getCategoryPendingReimbursementsList,
  getCompletedReimbursementsList,
  getReceivedReimbursementsList,
  getReimbursementsRequiringActionList
} from "../ReimbursementListUtils"
import { RetinaImage } from "../../../atoms/images/RetinaImage"
import { PlanInfoBadgeContainer } from "../../../../containers/planinfo/PlanInfoBadgeContainer"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  DownIcon,
  Link,
  Typography
} from "@carrotfertility/carotene-core"
import { ReimbursementDescriptionGroup, ReimbursementDescriptionList } from "../ReimbursementDescriptionList"
import { PayingForCareCoverageModal } from "../../digital-benefit-guide/PayingForCareModal"
import { ExpenseState } from "../../../../../utils/ExpenseState"
import { getCategoryCoverage } from "../../../../../utils/CategoryCoverage"
import { TextDirection } from "types/Locale"
import { useTextDirection } from "../../../../../services/locale/hooks/useTextDirection"

const ConditionalBadge = ({ color, variant, overlap, badgeContent, children }) => {
  const textDirection = useTextDirection()

  return badgeContent ? (
    <Badge
      anchorOrigin={{ vertical: "top", horizontal: textDirection === TextDirection.RightToLeft ? "left" : "right" }}
      color={color}
      variant={variant}
      overlap={overlap}
      badgeContent={badgeContent}
    >
      {children}
    </Badge>
  ) : (
    <>{children}</>
  )
}

const Category = ({ disabled = false, defaultExpanded = false, icon, name, id, summary, details }) => {
  return (
    <Accordion
      disabled={disabled}
      defaultExpanded={defaultExpanded}
      sx={(theme) => ({ paddingBlockEnd: theme.tokens.spacing.md })}
    >
      <AccordionSummary expandIcon={<DownIcon fontSize="large" />} aria-controls={id} id={id}>
        <Box display="flex" flexDirection="row">
          {icon}
        </Box>
        <Box display="flex" flexDirection="column" paddingLeft={(theme) => theme.tokens.spacing.xs}>
          <Typography variant="h4" paddingBottom={(theme) => theme.tokens.spacing.md}>
            {name}
          </Typography>
          {summary}
        </Box>
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </Accordion>
  )
}

const WaitingReimbursementList = ({ reimbursements }) => {
  const reimbursementsRequiringAction = getReimbursementsRequiringActionList(reimbursements)
  const receivedReimbursements = getReceivedReimbursementsList(reimbursements)
  const intl = useIntl()
  const textDirection = useTextDirection()

  return (
    <Category
      disabled={reimbursementsRequiringAction.length + receivedReimbursements.length === 0}
      defaultExpanded={reimbursementsRequiringAction.length > 0}
      icon={
        <Box
          padding={(theme) => theme.tokens.spacing.xxs}
          borderRadius={(theme) => theme.tokens.borderRadius.round}
          height="32px"
          width="32px"
          sx={(theme) => ({ backgroundColor: theme.palette.success.light })}
        >
          <ConditionalBadge
            color="error"
            variant="dot"
            overlap="circular"
            badgeContent={reimbursementsRequiringAction.length}
          >
            <RetinaImage name="icn-expenses-pending" height={24} width={24} />
          </ConditionalBadge>
        </Box>
      }
      name={<FormattedMessage defaultMessage="Waiting to be processed" />}
      id="processing"
      summary={
        <ReimbursementDescriptionList>
          <ReimbursementDescriptionGroup
            title={<FormattedMessage defaultMessage="# of expenses" />}
            value={reimbursementsRequiringAction.length + receivedReimbursements.length}
          />
          <ReimbursementDescriptionGroup
            title={
              <ConditionalBadge color="error" variant="dot" badgeContent={reimbursementsRequiringAction.length}>
                <FormattedMessage defaultMessage="Need your attention" />
              </ConditionalBadge>
            }
            value={reimbursementsRequiringAction.length}
          />
        </ReimbursementDescriptionList>
      }
      details={
        <>
          <Legend />
          {reimbursementsRequiringAction.length > 0 && (
            <ReimbursementsListSection
              title={
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: textDirection === TextDirection.RightToLeft ? "left" : "right"
                  }}
                  badgeContent={reimbursementsRequiringAction.length}
                  color="error"
                >
                  <FormattedMessage defaultMessage="Needs Attention" />
                </Badge>
              }
              emptyText={intl.formatMessage({ defaultMessage: "No transactions need attention" })}
              items={reimbursementsRequiringAction}
              showAlert={reimbursementsRequiringAction.find(
                (e) => e.expenseState === ExpenseState.NEEDS_MEMBER_UPLOADS
              )}
            />
          )}
          {receivedReimbursements.length > 0 && (
            <ReimbursementsListSection
              title={intl.formatMessage({ defaultMessage: "Received" })}
              emptyText={intl.formatMessage({ defaultMessage: "No received transactions" })}
              items={receivedReimbursements}
            />
          )}
        </>
      }
    />
  )
}

const CategoryReimbursementList = ({ reimbursements, subsidy, allSubsidies }) => {
  const reimbursementsPending = getCategoryPendingReimbursementsList(reimbursements)
  const reimbursementsCompleted = getCompletedReimbursementsList(reimbursements)
  const intl = useIntl()
  const hasQmeCategory = getCategoryCoverage(allSubsidies).qme
  const categoryName = GetCategoryName(subsidy.reimbursementCategoryNameMessageKey, hasQmeCategory)
  return (
    <Category
      disabled={reimbursementsPending.length + reimbursementsCompleted.length === 0}
      icon={<RetinaImage name={subsidy.reimbursementCategoryIconName || "icn-expenses-other"} height={32} width={32} />}
      name={categoryName}
      id={categoryName}
      summary={
        <>
          <PayingForCareCoverageModal element={subsidy} allSubsidies={allSubsidies} />
          <PlanInfoBadgeContainer
            subsidy={subsidy}
            allSubsidies={allSubsidies}
            sx={(theme) => ({ paddingBlockStart: theme.tokens.spacing.xs })}
          />
          <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)} />
          <ReimbursementSummary
            subsidy={subsidy}
            reimbursementsCount={reimbursementsPending.length + reimbursementsCompleted.length}
            showRemainingAnnual={allSubsidies.some(
              (subsidy) => subsidy.yearlyAmounts.max !== subsidy.lifetimeAmounts.max
            )}
            showRemainingLifetime={allSubsidies.some((subsidy) => subsidy.lifetimeAmounts.max !== null)}
          />
        </>
      }
      details={
        <>
          <Legend />
          {reimbursementsPending.length > 0 && (
            <ReimbursementsListSection
              title={intl.formatMessage({ defaultMessage: "Pending" })}
              items={reimbursementsPending}
            />
          )}
          {reimbursementsCompleted.length > 0 && (
            <ReimbursementsListSection
              title={intl.formatMessage({ defaultMessage: "Completed" })}
              items={reimbursementsCompleted}
            />
          )}
        </>
      }
    />
  )
}

export const MultipleCategoriesReimbursementsLists = ({ reimbursementsList, subsidies }) => {
  const hasQmeCategory = getCategoryCoverage(subsidies).qme
  return (
    <div {...getPiiProps()}>
      <Typography variant="body1" color="text.secondary" paddingBottom={(theme) => theme.tokens.spacing.md}>
        <FormattedMessage defaultMessage="Your employer offers separate fund categories for different kinds of eligible care and services. Funds from one category cannot be transferred to another." />
      </Typography>
      <Typography variant="body1" color="text.secondary" paddingBottom={(theme) => theme.tokens.spacing.lg}>
        <FormattedMessage
          defaultMessage="Check your <link>benefit guide</link> to learn what's covered."
          values={{
            link: (linkContent) => (
              <Link href={DbgPaths.PAYING_FOR_CARE} color="inherit">
                {linkContent}
              </Link>
            )
          }}
        />
      </Typography>
      <WaitingReimbursementList reimbursements={reimbursementsList} />
      {subsidies.map((subsidy) => {
        const reimbursements = reimbursementsList.filter(
          (r) => r.expensePlanCategoryNameMessageKey === subsidy.reimbursementCategoryNameMessageKey
        )
        const categoryNameMessageKey = subsidy.reimbursementCategoryNameMessageKey
        const categoryName = GetCategoryName(categoryNameMessageKey, hasQmeCategory)
        return (
          <CategoryReimbursementList
            reimbursements={reimbursements}
            subsidy={subsidy}
            allSubsidies={subsidies}
            key={categoryName}
          />
        )
      })}
    </div>
  )
}
