import React, { useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Button, Stack, Typography } from "@carrotfertility/carotene-core"
import { useHistory } from "react-router-dom"
import { useSendMessagePath } from "./hooks/useSendMessagePath"
import { getHeap } from "../../pages/provider-finder/utils/heap"

export function TalkToCarrotCalendlyError(): JSX.Element {
  const intl = useIntl()
  const history = useHistory()
  const sendMessagePath = useSendMessagePath()
  const headerText = intl.formatMessage({ defaultMessage: "Something's not working" })
  const altText = intl.formatMessage({ defaultMessage: "error sign" })

  useEffect(() => {
    getHeap().track("Talk to Carrot Calendly Error Page rendered")
  }, [])

  return (
    <Stack alignItems={"center"}>
      <Box
        component="img"
        src={`/images/error-sign.png`}
        srcSet={`/images/error-sign.png`}
        alt={altText}
        marginTop={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
        marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
        width={300}
      />
      <Stack alignItems={"center"} maxWidth={400}>
        <Typography variant="h2" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          {headerText}
        </Typography>
        <Typography marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xl)} textAlign={"center"}>
          <FormattedMessage defaultMessage="Try reloading this page. If the problem persists, please contact us so we can schedule your chat." />
        </Typography>
      </Stack>
      <Box display="flex" gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => history.push(sendMessagePath)}
          data-analytics-id="talk-to-carrot-calendly-error-contact-link"
        >
          <FormattedMessage defaultMessage="Contact us" />
        </Button>
        <Button onClick={() => window.location.reload()} data-analytics-id="talk-to-carrot-calendly-error-reload">
          <FormattedMessage defaultMessage="Reload page" />
        </Button>
      </Box>
    </Stack>
  )
}
