import React from "react"
import { Box, Typography, useTheme } from "@carrotfertility/carotene-core"
import { DigitalFrontDoorSectionLayout } from "./DigitalFrontDoorSectionLayout"
import { FormattedMessage } from "react-intl"
import { useDigitalFrontDoorBreakpointViews } from "../hooks/useDigitalFrontDoorBreakpointViews"

export function WhyMembersLoveCarrotRxSection() {
  const theme = useTheme()

  const { isMobileView, isAboveXLScreenView } = useDigitalFrontDoorBreakpointViews()

  return (
    <DigitalFrontDoorSectionLayout backgroundColor={theme.palette.text.secondary}>
      <Box paddingY={isAboveXLScreenView ? "7.5rem" : theme.tokens.spacing.xxxl}>
        <Typography
          marginBottom={isAboveXLScreenView ? theme.tokens.spacing.xxl : theme.tokens.spacing.lg}
          color={theme.palette.secondary.contrastText}
          variant="h2"
          fontSize={isMobileView ? "1.75rem" : "2.625rem"}
        >
          <FormattedMessage defaultMessage="Why members love Carrot Rx" />
        </Typography>
        <Box display="flex" flexDirection={isAboveXLScreenView ? "row" : "column-reverse"} maxWidth="100%">
          <Box
            component="img"
            src={require("../resources/images/carrot-rx-testimonial.jpg")}
            alt=""
            width={isAboveXLScreenView ? "50%" : "100%"}
            borderRadius={isAboveXLScreenView ? "2rem 0 0 2rem" : "0 0 0.75rem 0.75rem"}
            sx={{ objectFit: "cover" }}
          />
          <Box
            paddingLeft={isAboveXLScreenView ? "6.5rem" : theme.tokens.spacing.lg}
            paddingY={isMobileView ? theme.tokens.spacing.xl : "3.5rem"}
            paddingRight={isAboveXLScreenView ? "3.5rem" : theme.tokens.spacing.lg}
            width={isAboveXLScreenView ? "50%" : "100%"}
            borderRadius={isAboveXLScreenView ? "0 2rem 2rem 0" : " 0.75rem 0.75rem 0 0"}
            sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
          >
            <Box
              component="img"
              height={isAboveXLScreenView ? "1.5rem" : "1rem"}
              marginBottom={isAboveXLScreenView ? theme.tokens.spacing.md : theme.tokens.spacing.sm}
              src={require("../resources/images/carrot-rx-testimonial-quote.png")}
              alt="Testimonial from Carrot member"
            />
            <Typography
              color={theme.palette.text.secondary}
              fontSize={isMobileView ? "1rem" : "1.5rem"}
              aria-hidden="true"
              marginBottom={theme.tokens.spacing.md}
            >
              <FormattedMessage
                defaultMessage={`I used Alto for my egg-freezing medications and it was the most cost-effective option. I was pleasantly
            surprised by ease of use and the UX of the app and website. The sea salt caramel in every order is SUCH a
            nice touch. The speed of customer support was also a huge plus.`}
              />
            </Typography>
            <Typography
              color={theme.palette.text.secondary}
              fontSize={isMobileView ? "1rem" : "1.5rem"}
              aria-hidden="true"
            >
              <FormattedMessage defaultMessage={`- Carrot member`} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </DigitalFrontDoorSectionLayout>
  )
}
