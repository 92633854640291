import { ExpenseState } from "../../../../utils/ExpenseState"

export const getReimbursementsRequiringActionList = (reimbursements) => {
  return reimbursements
    ? reimbursements.filter(
        (x) =>
          x.expenseState === ExpenseState.NEEDS_MEMBER_UPLOADS ||
          (x.isCardExpense &&
            (x.expenseState === ExpenseState.DENIED_RECONCILIATION_REQUESTED ||
              x.expenseState === ExpenseState.PENDING_MEMBER_INFO))
      )
    : []
}

export const getPendingReimbursementsList = (reimbursements) => {
  return reimbursements
    ? reimbursements.filter(
        (x) =>
          (!x.isCardExpense &&
            (x.expenseState === ExpenseState.RECEIVED || x.expenseState === ExpenseState.APPROVED)) ||
          (x.isCardExpense && x.expenseState === ExpenseState.PENDING_CARE_NAV) ||
          x.expenseState === ExpenseState.ON_HOLD
      )
    : []
}

export const getCategoryPendingReimbursementsList = (reimbursements) => {
  return reimbursements
    ? reimbursements.filter(
        (x) =>
          (!x.isCardExpense &&
            ((x.expenseState === ExpenseState.RECEIVED && x.hasLineItems) ||
              x.expenseState === ExpenseState.APPROVED)) ||
          (x.isCardExpense && x.expenseState === ExpenseState.PENDING_CARE_NAV) ||
          x.expenseState === ExpenseState.ON_HOLD
      )
    : []
}

export const getReceivedReimbursementsList = (reimbursements) => {
  return reimbursements
    ? reimbursements.filter((x) => !x.isCardExpense && x.expenseState === ExpenseState.RECEIVED && !x.hasLineItems)
    : []
}

export const getCompletedReimbursementsList = (reimbursements) => {
  return reimbursements
    ? reimbursements.filter(
        (x) =>
          (!x.isCardExpense && (x.expenseState === ExpenseState.DENIED || x.expenseState === ExpenseState.PAID)) ||
          (x.isCardExpense &&
            (x.expenseState === ExpenseState.APPROVED || x.expenseState === ExpenseState.DENIED_RECONCILED))
      )
    : []
}
