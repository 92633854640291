import React from "react"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { Container, Stack, FlexContainer, Form, SlidingDotsLoader } from "@carrotfertility/carotene"
import useAboutYou, { formAboutYouAddressPayload } from "components/carrot-plans/hooks/useAboutYou"
import { AboutYou } from "types/carrotPlanTypes"
import { ReimbursementFormInternationalAddress } from "features/legacy-components/AboutYouFormInternationalAddress"
import { FormArrowSubmitButton } from "features/legacy-components/ArrowSubmitButton"
import { FormattedMessage } from "react-intl"
import { DialogContentText, DialogTitle } from "@carrotfertility/carotene-core"

function canSkip(data: AboutYou): boolean {
  return (
    Boolean(data?.address?.internationalAddressLine) &&
    Boolean(data?.address?.internationalCountry) &&
    Boolean(data?.address?.city) &&
    Boolean(data?.address?.state)
  )
}

export const InternationalAddressUpdate = (): JSX.Element => {
  const { data, isLoading, updateAboutYou } = useAboutYou()
  const { send } = useStateMachine(canSkip(data) ? "" : null)

  // @ts-expect-error TS7006
  async function onSubmit(data): Promise<void> {
    const aboutYouUpdate = formAboutYouAddressPayload(data)
    await updateAboutYou(aboutYouUpdate)
    send("")
  }
  return (
    <Container stack="48px">
      {isLoading ? (
        <FlexContainer direction="column" justifyContent="center">
          <SlidingDotsLoader />
        </FlexContainer>
      ) : (
        <>
          <DialogTitle>
            <FormattedMessage defaultMessage="Confirm your address" />
          </DialogTitle>
          <DialogContentText paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
            <FormattedMessage defaultMessage="Please confirm or update your address so that we can reimburse you." />
          </DialogContentText>
          <Form onSubmit={onSubmit}>
            <Stack spacing="medium">
              <ReimbursementFormInternationalAddress
                defaultValues={{
                  city: data?.address?.city,
                  state: data?.address?.state,
                  zip: data?.address?.zip,
                  internationalCountry: data?.address?.internationalCountry,
                  internationalAddressLine: data?.address?.internationalAddressLine
                }}
              />
              <FlexContainer justifyContent="flex-end">
                <FormArrowSubmitButton>
                  <FormattedMessage defaultMessage="Continue" />
                </FormArrowSubmitButton>
              </FlexContainer>
            </Stack>
          </Form>
        </>
      )}
    </Container>
  )
}
