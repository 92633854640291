import actionTypes from "actions/actionTypes"
import { createIsLoadedReducer, createReducer } from "reducers/shared"
import { combineReducers } from "redux"
import { RootState } from "reducers/index"

const reducers = {
  isLoaded: createIsLoadedReducer("FETCH_VIRTUAL_CARD_DETAILS"),
  cardDetails: createReducer(null, {
    // @ts-expect-error TS7006
    [actionTypes.FETCH_VIRTUAL_CARD_DETAILS_SUCCESS]: (state, action) => action.response
  })
}

const cardDetails = combineReducers(reducers)

export const getCardDetails = (state: RootState) => state.cardDetails

export default cardDetails
