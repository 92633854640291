import { Box, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { defaultMarkdownElements } from "services/markdown"

export const CalloutBox = ({
  backgroundColor,
  title,
  children
}: {
  backgroundColor?: string
  title?: string
  children: React.ReactNode
}): JSX.Element => {
  const theme = useTheme()
  const legacySupportedColors = {
    lightBlue: theme.palette.info.light,
    veryLightCarrotOrange: theme.palette.primary.light,
    mintAlternate: theme.palette.success.light,
    lightPampas: theme.palette.background.default
  }

  return (
    <Box
      {...defaultMarkdownElements.blockquote.props}
      // @ts-expect-error TS7053
      bgcolor={legacySupportedColors[backgroundColor] ?? theme.palette.primary.light}
    >
      {title && (
        <defaultMarkdownElements.h4.component {...defaultMarkdownElements.h4.props}>
          {title}
        </defaultMarkdownElements.h4.component>
      )}
      {children}
    </Box>
  )
}
