import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Dialog, DialogContent, DialogProps } from "@carrotfertility/carotene-core"

import {
  StateMachineProvider,
  StateMachineStepView as RequestCardView
} from "components/context/stateMachine/StateMachineV2"
import EmployeeOnlyModalContainer from "components/employee-only-containers/EmployeeOnlyModalContainer"

import { CountryCode } from "content/CountryCode"
import { requestCardSteps } from "pages/request-carrot-card-flow/workflows/step-library"
import { requestCardWorkflows } from "pages/request-carrot-card-flow/workflows/workflows"
import { getIsUsa } from "reducers/userInfo"
import { useIntl } from "react-intl"

import { getIsAvenuesMember, getIsRomanHealthNYMandateMember, getIsNBAMember, getIsWNBAMember } from "derivedSelectors"
import { DialogCloseButton } from "@carrotfertility/carotene-core-x"
import { getDesiredWorkflow, getInitialStep, getShouldSeeUserAgreement } from "./workflows/routing-helpers/helpers"
import { CarrotCardProvider } from "services/reimbursements/types/ReimbursementDetails"
import { getCountryCode } from "reducers/companyInfo"

type RequestCardContextProps = {
  isUsa?: boolean
  phoneNumber?: string
  phoneCountryCode?: CountryCode
  companyCountryCode?: CountryCode
  completeConfirmPhoneNumber?: (phoneNumber: string, countryCode: CountryCode, verificationCode?: string) => void
  verificationCode?: string
  showInformationScreen?: boolean
  onClose?: () => void
  shouldSeeUserAgreement?: boolean
}

const RequestCardContext = React.createContext<RequestCardContextProps>({})

type RequestCardContainerState = {
  isUsaCompany?: boolean
  phoneNumber?: string
  countryCode?: CountryCode
  verificationCode?: string
  showInformationScreen?: boolean
  onClose?: () => void
}

const RequestCardContainer = ({
  open,
  onExit,
  provider
}: Pick<DialogProps, "open"> & { onExit: () => void; provider: CarrotCardProvider }) => {
  const [state, setState] = useState<RequestCardContainerState>({})

  const completeConfirmPhoneNumber = (phoneNumber: string, countryCode: CountryCode, verificationCode: string) => {
    return setState({ ...state, phoneNumber, countryCode, verificationCode })
  }
  const intl = useIntl()

  const isUsa = useSelector(getIsUsa)
  const countryCode = useSelector(getCountryCode)
  const isAvenuesMember = useSelector(getIsAvenuesMember)
  const isNBAMember = useSelector(getIsNBAMember)
  const isRomanHealthNYMandateMember = useSelector(getIsRomanHealthNYMandateMember)
  const isWNBAMember = useSelector(getIsWNBAMember)

  const showInformationScreen = isAvenuesMember || isNBAMember || isRomanHealthNYMandateMember || isWNBAMember

  const contextualInformation = { isUsa, showInformationScreen, companyCountryCode: countryCode }
  const workflow = getDesiredWorkflow(provider, contextualInformation)
  const requestCardSection = {
    name: "RequestCard",
    initialStep: getInitialStep(workflow, contextualInformation),
    desiredWorkflow: workflow,
    steps: requestCardSteps,
    workflows: requestCardWorkflows
  }

  const context: RequestCardContextProps = {
    completeConfirmPhoneNumber: completeConfirmPhoneNumber,
    phoneNumber: state.phoneNumber,
    phoneCountryCode: state.countryCode,
    verificationCode: state.verificationCode,
    onClose: onExit,
    companyCountryCode: countryCode,
    isUsa,
    showInformationScreen,
    shouldSeeUserAgreement: getShouldSeeUserAgreement(provider, contextualInformation)
  }

  return (
    <RequestCardContext.Provider value={context}>
      <StateMachineProvider
        initialSection="RequestCard"
        stateMachineDescription={[requestCardSection]}
        onComplete={onExit}
      >
        <EmployeeOnlyModalContainer onClose={onExit}>
          <Dialog open={open} onClose={onExit}>
            <DialogCloseButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={onExit} />
            <DialogContent>
              <RequestCardView />
            </DialogContent>
          </Dialog>
        </EmployeeOnlyModalContainer>
      </StateMachineProvider>
    </RequestCardContext.Provider>
  )
}

export { RequestCardContext, RequestCardContainer }
