import React from "react"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { Form } from "@carrotfertility/carotene-core-x"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  SkipButton,
  StepLayout,
  useConvertTranslatedLabel
} from "components/carrot-plans/shared"
import { FormRadioButtonGroup } from "services/common-forms"

export default function PregnantPreviouslyStep(): JSX.Element {
  const intl = useIntl()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultValue = medicalInfo?.pregnantPreviously

  const { send } = useStateMachine(hasQuestionBeenSkipped("pregnantPreviously") ? "" : defaultValue)
  const buttonOptions = [
    { label: defineMessage({ defaultMessage: "Yes" }), value: "YES" },
    { label: defineMessage({ defaultMessage: "No" }), value: "NO" }
  ]
  const radioOptions = useConvertTranslatedLabel(buttonOptions)

  async function onClickContinue(formValues: { pregnantPreviously: string }): Promise<void> {
    if (formValues.pregnantPreviously !== null) {
      await updateMedicalInfo({ pregnantPreviously: formValues.pregnantPreviously })
      send(formValues.pregnantPreviously)
    } else {
      await onSkip()
    }
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["pregnantPreviously"] })
    send("")
  }
  const header = intl.formatMessage({
    defaultMessage: "Have you ever been pregnant previously?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="pregnantPreviously-label">{header}</span>}
        formElements={
          <Form onSubmit={onClickContinue} defaultValues={{ pregnantPreviously: defaultValue }}>
            <FormRadioButtonGroup
              name="pregnantPreviously"
              options={radioOptions}
              aria-labelledby="pregnantPreviously-label"
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
