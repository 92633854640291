import { Box, Theme, Typography, useMediaQuery } from "@carrotfertility/carotene-core"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import CarrotMemberWithCircleEnglish from "../resources/images/carrot-member-blue-circle-english.png"
import CarrotMemberWithCircleSpanish from "../resources/images/carrot-member-blue-circle-spanish.png"

export function CarrotMemberImageGroup(): JSX.Element {
  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
  const intl = useIntl()
  const { locale } = intl
  return (
    <Box display="flex" flex={1.5}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
          minWidth={isMobileView ? "178px" : "250px"}
          alignSelf="flex-end"
          flex={3}
          component="img"
          src={locale === "es-US" ? CarrotMemberWithCircleSpanish : CarrotMemberWithCircleEnglish}
          alt=""
        />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        flex={1}
        maxWidth={isMobileView ? "8rem" : "13rem"}
      >
        <Box>
          <Typography fontSize={isMobileView ? "3rem" : "5.75rem"}>
            <FormattedMessage defaultMessage="40%" />
          </Typography>
          <Typography fontSize={isMobileView ? "0.75rem" : "0.875rem"}>
            <FormattedMessage defaultMessage="Potential savings on fertility medications" />
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
