import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "components/carrot-plans/shared/useQueryRoutingInfo"
import { useParams } from "react-router-dom"
import { AmabKeys } from "components/carrot-plans/hooks/useMedicalQuestionMenuItem"

enum AmabSteps {
  LOW_TESTOSTERONE = "LOW_TESTOSTERONE",
  PRESERVATION = "NOT_LOW_T",
  ASSISTED_REPRODUCTION = "NOT_LOW_T",
  TRY_PREGNANT = "NOT_LOW_T"
}

export default function WhatKindOfJourneyAmabDecisionStep(): JSX.Element {
  const routingData = useCheckRoutingInfoQueryCache()
  const { journey } = useParams<{ journey: string }>()
  // @ts-expect-error TS7053
  const journeyValue = journey ? AmabKeys[journey] : AmabSteps[routingData?.journey]
  const { send } = useStateMachine()

  useEffect(() => {
    send(journeyValue)
  })
  return <></>
}
