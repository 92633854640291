import React, { FC, ReactNode } from "react"

type WrapperProps = {
  small?: boolean
  smallMedium?: boolean
  medium?: boolean
  mediumLarge?: boolean
  children: ReactNode
}

const Wrapper: FC<WrapperProps> = (props) => {
  const size = props.small
    ? "mw6"
    : props.smallMedium
    ? "mw65"
    : props.medium
    ? "mw7"
    : props.mediumLarge
    ? "mw75"
    : "mw85"
  return (
    <div className="w-100 center mw85 flex justify-center items-center pv3-ns ph3">
      <div className={`w-100 ${size} pv3-ns pv2 ph3-ns`}>{props.children}</div>
    </div>
  )
}

export default Wrapper
