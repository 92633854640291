import { combineReducers } from "redux"
import actionTypes from "actions/actionTypes"
import { createIsLoadedReducer, createReducer } from "./shared"
import moment from "moment"
import TestUserActionTypes from "actions/testUserActionTypes"

const { FETCH_DEDUCTIBLE_STATUS_SUCCESS } = actionTypes
const { TOGGLE_EXTERNAL_DEDUCTIBLE } = TestUserActionTypes

const reducers = {}

reducers.loaded = createIsLoadedReducer("FETCH_DEDUCTIBLE_STATUS")

reducers.displayDeductibleBreakdown = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state, action) => action.response.displayDeductibleBreakdown
})

reducers.sourceName = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state, action) => action.response?.sourceName ?? null,
  [TOGGLE_EXTERNAL_DEDUCTIBLE]: (state) =>
    state === "ExternallyManagedDeductibleSource"
      ? "CarrotSpecificDeductibleSource"
      : "ExternallyManagedDeductibleSource"
})

reducers.amountAppliedToDeductible = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state, action) =>
    action.response?.deductibleSnapshot?.amountAppliedToDeductible ?? null
})

reducers.carrotAmountAppliedToDeductible = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state, action) =>
    action.response?.deductibleSnapshot?.carrotAmountAppliedToDeductible ?? null
})

reducers.externalAmountAppliedToDeductible = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state, action) =>
    action.response?.deductibleSnapshot?.externalAmountAppliedToDeductible ?? null
})

reducers.deductibleAmount = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state, action) => action.response?.deductibleSnapshot?.totalAmount ?? null
})

reducers.deductibleRemaining = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state, action) => action.response?.deductibleSnapshot?.amountRemaining ?? null
})

reducers.planStartDate = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state, action) =>
    action.response?.deductibleSnapshot?.planStartDate
      ? new moment(action.response?.deductibleSnapshot?.planStartDate)
      : null
})

reducers.planEndDate = createReducer(null, {
  [FETCH_DEDUCTIBLE_STATUS_SUCCESS]: (state, action) =>
    action.response?.deductibleSnapshot?.planEndDate
      ? new moment(action.response?.deductibleSnapshot?.planEndDate)
      : null
})

const deductibleStatus = combineReducers(reducers)

export const getDisplayDeductibleBreakdown = (state) => state.deductibleStatus.displayDeductibleBreakdown
export const getSourceName = (state) => state.deductibleStatus.sourceName
export const getAmountAppliedToDeductible = (state) => state.deductibleStatus.amountAppliedToDeductible
export const getCarrotAmountAppliedToDeductible = (state) => state.deductibleStatus.carrotAmountAppliedToDeductible
export const getExternalAmountAppliedToDeductible = (state) => state.deductibleStatus.externalAmountAppliedToDeductible
export const getDeductibleAmount = (state) => state.deductibleStatus.deductibleAmount
export const getDeductibleRemaining = (state) => state.deductibleStatus.deductibleRemaining
export const getPlanStartDate = (state) => state.deductibleStatus.planStartDate
export const getPlanEndDate = (state) => state.deductibleStatus.planEndDate
export const getDeductibleStatus = (state) => state.deductibleStatus

export default deductibleStatus
