import React, { FC, ReactNode } from "react"
import { Spacer } from "../Atoms"
import { CircleImage } from "../images/CircleImage"

type RRIconBarProps = {
  iconName: string
  iconBackgroundColor: string
  label?: ReactNode
}

const RRIconBar: FC<RRIconBarProps> = ({ iconName, iconBackgroundColor, label }) => (
  <>
    <div className="flex flex-row justify-between items-center">
      <CircleImage iconName={iconName} backgroundColor={iconBackgroundColor} />
      {label}
    </div>
    <Spacer height={0.5} />
  </>
)

export { RRIconBar }
