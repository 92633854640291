import { createSelector } from "reselect"
import { getDeductibleStatus } from "reducers/userInfo"
import { DeductibleStatus } from "carrot-api/types/DeductibleStatus"
import { getDeductibleRemaining, getSourceName } from "reducers/deductibleStatus"

const getHasDeductible = createSelector([getDeductibleStatus], (deductibleStatus: DeductibleStatus) => {
  return deductibleStatus ? deductibleStatus !== DeductibleStatus.NoDeductible : null
})

const getHasDeductibleRemaining = createSelector(
  [getHasDeductible, getDeductibleRemaining],
  (hasDeductible, deductibleRemaining) => {
    return hasDeductible && (deductibleRemaining === null || deductibleRemaining > 0)
  }
)

const getCompanyHasCarrotManagedDeductibleEnabled = createSelector([getSourceName], (deductibleSourceName) => {
  return deductibleSourceName === "CarrotSpecificDeductibleSource"
})

const getCompanyHasExternallyManagedDeductibleEnabled = createSelector([getSourceName], (deductibleSourceName) => {
  return deductibleSourceName === "ExternallyManagedDeductibleSource"
})

export {
  getHasDeductible,
  getHasDeductibleRemaining,
  getCompanyHasCarrotManagedDeductibleEnabled,
  getCompanyHasExternallyManagedDeductibleEnabled
}
