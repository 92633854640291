import React from "react"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { PelvicSurgeryDetails } from "./MedicalInfoOptions"
import {
  StepLayout,
  ButtonFooterLayout,
  ContinueButton,
  SkipButton,
  RoutingFlowCheckboxGroup,
  useConvertTranslatedLabel,
  RoutingFlowTitle
} from "components/carrot-plans/shared"
import { FormattedMessage, useIntl } from "react-intl"
import { Form } from "@carrotfertility/carotene-core-x"

type FormValues = {
  pelvicSurgeryDetails?: string[]
}

export default function PelvicSurgeryDetailsStep(): JSX.Element {
  const intl = useIntl()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultValue = medicalInfo?.pelvicSurgeryDetails

  const { send } = useStateMachine(defaultValue || hasQuestionBeenSkipped("pelvicSurgeryDetails") ? "" : null)

  const options = useConvertTranslatedLabel(PelvicSurgeryDetails)

  async function onClickContinue(values: FormValues): Promise<void> {
    await updateMedicalInfo({ pelvicSurgeryDetails: values?.pelvicSurgeryDetails })
    send("")
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["pelvicSurgeryDetails"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "What type of pelvic or abdominal surgery did you have?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="pelvicSurgeryDetails-label">{header}</span>}
        description={<FormattedMessage defaultMessage="Select any that apply." />}
        formElements={
          <Form
            defaultValues={{
              pelvicSurgeryDetails: defaultValue || []
            }}
            onSubmit={onClickContinue}
          >
            <RoutingFlowCheckboxGroup
              name="pelvicSurgeryDetails"
              aria-labelledby="pelvicSurgeryDetails-label"
              options={options}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
