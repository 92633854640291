import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Breadcrumbs, Link, Stack, Typography } from "@carrotfertility/carotene-core"

import { TalkToCarrotLegal } from "components/talk-to-carrot/TalkToCarrotLegal"
import { RootBreadcrumb } from "components/talk-to-carrot/util/talkToCarrotMolecules"
import { SlidingDotsLoader } from "@carrotfertility/carotene"
import { useGetPhoneSupportNumberForUser } from "components/dynamic-content/hooks/useQueryPhoneSupport"
import { PhoneSupportNumberLink } from "services/contentful/custom-components/phone-support-number-elements"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header"

export function ErrorPage(): JSX.Element {
  const intl = useIntl()
  const {
    data: phoneSupportNumber,
    isLoading: isPhoneSupportLoading,
    isError: isPhoneSupportError
  } = useGetPhoneSupportNumberForUser()
  const headerText = intl.formatMessage({ defaultMessage: "Something's not working" })
  const altText = intl.formatMessage({ defaultMessage: "error sign" })
  return (
    <PageLayout
      footer={<TalkToCarrotLegal />}
      header={
        <PageBreadcrumbHeader
          breadcrumbs={
            <Breadcrumbs>
              <RootBreadcrumb />
            </Breadcrumbs>
          }
        />
      }
    >
      {isPhoneSupportLoading ? (
        <SlidingDotsLoader />
      ) : (
        <Stack alignItems={"center"}>
          <Box
            component="img"
            src={`/images/error-sign.png`}
            srcSet={`/images/error-sign.png`}
            alt={altText}
            marginTop={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
            marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
            width={300}
          />
          <Stack alignItems={"center"} maxWidth={400}>
            <Typography variant="h2" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
              {headerText}
            </Typography>
            <Typography marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)} textAlign={"center"}>
              <FormattedMessage defaultMessage="We've encountered a problem that we are trying to fix. In the meantime we want to ensure you can still get the help you need. " />
            </Typography>
            <Typography align="center">
              {isPhoneSupportError || !phoneSupportNumber.e164FormatPhoneNumber ? (
                <FormattedMessage
                  defaultMessage="Please <Link>send us a message</Link>."
                  values={{
                    Link: (chunks) => (
                      <Link color="inherit" href="/send-a-message">
                        {chunks}
                      </Link>
                    )
                  }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Please <Link>send us a message</Link> or give us a call at <PhoneSupportNumberLink></PhoneSupportNumberLink>"
                  values={{
                    Link: (chunks) => (
                      <Link color="inherit" href="/send-a-message">
                        {chunks}
                      </Link>
                    ),
                    PhoneSupportNumberLink
                  }}
                />
              )}
            </Typography>
          </Stack>
        </Stack>
      )}
    </PageLayout>
  )
}
