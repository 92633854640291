import React, { useContext, useState } from "react"
import { useLocation } from "react-router-dom"

export enum SearchType {
  LOCATION = "Location",
  KEYWORD = "Keyword"
}

const SearchTypeContext = React.createContext(null)
export const useSearchTypeContext = () => useContext(SearchTypeContext)

export function SearchTypeContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const location = useLocation()
  const [searchType, setSearchType] = useState(
    location.pathname.includes("search-by-name") ? SearchType.KEYWORD : SearchType.LOCATION
  )

  return (
    <SearchTypeContext.Provider
      value={{
        searchType,
        setSearchType
      }}
    >
      {children}
    </SearchTypeContext.Provider>
  )
}
