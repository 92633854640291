import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Box,
  Checkbox,
  CloseIcon,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  MenuIcon,
  MenuItem,
  Typography,
  checkboxClasses
} from "@carrotfertility/carotene-core"

interface CarrotPlansTourModalProps extends Omit<DialogProps, "onClose"> {
  onClose: (props: unknown) => unknown
}

const InactiveCheckbox: React.FC<{ checked: boolean }> = ({ checked }) => (
  <Box
    alignItems="center"
    display="flex"
    bgcolor={(theme) => theme.palette.info.dark}
    borderRadius={(theme) => theme.tokens.borderRadius.md}
    padding={(theme) => theme.spacing(theme.tokens.spacing.md)}
    sx={{ pointerEvents: "none" }}
  >
    <Box paddingRight={(theme) => theme.spacing(theme.tokens.spacing.sm)}>
      <Checkbox
        sx={{
          color: "white",
          [`&.${checkboxClasses.checked}`]: {
            color: "white"
          }
        }}
        checked={checked}
      />
    </Box>

    <Typography color={(theme) => theme.palette.secondary.contrastText}>
      {<FormattedMessage defaultMessage="Get advice on making the most of your benefit" />}
    </Typography>

    <Box marginLeft={"auto"} color={(theme) => theme.palette.secondary.contrastText}>
      <MenuIcon />
    </Box>
  </Box>
)

const NotRelevant: React.FC = () => {
  return (
    <Box
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      display="flex"
      bgcolor={(theme) => theme.palette.info.dark}
      justifyContent={"space-around"}
      sx={{ pointerEvents: "none" }}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Box color={(theme) => theme.palette.secondary.contrastText}>
          <MenuIcon />
        </Box>
        <Box
          bgcolor={(theme) => theme.palette.secondary.light}
          borderRadius={(theme) => theme.tokens.borderRadius.md}
          marginY={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        >
          <MenuItem>
            <Typography>{<FormattedMessage defaultMessage="This is not relevant to me" />}</Typography>
          </MenuItem>
        </Box>
      </Box>
    </Box>
  )
}

export default function CarrotPlansTourModal({ onClose, ...otherProps }: CarrotPlansTourModalProps): JSX.Element {
  const intl = useIntl()

  return (
    <Dialog aria-labelledby="cp-tour-modal-title" onClose={onClose} {...otherProps}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle id="cp-tour-modal-title">
        {intl.formatMessage({ defaultMessage: "How to use Carrot Plans" })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {intl.formatMessage({
            defaultMessage:
              "Your Carrot Plan is your Care Team's top suggestions for you right now. Click any item with an orange arrow to take action:"
          })}
        </DialogContentText>
        <InactiveCheckbox checked={false} />
        <Divider role="none" />
        <DialogContentText>
          {intl.formatMessage({ defaultMessage: "Check items off to let us know you've completed them:" })}
        </DialogContentText>
        <InactiveCheckbox checked />
        <Divider role="none" />
        <DialogContentText>
          {intl.formatMessage({
            defaultMessage: "Click the three dots on the right to tell us if something doesn't feel relevant:"
          })}
        </DialogContentText>
        <NotRelevant />
      </DialogContent>
    </Dialog>
  )
}
