import React, { useEffect, useState } from "react"
import { createErrorBoundary } from "utils/createErrorBoundary"
import { ModalErrorStep } from "components/views/account/phoneNumberUpdate/ModalErrorStep"
import { UpdatePhoneNumberCompletedStep } from "components/views/account/phoneNumberUpdate/UpdatePhoneNumberCompletedStep"
import { UpdatePhoneNumberFormStep } from "components/views/account/phoneNumberUpdate/UpdatePhoneNumberFormStep"
import { useIntl } from "react-intl"
import { Box, CloseIcon, Dialog, IconButton } from "@carrotfertility/carotene-core"
import { useToggle } from "utils/Hooks"
import EmployeeOnlyModalContainer from "components/employee-only-containers/EmployeeOnlyModalContainer"
import { useCurrentUser } from "components/context/user/UserContext"
import useMemberActionTracking from "../../../services/memberActionTracking/useUpdateMemberActionTracking"
import { MemberActions } from "../../../services/memberActionTracking/memberActions"

export type ResendState = "READY" | "SENDING" | "SENT"

const VerificationFormStepComponents = {
  FORM: UpdatePhoneNumberFormStep,
  COMPLETED: UpdatePhoneNumberCompletedStep,
  FAILED: ModalErrorStep
} as const

export type VerificationFormStep = keyof typeof VerificationFormStepComponents

const UpdatePhoneNumberErrorBoundary = createErrorBoundary(ModalErrorStep)

export function PhoneNumberCollectionModal() {
  const intl = useIntl()
  const { mutate: updateMemberActions } = useMemberActionTracking()
  const { refreshUserInfo } = useCurrentUser()
  const [showPhoneNumberCollectionModal, togglePhoneNumberCollectionModal] = useToggle(true)
  const [verificationFormStep, setVerificationFormStep] = useState<VerificationFormStep>("FORM")

  const handleClose = () => {
    if (verificationFormStep === "FORM") {
      updateMemberActions(MemberActions.PHONE_NUMBER_COLLECTION_MODAL_DISMISSED)
    }
    togglePhoneNumberCollectionModal()
    refreshUserInfo()
  }

  useEffect(() => {
    if (showPhoneNumberCollectionModal) {
      setVerificationFormStep("FORM")
      updateMemberActions(MemberActions.PHONE_NUMBER_COLLECTION_MODAL_VIEWED)
    }
  }, [setVerificationFormStep, showPhoneNumberCollectionModal, updateMemberActions])

  const Step = VerificationFormStepComponents[verificationFormStep]

  return (
    <EmployeeOnlyModalContainer onClose={handleClose} open={showPhoneNumberCollectionModal}>
      <Dialog scroll="body" open={showPhoneNumberCollectionModal} data-testid="phone-collection-modal">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <UpdatePhoneNumberErrorBoundary>
          <Step onClose={handleClose} setVerificationFormStep={setVerificationFormStep} isAnnouncementModal={true} />
        </UpdatePhoneNumberErrorBoundary>
      </Dialog>
    </EmployeeOnlyModalContainer>
  )
}
