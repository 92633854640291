enum SupportCenterLinks {
  WHAT_SHOULD_MY_ITEMIZED_STATEMENT_INCLUDE = "sections/360007197434-Itemized-statements",
  HOW_DOES_CARROT_WORK_WITH_MY_INSURANCE = "articles/1260800886630-How-does-Carrot-work-with-my-health-insurance-deductible-",
  WHY_HAVE_DEDUCTIBLE_MANAGED_BY_CARROT = "articles/1260800847309",
  CARROT_CARD_SUPPORT_CENTER = "sections/360007236473-Carrot-Card",
  CARROT_AND_TAXES = "sections/360007197454-Taxes",
  WHY_ARE_YOU_ASKING_INFERTILITY_OR_MEDICAL = "articles/360042872074-Why-are-you-asking-if-I-have-an-infertility-diagnosis-or-medical-necessity-when-I-submit-a-statement-",
  QUALIFIED_MEDICAL_EXPENSES = "sections/4461337383195-Qualified-medical-expenses",
  CARROT_PROVIDERS = "sections/360007236353-Carrot-providers",
  ZENDESK_TAXABILITY_GUIDE = "sections/360007197454-Taxes",
  USING_CARROT_WITH_INSURANCE = "sections/360007430633-Using-Carrot-with-insurance"
}

enum SupportCenterTypes {
  SECTIONS = "sections",
  ARTICLES = "articles"
}

export { SupportCenterLinks, SupportCenterTypes }
