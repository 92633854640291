import { useIntl } from "react-intl"
import { FormCheckbox, useWatch } from "@carrotfertility/carotene-core-x"
import React from "react"

export default function SmsMarketingTextOptIn({ countryCodeFieldName }: { countryCodeFieldName: string }) {
  const intl = useIntl()
  const countryCodeSelection = useWatch({ name: countryCodeFieldName })
  if (countryCodeSelection === "US") {
    return (
      <FormCheckbox
        name={"allowsMarketingTexts"}
        label={intl.formatMessage({
          defaultMessage: "Text me occasional benefit updates such as tips, reminders, and more."
        })}
        helperText={intl.formatMessage({ defaultMessage: "You can opt out anytime via SMS." })}
      />
    )
  }
  return null
}
