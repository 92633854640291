import { useBenefitEnrollment } from "../../context/enrollment/BenefitEnrollmentContext"
import React, { FC, FormEvent } from "react"
import { Link } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { PageLayout } from "../../../features/global-ui/page-layout"
import { Title } from "../../../services/page-title/Title"
import ActivateTemplate from "./ActivateTemplate"
import { useHistory } from "react-router-dom"
import EmployeeOnlySpaceContainer from "../../employee-only-containers/EmployeeOnlySpaceContainer"

interface ActivateProps {
  error: boolean
  onSubmit: (event: FormEvent) => Promise<void>
  submitting: boolean
}
const Activate: FC<ActivateProps> = ({ error, onSubmit, submitting }) => {
  const intl = useIntl()
  const { needsBenefitEnrollment } = useBenefitEnrollment()
  const benefitEnrollmentError = error && needsBenefitEnrollment
  const defaultError = error && !needsBenefitEnrollment
  const history = useHistory()
  const onLinkClick = () => {
    history.push("/#needs-benefit-enrollment")
  }

  return (
    <PageLayout>
      <EmployeeOnlySpaceContainer>
        <Title title={intl.formatMessage({ defaultMessage: "Activate your Carrot Card" })} />
        <ActivateTemplate
          header={intl.formatMessage({ defaultMessage: "Activate your Carrot Card" })}
          body={intl.formatMessage({
            defaultMessage:
              "Once you activate your Carrot Card, you’ll be able to use to pay for eligible services at eligible providers."
          })}
          buttonOnClick={onSubmit}
          buttonText={
            submitting
              ? intl.formatMessage({ defaultMessage: "Activating" })
              : intl.formatMessage({ defaultMessage: "Activate" })
          }
          buttonDisabled={submitting}
          errorMessage={
            defaultError ? (
              <FormattedMessage
                defaultMessage="There was a problem activating your Carrot Card, and we're working on it. Please try again later, or <link>contact our care team</link> if you have any questions."
                values={{
                  link: (linkContent) => (
                    <Link href={"/talk-to-carrot"} color="inherit">
                      <span>{linkContent}</span>
                    </Link>
                  )
                }}
              />
            ) : benefitEnrollmentError ? (
              <FormattedMessage
                defaultMessage="We’re unable to activate your Carrot Card because we need some information about your health insurance. Please <link>share a few details about your plan</link> and try again."
                values={{
                  link: (linkContent) => (
                    <Link onClick={onLinkClick} color="inherit" component="button">
                      <span>{linkContent}</span>
                    </Link>
                  )
                }}
              />
            ) : null
          }
        />
      </EmployeeOnlySpaceContainer>
    </PageLayout>
  )
}

export default Activate
