import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Link, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Link as ReactRouterLink } from "react-router-dom"

type TierInfoProps = {
  showLink?: boolean
}

export function TierInfoWhenCustomNetworksForSidebar({ showLink }: TierInfoProps): JSX.Element {
  const theme = useTheme()
  const intl = useIntl()
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
      borderRadius={(theme) => theme.tokens.borderRadius.sm}
      padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      sx={{ backgroundColor: theme.palette.info.light }}
    >
      <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
        <Typography fontWeight="bold" variant="body1" color="secondary">
          <FormattedMessage defaultMessage={`What are provider tiers?`} />
        </Typography>
        <Typography variant="body1" color="secondary">
          <FormattedMessage
            defaultMessage={`Your health insurance plan offers two in-network provider tiers for fertility clinics: Tier 1 and Tier 2.`}
          />
        </Typography>
      </Box>
      <Typography variant="body1" color="secondary">
        <FormattedMessage
          defaultMessage={`Your chosen provider’s tier impacts your deductibles and how you pay for care.`}
        />
      </Typography>
      {showLink ? (
        <Link
          to="/your-benefit-guide/coverage/employer-specific-information"
          component={ReactRouterLink}
          color="inherit"
          title={intl.formatMessage({ defaultMessage: "Learn about provider tiers in your benefit guide" })}
        >
          <FormattedMessage defaultMessage={`Learn more`} />
        </Link>
      ) : null}
    </Box>
  )
}
