import AboutYouIntroStep from "./AboutYouIntroStep"
import AboutYouDobStep from "./AboutYouDobStep"
import AboutYourPartnerIntroStep from "./AboutYourPartnerIntroStep"
import AboutYouSexStep from "./AboutYouSexStep"
import AboutYouGenderIdentityStep from "./AboutYouGenderIdentityStep"
import AboutYouAddressStep from "./AboutYouAddressStep"
import AboutYourPartnerNameStep from "./AboutYourPartnerNameStep"
import AboutYourPartnerPreferredNameStep from "./AboutYourPartnerPreferredNameStep"
import AboutYourPartnerEmailStep from "./AboutYourPartnerEmailStep"
import AboutYourPartnerDobStep from "./AboutYourPartnerDobStep"
import AboutYourPartnerSexStep from "./AboutYourPartnerSexStep"
import AboutYourPartnerGenderIdentityStep from "./AboutYourPartnerGenderIdentityStep"
import { Steps as JourneySteps } from "../journey/step-library"
import { AboutYouGenderIdentityDecisionStep, PartnerGenderIdentityDecisionStep } from "./GenderIdentityDecisionStep"
import AboutYourPartnerAccessInviteStep from "./AboutYourPartnerAccessInviteStep"
import { PartnerInfoNeededDecisionStep } from "./PartnerInfoNeededDecisionStep"
import { PartnerAccessDecisionStep } from "./PartnerAccessDecisionStep"

export enum Workflows {
  ABOUT_YOU_DEFAULT = "About You Default Workflow"
}

export enum Steps {
  ABOUT_YOU_INTRO = "About You Intro",
  ABOUT_YOU_DOB = "About You Date of Birth",
  ABOUT_YOU_SEX = "About You Sex",
  ABOUT_YOU_GENDER_IDENTITY_DECISION = "About You Gender Identity Decision",
  ABOUT_YOU_GENDER_IDENTITY = "About You Gender Identity",
  ABOUT_YOU_ADDRESS = "About You Address",
  PARTNER_INFO_NEEDED_DECISION = "About You Partner Info Needed Decision",
  ABOUT_YOUR_PARTNER_INTRO = "About Your Partner Intro",
  PARTNER_NAME = "About Your Partner Name",
  PARTNER_PREFERRED_NAME = "About Your Partner Preferred Name",
  PARTNER_EMAIL = "About Your Partner Email",
  PARTNER_DOB = "About Your Partner Date of Birth",
  PARTNER_SEX = "About Your Partner Sex",
  PARTNER_GENDER_IDENTITY_DECISION = "About Your Partner Gender Identity Decision Step",
  PARTNER_GENDER_IDENTITY = "About Your Partner Gender Identity",
  PARTNER_ACCESS_ENABLED_DECISION = "Partner Access Enabled Decision",
  PARTNER_ACCESS_INVITE = "Partner Access Invite"
}

export const aboutYouSteps = [
  { name: Steps.ABOUT_YOU_INTRO, component: AboutYouIntroStep },
  { name: Steps.ABOUT_YOU_DOB, component: AboutYouDobStep },
  { name: Steps.ABOUT_YOU_SEX, component: AboutYouSexStep },
  { name: Steps.ABOUT_YOU_GENDER_IDENTITY_DECISION, component: AboutYouGenderIdentityDecisionStep, decisionStep: true },
  { name: Steps.ABOUT_YOU_GENDER_IDENTITY, component: AboutYouGenderIdentityStep },
  { name: Steps.ABOUT_YOU_ADDRESS, component: AboutYouAddressStep },
  {
    name: Steps.PARTNER_INFO_NEEDED_DECISION,
    component: PartnerInfoNeededDecisionStep,
    decisionStep: true
  },
  { name: Steps.ABOUT_YOUR_PARTNER_INTRO, component: AboutYourPartnerIntroStep },
  { name: Steps.PARTNER_NAME, component: AboutYourPartnerNameStep },
  { name: Steps.PARTNER_PREFERRED_NAME, component: AboutYourPartnerPreferredNameStep },
  { name: Steps.PARTNER_EMAIL, component: AboutYourPartnerEmailStep },
  { name: Steps.PARTNER_DOB, component: AboutYourPartnerDobStep },
  { name: Steps.PARTNER_SEX, component: AboutYourPartnerSexStep },
  { name: Steps.PARTNER_GENDER_IDENTITY_DECISION, component: PartnerGenderIdentityDecisionStep, decisionStep: true },
  { name: Steps.PARTNER_GENDER_IDENTITY, component: AboutYourPartnerGenderIdentityStep },
  { name: Steps.PARTNER_ACCESS_ENABLED_DECISION, component: PartnerAccessDecisionStep, decisionStep: true },
  { name: Steps.PARTNER_ACCESS_INVITE, component: AboutYourPartnerAccessInviteStep }
]
//prettier-ignore
export const aboutYouFlow = [
  { at: Steps.ABOUT_YOU_INTRO,                    given: "",       goto: Steps.ABOUT_YOU_DOB },
  { at: Steps.ABOUT_YOU_DOB,                      given: "",       goto: Steps.ABOUT_YOU_SEX },
  { at: Steps.ABOUT_YOU_SEX,                      given: "",       goto: Steps.ABOUT_YOU_GENDER_IDENTITY_DECISION },
  { at: Steps.ABOUT_YOU_GENDER_IDENTITY_DECISION, given: "YES",    goto: Steps.ABOUT_YOU_GENDER_IDENTITY },
  { at: Steps.ABOUT_YOU_GENDER_IDENTITY_DECISION, given: "NO",     goto: Steps.ABOUT_YOU_ADDRESS},
  { at: Steps.ABOUT_YOU_GENDER_IDENTITY,          given: "",       goto: Steps.ABOUT_YOU_ADDRESS },
  { at: Steps.ABOUT_YOU_ADDRESS,                  given: "",       goto: Steps.PARTNER_INFO_NEEDED_DECISION },
  { at: Steps.PARTNER_INFO_NEEDED_DECISION,     given: "NO",    goto: JourneySteps.WHAT_KIND_OF_JOURNEY,     sectionName: "Journey"},
  { at: Steps.PARTNER_INFO_NEEDED_DECISION,     given: "YES",     goto: Steps.ABOUT_YOUR_PARTNER_INTRO},
  { at: Steps.ABOUT_YOUR_PARTNER_INTRO,           given: "YES",    goto: Steps.PARTNER_NAME },
  { at: Steps.ABOUT_YOUR_PARTNER_INTRO,           given: "NO",     goto: JourneySteps.WHAT_KIND_OF_JOURNEY,     sectionName: "Journey" },
  { at: Steps.PARTNER_NAME,                       given: "",       goto: Steps.PARTNER_PREFERRED_NAME },
  { at: Steps.PARTNER_PREFERRED_NAME,             given: "",       goto: Steps.PARTNER_EMAIL },
  { at: Steps.PARTNER_EMAIL,                      given: "",       goto: Steps.PARTNER_DOB },
  { at: Steps.PARTNER_DOB,                        given: "",       goto: Steps.PARTNER_SEX },
  { at: Steps.PARTNER_SEX,                        given: "",       goto: Steps.PARTNER_GENDER_IDENTITY_DECISION},
  { at: Steps.PARTNER_GENDER_IDENTITY_DECISION,   given: "YES",    goto: Steps.PARTNER_GENDER_IDENTITY },
  { at: Steps.PARTNER_GENDER_IDENTITY_DECISION,   given: "NO",     goto: Steps.PARTNER_ACCESS_ENABLED_DECISION },
  { at: Steps.PARTNER_GENDER_IDENTITY,            given: "",       goto: Steps.PARTNER_ACCESS_ENABLED_DECISION },
  { at: Steps.PARTNER_ACCESS_ENABLED_DECISION,    given: "YES",    goto: Steps.PARTNER_ACCESS_INVITE},
  { at: Steps.PARTNER_ACCESS_ENABLED_DECISION,    given: "NO",     goto: JourneySteps.WHAT_KIND_OF_JOURNEY,     sectionName: "Journey"},
  { at: Steps.PARTNER_ACCESS_INVITE,              given: "",       goto: JourneySteps.WHAT_KIND_OF_JOURNEY,     sectionName: "Journey"}
]

export const aboutYouWorkflows = [{ name: Workflows.ABOUT_YOU_DEFAULT, workflow: aboutYouFlow }]
