// Not filtering GenderIdentityChoices for restrictive countries since the entire is hidden in that case

import { FormSelectOption } from "@carrotfertility/carotene-core-x"
import { useIntl } from "react-intl"

export function useGetGenderIdentityOptions(): FormSelectOption[] {
  const intl = useIntl()
  return [
    { label: intl.formatMessage({ defaultMessage: "Man" }), value: "MAN" },
    { label: intl.formatMessage({ defaultMessage: "Woman" }), value: "WOMAN" },
    {
      label: intl.formatMessage({ defaultMessage: "Non-binary" }),
      value: "NON_BINARY"
    },
    {
      label: intl.formatMessage({ defaultMessage: "Another gender identity" }),
      value: "ANOTHER_GENDER_IDENTITY"
    }
  ]
}
