import React, { useState } from "react"
import { ChangeLocationModal } from "./ChangeLocationModal"
import { Box, Button, ForwardIcon, Typography, useTheme } from "@carrotfertility/carotene-core"
import { PiiText } from "services/tracking"
import { FormattedMessage } from "react-intl"

export function SidebarLocationPicker({ searchLocation }: { searchLocation: string }): JSX.Element {
  const [open, setOpen] = useState(false)
  const theme = useTheme()

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true)
        }}
        sx={{
          borderRadius: theme.tokens.borderRadius.sm,
          backgroundColor: theme.palette.background.paper,
          justifyContent: "left",
          padding: theme.tokens.spacing.md,
          borderColor: "transparent",
          ":hover": {
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.primary.main
          },
          ":focus-visible": {
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.primary.main
          }
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={(theme) => theme.tokens.spacing.sm}
          alignItems="flex-start"
          justifyContent="left"
        >
          <Typography component={PiiText} textAlign="left">
            {searchLocation}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            color={(theme) => theme.palette.text.secondary}
            fontWeight="bold"
          >
            <Typography variant="body1" color="inherit" fontWeight="inherit">
              <FormattedMessage defaultMessage="Change location" />
            </Typography>
            <ForwardIcon fontSize="small" color="inherit" fontWeight="inherit" />
          </Box>
        </Box>
      </Button>
      <ChangeLocationModal open={open} setOpen={() => setOpen(false)} />
    </>
  )
}
