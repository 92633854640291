import { Stack, useTheme, Divider } from "@carrotfertility/carotene-core"
import { FormSelect, useWatch, FormTextField } from "@carrotfertility/carotene-core-x"
import { renderLabelWithPlaceholder } from "pages/provider-finder/utils/renderLabelUtil"
import React from "react"
import { useIntl } from "react-intl"

export function GenderIdentityFormSelect(): JSX.Element {
  const theme = useTheme()
  const intl = useIntl()
  const genderIdentityValue = useWatch({ name: "genderIdentity" })

  const genderOptions = [
    { label: intl.formatMessage({ defaultMessage: "Man" }), value: "MAN" },
    {
      label: intl.formatMessage({ defaultMessage: "Woman" }),
      value: "WOMAN"
    },
    {
      label: intl.formatMessage({ defaultMessage: "Non-binary" }),
      value: "NON_BINARY"
    },
    {
      label: intl.formatMessage({ defaultMessage: "Another gender identity" }),
      value: "ANOTHER_GENDER_IDENTITY"
    }
  ]

  return (
    <>
      <FormSelect
        name="genderIdentity"
        renderValue={renderLabelWithPlaceholder(genderOptions, intl.formatMessage({ defaultMessage: "Select option" }))}
        label={intl.formatMessage({ defaultMessage: "Gender" })}
        options={genderOptions}
      />
      {genderIdentityValue === "ANOTHER_GENDER_IDENTITY" ? (
        <Stack display="flex" direction="row" spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <Divider orientation="vertical" flexItem />
          <FormTextField
            name={"genderIdentityOther"}
            placeholder={intl.formatMessage({ defaultMessage: "Optional details" })}
            label={intl.formatMessage({ defaultMessage: "Other" })}
            inputProps={{ maxLength: 200 }}
          />
        </Stack>
      ) : null}
    </>
  )
}
