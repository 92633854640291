import React, { FC } from "react"
import { ArrowLink, ArrowLinkProps } from "./ArrowLink"
import { getSupportCenterLink, goToHelpCenter } from "carrot-api/HelpCenterClient"

type LegacyZendeskArrowLinkProps = {
  supportCenterLink?: string
} & ArrowLinkProps

const LegacyZendeskArrowLink: FC<LegacyZendeskArrowLinkProps> = ({
  supportCenterLink,
  ...props
}: LegacyZendeskArrowLinkProps) => {
  let zdLinkFragment = supportCenterLink
  // Contentful zendesk links will be provided via the "to" and they will be full URLs to zendesk, so parse out the fragment
  // e.g. https://get-carrot.zendesk.com/hc/sections/4410088379931-Carrot-Plans
  if (props.to) {
    const path = props.to?.split("/")
    zdLinkFragment = path[path.length - 2] + "/" + path[path.length - 1]
  }

  return (
    <ArrowLink
      to={getSupportCenterLink(zdLinkFragment)}
      onClick={(event) => goToHelpCenter(event, zdLinkFragment)}
      target={"_blank"}
      {...props}
    >
      {props.children}
    </ArrowLink>
  )
}

export { LegacyZendeskArrowLink }
