export type SocialDeterminantsOfHealth = {
  hasIssuesAffectingWellBeing: SDOHAnswerOptions
  routingInfoId?: number
}

export enum SDOHAnswerOptions {
  YES = "YES",
  NO = "NO",
  SKIP = "SKIP"
}
