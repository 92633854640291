import { Steps } from "./steps"
import { UploadAdditionalDocuments } from "../steps/UploadAdditionalDocuments"
import { AnythingElseToShare } from "../steps/AnythingElseToShare"
import { UploadAdditionalDocumentsSuccess } from "../steps/UploadAdditionalDocumentsSuccess"
import { uploadAdditionalDocumentsWorkflows } from "./workflow"

export const uploadAdditionalDocumentsSection = {
  name: "UploadAdditionalDocumentsSection",
  initialStep: Steps.UPLOAD_ADDITIONAL_FILES,
  desiredWorkflow: "UploadAdditionalDocuments",
  steps: [
    {
      name: Steps.UPLOAD_ADDITIONAL_FILES,
      component: UploadAdditionalDocuments
    },
    { name: Steps.ANYTHING_ELSE_TO_SHARE, component: AnythingElseToShare },
    { name: Steps.UPLOAD_ADDITIONAL_FILES_SUCCESS, component: UploadAdditionalDocumentsSuccess }
  ],
  workflows: uploadAdditionalDocumentsWorkflows
}
