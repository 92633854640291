import { Box, Button, Typography } from "@carrotfertility/carotene-core"
import React from "react"
import useCarrotPlanEmployeeSettings from "../carrot-plans/hooks/useCarrotPlanEmployeeSettings"
import { useHistory } from "react-router-dom"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import useGetMemberBenefit from "../carrot-plans/hooks/useGetMemberBenefit"
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk"
import { useStartRouting } from "../carrot-plans/shared/useQueryStartRouting"
import { useStartRoutingInfo } from "../carrot-plans/shared/useQueryRoutingInfo"
import { caseRateRenewalRoutingFlowUrl } from "../carrot-plans/case-rate-renewals/workflow"
import { CarrotPlansPaths } from "../../utils/Paths"

const EXPERIENCE_TYPE_TO_COPY = {
  LITE: {
    title: defineMessage({ defaultMessage: "Your Carrot Plan" }),
    body: defineMessage({
      defaultMessage:
        "When you're ready to access experts, providers, and more, tell us about your journey and we'll craft a free, personalized plan to support you."
    })
  },
  PRO: {
    title: defineMessage({ defaultMessage: "Your Carrot Plan" }),
    body: defineMessage({
      defaultMessage:
        "When you're ready to access Carrot funds, experts, providers, and more, tell us about your journey and we'll craft a free, personalized plan to support you."
    })
  }
}

export function GetCarrotPlanHero(): JSX.Element {
  const { data: memberBenefit } = useGetMemberBenefit()
  const { startRouting } = useStartRouting(useStartRoutingInfo(), caseRateRenewalRoutingFlowUrl)
  const { isDoneForNow } = useCarrotPlanEmployeeSettings()
  const history = useHistory()
  const isCarrotLite = memberBenefit?.program.isCarrotLite
  const { homePageCtaTestUnlockBenefit } = useFlags()
  const ldClient = useLDClient()

  async function getYourCarrotPlan(): Promise<Promise<void> | void> {
    await ldClient.flush()
    if (isDoneForNow) {
      await startRouting()
    } else {
      history.push(CarrotPlansPaths.ENROLLMENT)
    }
  }

  const intl = useIntl()
  const title = isCarrotLite
    ? intl.formatMessage(EXPERIENCE_TYPE_TO_COPY.LITE.title)
    : intl.formatMessage(EXPERIENCE_TYPE_TO_COPY.PRO.title)
  const body = isCarrotLite
    ? intl.formatMessage(EXPERIENCE_TYPE_TO_COPY.LITE.body)
    : intl.formatMessage(EXPERIENCE_TYPE_TO_COPY.PRO.body)
  return (
    <Box
      bgcolor={(theme) => theme.palette.info.dark}
      color={(theme) => theme.palette.secondary.contrastText}
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      display="flex"
      flexWrap="wrap-reverse"
      gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
    >
      <Box flex="1">
        <Typography
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxs)}
          variant="h3"
          component="h2"
          color="inherit"
        >
          {title}
        </Typography>
        <Typography marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)} color="inherit">
          {body}
        </Typography>

        <Button id="get-your-carrot-plan-button" onClick={getYourCarrotPlan}>
          {homePageCtaTestUnlockBenefit === "Control" ? (
            <FormattedMessage defaultMessage="Get your Carrot Plan" />
          ) : (
            <FormattedMessage defaultMessage="Unlock my benefit" />
          )}
        </Button>
      </Box>
      <Box
        component="img"
        src={`/images/illo-underwater-checklist.png`}
        srcSet={`/images/illo-underwater-checklist-2x.png`}
        alt=""
        height={228}
        width={228}
      />
    </Box>
  )
}
