import { RoundedRectangle } from "../../../views/atoms/roundedrectangle/RoundedRectangle"
import { FlexContainer, H3, Stack } from "@carrotfertility/carotene"
import { CircleImage } from "../../../views/atoms/images/CircleImage"
import { BodySmall } from "../../../views/atoms/Bodies"
import React from "react"
import { FormattedMessage } from "react-intl"

export const IVFCard = (): JSX.Element => {
  return (
    <>
      <RoundedRectangle backgroundColor="light-gray" className="flex-row">
        <FlexContainer shrink={0}>
          <CircleImage iconName="icn-documents" backgroundColor={"black-10"} />
        </FlexContainer>
        <Stack direction="column" spacing="tiny" justifyContent="flex-end" paddingLeftRight="small">
          <H3 color={"black-80"}>
            <FormattedMessage defaultMessage="Using Carrot funds for IVF" />
          </H3>
          <BodySmall color={"black-80"}>
            <FormattedMessage defaultMessage="To support healthy outcomes, members are required to complete two steps before they can use Carrot funds for IVF-related expenses." />
          </BodySmall>
          <BodySmall color={"black-80"}>
            <FormattedMessage defaultMessage="Once you're done submitting this expense, we'll show you how to complete those." />
          </BodySmall>
        </Stack>
      </RoundedRectangle>
    </>
  )
}
