import { combineReducers } from "redux"
import actionTypes from "actions/actionTypes"

const list = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.reimbursements
    default:
      return state
  }
}

const reimbursements = combineReducers({
  list
})

export const getReimbursementsList = (state) => state.reimbursements.list

export default reimbursements
