import React from "react"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { CycleDetails } from "./MedicalInfoOptions"
import {
  StepLayout,
  RoutingFlowCheckboxGroup,
  ButtonFooterLayout,
  ContinueButton,
  SkipButton,
  useConvertTranslatedLabel,
  RoutingFlowTitle
} from "components/carrot-plans/shared"
import { FormattedMessage, useIntl } from "react-intl"
import { Form } from "@carrotfertility/carotene-core-x"

type FormValues = {
  cycleDetails?: string[]
}

export default function CycleDetailsStep(): JSX.Element {
  const intl = useIntl()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultValue = medicalInfo?.cycleDetails

  const { send } = useStateMachine(defaultValue || hasQuestionBeenSkipped("cycleDetails") ? "" : null)

  const options = useConvertTranslatedLabel(CycleDetails)

  async function onClickContinue(values: FormValues): Promise<void> {
    await updateMedicalInfo({ cycleDetails: values?.cycleDetails })
    send("")
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["cycleDetails"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Select any that apply."
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="cycleDetails-label">{header}</span>}
        formElements={
          <Form
            defaultValues={{
              cycleDetails: defaultValue || []
            }}
            onSubmit={onClickContinue}
          >
            <RoutingFlowCheckboxGroup name="cycleDetails" aria-labelledby="cycleDetails-label" options={options} />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
