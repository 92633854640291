import React from "react"
import SignUpPage from "components/signup"
import { SignUpProvider } from "components/signup/context/SignUpContext"

const SignUp = (): JSX.Element => {
  return (
    <SignUpProvider>
      <SignUpPage />
    </SignUpProvider>
  )
}

export default SignUp
