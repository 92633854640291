import { ProviderFinderClient } from "../lib/ProviderFinderClient"
import Settings from "../lib/Settings"
import { useUserContext } from "../context/UserContext"
import { useMemo } from "react"

export const useProviderFinderClient = (): ProviderFinderClient => {
  const { handleSessionExpiration } = useUserContext()

  if (!handleSessionExpiration) {
    throw new Error("Missing handleSessionExpiration")
  }

  return useMemo(() => {
    return new ProviderFinderClient(Settings.BASE_URL, handleSessionExpiration)
  }, [handleSessionExpiration])
}
