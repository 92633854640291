import { useIntl } from "react-intl"
import { getCountryUsesImperialUnits } from "../utils/getCountryUsesImperialUnits"

export const useGetLocalizedDistanceStringWithUnits = ({
  distanceInKm,
  countryCode,
  numberOfFractionDigits = 0
}: {
  distanceInKm: number
  countryCode: string
  numberOfFractionDigits?: number
}): string => {
  const intl = useIntl()
  const { locale } = intl
  const countryUsesImperialUnits = getCountryUsesImperialUnits(countryCode)
  const KM_PER_MILE = 1.60934
  return new Intl.NumberFormat(locale, {
    style: "unit",
    unit: countryUsesImperialUnits ? "mile" : "kilometer",
    maximumFractionDigits: numberOfFractionDigits,
    minimumFractionDigits: numberOfFractionDigits
  }).format(countryUsesImperialUnits ? distanceInKm / KM_PER_MILE : distanceInKm)
}
