import actionTypes from "actions/actionTypes"
import { createReducer } from "./shared"
import { combineReducers } from "redux"
import { RootState } from "./index"

const { FETCH_SUBSIDIES_SUCCESS } = actionTypes

const reducers = {
  subsidies: createReducer(null, {
    // @ts-expect-error TS7006
    [FETCH_SUBSIDIES_SUCCESS]: (state, action) => {
      if (action?.response?.length) {
        if (action.response?.length === 1) {
          const augmentedSubsidy = {
            ...action.response[0],
            hasCostShareDisplay: action.response[0]?.reimbursementPercent !== 100
          }
          return [augmentedSubsidy]
        } else {
          // @ts-expect-error TS7006
          const augmentedSubsidies = action.response.map((subsidy) => {
            return {
              ...subsidy,
              hasCostShareDisplay: subsidy?.reimbursementPercent !== 100
            }
          })
          return [...augmentedSubsidies]
        }
      } else {
        return action.response
      }
    }
  })
}

const subsidies = combineReducers(reducers)

export const getSubsidies = (state: RootState) => state.subsidies.subsidies

export default subsidies
