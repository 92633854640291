import { Section } from "../utils/utils"
import React from "react"
import { ContentfulRawMarkdownRenderer } from "services/contentful"
import { Box } from "@carrotfertility/carotene-core"

export const GuideContentMarkdown = ({ section, pageIndex }: { section: Section; pageIndex: number }): JSX.Element => {
  return (
    <Box component="article" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxl)}>
      <ContentfulRawMarkdownRenderer>{section?.pages[pageIndex]?.body}</ContentfulRawMarkdownRenderer>
    </Box>
  )
}
