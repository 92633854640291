import { useCurrentUser } from "../../../context/user/UserContext"

export default function usePartnerInformation() {
  const {
    partnerFirstName,
    partnerLastName,
    partnerDateOfBirth,
    partnerEmail,
    partnerPreferredName,
    partnerSex,
    partnerGenderIdentity,
    partnerGenderIdentityOther
  } = useCurrentUser()

  const hasProvidedAnyPartnerInfo = [
    partnerFirstName,
    partnerLastName,
    partnerDateOfBirth,
    partnerEmail,
    partnerPreferredName,
    partnerSex,
    partnerGenderIdentity,
    partnerGenderIdentityOther
  ].some((element) => element)

  const hasProvidedInfoRequiredForPartnerAccess = [
    partnerFirstName,
    partnerLastName,
    partnerDateOfBirth,
    partnerEmail
  ].every((element) => element)

  return { hasProvidedAnyPartnerInfo, hasProvidedInfoRequiredForPartnerAccess }
}
