import React from "react"
import { Select, SelectProps } from "@carrotfertility/carotene"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

export interface FormSingleSelectProps extends SelectProps {
  name?: string
  getSelectedValue?: any
  optionComponent?: any
  rules?: any
}

export const FormSingleSelect = React.forwardRef(
  ({ rules, name, onChange, optionComponent, ...props }: FormSingleSelectProps, ref) => {
    const {
      formState: { errors },
      ...formMethods
    } = useFormContext()
    const intl = useIntl()
    return (
      <Controller
        rules={rules}
        {...formMethods}
        name={name}
        render={({ field }) => (
          <Select
            ref={ref}
            isSearchable={false}
            placeholder={intl.formatMessage({ defaultMessage: "Select an option" })}
            isError={Boolean(errors[name])}
            errorMessage={errors[name]?.message.toString()}
            {...{ ...props, ...field }}
            onChange={(args) => field.onChange(onChange ? onChange(args) : args)}
            optionComponent={optionComponent}
          />
        )}
      />
    )
  }
)
FormSingleSelect.displayName = "FormSingleSelect"
