import React, { FC } from "react"
import { useToggle } from "utils/Hooks"
import { HowMuchReimbursedModalContainer } from "components/containers/reimbursements/educationmodal/HowMuchReimbursedModalContainer"
import subsidyStatus from "utils/CarrotPropTypes"
import { Box, IconButton, InfoIcon, Typography, useTheme, BoxProps } from "@carrotfertility/carotene-core"

type DeductibleAndCostShareBadgeProps = {
  subsidy: typeof subsidyStatus
  children: React.ReactNode
} & Pick<BoxProps, "sx">

const DeductibleAndCostShareBadge: FC<DeductibleAndCostShareBadgeProps> = ({ subsidy, sx, children }) => {
  const [showHowMuchReimbursedModal, toggleHowMuchReimbursedModal] = useToggle()
  const theme = useTheme()

  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" sx={sx}>
      <Typography variant="body1" color={theme.palette.text.secondary}>
        {children}
      </Typography>
      <IconButton
        onClick={(event) => {
          event.stopPropagation()
          toggleHowMuchReimbursedModal()
        }}
        size="small"
        sx={() => ({ pointerEvents: "auto" })}
      >
        <InfoIcon />
        {showHowMuchReimbursedModal && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <HowMuchReimbursedModalContainer onExit={toggleHowMuchReimbursedModal} subsidy={subsidy} />
          </div>
        )}
      </IconButton>
    </Box>
  )
}

export { DeductibleAndCostShareBadge }
