import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import moment from "moment"
import { Spacer } from "components/views/atoms/Atoms"
import { FormInput } from "components/views/atoms/forms/FormInput"

const FormDateOfBirthInput = forwardRef(({ dateOfBirth }, ref) => {
  const [day, setDay] = useState("")
  const [validDay, setValidDay] = useState(true)

  const [month, setMonth] = useState("")
  const [validMonth, setValidMonth] = useState(true)

  const [year, setYear] = useState("")
  const [validYear, setValidYear] = useState(true)

  const [previousDateOfBirth, setPreviousDateOfBirth] = useState()

  useEffect(() => {
    if (dateOfBirth !== null && !dateOfBirth.isSame(previousDateOfBirth)) {
      //Only reset it if they were blank
      setDay(dateOfBirth.date())
      setMonth(dateOfBirth.month() + 1) //month is zero based
      setYear(dateOfBirth.year())
      setPreviousDateOfBirth(dateOfBirth)
    }
  }, [dateOfBirth, previousDateOfBirth])

  useImperativeHandle(ref, () => ({
    validate: () => {
      //We have to leverage local variable because the setters are non-blocking
      // and we'd always be returning the previous state of the valid state objects otherwise.
      const validDay = day > 0 && day <= moment(year + "-" + month, "YYYY-MM").daysInMonth()
      setValidDay(validDay)
      const validMonth = month <= 12 && month > 0
      setValidMonth(validMonth)
      const validYear = year <= moment().year() && year > 1900
      setValidYear(validYear)
      return validDay && validMonth && validYear
    },
    getMoment: () => {
      return moment(`${month}-${day}-${year}`, "MM-DD-YYYY")
    }
  }))

  return (
    <div className={"flex flex-row w-100"}>
      <div className="flex-auto">
        <FormInput
          name="month"
          // eslint-disable-next-line formatjs/no-literal-string-in-jsx
          placeholder="mm"
          onChange={(e) => setMonth(e.target.value)}
          valid={validMonth}
          value={month}
        />
      </div>
      <Spacer width={1} />
      <div className="flex-auto">
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
        <FormInput name="day" placeholder="dd" onChange={(e) => setDay(e.target.value)} valid={validDay} value={day} />
      </div>
      <Spacer width={1} />
      <div className="flex-auto">
        <FormInput
          name="year"
          // eslint-disable-next-line formatjs/no-literal-string-in-jsx
          placeholder="yyyy"
          onChange={(e) => setYear(e.target.value)}
          valid={validYear}
          value={year}
        />
      </div>
    </div>
  )
})

FormDateOfBirthInput.displayName = "FormDateOfBirthInput"
export { FormDateOfBirthInput }
