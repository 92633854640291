import React, { useEffect, useState } from "react"
import { Box, Divider, Snackbar } from "@carrotfertility/carotene-core"
import { BiometricAuthenticationSettings } from "components/views/account/BiometricAuthenticationSettings"
import usePartnerAccess, { useUserRole } from "components/partner-access/hooks/usePartnerAccess"
import {
  PartnerInformationSettings,
  PartnerInformationSettingsPrompt
} from "components/views/account/PartnerInformationSettings"
import usePartnerInformation from "components/views/account/hooks/usePartnerInformation"
import { FeatureGate } from "utils/FeatureGate"
import { PersonalInformationSettings } from "components/views/account/PersonalInformationSettings"
import { LanguageSettings } from "components/views/account/LanguageSettings"
import { useFormContext } from "react-hook-form"
import { PartnerAccessAccountSettingsFooter } from "components/partner-access/member-account-settings/PartnerAccessAccountSettingsFooter"
import { AddressSettings } from "../../components/views/account/AddressSettings"
import EmailAddressesAccountSettings from "../../components/EmailVerification/EmailAddressesAccountSettings"
import PasswordSettings from "components/views/account/PasswordSettings"
import { AccountDeletion } from "components/views/account/AccountDeletion"
import { FormattedMessage } from "react-intl"
import { useCurrentUser } from "components/context/user/UserContext"
import { PageHeader, PageHeaderGlyph } from "features/global-ui/page-header"
import { PageLayout } from "features/global-ui/page-layout"
import { FormButton } from "@carrotfertility/carotene-core-x"
import { CommunicationSettings } from "components/views/account/CommunicationSettings"
import { useTextDirection } from "services/locale/hooks/useTextDirection"
import { TextDirection } from "types/Locale"

export function SettingsPageDivider(): JSX.Element {
  return (
    <Box margin={(theme) => theme.spacing(theme.tokens.spacing.xxl, theme.tokens.spacing.none)}>
      <Divider />
    </Box>
  )
}

export function SettingsPage(): JSX.Element {
  const { isPosingAsMember } = useCurrentUser()
  const {
    formState: { isDirty }
  } = useFormContext()
  const { hasProvidedAnyPartnerInfo } = usePartnerInformation()
  const [showPartnerInfo, setShowPartnerInfo] = useState(hasProvidedAnyPartnerInfo)
  const { isPartnerAccessingAccount } = useUserRole()
  const { showPartnerAccess } = usePartnerAccess()
  const textDirection = useTextDirection()

  useEffect(() => {
    setShowPartnerInfo(hasProvidedAnyPartnerInfo)
  }, [hasProvidedAnyPartnerInfo])

  function handleShowPartnerInfoClick() {
    setShowPartnerInfo(true)
  }

  return (
    <PageLayout
      header={
        <PageHeader
          startGlyph={
            <PageHeaderGlyph src="/images/icn-account-settings.png" srcSet={"/images/icn-account-settings-2x.png"} />
          }
          pageTitle={<FormattedMessage defaultMessage="Account settings" />}
        />
      }
    >
      <Box maxWidth="432px">
        <PersonalInformationSettings />
        <SettingsPageDivider />
        <AddressSettings />
        <SettingsPageDivider />
        <BiometricAuthenticationSettings />
        <EmailAddressesAccountSettings />
        <SettingsPageDivider />
        <AccountDeletion />
        {!isPartnerAccessingAccount && (
          <>
            <PasswordSettings isPartnerSection={false} />
            <SettingsPageDivider />
          </>
        )}
        <LanguageSettings />
        <SettingsPageDivider />
        <CommunicationSettings />
        <FeatureGate filter={(c) => c.isPartnerEnabled}>
          {showPartnerInfo && (
            <>
              <SettingsPageDivider />
              <PartnerInformationSettings />
            </>
          )}
        </FeatureGate>
        <SettingsPageDivider />
        <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxl)} display="flex" justifyContent="flex-end">
          <FormButton disabled={!isDirty || isPosingAsMember} type="submit">
            <FormattedMessage defaultMessage="Save changes" />
          </FormButton>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: textDirection === TextDirection.RightToLeft ? "right" : "left"
          }}
          open={isPosingAsMember}
          message={
            "Editing account settings while posing as a member is not allowed. If you need to update member info, please do so in the Admin app."
          }
        />
      </Box>
      <FeatureGate filter={(c) => c.isPartnerEnabled}>
        {!showPartnerInfo && <PartnerInformationSettingsPrompt onClick={handleShowPartnerInfoClick} />}
      </FeatureGate>
      {showPartnerAccess && !isPartnerAccessingAccount && (
        <PartnerAccessAccountSettingsFooter addPartnerInfo={showPartnerInfo} />
      )}
    </PageLayout>
  )
}
