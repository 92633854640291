enum AttachmentStates {
  UPLOADING = "UPLOADING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  OVERSIZED = "OVERSIZED",
  INVALIDTYPE = "INVALIDTYPE",
  VIRUSDETECTED = "VIRUSDETECTED"
}

export default AttachmentStates
