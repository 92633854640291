import {
  Box,
  Button,
  ButtonProps,
  ButtonTypeMap,
  BackIcon,
  ForwardIcon,
  Stack,
  useTheme
} from "@carrotfertility/carotene-core"
import React, { ReactNode } from "react"
import { FormattedMessage } from "react-intl"

export function SkipButton<RootComponent extends React.ElementType = ButtonTypeMap["defaultComponent"]>({
  children,
  onClick,
  ...otherProps
}: ButtonProps<RootComponent>): JSX.Element {
  return (
    <Button {...otherProps} onClick={onClick} color="secondary" variant="text">
      {children}
    </Button>
  )
}

export function ContinueButton({
  children,
  onClick,
  disabled
}: {
  children: ReactNode | ReactNode[]
  onClick?: (val?: any) => void
  disabled?: boolean
}): JSX.Element {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      type="submit"
      color="secondary"
      variant="outlined"
      endIcon={<ForwardIcon />}
    >
      {children}
    </Button>
  )
}

type RegistrationActionBarProps = {
  primaryAction: React.ReactNode
  secondaryAction?: React.ReactNode
  tertiaryAction?: React.ReactNode
}

export function RegistrationActionBar(props: RegistrationActionBarProps) {
  return (
    <Box marginTop={(theme) => theme.spacing(theme.tokens.spacing.xl)} display="flex" flexDirection="row">
      {props.tertiaryAction ? (
        <Box
          sx={{
            marginInlineEnd: "auto"
          }}
        >
          {props.tertiaryAction}
        </Box>
      ) : null}
      {props.secondaryAction}
      {props.primaryAction}
    </Box>
  )
}

/**
 * Recommendation would be to use RegistrationActionBar instead if possible
 */
export function RegistrationButtonFooter({
  continueButton,
  skipButton,
  exploreBenefitButton,
  getCarrotPlanButton,
  onBack
}: {
  continueButton?: ReactNode
  skipButton?: ReactNode
  exploreBenefitButton?: ReactNode
  getCarrotPlanButton?: ReactNode
  onBack?: () => void
}): JSX.Element {
  const theme = useTheme()

  return (
    <Box
      marginTop={(theme) => theme.spacing(theme.tokens.spacing.xl)}
      display="flex"
      justifyContent={onBack ? "space-between" : "flex-end"}
    >
      {onBack ? (
        <Button onClick={onBack} variant="text" color="secondary" startIcon={<BackIcon />}>
          <FormattedMessage defaultMessage="Back" />
        </Button>
      ) : null}
      <Stack
        direction={{ xs: exploreBenefitButton || getCarrotPlanButton ? "column" : "row", sm: "row" }}
        spacing={theme.spacing(theme.tokens.spacing.sm)}
      >
        {exploreBenefitButton}
        {getCarrotPlanButton}
        {skipButton}
        {continueButton}
      </Stack>
    </Box>
  )
}
