import React from "react"
import AriaModal from "react-aria-modal"
import PropTypes from "prop-types"

const getApplicationNode = () => document.getElementById("body")

const ActionModal = (props) => (
  <AriaModal
    {...props}
    getApplicationNode={getApplicationNode}
    underlayColor="rgba(181, 173, 166, 0.6)"
    verticallyCenter
    focusDialog
  >
    <div className={`relative mw-1280 pa3-ns pa2`}>
      <div className={`flex flex-column overflow-hidden bg-white shadow-modal br3`}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <div tabIndex={0}>{props.children}</div>
      </div>
    </div>
  </AriaModal>
)

ActionModal.propTypes = {
  onExit: PropTypes.func.isRequired,
  titleText: PropTypes.string.isRequired
}

export default ActionModal
