import { Box, useTheme } from "@carrotfertility/carotene-core"
import React, { useEffect } from "react"
import { FertilityMedicationSavingSection } from "../../features/carrot-rx/digital-front-door/FertilityMedicationSavingSection"
import { DeliverySection } from "../../features/carrot-rx/components/DeliverySection"
import { WhyMembersLoveCarrotRxSection } from "../../features/carrot-rx/components/WhyMembersLoveCarrotRxSection"
import { ExceptionalCareSection } from "../../features/carrot-rx/components/ExceptionalCareSection"
import { StartSavingWithCarrotRxSection } from "../../features/carrot-rx/digital-front-door/StartSavingWithCarrotRxSection"
import { Footer } from "../../features/carrot-rx/digital-front-door/Footer"
import { Header } from "../../features/carrot-rx/digital-front-door/Header"
import { useNavigateToDigitalFrontDoor } from "components/views/app/navigation/hooks/useNavigateToDigitalFrontDoor"
import { useHistory } from "react-router-dom"
import { NavigationTab } from "../../features/carrot-rx/digital-front-door/NavigationTab"
import { useCurrentUser } from "components/context/user/UserContext"
import { Title } from "services/page-title/Title"
import { useIntl } from "react-intl"

export function DigitalFrontDoorPage() {
  const { navigateToDigitalFrontDoor, isLoading } = useNavigateToDigitalFrontDoor()
  const { isInAppLockdown } = useCurrentUser()
  const history = useHistory()
  const intl = useIntl()
  const theme = useTheme()

  useEffect(() => {
    if (!navigateToDigitalFrontDoor && !isLoading) {
      history.push("/your-benefit-guide/pharmacy")
    }
  }, [history, navigateToDigitalFrontDoor, isLoading])

  return navigateToDigitalFrontDoor ? (
    <Box display="flex" flexDirection="column" width="100%" minHeight="100vh">
      <Title title={intl.formatMessage({ defaultMessage: "Carrot Rx" })} />

      <Header isInAppLockdown={isInAppLockdown} />
      <NavigationTab isInAppLockdown={isInAppLockdown} />
      <FertilityMedicationSavingSection isInAppLockdown={isInAppLockdown} />
      <DeliverySection isInAppLockdown={isInAppLockdown} />
      <WhyMembersLoveCarrotRxSection />
      <ExceptionalCareSection backgroundColor={theme.palette.background.paper} />
      {!isInAppLockdown && <StartSavingWithCarrotRxSection />}
      <Footer />
    </Box>
  ) : null
}
