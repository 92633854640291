import { InputAdornment } from "@carrotfertility/carotene-core"
import { FormSelect, FormTextField, useWatch } from "@carrotfertility/carotene-core-x"
import { usePhoneCodeSelectOptions } from "components/phone-number-collection/usePhoneCodeSelectOptions"
import { CountryCode } from "content/CountryCode"
import React from "react"
import { useIntl } from "react-intl"
import { getCountryCallingCode, isValidPhoneNumber } from "libphonenumber-js"

export function PhoneNumberAndCountryCode({
  phoneCountryCode,
  phoneNumber
}: {
  phoneCountryCode: "phoneCountryCode" | "partnerPhoneCountryCode"
  phoneNumber: "phoneNumber" | "partnerPhoneNumber"
}): JSX.Element {
  const intl = useIntl()
  const PhoneCodeSelectOptions = usePhoneCodeSelectOptions()
  const countryCodeSelection = useWatch({ name: phoneCountryCode })
  const phoneCode = getCountryCallingCode(countryCodeSelection as CountryCode)

  return (
    <>
      <FormSelect
        options={PhoneCodeSelectOptions}
        controllerProps={{
          rules: {
            required: intl.formatMessage({ defaultMessage: "Required" })
          }
        }}
        label={intl.formatMessage({ defaultMessage: "Country code" })}
        name={phoneCountryCode}
      />
      <FormTextField
        registerOptions={{
          required: intl.formatMessage({ defaultMessage: "Required" }),
          validate: {
            validPhoneNumber: (value) => {
              return (
                isValidPhoneNumber(value, countryCodeSelection) ||
                intl.formatMessage({
                  defaultMessage: "Invalid format for the selected country code. Please review and try again"
                })
              )
            }
          }
        }}
        label={intl.formatMessage({ defaultMessage: "Phone number" })}
        name={phoneNumber}
        InputProps={{
          startAdornment: phoneCode ? (
            <InputAdornment position="start">
              {intl.formatMessage({ defaultMessage: "+{phoneCode}" }, { phoneCode: phoneCode })}
            </InputAdornment>
          ) : null
        }}
      />
    </>
  )
}
