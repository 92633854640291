import { Box, Stack, Theme, Typography, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { DigitalFrontDoorSectionLayout } from "./DigitalFrontDoorSectionLayout"
import { FormattedMessage } from "react-intl"
import { FillYourPrescriptionButton } from "./FillYourPrescriptionButton"
import { GetACarrotPlanButton } from "./GetACarrotPlanButton"
import { useDigitalFrontDoorBreakpointViews } from "../hooks/useDigitalFrontDoorBreakpointViews"

function OverlayBadge({
  invertColors,
  leftOffset,
  children
}: {
  invertColors: boolean
  leftOffset: string
  children: React.ReactNode
}): JSX.Element {
  const { palette } = useTheme()
  const backgroundColor = invertColors ? palette.secondary.contrastText : palette.secondary.main
  const textColor = invertColors ? palette.secondary.main : palette.secondary.contrastText

  return (
    <Box
      width="9.5rem"
      position="absolute"
      bottom="1.25rem"
      left={leftOffset}
      padding="0.625rem 1rem"
      textAlign="center"
      sx={{ backgroundColor: backgroundColor }}
    >
      <Typography variant="overline" color={textColor} fontWeight="unset">
        {children}
      </Typography>
    </Box>
  )
}

export function DeliverySection({ isInAppLockdown }: { isInAppLockdown: boolean }) {
  const { isMobileView, isUnderXLScreenView } = useDigitalFrontDoorBreakpointViews()
  return (
    <Box id="fast-and-free-delivery">
      <DigitalFrontDoorSectionLayout backgroundColor={"#F1EFEB"}>
        <Stack
          direction={isUnderXLScreenView ? "column" : "row"}
          minHeight={isMobileView ? "32rem" : "40rem"}
          gap={isMobileView ? (theme) => theme.tokens.spacing.lg : "6.375rem"}
          justifyContent="space-between"
          paddingY={isMobileView ? (theme: Theme) => theme.tokens.spacing.xxl : "7.5rem"}
        >
          <Stack
            direction="column"
            maxWidth="31.25rem"
            gap={isMobileView ? (theme) => theme.tokens.spacing.lg : (theme) => theme.tokens.spacing.xxl}
          >
            <Typography variant="h2" fontSize={isMobileView ? "1.75rem" : "2.5rem"}>
              <FormattedMessage defaultMessage="Fast and free delivery" />
            </Typography>
            <Typography
              variant="body1"
              fontSize={isMobileView ? "0.813rem" : "1.25rem"}
              lineHeight={isMobileView ? "1.219" : "1.875rem"}
            >
              <FormattedMessage
                defaultMessage="Free <b>same or next-day delivery to your home</b> means no missed cycles due to medication mishaps."
                values={{
                  b: (boldContent) => (
                    <Typography component="span" fontWeight="bold" fontSize="inherit">
                      {boldContent}
                    </Typography>
                  )
                }}
              />
            </Typography>
            {isInAppLockdown ? <GetACarrotPlanButton /> : <FillYourPrescriptionButton />}
          </Stack>
          <Box
            position="relative"
            minWidth={isUnderXLScreenView ? "unset" : "31.25rem"}
            height={isMobileView ? "14.5rem" : "24.5rem"}
          >
            <Box
              component="img"
              src={require("../resources/images/carrot-rx-couple.jpg")}
              alt=""
              height={isMobileView ? "14.5rem" : "24.5rem"}
              borderRadius="0 2rem 0 0"
              sx={{ objectFit: "cover" }}
            />
            <OverlayBadge invertColors={false} leftOffset="1.25rem">
              <FormattedMessage defaultMessage="Nick and Eunice" />
            </OverlayBadge>
            <OverlayBadge invertColors={true} leftOffset="11.25rem">
              <FormattedMessage defaultMessage="Carrot Members" />
            </OverlayBadge>
          </Box>
        </Stack>
      </DigitalFrontDoorSectionLayout>
    </Box>
  )
}
