import { Steps } from "../steps"
import { Workflows } from "../workflows"
import {
  ActiveStepFunction,
  GetWorkflowFunction,
  InitialStepFunction,
  ShouldSeeUserAgreementFunction,
  TotalStepsFunction
} from "./routing-helper-types"

export const getInitialRequestCardStep: InitialStepFunction = ({ showInformationScreen, isUsa }) => {
  if (showInformationScreen && isUsa) return Steps.REQUEST_CARD_INFORMATION
  if (!showInformationScreen && isUsa) return Steps.MAILING_ADDRESS
  return Steps.CONFIRM_PHONE_NUMBER
}

export const getStripeShouldSeeUserAgreement: ShouldSeeUserAgreementFunction = () => {
  return true
}

export const getStripeWorkflow: GetWorkflowFunction = () => Workflows.REQUEST_CARD

export const getTotalStepsStripeRequestCard: TotalStepsFunction = ({ isUsa }) => {
  return isUsa ? 4 : 3
}

export const getActiveStepStripeRequestCard: ActiveStepFunction = (step, { isUsa }) => {
  let activeSection = -1
  switch (step) {
    case Steps.MAILING_ADDRESS:
      activeSection = 1
      break
    case Steps.CONFIRM_PHONE_NUMBER:
    case Steps.VERIFY_PHONE_NUMBER:
      activeSection = 2
      break
    case Steps.STRIPE_USER_AGREEMENT:
      activeSection = 3
      break
  }
  if (!isUsa) {
    activeSection -= 1
  }
  return activeSection
}
