import React, { ReactElement } from "react"
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  useTheme
} from "@carrotfertility/carotene-core"
import { useSocialDeterminantsOfHealthMutation } from "./useSocialDeterminantsOfHealth"
import { FormattedMessage, useIntl } from "react-intl"
import { YES_NO_BUTTON_OPTIONS } from "../carrot-plans/shared/yesNoButtonOptions"
import { SDoHFindHelpLink } from "./SDoHLinks"
import useGetUserInfoConditionsSubset from "../../services/user/hooks/useGetCurrentUserConditionsSubset"

export const SDoHQuestionnaireDialog = ({
  open,
  setOpen
}: {
  open: boolean
  setOpen: (open: boolean) => void
}): ReactElement => {
  const [showThankYou, setShowThankYou] = React.useState(false)
  const userInfoSubsetQuery = useGetUserInfoConditionsSubset()

  async function handleClose() {
    setOpen(false)
    await userInfoSubsetQuery.refetch()
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <>
        <DialogCloseButton handleClose={handleClose} />
        {showThankYou ? (
          <SearchForResourcesInnerDialog />
        ) : (
          <SDoHQuestionnaireInnerDialog setShowThankYou={setShowThankYou} />
        )}
      </>
    </Dialog>
  )
}

const SDoHQuestionnaireInnerDialog = ({ setShowThankYou }: { setShowThankYou: (value: boolean) => void }) => {
  const socialDeterminantsOfHealthMutation = useSocialDeterminantsOfHealthMutation()

  async function handleResponse(hasIssuesAffectingWellBeing: string): Promise<void> {
    await socialDeterminantsOfHealthMutation.mutateAsync(
      { hasIssuesAffectingWellBeing: hasIssuesAffectingWellBeing },
      {
        onSuccess: () => {
          setShowThankYou(true)
        }
      }
    )
  }

  return (
    <>
      <DialogTitle id="sdoh-title">
        <FormattedMessage defaultMessage="Are there any issues affecting your well-being or access to healthcare?" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="sdoh-description">
          <FormattedMessage defaultMessage="These issues may include difficulty with basic needs (like food or housing), challenges with education or employment, unsafe living conditions, or trouble getting medical care. Your answer helps us provide local resources for any social or economic challenges you may be experiencing." />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <YesNoButtonOptions handleResponse={handleResponse} isLoading={socialDeterminantsOfHealthMutation.isLoading} />
      </DialogActions>
    </>
  )
}

const YesNoButtonOptions = ({
  handleResponse,
  isLoading
}: {
  handleResponse: (value: string) => void
  isLoading: boolean
}): ReactElement => {
  const theme = useTheme()

  return (
    <Stack
      spacing={theme.spacing(theme.tokens.spacing.sm)}
      sx={{ display: "inline-flex", flexDirection: "row-reverse" }}
    >
      {YES_NO_BUTTON_OPTIONS.map((option) => (
        <Button
          key={option.value}
          color="secondary"
          disabled={isLoading}
          variant="outlined"
          onClick={() => handleResponse(option.value)}
        >
          <FormattedMessage {...option.label} />
        </Button>
      ))}
    </Stack>
  )
}

const SearchForResourcesInnerDialog = () => {
  return (
    <>
      <DialogTitle id="sdoh-questionnaire-title">
        <FormattedMessage defaultMessage="Thank you. You can now search for resources in your area." />
      </DialogTitle>
      <DialogActions>
        <Box
          sx={{
            marginInlineEnd: "auto"
          }}
        >
          <SDoHFindHelpLink />
        </Box>
      </DialogActions>
    </>
  )
}

const DialogCloseButton = ({ handleClose }: { handleClose: () => void }) => {
  const intl = useIntl()

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end"
      }}
    >
      <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}
