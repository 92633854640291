import React from "react"
import { useHistory } from "react-router-dom"
import { useCaseRateRenewals } from "../useCaseRateRenewals"
import { ThanksForLettingUsKnowContent } from "../ThanksForLettingUsKnowContent"

export function ThanksForLettingUsKnowStep(): JSX.Element {
  const history = useHistory()
  const { setOpenGatedAccountModal } = useCaseRateRenewals()

  function close(): void {
    setOpenGatedAccountModal(false)
    history.push("/")
  }

  return <ThanksForLettingUsKnowContent {...{ close }} />
}
