import React from "react"
import { Redirect, Route, useHistory, useParams } from "react-router-dom"
import { LandingPageState } from "./LandingPage"

import { Section } from "./utils/utils"
import { PageRouter } from "./PageRouter"
import { Switch } from "services/routing/Switch"

const SectionRouter = ({
  menu,
  willShowSurvey,
  sections,
  guideRootPath,
  guideSlugId,
  guideTitle
}: {
  menu: JSX.Element
  willShowSurvey: boolean
  sections: Section[]
  guideRootPath: string
  guideSlugId: string
  guideTitle: string
}): JSX.Element => {
  const history = useHistory()
  const params = useParams<{ section: string }>()
  const section = sections.find(({ slug }) => slug === params.section)
  const currentSectionIndex = sections.indexOf(section)
  const isLastSection = currentSectionIndex >= sections.length - 1
  const isFirstSection = currentSectionIndex === 0
  const returnHome = (landingPageState?: LandingPageState): void => history.push(guideRootPath, landingPageState || {})

  const sectionRootPath = `${guideRootPath}/${params.section}`

  const handleOnLastPageNext = (): void => {
    if (!isLastSection) {
      history.push(`${guideRootPath}/${sections[currentSectionIndex + 1].slug}/1`)
    } else if (isLastSection && willShowSurvey) {
      history.push(`${guideRootPath}/survey`)
    } else {
      returnHome({ isPostGuide: true })
    }
  }
  const handleOnFirstPageBack = (): void => {
    const priorSection = sections[currentSectionIndex - 1]
    history.push(`${guideRootPath}/${priorSection.slug}/${priorSection.pages.length}`)
  }

  if (!section) {
    history.push(guideRootPath)
    return null
  }

  return (
    <Switch>
      <Route
        exact
        path="/read/:category/guide/:guide/:section/:pageNumber"
        component={() => {
          return (
            <PageRouter
              menu={menu}
              onLastPageNext={handleOnLastPageNext}
              onFirstPageBack={handleOnFirstPageBack}
              isLastSection={isLastSection}
              willShowSurvey={willShowSurvey}
              section={section}
              isFirstSection={isFirstSection}
              sectionRootPath={sectionRootPath}
              guideSlugId={guideSlugId}
              guideTitle={guideTitle}
            />
          )
        }}
      />
      <Redirect to="/read/:category/guide/:guide/:section/1" />
    </Switch>
  )
}

export { SectionRouter }
