import { useMedicalInfoWorkflow } from "../hooks/useMedicalInfoFlow"
import { Steps as JourneySteps } from "../steps/journey/step-library"
import { journeySteps, journeyWorkflows, Workflows as JourneyWorkflows } from "../steps/journey/workflow"
import {
  medicalQuestionsSteps,
  medicalQuestionsWorkflows,
  Steps as MedicalQuestionsSteps
} from "../steps/medical-questions/workflows"
import { StateMachineProvider, StateMachineStepView } from "../../context/stateMachine/StateMachineV2"
import { RoutingFlowContainer } from "./RoutingFlowContainer"
import React, { useEffect } from "react"
import { useBodyClass } from "../../../utils/Hooks"
import { useHistory } from "react-router-dom"
import useCarrotPlanEmployeeSettings from "../hooks/useCarrotPlanEmployeeSettings"
import { CaseRateRenewalsStatus, useGetCaseRateRenewalStatus } from "../case-rate-renewals/useQueryCaseRateRenewals"
import { Progress } from "@carrotfertility/carotene-core"

export enum Sections {
  JOURNEY = "Journey",
  MEDICAL = "Medical"
}

export default function UpdateRoutingFlow(): JSX.Element {
  useBodyClass("bg-warm-gray")
  const { workflow: desiredMedicalWorkflow } = useMedicalInfoWorkflow()
  const { data, showAdvancedCheckInFeatures } = useGetCaseRateRenewalStatus()
  const { isDoneForNow, appIsUnlockedAndPendingCarrotPlan, isLoading } = useCarrotPlanEmployeeSettings()
  const history = useHistory()
  const isJourneyChangeFlow = window.location.href.includes("/carrot-plans/journey-change/routing")

  useEffect(() => {
    if (
      !isJourneyChangeFlow &&
      data !== CaseRateRenewalsStatus.GATED &&
      !showAdvancedCheckInFeatures &&
      !isDoneForNow &&
      !appIsUnlockedAndPendingCarrotPlan &&
      !isLoading
    ) {
      history.push("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [data, isLoading, isDoneForNow, appIsUnlockedAndPendingCarrotPlan])

  const journeySection = {
    name: Sections.JOURNEY,
    initialStep: JourneySteps.WHAT_KIND_OF_JOURNEY,
    desiredWorkflow: JourneyWorkflows.JOURNEY,
    steps: journeySteps,
    workflows: journeyWorkflows
  }

  const medicalQuestionsSection = {
    name: Sections.MEDICAL,
    initialStep: MedicalQuestionsSteps.WELCOME,
    desiredWorkflow: desiredMedicalWorkflow,
    steps: medicalQuestionsSteps,
    workflows: medicalQuestionsWorkflows
  }

  return isLoading ? (
    <Progress />
  ) : (
    <StateMachineProvider
      initialSection={Sections.JOURNEY}
      stateMachineDescription={[journeySection, medicalQuestionsSection]}
      onComplete={null}
    >
      <RoutingFlowContainer>
        <StateMachineStepView />
      </RoutingFlowContainer>
    </StateMachineProvider>
  )
}
