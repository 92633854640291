import { createReducer } from "reducers/shared"
import actionTypes from "actions/actionTypes"
import { RootState } from "reducers/index"
import { BenefitConfiguration } from "types/benefitConfiguration"
import { Reducer } from "react"
import TestUserActionTypes from "actions/testUserActionTypes"

const { FETCH_BENEFIT_CONFIGURATION_SUCCESS, FETCH_BENEFIT_CONFIGURATION_FAILURE } = actionTypes
const {
  //feature actions
  TOGGLE_IS_PARTNER_FEATURE_ENABLED,
  TOGGLE_IS_CARROT_RX_FEATURE_ENABLED,
  TOGGLE_IS_GC_FEATURE_ENABLED,
  TOGGLE_IS_DONOR_FEATURE_ENABLED,
  TOGGLE_IS_LGBTQ_FEATURE_ENABLED,
  TOGGLE_IS_ADOPTION_FEATURE_ENABLED,
  TOGGLE_IS_BANK_INFO_FEATURE_ENABLED,
  TOGGLE_IS_SUPPORT_CENTER_FEATURE_ENABLED,
  TOGGLE_IS_CARROT_PREGNANCY_FEATURE_ENABLED,
  TOGGLE_IS_LOW_T_FEATURE_ENABLED,
  TOGGLE_IS_MENOPAUSE_FEATURE_ENABLED,
  TOGGLE_IS_GROSS_UP_FEATURE_ENABLED,
  TOGGLE_IS_CUSTOM_COVERAGE_CONTENT_FEATURE_ENABLED,
  TOGGLE_IS_PREGNANCY_AND_POSTPARTUM_COVERAGE_FEATURE_ENABLED,
  TOGGLE_IS_DOULA_EXPENSES_COVERAGE_ENABLED,
  TOGGLE_IS_ADDITIONAL_PREGNANCY_EXPENSES_COVERAGE_ENABLED,
  TOGGLE_IS_PARTNER_CREDENTIAL_ACCESS_ENABLED,

  //coverage actions
  TOGGLE_IS_GC_COVERAGE_ENABLED,
  TOGGLE_IS_COVERAGE_COPAY_ENABLED,
  TOGGLE_ARE_COVERAGE_IVF_REQUIREMENTS_ENABLED,
  TOGGLE_IS_ADOPTION_COVERAGE_ENABLED,
  TOGLLE_IS_INFERTILITY_COVERAGE_ENABLED,
  TOGGLE_IS_ELECTIVE_ART_COVERAGE_ENABLED,
  TOGGLE_IS_PRESERVATION_COVERAGE_ENABLED,
  TOGGLE_IS_MEMBER_AND_PARTNER_ELIGIBLE_COVERAGE,
  TOGGLE_IS_ELECTIVE_PRESERVATION_COVERAGE_ENABLED,
  TOGGLE_IS_INFERTILITY_DIAGNOSIS_COVERAGE_ENABLED,
  TOGGLE_IS_MEDICALLY_NECESSARY_PRESERVATION_COVERAGE_ENABLED,
  TOGGLE_IS_DONOR_ASSISTANCE_COVERAGE_ENABLED,
  TOGGLE_IS_PARTNER_ELIGIBLE_IF_ON_CUSTOMER_MEDICAL_PLAN,
  TOGGLE_LEGAL_PARTNERS_ELIGIBLE,
  TOGGLE_IS_PROVIDER_FINDER_HIDE_FERTILITY_CLINICS_ENABLED,
  TOGGLE_IS_PROVIDER_FINDER_HIDE_REPRODUCTIVE_UROLOGY_ENABLED,
  TOGGLE_IS_PROVIDER_FINDER_HIDE_CRYOBANKS_STORAGE_ENABLED,

  //rider actions
  TOGGLE_HAS_INFERTILITY_DIAGNOSIS_RIDER,
  TOGGLE_CAN_CONTINUE_AFTER_EXHAUSTED_RIDER,
  TOGGLE_HAS_MEDICALLY_NECESSARY_PRESERVATION_RIDER,

  //custom coverage action
  UPDATE_CUSTOM_COVERAGE_TEXT,
  UPDATE_ADDITIONAL_EMPLOYER_BENEFITS_TEXT
} = TestUserActionTypes

// @ts-expect-error TS7031
const toggleFeatureAction = ({ featureConfig, ...state }, { type }) => {
  switch (type) {
    case TOGGLE_IS_PARTNER_FEATURE_ENABLED:
      featureConfig.isPartnerEnabled = !featureConfig.isPartnerEnabled
      break
    case TOGGLE_IS_CARROT_RX_FEATURE_ENABLED:
      featureConfig.isCarrotRxEnabled = !featureConfig.isCarrotRxEnabled
      break
    case TOGGLE_IS_GC_FEATURE_ENABLED:
      featureConfig.isGcEnabled = !featureConfig.isGcEnabled
      break
    case TOGGLE_IS_DONOR_FEATURE_ENABLED:
      featureConfig.isDonorEnabled = !featureConfig.isDonorEnabled
      break
    case TOGGLE_IS_LGBTQ_FEATURE_ENABLED:
      featureConfig.isLgtbqEnabled = !featureConfig.isLgtbqEnabled
      break
    case TOGGLE_IS_ADOPTION_FEATURE_ENABLED:
      featureConfig.isAdoptionEnabled = !featureConfig.isAdoptionEnabled
      break
    case TOGGLE_IS_BANK_INFO_FEATURE_ENABLED:
      featureConfig.isBankInfoEnabled = !featureConfig.isBankInfoEnabled
      break
    case TOGGLE_IS_SUPPORT_CENTER_FEATURE_ENABLED:
      featureConfig.isSupportCenterEnabled = !featureConfig.isSupportCenterEnabled
      break
    case TOGGLE_IS_CARROT_PREGNANCY_FEATURE_ENABLED:
      featureConfig.isCarrotPregnancyEnabled = !featureConfig.isCarrotPregnancyEnabled
      break
    case TOGGLE_IS_LOW_T_FEATURE_ENABLED:
      featureConfig.isLowTEnabled = !featureConfig.isLowTEnabled
      break
    case TOGGLE_IS_MENOPAUSE_FEATURE_ENABLED:
      featureConfig.isMenopauseEnabled = !featureConfig.isMenopauseEnabled
      break
    case TOGGLE_IS_GROSS_UP_FEATURE_ENABLED:
      featureConfig.isGrossUpEnabled = !featureConfig.isGrossUpEnabled
      break
    case TOGGLE_IS_CUSTOM_COVERAGE_CONTENT_FEATURE_ENABLED:
      featureConfig.isCustomCoverageContentEnabled = !featureConfig.isCustomCoverageContentEnabled
      break
    case TOGGLE_IS_PREGNANCY_AND_POSTPARTUM_COVERAGE_FEATURE_ENABLED:
      featureConfig.isPregnancyAndPostpartumCoverageEnabled = !featureConfig.isPregnancyAndPostpartumCoverageEnabled
      break
    case TOGGLE_IS_DOULA_EXPENSES_COVERAGE_ENABLED:
      featureConfig.isDoulaExpensesCoverageEnabled = !featureConfig.isDoulaExpensesCoverageEnabled
      break
    case TOGGLE_IS_ADDITIONAL_PREGNANCY_EXPENSES_COVERAGE_ENABLED:
      featureConfig.isAdditionalPregnancyExpensesCoverageEnabled =
        !featureConfig.isAdditionalPregnancyExpensesCoverageEnabled
      break
    case TOGGLE_IS_PARTNER_CREDENTIAL_ACCESS_ENABLED:
      featureConfig.isPartnerCredentialAccessEnabled = !featureConfig.isPartnerCredentialAccessEnabled
      break
  }
  return {
    ...state,
    featureConfig
  }
}

// @ts-expect-error TS7031
const toggleCoverageAction = ({ coverageConfig, ...state }, { type }) => {
  switch (type) {
    case TOGGLE_IS_GC_COVERAGE_ENABLED:
      coverageConfig.isGcEnabled = !coverageConfig.isGcEnabled
      break
    case TOGGLE_IS_COVERAGE_COPAY_ENABLED:
      coverageConfig.isCopayEnabled = !coverageConfig.isCopayEnabled
      break
    case TOGGLE_ARE_COVERAGE_IVF_REQUIREMENTS_ENABLED:
      coverageConfig.areIvfRequirementsEnabled = !coverageConfig.areIvfRequirementsEnabled
      break
    case TOGGLE_IS_ADOPTION_COVERAGE_ENABLED:
      coverageConfig.isAdoptionEnabled = !coverageConfig.isAdoptionEnabled
      break
    case TOGLLE_IS_INFERTILITY_COVERAGE_ENABLED:
      coverageConfig.isInfertilityEnabled = !coverageConfig.isInfertilityEnabled
      break
    case TOGGLE_IS_ELECTIVE_ART_COVERAGE_ENABLED:
      coverageConfig.isElectiveArtEnabled = !coverageConfig.isElectiveArtEnabled
      break
    case TOGGLE_IS_PRESERVATION_COVERAGE_ENABLED:
      coverageConfig.isPreservationEnabled = !coverageConfig.isPreservationEnabled
      break
    case TOGGLE_IS_ELECTIVE_PRESERVATION_COVERAGE_ENABLED:
      coverageConfig.isElectivePreservationEnabled = !coverageConfig.isElectivePreservationEnabled
      break
    case TOGGLE_IS_INFERTILITY_DIAGNOSIS_COVERAGE_ENABLED:
      coverageConfig.isInfertilityDiagnosisEnabled = !coverageConfig.isInfertilityDiagnosisEnabled
      break
    case TOGGLE_IS_MEDICALLY_NECESSARY_PRESERVATION_COVERAGE_ENABLED:
      coverageConfig.isMedicallyNecessaryPreservationEnabled = !coverageConfig.isMedicallyNecessaryPreservationEnabled
      break
    case TOGGLE_IS_DONOR_ASSISTANCE_COVERAGE_ENABLED:
      coverageConfig.isDonorAssistanceEnabled = !coverageConfig.isDonorAssistanceEnabled
      break
    case TOGGLE_IS_MEMBER_AND_PARTNER_ELIGIBLE_COVERAGE:
      coverageConfig.isMemberAndPartnerEligible = !coverageConfig.isMemberAndPartnerEligible
      break
    case TOGGLE_IS_PARTNER_ELIGIBLE_IF_ON_CUSTOMER_MEDICAL_PLAN:
      coverageConfig.isPartnerEligibleIfOnCustomerMedicalPlan = !coverageConfig.isPartnerEligibleIfOnCustomerMedicalPlan
      break
    case TOGGLE_LEGAL_PARTNERS_ELIGIBLE:
      coverageConfig.areLegalPartnersEligible = !coverageConfig.areLegalPartnersEligible
      break
    case TOGGLE_IS_PROVIDER_FINDER_HIDE_FERTILITY_CLINICS_ENABLED:
      coverageConfig.isProviderFinderHideFertilityClinicsEnabled =
        !coverageConfig.isProviderFinderHideFertilityClinicsEnabled
      break
    case TOGGLE_IS_PROVIDER_FINDER_HIDE_REPRODUCTIVE_UROLOGY_ENABLED:
      coverageConfig.isProviderFinderHideReproductiveUrologyEnabled =
        !coverageConfig.isProviderFinderHideReproductiveUrologyEnabled
      break
    case TOGGLE_IS_PROVIDER_FINDER_HIDE_CRYOBANKS_STORAGE_ENABLED:
      coverageConfig.isProviderFinderHideCryobanksStorageEnabled =
        !coverageConfig.isProviderFinderHideCryobanksStorageEnabled
      break
  }
  return {
    ...state,
    coverageConfig
  }
}

// @ts-expect-error TS7031
const toggleRiderAction = ({ rider, ...state }, { type }) => {
  const updateableRider = rider || {}

  switch (type) {
    case TOGGLE_HAS_INFERTILITY_DIAGNOSIS_RIDER:
      updateableRider.hasInfertilityDiagnosisRider = !updateableRider.hasInfertilityDiagnosisRider
      break
    case TOGGLE_CAN_CONTINUE_AFTER_EXHAUSTED_RIDER:
      updateableRider.canContinueAfterExhausted = !updateableRider.canContinueAfterExhausted
      break
    case TOGGLE_HAS_MEDICALLY_NECESSARY_PRESERVATION_RIDER:
      updateableRider.hasMedicallyNecessaryPreservationRider = !updateableRider.hasMedicallyNecessaryPreservationRider
      break
  }
  return {
    ...state,
    rider: updateableRider
  }
}

const benefitConfiguration: Reducer<BenefitConfiguration, any> = createReducer<BenefitConfiguration>(null, {
  // @ts-expect-error TS7006
  [FETCH_BENEFIT_CONFIGURATION_SUCCESS]: (state, action) => {
    const response = action.response

    return {
      ...state,
      benefitConfigLoadError: false,
      coverageConfig: response.coverages,
      featureConfig: response.features,
      customCoverageText: response.customCoverageText,
      additionalEmployerBenefitsText: response.additionalEmployerBenefitsText,
      rider: response.rider
        ? {
            keys: response.rider.keys,
            canContinueAfterExhausted: response.rider.canContinueAfterExhausted
          }
        : null
    }
  },
  [FETCH_BENEFIT_CONFIGURATION_FAILURE]: () => {
    return { benefitConfigLoadError: true }
  },
  [TOGGLE_IS_PARTNER_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_CARROT_RX_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_GC_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_DONOR_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_LGBTQ_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_ADOPTION_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_BANK_INFO_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_SUPPORT_CENTER_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_CARROT_PREGNANCY_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_LOW_T_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_MENOPAUSE_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_GROSS_UP_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_CUSTOM_COVERAGE_CONTENT_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_PREGNANCY_AND_POSTPARTUM_COVERAGE_FEATURE_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_PARTNER_CREDENTIAL_ACCESS_ENABLED]: toggleFeatureAction,
  [TOGGLE_IS_GC_COVERAGE_ENABLED]: toggleCoverageAction,
  [TOGGLE_IS_COVERAGE_COPAY_ENABLED]: toggleCoverageAction,
  [TOGGLE_ARE_COVERAGE_IVF_REQUIREMENTS_ENABLED]: toggleCoverageAction,
  [TOGGLE_IS_ADOPTION_COVERAGE_ENABLED]: toggleCoverageAction,
  [TOGLLE_IS_INFERTILITY_COVERAGE_ENABLED]: toggleCoverageAction,
  [TOGGLE_IS_ELECTIVE_ART_COVERAGE_ENABLED]: toggleCoverageAction,
  [TOGGLE_IS_PRESERVATION_COVERAGE_ENABLED]: toggleCoverageAction,
  [TOGGLE_IS_DONOR_ASSISTANCE_COVERAGE_ENABLED]: toggleCoverageAction,
  [TOGGLE_IS_MEMBER_AND_PARTNER_ELIGIBLE_COVERAGE]: toggleCoverageAction,
  [TOGGLE_IS_PARTNER_ELIGIBLE_IF_ON_CUSTOMER_MEDICAL_PLAN]: toggleCoverageAction,
  [TOGGLE_LEGAL_PARTNERS_ELIGIBLE]: toggleCoverageAction,
  [TOGGLE_IS_ELECTIVE_PRESERVATION_COVERAGE_ENABLED]: toggleCoverageAction,
  [TOGGLE_IS_INFERTILITY_DIAGNOSIS_COVERAGE_ENABLED]: toggleCoverageAction,
  [TOGGLE_IS_MEDICALLY_NECESSARY_PRESERVATION_COVERAGE_ENABLED]: toggleCoverageAction,
  [TOGGLE_IS_PROVIDER_FINDER_HIDE_FERTILITY_CLINICS_ENABLED]: toggleCoverageAction,
  [TOGGLE_IS_PROVIDER_FINDER_HIDE_REPRODUCTIVE_UROLOGY_ENABLED]: toggleCoverageAction,
  [TOGGLE_IS_PROVIDER_FINDER_HIDE_CRYOBANKS_STORAGE_ENABLED]: toggleCoverageAction,

  [TOGGLE_HAS_INFERTILITY_DIAGNOSIS_RIDER]: toggleRiderAction,
  [TOGGLE_CAN_CONTINUE_AFTER_EXHAUSTED_RIDER]: toggleRiderAction,
  [TOGGLE_HAS_MEDICALLY_NECESSARY_PRESERVATION_RIDER]: toggleRiderAction,

  // @ts-expect-error TS7031
  [UPDATE_CUSTOM_COVERAGE_TEXT]: ({ customCoverageText, ...state }) => {
    const defaultText =
      "##Custom Coverage\n Lorem ipsum dolor sit amet\n Nunc blandit bibendum arcu.\n\n - Proin fermentum urna.\n - Curabitur tempor augue."
    const originalCustomCoverageText = customCoverageText
    customCoverageText = customCoverageText ? null : state.originalCustomCoverageText || defaultText

    return {
      ...state,
      customCoverageText,
      originalCustomCoverageText
    }
  },
  // @ts-expect-error TS7031
  [UPDATE_ADDITIONAL_EMPLOYER_BENEFITS_TEXT]: ({ additionalEmployerBenefitsText, ...state }) => {
    const defaultText =
      "##Additional Employer Benefits\n Lorem ipsum dolor sit amet\n Nunc blandit bibendum arcu.\n\n - Proin fermentum urna.\n - Curabitur tempor augue."
    const originalAdditionalEmployerBenefitsText = additionalEmployerBenefitsText
    additionalEmployerBenefitsText = additionalEmployerBenefitsText
      ? null
      : state.originalAdditionalEmployerBenefitsText || defaultText

    return {
      ...state,
      additionalEmployerBenefitsText,
      originalAdditionalEmployerBenefitsText
    }
  }
})

export const getBenefitConfiguration = (state: RootState) => state.benefitConfiguration
export const getBankInfoEnabled = (state: RootState) => state.benefitConfiguration?.featureConfig?.isBankInfoEnabled
export const getCarrotRxFeatureEnabled = (state: RootState) =>
  state.benefitConfiguration?.featureConfig?.isCarrotRxEnabled

export const getIsBenefitConfigurationLoaded = (state: RootState) => state.benefitConfiguration !== null

export const getBenefitConfigurationLoadFailed = (state: RootState) =>
  state.benefitConfiguration?.benefitConfigLoadError

export default benefitConfiguration
