import { BodySmall } from "../Bodies"
import { Spacer } from "../Atoms"
import React, { FC, ReactNode, MouseEvent } from "react"
import { SinglePathSVG } from "../../SVG"
import { MaybeLink, MaybeLinkProps } from "./MaybeLink"

export type ArrowLinkProps = {
  children: ReactNode
  onClick?: (event?: MouseEvent<HTMLAnchorElement>) => any
  onClickOnly?: boolean
  to?: string
  enabled?: boolean
  color?: string
  TextComponent?: React.ComponentType<any>
  arrowRotation?: string
  arrowAfter?: boolean
  weight?: string
  dim?: boolean
} & MaybeLinkProps

/**
 * A link... with an arrow! Arrow can be positioned before or after the text with `arrowAfter`
 * and rotated with `arrowRotation`
 */
const ArrowLink: FC<ArrowLinkProps> = ({
  children,
  onClick,
  onClickOnly,
  to = "#",
  enabled = true,
  color = "black-80",
  TextComponent = BodySmall,
  arrowRotation = "",
  arrowAfter = false,
  weight = "normal",
  dim = true,
  ...props
}: ArrowLinkProps) => {
  const className = `
    flex flex-row no-underline dib animate-all pointer
    ${enabled ? "" : "o-60"}
    ${dim ? "dim" : ""}
    ${color}`

  return (
    <MaybeLink className={className} to={to} onClick={onClick} onClickOnly={onClickOnly} enabled={enabled} {...props}>
      <div className="flex items-start">
        {!arrowAfter && (
          <>
            <ArrowLinkArrow rotation={arrowRotation} />
            <Spacer width={0.5} />
          </>
        )}

        <div>
          <TextComponent color={color} display="di" weight={weight}>
            {children}
          </TextComponent>
        </div>

        {arrowAfter && (
          <>
            <Spacer width={0.5} />
            <ArrowLinkArrow rotation={arrowRotation} />
          </>
        )}
      </div>
    </MaybeLink>
  )
}

const ArrowLinkArrow = ({ rotation }: any) => (
  <div style={{ height: 21 }} className={`dib carrot-orange flex items-center v-mid ${rotation}`}>
    <SinglePathSVG icon="rightArrow" />
  </div>
)

export { ArrowLink, ArrowLinkArrow }
