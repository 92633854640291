import React from "react"
import { Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { Spacer } from "../atoms/Atoms"
import { Body } from "../atoms/Bodies"

export const MerchantDeclineError = () => (
  <>
    <Spacer height={2} />
    <Typography variant="h3">
      <FormattedMessage defaultMessage="We're working on your request" />
    </Typography>
    <Spacer height={1} />
    <Body>
      <FormattedMessage defaultMessage="Unfortunately your request is taking longer than expected. We’ll send you an SMS as soon as the provider is approved." />
    </Body>
    <Spacer height={1} />
  </>
)
