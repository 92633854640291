import cookies from "js-cookie"
import Settings from "./CarrotConfig"

export const enum OneTrustCookieCategory {
  StrictlyNecessary = "C0001",
  Performance = "C0002",
  Functional = "C0003",
  Targeting = "C0004",
  SocialMedia = "C0005"
}

declare global {
  interface Window {
    OptanonWrapper: { __loaded: boolean }
    OneTrust: {
      OnConsentChanged: (callback: () => void) => void
      RejectAll: () => void
      changeLanguage: (lang: string) => void
    }
  }
}

const consentByCookieCategory = new Map<OneTrustCookieCategory, boolean>()

const CONSENT_COOKIE_NAME = "OptanonConsent"
const CONSENT_COOKIE_GROUPS_PARAM_NAME = "groups"
const CONSENT_COOKIE_GROUPS_SEPARATOR = ","
const CONSENT_COOKIE_GROUP_NAME_VALUE_SEPARATOR = ":"

const enum CookieCategoryConsent {
  Accepted = "1",
  Rejected = "0"
}

function updateConsent(): void {
  const consentData = cookies.get(CONSENT_COOKIE_NAME)
  const consentParams = new URLSearchParams(consentData)
  const consentGroups = consentParams.get(CONSENT_COOKIE_GROUPS_PARAM_NAME)

  consentGroups?.split(CONSENT_COOKIE_GROUPS_SEPARATOR)?.forEach((group) => {
    const [category, consent] = group?.split(CONSENT_COOKIE_GROUP_NAME_VALUE_SEPARATOR) || []
    consentByCookieCategory.set(category as OneTrustCookieCategory, consent === CookieCategoryConsent.Accepted)
  })
}

const autoRejectCookieConsent = Settings.AUTO_REJECT_ONE_TRUST_COOKIE_CONSENT ?? false

const initOneTrust = (): void => {
  if (autoRejectCookieConsent) {
    window.OneTrust.RejectAll()
  }

  updateConsent()
  window.OneTrust.OnConsentChanged(updateConsent)
}

const oneTrustLoadedBeforeApp = window.OptanonWrapper?.__loaded

if (oneTrustLoadedBeforeApp) {
  initOneTrust()
} else {
  window.addEventListener("OptanonWrapperLoaded", initOneTrust)
}

export function isOneTrustCookieCategoryAccepted(category: OneTrustCookieCategory): boolean {
  return consentByCookieCategory.get(category)
}
