enum TestUserActionTypes {
  TOGGLE_IS_CARROT_CARD_ELIGIBLE = "TOGGLE_IS_CARROT_CARD_ELIGIBLE",
  TOGGLE_DYNAMIC_CONTENT_TOGGLES_TOOL = "TOGGLE_DYNAMIC_CONTENT_TOGGLES_TOOL",
  TOGGLE_MEMBER_HAS_LEGAL_PARTNER = "TOGGLE_MEMBER_HAS_LEGAL_PARTNER",
  TOGGLE_EXTERNAL_DEDUCTIBLE = "TOGGLE_EXTERNAL_DEDUCTIBLE",
  TOGGLE_HAS_ACA = "TOGGLE_HAS_ACA",
  TOGGLE_IS_AVA_ACTIVE = "TOGGLE_IS_AVA_ACTIVE",
  TOGGLE_SUPPORTS_DEDUCTIBLE_PLANS = "TOGGLE_SUPPORTS_DEDUCTIBLE_PLANS",
  TOGGLE_IS_SAML_ACTIVE = "TOGGLE_IS_SAML_ACTIVE",
  TOGGLE_IS_USA = "TOGGLE_IS_USA",
  TOGGLE_IS_CARROT_LITE = "TOGGLE_IS_CARROT_LITE",
  TOGGLE_SUBJECT_TO_APP_LOCKDOWN = "TOGGLE_SUBJECT_TO_APP_LOCKDOWN",

  TOGGLE_CAN_CONTINUE_AFTER_EXHAUSTED_RIDER = "TOGGLE_CAN_CONTINUE_AFTER_EXHAUSTED_RIDER",
  TOGGLE_HAS_INFERTILITY_DIAGNOSIS_RIDER = "TOGGLE_HAS_INFERTILITY_DIAGNOSIS_RIDER",
  TOGGLE_HAS_MEDICALLY_NECESSARY_PRESERVATION_RIDER = "TOGGLE_HAS_MEDICALLY_NECESSARY_PRESERVATION_RIDER",

  TOGGLE_ARE_COVERAGE_IVF_REQUIREMENTS_ENABLED = "TOGGLE_ARE_COVERAGE_IVF_REQUIREMENTS_ENABLED",
  TOGGLE_IS_COVERAGE_COPAY_ENABLED = "TOGGLE_IS_COVERAGE_COPAY_ENABLED",
  TOGGLE_IS_GC_COVERAGE_ENABLED = "TOGGLE_IS_GC_COVERAGE_ENABLED",
  TOGGLE_IS_ELECTIVE_ART_COVERAGE_ENABLED = "TOGGLE_IS_ELECTIVE_ART_COVERAGE_ENABLED",
  TOGGLE_IS_INFERTILITY_DIAGNOSIS_COVERAGE_ENABLED = "TOGGLE_IS_INFERTILITY_DIAGNOSIS_COVERAGE_ENABLED",
  TOGLLE_IS_INFERTILITY_COVERAGE_ENABLED = "TOGLLE_IS_INFERTILITY_COVERAGE_ENABLED",
  TOGGLE_IS_MEDICALLY_NECESSARY_PRESERVATION_COVERAGE_ENABLED = "TOGGLE_IS_MEDICALLY_NECESSARY_PRESERVATION_COVERAGE_ENABLED",
  TOGGLE_IS_ELECTIVE_PRESERVATION_COVERAGE_ENABLED = "TOGGLE_IS_ELECTIVE_PRESERVATION_COVERAGE_ENABLED",
  TOGGLE_IS_ADOPTION_COVERAGE_ENABLED = "TOGGLE_IS_ADOPTION_COVERAGE_ENABLED",
  TOGGLE_IS_PRESERVATION_COVERAGE_ENABLED = "TOGGLE_IS_PRESERVATION_COVERAGE_ENABLED",
  TOGGLE_IS_MEMBER_AND_PARTNER_ELIGIBLE_COVERAGE = "TOGGLE_IS_MEMBER_AND_PARTNER_ELIGIBLE_COVERAGE",
  TOGGLE_IS_DONOR_ASSISTANCE_COVERAGE_ENABLED = "TOGGLE_IS_DONOR_ASSISTANCE_COVERAGE_ENABLED",
  TOGGLE_IS_PARTNER_ELIGIBLE_IF_ON_CUSTOMER_MEDICAL_PLAN = "TOGGLE_IS_PARTNER_ELIGIBLE_IF_ON_CUSTOMER_MEDICAL_PLAN",
  TOGGLE_LEGAL_PARTNERS_ELIGIBLE = "TOGGLE_LEGAL_PARTNERS_ELIGIBLE",
  TOGGLE_IS_PROVIDER_FINDER_HIDE_FERTILITY_CLINICS_ENABLED = "TOGGLE_IS_PROVIDER_FINDER_HIDE_FERTILITY_CLINICS_ENABLED",
  TOGGLE_IS_PROVIDER_FINDER_HIDE_REPRODUCTIVE_UROLOGY_ENABLED = "TOGGLE_IS_PROVIDER_FINDER_HIDE_REPRODUCTIVE_UROLOGY_ENABLED",
  TOGGLE_IS_PROVIDER_FINDER_HIDE_CRYOBANKS_STORAGE_ENABLED = "TOGGLE_IS_PROVIDER_FINDER_HIDE_CRYOBANKS_STORAGE_ENABLED",

  TOGGLE_IS_CARROT_RX_FEATURE_ENABLED = "TOGGLE_IS_CARROT_RX_FEATURE_ENABLED",
  TOGGLE_IS_BANK_INFO_FEATURE_ENABLED = "TOGGLE_IS_BANK_INFO_FEATURE_ENABLED",
  TOGGLE_IS_PARTNER_FEATURE_ENABLED = "TOGGLE_IS_PARTNER_FEATURE_ENABLED",
  TOGGLE_IS_CARROT_PREGNANCY_FEATURE_ENABLED = "TOGGLE_IS_CARROT_PREGNANCY_FEATURE_ENABLED",
  TOGGLE_IS_LOW_T_FEATURE_ENABLED = "TOGGLE_IS_LOW_T_FEATURE_ENABLED",
  TOGGLE_IS_LOW_T_COVERAGE_FEATURE_ENABLED = "TOGGLE_IS_LOW_T_COVERAGE_FEATURE_ENABLED",
  TOGGLE_IS_MENOPAUSE_FEATURE_ENABLED = "TOGGLE_IS_MENOPAUSE_FEATURE_ENABLED",
  TOGGLE_IS_MENOPAUSE_COVERAGE_FEATURE_ENABLED = "TOGGLE_IS_MENOPAUSE_COVERAGE_FEATURE_ENABLED",
  TOGGLE_IS_GENDER_AFFIRMING_CARE_COVERAGE_FEATURE_ENABLED = "TOGGLE_IS_GENDER_AFFIRMING_CARE_COVERAGE_FEATURE_ENABLED",
  TOGGLE_IS_DONOR_FEATURE_ENABLED = "TOGGLE_IS_DONOR_FEATURE_ENABLED",
  TOGGLE_IS_ADOPTION_FEATURE_ENABLED = "TOGGLE_IS_ADOPTION_FEATURE_ENABLED",
  TOGGLE_IS_SUPPORT_CENTER_FEATURE_ENABLED = "TOGGLE_IS_SUPPORT_CENTER_FEATURE_ENABLED",
  TOGGLE_IS_LGBTQ_FEATURE_ENABLED = "TOGGLE_IS_LGBTQ_FEATURE_ENABLED",
  TOGGLE_IS_GC_FEATURE_ENABLED = "TOGGLE_IS_GC_FEATURE_ENABLED",
  UPDATE_CUSTOM_COVERAGE_TEXT = "UPDATE_CUSTOM_COVERAGE_TEXT",
  UPDATE_ADDITIONAL_EMPLOYER_BENEFITS_TEXT = "UPDATE_ADDITIONAL_EMPLOYER_BENEFITS_TEXT",
  TOGGLE_IS_GROSS_UP_FEATURE_ENABLED = "TOGGLE_IS_GROSS_UP_FEATURE_ENABLED",
  TOGGLE_IS_CUSTOM_COVERAGE_CONTENT_FEATURE_ENABLED = "TOGGLE_IS_CUSTOM_COVERAGE_CONTENT_FEATURE_ENABLED",
  TOGGLE_IS_PREGNANCY_AND_POSTPARTUM_COVERAGE_FEATURE_ENABLED = "TOGGLE_IS_PREGNANCY_AND_POSTPARTUM_COVERAGE_FEATURE_ENABLED",
  TOGGLE_IS_DOULA_EXPENSES_COVERAGE_ENABLED = "TOGGLE_IS_DOULA_EXPENSES_COVERAGE_ENABLED",
  TOGGLE_IS_ADDITIONAL_PREGNANCY_EXPENSES_COVERAGE_ENABLED = "TOGGLE_IS_ADDITIONAL_PREGNANCY_EXPENSES_COVERAGE_ENABLED",
  TOGGLE_IS_MIRA_ACTIVE = "TOGGLE_IS_MIRA_ACTIVE",
  TOGGLE_IS_PARTNER_CREDENTIAL_ACCESS_ENABLED = "TOGGLE_IS_PARTNER_CREDENTIAL_ACCESS_ENABLED",

  UPDATE_COUNTRY_CODE = "UPDATE_COUNTRY_CODE"
}

export type TestUserActionType = keyof typeof TestUserActionTypes

export default TestUserActionTypes
