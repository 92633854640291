import React from "react"
import { Link, DialogContent, DialogContentText, DialogTitle } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { Link as ReactRouterLink } from "react-router-dom"

function UpdatePhoneNumberCompletedStep({ onClose }: { onClose: () => void }) {
  return (
    <>
      <DialogTitle>
        <FormattedMessage defaultMessage="Phone number verified & updated" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage defaultMessage="Questions or concerns?" />
          &nbsp;
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <Link onClick={onClose} component={ReactRouterLink} to="/talk-to-carrot">
            <FormattedMessage defaultMessage="Contact the Carrot Care Team" />
          </Link>
        </DialogContentText>
      </DialogContent>
    </>
  )
}

export { UpdatePhoneNumberCompletedStep }
