import React from "react"
import { IlloDocAndCoins } from "components/views/atoms/Illustrations"
import Locations from "components/views/home/navigation"
import { useHistory } from "react-router-dom"
import EmployeeOnlyModalContainer from "../employee-only-containers/EmployeeOnlyModalContainer"
import { FormattedMessage } from "react-intl"
import {
  Button,
  useTheme,
  Dialog,
  Box,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions
} from "@carrotfertility/carotene-core"

// @ts-expect-error TS7031
export function EraseYourAnswersModal({ onExit }): JSX.Element {
  const history = useHistory()
  const handleQleResetClick = (): void => history.push(Locations.Home.QleEnrollmentActive)
  const theme = useTheme()

  return (
    <EmployeeOnlyModalContainer onClose={onExit}>
      <Dialog open={true} onClose={onExit} aria-labelledby="step-heading">
        <Box justifyContent="center" display="flex" paddingY={theme.spacing(theme.tokens.spacing.lg)}>
          <IlloDocAndCoins />
        </Box>
        <DialogTitle id="step-heading">
          <FormattedMessage defaultMessage="Erase existing answers?" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage defaultMessage="All of the insurance information you provided previously will be deleted so that you can make corrections." />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ textWrap: "no-wrap" }} color="secondary" variant="outlined" onClick={onExit}>
            <FormattedMessage defaultMessage="No, I don't need to update" />
          </Button>
          <Button sx={{ textWrap: "no-wrap" }} onClick={handleQleResetClick}>
            <FormattedMessage defaultMessage="Yes, I need to update" />
          </Button>
        </DialogActions>
      </Dialog>
    </EmployeeOnlyModalContainer>
  )
}
