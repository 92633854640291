import styled from "styled-components"
import React, { FC, ReactNode } from "react"

type LabelProps = {
  color?: string
  children: ReactNode
}

const Label: FC<LabelProps> = ({ children, color = "black-60", ...props }) => (
  <StyledLabel className={`gotham ${color}`} {...props}>
    {children}
  </StyledLabel>
)

const StyledLabel = styled.span`
  letter-spacing: 0.06em;
  display: block;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 17px;
`

export { Label }
