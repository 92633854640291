import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"

export default function MenopauseDiagnosisDecisionStep(): JSX.Element {
  const { hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const { send } = useStateMachine()
  useEffect(() => {
    send(hasQuestionBeenSkipped("menopauseDiagnosis") ? "NO" : medicalInfo?.menopauseDiagnosis)
  })
  return <></>
}
