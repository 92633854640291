import { Box, SelectedIcon, Typography, Progress, Button, ForwardIcon } from "@carrotfertility/carotene-core"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { getStepMenuItemDisplayText } from "components/home/CarrotPlansHero"
import React from "react"
import { useIntl, FormattedMessage } from "react-intl"
import { CarrotPlan } from "types/carrotPlanTypes"
import useCarrotPlanMenuItems, { CarrotPlanStepMenuItem } from "../hooks/useCarrotPlanMenuItems"
import { CaseRateRenewalModalButtonGroup } from "./CaseRateRenewalModalButtonGroup"
import { CaseRateRenewalModalTopSection } from "./CaseRateRenewalModalTopSection"
import { OPTIONS } from "./steps/CarrotPlanOptionsStep"
import { AdvancedCheckInEmailLinkQueryString, useCaseRateRenewals } from "./useCaseRateRenewals"
import { useRepublishCarrotPlan, useGetCaseRateRenewalStatus } from "./useQueryCaseRateRenewals"
import { Steps } from "./workflow"
import queryString from "query-string"

export function DisplayCarrotPlan({ steps, locale }: { steps: CarrotPlanStepMenuItem[]; locale: string }): JSX.Element {
  return (
    <Box
      padding={(theme) => theme.spacing(theme.tokens.spacing.md)}
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      bgcolor={(theme) => theme.palette.background.default}
    >
      {steps.map((step) => (
        <Box key={step.menuItemId} display="flex" padding={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
          <Box marginRight={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
            {step.completedAtUtc !== null ? (
              <SelectedIcon />
            ) : (
              <Box padding={(theme) => theme.spacing(theme.tokens.spacing.sm)} />
            )}
          </Box>
          <Typography>{getStepMenuItemDisplayText(step, locale)}</Typography>
        </Box>
      ))}
    </Box>
  )
}

export function CarrotPlanOptionsContent({ carrotPlan }: { carrotPlan: CarrotPlan }): JSX.Element {
  const intl = useIntl()
  const { locale } = intl
  const { step } = queryString.parse(window.location.search)
  const { data: carrotPlanStepMenuItems, isLoading: menuItemsLoading } = useCarrotPlanMenuItems(
    carrotPlan?.steps,
    locale
  )
  const { send, back } = useStateMachine(
    step === Steps.UPDATE_CARROT_PLAN_DETAILS || step === AdvancedCheckInEmailLinkQueryString.REFRESH_PLAN
      ? OPTIONS.REFRESH_MY_PLAN
      : null
  )
  const { mutate, isLoading } = useRepublishCarrotPlan()
  const { setRequestError, setDisableModalCloseOnLoading } = useCaseRateRenewals()
  const { showAdvancedCheckInFeatures } = useGetCaseRateRenewalStatus()

  function onError(): void {
    setRequestError(true)
    setDisableModalCloseOnLoading(false)
    send("ERROR")
  }

  function onSuccess(): void {
    send(OPTIONS.KEEP_MY_PLAN)
    setDisableModalCloseOnLoading(false)
  }

  async function keepMyPlan(): Promise<void> {
    setDisableModalCloseOnLoading(true)
    mutate(null, {
      onSuccess,
      onError
    })
  }

  return menuItemsLoading ? (
    <Progress />
  ) : (
    <>
      <CaseRateRenewalModalTopSection
        header={intl.formatMessage({
          defaultMessage: "Here's your most recent Carrot Plan. Would you like some new suggestions?"
        })}
      />
      <Box>
        <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="If the plan below is no longer supporting your journey, we'd love to refresh it. If it's still working, you're welcome to keep it for now and request more guidance whenever you're ready." />
        </Typography>
        <Box paddingY={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          <DisplayCarrotPlan steps={carrotPlanStepMenuItems} locale={locale} />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="text"
            color="secondary"
            disabled={isLoading}
            onClick={() => send(OPTIONS.DONE_FOR_NOW)}
            endIcon={<ForwardIcon />}
          >
            <FormattedMessage defaultMessage="I'm done using Carrot" />
          </Button>
        </Box>
        <CaseRateRenewalModalButtonGroup
          back={!showAdvancedCheckInFeatures && back}
          disableBack={isLoading}
          buttonOne={
            <Button variant="outlined" color="inherit" disabled={isLoading} onClick={keepMyPlan}>
              <FormattedMessage defaultMessage="Keep my plan" />
            </Button>
          }
          buttonTwo={
            <Button disabled={isLoading} variant="contained" onClick={() => send(OPTIONS.REFRESH_MY_PLAN)}>
              <FormattedMessage defaultMessage="Refresh my plan" />
            </Button>
          }
        />
      </Box>
    </>
  )
}
