import { CenteredLoadingIndicator } from "../components/views/molecules/Molecules"
import { useFormContext } from "react-hook-form"
import React, { useContext } from "react"
import { ResetPasswordContext } from "../components/containers/login/ResetPasswordContainer"
import { Alert, Typography, Stack, useTheme, TextField, Button, Box } from "@carrotfertility/carotene-core"
import { AppAccessPageLayout } from "components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "components/access-page/AppAccessPageCard"
import { Form } from "../components/views/atoms/forms/Form"
import { Link as RouterLink } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"

const GuidErrorMessage = (): JSX.Element => {
  const theme = useTheme()
  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)} marginBottom={theme.spacing(theme.tokens.spacing.md)}>
      <Alert severity="error">
        <FormattedMessage defaultMessage="This password reset link has already been used. Please request a new one." />
      </Alert>
      <Box textAlign="center">
        <Button component={RouterLink} to={"/login"}>
          <FormattedMessage defaultMessage="Back to sign in" />
        </Button>
      </Box>
    </Stack>
  )
}

const ResetPasswordForm = (): JSX.Element => {
  const intl = useIntl()
  const {
    formState: { errors },
    register
  } = useFormContext()
  const { onSubmit, isSubmitting } = useContext(ResetPasswordContext)
  const theme = useTheme()
  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)} textAlign="center">
      <Stack spacing={theme.spacing(theme.tokens.spacing.xxxs)}>
        <Typography variant="h1">
          <FormattedMessage defaultMessage="Reset password" />
        </Typography>
        {errors.password ? (
          <Alert severity="error" sx={{ textAlign: "start" }}>
            {errors.password?.message.toString()}
          </Alert>
        ) : (
          <Typography>
            <FormattedMessage defaultMessage="Please enter the new password you would like to use." />
          </Typography>
        )}
      </Stack>
      <TextField
        InputLabelProps={{ sx: { textAlign: "left" } }}
        {...register("password")}
        error={Boolean(errors.password)}
        label={intl.formatMessage({ defaultMessage: "Password" })}
        type="password"
        id="password"
      />
      <TextField
        InputLabelProps={{ sx: { textAlign: "left" } }}
        {...register("confirmPassword")}
        error={Boolean(errors.confirmPassword)}
        label={intl.formatMessage({ defaultMessage: "Confirm password" })}
        type="password"
        id="confirmPassword"
      />
      <div>
        <Button disabled={isSubmitting} onClick={!isSubmitting ? onSubmit : null}>
          <FormattedMessage defaultMessage="Reset password" />
        </Button>
      </div>
    </Stack>
  )
}

const ResetPassword = (): JSX.Element => {
  const intl = useIntl()
  const { onSubmit, isLoaded, isGuidError } = useContext(ResetPasswordContext)

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Sign in" })}>
      <AppAccessPageCard>
        <Form onSubmit={onSubmit}>
          {(() => {
            if (isGuidError) return <GuidErrorMessage />
            if (isLoaded) return <ResetPasswordForm />
            return (
              <Box padding={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}>
                <CenteredLoadingIndicator />
              </Box>
            )
          })()}
        </Form>
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}

export default ResetPassword
