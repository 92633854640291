import { FormRadioGroup } from "@carrotfertility/carotene-core-x"
import React from "react"
import { useIntl } from "react-intl"

export function PreferredContactMethod({
  name
}: {
  name: "preferredContactMethod" | "partnerPreferredContactMethod"
}): JSX.Element {
  const intl = useIntl()
  return (
    <FormRadioGroup
      label={intl.formatMessage({ defaultMessage: "Preferred contact method" })}
      options={[
        { label: intl.formatMessage({ defaultMessage: "Email" }), value: "Email" },
        { label: intl.formatMessage({ defaultMessage: "Phone" }), value: "Phone" }
      ]}
      controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
      name={name}
    />
  )
}
