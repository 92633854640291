import { defineMessage } from "react-intl"

export const employeeOnlyHeaderText = defineMessage({ defaultMessage: "You've reached a member-only space" })

export const employeeOnlyText = defineMessage({
  defaultMessage:
    "For privacy and legal reasons, this space is only available to the primary Carrot member. For access, please ask the member to sign in to their account."
})

export const employeeOnlyLink = "/send-a-message/?verification=personal-email"
