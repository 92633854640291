import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { UserInfo } from "lib/carrot-api/types/UserInfo"
import { carrotClient } from "utils/CarrotClient"

export default function useMemberQuery<TData>(select?: (data: UserInfo) => TData): UseQueryResult<TData> {
  return useQuery({
    queryFn: () => carrotClient.getCurrentUser(),
    queryKey: ["member"],
    select
  })
}
