import React from "react"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useMedicalInfoWorkflow } from "../../hooks/useMedicalInfoFlow"
import { MaleMedicalHistoryOptions, FemaleMedicalHistoryOptions } from "./MedicalInfoOptions"
import {
  StepLayout,
  ButtonFooterLayout,
  ContinueButton,
  SkipButton,
  RoutingFlowCheckboxGroup,
  useConvertTranslatedLabel,
  RoutingFlowTitle
} from "components/carrot-plans/shared"
import { FormattedMessage, useIntl } from "react-intl"
import { Form } from "@carrotfertility/carotene-core-x"

type FormValues = {
  medicalHistory?: string[]
}

export default function MedicalHistoryStep(): JSX.Element {
  const intl = useIntl()
  const { IsAMAB, IsAFAB } = useMedicalInfoWorkflow()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultValue = IsAMAB ? medicalInfo?.maleMedicalHistory : IsAFAB ? medicalInfo?.femaleMedicalHistory : ""
  const buttonOptions = IsAMAB ? MaleMedicalHistoryOptions : IsAFAB ? FemaleMedicalHistoryOptions : null

  const { send } = useStateMachine(defaultValue || hasQuestionBeenSkipped("medicalHistory") ? "" : null)

  const options = useConvertTranslatedLabel(buttonOptions)

  async function onClickContinue(values: FormValues): Promise<void> {
    const selected = values?.medicalHistory || []
    let medicalInfoPayload = {}
    let nextStep = ""

    if (IsAMAB) {
      medicalInfoPayload = {
        maleMedicalHistory: selected
      }
    } else if (IsAFAB) {
      medicalInfoPayload = {
        femaleMedicalHistory: selected
      }

      if (values?.medicalHistory.includes("PELVIC_ABDOMEN_SURGERY")) {
        nextStep = "PELVIC_ABDOMEN_SURGERY"
      }
    }

    await updateMedicalInfo(medicalInfoPayload)
    send(nextStep)
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["medicalHistory"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Do you have any of the following in your medical history?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="medicalHistory-label">{header}</span>}
        description={<FormattedMessage defaultMessage="Select all that apply." />}
        formElements={
          <Form
            defaultValues={{
              medicalHistory: defaultValue || []
            }}
            onSubmit={onClickContinue}
          >
            <RoutingFlowCheckboxGroup name="medicalHistory" aria-labelledby="medicalHistory-label" options={options} />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
