import { Button } from "@carrotfertility/carotene-core"
import React from "react"
import { FormattedMessage } from "react-intl"
import { TextDirection } from "../../../../types/Locale"
import { useTextDirection } from "../../../../services/locale/hooks/useTextDirection"

export function SearchThisLocationButton({ onClick }: { onClick: () => void }): JSX.Element {
  const textDirection = useTextDirection()
  return (
    <Button
      onClick={onClick}
      id="provider-finder-search-this-location-button"
      variant="contained"
      size="small"
      color="secondary"
      sx={{
        position: "absolute",
        insetBlockStart: 0,
        insetInlineStart: "50%",
        marginBlockStart: "24px",
        transform: textDirection === TextDirection.LeftToRight ? "translate(-50%, 0)" : "translate(50%, 0)"
      }}
    >
      <FormattedMessage defaultMessage="Search this location" />
    </Button>
  )
}
