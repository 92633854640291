import { useEffect } from "react"
import { SupportedLocale } from "../types/Locale"

declare global {
  interface Window {
    heap: any
  }
}

export type HeapPropertyValue = number | string
interface Heap {
  track: (event: string, properties?: Record<string, HeapPropertyValue>) => void
  identify: (identity: string) => void
  resetIdentity: () => void
  addUserProperties: (properties: Record<string, HeapPropertyValue>) => void
  addEventProperties: (properties: Record<string, HeapPropertyValue>) => void
  removeEventProperty: (property: string) => void
  clearEventProperties: () => void
  appid: string
  userId: string
  identity: string | null
  config: Record<string, HeapPropertyValue>
}

type GetHeap = () => Heap

export const getHeap: GetHeap = () => window.heap

export function useTrackView(event: string, eventName: string): void {
  useEffect(() => {
    getHeap().track(event, { EventName: eventName })
  }, [event, eventName])
}

export enum HeapUserPropertyName {
  CarrotUserId = "CarrotUser Id",
  EmployeeId = "Employee Id",
  CompanyId = "Company Id",
  CountryCode = "Country Code",
  ParentCompanyId = "Parent Company Id",
  CompanyIsExcludedFromMetrics = "Company Is Excluded From Metrics",
  PreferredLocale = "Preferred Locale"
}

enum HeapEventPropertyName {
  IsPosingAsMember = "Is Posing As Member?",
  PosingAsEmployeeId = "Posing As Employee Id",
  Locale = "Locale",
  IsPartnerAccount = "Is Partner Account"
}

export enum HeapBoolean {
  TRUE = "true",
  FALSE = "false"
}

/* see https://www.typescriptlang.org/docs/handbook/declaration-merging.html#module-augmentation:~:text=namespaces%20can%20be%20used%20to%20extend%20enums%20with%20static%20members
 * and https://github.com/typescript-eslint/typescript-eslint/issues/5066 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace HeapBoolean {
  export function from(value: boolean): HeapBoolean {
    return value ? HeapBoolean.TRUE : HeapBoolean.FALSE
  }
}

export const setHeapPosingAsMember = (posingAsEmployeeId: string): void => {
  getHeap().addEventProperties({
    [HeapEventPropertyName.IsPosingAsMember]: HeapBoolean.TRUE,
    [HeapEventPropertyName.PosingAsEmployeeId]: posingAsEmployeeId
  })
}

export const unsetHeapPosingAsMember = (): void => {
  getHeap().addEventProperties({
    [HeapEventPropertyName.IsPosingAsMember]: HeapBoolean.FALSE
  })

  getHeap().removeEventProperty(HeapEventPropertyName.PosingAsEmployeeId)
}

export const setHeapLocale = (locale: SupportedLocale): void => {
  getHeap().addEventProperties({
    [HeapEventPropertyName.Locale]: locale?.toString()
  })
}

export const setHeapPartnerInfo = (isPartnerAccount: boolean): void => {
  getHeap().addEventProperties({
    [HeapEventPropertyName.IsPartnerAccount]: HeapBoolean.from(isPartnerAccount)
  })
}
