import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import { Paths } from "utils/Paths"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header/page-header"
import { TalkToCarrotLegal } from "./TalkToCarrotLegal"
import { Title } from "services/page-title/Title"
import { FormattedMessage, useIntl } from "react-intl"
import { SmallHeaderOverride, RootBreadcrumb } from "./util/talkToCarrotMolecules"
import TwoColumnRightPrimary from "components/views/atoms/layouts/TwoColumnRightPrimary"
import WorldVectorMap from "./TalkToCarrotMap"
import { PageOption } from "components/dynamic-content/util/dynamicContent"
import { GuidanceElement } from "lib/contentful/components/GuidanceElement"
import { TalkToCarrotSelectOption } from "./TalkToCarrotSelectOption"
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Breadcrumbs,
  Breadcrumb,
  useTheme,
  Stack
} from "@carrotfertility/carotene-core"
import { Spacer } from "components/views/atoms/Atoms"
import { useGetTalkToCarrotLocationGuidance } from "./hooks/useGetTalkToCarrotLocationGuidance"
import { FullScreenLoading } from "components/read/shared/FullScreenLoading"
import { useCurrentUser } from "../context/user/UserContext"
import { TalkToCarrotContext } from "./TalkToCarrotContext"
import { LocalizedPageOption } from "./TalkToCarrotRouter"
import { useResponsiveTalkToCarrotComponent } from "./hooks/useResponsiveTalkToCarrotComponent"

const TalkToCarrotLocationPicker = ({
  talkToCarrotLocations,
  onSelectedLocationChanged
}: {
  talkToCarrotLocations: LocalizedPageOption[] | null
  onSelectedLocationChanged: (location: LocalizedPageOption) => void
}): JSX.Element => {
  const history = useHistory()
  const {
    company: { countryCode }
  } = useCurrentUser()
  const intl = useIntl()
  const { locale } = intl
  const theme = useTheme()
  const [hoveredLocation, setHoveredLocation] = useState<PageOption>(null)
  const { selectedLocationOption } = useContext(TalkToCarrotContext)

  const { data: guidanceData, isLoading } = useGetTalkToCarrotLocationGuidance(locale)
  const guidance = <GuidanceElement guidance={guidanceData} />

  const onSubmit = (): void => {
    history.push(`${Paths.TALK_TO_CARROT}/${selectedLocationOption.slug}/specialist`)
  }

  function handleSelectLocation(_event: any, locationOption: LocalizedPageOption): void {
    setHoveredLocation(null)
    onSelectedLocationChanged(locationOption)
  }

  const continueButton = (
    <div className={"ml-auto"}>
      <Button
        color="primary"
        size="medium"
        onClick={onSubmit}
        disabled={!selectedLocationOption}
        data-id="ttc-specialist-select-location-button"
        data-prefilledlocation={countryCode}
        data-selectedlocation={selectedLocationOption?.value}
        data-selectedlocationmatchesprefilled={countryCode === selectedLocationOption?.value}
      >
        <FormattedMessage defaultMessage="Continue" />
      </Button>
    </div>
  )

  const locationSelector = (
    <Autocomplete
      aria-label={intl.formatMessage({ defaultMessage: "Preferred location" })}
      disablePortal
      multiple={false}
      onChange={handleSelectLocation}
      value={selectedLocationOption}
      getOptionLabel={(option) => option.formattedLabel}
      options={talkToCarrotLocations || []}
      clearOnEscape
      renderOption={(props, state) => (
        <TalkToCarrotSelectOption
          {...props}
          key={`${state.value}-${state.pageId}`}
          isSelected={state.value === selectedLocationOption?.value}
          onMouseOver={() => setHoveredLocation(state)}
          onMouseOut={() => setHoveredLocation(null)}
        >
          {state.formattedLabel}
        </TalkToCarrotSelectOption>
      )}
      renderInput={(params) => (
        <TextField
          placeholder={intl.formatMessage({
            defaultMessage: "Select option"
          })}
          name={intl.formatMessage({
            defaultMessage: "location"
          })}
          {...params}
        />
      )}
    />
  )

  const PickerFooter = (): JSX.Element => (
    <Box display="flex" alignContent="center" paddingTop={theme.spacing(theme.tokens.spacing.sm)}>
      {continueButton}
    </Box>
  )

  const map = (
    <WorldVectorMap
      selectedLocationId={selectedLocationOption ? selectedLocationOption.value.toLowerCase() : ""}
      hoveredLocationId={hoveredLocation ? hoveredLocation.value.toLowerCase() : ""}
      onMouseEnter={({ target }: any): void =>
        setHoveredLocation(talkToCarrotLocations.find(({ value }) => value.toLowerCase() === target.id.toLowerCase()))
      }
      onMouseLeave={(): void => setHoveredLocation(null)}
      onClick={(event: any): void => {
        const optionClicked = talkToCarrotLocations.find(
          ({ value }) => value.toLowerCase() === event?.target.id.toLowerCase()
        )
        if (optionClicked) {
          handleSelectLocation(event, optionClicked)
        }
      }}
    />
  )

  const locationPickerWithMap = (
    <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
      {map}
      {locationSelector}
      <PickerFooter />
    </Stack>
  )
  const locationPicker = (
    <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
      {locationSelector}
      <PickerFooter />
    </Stack>
  )

  const largeContent = <TwoColumnRightPrimary secondaryContent={guidance} primaryContent={locationPickerWithMap} />

  const mediumContent = (
    <>
      {guidance}
      <Spacer height={3} />
      {map}
      {locationPicker}
    </>
  )

  const smallContent = (
    <>
      <SmallHeaderOverride>
        {guidance}
        <Spacer height={2} />
        {locationPicker}
      </SmallHeaderOverride>
    </>
  )

  const responsiveContent = useResponsiveTalkToCarrotComponent(smallContent, mediumContent, largeContent)

  const pageContent = (
    <PageLayout
      footer={<TalkToCarrotLegal />}
      header={
        <PageBreadcrumbHeader
          breadcrumbs={
            <Breadcrumbs>
              <RootBreadcrumb />
              <Breadcrumb currentPage>
                <FormattedMessage defaultMessage="Location" />
              </Breadcrumb>
            </Breadcrumbs>
          }
        />
      }
    >
      <Title
        title={intl.formatMessage({
          defaultMessage: "Preferred location"
        })}
        productTitle={intl.formatMessage({
          defaultMessage: "Talk to Carrot"
        })}
      />
      {guidance && responsiveContent}
    </PageLayout>
  )
  if (isLoading) {
    return <FullScreenLoading />
  }
  return <>{talkToCarrotLocations && pageContent}</>
}

export { TalkToCarrotLocationPicker }
