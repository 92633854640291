import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { carrotClient } from "../../../utils/CarrotClient"
import buildCarrotApiRetryPolicy from "../../../utils/CarrotApiRetryPolicy"
import { MessagingAuthResponse } from "../../../types/zendeskTypes"
import ResponseError from "../../../types/responseError"

export const useGetZendeskMessagingWidgetSettings = (): UseQueryResult<MessagingAuthResponse, ResponseError> => {
  return useQuery<MessagingAuthResponse, ResponseError>(
    ["useGetZendeskMessagingWidgetSettings"],
    () => {
      return carrotClient.getZendeskMessagingWidgetSettings()
    },
    {
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
