import { Box, Theme, useMediaQuery } from "@carrotfertility/carotene-core"
import React, { ReactNode } from "react"

export function DigitalFrontDoorSectionLayout({
  children,
  backgroundColor
}: {
  children: ReactNode
  backgroundColor: string
}) {
  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
  const responsivePadding = isMobileView ? (theme: Theme) => theme.tokens.spacing.lg : "7.5rem"
  return (
    <Box
      sx={{
        background: backgroundColor
      }}
      width="100%"
      paddingX={responsivePadding}
    >
      {children}
    </Box>
  )
}
