import React from "react"
import styled from "styled-components"
import carrotPartnerMarker from "../../resources/images/carrot-partner-marker.svg"
import selectedCarrotPartnerMarker from "../../resources/images/carrot-partner-marker-selected.svg"
import regularProviderMarker from "../../resources/images/regular-provider-marker.svg"
import selectedRegularProviderMarker from "../../resources/images/regular-provider-marker-selected.svg"

type MarkerProps = {
  isCarrotPartner: boolean
  lat: number
  lng: number
  isSelected?: boolean
  providerUuid: number
  providerName: string
}

const StyledMarker = styled.img<{ isSelected: boolean }>(({ isSelected }) => ({
  height: isSelected ? "40px" : "30px",
  width: isSelected ? "40px" : "30px",
  cursor: "pointer",
  transform: "translate(-50%, -100%)",
  "&:hover": {
    filter: "drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.4))",
    transform: "translate(-50%, -120%)"
  },
  fill: "currentColor",
  color: "black"
}))

export function Marker(props: MarkerProps): JSX.Element {
  const { isCarrotPartner, providerUuid, providerName, isSelected } = props
  const getImageSrc = (): string => {
    if (isCarrotPartner) {
      if (isSelected) {
        return selectedCarrotPartnerMarker
      }
      return carrotPartnerMarker
    }
    if (isSelected) {
      return selectedRegularProviderMarker
    }
    return regularProviderMarker
  }

  return (
    <StyledMarker
      className="provider-finder-map-provider-marker"
      data-provider-uuid={providerUuid}
      alt={`A marker to denote the location of ${providerName}`}
      src={getImageSrc()}
      isSelected={isSelected}
    />
  )
}
