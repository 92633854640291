import { MessageDescriptor, defineMessage } from "react-intl"

export type Survey = {
  title: string
  questions: { name: MessageDescriptor; body: MessageDescriptor }[]
}

type SurveyOption = {
  value: string
  label: MessageDescriptor
}

export const surveyOptions: SurveyOption[] = [
  {
    value: "5",
    label: defineMessage({ defaultMessage: "Strongly agree" })
  },
  {
    value: "4",
    label: defineMessage({ defaultMessage: "Agree" })
  },
  {
    value: "3",
    label: defineMessage({ defaultMessage: "Neither agree nor disagree" })
  },
  {
    value: "2",
    label: defineMessage({ defaultMessage: "Disagree" })
  },
  {
    value: "1",
    label: defineMessage({ defaultMessage: "Strongly disagree" })
  }
]

export const surveyQuestions: Survey = {
  title: "Survey",
  questions: [
    {
      name: defineMessage({
        defaultMessage: "Before you leave, we have three quick questions to get your feedback on this guide. "
      }),
      body: defineMessage({
        defaultMessage:
          "Your responses won't affect your benefit or care in any way, and will help us improve your experience."
      })
    },
    {
      name: defineMessage({
        defaultMessage: "This guide helps me feel more confident taking the next steps in my journey."
      }),
      body: defineMessage({ defaultMessage: "Please select the option that best applies to you." })
    },
    {
      name: defineMessage({
        defaultMessage: "This guide provides useful information to help me take the next steps in my journey."
      }),
      body: defineMessage({ defaultMessage: "Please select the option that best applies to you." })
    },
    {
      name: defineMessage({
        defaultMessage: "This guide helps me feel better prepared to talk to a provider about my journey."
      }),
      body: defineMessage({ defaultMessage: "Please select the option that best applies to you." })
    }
  ]
}
