import { reportErrorMessage } from "utils/ErrorReporting"

// @ts-expect-error TS7006
export function validateContentSource(source, overrides) {
  const customMarkdownTags = Object.keys(overrides).filter(
    (markdownKey) => markdownKey.charAt(0) === markdownKey.charAt(0).toUpperCase()
  )
  return contentValidator(source, customMarkdownTags)
}

function contentValidator(source: string, customMarkdownTags: string[]) {
  if (!source) {
    return null
  }

  const invalidOpeningTags = customMarkdownTags.reduce((accum, markdownTag) => {
    const openingCarat = "<"
    const downCasedMarkdownTag = markdownTag.charAt(0).toLowerCase() + markdownTag.slice(1)
    return { ...accum, [openingCarat + downCasedMarkdownTag]: openingCarat + markdownTag }
  }, {})

  let validatedSource = source

  Object.keys(invalidOpeningTags).forEach((invalidTag) => {
    if (validatedSource.includes(invalidTag)) {
      // @ts-expect-error TS7053
      validatedSource = validatedSource.replace(invalidTag, invalidOpeningTags[invalidTag])
      // @ts-expect-error TS7053
      reportErrorMessage(`Replaced an invalid markdown tag "${invalidTag}" with "${invalidOpeningTags[invalidTag]}`)
    } else {
      return null
    }
  })
  return validatedSource
}
