import React, { FC } from "react"
import { Link, LinkProps, InternalIcon } from "@carrotfertility/carotene-core"
import { getSupportCenterLink, goToHelpCenter } from "carrot-api/HelpCenterClient"

type ZendeskArrowLinkProps = {
  supportCenterLink?: string
} & LinkProps

const ZendeskArrowLink: FC<ZendeskArrowLinkProps> = ({ supportCenterLink, ...props }: ZendeskArrowLinkProps) => {
  let zdLinkFragment = supportCenterLink
  // Contentful zendesk links will be provided via the "to" and they will be full URLs to zendesk, so parse out the fragment
  // e.g. https://get-carrot.zendesk.com/hc/sections/4410088379931-Carrot-Plans
  if (props.href) {
    const path = props.href?.split("/")
    zdLinkFragment = path[path.length - 2] + "/" + path[path.length - 1]
  }

  return (
    <Link
      href={getSupportCenterLink(zdLinkFragment)}
      // @ts-expect-error TS7006
      onClick={(event) => goToHelpCenter(event, zdLinkFragment)}
      target={"_blank"}
      endIcon={<InternalIcon fontSize="small" color="primary" />}
    >
      {props.children}
    </Link>
  )
}

export { ZendeskArrowLink }
