import React from "react"
// eslint-disable-next-line no-restricted-imports
import { Switch as ReactRouterSwitch, Route, Redirect } from "react-router-dom"

export function validateSwitchChildren(children: React.ReactNode, allowedRouters?: React.FC[]) {
  const routers = allowedRouters ?? []
  const allowedChildren = [Switch, Route, Redirect, ...routers]
  React.Children.forEach(children, (child: any) => {
    if (child && !allowedChildren.includes(child.type)) {
      throw new Error(
        "Switch only allows Route, Redirect, and any custom routers passed to the allowRouters prop as children. If you'd like to add an allowed custom router - add your component to the allowedRouters prop on Switch. Note: all custom routers must return Route or Redirect"
      )
    }
  })
}

export function Switch({
  children,
  allowedRouters
}: {
  children: React.ReactNode
  allowedRouters?: React.FC[]
}): JSX.Element {
  validateSwitchChildren(children, allowedRouters)

  return <ReactRouterSwitch>{children}</ReactRouterSwitch>
}
