import { colors } from "@carrotfertility/carotene"
import React, { useCallback, useContext, useMemo, useState } from "react"
import useEmailVerification from "./useEmailVerification"
import RemoveEmailModal from "./RemoveEmailModal"
import AddEmailAddressModal from "./AddEmailAddressModal"
import EditEmailAddressModal from "./EditEmailAddressModal"
import VerificationSentModal from "./VerificationSentModal"
import RemoveEmailTooltip from "./RemoveEmailTooltip"
import { useUserRole } from "../partner-access/hooks/usePartnerAccess"
import { SettingsContext } from "../../pages/account"
import { Button, Chip, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { PiiText } from "services/tracking"

export function EmailWithChip({
  email,
  chipLabel,
  chipColor = "default"
}: {
  email: string
  chipLabel: string
  chipColor?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning"
}): JSX.Element {
  const theme = useTheme()
  return (
    <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.xs)} useFlexGap flexWrap="wrap">
      <Typography component={PiiText} fontWeight="bold">
        {email}
      </Typography>
      <Chip label={chipLabel} color={chipColor} />
    </Stack>
  )
}

function NoPersonalEmail({ openDialog, email }: { openDialog: () => void; email: string }): JSX.Element {
  const { isPartnerAccessingAccount } = useUserRole()
  const theme = useTheme()
  const intl = useIntl()
  function openAddPersonalEmailModal(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    openDialog()
  }

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
      <EmailWithChip
        chipLabel={intl.formatMessage({ defaultMessage: "contact", description: "main contact email" })}
        chipColor="secondary"
        email={email}
      />
      {!isPartnerAccessingAccount && (
        <div>
          <Button variant="outlined" color="secondary" onClick={openAddPersonalEmailModal}>
            <FormattedMessage defaultMessage="Add another email address" />
          </Button>
        </div>
      )}
    </Stack>
  )
}

function VerifiedPersonalEmail({
  email,
  personalEmail,
  openDialog
}: {
  email: string
  personalEmail: string
  openDialog: () => void
}): JSX.Element {
  const { isPartnerAccessingAccount } = useUserRole()
  const theme = useTheme()
  const intl = useIntl()
  function openRemovePersonalEmailModal(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    openDialog()
  }
  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <EmailWithChip
        chipLabel={intl.formatMessage({ defaultMessage: "Sign-in", description: "Email used to sign in" })}
        chipColor="secondary"
        email={email}
      />
      <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
        <EmailWithChip
          chipLabel={intl.formatMessage({ defaultMessage: "Contact", description: "Main contact email" })}
          chipColor="secondary"
          email={personalEmail}
        />
        {!isPartnerAccessingAccount && (
          <Stack direction={"row"} spacing={theme.spacing(theme.tokens.spacing.xxs)}>
            <Button variant="outlined" color="secondary" onClick={openRemovePersonalEmailModal}>
              <FormattedMessage defaultMessage="Remove this email" />
            </Button>
            <RemoveEmailTooltip color={colors.base.black} />
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

function UnverifiedPersonalEmail({
  email,
  personalEmail,
  openDialog
}: {
  email: string
  personalEmail: string
  openDialog: () => void
}): JSX.Element {
  const { setIsModalForm } = useContext(SettingsContext)
  const [openVerificationSentModal, setOpenVerificationSentModal] = useState(false)
  const [verificationSending, setVerificationSending] = useState(false)
  const { sendVerificationEmail } = useEmailVerification()
  const { isPartnerAccessingAccount } = useUserRole()
  const theme = useTheme()
  const intl = useIntl()

  function openUpdatePersonalEmailDialog(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    openDialog()
  }

  // @ts-expect-error TS7006
  async function openVerificationModal(event) {
    event.preventDefault()
    setIsModalForm(true)
    setVerificationSending(true)
    await sendVerificationEmail()
    setVerificationSending(false)
    setOpenVerificationSentModal(true)
  }

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
      <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
        <EmailWithChip
          chipLabel={intl.formatMessage({ defaultMessage: "Contact", description: "Main contact email" })}
          chipColor="secondary"
          email={email}
        />
        <EmailWithChip
          chipLabel={intl.formatMessage({ defaultMessage: "Unverified" })}
          chipColor="error"
          email={personalEmail}
        />
      </Stack>
      {!isPartnerAccessingAccount && (
        <Stack direction={"row"} spacing={theme.spacing(theme.tokens.spacing.xs)}>
          <Button variant="outlined" color="secondary" onClick={openUpdatePersonalEmailDialog}>
            <FormattedMessage defaultMessage="Edit" />
          </Button>
          <Button variant="outlined" color="secondary" onClick={openVerificationModal} disabled={verificationSending}>
            {verificationSending
              ? intl.formatMessage({ defaultMessage: "Sending verification" })
              : intl.formatMessage({ defaultMessage: "Send verification" })}
          </Button>
        </Stack>
      )}
      <VerificationSentModal open={openVerificationSentModal} onClose={() => setOpenVerificationSentModal(false)} />
    </Stack>
  )
}

export default function EmailAddressesAccountSettings(): JSX.Element {
  const { setIsModalForm } = useContext(SettingsContext)
  const theme = useTheme()
  const { data: personalEmailVerified, email, personalEmail, removePersonalEmail } = useEmailVerification()
  const [openedModal, setOpenedModal] = useState<"add" | "edit" | "remove" | null>(null)
  const [removeDisabled, setRemoveDisabled] = useState(false)

  const openDialog = useCallback(
    (modalName: "add" | "edit" | "remove") => {
      return () => {
        setIsModalForm(true)
        setOpenedModal(modalName)
      }
    },
    [setIsModalForm]
  )

  function closeModal() {
    setOpenedModal(null)
    setIsModalForm(false)
  }

  async function onRemovePersonalEmail(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    setRemoveDisabled(true)
    await removePersonalEmail()
    setRemoveDisabled(false)
  }

  const emailSection = useMemo(() => {
    if (personalEmail === null) {
      return <NoPersonalEmail openDialog={openDialog("add")} {...{ email }} />
    }

    if (personalEmailVerified) {
      return <VerifiedPersonalEmail openDialog={openDialog("remove")} {...{ email, personalEmail }} />
    }

    return <UnverifiedPersonalEmail openDialog={openDialog("edit")} {...{ email, personalEmail }} />
  }, [email, openDialog, personalEmail, personalEmailVerified])

  return (
    <Stack>
      <Typography variant="h2" id={"email-addresses"}>
        <FormattedMessage defaultMessage="Email addresses" />
      </Typography>
      <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
        <Typography variant="body1">
          <FormattedMessage defaultMessage="The address you use to sign in won't change, but if you'd like us to contact you at a different address, add that here." />
        </Typography>
        {emailSection}
      </Stack>
      <AddEmailAddressModal open={openedModal === "add"} closeModal={closeModal} />
      <RemoveEmailModal
        {...{ removeDisabled, onRemovePersonalEmail }}
        open={openedModal === "remove"}
        closeModal={closeModal}
      />
      <EditEmailAddressModal
        {...{
          removeDisabled,
          onRemovePersonalEmail
        }}
        open={openedModal === "edit"}
        closeModal={closeModal}
      />
    </Stack>
  )
}
