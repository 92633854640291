enum ExpenseState {
  PENDING_MEMBER_INFO = "PENDING_MEMBER_INFO",
  PENDING_CARE_NAV = "PENDING_CARE_NAV",
  APPROVED = "APPROVED",
  DENIED_RECONCILED = "DENIED_RECONCILED",
  DENIED_RECONCILIATION_REQUESTED = "DENIED_RECONCILIATION_REQUESTED",
  DENIED = "DENIED",
  PAID = "PAID",
  RECEIVED = "RECEIVED",
  NEEDS_MEMBER_UPLOADS = "NEEDS_MEMBER_UPLOADS",
  ON_HOLD = "ON_HOLD"
}
export { ExpenseState }
