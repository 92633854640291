import { TalkToCarrotLegal } from "components/talk-to-carrot/TalkToCarrotLegal"
import { GuidanceElement } from "lib/contentful/components/GuidanceElement"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetSendMessageGuidance } from "./hooks/useGetSendMessageGuidance"
import TwoColumnRightPrimary from "components/views/atoms/layouts/TwoColumnRightPrimary"
import { SendMessage } from "./send-message/SendMessage"
import { talkToCarrotSource } from "./send-message/SendMessageUtil"
import { RootBreadcrumb } from "./util/talkToCarrotMolecules"
import { Link as ReactRouterLink } from "react-router-dom"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header"
import { Breadcrumb, Breadcrumbs } from "@carrotfertility/carotene-core"

export const TalkToCarrotSendAMessage = (): JSX.Element => {
  const intl = useIntl()
  const { locale } = intl
  const { data: guidance } = useGetSendMessageGuidance(locale)

  return (
    <PageLayout
      footer={<TalkToCarrotLegal />}
      header={
        <PageBreadcrumbHeader
          breadcrumbs={
            <Breadcrumbs>
              <RootBreadcrumb />
              <Breadcrumb component={ReactRouterLink} to={"/talk-to-carrot/care-navigation"}>
                <FormattedMessage defaultMessage="Care Navigation" />
              </Breadcrumb>
              <Breadcrumb currentPage>
                <FormattedMessage defaultMessage="Send a message" />
              </Breadcrumb>
            </Breadcrumbs>
          }
        />
      }
    >
      <TwoColumnRightPrimary
        secondaryContent={<GuidanceElement guidance={guidance} />}
        primaryContent={<SendMessage source={talkToCarrotSource.SEND_A_MESSAGE} />}
      />
    </PageLayout>
  )
}
