import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import { useCheckRoutingInfoQueryCache, useUpdateRoutingInfo } from "components/carrot-plans/shared/useQueryRoutingInfo"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  StepLayout,
  useConvertTranslatedLabel
} from "components/carrot-plans/shared"
import { FormRadioButtonGroup } from "services/common-forms"

const OPTIONS = [
  {
    label: defineMessage({ defaultMessage: "Yes, I'd like to answer questions related to ovarian/uterine health" }),
    value: "AFAB_PREF"
  },
  {
    label: defineMessage({ defaultMessage: "Yes, I'd like to answer questions related to sperm health" }),
    value: "AMAB_PREF"
  },
  {
    label: defineMessage({ defaultMessage: "No, I'd prefer not to answer health questions right now" }),
    value: "DECLINED_HEALTH_QS"
  }
]
export default function AmabAfabMedicalPreferenceStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.amabAfabMedicalPreference
  const { send } = useStateMachine(defaultValue ? "" : null)
  const intl = useIntl()
  const radioOptions = useConvertTranslatedLabel(OPTIONS)

  async function onClickContinue(formValues: { amabAfabMedicalPreference: string }): Promise<void> {
    await mutateAsync(formValues, { onSuccess: () => send("") })
  }
  const header = intl.formatMessage({
    defaultMessage: "Would you like to answer some health questions to help us support you better?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="amabAfabMedicalPreference-label">{header}</span>}
        formElements={
          <Form defaultValues={{ amabAfabMedicalPreference: defaultValue }} onSubmit={onClickContinue}>
            <FormRadioButtonGroup
              name="amabAfabMedicalPreference"
              options={radioOptions}
              aria-labelledby="amabAfabMedicalPreference-label"
              controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
