import { useQueryClient } from "@tanstack/react-query"
import { MedicalInfoQueryKey } from "../context/MedicalInfoContext"
import { useHistory } from "react-router-dom"
import { ROUTING_INFO_QUERY_KEY } from "./useQueryRoutingInfo"

export function useStartRouting(mutateFunction: { mutate: any }, url: string) {
  const { mutate } = mutateFunction
  const queryClient = useQueryClient()
  const history = useHistory()

  async function startRouting() {
    mutate(null, {
      onSuccess: async () => {
        // Remove these query from the cache
        queryClient.removeQueries([MedicalInfoQueryKey])
        // Refetch so that we are sure to get null back for medical since we are starting routing over
        await queryClient.prefetchQuery([ROUTING_INFO_QUERY_KEY])
        await queryClient.refetchQueries([MedicalInfoQueryKey])
        history.push(url)
      }
    })
  }

  return { startRouting }
}
