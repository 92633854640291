import React from "react"
import { Box, Pagination } from "@carrotfertility/carotene-core"

type PaginationBarProps = {
  count: number
  page: number
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void
}

export function PaginationBar({ count, page, onChange }: PaginationBarProps): JSX.Element {
  return (
    <Box display="flex" justifyContent="center">
      <Pagination count={count} page={page} onChange={onChange} />
    </Box>
  )
}
