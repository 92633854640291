import React from "react"
import { Box, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { DigitalFrontDoorSectionLayout } from "../../components/DigitalFrontDoorSectionLayout"
import { FormattedMessage } from "react-intl"
import { useDigitalFrontDoorBreakpointViews } from "../../hooks/useDigitalFrontDoorBreakpointViews"
import AltoSvg from "../../resources/images/alto.svg"

export function ContactAlto(): JSX.Element {
  const theme = useTheme()

  const { isMobileView, isAboveXLScreenView } = useDigitalFrontDoorBreakpointViews()

  return (
    <DigitalFrontDoorSectionLayout backgroundColor={theme.palette.text.tertiary}>
      <Box paddingY={isAboveXLScreenView ? "3rem" : theme.tokens.spacing.xxxl} width="100%">
        <Stack
          direction={isAboveXLScreenView ? "row" : "column"}
          gap={isAboveXLScreenView ? "102px" : "10px"}
          width="100%"
        >
          {!isMobileView && <Box component="img" src={AltoSvg} alt="" width={"102px"} height={"102px"} />}
          <Stack gap={"1rem"}>
            <Typography variant="h4" color={theme.palette.secondary.contrastText}>
              <FormattedMessage defaultMessage="Contact Alto" />
            </Typography>
            <Stack direction={isAboveXLScreenView ? "row" : "column"} width="100%" gap="24px">
              <Typography variant="body1" color={theme.palette.secondary.contrastText} width={"100%"}>
                <FormattedMessage
                  defaultMessage="If you need support with your order, call Alto at{br}844-725-0001.{br}{br}You can also send them a message in the Alto app if you already have an account."
                  values={{
                    br: <br />
                  }}
                />
              </Typography>

              <Typography variant="body1" color={theme.palette.secondary.contrastText} width={"100%"}>
                <FormattedMessage
                  defaultMessage="Contact Alto if:{br}{br}
<li> You have questions about delivery, pricing, Carrot discounts, or your medications</li>
<li> You don't receive a text that mentions Carrot Rx</li>"
                  values={{
                    br: <br />,
                    li: (content) => <li>{content}</li>
                  }}
                />
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </DigitalFrontDoorSectionLayout>
  )
}
