import React from "react"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import {
  ButtonFooterLayout,
  ContinueButton,
  SkipButton,
  StepLayout,
  RoutingFlowCheckboxGroup,
  FormTextFieldWithCharacterCount,
  useConvertTranslatedLabel,
  RoutingFlowTitle
} from "components/carrot-plans/shared"
import { Stack, useTheme } from "@carrotfertility/carotene-core"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { PregnantPreviouslyDetails } from "./MedicalInfoOptions"
import { Form, useWatch } from "@carrotfertility/carotene-core-x"

type FormValues = {
  pregnantPreviouslyDetails?: string[]
  pregnantPreviouslyDetailsOther?: string
}

const OPTIONAL_ANSWER_KEY = "otherPregnantPreviouslyDetails"

const OPTIONAL_SELECTION = {
  label: defineMessage({ defaultMessage: "Something else you'd like us to know about" }),
  value: OPTIONAL_ANSWER_KEY
}

function OptionalInfoInput() {
  const selectedValues = useWatch({ name: "pregnantPreviouslyDetails" })
  if (!selectedValues.includes(OPTIONAL_ANSWER_KEY)) {
    return null
  }
  return (
    <FormTextFieldWithCharacterCount
      name="pregnantPreviouslyDetailsOther"
      helperText={<FormattedMessage defaultMessage="Optional details about other pregnancy complications" />}
    />
  )
}

export default function PregnantPreviouslyDetailsStep(): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const defaultValues = medicalInfo?.pregnantPreviouslyDetails
  const defaultPregnantPreviouslyDetailsOtherValue = medicalInfo?.pregnantPreviouslyDetailsOther
  const defaultPregnantPreviouslyDetailsValuesWithOther = [
    ...(defaultValues || []),
    defaultPregnantPreviouslyDetailsOtherValue ? OPTIONAL_ANSWER_KEY : ""
  ].filter(Boolean)
  const { send } = useStateMachine(
    defaultValues || defaultPregnantPreviouslyDetailsOtherValue || hasQuestionBeenSkipped("pregnantPreviouslyDetails")
      ? ""
      : null
  )

  const options = useConvertTranslatedLabel([...PregnantPreviouslyDetails, OPTIONAL_SELECTION])

  async function onClickContinue(values: FormValues): Promise<void> {
    const selected = values.pregnantPreviouslyDetails.filter((value) => value !== OPTIONAL_ANSWER_KEY)
    await updateMedicalInfo({
      pregnantPreviouslyDetails: selected,
      pregnantPreviouslyDetailsOther: values?.pregnantPreviouslyDetailsOther || ""
    })

    send("")
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["pregnantPreviouslyDetails"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Did you experience any of the following during a past pregnancy?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="pregnantPreviouslyDetails-label">{header}</span>}
        formElements={
          <Form
            defaultValues={{
              pregnantPreviouslyDetails: defaultPregnantPreviouslyDetailsValuesWithOther,
              pregnantPreviouslyDetailsOther: defaultPregnantPreviouslyDetailsOtherValue
            }}
            onSubmit={onClickContinue}
          >
            <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
              <RoutingFlowCheckboxGroup
                name="pregnantPreviouslyDetails"
                aria-labelledby="pregnantPreviouslyDetails-label"
                options={options}
              />
              <OptionalInfoInput />
            </Stack>
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
