import React, { ReactElement, useEffect, useState } from "react"
import TwoColumnRightPrimary from "components/views/atoms/layouts/TwoColumnRightPrimary"
import { getTalkToCarrotProviderEligibilityGuidance } from "../../lib/contentful/contentfulClient"
import { GuidanceElement } from "../../lib/contentful/components/GuidanceElement"
import { SendMessage } from "../../components/talk-to-carrot/send-message/SendMessage"
import { talkToCarrotSource } from "../../components/talk-to-carrot/send-message/SendMessageUtil"
import { TalkToCarrotLegal } from "../../components/talk-to-carrot/TalkToCarrotLegal"
import { useIntl } from "react-intl"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header"
import { Breadcrumbs, Breadcrumb } from "@carrotfertility/carotene-core"

// @ts-expect-error TS7031
const ProviderEligibility = ({ breadcrumbs }) => {
  const [guidance, setGuidance] = useState<ReactElement<typeof GuidanceElement>>(null)
  const intl = useIntl()
  const { locale } = intl
  const breadcrumbTitle = intl.formatMessage({
    defaultMessage: "Check provider eligibility"
  })

  useEffect(() => {
    const loadGuidance = async () => {
      const guidance = await getTalkToCarrotProviderEligibilityGuidance(locale)
      setGuidance(<GuidanceElement guidance={guidance} />)
    }
    if (!guidance) {
      loadGuidance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return (
    <PageLayout
      footer={<TalkToCarrotLegal />}
      header={
        <PageBreadcrumbHeader
          breadcrumbs={
            <Breadcrumbs>
              {[
                ...breadcrumbs,
                <Breadcrumb key="help-breadcrumb" currentPage>
                  {breadcrumbTitle}
                </Breadcrumb>
              ]}
            </Breadcrumbs>
          }
        />
      }
    >
      <TwoColumnRightPrimary
        secondaryContent={guidance}
        primaryContent={<SendMessage source={talkToCarrotSource.PROVIDER_ELIGIBILITY} />}
      />
    </PageLayout>
  )
}

export { ProviderEligibility }
