import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import { useCheckRoutingInfoQueryCache, useUpdateRoutingInfo } from "components/carrot-plans/shared/useQueryRoutingInfo"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  StepLayout,
  useConvertTranslatedLabel
} from "components/carrot-plans/shared"
import { FormRadioButtonGroup } from "services/common-forms"

const OPTIONS = [
  { label: defineMessage({ defaultMessage: "Yes" }), value: "YES" },
  { label: defineMessage({ defaultMessage: "No" }), value: "NO" }
]

export default function IvfInformationStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.interestNoIvf
  const { send } = useStateMachine(defaultValue)
  const intl = useIntl()
  const radioOptions = useConvertTranslatedLabel(OPTIONS)

  async function onClickContinue(formValues: { interestNoIvf: string }): Promise<void> {
    await mutateAsync(formValues, { onSuccess: () => send(formValues?.interestNoIvf) })
  }
  const header = intl.formatMessage({
    defaultMessage:
      "Your answers indicate that IVF may be a possibility now or in the future. Are you interested in learning about evidence-based fertility options that can help some people get pregnant without IVF?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="interestNoIvf-label">{header}</span>}
        formElements={
          <Form defaultValues={{ interestNoIvf: defaultValue }} onSubmit={onClickContinue}>
            <FormRadioButtonGroup
              controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
              aria-labelledby="interestNoIvf-label"
              name="interestNoIvf"
              options={radioOptions}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
