import React, { useState } from "react"
import { useReimbursementContext } from "../../context/reimbursement/GetReimbursedContext"
import { useCurrentUser } from "../../context/user/UserContext"
import { Progress } from "../../views/modal/Progress"
import { BackArrow } from "../../views/atoms/Molecules"
import { FlexContainer, H2, Select, Stack, Text } from "@carrotfertility/carotene"
import ArrowSubmitButton from "../../../features/legacy-components/ArrowSubmitButton"
import { useStateMachine } from "../../context/stateMachine/StateMachineV2"
import { defineMessage, FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import useBenefit from "../../dynamic-content/hooks/useGetBenefit"
import { JourneyTypes } from "./ExpenseJourneyTypes"

export const AboutThisExpenseRelatedToStorageFees = (): JSX.Element => {
  const { updateRelatedToStorageFees, state } = useReimbursementContext()
  const { send, back } = useStateMachine()
  const [relatedToStorageFees, setRelatedToStorageFees] = useState(state.relatedToStorageFees || null)
  const { isUsa } = useCurrentUser()
  const intl = useIntl()
  const { data: benefit } = useBenefit()

  const storageFeesBodyText = isUsa
    ? intl.formatMessage({
        defaultMessage:
          "This helps us determine whether an expense is taxable so we can process reimbursements in a legally compliant way."
      })
    : intl.formatMessage({ defaultMessage: "If you're not sure, choose the option that seems like the best fit." })

  type StorageFeesOption = {
    label: MessageDescriptor
    id: string
  }
  const storageFeeOptions: StorageFeesOption[] = [
    {
      id: "OTHER",
      label: defineMessage({ defaultMessage: "No" })
    },
    {
      id: "FIRST_YEAR_STORAGE",
      label: defineMessage({ defaultMessage: "Yes: First year of storage" })
    },
    {
      id: "POST_FIRST_YEAR_STORAGE",
      label: defineMessage({ defaultMessage: "Yes: After first year of storage" })
    }
  ]

  // @ts-expect-error TS7006
  const isGCExpense = (expenseRelatedTo): boolean => {
    return expenseRelatedTo.includes("GC")
  }

  // @ts-expect-error TS7006
  const isPossibleCarrotRxExpense = (expenseRelatedTo): boolean => {
    return (
      benefit.carrotRx.isCarrotRxRequired &&
      (expenseRelatedTo === JourneyTypes.GC ||
        expenseRelatedTo === JourneyTypes.PRESERVATION ||
        (expenseRelatedTo === JourneyTypes.FERTILITY_CARE &&
          (benefit.journeys.fertilityCare.eligibleExpensesDetail.isRxCovered ||
            benefit.journeys.fertilityCare.eligibleExpensesDetail.hasDonorAssistance)))
    )
  }

  const handleContinueClick = (): void => {
    updateRelatedToStorageFees(relatedToStorageFees)

    if (isPossibleCarrotRxExpense(state.expenseRelatedTo?.value)) {
      send("RELATED_TO_MEDICATION")
    } else if (isGCExpense(state.expenseRelatedTo?.value)) {
      send("ADDITIONAL_INFO_ANYTHING_ELSE")
    } else {
      send("")
    }
  }

  // @ts-expect-error TS7006
  const handleSelectionChange = (selectedValue): void => {
    setRelatedToStorageFees(selectedValue)
  }

  const formattedRelatedToStorageFeesOptions = storageFeeOptions.map((option) => ({
    ...option,
    label: intl.formatMessage(option.label)
  }))

  return (
    <>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <Stack minHeight="500px" paddingTopBottom="huge">
        <H2 id="step-heading">
          <FormattedMessage defaultMessage="Is this related to storage fees?" />
        </H2>
        <Text>{storageFeesBodyText}</Text>

        <Select
          aria-labelledby="step-heading"
          isSearchable={false}
          defaultValue={state.relatedToStorageFees?.label ? (state.relatedToStorageFees as any) : ""}
          onChange={handleSelectionChange}
          options={formattedRelatedToStorageFeesOptions}
          placeholder={intl.formatMessage({ defaultMessage: "Select option" })}
        />
      </Stack>
      <FlexContainer paddingTopBottom="huge" justifyContent="flex-end">
        <ArrowSubmitButton enabled={!!relatedToStorageFees.value} onClick={handleContinueClick}>
          <FormattedMessage defaultMessage="Continue" />
        </ArrowSubmitButton>
      </FlexContainer>
    </>
  )
}
