import React from "react"
import { Spacer } from "components/views/atoms/Atoms"
import { H2, Body, FlexContainer } from "@carrotfertility/carotene"
import { Button, Stack } from "@carrotfertility/carotene-core"
import { IlloDocSigning } from "components/views/atoms/Illustrations"
import { useHistory } from "react-router-dom"
import { CarrotRoute } from "utils/CarrotUrls"
import { FormattedMessage } from "react-intl"

export function GetReimbursedDoIvfRequirement(): JSX.Element {
  const history = useHistory()
  const onExit = () => {
    history.push(CarrotRoute.IvfRequirements)
  }

  return (
    <>
      <Spacer height={2} />
      <FlexContainer justifyContent="center" padding="none" stack="large">
        <IlloDocSigning />
      </FlexContainer>
      <Stack padding="tiny">
        <H2 stack="small" variant="primary">
          <FormattedMessage defaultMessage="Complete your IVF requirements" />
        </H2>
        <Body color="black-80">
          <FormattedMessage defaultMessage="Fulfill your requirements now so that we can complete your reimbursement as quickly as possible." />
        </Body>
      </Stack>
      <FlexContainer justifyContent="center" paddingTopBottom="huge">
        <Button variant="contained" color="primary" size="medium" onClick={onExit}>
          <FormattedMessage defaultMessage="Complete requirements" />
        </Button>
      </FlexContainer>
    </>
  )
}
