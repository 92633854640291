import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import useEmailVerification, { VerifyPersonalEmailResponse } from "./useEmailVerification"
import { useHistory } from "react-router"
import Locations from "../views/home/navigation"
import { useCurrentUser } from "../context/user/UserContext"
import { FullScreenLoading } from "../read/shared/FullScreenLoading"

function useVerifyEmailModal(response: VerifyPersonalEmailResponse) {
  const history = useHistory()
  const { personalEmail } = useCurrentUser()

  useEffect(() => {
    if (response === VerifyPersonalEmailResponse.SUCCESS || response === VerifyPersonalEmailResponse.ALREADY_VERIFIED) {
      history.push(Locations.Home.PersonalEmailVerified)
    }

    if (response === VerifyPersonalEmailResponse.EXPIRED) {
      history.push("/token-expired")
    }

    if (response === VerifyPersonalEmailResponse.ERROR && personalEmail === null) {
      history.push("/no-personal-email")
    }

    if (response === VerifyPersonalEmailResponse.ERROR && personalEmail !== null) {
      history.push("/wrong-personal-email")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [personalEmail, response])
}

export default function VerifyPersonalEmail(): JSX.Element {
  const { token } = useParams<{ token: string }>()
  const { verifyPersonalEmail } = useEmailVerification()
  const [response, setResponse] = useState(null)

  useEffect(() => {
    verifyPersonalEmail(token).then((res) => setResponse(res))
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [token])

  useVerifyEmailModal(response)

  return <FullScreenLoading />
}
