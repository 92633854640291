import React, { useEffect, useState } from "react"
import { Button, Container, Divider, FlexContainer, Image, useBreakpointComponent } from "@carrotfertility/carotene"
import { Link, useLocation } from "react-router-dom"
import { ContentfulConditionalGate } from "../../lib/contentful/components/ContentfulConditionGate"
import { ReadAndLearnBreadcrumbs } from "../../components/read/shared/ReadAndLearnBreadcrumbs"
import slugify from "slugify"
import { CarrotPlanRequiredModal } from "../../components/views/app/CarrotPlanRequiredModal"
import { GuideComponentProps } from "./GuideParser"
import { useCurrentUser } from "components/context/user/UserContext"
import { getHeap } from "./utils/heap"
import { FormattedMessage, useIntl } from "react-intl"
import { ContentfulRawMarkdownRenderer } from "services/contentful"
import { Box, CloseIcon, Dialog, DialogContent, IconButton, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Title } from "services/page-title/Title"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header"

const fixMarkdownHeaders = { "*:first-child": { marginTop: 0 } }

const OrangeHeaderBackground = ({ children }: { children: JSX.Element }): JSX.Element => {
  const mobileHeader = (
    <Container background="veryLightCarrotOrange" borderRadius="8px" width="100%" padding="large">
      {children}
    </Container>
  )
  const desktopHeader = (
    <Container background="veryLightCarrotOrange" borderRadius="8px" width="69%" padding="large" maxWidth="600px">
      {children}
    </Container>
  )
  return useBreakpointComponent([mobileHeader, mobileHeader, mobileHeader, desktopHeader]) || <></>
}

export type LandingPageState = {
  isPostGuide?: boolean
}

const GuideImage = (): JSX.Element => {
  const LargeImage = (): JSX.Element => (
    <Container sx={{ position: "absolute", insetBlockStart: "-40px", insetInlineEnd: "0" }} width={"360px"}>
      <Image
        src={require(`./resources/images/guide-landing.png`)}
        altText={"Illustration of person sitting and holding a tablet"}
        sx={{
          transform: "scaleX(-1)",
          boxSizing: "content-box"
        }}
      />
    </Container>
  )

  const MediumImage = (): JSX.Element => (
    <Container sx={{ position: "absolute", insetInlineEnd: "0" }} width={"220px"}>
      <Image
        src={require(`./resources/images/guide-landing.png`)}
        altText={"Illustration of person sitting and holding a tablet"}
        sx={{
          transform: "scaleX(-1)",
          boxSizing: "content-box"
        }}
      />
    </Container>
  )
  const image = useBreakpointComponent([null, null, <MediumImage key="mediumImage" />, <LargeImage key="largeImage" />])
  return image || <></>
}

const Banner = ({ title }: { title: string }): JSX.Element => {
  return (
    <Container sx={{ position: "relative" }}>
      <GuideImage />
      <OrangeHeaderBackground>
        <FlexContainer direction="column" sx={{ marginInlineStart: [null, null, null, "32px"] }}>
          <Typography variant="overline" color={(theme) => theme.palette.text.tertiary} sx={{ zIndex: 1 }}>
            <FormattedMessage defaultMessage="Guide" />
          </Typography>
          <Container padding={[null, null, "0 160px 0 0", "0 50px 0 0"]} sx={{ zIndex: 1 }}>
            <Typography variant="h1">{title}</Typography>
          </Container>
        </FlexContainer>
      </OrangeHeaderBackground>
    </Container>
  )
}

const Description = ({ descriptionMarkdown }: { descriptionMarkdown: string }): JSX.Element => {
  return (
    //zIndex here to force banner image behind
    <Container
      sx={{ zIndex: 1 }}
      width={["100%", null, null, "calc(60% - 24px)"]}
      max-width={"600px"}
      paddingLeftRight="medium"
    >
      <ContentfulRawMarkdownRenderer>{descriptionMarkdown}</ContentfulRawMarkdownRenderer>
    </Container>
  )
}

const Audience = ({ audienceMarkdown }: { audienceMarkdown: string }): JSX.Element => {
  return (
    <Container
      background="lightPampas"
      borderRadius="8px"
      padding="medium"
      width={["100%", "100%", "100%", "39%"]}
      max-width={"320px"}
      height={"inherit"}
      sx={fixMarkdownHeaders}
    >
      <ContentfulRawMarkdownRenderer>{audienceMarkdown}</ContentfulRawMarkdownRenderer>
    </Container>
  )
}

const Overview = ({ overviewMarkdown }: { overviewMarkdown: string }): JSX.Element => {
  return (
    <Container
      background="lightPampas"
      borderRadius="8px"
      width={["100%", null, null, "60%"]}
      max-width={"600px"}
      height={"100%"}
      padding="medium"
      sx={fixMarkdownHeaders}
    >
      <ContentfulRawMarkdownRenderer>{overviewMarkdown}</ContentfulRawMarkdownRenderer>
    </Container>
  )
}

const LegalFooter = (): JSX.Element => {
  const theme = useTheme()
  return (
    <FlexContainer direction="column" gap="medium" sx={{ marginBlockEnd: "90px" }}>
      <Divider color="lightGrey" />
      <Typography variant="body2" color={theme.palette.text.tertiary}>
        <FormattedMessage
          defaultMessage="Legality and availability of services vary from country to country and often even within a country. Contact your
      Care Team for help understanding what services are available to you domestically or through cross-border care."
          description="Legal disclaimer for articles and videos. Care Team refers to the care navigators that help Carrot members navigate their fertility journey"
        />
      </Typography>
      <Divider color="gray" sx={{ inlineSize: "47px" }} />
      <Typography variant="body2" color={theme.palette.text.tertiary}>
        <FormattedMessage
          defaultMessage="We strive to be as clear and inclusive as possible. When appropriate, we use terminology that reflects current
      medical practice. We recognize that these terms do not capture every individual's experience."
        />
      </Typography>
      <Divider color="gray" sx={{ inlineSize: "47px" }} />
      <Typography variant="body2" color={theme.palette.text.tertiary}>
        <FormattedMessage defaultMessage="Carrot resources are for informational purposes only and do not constitute medical advice or professional services. They are not a substitute for legal, tax, or professional medical advice, diagnosis, or treatment. If you are experiencing a medical emergency, call emergency services immediately." />
      </Typography>
    </FlexContainer>
  )
}

const LandingPage = ({ guide, subcategory, category }: GuideComponentProps): JSX.Element => {
  const { isInAppLockdown } = useCurrentUser()
  const { state } = useLocation<LandingPageState>()
  const [postGuideOpen, setPostGuideOpen] = useState(state?.isPostGuide || false)

  const intl = useIntl()

  const guideSlugId = `${guide.slug}-${guide.id}`

  useEffect(() => {
    if (isInAppLockdown) {
      getHeap().track("Carrot Plan Required Modal Rendered", {
        "Guide Slug ID": guideSlugId
      })
    }

    if (postGuideOpen) {
      getHeap().track("Post Guide Modal Rendered", {
        "Guide Slug ID": guideSlugId
      })
    }
  }, [isInAppLockdown, postGuideOpen, guideSlugId])

  const categorySlug = slugify(category?.fields?.navTitle)

  const [firstSection] = guide.sections

  return (
    <>
      <Title title={guide.title} productTitle="Read and learn" />
      <ContentfulConditionalGate conditions={guide.conditions} allowConditionsOverride={true}>
        <PageLayout
          header={
            <PageBreadcrumbHeader
              breadcrumbs={<ReadAndLearnBreadcrumbs category={category} subcategory={subcategory} />}
            />
          }
        >
          <FlexContainer gap="giant" direction="column">
            <FlexContainer gap="large" direction="column" data-guide-id={guideSlugId}>
              <Banner title={guide.title} />
              <Description descriptionMarkdown={guide.description} />
              <Container paddingLeftRight="medium">
                <Link to={`/read/${categorySlug}/guide/${guide.slug}/${firstSection.slug}`}>
                  <Button id="get-started-button" variant="primary">
                    <FormattedMessage defaultMessage="Get started" />
                  </Button>
                </Link>
              </Container>
              <FlexContainer direction={["column", null, null, "row"]} gap="huge">
                <Overview overviewMarkdown={guide.overview} />
                <Audience audienceMarkdown={guide.audience} />
              </FlexContainer>
              <LegalFooter />
            </FlexContainer>
          </FlexContainer>
        </PageLayout>
        <Dialog
          aria-labelledby="post-guide-modal-title"
          aria-describedby="post-guide-modal-description"
          onClose={() => {
            setPostGuideOpen(false)
            window.history.replaceState({}, document.title)
          }}
          open={postGuideOpen}
          id="post-guide-modal-container"
          data-guide-id={guideSlugId}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              aria-label={intl.formatMessage({ defaultMessage: "Close modal" })}
              onClick={() => {
                setPostGuideOpen(false)
                window.history.replaceState({}, document.title)
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent sx={{ gap: 0 }}>
            <ContentfulRawMarkdownRenderer>{guide.afterGuideModal}</ContentfulRawMarkdownRenderer>
          </DialogContent>
        </Dialog>
        <CarrotPlanRequiredModal />
      </ContentfulConditionalGate>
    </>
  )
}

export default LandingPage
