import React, { ReactElement, ReactNode } from "react"
import TwoColumnRightPrimary from "../views/atoms/layouts/TwoColumnRightPrimary"
import styled from "styled-components"
import { Spacer } from "../views/atoms/Atoms"
import { GuidanceElement } from "lib/contentful/components/GuidanceElement"
import { Tile } from "lib/contentful/components/TileElement"
import { useResponsiveTalkToCarrotComponent } from "./hooks/useResponsiveTalkToCarrotComponent"

type TalkToCarrotTilePageProps = {
  guidance: ReactElement<typeof GuidanceElement>
  tiles: ReactElement<typeof Tile>[]
}

const TalkToCarrotStyledTilesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 24px;
`

const TalkToCarrotTilesWrapper = ({ children }: { children: ReactNode }) => (
  <TalkToCarrotStyledTilesWrapper>{children}</TalkToCarrotStyledTilesWrapper>
)

const TalkToCarrotTilePage = ({ guidance, tiles }: TalkToCarrotTilePageProps) => {
  const largeContent = (
    <TwoColumnRightPrimary
      secondaryContent={guidance}
      primaryContent={<TalkToCarrotTilesWrapper>{tiles}</TalkToCarrotTilesWrapper>}
    />
  )

  const mediumContent = (
    <>
      {guidance}
      <Spacer height={3} />
      <TalkToCarrotTilesWrapper>{tiles}</TalkToCarrotTilesWrapper>
    </>
  )

  const smallContent = (
    <>
      {guidance}
      <Spacer height={2} />
      <TalkToCarrotTilesWrapper>{tiles}</TalkToCarrotTilesWrapper>
    </>
  )

  const responsiveContent = useResponsiveTalkToCarrotComponent(smallContent, mediumContent, largeContent)
  return responsiveContent || <></>
}

export default TalkToCarrotTilePage
