import { MessageDescriptor, defineMessage } from "react-intl"
import { CoverageConfiguration } from "types/benefitConfiguration"

type WhoReceivedCareChoice = {
  label: MessageDescriptor
  id: string
  selected: boolean
  filter?: (coverageConfig: CoverageConfiguration) => boolean
}

export const whoReceivedCareChoices: WhoReceivedCareChoice[] = [
  {
    label: defineMessage({ defaultMessage: "Me" }),
    id: "MEMBER",
    selected: false
  },
  {
    label: defineMessage({ defaultMessage: "My legal spouse or tax-dependent partner" }),
    id: "LEGAL_PARTNER",
    selected: false,
    filter: (coverageConfig) => coverageConfig.isMemberAndPartnerEligible
  },
  {
    label: defineMessage({ defaultMessage: "My non-married partner" }),
    id: "NON_MARRIED_PARTNER",
    selected: false,
    filter: (coverageConfig) => coverageConfig.isMemberAndPartnerEligible && !coverageConfig.areLegalPartnersEligible
  },
  {
    label: defineMessage({ defaultMessage: "Birth parent for adoption" }),
    id: "BIRTH_PARENT",
    selected: false,
    filter: (coverageConfig) => coverageConfig.isAdoptionEnabled
  },
  {
    label: defineMessage({ defaultMessage: "Donor" }),
    id: "DONOR",
    selected: false,
    filter: (coverageConfig) => {
      return coverageConfig.isDonorAssistanceEnabled || coverageConfig.isGcEnabled
    }
  },
  {
    label: defineMessage({ defaultMessage: "Gestational carrier" }),
    id: "GESTATIONAL_CARRIER",
    selected: false,
    filter: (coverageConfig) => coverageConfig.isGcEnabled
  }
]
