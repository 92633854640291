import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { carrotClient } from "../../../utils/CarrotClient"
import buildCarrotApiRetryPolicy from "../../../utils/CarrotApiRetryPolicy"

/**
 * Retrieve a list of all possible locales for all countries
 * from Backend Locale Service
 */
export const useAllLocales = (): UseQueryResult<string[]> => {
  return useQuery<string[]>(
    ["useAllLocales"],
    () => {
      return carrotClient.getAllEnabledLocales()
    },
    {
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
