import { Steps } from "./steps"

import { RequestCardInformation } from "../steps/RequestCardInformation"
import { RequestCardMailingAddress } from "../steps/RequestCardMailingAddress"
import { ConfirmPhoneNumber } from "../steps/ConfirmPhoneNumber"
import { VerifyPhoneNumber } from "../steps/VerifyPhoneNumber"
import { StripeUserAgreement } from "../steps/StripeUserAgreement"
import { RequestCardSuccess } from "../steps/RequestCardSuccess"
import { CardRequestPending } from "../steps/CardRequestPending"
import { RequestCardInternationalAddress } from "../steps/RequestCardInternationalAddress"
import { AirwallexUserAgreement } from "../steps/AirwallexUserAgreement/user-agreement"
import { NoUserAgreementRequest } from "../steps/NoUserAgreementRequest"

export const requestCardSteps = [
  { name: Steps.REQUEST_CARD_INFORMATION, component: RequestCardInformation },
  { name: Steps.MAILING_ADDRESS, component: RequestCardMailingAddress },
  { name: Steps.INTERNATIONAL_ADDRESS, component: RequestCardInternationalAddress },
  { name: Steps.CONFIRM_PHONE_NUMBER, component: ConfirmPhoneNumber },
  { name: Steps.VERIFY_PHONE_NUMBER, component: VerifyPhoneNumber },
  { name: Steps.STRIPE_USER_AGREEMENT, component: StripeUserAgreement },
  { name: Steps.AIRWALLEX_USER_AGREEMENT, component: AirwallexUserAgreement },
  { name: Steps.REQUEST_CARD_SUCCESS, component: RequestCardSuccess },
  { name: Steps.CARD_REQUEST_PENDING, component: CardRequestPending },
  { name: Steps.NO_USER_AGREEMENT_REQUEST_CARD, component: NoUserAgreementRequest }
]
