import { Box } from "@carrotfertility/carotene-core"
import React from "react"

export const CircleImage = ({
  isMobileView,
  src,
  hexcode
}: {
  isMobileView: boolean
  src: string
  hexcode: string
}) => {
  return (
    <Box
      borderRadius={(theme) => theme.tokens.borderRadius.round}
      border={`0.188rem solid ${hexcode}`}
      width={isMobileView ? "3.75rem" : "5rem"}
      height={isMobileView ? "3.75rem" : "5rem"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      minWidth={isMobileView ? "3.75rem" : "5rem"}
    >
      <Box
        component="img"
        src={src}
        alt=""
        width={isMobileView ? "2.25rem" : "3rem"}
        height={isMobileView ? "2.25rem" : "3rem"}
      />
    </Box>
  )
}
