import React from "react"
import { Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"

export const MerchantDeclineLoading = () => (
  <div>
    <div className="tc pt5">
      <Typography variant="h3">
        <FormattedMessage defaultMessage="Still loading, just a moment…" />
      </Typography>
    </div>

    <div className="flex justify-center items-center h-100">
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
)
