import React from "react"
import { Steps as JourneySteps } from "./steps/journey/step-library"
import { journeySteps, journeyWorkflows, Workflows as JourneyWorkflows } from "./steps/journey/workflow"
import {
  aboutYouSteps,
  aboutYouWorkflows,
  Steps as AboutYouSteps,
  Workflows as AboutYouWorkflows
} from "./steps/about-you/workflow"
import {
  Steps as MedicalQuestionsSteps,
  medicalQuestionsSteps,
  medicalQuestionsWorkflows
} from "./steps/medical-questions/workflows"
import { StateMachineProvider, StateMachineStepView } from "../context/stateMachine/StateMachineV2"
import { RoutingFlowContainer } from "./shared/RoutingFlowContainer"
import { useMedicalInfoWorkflow } from "./hooks/useMedicalInfoFlow"

export enum Sections {
  ABOUT_YOU = "AboutYou",
  JOURNEY = "Journey",
  MEDICAL = "Medical"
}

export default function RoutingFlow(): JSX.Element {
  const { workflow: desiredMedicalWorkflow } = useMedicalInfoWorkflow()

  const aboutYouSection = {
    name: Sections.ABOUT_YOU,
    initialStep: AboutYouSteps.ABOUT_YOU_INTRO,
    desiredWorkflow: AboutYouWorkflows.ABOUT_YOU_DEFAULT,
    steps: aboutYouSteps,
    workflows: aboutYouWorkflows
  }

  const journeySection = {
    name: Sections.JOURNEY,
    initialStep: JourneySteps.WHAT_KIND_OF_JOURNEY,
    desiredWorkflow: JourneyWorkflows.JOURNEY,
    steps: journeySteps,
    workflows: journeyWorkflows
  }

  const medicalQuestionsSection = {
    name: Sections.MEDICAL,
    initialStep: MedicalQuestionsSteps.WELCOME,
    desiredWorkflow: desiredMedicalWorkflow,
    steps: medicalQuestionsSteps,
    workflows: medicalQuestionsWorkflows
  }

  return (
    <StateMachineProvider
      initialSection={Sections.ABOUT_YOU}
      stateMachineDescription={[aboutYouSection, journeySection, medicalQuestionsSection]}
      onComplete={null}
    >
      <RoutingFlowContainer>
        <StateMachineStepView />
      </RoutingFlowContainer>
    </StateMachineProvider>
  )
}
