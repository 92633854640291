import { Box } from "@carrotfertility/carotene-core"
import { CenteredLoadingIndicator } from "../molecules/Molecules"
import React from "react"

export function RegisteringLoadingIndicator({ isModal = false }: { isModal?: boolean }): JSX.Element {
  // When used inside a dialog 100vh results in a vertical scrollbar
  const height = isModal ? "100%" : "100vh"
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height={height}>
      <CenteredLoadingIndicator />
    </Box>
  )
}
