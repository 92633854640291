import React from "react"

const NotLarge = ({ children }) => <div className="db dn-l">{children}</div>
const NotMedium = ({ children }) => <div className="db dn-m">{children}</div>
const NotSmall = ({ children }) => <div className="dn db-ns">{children}</div>

const Small = ({ children }) => <div className="db dn-ns">{children}</div>
const Medium = ({ children }) => <div className="dn db-m">{children}</div>
const Large = ({ children }) => <div className="dn db-l">{children}</div>

export { NotLarge, NotMedium, NotSmall, Small, Medium, Large }
