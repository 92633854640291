import React from "react"
import SignUpFindAccountSuccessPage from "components/signup-find-account-success"
import {
  SignUpFindAccountSuccessProvider,
  useSignUpFindAccountSuccess
} from "components/signup-find-account-success/context/SignUpFindAccountSuccessContext"

export default function SignUpFindAccountSuccess(): JSX.Element {
  return (
    <SignUpFindAccountSuccessProvider>
      <ContextProvidingWrapper />
    </SignUpFindAccountSuccessProvider>
  )
}

function ContextProvidingWrapper(): JSX.Element {
  const contextValue = useSignUpFindAccountSuccess()

  return <SignUpFindAccountSuccessPage {...contextValue} />
}
