import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Container } from "@carrotfertility/carotene"
import { Steps } from "../workflow/steps"
import { useCurrentUser } from "../../context/user/UserContext"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import useSubmitWithErrorCatch from "../hooks/useSubmitWithErrorCatch"
import {
  BenefitEnrollmentFlowModalBackButton,
  BenefitEnrollmentModalContinueButton,
  BenefitEnrollmentModalFormSelect
} from "../shared"
import { useUserDeductible } from "../../context/user/DeductibleContext"
import { defineMessage, useIntl, MessageDescriptor, FormattedMessage } from "react-intl"
import { Box, Typography } from "@carrotfertility/carotene-core"

const FieldName = "memberCoverageTypeSelectValue"
const ViewName = Steps.MEMBER_COVERAGE_TYPE

export const enum MemberCoverageTypeOptions {
  MEMBER_ONLY = "MEMBER_ONLY",
  MEMBER_AND_PARTNER = "MEMBER_AND_PARTNER",
  MEMBER_PARTNER_DEPENDENTS = "MEMBER_PARTNER_DEPENDENTS",
  MEMBER_DEPENDENTS_NOT_PARTNER = "MEMBER_DEPENDENTS_NOT_PARTNER",
  IM_NOT_SURE = "IM_NOT_SURE"
}

const memberCoverageTypeIdToLabel = {
  [MemberCoverageTypeOptions.MEMBER_ONLY]: defineMessage({ defaultMessage: "Just me" }),
  [MemberCoverageTypeOptions.MEMBER_AND_PARTNER]: defineMessage({ defaultMessage: "Me and a partner" }),
  [MemberCoverageTypeOptions.MEMBER_PARTNER_DEPENDENTS]: defineMessage({
    defaultMessage: "Me and a partner and dependents"
  }),
  [MemberCoverageTypeOptions.MEMBER_DEPENDENTS_NOT_PARTNER]: defineMessage({
    defaultMessage: "Me and dependents but no partner"
  }),
  [MemberCoverageTypeOptions.IM_NOT_SURE]: defineMessage({ defaultMessage: "I'm not sure" })
}

export const getMemberCoverageLabelFromId = (id: MemberCoverageTypeOptions): MessageDescriptor =>
  memberCoverageTypeIdToLabel[id]

function MemberCoverageTypeStep(): JSX.Element {
  const { memberCoverageTypeSelectValue } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const { setNextStep } = useStateMachine(ViewName, memberCoverageTypeSelectValue)
  const formMethods = useForm()
  // @ts-expect-error TS7006
  const { onSubmit } = useSubmitWithErrorCatch(async (args) => {
    const val = args[FieldName].value
    val !== "IM_NOT_SURE" &&
      (await updateBenefitEnrollmentAnswers({
        memberCoverageTypeSelectValue: val
      }))
    setNextStep(ViewName, val)
  })

  const { handleSubmit } = formMethods
  const intl = useIntl()

  return (
    <FormProvider {...formMethods}>
      <Container padding="none" stack="huge">
        <BenefitEnrollmentFlowModalBackButton />
      </Container>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xl}
      >
        <FormattedMessage defaultMessage="Does your health plan cover just you or does it cover other people as well?" />
      </Typography>
      <BenefitEnrollmentModalFormSelect
        name={FieldName}
        aria-labelledby="step-heading"
        defaultValue={memberCoverageTypeSelectValue}
        options={[
          {
            label: intl.formatMessage(getMemberCoverageLabelFromId(MemberCoverageTypeOptions.MEMBER_ONLY)),
            value: MemberCoverageTypeOptions.MEMBER_ONLY
          },
          {
            label: intl.formatMessage(getMemberCoverageLabelFromId(MemberCoverageTypeOptions.MEMBER_AND_PARTNER)),
            value: MemberCoverageTypeOptions.MEMBER_AND_PARTNER
          },
          {
            label: intl.formatMessage(
              getMemberCoverageLabelFromId(MemberCoverageTypeOptions.MEMBER_PARTNER_DEPENDENTS)
            ),
            value: MemberCoverageTypeOptions.MEMBER_PARTNER_DEPENDENTS
          },
          {
            label: intl.formatMessage(
              getMemberCoverageLabelFromId(MemberCoverageTypeOptions.MEMBER_DEPENDENTS_NOT_PARTNER)
            ),
            value: MemberCoverageTypeOptions.MEMBER_DEPENDENTS_NOT_PARTNER
          },
          {
            label: intl.formatMessage(getMemberCoverageLabelFromId(MemberCoverageTypeOptions.IM_NOT_SURE)),
            value: MemberCoverageTypeOptions.IM_NOT_SURE
          }
        ]}
      />
      <Box height="23rem" />
      <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
    </FormProvider>
  )
}

export { MemberCoverageTypeStep }
