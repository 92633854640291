import {
  StateMachineProvider,
  StateMachineStepView as UploadAdditionalDocumentsView
} from "../context/stateMachine/StateMachineV2"
import { uploadAdditionalDocumentsSection } from "./workflow/step-library"
import React, { useState } from "react"
import EmployeeOnlyModalContainer from "../employee-only-containers/EmployeeOnlyModalContainer"
import { Box, CloseIcon, Dialog, DialogContent, IconButton } from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"
import { FileTypes } from "../get-reimbursed/steps/UploadFile"

type UploadAdditionalDocumentsProps = {
  expenseId: number
  isOpen: boolean
  onExit: () => void
}

type UploadAdditionalDocumentsContextProps = {
  expenseId?: number
  files?: FileTypes
  setFiles?: (attachments: FileTypes) => void
}

export const UploadAdditionalDocumentsContext = React.createContext<UploadAdditionalDocumentsContextProps>({})
export const UploadAdditionalDocumentsFlow = ({ expenseId, isOpen, onExit }: UploadAdditionalDocumentsProps) => {
  const intl = useIntl()
  const [files, setFiles] = useState([])
  const context = {
    expenseId: expenseId,
    files: files,
    setFiles: setFiles
  }

  return (
    <UploadAdditionalDocumentsContext.Provider value={context}>
      <StateMachineProvider
        initialSection="UploadAdditionalDocumentsSection"
        stateMachineDescription={[uploadAdditionalDocumentsSection]}
        onComplete={() => {}}
      >
        <EmployeeOnlyModalContainer onClose={onExit}>
          <Dialog open={isOpen} onClose={onExit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={onExit}>
                <CloseIcon />
              </IconButton>
            </Box>
            <DialogContent>
              <UploadAdditionalDocumentsView />
            </DialogContent>
          </Dialog>
        </EmployeeOnlyModalContainer>
      </StateMachineProvider>
    </UploadAdditionalDocumentsContext.Provider>
  )
}
