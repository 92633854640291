import React from "react"
import SignUpGetHelpPage from "../components/signup-get-help"
import { SignUpGetHelpProvider } from "../components/signup-get-help/context/SignUpGetHelpContext"
import { useHistory } from "react-router"

const SignUpGetHelp = (): JSX.Element => {
  const history = useHistory()

  function onGetHelpSuccessfulRequest(): void {
    history.push("/signup-get-help-success")
  }

  return (
    <SignUpGetHelpProvider {...{ onGetHelpSuccessfulRequest }}>
      <SignUpGetHelpPage />
    </SignUpGetHelpProvider>
  )
}

export default SignUpGetHelp
