import React from "react"
import { isOneTrustCookieCategoryAccepted, OneTrustCookieCategory } from "utils/oneTrustCookieConsent"
import { useIntl } from "react-intl"
import { Locale } from "../../types/Locale"

type EmbeddedVideoProps = {
  videoUrl: string
  title: string
}

type EmbeddedVideoType = "youtube" | "vimeo" | "unknown"

const VimeoQueryParameters = {
  textTrack: "textTrack",
  doNotTrack: "dnt"
}
const YoutubeQueryParameters = {
  closedCaptionToggle: "cc_load_policy",
  closedCaptionLanguage: "cc_lang_pref"
}

const enum VimeoDoNotTrack {
  TrackingBlocked = "1",
  TrackingAllowed = "0"
}

function PlayerIframe(
  props: React.IframeHTMLAttributes<HTMLIFrameElement> & { title: string; playerType?: EmbeddedVideoType }
) {
  const { playerType, ...iframeProps } = props
  return (
    <div className="embed-container">
      <iframe {...iframeProps} data-videoplayer={playerType} title={iframeProps.title} className="bn" allowFullScreen />
    </div>
  )
}

function VimeoVideo(props: EmbeddedVideoProps) {
  const { locale: localeString } = useIntl()
  const videoUrlObject = new URL(props.videoUrl)

  videoUrlObject.searchParams.append(
    VimeoQueryParameters.doNotTrack,
    isOneTrustCookieCategoryAccepted(OneTrustCookieCategory.Targeting)
      ? VimeoDoNotTrack.TrackingAllowed
      : VimeoDoNotTrack.TrackingBlocked
  )
  videoUrlObject.searchParams.append(
    VimeoQueryParameters.textTrack,
    Locale.getLocaleFromString(localeString).vimeoLanguageTag
  )

  return (
    <PlayerIframe
      data-ot-ignore
      /* must be first! 😲 */
      playerType="vimeo"
      title={props.title}
      src={videoUrlObject.href}
    />
  )
}

function YoutubeVideo(props: EmbeddedVideoProps) {
  const { locale: localeString } = useIntl()
  const videoUrlObject = new URL(props.videoUrl)

  const languageTag = Locale.getLocaleFromString(localeString).youtubeLanguageTag
  if (languageTag) {
    videoUrlObject.searchParams.append(YoutubeQueryParameters.closedCaptionToggle, "1")
    videoUrlObject.searchParams.append(YoutubeQueryParameters.closedCaptionLanguage, languageTag)
  }

  return <PlayerIframe title={props.title} playerType="youtube" src={videoUrlObject.href} />
}

export function EmbeddedVideo(props: EmbeddedVideoProps): JSX.Element {
  const videoUrlObject = new URL(props.videoUrl)

  switch (videoUrlObject.host) {
    case "www.youtube.com":
      return <YoutubeVideo {...props} />
    case "player.vimeo.com":
      return <VimeoVideo {...props} />
    default:
      return <PlayerIframe playerType="unknown" title={props.title} src={videoUrlObject.href} />
  }
}
