import React, { FC, useEffect, useState } from "react"
import Settings from "../../../utils/CarrotConfig"
import EmployeeOnlySpaceContainer from "components/employee-only-containers/EmployeeOnlySpaceContainer"
import EmployeeOnlyModalContainer from "../../employee-only-containers/EmployeeOnlyModalContainer"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InternalIcon,
  Progress,
  Stack,
  Typography,
  useTheme
} from "@carrotfertility/carotene-core"
import { Title } from "services/page-title/Title"
import { PageLayout } from "features/global-ui/page-layout"
import { PageHeader, PageHeaderGlyph } from "features/global-ui/page-header"
import {
  CarrotDocumentType,
  DocuSignEvent,
  getSignatureForDocument,
  SignatureCompleteEvent
} from "carrot-api/signatureClient"
import { CarrotQueryParam, CarrotRoute } from "../../../utils/CarrotUrls"
import { PopupModal } from "react-calendly"
import { CALENDLY_PRIMARY_COLOR } from "../../../lib/contentful/utils/mappingUtils"
import { useIvfRequirementsInfo } from "./hooks/useIvfRequirementsInfo"
import { useHistory } from "react-router-dom"
import { StringParam, useQueryParam } from "use-query-params"
import { ErrorPage } from "../app/ErrorPage"
import { IvfRequirementsInfo } from "carrot-api/types/IvfRequirementsInfo"
import { useFlags } from "launchdarkly-react-client-sdk"
import {
  DialogTimeTapScheduler,
  TimeTapConfirmationDialog,
  TimeTapEnvironment,
  useReportToHeapTimeTapChatScheduled
} from "../../time-tap/TimeTapScheduler"

type BackButtonsProps = {
  backOnClick: () => void
  doThisLaterOnClick: () => void
}

const BackButtons: FC<BackButtonsProps> = ({ backOnClick, doThisLaterOnClick }: BackButtonsProps) => {
  return (
    <Box
      display="flex"
      flexDirection="row-reverse"
      minWidth="240px"
      gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
      alignItems="center"
    >
      <Button color="primary" onClick={doThisLaterOnClick}>
        <FormattedMessage defaultMessage="Do this later" />
      </Button>
      <Button color="secondary" variant="outlined" onClick={backOnClick}>
        <FormattedMessage defaultMessage="Back" description="to the previous page" />
      </Button>
    </Box>
  )
}

type IvfRequirementsButtonProps = {
  id: string
  iconName: string
  label: string
  onClick: () => void
  enabled?: boolean
}

const CenteredProgress = (): JSX.Element => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
      <Progress />
    </Box>
  )
}

const IvfRequirementsButton: FC<IvfRequirementsButtonProps> = ({
  id,
  iconName,
  label,
  onClick,
  enabled = true
}: IvfRequirementsButtonProps) => {
  const theme = useTheme()
  const onClickHandler = enabled ? onClick : null
  return (
    <Box id={id}>
      <Box
        component="button"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        height="100%"
        minHeight="140px"
        sx={(theme) => ({
          cursor: enabled ? "pointer" : "default",
          backgroundColor: theme.palette.background.paper,
          padding: `${theme.spacing(theme.tokens.spacing.md)} ${theme.spacing(theme.tokens.spacing.lg)}`,
          opacity: enabled ? 1 : 0.6,
          outlineOffset: theme.spacing(theme.tokens.spacing.xxxs)
        })}
        border={(theme) =>
          enabled
            ? `solid ${theme.tokens.borderWidth.md} ${theme.palette.border.light}`
            : `solid ${theme.tokens.borderWidth.md} ${theme.palette.secondary.light}`
        }
        borderRadius={(theme) => theme.tokens.borderRadius.md}
        onClick={onClickHandler}
        tabIndex={!enabled ? -1 : undefined}
      >
        <Stack width="100%" spacing={theme.spacing(theme.tokens.spacing.md)}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={theme.spacing(theme.tokens.spacing.xxl)}
            height={theme.spacing(theme.tokens.spacing.xxl)}
            sx={(theme) => ({
              backgroundColor: theme.palette.background.hover,
              borderRadius: "100%"
            })}
          >
            <Box component="img" alt="" src={`/images/${iconName.valueOf()}.png`} />
          </Box>
          <Box
            width="100%"
            display="flex"
            gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}
            justifyContent="space-between"
          >
            <Typography sx={{ textAlign: "start" }} variant="h5">
              {label}
            </Typography>
            <Box minWidth="10px">
              <InternalIcon color="primary" />
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}

type CalendlyWidgetProps = {
  rootElementId: string
  url: string
  open: boolean
  setOpen: (value: boolean) => void
  chatType: string
  employeeId: number
}

const CalendlyWidget = ({
  rootElementId,
  url,
  open,
  setOpen,
  chatType,
  employeeId
}: CalendlyWidgetProps): JSX.Element => {
  useEffect(() => {
    // @ts-expect-error TS7006
    const handleEsc = (event): void => {
      if (event.keyCode === 27) {
        setOpen(false)
      }
    }
    window.addEventListener("keydown", handleEsc)

    return () => {
      window.removeEventListener("keydown", handleEsc)
    }
  }, [setOpen])

  return (
    <PopupModal
      url={url}
      utm={{
        utmCampaign: String(employeeId),
        utmSource: chatType
      }}
      onModalClose={() => setOpen(false)}
      open={open}
      rootElement={document.getElementById(rootElementId)}
      pageSettings={{
        primaryColor: CALENDLY_PRIMARY_COLOR
      }}
    />
  )
}

type ScheduleIvfChatButtonProps = {
  employeeId: number
  enabled: boolean
}

const ScheduleIvfChatButton: FC<ScheduleIvfChatButtonProps> = ({ employeeId, enabled }) => {
  const rootElementId = "scheduleIvfChatButton"
  const intl = useIntl()
  const { showTimeTap } = useFlags()
  const [open, setOpen] = useState(false)
  const localeForEnvVar = intl.locale.toUpperCase().replace("-", "_")
  const treatmentOptionsUrlPropertyName = `IVF_TREATMENT_OPTIONS_URL_${localeForEnvVar}`
  const treatmentOptionsUrl =
    treatmentOptionsUrlPropertyName in Settings
      ? // @ts-expect-error TS7053
        Settings[treatmentOptionsUrlPropertyName]
      : Settings.IVF_TREATMENT_OPTIONS_URL
  const ivfChatTimeTapReasonId = Settings.IVF_REQUIREMENTS_CHAT_REASON_ID
  const timeTapEnvironment = Settings.IVF_REQUIREMENTS_CHAT_TIMETAP_ENVIRONMENT as TimeTapEnvironment
  const [showChatConfirmation, setShowChatConfirmation] = useState(false)

  useReportToHeapTimeTapChatScheduled(
    setShowChatConfirmation,
    {
      eventName: "Ivf requirements chat scheduled",
      properties: {
        "Reason ID": ivfChatTimeTapReasonId
      }
    },
    Boolean(ivfChatTimeTapReasonId)
  )

  return (
    <>
      <IvfRequirementsButton
        id={rootElementId}
        enabled={enabled}
        iconName="icn-schedule-chat"
        label={intl.formatMessage({ defaultMessage: "Schedule your expert chat" })}
        onClick={() => setOpen(true)}
      />
      {showTimeTap && ivfChatTimeTapReasonId ? (
        <>
          <DialogTimeTapScheduler
            reasonId={Number(ivfChatTimeTapReasonId)}
            timeTapEnvironment={timeTapEnvironment}
            open={open && !showChatConfirmation}
            setOpen={setOpen}
          />
          <TimeTapConfirmationDialog
            open={showChatConfirmation}
            onClose={() => {
              setOpen(false)
              setShowChatConfirmation(false)
            }}
            chatType="OTHER"
          />
        </>
      ) : (
        <CalendlyWidget
          rootElementId={rootElementId}
          url={treatmentOptionsUrl}
          chatType="IvfRequirementsChat"
          open={open}
          setOpen={setOpen}
          employeeId={employeeId}
        />
      )}
    </>
  )
}

type SignAuthorizationFormModalButtonProps = {
  enabled: boolean
}

const windowEventListeners: Array<(event: SignatureCompleteEvent) => void> = []

const SignAuthorizationFormModalButton: FC<SignAuthorizationFormModalButtonProps> = ({ enabled }) => {
  const intl = useIntl()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [frameSrc, setFrameSrc] = useState("/blank.html")
  const [docuSignIsReady, setDocuSignIsReady] = useState(false)

  const handleWindowEvent = (event: SignatureCompleteEvent) => {
    if (event?.data?.documentType === CarrotDocumentType.IvfMedicalRecordsConsentForm) {
      // For now, only disable the tile (signed=true) upon a successful signing of the document.
      if (event?.data?.event === DocuSignEvent.SigningComplete) {
        onDocuSignSessionComplete()
        const separator = window.location.href.indexOf("?") === -1 ? "?" : "&"
        window.location.href = window.location.href + separator + `${CarrotQueryParam.Signed}=true`
      } else {
        onCloseModal()
      }
    }
  }

  const onDocuSignSessionComplete = () => {
    windowEventListeners.forEach((listener) => window.removeEventListener("message", listener))
    windowEventListeners.length = 0
    setFrameSrc("/blank.html")
    setDocuSignIsReady(false)
  }

  const onCloseModal = () => {
    onDocuSignSessionComplete()
    setModalIsOpen(false)
  }

  const showDocument = async () => {
    setModalIsOpen(true)
    windowEventListeners.push(handleWindowEvent)
    window.addEventListener("message", handleWindowEvent)
    const envelopeSignatureInfo = await getSignatureForDocument(CarrotDocumentType.IvfMedicalRecordsConsentForm)
    setFrameSrc(envelopeSignatureInfo.docuSignUrl)
    setDocuSignIsReady(true)
  }

  return (
    <>
      <IvfRequirementsButton
        id="signIvfConsentButton"
        enabled={enabled}
        iconName="icn-documents"
        label={intl.formatMessage({ defaultMessage: "Sign your document" })}
        onClick={showDocument}
      />
      <EmployeeOnlyModalContainer onClose={onCloseModal}>
        <Dialog
          aria-labelledby={intl.formatMessage({ defaultMessage: "Authorization Form Modal" })}
          onClose={onCloseModal}
          open={modalIsOpen}
          fullScreen={true}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={onCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            height="95%"
            paddingLeft={(theme) => theme.tokens.spacing.xxxl}
            paddingRight={(theme) => theme.tokens.spacing.xxxl}
          >
            <>
              {docuSignIsReady ? (
                <iframe
                  className="w-100 h-100 border-box ba b--black-30"
                  title={intl.formatMessage({ defaultMessage: "Authorization Form" })}
                  src={frameSrc}
                  allowFullScreen
                />
              ) : (
                <CenteredProgress />
              )}
            </>
          </Box>
        </Dialog>
      </EmployeeOnlyModalContainer>
    </>
  )
}

const RequirementsSteps = ({
  ivfInfo,
  isConsentSignedOverride
}: {
  ivfInfo: IvfRequirementsInfo
  isConsentSignedOverride: boolean
}) => {
  const theme = useTheme()
  const isConsentSigned = ivfInfo.hasIvfConsentSignature || isConsentSignedOverride
  return (
    <Grid container spacing={0}>
      <Grid container columns={2} spacing={theme.spacing(theme.tokens.spacing.lg)}>
        <Grid item md={1} xs={2}>
          <Typography variant="h5" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxs)}>
            <FormattedMessage defaultMessage="Step 1" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage defaultMessage="Meet with an IVF expert to discuss treatment options and what Carrot can cover, and complete a questionnaire before and after. The person undergoing the embryo transfer must attend the chat. If you’re pursuing IVF with a partner, we encourage both of you to attend." />
          </Typography>
        </Grid>
        <Grid item md={1} xs={2}>
          <Typography variant="h5" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxs)}>
            <FormattedMessage defaultMessage="Step 2" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage defaultMessage="Allow Carrot to view relevant health information for the person(s) receiving an embryo transfer. Personal information is always kept secure, and we will never share it with your employer or any other third party." />
          </Typography>
        </Grid>
        <Grid item md={1} xs={2}>
          <ScheduleIvfChatButton employeeId={ivfInfo.employeeId} enabled={!ivfInfo.hasUpcomingOrCompletedChat} />
        </Grid>
        <Grid item md={1} xs={2}>
          <SignAuthorizationFormModalButton enabled={!isConsentSigned} />
        </Grid>
      </Grid>
    </Grid>
  )
}

const IvfRequirements: FC = () => {
  const intl = useIntl()
  const theme = useTheme()
  const history = useHistory()
  const [signed] = useQueryParam(CarrotQueryParam.Signed, StringParam)
  const ivfRequirementsInfoQueryResult = useIvfRequirementsInfo()
  const isConsentSignedOverride = Boolean(signed)
  const backOnClick = isConsentSignedOverride ? () => history.go(-4) : history.goBack
  const redirectToTransactionsPage = () => {
    history.push(CarrotRoute.Transactions.valueOf())
  }
  const ivfRequirementsCompleteOrNotAvailable =
    !ivfRequirementsInfoQueryResult.isLoading &&
    !ivfRequirementsInfoQueryResult.isError &&
    (!ivfRequirementsInfoQueryResult.data?.hasIvfRequirements ||
      (ivfRequirementsInfoQueryResult.data?.hasUpcomingOrCompletedChat &&
        ivfRequirementsInfoQueryResult.data?.hasIvfConsentSignature))

  if (ivfRequirementsCompleteOrNotAvailable) {
    redirectToTransactionsPage()
  }

  return (
    <PageLayout
      variant="sidebar"
      sidebar={<></>}
      header={
        <PageHeader
          startGlyph={
            <PageHeaderGlyph src="/images/icn-get-reimbursed.png" srcSet="/images/icn-get-reimbursed-2x.png" />
          }
          pageTitle={<FormattedMessage defaultMessage="How to use Carrot funds for IVF" />}
        />
      }
    >
      <Title title={intl.formatMessage({ defaultMessage: "How to use Carrot funds for IVF" })} />
      <EmployeeOnlySpaceContainer>
        {ivfRequirementsInfoQueryResult.isError ? (
          <ErrorPage />
        ) : (
          <Stack spacing={theme.tokens.spacing.lg}>
            <Typography variant="body1">
              <FormattedMessage defaultMessage="To support healthy outcomes, members are required to complete two steps before they can use Carrot funds for IVF-related expenses." />
            </Typography>
            <Stack spacing={theme.tokens.spacing.xxl}>
              <Typography variant="body1">
                <FormattedMessage defaultMessage="If you decide to pursue IVF, we recommend completing these as early as possible to ensure that you'll be able to use your Carrot funds." />
              </Typography>
              {ivfRequirementsInfoQueryResult.isLoading ? (
                <CenteredProgress />
              ) : (
                <Stack spacing={theme.tokens.spacing.xxxl}>
                  <RequirementsSteps
                    ivfInfo={ivfRequirementsInfoQueryResult.data}
                    isConsentSignedOverride={isConsentSignedOverride}
                  />
                  <BackButtons backOnClick={backOnClick} doThisLaterOnClick={redirectToTransactionsPage} />
                </Stack>
              )}
            </Stack>
            <Divider />
          </Stack>
        )}
      </EmployeeOnlySpaceContainer>
    </PageLayout>
  )
}

export { IvfRequirements }
