import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useSetDoneWithCarrot } from "../useQueryCaseRateRenewals"
import { useCaseRateRenewals } from "../useCaseRateRenewals"
import { DoneForNowContent } from "../DoneForNowContent"

export function DoneUsingCarrotStep(): JSX.Element {
  const { send, back } = useStateMachine(null)
  const { mutate, isLoading } = useSetDoneWithCarrot()
  const { setRequestError, setDisableModalCloseOnLoading } = useCaseRateRenewals()

  function onError(): void {
    setRequestError(true)
    setDisableModalCloseOnLoading(false)
    send("ERROR")
  }

  function onSuccess(): void {
    setDisableModalCloseOnLoading(false)
    send("THANKS_FOR_LETTING_US_KNOW")
  }

  async function doneWithCarrot(): Promise<void> {
    setDisableModalCloseOnLoading(true)
    mutate(null, { onSuccess, onError })
  }
  return <DoneForNowContent takeMeBackOnClick={back} yesImDoneOnClick={doneWithCarrot} isLoading={isLoading} />
}
