import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import {
  hasQuestionBeenSkipped,
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "components/carrot-plans/shared/useQueryRoutingInfo"
import { ButtonFooterLayout, ConfirmButton, RoutingFlowTitle, StepLayout } from "components/carrot-plans/shared"
import { FormattedMessage, useIntl } from "react-intl"
import { Form, FormTextField } from "@carrotfertility/carotene-core-x"
import { useTrackExperimentEvent } from "components/views/register/hooks/useExperiments"

export default function IsThereAnythingElseStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.memberJourneyAdditionalInfo
  const { send } = useStateMachine(
    defaultValue || hasQuestionBeenSkipped("memberJourneyAdditionalInfo", routingData) ? "" : null
  )
  const isRefreshRoutingFlow = window.location.href.includes("/carrot-plans/case-rate-renewal/routing")
  const intl = useIntl()
  const { trackExperimentEvent } = useTrackExperimentEvent()

  // @ts-expect-error TS7006
  function sanitizeInputs(inputValue) {
    // @ts-expect-error TS7006
    return inputValue === null || inputValue.split("").every((char) => char === " ") || inputValue === ""
      ? null
      : inputValue
  }
  async function onSkip(): Promise<void> {
    await mutateAsync({ skipFields: ["memberJourneyAdditionalInfo"] }, { onSuccess: () => send("") })
  }
  async function onSubmit(formValues: { memberJourneyAdditionalInfo?: string }): Promise<void> {
    const sanitizedInput = sanitizeInputs(formValues?.memberJourneyAdditionalInfo)
    if (!isRefreshRoutingFlow) {
      // track for journey selection in registration experiment
      trackExperimentEvent("carrot-plan-created")
    }
    if (sanitizedInput === null) {
      await onSkip()
    } else {
      await mutateAsync({ memberJourneyAdditionalInfo: sanitizedInput }, { onSuccess: () => send("") })
    }
  }

  const header = intl.formatMessage({
    defaultMessage: "Is there anything else you'd like to share?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="memberJourneyAdditionalInfo-label">{header}</span>}
        description={intl.formatMessage({
          defaultMessage:
            "This could include information about your journey to this point, any other journeys you are on or considering, or any personal or cultural considerations you'd like us to be aware of."
        })}
        formElements={
          <Form onSubmit={onSubmit}>
            <FormTextField
              name="memberJourneyAdditionalInfo"
              defaultValue={defaultValue}
              rows={4}
              multiline
              inputProps={{ maxLength: 3000, "aria-labelledby": "memberJourneyAdditionalInfo-label" }}
            />
            <ButtonFooterLayout
              continueButton={
                <ConfirmButton>
                  {isRefreshRoutingFlow ? (
                    <FormattedMessage defaultMessage="Refresh Carrot Plan" />
                  ) : (
                    <FormattedMessage defaultMessage="Create Carrot Plan" />
                  )}
                </ConfirmButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
