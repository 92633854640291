import React from "react"
import {
  Box,
  CloseIcon,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  IconButton
} from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"

interface RoutingFlowDialogProps extends Pick<DialogProps, "open"> {
  title: React.ReactNode
  description: React.ReactNode
  onClose: () => void
}

export function RoutingFlowDialog({ title, description, ...otherProps }: RoutingFlowDialogProps) {
  const intl = useIntl()
  return (
    <Dialog
      aria-labelledby="routing-flow-modal-title"
      aria-describedby="routing-flow-modal-description"
      {...otherProps}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={otherProps?.onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle id="routing-flow-modal-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="routing-flow-modal-description">{description}</DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
