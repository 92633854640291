import { FlexContainer } from "@carrotfertility/carotene"
import { Button, BackIcon, ForwardIcon, Stack, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { FormattedMessage } from "react-intl"

export const NavFooter = ({
  isFirstPage,
  onNext,
  onBack,
  nextText,
  navDataAttributes
}: {
  isFirstPage: boolean
  onNext: (e: React.MouseEvent<HTMLElement>) => void
  onBack: (e: React.MouseEvent<HTMLElement>) => void
  nextText: string
  navDataAttributes: { slug: string; pageIndex: number; sectionTotalPages: number }
}): JSX.Element => {
  const theme = useTheme()

  const { slug, pageIndex, sectionTotalPages } = navDataAttributes
  return (
    <Stack
      paddingX={{
        sm: theme.tokens.spacing.md,
        md: theme.tokens.spacing.xxxl,
        lg: theme.tokens.spacing.xxxl
      }}
      paddingY={{
        xs: theme.tokens.spacing.md,
        sm: theme.tokens.spacing.lg,
        md: theme.tokens.spacing.lg,
        lg: theme.tokens.spacing.lg
      }}
      direction="row"
      justifyContent={isFirstPage ? "flex-end" : "space-between"}
    >
      {!isFirstPage && (
        <FlexContainer
          id="back-button"
          data-slug={slug}
          data-pageIndex={pageIndex}
          data-section-totalPages={sectionTotalPages}
        >
          <Button variant="outlined" color="secondary" onClick={onBack}>
            <Stack spacing={theme.spacing(theme.tokens.spacing.xs)} useFlexGap direction="row" alignItems="center">
              <BackIcon color="secondary" />
              <FormattedMessage defaultMessage="Back" description="navigate to the previous page of the guide" />
            </Stack>
          </Button>
        </FlexContainer>
      )}
      <FlexContainer
        id="next-button"
        data-slug={slug}
        data-pageIndex={pageIndex}
        data-section-totalPages={sectionTotalPages}
      >
        {nextText === "Finish" ? (
          <Button color="primary" onClick={onNext}>
            <FormattedMessage
              defaultMessage="{nextText}"
              values={{
                nextText
              }}
            />
          </Button>
        ) : (
          <Button variant="outlined" color="secondary" onClick={onNext}>
            <Stack spacing={theme.spacing(theme.tokens.spacing.xs)} useFlexGap direction="row" alignItems="center">
              <FormattedMessage
                defaultMessage="{nextText}"
                values={{
                  nextText
                }}
              />
              <ForwardIcon color="secondary" />
            </Stack>
          </Button>
        )}
      </FlexContainer>
    </Stack>
  )
}
