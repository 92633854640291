import React from "react"
import { Container, Icon, Text, Tooltip } from "@carrotfertility/carotene"
import { FormattedMessage } from "react-intl"

export default function RemoveEmailTooltip({ color = "carrotOrange" }: { color?: string }): JSX.Element {
  return (
    <Tooltip
      anchor={<Icon size={"small"} name="helpCircle" color={color} />}
      content={
        <Container maxWidth={"300px"}>
          <Text>
            <FormattedMessage defaultMessage="If you remove this address, our messages will go to the address you use for signing in to Carrot." />
          </Text>
        </Container>
      }
    />
  )
}
