import React from "react"
import { useCurrentUser } from "../components/context/user/UserContext"

type DisallowWhilePosingProps = {
  children: JSX.Element
}

export function DisallowWhilePosing({ children }: DisallowWhilePosingProps): JSX.Element {
  const { isPosingAsMember } = useCurrentUser()

  return isPosingAsMember ? null : <>{children}</>
}
