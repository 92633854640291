import React from "react"
import PlanInformationRow, { InsurancePlanInformationRowData } from "./InsurancePlanInformationRow"
import { useIntl } from "react-intl"
import { Table, TableCell, TableContainer, TableHead, TableRow } from "@carrotfertility/carotene-core"

type PlanInformationTableProps = {
  planData: InsurancePlanInformationRowData[]
  hasPartner: boolean
  insuranceExpired: boolean
}
const InsurancePlanInformationTable = (props: PlanInformationTableProps): JSX.Element => {
  const { planData, hasPartner, insuranceExpired } = props
  const intl = useIntl()

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="td"></TableCell>
            <TableCell>
              {hasPartner
                ? intl.formatMessage({ defaultMessage: "You" })
                : intl.formatMessage({ defaultMessage: "Answers" })}
            </TableCell>
            {hasPartner && <TableCell>{intl.formatMessage({ defaultMessage: "Your Partner" })}</TableCell>}
          </TableRow>
        </TableHead>
        {planData.map((row, index) => (
          <PlanInformationRow key={index} {...{ hasPartner, insuranceExpired, ...row }} />
        ))}
      </Table>
    </TableContainer>
  )
}

export default InsurancePlanInformationTable
