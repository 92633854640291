import React, { FC } from "react"
import { MaybeLink, MaybeLinkProps } from "./MaybeLink"

export type TextLinkProps = {
  underline?: boolean
  underlineColor?: string
} & MaybeLinkProps

/**
 * Your go-to component for creating links in the app
 */
const TextLink: FC<TextLinkProps> = ({
  to,
  children,
  underline = true,
  underlineColor = "light-carrot-orange",
  ...props
}: TextLinkProps) => {
  const underlineClass = underline ? `bb b--${underlineColor}` : ""
  const classes = `${underlineClass} no-underline pointer cc dim animate-all lh-copy`
  const style = {
    paddingBottom: "1px"
  }
  return (
    <MaybeLink to={to} className={classes} style={style} {...props}>
      {children}
    </MaybeLink>
  )
}
export { TextLink }
