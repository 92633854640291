import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import EmployeeOnlyModalContainer from "../employee-only-containers/EmployeeOnlyModalContainer"
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@carrotfertility/carotene-core"

type RemoveEmailModalProps = {
  open: boolean
  closeModal: () => void
  removeDisabled: boolean
  onRemovePersonalEmail: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>
}

export default function RemoveEmailModal({
  open,
  closeModal,
  removeDisabled,
  onRemovePersonalEmail
}: RemoveEmailModalProps): JSX.Element {
  const intl = useIntl()
  const [showEmailRemoved, setShowEmailRemoved] = useState(false)

  useEffect(() => {
    if (open) {
      setShowEmailRemoved(false)
    }
  }, [open])

  function onClose() {
    closeModal()
  }

  return (
    <EmployeeOnlyModalContainer onClose={onClose} open={open}>
      <Dialog
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        maxWidth="md"
        open={open}
        onClose={onClose}
      >
        {showEmailRemoved ? (
          <>
            <DialogTitle id="modal-title">
              <FormattedMessage defaultMessage="Email address removed" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="modal-description">
                <FormattedMessage defaultMessage="This email address was successfully removed." />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                <FormattedMessage defaultMessage="Close" />
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <Box display="flex" justifyContent="flex-end">
              <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <DialogTitle id="modal-title">
              <FormattedMessage defaultMessage="Are you sure you want to remove this email address?" />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="modal-description">
                <FormattedMessage defaultMessage="If you remove this address, our messages will go to the address you use for signing in to Carrot." />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="secondary" onClick={onClose}>
                <FormattedMessage defaultMessage="Cancel" />
              </Button>
              <Button
                disabled={removeDisabled}
                onClick={async (event) => {
                  await onRemovePersonalEmail(event)
                  setShowEmailRemoved(true)
                }}
              >
                <FormattedMessage defaultMessage="Remove" />
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </EmployeeOnlyModalContainer>
  )
}
