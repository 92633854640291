import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import useAboutYou from "components/carrot-plans/hooks/useAboutYou"
import { StepLayout } from "components/carrot-plans/shared/StepLayout"
import { ButtonFooterLayout, ContinueButton, SkipButton } from "components/carrot-plans/shared/ButtonFooter"
import { Form, FormTextField } from "@carrotfertility/carotene-core-x"
import { FormattedMessage, useIntl } from "react-intl"
import { RoutingFlowTitle } from "components/carrot-plans/shared"

export default function AboutYourPartnerPreferredNameStep(): JSX.Element {
  const intl = useIntl()
  const { data, updateAboutYou, hasQuestionBeenSkipped } = useAboutYou()
  const { send } = useStateMachine(
    data?.partnerPreferredName || hasQuestionBeenSkipped("partnerPreferredName") ? "" : null
  )
  async function onSkip(): Promise<void> {
    await updateAboutYou({ partnerPreferredName: "skip" })
    send("")
  }
  // @ts-expect-error TS7006
  async function onClickContinue(formValues): Promise<void> {
    if (formValues?.partnerPreferredName) {
      await updateAboutYou(formValues)
      send("")
    } else {
      await onSkip()
    }
  }

  const header = intl.formatMessage({
    defaultMessage: "What should we call your partner?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={header}
        description={intl.formatMessage({
          defaultMessage:
            "The name they'd like us to use, if different from their legal name. This will not be shared with your employer or theirs."
        })}
        formElements={
          <Form onSubmit={onClickContinue}>
            <FormTextField
              name={"partnerPreferredName"}
              label={intl.formatMessage({ defaultMessage: "Name" })}
              inputProps={{ "data-testid": "about-you-partnerPreferredName" }}
              defaultValue={data?.partnerPreferredName}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
