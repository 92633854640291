import useBenefit from "components/dynamic-content/hooks/useGetBenefit"

export function useNavigateToDigitalFrontDoor() {
  const { data, isLoading } = useBenefit()
  return {
    navigateToDigitalFrontDoor:
      data?.carrotRx?.isCarrotRxActive &&
      (data?.journeys?.fertilityPreservation?.isAppSupportEnabled ||
        data?.journeys?.fertilityCare?.isAppSupportEnabled),
    isLoading
  }
}
