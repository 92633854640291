import { SendMessage } from "../../../talk-to-carrot/send-message/SendMessage"
import React from "react"
import { MemberHealthPlanNotListed } from "./HealthPlanNotListedStep"
import { PartnerHealthPlanNotListed } from "./PartnerHealthPlanNotListedStep"

export const MemberHealthPlanNotListedContainer = () => (
  <SendMessage modalTopic={"MemberHealthPlanConnect"} BenefitEnrollmentView={MemberHealthPlanNotListed} />
)

export const PartnerHealthPlanNotListedContainer = () => (
  <SendMessage modalTopic={"PartnerHealthPlanConnect"} BenefitEnrollmentView={PartnerHealthPlanNotListed} />
)
