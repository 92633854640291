import { useMutation, UseMutationResult } from "@tanstack/react-query"
import ResponseError from "types/responseError"
import { HttpStatusCodes } from "utils/HttpStatusCodes"
import Settings from "../../../utils/CarrotConfig"
import GuidesClient from "../lib/GuidesClient"
import { GuideSurveyAnswers } from "../types/GuideSurveyAnswers"

const client = new GuidesClient(Settings.CARROT_BACKEND_URL)

export function useSubmitGuideSurveyAnswers(): UseMutationResult<void, ResponseError> {
  return useMutation((submitAnswersData: GuideSurveyAnswers) => client.submitGuideSurveyAnswers(submitAnswersData), {
    retry: (failureCount, error) => {
      switch (error.statusCode) {
        case HttpStatusCodes.UNAUTHORIZED:
        case HttpStatusCodes.BAD_REQUEST:
        case HttpStatusCodes.NOT_FOUND:
          return false
        default:
          return failureCount <= 3
      }
    }
  })
}
