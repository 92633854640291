import React, { FC, useMemo } from "react"
import {
  CMD_CONFIGURATION,
  COMPANY_DEDUCTIBLE_STATUS,
  useUserDeductible
} from "../../../context/user/DeductibleContext"
import { CMDTableContainer } from "./CMDTable"
import { ExternalDeductibleInformational } from "./ExternalDeductibleInformational"
import { YourCostShareInformational } from "./YourCostShareInformational"
import subsidyStatus from "utils/CarrotPropTypes"
import { useBenefitEnrollment } from "../../../context/enrollment/BenefitEnrollmentContext"
import EmployeeOnlyModalContainer from "../../../employee-only-containers/EmployeeOnlyModalContainer"
import { DbgPaths } from "../../../../utils/Paths"
import { FormattedMessage, useIntl } from "react-intl"
import { reimbursementCategoryNameMessageKeys } from "../reimbursementCategoryNameMessageKeys"
import {
  Box,
  CloseIcon,
  Dialog,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Typography
} from "@carrotfertility/carotene-core"

export type HowMuchMemberPartnerReimbursedModalProps = {
  onExit: () => void
  subsidy: typeof subsidyStatus
}

const HowMuchMemberPartnerReimbursedModal: FC<HowMuchMemberPartnerReimbursedModalProps> = ({ onExit, subsidy }) => {
  /* eslint-disable react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps */
  // @ts-expect-error TS2339
  const costShare = useMemo(() => subsidy && subsidy?.hasCostShareDisplay, [subsidy?.hasCostShareDisplay])
  /* eslint-enable react-hooks/exhaustive-deps */
  const { companyDeductibleStatus, cmdConfiguration, hasCMD } = useUserDeductible()
  const hasEMD = useMemo(
    () => companyDeductibleStatus === COMPANY_DEDUCTIBLE_STATUS.EXTERNAL,
    [companyDeductibleStatus]
  )
  const costShareOnly = useMemo(() => costShare && !hasCMD && !hasEMD, [costShare, hasCMD, hasEMD])
  const supportsOOPM = useBenefitEnrollment()?.companySupportsOOPM
  const intl = useIntl()

  return (
    <EmployeeOnlyModalContainer onClose={onExit}>
      <Dialog aria-labelledby="deductible-modal-header" maxWidth="md" onClose={onExit} open={true}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={onExit}>
            <CloseIcon />
          </IconButton>
        </Box>
        <ReimbursementModalHeaderAndCopy
          deductibleStatus={companyDeductibleStatus}
          cmdConfig={cmdConfiguration}
          hasCMD={hasCMD}
          hasEMD={hasEMD}
          costShare={costShare}
        />
        {companyDeductibleStatus === COMPANY_DEDUCTIBLE_STATUS.CARROT_MANAGED_DEDUCTIBLE && <CMDTableContainer />}
        {hasEMD && <ExternalDeductibleInformational costShare={costShare} />}
        {costShare && (
          <YourCostShareInformational
            // @ts-expect-error TS2551
            reimbursementPercent={subsidy.reimbursementPercent}
            isDefaultCategory={
              // @ts-expect-error TS2339
              subsidy.reimbursementCategoryNameMessageKey === reimbursementCategoryNameMessageKeys.DEFAULT
            }
            costShareOnly={costShareOnly}
            supportsOOPM={supportsOOPM}
          />
        )}
      </Dialog>
    </EmployeeOnlyModalContainer>
  )
}

export type ReimbursementModalHeaderAndCopyProps = {
  cmdConfig: string
  deductibleStatus: string
  hasCMD: boolean
  hasEMD: boolean
  costShare: boolean
}

function ReimbursementModalHeaderAndCopy({
  cmdConfig,
  deductibleStatus,
  hasCMD,
  hasEMD,
  costShare
}: ReimbursementModalHeaderAndCopyProps): JSX.Element {
  const cmdEnabled = deductibleStatus === COMPANY_DEDUCTIBLE_STATUS.CARROT_MANAGED_DEDUCTIBLE
  const memberAndPartnerSeparateCMD = cmdEnabled && cmdConfig === CMD_CONFIGURATION.MEMBER_AND_PARTNER_SEPARATE
  const hasLegacyCMD = cmdConfig === null && hasCMD
  const memberOnlyCMD = cmdEnabled && (cmdConfig === CMD_CONFIGURATION.MEMBER_ONLY || hasLegacyCMD)
  const memberAndPartnerCombinedCMD = cmdEnabled && cmdConfig === CMD_CONFIGURATION.MEMBER_AND_PARTNER_COMBINED

  const aboutYour =
    hasCMD || hasEMD ? (
      <FormattedMessage defaultMessage="About your deductible" />
    ) : (
      <FormattedMessage defaultMessage="About your cost share" />
    )

  const costShareText = costShare ? (
    <FormattedMessage defaultMessage="Funds you use may also be subject to bank fees, taxes, cost sharing, and other plan details." />
  ) : (
    <FormattedMessage defaultMessage="Funds you use may also be subject to bank fees, taxes, and other plan details." />
  )

  // @ts-expect-error TS7006
  const deductibleTextLink = (linkText) => (
    <Link color="inherit" href={DbgPaths.QUALIFIED_MEDICAL_EXPENSES} target="_blank">
      {linkText}
    </Link>
  )

  const memberOnlyOrCombinedCMD = (
    <FormattedMessage
      defaultMessage="You will need to meet an annual deductible before you can use Carrot funds for eligible <link>qualified medical expenses</link>."
      values={{
        link: (linkText) => deductibleTextLink(linkText)
      }}
    />
  )

  const separateCMD = (
    <FormattedMessage
      defaultMessage="You and your partner will need to meet annual deductibles before you can use Carrot funds for eligible <link>qualified medical expenses</link>."
      values={{
        link: (linkText) => deductibleTextLink(linkText)
      }}
    />
  )

  const partnerOnlyCMD = (
    <FormattedMessage
      defaultMessage="Your partner will need to meet an annual deductible before you can use Carrot funds for eligible <link>qualified medical expenses</link>."
      values={{
        link: (linkText) => deductibleTextLink(linkText)
      }}
    />
  )

  let deductibleText: React.ReactNode

  if (memberOnlyCMD || memberAndPartnerCombinedCMD) {
    deductibleText = memberOnlyOrCombinedCMD
  } else if (memberAndPartnerSeparateCMD) {
    deductibleText = separateCMD
  } else if (!memberOnlyCMD && !memberAndPartnerCombinedCMD && !memberAndPartnerSeparateCMD) {
    deductibleText = partnerOnlyCMD
  }

  return (
    <Stack>
      <DialogTitle id="deductible-modal-header">{aboutYour}</DialogTitle>
      {hasCMD && (
        <Typography>
          {deductibleText} {costShareText}
        </Typography>
      )}
    </Stack>
  )
}

export { HowMuchMemberPartnerReimbursedModal }
