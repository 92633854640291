import { useBenefitEnrollment } from "components/context/enrollment/BenefitEnrollmentContext"
import { SelectOption } from "content/SelectOption"
import { useEffect } from "react"
import { useToggle } from "utils/Hooks"
import { providerNotListedValue } from "."
import { TradingPartner, CheckIfProviderIsListed, DefaultPartnerVal } from "../types"
import { useIntl } from "react-intl"

export interface UseTradingPartners {
  options: SelectOption[]
  providerListed: boolean
  availableTradingPartners: TradingPartner[]
  checkIfProviderIsListed: CheckIfProviderIsListed
}

export interface UseTradingPartnersArgs {
  defaultTradingPartnerPk: DefaultPartnerVal
}

export function useTradingPartners({ defaultTradingPartnerPk }: UseTradingPartnersArgs): UseTradingPartners {
  const { availableTradingPartners } = useBenefitEnrollment()
  const [providerListed, setProviderListed] = useToggle(true)
  const intl = useIntl()

  function checkIfProviderIsListed(selectedValue: SelectOption): SelectOption {
    parseInt(selectedValue.id, 10) === -1 ? setProviderListed(false) : setProviderListed(true)
    return selectedValue
  }

  if (availableTradingPartners && !availableTradingPartners.some((tp) => tp.id === providerNotListedValue)) {
    availableTradingPartners.push({
      id: providerNotListedValue,
      description: intl.formatMessage({ defaultMessage: "Provider not listed" }),
      tradingPartnerPk: -1
    })
  }

  // @ts-ignore
  const options: SelectOption[] = availableTradingPartners.map((tradingPartner: TradingPartner, index) => ({
    key: index,
    label: tradingPartner.description,
    id: tradingPartner.tradingPartnerPk
  }))

  useEffect(() => {
    checkIfProviderIsListed({ id: defaultTradingPartnerPk, label: "" })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return {
    options,
    providerListed,
    availableTradingPartners,
    checkIfProviderIsListed
  }
}
