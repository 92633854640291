import React from "react"
import useBenefit from "../../../../dynamic-content/hooks/useGetBenefit"
import { TextLink } from "@carrotfertility/carotene"
import { DbgPaths } from "../../../../../utils/Paths"
import { FormattedMessage } from "react-intl"
import { CategorySections } from "../PayingForCareModal"

const PreservationHeader = (): JSX.Element => {
  const { data: benefitInfo } = useBenefit()
  const journeys = benefitInfo.journeys
  return (
    <>
      {(journeys.fertilityCare.eligibleExpensesDetail.isCoveredWithInfertilityDiagnosis ||
        journeys.fertilityCare.eligibleExpensesDetail.isElectiveCareCovered) && (
        <>
          {journeys.fertilityCare.eligibleExpensesDetail.hasDonorAssistance ? (
            <p>
              <FormattedMessage
                defaultMessage="This category includes eligible <link>fertility care</link> expenses and related donor assistance."
                values={{
                  link: (linkContent) => (
                    <TextLink size="medium" target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                      {linkContent}
                    </TextLink>
                  )
                }}
              />
            </p>
          ) : (
            <p>
              <FormattedMessage
                defaultMessage="This category includes eligible <link>fertility care</link> expenses."
                values={{
                  link: (linkContent) => (
                    <TextLink size="medium" target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                      {linkContent}
                    </TextLink>
                  )
                }}
              />
            </p>
          )}
          <br />
          <p>
            <FormattedMessage defaultMessage="Fertility care involves actively trying for a pregnancy, including up to one year of related storage costs for eggs, sperm, and embryos." />
          </p>
        </>
      )}
      {journeys.fertilityCare.eligibleExpensesDetail.hasDonorAssistance && (
        <p>
          <FormattedMessage
            defaultMessage="This category includes eligible <link>donor assistance</link> expenses related to fertility care."
            values={{
              link: (linkContent) => (
                <TextLink size="medium" target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                  {linkContent}
                </TextLink>
              )
            }}
          />
        </p>
      )}
    </>
  )
}

export function getPreservationSections(): CategorySections {
  return {
    header: <PreservationHeader />
  }
}
