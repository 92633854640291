import React from "react"
import { Redirect, useParams } from "react-router-dom"
import slugify from "slugify"
import { CategoryPage } from "./CategoryPage"
import useGetCategories from "./shared/useGetCategories"
import { ErrorPage } from "../views/app/ErrorPage"

export function ReadAndLearnCategoryPage(): JSX.Element {
  const params = useParams<{ category?: string }>()
  const { data: categories } = useGetCategories()

  if (!params.category) {
    return <Redirect to="/read" />
  }
  if (!categories) {
    return <ErrorPage />
  }
  const categoryExists = categories.find(({ fields: { navTitle } }) => slugify(navTitle) === params.category)

  if (!categoryExists) {
    return <Redirect to="/read" />
  }

  return <CategoryPage />
}
