import React, { HTMLAttributes, ReactNode } from "react"
import { Label, Spacer, Asterisk } from "../Atoms"
import { SharedInputCssProps } from "./StyledInputs"
import { ValidationMessage } from "components/views/atoms/forms/ValidationMessage"
import { FieldErrors } from "react-hook-form"

export type CompleteFormElementProps<Element> = {
  name: string
  label?: string
  showAsterisk?: boolean
  rightLabel?: ReactNode
  errors?: FieldErrors<any>
  showValidation?: boolean
  disabled?: boolean
  helperText?: string
  defaultValue?: any
} & HTMLAttributes<Element> &
  Omit<SharedInputCssProps, "valid">

function completeFormElementFactory<Element extends HTMLElement, Props extends CompleteFormElementProps<Element>>(
  Component: any
) {
  // eslint-disable-next-line react/display-name
  return React.forwardRef<Element, Props>(
    (
      {
        name,
        label = null,
        showAsterisk = false,
        rightLabel,
        errors = {},
        showValidation = true,
        helperText = "",
        disabled = false,
        ...props
      },
      ref
    ) => {
      const valid = errors[name] === undefined
      return (
        <div>
          {label && (
            <>
              <div className="flex justify-between">
                <Label htmlFor={name} valid={valid}>
                  {label}
                  {showAsterisk ? <Asterisk /> : null}
                </Label>
                {rightLabel}
              </div>
              <Spacer height={0.5} />
            </>
          )}
          <Component readOnly={disabled} name={name} label={label} ref={ref} valid={valid} {...props} />
          {showValidation && <ValidationMessage helperText={helperText} errors={errors} name={name} />}
        </div>
      )
    }
  )
}

export { completeFormElementFactory }
