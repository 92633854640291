import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useGetPartnerInviteStatus } from "components/partner-access/hooks/usePartnerAccess"
import { PartnerInviteStatus } from "components/partner-access/partnerAccessTypes"
import { useSelector } from "react-redux"
import { getBenefitConfiguration } from "../../../../reducers/benefitConfiguration"

export function PartnerInfoNeededDecisionStep(): JSX.Element {
  const benefitConfiguration = useSelector(getBenefitConfiguration)
  const lgbtqEnabled = benefitConfiguration.featureConfig.isLgtbqEnabled
  const { inviteData, isInviteStatusLoading } = useGetPartnerInviteStatus()
  const { send } = useStateMachine(null)

  useEffect(() => {
    // in restricted countries (lgbtq content disabled) we can't show or collect info on partners
    if (!lgbtqEnabled) {
      send("NO")
    }

    if (!isInviteStatusLoading) {
      const hasInviteBeenSent =
        inviteData?.status === PartnerInviteStatus.INVITED || inviteData?.status === PartnerInviteStatus.ACTIVE
      send(hasInviteBeenSent ? "NO" : "YES")
    }
  }, [isInviteStatusLoading, inviteData, send, lgbtqEnabled])
  return <></>
}
