import { StatusCodes } from "http-status-codes"
// @ts-expect-error TS7016
import { v4 as uuid } from "uuid"
import Settings from "../../../utils/CarrotConfig"
import { GeneratePaymentIntentResponse } from "../types/GeneratePaymentIntentResponse"
import { PaymentIntentArgs } from "../types/PaymentIntentArgs"
import StripeResponseError from "../types/StripeResponseError"

export default class InboundMemberPaymentsClient {
  async processJsonResponse<ResponseType>(response: Response, correlationId: string): Promise<ResponseType> {
    if (response.status === StatusCodes.OK) {
      return response?.json()
    }

    const buffer = await response.arrayBuffer()
    const text = new TextDecoder().decode(buffer)
    const data = JSON.parse(text)
    if (data && data.error) {
      throw new StripeResponseError(response, correlationId, data.error["message"], data.error["code"])
    } else {
      throw new Error(`Unexpected response from server: ${response.status}`)
    }
  }

  createJsonHeaders(correlationId: string): Headers {
    const headers = new Headers()
    headers.append("X-Request-ID", correlationId)
    headers.append("X-React-App-Version", process.env.REACT_APP_VERSION || "local")
    headers.append("Content-Type", "application/json")
    headers.append("X-Authorizer-Domain", Settings.AUTHORIZER_DOMAIN)
    return headers
  }

  // @ts-expect-error TS7006
  async post(url: URL, values): Promise<any> {
    const correlationId = uuid()

    const response = await fetch(url, {
      method: "POST",
      headers: this.createJsonHeaders(correlationId),
      credentials: "include",
      body: JSON.stringify({
        amount: values.amount,
        currency_code: values.currency_code,
        employee_id: values.employee_id,
        first_name: values.first_name,
        last_name: values.last_name,
        company_id: values.company_id,
        company_name: values.company_name,
        email: values.email
      })
    })

    return await this.processJsonResponse(response, correlationId)
  }

  async generatePaymentIntent(values: PaymentIntentArgs): Promise<GeneratePaymentIntentResponse> {
    const url = new URL(`${Settings.INBOUND_MEMBER_PAYMENTS_BASE_URL}/generate-payment-intent`)
    return await this.post(url, values)
  }
}
