import React from "react"
import { Redirect, Route } from "react-router"
import { useCurrentUser } from "../../context/user/UserContext"

// @ts-expect-error TS7031
export default function LockedDownRoute({ component: Component, path, ...rest }): JSX.Element {
  const { isInAppLockdown } = useCurrentUser()
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return isInAppLockdown ? <Redirect to="/carrot-plan-required" /> : <Component {...props} />
      }}
    />
  )
}
