import React from "react"
import { isArticle, isVideo } from "utils/ContentTypes"
import { useHistory, useParams } from "react-router-dom"
import { useContentfulConditionalGate } from "../../../lib/contentful/components/ContentfulConditionGate"
import ReadLearnArticle from "../../read/ReadLearnArticle"
import { findArticlesBySlug, findCategoryBySlug, findSubcategoryForArticle } from "../../read/shared/Finder"
import useGetCategories from "../../read/shared/useGetCategories"

const ReadArticleContainer = (): JSX.Element => {
  const history = useHistory()
  const params = useParams<{ category: string; article: string }>()

  const { data: categories } = useGetCategories()

  const articles = findArticlesBySlug(categories, params.article)
  const category = findCategoryBySlug(categories, params.category)
  const articleConditions = articles?.map((article) => {
    const subCategory = findSubcategoryForArticle(categories, article)
    return {
      element: (
        <ReadLearnArticle
          articleBody={isArticle(article) ? article.fields.body : null}
          articleTitle={article.fields.title}
          articleContent={isArticle(article) ? article.fields.content : null}
          category={category}
          isVideo={isVideo(article)}
          lastUpdated={article.sys.updatedAt}
          subCategory={subCategory}
          videoUrl={isVideo(article) ? article.fields.url : null}
        />
      ),
      conditions: article.fields.conditions
    }
  })

  const [article] = useContentfulConditionalGate(articleConditions ?? [], true)
  if (!article || !category) {
    history.push("/read")
    return null
  }

  return <>{article}</>
}

export { isVideo }

export default ReadArticleContainer
