import React from "react"
import MarkdownToJsx from "markdown-to-jsx"
import { defaultMarkdownElements } from "./elements"

type MarkdownToJsxProps = React.ComponentProps<typeof MarkdownToJsx>
export interface MarkdownProps {
  wrapper?: MarkdownToJsxProps["options"]["wrapper"]
  overrides?: Partial<unknown>
  options?: Omit<MarkdownToJsxProps["options"], "overrides" | "wrapper">
  children: MarkdownToJsxProps["children"]
}

export function Markdown({
  overrides = {},
  options = {},
  children,
  wrapper = ({ children }) => children
}: MarkdownProps) {
  if (!children) {
    return null
  }
  return (
    <MarkdownToJsx
      options={{
        ...options,
        wrapper,
        overrides: {
          ...defaultMarkdownElements,
          ...overrides
        },
        forceBlock: true
      }}
    >
      {children}
    </MarkdownToJsx>
  )
}

export { defaultMarkdownElements } from "./elements"
