import { Container, H2, Stack, TextLink } from "@carrotfertility/carotene"
import React from "react"
import { FormattedMessage } from "react-intl"
import { TtcPaths } from "../../../../utils/Paths"

export const FinancialSupportErrorMessage = (): JSX.Element => {
  return (
    <Container background={"lightGrey"} borderRadius={"8px"} padding={"48px"} width={"100%"}>
      <Stack direction={"column"} spacing={"small"}>
        <H2>
          <FormattedMessage defaultMessage="Something's not working" />
        </H2>
        <FormattedMessage
          defaultMessage="Try reloading this page. If the problem persists, feel free to <link>contact us</link>."
          values={{
            link: (linkContent) => (
              <TextLink to={TtcPaths.TTC_CARE_NAV} target={"_blank"}>
                {linkContent}
              </TextLink>
            )
          }}
        />
      </Stack>
    </Container>
  )
}
