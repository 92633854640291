export enum Steps {
  BEFORE_YOU_CONTINUE_WARNING = "BEFORE_YOU_CONTINUE_WARNING",
  INTERNATIONAL_ADDRESS_UPDATE_DECISION = "INTERNATIONAL_ADDRESS_UPDATE_DECISION",
  INTERNATIONAL_ADDRESS_UPDATE = "INTERNATIONAL_ADDRESS_CORRECTION",
  UPLOAD_STATEMENT = "UPLOAD_STATEMENT",
  ELIGIBLE_REIMBURSEMENT_AMOUNT = "ABOUT_EXPENSE_ELIGIBLE_REIMBURSEMENT_AMOUNT",
  WHO_RECEIVED_CARE = "ABOUT_EXPENSE_WHO_RECEIVED_CARE",
  WHAT_KIND_OF_FERTILITY_CARE = "ABOUT_EXPENSE_WHAT_KIND_OF_FERTILITY_CARE",
  WHAT_KIND_OF_SERVICE = "ABOUT_EXPENSE_WHAT_KIND_OF_PREGNANCY_AND_POSTPARTUM_SERVICE",
  EXPENSE_RELATED_TO = "ABOUT_EXPENSE_EXPENSE_RELATED_TO",
  ATTESTATION_MEDICALLY_NECESSARY_AND_ELIGIBLE = "ATTESTATION_MEDICALLY_NECESSARY_AND_ELIGIBLE",
  ATTESTATION_MEDICALLY_RELATED = "ATTESTATION_MEDICALLY_RELATED",
  RELATED_TO_STORAGE_FEES = "RELATED_TO_STORAGE_FEES",
  RELATED_TO_MEDICATION = "RELATED_TO_MEDICATION",
  RELATED_TO_INFERTILITY_DIAGNOSIS = "ABOUT_EXPENSE_RELATED_TO_INFERTILITY_DIAGNOSIS",
  STEPCHILD_ADOPTION = "ABOUT_EXPENSE_RELATED_TO_STEPCHILD_ADOPTION",
  MEDICALLY_NECESSARY = "ABOUT_EXPENSE_MEDICALLY_NECESSARY",
  ANYTHING_ELSE = "ADDITIONAL_INFO_ANYTHING_ELSE",
  DO_IVF_REQUIREMENT = "GET_REIMBURSED_DO_IVF_REQUIREMENT",
  FINAL_STEP = "GET_REIMBURSED_FINAL_STEP"
}
