import { Entry } from "contentful"
import { reportErrorMessage } from "../../../utils/ErrorReporting"

// @ts-expect-error TS7031
const handleField = async ({ field, idsInPath, getEntryById }) => {
  if (field.sys.type === "Link") {
    const linkEntry = await getEntryById(field.sys.id)
    await nestedRefs(linkEntry, getEntryById, [...idsInPath, field.sys.id])
    return linkEntry
  } else if (field.sys.type === "Entry") {
    await nestedRefs(field, getEntryById, [...idsInPath, field.sys.id])
    return field
  } else {
    return field
  }
}

// @ts-expect-error TS7031
const hasDuplicate = ({ field, idsInPath }) => {
  const fieldId = field.sys.id
  if (idsInPath.includes(fieldId)) {
    reportErrorMessage(
      `Contentful infinite loop discovered for entity with id: ${fieldId}, entry chain ${[...idsInPath, fieldId].join(
        " -> "
      )}`
    )
    return true
  }
  return false
}

// @ts-expect-error TS7031
const handleFieldKey = async ({ fields, key, idsInPath, getEntryById }) => {
  const field = fields[key]

  if (field) {
    if (Array.isArray(field)) {
      fields[key] = await Promise.all(
        field.map((fieldEntry) => {
          if (hasDuplicate({ field: fieldEntry, idsInPath })) {
            return null
          }
          return handleField({ field: fieldEntry, idsInPath, getEntryById })
        })
      )
    } else if (typeof field === "object") {
      if (hasDuplicate({ field, idsInPath })) {
        fields[key] = null
      } else {
        fields[key] = await handleField({ field, idsInPath, getEntryById })
      }
    }
  } else {
    fields[key] = null
  }
}

const nestedRefs = async (
  entry: Entry<any>,
  getEntryById: (id: string, locale: string) => Promise<Entry<any>>,
  idsInPath: string[] = []
) => {
  if (entry && entry.fields) {
    const { fields } = entry
    return Promise.all(Object.keys(fields).map((key) => handleFieldKey({ fields, key, idsInPath, getEntryById })))
  }
  return null
}

export { nestedRefs }
