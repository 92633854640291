export const findMatchedSubstring = (
  targetString: string,
  substringInput: string
): { length: number; offset: number } | null => {
  const index = targetString.toLowerCase().indexOf(substringInput.toLowerCase())

  if (index !== -1) {
    return {
      length: substringInput.length,
      offset: index
    }
  } else {
    return null
  }
}
