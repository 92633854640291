import actionTypes from "actions/actionTypes"
import { createIsLoadedReducer, createReducer } from "reducers/shared"
import { combineReducers } from "redux"

const reducers = {}

reducers.isLoaded = createIsLoadedReducer("FETCH_AVA")

reducers.landingPageUrl = createReducer(null, {
  [actionTypes.FETCH_AVA_CHECKOUT_SUCCESS]: (state, action) => action.response
})

reducers.status = createReducer("IDLE", {
  [actionTypes.FETCH_AVA_CHECKOUT_REQUEST]: () => "FETCHING",
  [actionTypes.FETCH_AVA_CHECKOUT_SUCCESS]: () => "COMPLETED",
  [actionTypes.FETCH_AVA_CHECKOUT_FAILURE]: () => "FAILED"
})

const avaCheckout = combineReducers(reducers)

export const getAvaCheckoutLandingPageUrl = (state) => state.avaCheckout?.landingPageUrl

export default avaCheckout
