import { useBreakpointValue } from "@carrotfertility/carotene"
import React from "react"
import { Guide } from "../utils/utils"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Divider,
  IconButton,
  Menu,
  MenuIcon,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  MenuItem,
  DialogTitle
} from "@carrotfertility/carotene-core"

const ExitModal = ({
  open,
  setOpen,
  onExit
}: {
  open: boolean
  // @ts-expect-error TS7006
  setOpen: (open) => void
  onExit: () => void
}): JSX.Element => {
  const intl = useIntl()
  const formattedContinue = intl.formatMessage({ defaultMessage: "Continue" })
  const formattedContinueGuide = intl.formatMessage({ defaultMessage: "Continue guide" })
  const continueButtonText = useBreakpointValue<string>([
    formattedContinue,
    formattedContinue,
    formattedContinueGuide,
    formattedContinueGuide
  ])
  function handleClose() {
    setOpen(false)
  }
  return (
    <Dialog
      aria-labelledby="guide-exit-modal-title"
      aria-describedby="guide-exit-modal-description"
      onClose={handleClose}
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="guide-exit-modal-title">
        <FormattedMessage defaultMessage="Are you sure you want to leave?" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="guide-exit-modal-description">
          <FormattedMessage defaultMessage="If you leave, you'll lose your progress and won't be able to pick up where you left off." />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={handleClose} id="continue-guide-modal-button">
          {continueButtonText}
        </Button>
        <Button onClick={onExit} id="leave-guide-modal-button">
          <FormattedMessage defaultMessage="Leave guide" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const PageNavigation = ({
  onExit,
  guide,
  onPageChange
}: {
  onExit: () => void
  guide: Guide
  onPageChange: (sectionIndex: number) => void
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [exitOpen, setExitOpen] = React.useState(false)
  const intl = useIntl()

  return (
    <>
      <IconButton
        id="guides-dropdown"
        aria-label={intl.formatMessage({ defaultMessage: "Navigation menu for the guide" })}
        onClick={handleClick}
        aria-controls={open ? "guides-dropdown-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        id="guides-dropdown-menu"
        anchorEl={anchorEl}
        MenuListProps={{
          "aria-labelledby": "guides-dropdown"
        }}
      >
        {guide.sections.map(({ title }, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              handleClose()
              onPageChange(i)
            }}
            id="sections-list"
          >
            {title}
          </MenuItem>
        ))}
        {guide.companionDocument && <Divider role="separator" />}
        {guide.companionDocument && (
          <MenuItem
            onClick={() => {
              window.open(guide.companionDocument.file.url, "_blank")
            }}
            id="download-worksheet-menuitem"
          >
            <FormattedMessage
              defaultMessage="Download worksheet"
              description="Link to download a pdf worksheet that accompanies an educational guide. The pdf is a resource for the user to use while going through the guide. It's intended to be used as a tool for weighing pros and cons and recording their preferences."
            />
          </MenuItem>
        )}
        <Divider role="separator" />
        <MenuItem
          onClick={() => {
            handleClose()
            setExitOpen(true)
          }}
          id="leave-guide-link"
        >
          <FormattedMessage defaultMessage="Leave guide" />
        </MenuItem>
      </Menu>

      <ExitModal open={exitOpen} setOpen={setExitOpen} onExit={onExit} />
    </>
  )
}
