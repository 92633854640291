declare global {
  interface Window {
    _paq: string[][]
  }
}

export function trackMatomoPageView(href: string, title = window.document.title): void {
  if (window._paq) {
    setTimeout(() => {
      window._paq.push(["setCustomUrl", href])
      window._paq.push(["setDocumentTitle", title])
      window._paq.push(["trackPageView"])
    }, 0)
  }
}
