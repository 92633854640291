import { Box, Theme, Stack, Link, Divider, Grid, Typography } from "@carrotfertility/carotene-core"
import React from "react"
import Checkmark from "../resources/images/checkmark.svg"
import { FormattedMessage, MessageDescriptor, defineMessage, useIntl } from "react-intl"
import { useDigitalFrontDoorBreakpointViews } from "../hooks/useDigitalFrontDoorBreakpointViews"

const CheckmarkIcon = () => {
  return (
    <Box
      height={24}
      borderRadius={(theme) => theme.tokens.borderRadius.round}
      sx={{ backgroundColor: "#2C634B" }}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      minWidth={24}
    >
      <Box component="img" src={Checkmark} alt="" />
    </Box>
  )
}

const handleOnClick = (sectionId: string) => {
  document.querySelector(sectionId).scrollIntoView({ behavior: "smooth" })
}

const StackItem = ({ id, onClick, message }: { id: string; onClick: () => void; message: MessageDescriptor }) => {
  const intl = useIntl()
  return (
    <Stack direction="row" gap={(theme) => theme.tokens.spacing.sm} alignItems="center">
      <CheckmarkIcon />
      <Link
        id={id}
        component="button"
        onClick={onClick}
        sx={{
          "text-decoration-color": (theme: Theme) => theme.palette.text.secondary,
          "&:hover": {
            textDecorationColor: "inherit"
          }
        }}
      >
        {intl.formatMessage(message)}
      </Link>
    </Stack>
  )
}

const FertilityMedsStack = () => (
  <StackItem
    id="save-on-fertility-meds-link"
    onClick={() => handleOnClick("#save-on-fertility-meds")}
    message={defineMessage({ defaultMessage: "Save 40% on fertility meds" })}
  />
)

const DeliveryStack = () => (
  <StackItem
    id="fast-and-free-delivery-link"
    onClick={() => handleOnClick("#fast-and-free-delivery")}
    message={defineMessage({ defaultMessage: "Fast & free delivery" })}
  />
)

const ExceptionalCareStack = () => (
  <StackItem
    id="exceptional-care-link"
    onClick={() => handleOnClick("#exceptional-care")}
    message={defineMessage({ defaultMessage: "24/7 support" })}
  />
)

const StartSavingStack = () => (
  <StackItem
    id="start-saving-with-carrot-rxlink"
    onClick={() => handleOnClick("#start-saving-with-carrot-rx")}
    message={defineMessage({ defaultMessage: "Start saving with Carrot Rx" })}
  />
)

export function NavigationTab({ isInAppLockdown }: { isInAppLockdown: boolean }) {
  const { isMobileView, isAboveXLScreenView } = useDigitalFrontDoorBreakpointViews()

  return (
    <Box
      marginX={isAboveXLScreenView && "7.5rem"}
      marginBottom={isAboveXLScreenView && ((theme) => theme.tokens.spacing.sm)}
      sx={{ backgroundColor: "#F1EFEB", transform: isAboveXLScreenView && `translateY(-50%)` }}
      borderRadius={isAboveXLScreenView && "32px"}
    >
      {isAboveXLScreenView ? (
        <Grid container padding={(theme) => theme.tokens.spacing.lg} alignItems={"center"}>
          <Grid
            item
            xs
            paddingRight={(theme) => theme.spacing(theme.tokens.spacing.md)}
            paddingY={(theme) => theme.tokens.spacing.lg}
          >
            <FertilityMedsStack />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid
            item
            xs
            paddingX={(theme) => theme.spacing(theme.tokens.spacing.md)}
            paddingY={(theme) => theme.tokens.spacing.lg}
          >
            <DeliveryStack />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid
            item
            xs
            paddingX={(theme) => theme.spacing(theme.tokens.spacing.md)}
            paddingY={(theme) => theme.tokens.spacing.lg}
          >
            <ExceptionalCareStack />
          </Grid>
          {!isInAppLockdown && (
            <>
              <Divider orientation="vertical" flexItem />
              <Grid
                item
                xs
                paddingLeft={(theme) => theme.spacing(theme.tokens.spacing.md)}
                paddingY={(theme) => theme.tokens.spacing.lg}
              >
                <StartSavingStack />
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        <Stack
          gap={(theme) => theme.tokens.spacing.md}
          paddingY={(theme) => theme.tokens.spacing.xl}
          paddingX={isMobileView ? (theme) => theme.tokens.spacing.lg : "7.5rem"}
        >
          <Typography variant="h4" fontSize={"1.25"}>
            <FormattedMessage defaultMessage="Benefits" />
          </Typography>
          <FertilityMedsStack />
          <DeliveryStack />
          <ExceptionalCareStack />
          {!isInAppLockdown && <StartSavingStack />}
        </Stack>
      )}
    </Box>
  )
}
