import React, { useContext } from "react"

const ConfigContext = React.createContext(null)
export const useConfigContext = () => useContext(ConfigContext)

type ConfigContextProviderProps = {
  children: React.ReactNode
  config: object
}

export function ConfigContextProvider({ children, config }: ConfigContextProviderProps): JSX.Element {
  return (
    <ConfigContext.Provider
      value={{
        config
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}
