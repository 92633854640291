import React from "react"
import Dropzone from "react-dropzone"
import { Text } from "@carrotfertility/carotene"
import { Button } from "@carrotfertility/carotene-core"
import { FlexRowCenter, Spacer } from "components/views/atoms/Atoms"
import { BodyLarge, BodySmall } from "components/views/atoms/Bodies"
import { CircleImage } from "components/views/atoms/images/CircleImage"
import { AcceptableFileExtensions } from "components/views/reimbursements/upload/AcceptableFileExtensions"
import { FormattedMessage } from "react-intl"
import Settings from "../../../../utils/CarrotConfig"

const CloudImage = ({ dragging, small }) => (
  <CircleImage
    size={small ? 54 : 96}
    padding={small ? 12 : 18}
    className="animate-all-slow"
    backgroundColor={dragging ? "coral" : "cornflower-blue"}
    iconName="icn-cloud-upload"
  />
)

const FileSizeLimitLabel = () => {
  const maxFileSize = Settings.MAX_FILE_SIZE / 1000000
  return (
    <div style={{ maxWidth: 309 }}>
      <Spacer height={0.25} />
      <BodySmall color="black-60">
        <FormattedMessage
          defaultMessage="Upload jpg, jpeg, png, pdf, HEIC, doc, docx, txt, xlsx, and zip files. The maximum file size we accept is {maxFileSize} MB."
          values={{ maxFileSize }}
        />
      </BodySmall>
    </div>
  )
}

const DropzoneIllustration = ({ isDragActive, small }) => (
  <div className="flex flex-column justify-center items-center h-100">
    <FlexRowCenter>
      <CloudImage dragging={isDragActive} small={small} />
    </FlexRowCenter>
    <Spacer height={1} />
    <BodyLarge>
      <span className="dn db-ns">
        {isDragActive ? (
          <FormattedMessage defaultMessage="Drop files to upload" />
        ) : (
          <FormattedMessage defaultMessage="Drag files here or click to browse" />
        )}
      </span>
      <span className="dn-ns db">
        <FormattedMessage defaultMessage="Tap to browse files" />
      </span>
    </BodyLarge>
    {!isDragActive ? <FileSizeLimitLabel /> : <Spacer height={1.3} />}
  </div>
)

const NewDropzoneIllustration = ({ isDragActive }) => (
  <div className="flex flex-column justify-center items-center h-100">
    <Spacer height={1} />
    {isDragActive ? <Spacer height={2} /> : null}
    <div className="dn db-ns">
      <BodyLarge>
        {isDragActive ? (
          <FormattedMessage defaultMessage="Drop files to upload" />
        ) : (
          <FormattedMessage defaultMessage="Drag and drop files here" />
        )}
      </BodyLarge>
    </div>

    {isDragActive ? (
      <Spacer height={3} />
    ) : (
      <>
        <div className="dn db-ns">
          <Spacer height={1} />
          <BodyLarge>
            <Text variant="disabled">
              <FormattedMessage defaultMessage="or" />
            </Text>
          </BodyLarge>
          <Spacer height={1} />
        </div>
        <Button variant="outlined" size="medium" color="secondary">
          <FormattedMessage defaultMessage="Add files" />
        </Button>
      </>
    )}
  </div>
)

const UploadDropzoneWithIllustration = ({ small, onDropAccepted, onDropRejected, showNew = false }) => {
  return (
    <Dropzone
      multiple
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      accept={AcceptableFileExtensions}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          className={`
          bg-light-gray w-100 br3 pointer no-outline tc lh-solid ph3 animate-all-slow ${small ? "pv35" : "pv5"} ${
            isDragActive ? "ba bw1 b--carrot-orange" : "ba bw1 b--moon-gray"
          }`}
          {...getRootProps()}
        >
          {isDragActive ? <div className="fullscreen-dropzone-overlay" /> : ""}

          <input id="upload-files-input" data-testid="upload-files-input-test" {...getInputProps()} />

          {showNew ? (
            <NewDropzoneIllustration isDragActive={isDragActive} />
          ) : (
            <DropzoneIllustration isDragActive={isDragActive} small={small} />
          )}
        </div>
      )}
    </Dropzone>
  )
}

export { UploadDropzoneWithIllustration }
