import React from "react"
import { Box, Container, Link, Paper, Stack, useTheme } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"

type AppAccessPageCardProps = {
  shake?: boolean
  id?: string
}

export function AppAccessPageCard({
  shake,
  children,
  id
}: React.PropsWithChildren<AppAccessPageCardProps>): JSX.Element {
  return (
    <Container component="main" id={id} className={shake ? "shake" : ""}>
      <Paper
        sx={(theme) => ({
          borderRadius: theme.tokens.borderRadius.md,
          padding: theme.spacing(theme.tokens.spacing.xl, theme.tokens.spacing.xxxl),
          [theme.breakpoints.down("md")]: {
            padding: theme.spacing(theme.tokens.spacing.lg)
          }
        })}
      >
        <Box display="flex" justifyContent="center" padding={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
          <Box
            component="img"
            src={`/images/CarrotGrey.svg`}
            alt=""
            height={(theme) => theme.spacing(theme.tokens.spacing.md)}
          />
        </Box>
        {children}
      </Paper>
    </Container>
  )
}

type AppAccessPageCardFooterLink = {
  id: string
  to: string
  linkText: string
  external?: boolean
}
interface AppAccessPageCardFooterProps {
  links: AppAccessPageCardFooterLink[]
}

export function AppAccessPageCardFooter(props: AppAccessPageCardFooterProps): JSX.Element {
  const theme = useTheme()
  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)} alignItems="center">
      {props.links.map((linkProps) =>
        linkProps.external ? (
          <Link key={linkProps.id} id={linkProps.id} href={linkProps?.to}>
            {linkProps?.linkText}
          </Link>
        ) : (
          <Link key={linkProps.id} component={RouterLink} id={linkProps.id} to={linkProps?.to}>
            {linkProps?.linkText}
          </Link>
        )
      )}
    </Stack>
  )
}
