import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme
} from "@carrotfertility/carotene-core"
import { IlloCalendar } from "components/views/atoms/Illustrations"
import Locations from "components/views/home/navigation"
import React from "react"
import { FormattedMessage, MessageDescriptor } from "react-intl"
import { useHistory } from "react-router-dom"
import EmployeeOnlyModalContainer from "../../../employee-only-containers/EmployeeOnlyModalContainer"

type EnrollmentExpiredReminderProps = {
  onExit: () => void
  title: MessageDescriptor
}

const EnrollmentExpiredReminder = (props: EnrollmentExpiredReminderProps): JSX.Element => {
  const history = useHistory()
  const onClickUpdate = (): void => history.push(Locations.Home.EnrollmentFlowActive)
  const theme = useTheme()

  return (
    <EmployeeOnlyModalContainer onClose={props.onExit}>
      <Dialog open={true} onClose={props.onExit} aria-labelledby="step-heading">
        <Box justifyContent="center" display="flex" paddingY={theme.spacing(theme.tokens.spacing.lg)}>
          <IlloCalendar height={228} width={228} />
        </Box>
        <DialogTitle id="step-heading">
          <FormattedMessage {...props.title} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage defaultMessage="Update now to access your Carrot funds." />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ textWrap: "no-wrap" }} color="secondary" variant="outlined" onClick={props.onExit}>
            <FormattedMessage defaultMessage="I'll do this later" />
          </Button>
          <Button sx={{ textWrap: "no-wrap" }} color="primary" onClick={onClickUpdate}>
            <FormattedMessage defaultMessage="Update now" />
          </Button>
        </DialogActions>
      </Dialog>
    </EmployeeOnlyModalContainer>
  )
}

export default EnrollmentExpiredReminder
