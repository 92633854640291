import React from "react"
import { Box, useMediaQuery, useTheme } from "@carrotfertility/carotene-core"

export const LogoHeaderBar = (): JSX.Element => {
  const theme = useTheme()
  const showDesktopHeader = useMediaQuery(theme.breakpoints.up("lg"))
  const desktopHeader = (
    <Box height="156px">
      <Box padding={(theme) => theme.spacing(theme.tokens.spacing.lg, theme.tokens.spacing.xxxl)}>
        <Box
          component="img"
          src={`/images/CarrotGrey.svg`}
          alt=""
          height={(theme) => theme.spacing(theme.tokens.spacing.md)}
        />
      </Box>
    </Box>
  )
  const mobileHeader = (
    <Box
      display="flex"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default
      })}
      justifyContent="center"
      padding={(theme) => theme.spacing(theme.tokens.spacing.xl)}
    >
      <Box
        component="img"
        src={`/images/CarrotGrey.svg`}
        alt=""
        height={(theme) => theme.spacing(theme.tokens.spacing.md)}
      />
    </Box>
  )
  return showDesktopHeader ? desktopHeader : mobileHeader
}
