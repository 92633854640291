import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import useAboutYou from "components/carrot-plans/hooks/useAboutYou"
import { FormattedMessage, useIntl } from "react-intl"
import { Box } from "@carrotfertility/carotene-core"
import { ButtonFooterLayout, ContinueButton, RoutingFlowTitle, StepLayout } from "components/carrot-plans/shared"
import { FormRadioButtonGroup } from "services/common-forms"

function calculateIfMemberHasPartner(value: string | null | undefined, isSkipped: boolean): "YES" | "NO" | null {
  if (typeof value === "undefined" || value === null || !value.trim()) {
    return isSkipped ? "NO" : null
  }
  return isSkipped ? "NO" : "YES"
}

export default function AboutYourPartnerIntroStep(): JSX.Element {
  const intl = useIntl()
  const { data, hasQuestionBeenSkipped, updateAboutYou } = useAboutYou()
  const defaultValue = calculateIfMemberHasPartner(data?.partnerFirstName, hasQuestionBeenSkipped("partnerSection"))
  const { send } = useStateMachine(defaultValue)

  async function onSubmit({ partnerSelection }: { partnerSelection?: string }): Promise<void> {
    if (partnerSelection === "NO") {
      await updateAboutYou({
        partnerFirstName: "nullOut",
        partnerLastName: "nullOut",
        partnerEmail: "nullOut",
        partnerDateOfBirth: "nullOut",
        partnerGenderIdentityOther: "nullOut",
        partnerPreferredName: "nullOut",
        partnerSex: "nullOut",
        partnerGenderIdentity: "nullOut",
        partnerSection: "skip"
      })
    }

    send(partnerSelection)
  }
  const header = intl.formatMessage({
    defaultMessage: "Are you on your journey with a partner?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <Box display="flex" justifyContent="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
        <Box
          component="img"
          src={`/images/illo-partners.png`}
          srcSet={`/images/illo-partners-2x.png`}
          alt=""
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
          height={228}
          width={228}
        />
      </Box>
      <StepLayout
        header={<span id="partnerSelection-label">{header}</span>}
        description={intl.formatMessage({
          defaultMessage: "Providing details about your partner or spouse helps us better support both of you."
        })}
        formElements={
          <Form defaultValues={{ partnerSelection: defaultValue }} onSubmit={onSubmit}>
            <FormRadioButtonGroup
              aria-labelledby="partnerSelection-label"
              controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
              name="partnerSelection"
              options={[
                { label: intl.formatMessage({ defaultMessage: "Yes" }), value: "YES" },
                { label: intl.formatMessage({ defaultMessage: "No" }), value: "NO" }
              ]}
            />

            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
