import { FlexContainer } from "@carrotfertility/carotene"
import React from "react"
import { useIntl } from "react-intl"
import { Typography, useTheme } from "@carrotfertility/carotene-core"

function ClosedThreadMessageBubble({ text }: { text: string }): JSX.Element {
  const theme = useTheme()
  return (
    <FlexContainer direction="row" alignItems="start" padding="43px 44px 35px 44px" gap="23px">
      <Typography variant="body2" color={theme.palette.text.disabled}>
        {text}
      </Typography>
    </FlexContainer>
  )
}

export function ClosedThreadMessage(): JSX.Element {
  const intl = useIntl()
  const text = intl.formatMessage({
    defaultMessage:
      "We've marked this conversation as resolved. If you have additional questions, you can start a follow-up conversation."
  })
  return <ClosedThreadMessageBubble text={text} />
}
