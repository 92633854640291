import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "components/carrot-plans/shared/useQueryRoutingInfo"

export default function WhatKindOfJourneyPreservationDecisionStep(): JSX.Element {
  const routingData = useCheckRoutingInfoQueryCache()
  let journeyValue: string

  switch (routingData?.journey) {
    case "PRESERVATION":
      journeyValue = "PRESERVATION"
      break
    case "ASSISTED_REPRODUCTION":
      journeyValue = "ASSISTED_REPRODUCTION"
      break
    case "TRY_PREGNANT":
      journeyValue = "TRY_PREGNANT"
      break
    case "PREGNANT":
      journeyValue = "PREGNANT"
      break
    case "EXPLORING":
      journeyValue = "EXPLORING"
      break
    case "MENOPAUSE":
      journeyValue = "MENOPAUSE"
      break
    case "LOW_TESTOSTERONE":
      journeyValue = "LOW_TESTOSTERONE"
      break
    default:
      journeyValue = "OTHER_ANSWERS"
  }

  const { send } = useStateMachine()
  useEffect(() => {
    send(journeyValue)
  })
  return <></>
}
