import Settings from "../lib/Settings"
import { useMemo } from "react"
import { CarrotPartnerBookingApiClient } from "../lib/CarrotPartnerBookingApiClient"
import { useAuthentication } from "../../../components/context/authentication/AuthenticationProvider"

export const useCarrotPartnerBookingApiClient = (): CarrotPartnerBookingApiClient => {
  const { handleSessionExpiration } = useAuthentication()

  if (!handleSessionExpiration) {
    throw new Error("Missing handleSessionExpiration")
  }

  return useMemo(() => {
    return new CarrotPartnerBookingApiClient(Settings.CARROT_BACKEND_URL, handleSessionExpiration)
  }, [handleSessionExpiration])
}
