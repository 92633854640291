import { useStateMachine } from "components/context/stateMachine/StateMachineContext"
import {
  BenefitEnrollmentFlowModalBackButton,
  BenefitEnrollmentModalFormSelect,
  BenefitEnrollmentModalContinueButton
} from "../shared"
import useSubmitWithErrorCatch from "../hooks/useSubmitWithErrorCatch"
import { Steps } from "../workflow/steps"
import { useProgressTracker } from "components/views/modal/ProgressTracker"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { getPartnerPlanSourceSelectValue } from "reducers/userInfo"
import { useUserDeductible } from "../../context/user/DeductibleContext"
import { defineMessage, useIntl, MessageDescriptor, FormattedMessage } from "react-intl"
import { Box, Typography } from "@carrotfertility/carotene-core"

const FieldName = "partnerPlanSourceSelectValue"

type PlanSourceOption = {
  label: string
  value: string
}
interface PartnerPlanSourceStepProps {
  planSourceOptions: PlanSourceOption[]
  viewName: typeof Steps[keyof typeof Steps]
}

export const enum PartnerPlanSourceOptions {
  EMPLOYER = "EMPLOYER",
  MEMBER = "MEMBER",
  PARENT = "PARENT",
  OTHER = "OTHER",
  NOT_INSURED = "NOT_INSURED",
  IM_NOT_SURE = "IM_NOT_SURE"
}

const partnerPlanSourceIdToLabel = {
  [PartnerPlanSourceOptions.MEMBER]: defineMessage({ defaultMessage: "From my employer" }),
  [PartnerPlanSourceOptions.EMPLOYER]: defineMessage({ defaultMessage: "From their employer" }),
  [PartnerPlanSourceOptions.PARENT]: defineMessage({ defaultMessage: "From their parent’s employer" }),
  [PartnerPlanSourceOptions.OTHER]: defineMessage({ defaultMessage: "From somewhere else" }),
  [PartnerPlanSourceOptions.NOT_INSURED]: defineMessage({ defaultMessage: "They’re not insured" }),
  [PartnerPlanSourceOptions.IM_NOT_SURE]: defineMessage({ defaultMessage: "I'm not sure" })
}

export const getPartnerPlanSourceLabelFromId = (id: PartnerPlanSourceOptions): MessageDescriptor =>
  partnerPlanSourceIdToLabel[id]

// @ts-expect-error TS7006
function PartnerPlanSourceACAStep(props): JSX.Element {
  const intl = useIntl()
  const planSourceOptions = [
    {
      label: intl.formatMessage(getPartnerPlanSourceLabelFromId(PartnerPlanSourceOptions.MEMBER)),
      value: PartnerPlanSourceOptions.MEMBER
    },
    {
      label: intl.formatMessage(getPartnerPlanSourceLabelFromId(PartnerPlanSourceOptions.EMPLOYER)),
      value: PartnerPlanSourceOptions.EMPLOYER
    },
    {
      label: intl.formatMessage(getPartnerPlanSourceLabelFromId(PartnerPlanSourceOptions.PARENT)),
      value: PartnerPlanSourceOptions.PARENT
    },
    {
      label: intl.formatMessage(getPartnerPlanSourceLabelFromId(PartnerPlanSourceOptions.OTHER)),
      value: PartnerPlanSourceOptions.OTHER
    },
    {
      label: intl.formatMessage(getPartnerPlanSourceLabelFromId(PartnerPlanSourceOptions.NOT_INSURED)),
      value: PartnerPlanSourceOptions.NOT_INSURED
    },
    {
      label: intl.formatMessage(getPartnerPlanSourceLabelFromId(PartnerPlanSourceOptions.IM_NOT_SURE)),
      value: PartnerPlanSourceOptions.IM_NOT_SURE
    }
  ]
  return <PartnerPlanSource {...{ ...props, planSourceOptions }} viewName={Steps.PARTNER_PLAN_SOURCE_ACA} />
}

// @ts-expect-error TS7006
function PartnerPlanSourceStep(props): JSX.Element {
  const intl = useIntl()
  const planSourceOptions = [
    {
      label: intl.formatMessage(getPartnerPlanSourceLabelFromId(PartnerPlanSourceOptions.EMPLOYER)),
      value: PartnerPlanSourceOptions.EMPLOYER
    },
    {
      label: intl.formatMessage(getPartnerPlanSourceLabelFromId(PartnerPlanSourceOptions.PARENT)),
      value: PartnerPlanSourceOptions.PARENT
    },
    {
      label: intl.formatMessage(getPartnerPlanSourceLabelFromId(PartnerPlanSourceOptions.OTHER)),
      value: PartnerPlanSourceOptions.OTHER
    },
    {
      label: intl.formatMessage(getPartnerPlanSourceLabelFromId(PartnerPlanSourceOptions.NOT_INSURED)),
      value: PartnerPlanSourceOptions.NOT_INSURED
    },
    {
      label: intl.formatMessage(getPartnerPlanSourceLabelFromId(PartnerPlanSourceOptions.IM_NOT_SURE)),
      value: PartnerPlanSourceOptions.IM_NOT_SURE
    }
  ]
  return <PartnerPlanSource {...{ ...props, planSourceOptions }} viewName={Steps.PARTNER_PLAN_SOURCE} />
}

function PartnerPlanSource({ planSourceOptions, viewName }: PartnerPlanSourceStepProps): JSX.Element {
  const intl = useIntl()
  useProgressTracker(1, intl.formatMessage({ defaultMessage: "YOUR INSURANCE COVERAGE" }))
  let partnerPlanSourceSelectValue = useSelector(getPartnerPlanSourceSelectValue)
  if (!planSourceOptions.map(({ value }) => value).includes(partnerPlanSourceSelectValue)) {
    partnerPlanSourceSelectValue = null
  }
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()

  const { setNextStep } = useStateMachine(viewName, partnerPlanSourceSelectValue)
  const formMethods = useForm()
  // @ts-expect-error TS7006
  const { onSubmit } = useSubmitWithErrorCatch(async (args) => {
    const val = args[FieldName].value
    val !== "IM_NOT_SURE" && (await updateBenefitEnrollmentAnswers({ partnerPlanSourceSelectValue: val }))
    setNextStep(viewName, val)
  })

  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}>
        <BenefitEnrollmentFlowModalBackButton />
      </Box>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xl}
      >
        <FormattedMessage defaultMessage="Where does your partner get their primary health insurance?" />
      </Typography>
      <BenefitEnrollmentModalFormSelect
        name={FieldName}
        aria-labelledby="step-heading"
        defaultValue={partnerPlanSourceSelectValue}
        options={planSourceOptions}
      />
      <Box height="24rem" />
      <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
    </FormProvider>
  )
}

export { PartnerPlanSourceStep, PartnerPlanSourceACAStep }
