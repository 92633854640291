import { Steps } from "../../steps"

// prettier-ignore
export default [
  {forStep: Steps.PROCESS_ENROLLMENT_TYPE,                            memberSelects: '',                             goto: Steps.FIRST_TIME_OR_RE_ENROLL},
  {forStep: Steps.FIRST_TIME_OR_RE_ENROLL,                       memberSelects: Steps.RE_ENROLL_INTRO_INFORMATIONAL, goto: Steps.RE_ENROLL_INTRO_INFORMATIONAL},
  {forStep: Steps.FIRST_TIME_OR_RE_ENROLL,                       memberSelects: Steps.INTRO_INFORMATIONAL ,     goto: Steps.INTRO_INFORMATIONAL},
  {forStep: Steps.RE_ENROLL_INTRO_INFORMATIONAL,                  memberSelects: '',                             goto: Steps.INSURANCE_DISCLAIMER},
  {forStep: Steps.INTRO_INFORMATIONAL,                            memberSelects: '',                             goto: Steps.INSURANCE_DISCLAIMER},
  {forStep: Steps.INTRO_INFORMATIONAL,                            memberSelects: 'SKIP_THIS_FLOW',               goto: Steps.CONNECT_INSURANCE_FLOW},
  {forStep: Steps.INSURANCE_DISCLAIMER,                           memberSelects: '',                             goto: Steps.ARE_YOU_ENROLLED_IN_MEDICARE },
  {forStep: Steps.INSURANCE_DISCLAIMER,                           memberSelects: 'SKIP_MEDICARE',                goto: Steps.MEMBER_PLAN_SOURCE },

]
