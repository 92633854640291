import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Stack, useTheme, Button, Box, Typography } from "@carrotfertility/carotene-core"
import {
  emailVerificationHeaderText,
  EmailVerificationConfirmationSentLink,
  EmailVerificationContactUs,
  emailVerificationFindEmailText,
  emailVerificationExpirationText
} from "./VerificationSentModal"
import { FormattedMessage, useIntl } from "react-intl"

export default function EmailVerificationSent({ personalEmail }: { personalEmail: string }): JSX.Element {
  const theme = useTheme()
  const intl = useIntl()
  return (
    <>
      <Box textAlign="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
        <Typography variant="h1">{intl.formatMessage(emailVerificationHeaderText)}</Typography>
      </Box>

      <Stack textAlign="center" spacing={theme.spacing(theme.tokens.spacing.lg)}>
        <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
          <EmailVerificationConfirmationSentLink personalEmail={personalEmail} component={Typography} />
          <Box>
            <Typography>{intl.formatMessage(emailVerificationExpirationText)}</Typography>
            <Typography>{intl.formatMessage(emailVerificationFindEmailText)}</Typography>
          </Box>
          <EmailVerificationContactUs component={Typography} />
        </Stack>
        <Box textAlign="center">
          <Button to="/" id="continue" component={RouterLink}>
            <FormattedMessage defaultMessage="Continue" />
          </Button>
        </Box>
      </Stack>
    </>
  )
}
