import React from "react"
import {
  DialogProps,
  Dialog,
  Box,
  IconButton,
  CloseIcon,
  DialogContent,
  useMediaQuery,
  useTheme,
  DialogTitle
} from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"

interface ContentfulDialogProps extends Pick<DialogProps, "open" | "children"> {
  title: React.ReactNode
  onClose: () => void
}

export function ContentfulDialog({ title, children, ...otherProps }: ContentfulDialogProps) {
  const intl = useIntl()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Dialog fullScreen={fullScreen} aria-labelledby="contentful-modal-title" {...otherProps}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={otherProps?.onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle id="contentful-modal-title">{title}</DialogTitle>
      <DialogContent sx={{ gap: 0 }}>{children}</DialogContent>
    </Dialog>
  )
}
