import React, { useEffect, useState } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import Settings from "../../utils/CarrotConfig"
import InboundMemberPaymentsStatusMessage from "./components/InboundMemberPaymentsStatusMessage"
import { Redirect, useLocation, useHistory } from "react-router"

const InboundMemberPaymentsStatusPage = () => {
  const stripePromise = loadStripe(Settings.STRIPE_PUBLISHABLE_KEY)
  const location = useLocation()
  const history = useHistory()
  const [locationState, setLocationState] = useState({})
  const paymentIntentClientSecret =
    // @ts-expect-error TS7053
    location?.state !== undefined ? location?.state["paymentIntentClientSecret"] : undefined

  useEffect(() => {
    setLocationState(location?.state)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  useEffect(() => {
    if (history.action === "POP") {
      // @ts-expect-error TS7053
      location.state["paymentIntentClientSecret"] = undefined
      // @ts-expect-error TS7053
      location.state["paymentIntentAmount"] = undefined
      // @ts-expect-error TS7053
      location.state["paymentIntentCurrencyCode"] = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [locationState])

  return paymentIntentClientSecret ? (
    <Elements stripe={stripePromise}>
      <InboundMemberPaymentsStatusMessage paymentIntentClientSecret={paymentIntentClientSecret} />
    </Elements>
  ) : (
    <Redirect to="/make-a-payment" />
  )
}

export default InboundMemberPaymentsStatusPage
