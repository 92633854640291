import { Steps } from "./steps"

export enum Workflow {
  UPLOAD_ADDITIONAL_DOCUMENTS = "UploadAdditionalDocuments"
}

const uploadAdditionalDocumentsWorkflow = [
  { at: Steps.UPLOAD_ADDITIONAL_FILES, given: "", goto: Steps.ANYTHING_ELSE_TO_SHARE },
  { at: Steps.ANYTHING_ELSE_TO_SHARE, given: "", goto: Steps.UPLOAD_ADDITIONAL_FILES_SUCCESS }
]

export const uploadAdditionalDocumentsWorkflows = [
  { name: Workflow.UPLOAD_ADDITIONAL_DOCUMENTS, workflow: uploadAdditionalDocumentsWorkflow }
]
