// @ts-expect-error TS7006
export const createIsLoadedReducer = (actionTypeRoot) => {
  // @ts-expect-error TS7006
  return (state = false, action) => {
    switch (action.type) {
      case `${actionTypeRoot}_SUCCESS`:
        return true
      case `${actionTypeRoot}_FAILURE`:
        return false
      default:
        return state
    }
  }
}

// @ts-expect-error TS7006
export function createReducer<TReturn>(initialState, handlers) {
  // @ts-expect-error TS7006
  return (state = initialState, action): TReturn | null => {
    // eslint-disable-next-line no-prototype-builtins
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}
