import React from "react"
import { colors } from "@carrotfertility/carotene"
import { Toaster } from "react-hot-toast"

export function CarrotToaster(): JSX.Element {
  return (
    <Toaster
      position="top-center"
      toastOptions={{
        success: {
          iconTheme: {
            primary: `${colors.base.darkGreen}`,
            secondary: "#fff"
          },
          style: {
            background: "#DDEBE2",
            padding: "24px 12px",
            fontFamily: "Gotham, Noto Sans, Noto Sans Thai Looped, sans-serif",
            fontSize: "14px"
          }
        },
        error: {
          iconTheme: {
            primary: `${colors.base.errorRed}`,
            secondary: "#fff"
          },
          style: {
            background: `${colors.base.lightRed}`,
            padding: "24px 12px",
            fontFamily: "Gotham, Noto Sans, Noto Sans Thai Looped, sans-serif",
            fontSize: "14px"
          }
        },
        loading: {
          style: {
            padding: "1.5rem 0.25rem 1.5rem 1.5rem",
            fontFamily: "Gotham, Noto Sans, Noto Sans Thai Looped, sans-serif",
            fontSize: "14px"
          }
        }
      }}
    />
  )
}
