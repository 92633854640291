import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { usePartnerRegistrationFlow } from "../PartnerRegistrationFlow"
import { EmailOptIn } from "../../../views/register/EmailOptIn"
import { usePartnerRegFlowComplete } from "../../hooks/usePartnerAccess"

export function EmailOptInStep(): JSX.Element {
  const { send } = useStateMachine(null)
  const { emailOptIn, setEmailOptIn } = usePartnerRegistrationFlow()
  const { isLoading: isSendCompletePartnerRegistrationLoading, mutate: sendCompletePartnerRegistration } =
    usePartnerRegFlowComplete(emailOptIn)

  return (
    <EmailOptIn
      isSubmitting={isSendCompletePartnerRegistrationLoading}
      emailOptIn={emailOptIn}
      setEmailOptIn={setEmailOptIn}
      onBack={() => send("BACK")}
      onComplete={() => sendCompletePartnerRegistration(null)}
    />
  )
}
