import { defineMessage } from "react-intl"

export const contentTypeMapping = {
  groupSession: {
    name: defineMessage({ defaultMessage: "Group Session" })
  },
  guide: {
    name: defineMessage({ defaultMessage: "Guide" })
  },
  article: {
    name: defineMessage({ defaultMessage: "Article" })
  },
  video: {
    name: defineMessage({ defaultMessage: "Video" })
  }
}
