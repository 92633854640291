export enum Journey {
  ADOPTION = "ADOPTION",
  GESTATIONAL = "GESTATIONAL",
  PRESERVATION = "PRESERVATION",
  TRY_PREGNANT = "TRY_PREGNANT",
  ASSISTED_REPRODUCTION = "ASSISTED_REPRODUCTION",
  PREGNANT = "PREGNANT",
  PARENTING = "PARENTING",
  MENOPAUSE = "MENOPAUSE",
  LOW_TESTOSTERONE = "LOW_TESTOSTERONE",
  GENDER_AFFIRMING_CARE = "GENDER_AFFIRMING_CARE",
  EXPLORING = "EXPLORING",
  SOMETHING_ELSE = "SOMETHING_ELSE"
}

export enum ContentfulOrderTag {
  ADOPTION = "ADOPTION",
  GESTATIONAL = "GESTATIONAL",
  PRESERVATION = "PRESERVATION",
  TRY_PREGNANT = "TRY_PREGNANT",
  ASSISTED_REPRODUCTION = "ASSISTED_REPRODUCTION",
  PREGNANT = "PREGNANT",
  PARENTING = "PARENTING",
  MENOPAUSE = "MENOPAUSE",
  LOW_TESTOSTERONE = "LOW_TESTOSTERONE",
  GENDER_AFFIRMING_CARE = "GENDER_AFFIRMING_CARE",
  FERTILITY = "FERTILITY",
  UROLOGY = "UROLOGY",
  EMOTIONAL_SUPPORT = "EMOTIONAL_SUPPORT",
  LGBTQ_FAMILY_FORMING = "LGBTQ_FAMILY_FORMING",
  NUTRITION = "NUTRITION"
}

export type ReadAndLearnTag = Exclude<
  ContentfulOrderTag,
  | ContentfulOrderTag.FERTILITY
  | ContentfulOrderTag.UROLOGY
  | ContentfulOrderTag.EMOTIONAL_SUPPORT
  | ContentfulOrderTag.LGBTQ_FAMILY_FORMING
  | ContentfulOrderTag.NUTRITION
>
export type TalkToCarrotTag = Exclude<
  ContentfulOrderTag,
  ContentfulOrderTag.PRESERVATION | ContentfulOrderTag.ASSISTED_REPRODUCTION | ContentfulOrderTag.TRY_PREGNANT
>

export enum Order {
  READ_AND_LEARN = "readAndLearnOrder",
  TALK_TO_CARROT = "talkToCarrotOrder"
}

interface JourneyToContentOrderMapping {
  value?: string
  readAndLearnOrder: ReadAndLearnTag[]
  talkToCarrotOrder: TalkToCarrotTag[]
}

export const journeyOrderMapping: { [currentJourney: string]: JourneyToContentOrderMapping } = {
  [Journey.ADOPTION]: {
    value: Journey.ADOPTION,
    readAndLearnOrder: [
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.TRY_PREGNANT,
      ContentfulOrderTag.PRESERVATION,
      ContentfulOrderTag.ASSISTED_REPRODUCTION,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ],
    talkToCarrotOrder: [
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.EMOTIONAL_SUPPORT,
      ContentfulOrderTag.LGBTQ_FAMILY_FORMING,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.NUTRITION,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.FERTILITY,
      ContentfulOrderTag.UROLOGY,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ]
  },
  [Journey.GESTATIONAL]: {
    value: Journey.GESTATIONAL,
    readAndLearnOrder: [
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.TRY_PREGNANT,
      ContentfulOrderTag.PRESERVATION,
      ContentfulOrderTag.ASSISTED_REPRODUCTION,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ],
    talkToCarrotOrder: [
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.EMOTIONAL_SUPPORT,
      ContentfulOrderTag.LGBTQ_FAMILY_FORMING,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.NUTRITION,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.FERTILITY,
      ContentfulOrderTag.UROLOGY,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ]
  },
  [Journey.PRESERVATION]: {
    value: Journey.PRESERVATION,
    readAndLearnOrder: [
      ContentfulOrderTag.PRESERVATION,
      ContentfulOrderTag.TRY_PREGNANT,
      ContentfulOrderTag.ASSISTED_REPRODUCTION,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ],
    talkToCarrotOrder: [
      ContentfulOrderTag.FERTILITY,
      ContentfulOrderTag.UROLOGY,
      ContentfulOrderTag.EMOTIONAL_SUPPORT,
      ContentfulOrderTag.NUTRITION,
      ContentfulOrderTag.LGBTQ_FAMILY_FORMING,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ]
  },
  [Journey.ASSISTED_REPRODUCTION]: {
    value: Journey.ASSISTED_REPRODUCTION,
    readAndLearnOrder: [
      ContentfulOrderTag.ASSISTED_REPRODUCTION,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.TRY_PREGNANT,
      ContentfulOrderTag.PRESERVATION,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ],
    talkToCarrotOrder: [
      ContentfulOrderTag.FERTILITY,
      ContentfulOrderTag.UROLOGY,
      ContentfulOrderTag.EMOTIONAL_SUPPORT,
      ContentfulOrderTag.NUTRITION,
      ContentfulOrderTag.LGBTQ_FAMILY_FORMING,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ]
  },
  [Journey.TRY_PREGNANT]: {
    value: Journey.TRY_PREGNANT,
    readAndLearnOrder: [
      ContentfulOrderTag.TRY_PREGNANT,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.ASSISTED_REPRODUCTION,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.PRESERVATION,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ],
    talkToCarrotOrder: [
      ContentfulOrderTag.FERTILITY,
      ContentfulOrderTag.UROLOGY,
      ContentfulOrderTag.EMOTIONAL_SUPPORT,
      ContentfulOrderTag.NUTRITION,
      ContentfulOrderTag.LGBTQ_FAMILY_FORMING,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ]
  },
  [Journey.PREGNANT]: {
    value: Journey.PREGNANT,
    readAndLearnOrder: [
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.TRY_PREGNANT,
      ContentfulOrderTag.ASSISTED_REPRODUCTION,
      ContentfulOrderTag.PRESERVATION,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ],
    talkToCarrotOrder: [
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.EMOTIONAL_SUPPORT,
      ContentfulOrderTag.NUTRITION,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.LGBTQ_FAMILY_FORMING,
      ContentfulOrderTag.FERTILITY,
      ContentfulOrderTag.UROLOGY,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ]
  },
  [Journey.EXPLORING]: {
    readAndLearnOrder: [
      ContentfulOrderTag.TRY_PREGNANT,
      ContentfulOrderTag.PRESERVATION,
      ContentfulOrderTag.ASSISTED_REPRODUCTION,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ],
    talkToCarrotOrder: [
      ContentfulOrderTag.EMOTIONAL_SUPPORT,
      ContentfulOrderTag.FERTILITY,
      ContentfulOrderTag.UROLOGY,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.LGBTQ_FAMILY_FORMING,
      ContentfulOrderTag.NUTRITION,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ]
  },
  [Journey.MENOPAUSE]: {
    value: Journey.MENOPAUSE,
    readAndLearnOrder: [
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.TRY_PREGNANT,
      ContentfulOrderTag.ASSISTED_REPRODUCTION,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.PRESERVATION,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE,
      ContentfulOrderTag.LOW_TESTOSTERONE
    ],
    talkToCarrotOrder: [
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.EMOTIONAL_SUPPORT,
      ContentfulOrderTag.NUTRITION,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.FERTILITY,
      ContentfulOrderTag.UROLOGY,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.LGBTQ_FAMILY_FORMING,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE,
      ContentfulOrderTag.LOW_TESTOSTERONE
    ]
  },
  [Journey.LOW_TESTOSTERONE]: {
    value: Journey.LOW_TESTOSTERONE,
    readAndLearnOrder: [
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.TRY_PREGNANT,
      ContentfulOrderTag.ASSISTED_REPRODUCTION,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.PRESERVATION,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE,
      ContentfulOrderTag.MENOPAUSE
    ],
    talkToCarrotOrder: [
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.UROLOGY,
      ContentfulOrderTag.EMOTIONAL_SUPPORT,
      ContentfulOrderTag.NUTRITION,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.FERTILITY,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.LGBTQ_FAMILY_FORMING,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE,
      ContentfulOrderTag.MENOPAUSE
    ]
  },
  [Journey.GENDER_AFFIRMING_CARE]: {
    value: Journey.GENDER_AFFIRMING_CARE,
    readAndLearnOrder: [
      ContentfulOrderTag.GENDER_AFFIRMING_CARE,
      ContentfulOrderTag.TRY_PREGNANT,
      ContentfulOrderTag.PRESERVATION,
      ContentfulOrderTag.ASSISTED_REPRODUCTION,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE
    ],
    talkToCarrotOrder: [
      ContentfulOrderTag.GENDER_AFFIRMING_CARE,
      ContentfulOrderTag.EMOTIONAL_SUPPORT,
      ContentfulOrderTag.NUTRITION,
      ContentfulOrderTag.FERTILITY,
      ContentfulOrderTag.UROLOGY,
      ContentfulOrderTag.LGBTQ_FAMILY_FORMING,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE
    ]
  },
  [Journey.SOMETHING_ELSE]: {
    readAndLearnOrder: [
      ContentfulOrderTag.TRY_PREGNANT,
      ContentfulOrderTag.PRESERVATION,
      ContentfulOrderTag.ASSISTED_REPRODUCTION,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ],
    talkToCarrotOrder: [
      ContentfulOrderTag.EMOTIONAL_SUPPORT,
      ContentfulOrderTag.FERTILITY,
      ContentfulOrderTag.UROLOGY,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.LGBTQ_FAMILY_FORMING,
      ContentfulOrderTag.NUTRITION,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ]
  },
  [Journey.PARENTING]: {
    value: Journey.PARENTING,
    readAndLearnOrder: [
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.TRY_PREGNANT,
      ContentfulOrderTag.ASSISTED_REPRODUCTION,
      ContentfulOrderTag.PRESERVATION,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ],
    talkToCarrotOrder: [
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.EMOTIONAL_SUPPORT,
      ContentfulOrderTag.NUTRITION,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.LGBTQ_FAMILY_FORMING,
      ContentfulOrderTag.FERTILITY,
      ContentfulOrderTag.UROLOGY,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ]
  },
  DEFAULT: {
    readAndLearnOrder: [
      ContentfulOrderTag.TRY_PREGNANT,
      ContentfulOrderTag.PRESERVATION,
      ContentfulOrderTag.ASSISTED_REPRODUCTION,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ],
    talkToCarrotOrder: [
      ContentfulOrderTag.EMOTIONAL_SUPPORT,
      ContentfulOrderTag.FERTILITY,
      ContentfulOrderTag.UROLOGY,
      ContentfulOrderTag.ADOPTION,
      ContentfulOrderTag.GESTATIONAL,
      ContentfulOrderTag.LGBTQ_FAMILY_FORMING,
      ContentfulOrderTag.NUTRITION,
      ContentfulOrderTag.PREGNANT,
      ContentfulOrderTag.PARENTING,
      ContentfulOrderTag.MENOPAUSE,
      ContentfulOrderTag.LOW_TESTOSTERONE,
      ContentfulOrderTag.GENDER_AFFIRMING_CARE
    ]
  }
}
