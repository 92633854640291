import { defineMessage } from "react-intl"

export const MaleMedicalHistoryOptions = [
  {
    label: defineMessage({ defaultMessage: "Two or more miscarriages experienced by a partner" }),
    value: "TWO_PLUS_MISCARRIAGES_BY_PARTNER"
  },
  { label: defineMessage({ defaultMessage: "Infection in the pelvis or abdomen" }), value: "INFECTION_PELVIC_ABDOMEN" },
  { label: defineMessage({ defaultMessage: "Sexually transmitted infection (STD/STI)" }), value: "STD_STI" },
  { label: defineMessage({ defaultMessage: "Varicocele" }), value: "VARICOCELE" },
  { label: defineMessage({ defaultMessage: "Mumps" }), value: "MUMPS" },
  { label: defineMessage({ defaultMessage: "Vasectomy" }), value: "VASECTOMY" },
  { label: defineMessage({ defaultMessage: "Vasectomy reversal" }), value: "VASECTOMY_REVERSAL" },
  { label: defineMessage({ defaultMessage: "Hernia repair (inguinal or groin)" }), value: "HERNIA_REPAIR" },
  { label: defineMessage({ defaultMessage: "Testicular surgery" }), value: "TESTICULAR_SURGERY" },
  {
    label: defineMessage({
      defaultMessage: "Erectile dysfunction, ejaculatory dysfunction, or difficulty with intercourse"
    }),
    value: "ERECTILE_DYSFUNCTION"
  }
]

export const FemaleMedicalHistoryOptions = [
  { label: defineMessage({ defaultMessage: "Sexually transmitted infection (STD/STI)" }), value: "STD_STI" },
  { label: defineMessage({ defaultMessage: "Pelvic inflammatory disease (PID)" }), value: "PID" },
  { label: defineMessage({ defaultMessage: "Infection in the pelvis or abdomen" }), value: "INFECTION_PELVIC_ABDOMEN" },
  { label: defineMessage({ defaultMessage: "Endometriosis" }), value: "ENDOMETRIOSIS" },
  { label: defineMessage({ defaultMessage: "Uterine fibroids" }), value: "UTERINE_FIBROIDS" },
  {
    label: defineMessage({ defaultMessage: "Uterine scarring or Asherman syndrome" }),
    value: "UTERINE_SCARRING_ASHERMAN"
  },
  { label: defineMessage({ defaultMessage: "Uterine polyps" }), value: "UTERINE_POLYPS" },
  { label: defineMessage({ defaultMessage: "Polycystic ovary syndrome (PCOS)" }), value: "PCOS" },
  { label: defineMessage({ defaultMessage: "Pain or difficulty with intercourse" }), value: "PAIN_INTERCOURSE" },
  { label: defineMessage({ defaultMessage: "Pelvic or abdominal surgery" }), value: "PELVIC_ABDOMEN_SURGERY" }
]

export const MaleConditionsOptions = [
  { label: defineMessage({ defaultMessage: "Diabetes" }), value: "DIABETES" },
  { label: defineMessage({ defaultMessage: "High blood pressure" }), value: "HIGH_BLOOD_PRESSURE" },
  { label: defineMessage({ defaultMessage: "Autoimmune disease" }), value: "AUTOIMMUNE_DISEASE" },
  { label: defineMessage({ defaultMessage: "Obesity (BMI greater than 30)" }), value: "OBESITY" },
  { label: defineMessage({ defaultMessage: "Thyroid disorder" }), value: "THYROID_DISORDER" },
  {
    label: defineMessage({ defaultMessage: "Blood clotting or bleeding disorder" }),
    value: "BLOOD_CLOTTING_BLEEDING_DISORDER"
  }
]

export const FemaleConditionsOptions = [
  { label: defineMessage({ defaultMessage: "Diabetes" }), value: "DIABETES" },
  { label: defineMessage({ defaultMessage: "High blood pressure" }), value: "HIGH_BLOOD_PRESSURE" },
  {
    label: defineMessage({ defaultMessage: "Autoimmune disease that affects your hormones " }),
    value: "HORMONE_AFFECTING_AUTOIMMUNE_DISEASE"
  },
  { label: defineMessage({ defaultMessage: "Thyroid disorder" }), value: "THYROID_DISORDER" },
  {
    label: defineMessage({ defaultMessage: "Blood clotting or bleeding disorder" }),
    value: "BLOOD_CLOTTING_BLEEDING_DISORDER"
  }
]

export const MaleFamilyConditionsOptions = [
  { label: defineMessage({ defaultMessage: "Recurrent pregnancy loss" }), value: "RECURRENT_PREGNANCY_LOSS" },
  {
    label: defineMessage({ defaultMessage: "Birth defects or genetic disorders" }),
    value: "BIRTH_DEFECTS_GENETIC_DISORDERS"
  }
]

export const FemaleFamilyConditionsOptions = [
  { label: defineMessage({ defaultMessage: "Infertility" }), value: "INFERTILITY" },
  { label: defineMessage({ defaultMessage: "Recurrent pregnancy loss" }), value: "RECURRENT_PREGNANCY_LOSS" },
  { label: defineMessage({ defaultMessage: "Menopause before age 40" }), value: "MENOPAUSE_BEFORE_40" },
  {
    label: defineMessage({ defaultMessage: "Blood clotting or bleeding disorder" }),
    value: "BLOOD_CLOTTING_BLEEDING_DISORDER"
  },
  {
    label: defineMessage({ defaultMessage: "Chronic disease such as diabetes or high blood pressure" }),
    value: "CHRONIC_DISEASE"
  },
  {
    label: defineMessage({ defaultMessage: "Birth defects or genetic disorders" }),
    value: "BIRTH_DEFECTS_GENETIC_DISORDERS"
  }
]

export const MaleCurrentlyTakingOptions = [
  { label: defineMessage({ defaultMessage: "Multivitamin" }), value: "MULTIVITAMIN" },
  { label: defineMessage({ defaultMessage: "Testosterone" }), value: "TESTOSTERONE" },
  {
    label: defineMessage({ defaultMessage: "Performance enhancing supplements or steroids" }),
    value: "PERFORMANCE_ENHANCING_SUPPLEMENTS_STEROIDS"
  },
  { label: defineMessage({ defaultMessage: "Other hormone" }), value: "OTHER_HORMONE" }
]

export const FemaleCurrentlyTakingOptions = [
  { label: defineMessage({ defaultMessage: "Prenatal vitamins" }), value: "PRENATAL_VITAMINS" },
  { label: defineMessage({ defaultMessage: "Multivitamin" }), value: "MULTIVITAMIN" },
  { label: defineMessage({ defaultMessage: "Vitamin D" }), value: "VITAMIN_D" },
  {
    label: defineMessage({ defaultMessage: "Contraceptive such as birth control pills, IUD, implant, or injectable" }),
    value: "CONTRACEPTIVE"
  },
  { label: defineMessage({ defaultMessage: "Hormones" }), value: "HORMONES" }
]

export const SmokeDrinkOptions = [
  { label: defineMessage({ defaultMessage: "I smoke cigarettes daily" }), value: "CIGARETTES_DAILY" },
  { label: defineMessage({ defaultMessage: "I quit smoking" }), value: "QUIT_SMOKING" },
  { label: defineMessage({ defaultMessage: "I use marijuana" }), value: "MARIJUANA" },
  { label: defineMessage({ defaultMessage: "I exercise regularly" }), value: "EXERCISE_REGULARLY" },
  {
    label: defineMessage({ defaultMessage: "I consume three or more servings of alcohol a week" }),
    value: "THREE_PLUS_ALCOHOLS_WEEKLY"
  },
  {
    label: defineMessage({ defaultMessage: "I consume one or more servings of caffeine a day" }),
    value: "ONE_PLUS_COFFEE_DAILY"
  },
  {
    label: defineMessage({ defaultMessage: "I have had environmental exposure at a level known to impact fertility" }),
    value: "ENVIRONMENTAL_EXPOSURE_IMPACTING_FERTILITY"
  },
  {
    label: defineMessage({ defaultMessage: "I have had radiation exposure at a level known to impact fertility" }),
    value: "RADIATION_EXPOSURE_IMPACTING_FERTILITY"
  }
]

export const FemaleExperiencingSymptomsOptions = [
  { label: defineMessage({ defaultMessage: "Moderate to severe acne" }), value: "ACNE" },
  {
    label: defineMessage({
      defaultMessage: "Unexpected hair growth on the face, chest, nipples, lower abdomen, or lower back"
    }),
    value: "UNEXPECTED_HAIR_GROWTH"
  },
  { label: defineMessage({ defaultMessage: "Significant hair loss" }), value: "HAIR_LOSS" },
  { label: defineMessage({ defaultMessage: "Unexplained weight gain or loss" }), value: "WEIGHT_CHANGE" },
  { label: defineMessage({ defaultMessage: "Chronic fatigue" }), value: "CHRONIC_FATIGUE" }
]

export const MaleExperiencingSymptomsOptions = [
  { label: defineMessage({ defaultMessage: "Significant hair loss" }), value: "HAIR_LOSS" },
  { label: defineMessage({ defaultMessage: "Unexplained weight gain or loss" }), value: "WEIGHT_CHANGE" },
  { label: defineMessage({ defaultMessage: "Chronic fatigue" }), value: "CHRONIC_FATIGUE" },
  { label: defineMessage({ defaultMessage: "Heart racing or palpitations" }), value: "HEART_RACING_PALPITATIONS" },
  { label: defineMessage({ defaultMessage: "Irritability or mood changes" }), value: "IRRITABILITY_MOOD_CHANGES" }
]

export const CycleDetails = [
  { label: defineMessage({ defaultMessage: "I’ve never had a menstrual cycle" }), value: "NEVER_HAD_PERIOD" },
  { label: defineMessage({ defaultMessage: "Sometimes I skip months" }), value: "SKIP_MONTHS" },
  {
    label: defineMessage({ defaultMessage: "I have bleeding or spotting in between my expected cycles" }),
    value: "SPOTTING_BETWEEN"
  },
  {
    label: defineMessage({ defaultMessage: "I have not had a menstrual cycle in more than 12 months" }),
    value: "NO_MENSTRUAL_CYCLE_OVER_12_MONTHS"
  },
  {
    label: defineMessage({ defaultMessage: "My cycles have always been affected by hormones/contraception" }),
    value: "CYCLES_AFFECTED_BY_HORMONES"
  }
]

export const PeriodsPainfulDetails = [
  { label: defineMessage({ defaultMessage: "Pain with intercourse" }), value: "PAINFUL_INTERCOURSE" },
  {
    label: defineMessage({ defaultMessage: "Pain with bowel movements or urination" }),
    value: "PAINFUL_BOWEL_URINATION"
  },
  { label: defineMessage({ defaultMessage: "Very heavy bleeding" }), value: "HEAVY_BLEEDING" }
]

export const PregnantPreviouslyDetails = [
  { label: defineMessage({ defaultMessage: "Preterm birth" }), value: "PRETERM_BIRTH" },
  { label: defineMessage({ defaultMessage: "Unintended pregnancy loss" }), value: "UNINTENDED_LOSS" },
  { label: defineMessage({ defaultMessage: "Ectopic pregnancy" }), value: "ECTOPIC_PREGNANCY" },
  { label: defineMessage({ defaultMessage: "High blood pressure" }), value: "HIGH_BLOOD_PRESSURE" },
  { label: defineMessage({ defaultMessage: "High blood sugar" }), value: "HIGH_BLOOD_SUGAR" },
  {
    label: defineMessage({ defaultMessage: "Depression or other emotional challenges" }),
    value: "DEPRESSION_OTHER_CHALLENGES"
  }
]

export const MenopauseDiagnosis = [
  { label: defineMessage({ defaultMessage: "Yes" }), value: "YES" },
  { label: defineMessage({ defaultMessage: "No" }), value: "NO" }
]

export const MenopauseDiagnosisDetails = [
  {
    label: defineMessage({ defaultMessage: "I have been diagnosed with premature ovarian failure" }),
    value: "PREMATURE_OVARIAN_FAILURE"
  },
  {
    label: defineMessage({ defaultMessage: "I was diagnosed with menopause at under 40 years of age" }),
    value: "MENOPAUSE_UNDER_40"
  },
  {
    label: defineMessage({ defaultMessage: "I was diagnosed with menopause at over 40 years of age" }),
    value: "MENOPAUSE_OVER_40"
  }
]

export const MenopauseSymptoms = [
  { label: defineMessage({ defaultMessage: "Hot flashes" }), value: "HOT_FLASHES" },
  { label: defineMessage({ defaultMessage: "Vaginal dryness" }), value: "VAGINAL_DRYNESS" },
  {
    label: defineMessage({ defaultMessage: "Decreased interest in sexual intercourse" }),
    value: "DECREASED_INTEREST_INTERCOURSE"
  },
  {
    label: defineMessage({ defaultMessage: "Pain or discomfort during intercourse" }),
    value: "PAIN_DURING_INTERCOURSE"
  },
  { label: defineMessage({ defaultMessage: "Bleeding after intercourse" }), value: "BLEEDING_AFTER_INTERCOURSE" },
  {
    label: defineMessage({ defaultMessage: "Soreness, itching, or burning around the vaginal entrance" }),
    value: "SORENESS_AROUND_VAGINAL_ENTRANCE"
  },
  { label: defineMessage({ defaultMessage: "Unexplained weight gain" }), value: "UNEXPLAINED_WEIGHT_GAIN" },
  { label: defineMessage({ defaultMessage: "Problems with sleep (frequent waking)" }), value: "SLEEP_PROBLEMS" },
  { label: defineMessage({ defaultMessage: "Mood changes" }), value: "MOOD_CHANGES" },
  { label: defineMessage({ defaultMessage: "Forgetfulness" }), value: "FORGETFULNESS" }
]

export const PelvicSurgeryDetails = [
  { label: defineMessage({ defaultMessage: "Bilateral tubal ligation (”tied my tubes”)" }), value: "TUBES_TIED" },
  { label: defineMessage({ defaultMessage: "Other tubal surgery" }), value: "OTHER_TUBAL_SURGERY" },
  { label: defineMessage({ defaultMessage: "Removal of fibroids" }), value: "REMOVED_FIBROIDS" },
  { label: defineMessage({ defaultMessage: "Removal of polyps in uterus" }), value: "REMOVED_POLYPS_IN_UTERUS" },
  { label: defineMessage({ defaultMessage: "Removal of septum in uterus" }), value: "REMOVED_SEPTUM_IN_UTERUS" },
  { label: defineMessage({ defaultMessage: "Removal of uterus" }), value: "REMOVAL_OF_UTERUS" },
  { label: defineMessage({ defaultMessage: "One ovary removed" }), value: "ONE_OVARY_REMOVED" },
  { label: defineMessage({ defaultMessage: "Two ovaries removed" }), value: "TWO_OVARIES_REMOVED" }
]

export const LowTestosteroneSymptomsDetails = [
  {
    label: defineMessage({ defaultMessage: "Decreased interest in sex" }),
    value: "DECREASED_INTEREST_SEX"
  },
  {
    label: defineMessage({
      defaultMessage: "Erectile dysfunction, ejaculatory dysfunction, or difficulty with intercourse"
    }),
    value: "ERECTILE_DYSFUNCTION"
  },
  {
    label: defineMessage({ defaultMessage: "Fatigue or decreased energy" }),
    value: "FATIGUE_DECREASED_ENERGY"
  },
  {
    label: defineMessage({ defaultMessage: "Decreased muscular strength" }),
    value: "DECREASED_MUSCULAR_STRENGTH"
  },
  {
    label: defineMessage({ defaultMessage: "Depressed mood" }),
    value: "DEPRESSED_MOOD"
  },
  {
    label: defineMessage({ defaultMessage: "Irritability" }),
    value: "IRRITABILITY"
  }
]
